import React from 'react';
import { useEnhancedTaggingFiltersContext } from '@/condor/application/EnhancedTaggingContexts/EnhancedTaggingFiltersContext';
import FiltersBtn from '@/ui/shared/modules/Filters/FiltersPanel/FiltersBtn';
import { getFilterValues } from './helpers';

const EnhancedTaggingFiltersButton = () => {
  const { openSidebar, filters, filtersData } = useEnhancedTaggingFiltersContext();

  const filtersCount = Object.keys(getFilterValues(filters, filtersData)).length;

  return <FiltersBtn onClick={ openSidebar } filtersCount={ filtersCount } />;
};

export default EnhancedTaggingFiltersButton;
