import { connect } from 'react-redux';
import BulkDeleteHeadCell from './BulkDeleteHeadCell';
import selectors from '@/condor/application/actions/accounts/collection/selectors';
import { toggleBulkDelete } from '@/condor/application/actions/accounts/collection/actions';

const mapStateToProps = (state) => ({
  bulkDeleteItems: selectors.bulkDeleteItems(state),
  accounts: selectors.getCollectionData(state),
});

const mapDispatchToProps = {
  toggleBulkDeleteAll: toggleBulkDelete,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BulkDeleteHeadCell);
