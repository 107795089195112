import { validateDate } from '@/ui/shared/validators/validateDate';
import { validateMaxLength } from '@/ui/shared/validators/validateMaxLength';
import { validateMinLength } from '@/ui/shared/validators/validateMinLength';
import { validateNumberMaxMinAmount } from '@/ui/shared/validators/validateNumberMaxMinAmount';
import { validateRequiredFields } from '@/ui/shared/validators/validateRequiredFields';
import { validateFieldsArrayForReduxForm } from '@/ui/shared/validators/validateFieldsArrayForReduxForm';
import { validateExternalLinkName } from '@/ui/shared/validators/validateExternalLinkName';
import { validateLink } from '@/ui/shared/validators/validateLink';
import { validateNumber } from '@/ui/shared/validators/validateNumber';
import { validateRequiredField } from '@/ui/shared/validators/validateRequiredField';
import { validateColors } from '@/ui/shared/validators/validateColors';
import { groupValidators } from '@/ui/shared/validators/helpers';

const paramsRequiredFields = [
  { fieldName: 'name', fieldCaption: 'Deal Name:' },
  { fieldName: 'finishedAt', fieldCaption: 'Expiration Date:' },
  { fieldName: 'topButtonText', fieldCaption: 'Button Text:' },
  { fieldName: 'viewerCustomLinks', fieldCaption: 'Custom Link:' },
];

const paramsColorFields = [
  { fieldName: 'presentationBackgroundColor' },
  { fieldName: 'toolbarColor' },
  { fieldName: 'toolbarAccentColor' },
  { fieldName: 'toolbarButtonsColor' },
  { fieldName: 'buttonTextColor' },
  { fieldName: 'dealNameColor' },
  { fieldName: 'rightPanelBackgroundColor' },
  { fieldName: 'rightPanelAccentColor' },
  { fieldName: 'rightPanelIconsColor' },
  { fieldName: 'sectionHeaderColor' },
  { fieldName: 'customSectionHeaderBackgroundColor' },
  { fieldName: 'rightPanelTextAndDealRoadshowLogoColor' },
  { fieldName: 'topButtonColor' },
  { fieldName: 'topButtonTextColor' },
  { fieldName: 'toolbarAccentColor' },
  { fieldName: 'disclaimerBackgroundColor' },
  { fieldName: 'disclaimerTileColor' },
  { fieldName: 'disclaimerPrimaryTextColor' },
  { fieldName: 'disclaimerButtonColor' },
  { fieldName: 'disclaimerButtonTextColor' },
  { fieldName: 'disclaimerAccentColor' },
  { fieldName: 'disclaimerSecondaryTextColor' },
  { fieldName: 'disclaimerLinkColor' },
  { fieldName: 'disclaimerLabelTextColor' },
];

const paramsViewerCustomFiles = [
  {
    fieldName: 'name',
    fieldCaption: 'Display Name',
    functionValidation: [
      {
        name: validateRequiredField,
        params: {},
      },
      {
        name: validateMinLength,
        params: { length: 3 },
      },
      {
        name: validateMaxLength,
        params: { length: 128 },
      },
    ],
  },
];

const paramsViewerCustomLinks = [
  {
    fieldName: 'url',
    fieldCaption: 'URL',
    functionValidation: [
      {
        name: validateLink,
        params: {},
      },
      {
        name: validateMinLength,
        params: { length: 3 },
      },
      {
        name: validateMaxLength,
        params: { length: 256 },
      },
    ],
  },
  {
    fieldName: 'name',
    fieldCaption: 'Display Name',
    functionValidation: [
      {
        name: validateExternalLinkName,
        params: {
          length: {
            min: 3,
            max: 128,
          },
        },
      },
    ],
  },
];

/**
 * Validate Deal Size input
 * @param {Object} params
 * @returns {{}|null}
 */
const validateDealSize = (params) => {
  if (params.value && !/^[0-9]+(,[0-9]+)*(\.\d{1,2})?$/i.test(params.value)) {
    return { [params.fieldName]: 'Invalid size' };
  }
  return validateNumberMaxMinAmount(params);
};

const paramsEntryCodes = [
  {
    fieldName: 'loginsTimeLimit',
    fieldCaption: 'Minutes per login',
    functionValidation: [
      {
        name: validateNumber,
        params: {},
      },
    ],
  },
];

export default (values) => groupValidators(
  validateRequiredFields(paramsRequiredFields, values),
  validateMinLength({ fieldName: 'name', fieldCaption: 'Deal Name:', value: values.name, length: 3 }),
  validateMaxLength({ fieldName: 'name', fieldCaption: 'Deal Name:', value: values.name, length: 128 }),
  validateDate({
    fieldName: 'finishedAt',
    fieldCaption: 'Expiration Date:',
    value: values.finishedAt,
  }),
  validateDate({
    fieldName: 'expectedPricingDate',
    fieldCaption: 'Expected Pricing Date:',
    value: values.expectedPricingDate,
  }),
  validateNumber({
    fieldName: 'loginsTimeLimit',
    fieldCaption: 'Minutes per login',
    value: values.loginsTimeLimit,
  }),
  validateDealSize({
    fieldName: 'dealSizeMillions',
    value: values.dealSizeMillions,
    fieldCaption: 'Size',
    maxAmount: 100000,
    minAmount: 0,
  }),
  validateColors(paramsColorFields, values),
  validateFieldsArrayForReduxForm('entryCodes', paramsEntryCodes, { collection: values.entryCodes }),
  validateFieldsArrayForReduxForm('viewerCustomFiles', paramsViewerCustomFiles, { collection: values.viewerCustomFiles }),
  validateFieldsArrayForReduxForm('viewerCustomLinks', paramsViewerCustomLinks, { collection: values.viewerCustomLinks }),
);
