import React from 'react';
import styles from '@/condor/ui/components/Finsight/Filings/FilingsTables/tableStyles.scss';
import { Icon, IconType, Tooltip } from '@dealroadshow/uikit';

export const getIssuerNameCellEe = ({ row: filing }) => (
  <Tooltip content="View SEC filing">
    <a
      href={ filing.url }
      target="_blank"
    >
      { filing.secIssuerName }
      <Icon
        className={ styles.link }
        type={ IconType.externalLink }
      />
    </a>
  </Tooltip>
);
