import React from 'react';
import SharedTags from '@/ui/shared/modules/Filters/FiltersPanel/Tags';
import {
  useEnhancedTaggingFiltersContext,
  FiltersType,
} from '@/condor/application/EnhancedTaggingContexts/EnhancedTaggingFiltersContext';
import { getFilterValues, getFiltersFormatter } from '../helpers';
import { filtersKeys } from '../constants';

interface IProps {
  onTagRemove: (fieldName: string, value: any) => void,
  filters: FiltersType,
}

const Tags = ({ onTagRemove, filters }: IProps) => {
  const { filtersData, isFiltersLoaded } = useEnhancedTaggingFiltersContext();

  const tagRemoveHandler = (fieldName: string, fieldValue: any) => {
    if (Array.isArray(fieldValue)) {
      onTagRemove(fieldName, []);
    }

    if (fieldName === 'dates') {
      onTagRemove('dateTo', null);
      onTagRemove('dateFrom', null);
    }

    if (fieldName === 'transactionTypeIds') {
      onTagRemove('transactionTypeIds', {});
    }
  };

  if (!isFiltersLoaded) {
    return null;
  }

  return (
    <SharedTags
      filters={ getFilterValues(filters, filtersData) }
      onTagRemove={ tagRemoveHandler }
      filtersFormatter={ getFiltersFormatter(filtersData) }
      allowedToRemoveFilters={ filtersKeys }
    />
  );
};

export default Tags;
