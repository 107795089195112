import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleContactsTableFilterState } from '@/condor/application/actions/contacts/filters/actions';
import CheckboxFilters from './CheckboxFilters';
import selectors from '@/condor/application/actions/contacts/filters/selectors';

const mapStateToProps = (state) => (
  {
    filters: selectors.getFilters(state),
  }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    setIsActivated: () => toggleContactsTableFilterState('isActivated'),
    setIsUnactivated: () => toggleContactsTableFilterState('isUnactivated'),

    setIsVerified: () => toggleContactsTableFilterState('isVerified'),
    setIsUnverified: () => toggleContactsTableFilterState('isUnverified'),

    setIsBanned: () => toggleContactsTableFilterState('isBanned'),
    setIsUnbanned: () => toggleContactsTableFilterState('isUnbanned'),
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CheckboxFilters);
