import { batch, connect } from 'react-redux';
import * as userActions from '@/users/application/actions/data/actions';
import * as companyActions from '@/users/application/actions/companies/data/actions';
import * as userSelectors from '@/users/application/actions/data/selectors';
import * as companySelectors from '@/users/application/actions/companies/data/selectors';
import { resetUserLinks } from '@/users/application/actions/links/actions';
import { getCurrentUserPermissions } from '@/users/application/actions/permissions/actions';
import ContactProfile from './ContactProfile';

const mapStateToProps = (state) => (
  {
    isFetching: (
      (userSelectors.isUserFetching(state) && companySelectors.isCompaniesFetching(state)) ||
      userSelectors.isUserUpdating(state)
    ),
    contact: userSelectors.getUser(state),
  }
);

const mapDispatchToProps = (dispatch, ownProps) => (
  {
    init: () => {
      dispatch(userActions.getUserById(ownProps.match.params.contactId));
      dispatch(companyActions.getCompanies({ limit: 50 }));
      dispatch(getCurrentUserPermissions());
    },
    reset: () => batch(() => {
      dispatch(userActions.resetUserData());
      dispatch(companyActions.resetCompaniesData());
      dispatch(resetUserLinks());
    }),
  }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContactProfile);
