import React, { useCallback } from 'react';
import { Field } from 'redux-form';
import { ISSUER } from '@/finsight/application/actions/pageFilters/constants';
import CompanySelectField from '@/condor/ui/components/Finsight/Companies/CompanySelectField';
import { APPLICATION_WRP_ID } from '@/ui/shared/components/Layout/ApplicationWrp/constants';
import { CONTENT_WRP_ID } from '@/ui/shared/components/Layout/ContentWrp/constants';

interface IProps {
  tableRef: any,
  setIssuerTicker: (value: any) => void,
}

const IssuerField = ({ tableRef, setIssuerTicker }: IProps) => {
  const onIssuerChange = useCallback((value) => {
    setIssuerTicker(value);
  }, []);

  return (
    <Field
      name="primaryIssuer"
      isSelect
      usePortal
      role={ ISSUER }
      component={ CompanySelectField }
      onChange={ onIssuerChange }
      withoutExcludedIds
      isNarrow
      bodyElement={ document.getElementById(APPLICATION_WRP_ID) }
      scrollableParentElements={ [tableRef.current, document.getElementById(CONTENT_WRP_ID)] }
    />
  );
};

export default IssuerField;
