import { useState } from 'react';
import { useHistory } from 'react-router';

import EventRepository from '@/openMarket/infrastructure/repository/Condor/EventRepository';
import { IRoadshowData } from '@/openMarket/domain/Condor/vo/IEvent';
import { useDIContext } from '@/Framework/DI/DIContext';
import { useEventFormInit } from './useEventFormInit';
import { NotificationManager } from '@/ui/shared/components/Notification';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import getMessage from '@/Framework/Message/getMessage';
import { CONDOR_FINSIGHT_OPEN_MARKET_EVENTS } from '@/condor/ui/common/constants';
import { messageCodes } from '@/Framework/Message/messages';
import { IFormValues, IUseEventFormProps } from '../interfaces';
import { getEventPayload, getModifyKeyNames } from '../helpers';

export const useEventForm = ({ eventId, initProfile }: IUseEventFormProps) => {
  const history = useHistory();
  const { container } = useDIContext();
  const eventRepository = container.get<EventRepository>(EventRepository);

  const [roadshowData, setRoadshowData] = useState<IRoadshowData>(null);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isFetchingRoadshowData, setIsFetchingRoadshowData] = useState<boolean>(false);

  const isCreate = !eventId;
  const title = `${ isCreate ? 'Create' : 'Edit' } Event`;

  const redirectToTable = () => history.push(CONDOR_FINSIGHT_OPEN_MARKET_EVENTS);

  const getRoadshowsList = async (inputValue: string) => {
    try {
      const roadshows = await eventRepository.getRoadshowsList(inputValue);
      return { collection: roadshows };
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
      return error;
    }
  };

  const getRoadshowData = async (id: string) => {
    setIsFetchingRoadshowData(true);
    try {
      const response = await eventRepository.getRoadshowData(id);
      setRoadshowData(getModifyKeyNames(response));
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    } finally {
      setIsFetchingRoadshowData(false);
    }
  };

  const handleCreateEvent = async (payload: IFormValues) => {
    setIsSubmitting(true);
    try {
      await eventRepository.createEvent(getEventPayload(payload));
      NotificationManager.success(getMessage(messageCodes.OPEN_MARKET_EVENT_CREATED, { eventName: payload.name }));
      redirectToTable();
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUpdateEvent = async (payload: IFormValues) => {
    setIsSubmitting(true);
    try {
      await eventRepository.updateEvent(getEventPayload(payload));
      NotificationManager.success(getMessage(messageCodes.OPEN_MARKET_EVENT_UPDATED, { eventName: payload.name }));
      redirectToTable();
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmit = isCreate ? handleCreateEvent : handleUpdateEvent;

  return {
    ...useEventFormInit({ eventId, initProfile, redirectToTable, getRoadshowData }),
    handleSubmit,
    redirectToTable,
    getRoadshowData,
    setRoadshowData,
    getRoadshowsList,
    roadshowData,
    isFetchingRoadshowData,
    isSubmitting,
    isCreate,
    title,
  };
};
