import {
  COLOR_TYPES_NAMES,
  HEX_COLOR_TRANSPARENT,
  DEFAULT_PRESET_COLORS,
  FILL_COLORS,
  HEX_COLOR_WHITE_SHORT,
  HEX_COLOR_BLACK_SHORT,
} from './constants';

class ColorType {
  /**
   * @return {String}
   */
  static getHex() {
    return COLOR_TYPES_NAMES.HEX.value;
  }

  /**
   * @return {String}
   */
  static getRgb() {
    return COLOR_TYPES_NAMES.RGB.value;
  }

  /**
   * @return {String}
   */
  static getHsl() {
    return COLOR_TYPES_NAMES.HSL.value;
  }

  /**
   * @param {String} type
   * @return {Boolean}
   */
  static isHexType(type) {
    return ColorType.getHex() === type;
  }

  /**
   * @param {String} hex
   * @return {Boolean}
   */
  static isHexTransparent(hex) {
    return hex === HEX_COLOR_TRANSPARENT;
  }

  /**
   * @return {Array.<String>}
   */
  static getDefaultPresetColors() {
    return DEFAULT_PRESET_COLORS;
  }

  /**
   * @return {Array.<String>}
   */
  static getFillColors() {
    return FILL_COLORS;
  }

  /**
   * @return {String}
   */
  static getLightColor() {
    return HEX_COLOR_WHITE_SHORT;
  }

  /**
   * @return {String}
   */
  static getDarkColor() {
    return HEX_COLOR_BLACK_SHORT;
  }

  /**
   * @return {Object}
   */
  static getColorTypesName() {
    return COLOR_TYPES_NAMES;
  }
}

export default ColorType;
