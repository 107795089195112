import { batch, connect } from 'react-redux';
import {
  getAccountsByFilter,
  resetAccounts,
} from '@/condor/application/actions/accounts/collection/actions';
import { resetCondorAccountsItem } from '@/condor/application/actions/accounts/item/actions';
import { resetAccountsFilters } from '@/condor/application/actions/accounts/filters/actions';
import { getAccountTypes, resetAccountTypesCollection } from '@/condor/application/actions/accountTypes/collection/actions';
import { getCurrentUserPermissions } from '@/users/application/actions/permissions/actions';
import AccountsList from './AccountsList';

const mapDispatchToProps = (dispatch) => (
  {
    init: () => {
      dispatch(getAccountsByFilter());
      dispatch(getAccountTypes({ limit: 50, orderBy: 'name', orderAsc: true }));
      dispatch(getCurrentUserPermissions());
    },
    reset: () => batch(() => {
      dispatch(resetCondorAccountsItem());
      dispatch(resetAccounts());
      dispatch(resetAccountsFilters());
      dispatch(resetAccountTypesCollection());
    }),
  }
);

export default connect(
  null,
  mapDispatchToProps,
)(AccountsList);
