import React from 'react';
import { components as ReactSelectComponents } from 'react-select';
import { connect } from 'react-redux';
import CondorAsyncSelect from '@/ui/shared/components/Form/Select/legacy/CondorAsyncSelect/CondorAsyncSelect';
import { fetchAccountsForRemapTo } from '@/condor/application/actions/accounts/mappings/metadata/actions';
import { getAccountsOptions } from '@/condor/application/actions/accounts/mappings/metadata/selectors';
import styles from '../mappingModal.scss';

const mapStateToProps = (state) => ({
  filteredOptions: getAccountsOptions(state),
  components: {
    Option: ({ children, ...option }) => (
        // @ts-ignore
      <ReactSelectComponents.Option
        { ...option }
        className={ styles.selectAccountOptions }
      >
        { children }
      </ReactSelectComponents.Option>
    ),
  },
});

const mapDispatchToProps = {
  fetchOptions: fetchAccountsForRemapTo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CondorAsyncSelect);
