import { Editor, Element as SlateElement } from 'slate';
import { TSlateNode } from '@/ui/shared/components/Form/RichTextInput/interfaces';
import { TToolbarItemFormat } from '../interfaces';

export const isBlockActive = (editor: Editor, format: TToolbarItemFormat): boolean => {
  const { selection } = editor;
  if (!selection) {
    return false;
  }
  const [match] = Array.from(Editor.nodes(editor, {
    at: Editor.unhangRange(editor, selection),
    match: (n: TSlateNode) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  }));

  return !!match;
};
