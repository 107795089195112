import React, { createContext, useContext } from 'react';
import { useEnhancedTaggingFilters } from './useEnhancedTaggingFilters';

interface IProps {
  children: React.ReactNode,
}

type TEnhancedTaggingFiltersContext = ReturnType<typeof useEnhancedTaggingFilters>;

export const EnhancedTaggingFiltersContext = createContext<TEnhancedTaggingFiltersContext>(null);

export const useEnhancedTaggingFiltersContext = () => {
  const context = useContext(EnhancedTaggingFiltersContext);
  if (!context) {
    throw new Error('useEnhancedTaggingFiltersContext must be used within a EnhancedTaggingFiltersContextProvider');
  }
  return context;
};

const EnhancedTaggingFiltersContextProvider = ({ children }: IProps) => (
  <EnhancedTaggingFiltersContext.Provider value={ useEnhancedTaggingFilters() }>
    { children }
  </EnhancedTaggingFiltersContext.Provider>
);

export default EnhancedTaggingFiltersContextProvider;
