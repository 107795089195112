import { connect } from 'react-redux';
import get from 'lodash/get';
import RatingAgenciesFields from './RatingAgenciesFields';
import selectors from '@/condor/application/actions/finsight/deal/selectors';

const mapStateToProps = (state, ownProps) => {
  const item = get(selectors.getItemData(state), ownProps.field);
  return {
    ratingAgencies: selectors.getRatingAgencies(state),
    ratings: item ? item.ratings : {},
  };
};

export default connect(
  mapStateToProps,
)(RatingAgenciesFields);
