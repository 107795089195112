import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { onSearchChange } from '@/condor/application/actions/finsight/import/bond/actions';
import SearchInput from '@/ui/shared/components/Form/Fields/SearchInput';
import { IconType } from '@dealroadshow/uikit';
import { SEARCH_PLACEHOLDER } from '@/finsight/ui/components/bonds/constants';
import metadataSelectors from '@/finsight/application/actions/bonds/metadata/selectors';

const mapStateToProps = (state) => ({
    placeholder: SEARCH_PLACEHOLDER,
    iconType: IconType.search,
    value: metadataSelectors.getSearch(state),
    name: 'bondSearch',
    isNarrow: true,
  });

const mapDispatchToProps = {
  onChange: onSearchChange,
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchInput));
