import React, { useMemo } from 'react';
import { Tooltip } from '@dealroadshow/uikit';

/**
 * @param InputComponent
 */
const withTooltipOnInputLabel = (InputComponent) => (
  {
    labelTooltipContent,
    label,
    ...otherProps
  },
) => {
  const labelWithTooltip = useMemo(
    () => (
      <Tooltip content={ labelTooltipContent || label }>
        { label }
      </Tooltip>
    ),
    [],
  );
  return (
    <InputComponent
      { ...otherProps }
      label={ labelWithTooltip }
    />
  );
};

export default withTooltipOnInputLabel;
