import { injectable, inject } from 'inversify';
import Request from '@/Framework/api/Rpc/Request';
import RpcDispatcher from '@/dmPortal/application/DI/Rpc/HttpDispatcher';
import { IListProfile, IProfile } from '@/openMarket/domain/Condor/vo/IProfile';
import { IFetchCollectionPayload, IFetchCollectionResponse } from '@/Framework/State/useFetchCollection';
import { IDisplayedProfile } from '@/openMarket/domain/Condor/vo/IEvent';

@injectable()
class ProfileRepository {
  constructor(@inject(RpcDispatcher) private readonly rpc: typeof RpcDispatcher) {}

  getProfiles = async (payload: IFetchCollectionPayload): Promise<IFetchCollectionResponse<IDisplayedProfile>> => {
    const request = new Request('open_market.profiles.list', payload);
    const response = await this.rpc.call(request);

    return response.getResult().payload;
  };

  createProfile = async (payload: IProfile): Promise<void> => {
    const request = new Request('open_market.profile.create', payload);
    const response = await this.rpc.call(request);

    return response.getResult().payload;
  };

  updateProfile = async (payload: IProfile): Promise<void> => {
    const request = new Request('open_market.profile.update', payload);
    const response = await this.rpc.call(request);

    return response.getResult().payload;
  };

  getProfileById = async (id: string): Promise<IProfile> => {
    const request = new Request('open_market.profile.get_by_id', {
      profileId: id,
    });
    const response = await this.rpc.call(request);

    return response.getResult().payload;
  };

  deleteProfile = async (id: string) => {
    const request = new Request('open_market.profile.delete', {
      profileId: id,
    });
    const response = await this.rpc.call(request);

    return response.getResult().payload;
  };

  getProfilesList = async (payload: IFetchCollectionPayload): Promise<IFetchCollectionResponse<IListProfile>> => {
    const request = new Request('open_market.profiles.list', payload);
    const response = await this.rpc.call(request);

    return response.getResult().payload;
  };
}

export default ProfileRepository;
