import { connect } from 'react-redux';
import {
  addApplicationBlacklistItemsWithValidation,
  setApplicationBlacklistAddItemsInputState,
} from '@/condor/application/actions/blacklists/application/actions';
import selectors from '@/condor/application/actions/blacklists/application/selectors';
import AddItemsInput from '@/condor/ui/common/Blacklist/AddItemsInput';

const mapStateToProps = (state) => (
  {
    inputValue: selectors.getAddInputValue(state),
    errors: selectors.getAddingErrors(state),
    isAdding: selectors.isAdding(state),
    isFocused: selectors.isAddInputFocused(state),
  }
);

const mapDispatchToProps = {
  onInputChange: (e) => setApplicationBlacklistAddItemsInputState({ value: e.target.value }),
  onInputFocus: () => setApplicationBlacklistAddItemsInputState({ errors: [], isFocused: true }),
  onInputBlur: () => setApplicationBlacklistAddItemsInputState({ isFocused: false }),
  onAddClick: addApplicationBlacklistItemsWithValidation,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddItemsInput);
