import groupBy from 'lodash/groupBy';
import {
  DEAL_PROFILE_TRANCHE_FIELDS_ARRAY,
  DEAL_PROFILE_FIELDS,
  TRANCHE_GROUP_IDS,
} from '../constants';

/**
 * @param {Array} ratingAgencies
 * @return {Array}
 */
const getDealTrancheProperties = (ratingAgencies) => {
  const ratingsPropertyIndex = DEAL_PROFILE_TRANCHE_FIELDS_ARRAY.findIndex(
    (prop) => (prop.name === DEAL_PROFILE_FIELDS.TRANCHE.RATINGS.name),
  );

  return groupBy([
    ...DEAL_PROFILE_TRANCHE_FIELDS_ARRAY.slice(0, ratingsPropertyIndex),
    ...ratingAgencies.map((agency, i, agencies) => (
      {
        name: agency.abbreviation,
        label: agency.displayName,
        groupId: TRANCHE_GROUP_IDS.RATING_AGENCIES,
        ...((i === agencies.length - 1) && { isSeparator: true }),
      }
    )),
    ...DEAL_PROFILE_TRANCHE_FIELDS_ARRAY.slice(ratingsPropertyIndex + 1),
  ], (prop) => prop.groupId);
};

export default getDealTrancheProperties;
