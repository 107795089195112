import { connect, batch } from 'react-redux';
import {
  setSelectedImport,
  changeDeleteImportModalVisible,
} from '@/condor/application/actions/finsight/import/company/auditTrail/actions';
import DateOfImportCell from './DateOfImportCell';

const mapDispatchToProps = (dispatch, ownProps) => (
  {
    onDelete: () => batch(() => {
      dispatch(setSelectedImport(ownProps.cellProps.row));
      dispatch(changeDeleteImportModalVisible(true));
    }),
  }
);

export default connect(
  null,
  mapDispatchToProps,
)(DateOfImportCell);
