import { connect } from 'react-redux';
import { withRouter } from 'next/router';
import { fullResetBondsPage, initBonds } from '@/condor/application/actions/finsight/import/bond/actions';
import Bonds from './BondList';
import metadataSelectors from '@/finsight/application/actions/bonds/metadata/selectors';
import { CONDOR_FINSIGHT_IMPORT_BOND_LIST_BASE_URL } from '@/condor/ui/common/constants';

const mapStateToProps = (state) => {
  const isInitialized = metadataSelectors.getAssetClassCollection(state).length > 0;
  return {
    baseUrl: CONDOR_FINSIGHT_IMPORT_BOND_LIST_BASE_URL,
    isInitialized,
  };
};
const mapDispatchToProps = (dispatch) => ({
  init: () => dispatch(initBonds(CONDOR_FINSIGHT_IMPORT_BOND_LIST_BASE_URL)),
  reset: () => dispatch(fullResetBondsPage()),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Bonds));
