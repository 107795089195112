import React, { createContext, useContext, useEffect, useState } from 'react';
import useFetchCollection from '@/Framework/State/useFetchCollection';
import { useDIContext } from '@/Framework/DI/DIContext';
import ContactRepository from '@/finsight/infrastructure/repository/ContactRepository';
import { IManagePlansRequestItem } from '@/condor/domain/vo/FinsightManager/ManagePlans';

interface IPayloadState {
    includeApproved: boolean,
    includeDenied: boolean,
    includePending: boolean,
}

const useRequests = () => {
  const [filters, setFilters] = useState<IPayloadState>({
      includeApproved: false,
      includeDenied: false,
      includePending: true,
     });

  const { container } = useDIContext();
  const contactRepository = container.get(ContactRepository);

  const setApproved = () => {
    setFilters((prevState) => ({
      ...prevState,
        includeApproved: !prevState.includeApproved,
    }));
    requestsCollection.paginate(1);
  };

  const setDenied = () => {
    setFilters((prevState) => ({
        ...prevState,
        includeDenied: !prevState.includeDenied,
      }));
    requestsCollection.paginate(1);
  };

  const setPending = () => {
    setFilters((prevState) => ({
        ...prevState,
        includePending: !prevState.includePending,
      }));
    requestsCollection.paginate(1);
  };

  const requestsCollection: ReturnType<typeof useFetchCollection & {
    collection: IManagePlansRequestItem[],
  }> = useFetchCollection(
    contactRepository.getCondorSubscriptionRequests,
    { filters: { ...filters } } as { filters: IPayloadState },
    { sortBy: 'status', sortOrder: 'asc' },
  );

  useEffect(() => requestsCollection.reset, []);

  return {
    ...filters,
    setApproved,
    setDenied,
    setPending,
    ...requestsCollection,
  };
};

const RequestsContext = createContext<ReturnType<typeof useRequests>>(null);

export const useRequestsContext = () => {
  const context = useContext(RequestsContext);
  if (!context) {
    throw new Error('useRequestsContext must be used within a RequestCountContextProvider');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

const RequestsContextProvider = ({ children }: IProps) => (
  <RequestsContext.Provider value={ useRequests() }>
    { children }
  </RequestsContext.Provider>
);

export default RequestsContextProvider;
