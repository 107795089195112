import React, { useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Field as FieldComponent, FormRenderProps } from 'react-final-form';

import { useEnhancedTaggingCondorContext } from '@/condor/application/EnhancedTaggingContexts/EnhancedTaggingCondorContext';
import FinalFormSelect from '@/ui/shared/components/Form/Select/legacy/FinalFormSelect';
import TextField from '@/ui/shared/modules/EnhancedTagging/Field/TextField';
import Company from '@/ui/shared/modules/EnhancedTagging/Field/Company';
import TaggingStateSelect from '../TaggingStateSelect';
import { IRowData } from '@dealroadshow/uikit';

import spaces from '@/ui/shared/styles/helpers/spaces.scss';
import styles from './bulkEditingModal.scss';

interface IProps {
  formProps: FormRenderProps,
  fieldComponent: typeof FieldComponent,
  setModifiedCollection: (array: IRowData[]) => void,
  setIsDirtyForm: (value: boolean) => void,
}

const BulkEditRow = ({
  fieldComponent: Field,
  formProps,
  setModifiedCollection,
  setIsDirtyForm,
}: IProps) => {
  const {
    transactionTypeOptions,
    companies,
    sectorOptions,
    industryOptions,
    countriesOptions,
    currenciesOptions,
  } = useEnhancedTaggingCondorContext();
  const formState = formProps.form.getState();
  const values = formState.values as { editableTableArray: IRowData[] };
  const { dirty } = formState;
  const { editableTableArray } = values;

  const changeField = async (fieldName: string, value: string | number) => {
    const { change, batch } = formProps.form;

    batch(() => {
      editableTableArray.forEach((_, index) => {
        change(`editableTableArray[${ index }].${ fieldName }`, value);
      });
    });
  };

  useEffect(() => {
    setIsDirtyForm(dirty);
  }, [dirty]);

  useEffect(() => {
    setModifiedCollection(editableTableArray);
  }, [editableTableArray]);

  const companyOnChangeHandler = (value, suggestion, event) => {
    if (event.type !== 'change') {
      if (value) {
        changeField('parent', value);
        changeField('companyId', suggestion.id);
        return;
      }
      changeField('parent', value);
      changeField('companyId', null);
    }
  };

  return (
    <tr className={ styles.editRow } data-test="BulkEditRow">
      <td colSpan={ 2 } className={ styles.editRowTitle }>
        Bulk Edit controls
      </td>
      <td>
        <Field
          name="taggingState"
          component={ ({ meta, input }) => (
            <TaggingStateSelect
              value={ input.value }
              dataTest="taggingState"
              name="taggingState"
              formFieldClassName={ spaces.mbn }
              meta={ meta }
              simpleValue
              input={ {
                ...input,
                onChange: (value) => {
                  input.onChange(value);
                  changeField('taggingState', value);
                },
              } }
            />
          ) }
        />
      </td>
      <td>
        <Field
          name="transactionType"
          component={ ({ meta, input }) => (
            <FinalFormSelect
              dataTest="transactionType"
              placeholder="Type"
              name="transactionType"
              formFieldClassName={ spaces.mbn }
              meta={ meta }
              options={ transactionTypeOptions }
              simpleValue
              input={ {
                ...input,
                onChange: (value) => {
                  input.onChange(value);
                  changeField('transactionTypeId', value);
                },
              } }
            />
          ) }
        />
      </td>
      <td />
      <td>
        <TextField
          // @ts-ignore
          clearOnBlur
          // @ts-ignore
          component={ Company }
          // @ts-ignore
          fieldComponent={ Field }
          name="parent"
          dataTest="parent"
          placeholder="Company Name"
          // @ts-ignore
          formFieldClassName={ spaces.mbn }
          companies={ companies }
          usePortal={ false }
          onChange={ companyOnChangeHandler }
        />
      </td>
      <td>
        <Field
          name="subsector"
          component={ ({ meta, input }) => (
            <FinalFormSelect
              placeholder="ABS Sector & Subsector"
              dataTest="subsector"
              formFieldClassName={ spaces.mbn }
              simpleValue
              // @ts-ignore
              asterisk
              options={ sectorOptions }
              meta={ meta }
              input={ {
                ...input,
                onChange: (value) => {
                  input.onChange(value);
                  changeField('subsectorId', value);
                },
              } }
            />
          ) }
        />
      </td>
      <td>
        <Field
          name="subindustry"
          component={ ({ meta, input }) => (
            <FinalFormSelect
              dataTest="subindustry"
              placeholder="Company Industry & Subindustry"
              formFieldClassName={ spaces.mbn }
              // @ts-ignore
              component={ FinalFormSelect }
              simpleValue
              asterisk
              options={ industryOptions }
              meta={ meta }
              input={ {
                ...input,
                onChange: (value) => {
                  input.onChange(value);
                  changeField('subindustryId', value);
                },
              } }
            />
          ) }
        />
      </td>
      <td>
        <Field
          name="country"
          component={ ({ meta, input }) => (
            <FinalFormSelect
              dataTest="country"
              placeholder="Select Country"
              formFieldClassName={ spaces.mbn }
              // @ts-ignore
              component={ FinalFormSelect }
              simpleValue
              asterisk
              options={ countriesOptions }
              meta={ meta }
              input={ {
                ...input,
                onChange: (value) => {
                  input.onChange(value);
                  changeField('countryId', value);
                },
              } }
            />
          ) }
        />
      </td>
      <td>
        <Field
          name="currency"
          component={ ({ meta, input }) => (
            <FinalFormSelect
              name="currency"
              dataTest="currency"
              placeholder="Deal Currency"
              formFieldClassName={ spaces.mbn }
              // @ts-ignore
              component={ FinalFormSelect }
              simpleValue
              asterisk
              options={ currenciesOptions }
              meta={ meta }
              input={ {
                ...input,
                onChange: (value) => {
                  input.onChange(value);
                  changeField('currencyId', value);
                },
              } }
            />
          ) }
        />
      </td>
      <td colSpan={ 3 } />
    </tr>
  );
};

export default BulkEditRow;
