import React from 'react';
import TraceTable from '../common/TraceTable';
import { useTraceContext } from '@/condor/application/Trace/TraceContextProvider';

const Trades = () => {
  const { filters } = useTraceContext();
  return <TraceTable archived={ filters.includedArchived } />;
};

export default Trades;
