import React from 'react';
import { useHistory } from 'react-router-dom';
import { Spinner } from '@dealroadshow/uikit';
import ProfileWrapper from '@/condor/ui/common/Layout/ProfileWrapper';
import SsoCertificateUploadForm from './SsoCertificateUploadForm';
import UploadSsoCertificateContextProvider from '@/condor/application/Accounts/AccountProfile/AccountSSO/UploadSsoSertificate';
import AccountSSOForm from './AccountSSOForm';
import EnableSSOContextProvider from '@/condor/application/Accounts/AccountProfile/AccountSSO/EnableSSO';
import EnableSSO from './EnableSSO';
import { useSelector } from 'react-redux';
import { getUserPermissionsState } from '@/users/application/actions/permissions/selectors';
import { CONDOR_ACCOUNTS_LIST_BASE_URL } from '@/condor/ui/common/constants';

const AccountSSO = () => {
  const { currentUserPermissions } = useSelector(getUserPermissionsState);
  const { replace } = useHistory();

  if (currentUserPermissions.length === 0) {
    return (<Spinner overlay isVisible centered isFixed />);
  }

  if (currentUserPermissions.length && !currentUserPermissions.find((permission) => permission.moduleKey === 'user_permissions')) {
    replace(CONDOR_ACCOUNTS_LIST_BASE_URL);
    return null;
  }

  return (
    <ProfileWrapper>
      <EnableSSOContextProvider>
        <EnableSSO />
        <UploadSsoCertificateContextProvider>
          <SsoCertificateUploadForm />
        </UploadSsoCertificateContextProvider>
        <AccountSSOForm />
      </EnableSSOContextProvider>
    </ProfileWrapper>
  );
};

export default AccountSSO;
