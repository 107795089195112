import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import IndustryOrSectorField from './IndustryOrSectorField';
import { CONDOR_FINSIGHT_COMPANY_DEAL_TABLE_INLINE_EDIT_FORM_NAME } from '@/condor/ui/common/constants';
import dealsListSelectors from '@/condor/application/actions/finsight/company/deals/collection/selectors';

const formSelector = formValueSelector(CONDOR_FINSIGHT_COMPANY_DEAL_TABLE_INLINE_EDIT_FORM_NAME);

const mapStateToProps = (state) => (
  {
    currentProduct: formSelector(state, 'product'),
    sectorsOptions: dealsListSelectors.getOptions(state).sectors,
  }
);

export default connect(
  mapStateToProps,
)(IndustryOrSectorField);
