import { connect } from 'react-redux';
import {
  removeCondorFinsightCompany,
  resetCondorFinsightCompanyItem,
} from '@/condor/application/actions/finsight/company/item/actions';
import selectors from '@/condor/application/actions/finsight/company/item/selectors';
import DeleteModal from './DeleteModal';

const mapStateToProps = (state) => ({
  isModalVisible: selectors.isDeleteModalVisible(state),
  item: selectors.getItemData(state),
  isSpinnerVisible: selectors.isRemoving(state),
});

const mapDispatchToProps = {
  onDelete: removeCondorFinsightCompany,
  onCancel: resetCondorFinsightCompanyItem,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteModal);
