import { connect, batch } from 'react-redux';
import { bindActionCreators } from 'redux';
import GlobalBlacklist from './GlobalBlacklist';
import {
  getGlobalBlacklistByFilter,
  setGlobalBlacklistIsDeleteModalVisible,
  setCurrentGlobalBlacklistItem,
  reset,
} from '@/condor/application/actions/blacklists/global/actions';

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    init: getGlobalBlacklistByFilter,
    reset,
    onDeleteIconClick: (cellProps) => () => batch(() => {
      dispatch(setGlobalBlacklistIsDeleteModalVisible(true));
      dispatch(setCurrentGlobalBlacklistItem(cellProps.row));
    }),
  },
  dispatch,
);

export default connect(
  null,
  mapDispatchToProps,
)(GlobalBlacklist);
