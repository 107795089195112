import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import { Field } from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import FormWrapper from '@/condor/ui/common/Layout/FormWrapper';
import {
  CONDOR_FINSIGHT_MIGRATE_COMPANY_DEALS_FORM,
  CONDOR_FINSIGHT_COMPANIES_LIST_BASE_URL,
} from '@/condor/ui/common/constants';
import CompanySelectField from '../CompanySelectField';
import validate from './validate';
import getFieldsConfig from './helpers';
import styles from './migrateModal.scss';
import { Modal, Spinner, FullScreenPreloader } from '@dealroadshow/uikit';

interface IProps {
  item: any,
  onMigrate: () => void,
  onCancel: () => void,
  resetForm: () => void,
  informationForMigration: any,
  isSpinnerVisible: boolean,
}

const MigrateModal = (
  {
    item,
    onMigrate,
    onCancel,
    resetForm,
    informationForMigration,
    isSpinnerVisible,
  }: IProps,
) => {
  useEffect(() => resetForm, []);
  const { pathname } = useLocation();

  const fieldsListConfig = useMemo(() => getFieldsConfig(informationForMigration),
    [informationForMigration]);

  if (isEmpty(informationForMigration)) {
    return <FullScreenPreloader />;
  }

  const isCompaniesListUrl = pathname === CONDOR_FINSIGHT_COMPANIES_LIST_BASE_URL;

  const totalDealsCount = informationForMigration.deal.total;

  return (
    <Modal
      title={ isCompaniesListUrl ? 'Delete Confirmation' : 'Migrate Deals' }
      isVisible
      onCloseClicked={ onCancel }
      className={ styles.modal }
      dataTest="deleteMigrateDealsModal"
    >
      <div className={ styles.migrateContent }>
        <div className={ styles.migrateTitle }>
          There are
          <strong> { totalDealsCount } deal{ totalDealsCount > 1 ? 's' : '' } </strong>
          tagged to <strong>{ item.name }</strong>.
          Before you delete this company, please select a new company for each role
          (as applicable) to migrate all deals to:
        </div>
        <FormWrapper
          form={ CONDOR_FINSIGHT_MIGRATE_COMPANY_DEALS_FORM }
          dataTest={ CONDOR_FINSIGHT_MIGRATE_COMPANY_DEALS_FORM }
          enableReinitialize
          onSubmit={ onMigrate }
          onCancelClick={ onCancel }
          submitButtonText="Migrate All Deals"
          validate={ isCompaniesListUrl ? validate : noop }
          withAreYouSureModal={ false }
          buttonsWrapperClassname={ styles.buttonsWrp }
        >
          { Object.values(fieldsListConfig).map((fieldConfig, index) => (
            <Field
              // eslint-disable-next-line react/no-array-index-key
              key={ index }
              // @ts-ignore
              { ...fieldConfig }
              component={ CompanySelectField }
            />
          )) }
        </FormWrapper>
        { isSpinnerVisible && (<Spinner overlay isVisible />) }
      </div>
    </Modal>
  );
};

export default MigrateModal;
