import React, { useEffect } from 'react';
import cn from 'classnames';

import { useCallProfileContext } from '@/evercall/application/condor/call/profile/CallProfileContext';
import { Field, useForm } from '@/ui/shared/components/Form/FinalForm';
import useFormReinitializeAfterSubmit from '../useFormReinitializeAfterSubmit';

import { CheckboxInput, Icon, IconType, Tooltip } from '@dealroadshow/uikit';
import Input from '@/ui/shared/components/Form/Input';

import { IAdminCall } from '@/evercall/domain/vo/call/admin/AdminCall';
import { PARTICIPANT_CONFERENCE_ID_LABEL } from '@/evercall/ui/components/condor/CallsList/constants';
import { PARTICIPANT_CONFERENCE_ID_FIELD_TOOLTIP, REMOVE_FOOTER_TEXT_TOOLTIP_CONTENT } from './constants';
import evercallUrl from '@/evercall/infrastructure/evercallUrl';
import { toSlug } from '@/Framework/url/helpers';

import styles from './settings.scss';
import layoutStyles from '@/condor/ui/common/Layout/layout.scss';

interface IProps {
  isUpdating: boolean,
  isUpdateSucceeded: boolean,
}

const SettingsFormFields = ({ isUpdating, isUpdateSucceeded }: IProps) => {
  const {
    state: {
      item,
    },
  } = useCallProfileContext();

  const form = useForm<IAdminCall>();
  const { values } = form.getState();

  useEffect(
    () => {
      if (item.name) {
        form.initialize(item);
      }
    },
    [item.id],
  );

  useEffect(() => {
    if (!values.participantConferenceId) {
      if (values.groupName) {
        form.change('groupName', null);
        form.change('groupPath', null);
      }
      if (values.isFooterHidden) {
        form.change('isFooterHidden', false);
      }
    }
  }, [values.participantConferenceId]);

  useEffect(() => {
    if (!values.groupName) {
      form.change('groupPath', null);
    } else {
      form.change('groupPath', toSlug(values.groupName.replaceAll('\'', '') || ''));
    }
  }, [values.groupName]);

  useFormReinitializeAfterSubmit(() => {
    if (isUpdateSucceeded) {
      form.restart(item);
    }
  }, isUpdating);

  const getLabelWithExplainer = (label: string, tooltipContent: string) => (
    <>
      <span>{ label }</span>
      <span className={ styles.inputQuestionIconContainer }>
        <Tooltip content={ tooltipContent }>
          <Icon type={ IconType.question } />
        </Tooltip>
      </span>
    </>
  );

  return (
    <>
      <Field
        component={ Input }
        className={ layoutStyles.inputField }
        name="name"
        label="Call Name"
        placeholder="eg Acme Global Investor Call"
        dataTest="evercallProfileFormCallNameInput"
      />
      <div className={ styles.conferenceInputsContainer }>
        <Field
          name="participantConferenceId"
          component={ Input }
          isNarrow
          className={ styles.conferenceInputField }
          label={ getLabelWithExplainer(PARTICIPANT_CONFERENCE_ID_LABEL, PARTICIPANT_CONFERENCE_ID_FIELD_TOOLTIP) }
          placeholder="12345"
          dataTest="evercallProfileFormParticipantConferenceIdInput"
        />
        <div className={ styles.linkIconContainer }>
          <Icon
            className={ styles.linkIcon }
            type={ IconType.link }
          />
        </div>
        <Field
          name="groupName"
          render={ ({ input, meta }) => (
            <div className={ styles.groupNameInputContainer }>
              <Input
                isNarrow
                className={ styles.conferenceInputField }
                name={ input.name }
                label="Group Name (Optional)"
                placeholder="Enter group name"
                dataTest="evercallProfileFormGroupNameInput"
                disabled={ !values.participantConferenceId }
                meta={ meta }
                input={ input }
              />
              { values.participantConferenceId && values.groupName && (
                <div className={ styles.urlLabel }>
                  { evercallUrl.getUrl(`/events/${ values.groupPath }`) }
                </div>
              ) }
            </div>
          ) }
        />
        <Field
          isNarrow
          className={ styles.removeFooterCheckbox }
          name="isFooterHidden"
          dataTest="isFooterHidden"
          component={ CheckboxInput }
          disabled={ !values.participantConferenceId }
          type="checkbox"
          label={ (
            <>
              <span
                className={ cn({ [styles.footerHiddenCheckboxLabelDisabled]: !values.participantConferenceId }) }
              >
                Remove footer text
              </span>
              <Tooltip
                wrpClassName={ styles.explanationLabelIconContainer }
                content={ REMOVE_FOOTER_TEXT_TOOLTIP_CONTENT }
              >
                <Icon type={ IconType.question } />
              </Tooltip>
            </>
          ) }
        />
      </div>
      <div className={ styles.roadshowInputsContainer }>
        <Field
          component={ Input }
          formFieldClassName={ styles.roadshowInputFormField }
          isNarrow
          name="roadshowId"
          label={ getLabelWithExplainer('Roadshow ID', 'Link to display dial-ins in Roadshow') }
          placeholder="d38f43d38f43d38f43d38f"
          dataTest="roadshowId"
        />
        <Field
          component={ Input }
          formFieldClassName={ styles.roadshowInputFormField }
          isNarrow
          name="roadshowEntryCode"
          label="Entry Code"
          placeholder="Entry Code linked in event registration form"
          dataTest="roadshowEntryCodeInput"
        />
      </div>
    </>
  );
};

export default SettingsFormFields;
