import { connect } from 'react-redux';

import AccountMappings from './AccountMappings';

import allocationSelectors from '@/condor/application/actions/accounts/mappings/allocations/selectors';
import evercallsSelectors from '@/condor/application/actions/accounts/mappings/evercalls/selectors';
import notesSelectors from '@/condor/application/actions/accounts/mappings/notes/selectors';
import contactsSelectors from '@/condor/application/actions/accounts/mappings/contacts/selectors';
import dealsSelectors from '@/condor/application/actions/accounts/mappings/deals/selectors';
import leisSelectors from '@/condor/application/actions/accounts/mappings/leis/selectors';
import webDomainsSelectors from '@/condor/application/actions/accounts/mappings/webDomains/selectors';
import emailDomainsSelectors from '@/condor/application/actions/accounts/mappings/emailDomains/selectors';
import { openCondorAccountMappingModal } from '@/condor/application/actions/accounts/mappings/metadata/actions';

const mapStateToProps = (state) => (
  {
    allocations: allocationSelectors.getCollectionData(state),
    evercalls: evercallsSelectors.getCollectionData(state),
    notes: notesSelectors.getCollectionData(state),
    contacts: contactsSelectors.getCollectionData(state),
    deals: dealsSelectors.getCollectionData(state),
    leis: leisSelectors.getCollectionData(state),
    webDomains: webDomainsSelectors.getCollectionData(state),
    emailDomains: emailDomainsSelectors.getCollectionData(state),
  }
);

const mapDispatchToProps = {
  onRemap: openCondorAccountMappingModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountMappings);
