import React from 'react';
import { format } from 'date-fns';
import { MONTH_UNARY_DAY_YEAR_DATE_FULLTIME_FORMAT } from '@/Framework/DateTime/dateFormats';
import { Tooltip } from '@dealroadshow/uikit';
import { getCellDisplayDate } from './helpers';
import { IDatetime } from '@/Framework/DateTime/Datetime';
import utcToZonedDate from '@/Framework/DateTime/utcToZonedDate';

interface IProps {
  date: IDatetime,
}

const LoginAt = ({ date }: IProps) => {
  if (date?.dateTime) {
    const defaultDate = getCellDisplayDate(date);
    const fullDate = format(
        utcToZonedDate(date.timezone.timeZone, new Date(date.dateTime)),
        MONTH_UNARY_DAY_YEAR_DATE_FULLTIME_FORMAT,
    );
    return (
      <Tooltip content={ fullDate }>
        { defaultDate }
      </Tooltip>
    );
  }
  return null;
};

export default LoginAt;
