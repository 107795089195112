import get from 'lodash/get';
import { getCollectionSelectors } from '@/Framework/State/Redux/collectionFactory';
import actionTypes from './actionTypes';

export default (isAdmin = false) => {
  const pathToReducerState = isAdmin ?
    'dealRoadshows.roadshow.auditTrail.collection' :
    'roadshow.auditTrail.collection';
  return {
    getReleaseDate: (state) => get(state, `${ pathToReducerState }.releaseDate`, []),
    ...getCollectionSelectors(pathToReducerState, actionTypes),
  };
};
