import createAction from '@/Framework/State/Redux/createAction';
import TransactionTypeRepository from '@/dmPortal/infrastructure/repository/TransactionTypeRepository';
import actionTypesFactory from './actionTypes';

/**
 * @param {string} tenant
 * @param {?Object} clearCache
 *
 * @return {function(...[*]=)}
 */
export function getTransactionTypes(tenant, { clearCache = false } = {}) {
  return async (dispatch, getState) => {
    dispatch(createAction(actionTypesFactory(tenant).GET_TRANSACTION_TYPES));
    try {
      const transactionTypeRepository = getState().container.get(TransactionTypeRepository);
      const response = await transactionTypeRepository.getTransactionTypeList(tenant, { clearCache });
      dispatch(createAction(actionTypesFactory(tenant).GET_TRANSACTION_TYPES_SUCCESS, response));
    } catch (err) {
      dispatch(createAction(actionTypesFactory(tenant).GET_TRANSACTION_TYPES_ERROR, err));
    }
  };
}
