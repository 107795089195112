import React, { memo } from 'react';
import cn from 'classnames';
import { Icon, IconType, Spinner, Tooltip } from '@dealroadshow/uikit';

import styles from './exportButtonWithLoader.scss';

interface IProps {
  onClick: () => void,
  dataTest?: string,
  tooltipContent?: string,
  disabledTooltipContent?: string,
  className?: string,
  iconClassName?: string,
  tooltipContainerClassName?: string,
  spinnerClassName?: string,
  isVisible?: boolean,
  isDisabled?: boolean,
  isFetching?: boolean,
  isTooltipDisabled?: boolean,
  customLoaderComponent?: React.ReactNode,
}

const ExportButtonWithLoader = ({
  className: wrapperClass,
  isVisible = true,
  isDisabled = true,
  isFetching,
  onClick,
  tooltipContent = 'Export to Excel',
  disabledTooltipContent = 'Export to Excel not available',
  isTooltipDisabled = false,
  iconClassName,
  spinnerClassName,
  tooltipContainerClassName,
  dataTest = 'exportButtonWithLoader',
  customLoaderComponent,
}: IProps) => {
  if (!isVisible) {
    return null;
  }

  if (isFetching) {
    return customLoaderComponent || (
      <Spinner
        className={ cn(wrapperClass, styles.spinner, spinnerClassName) }
        size="small"
      />
    );
  }

  const exportIconClass = cn(styles.icon, iconClassName, {
    [styles.isDisabled]: isDisabled,
  });

  return (
    <button
      type="button"
      className={ cn(wrapperClass, styles.exportButton) }
      onClick={ onClick }
      disabled={ isDisabled }
      data-test={ dataTest }
    >
      <Tooltip
        disabled={ isTooltipDisabled }
        content={ isDisabled ? disabledTooltipContent : tooltipContent }
        containerClassName={ tooltipContainerClassName }
      >
        <Icon
          type={ IconType.fileXls }
          className={ exportIconClass }
        />
      </Tooltip>
    </button>
  );
};

export default memo(ExportButtonWithLoader);
