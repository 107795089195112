const filtersSelector = (state) => state.finsight.bonds.filters;
const getItemsFilter = (state) => filtersSelector(state).items?.filter || {};

export default {
  getDateFromMin: (state) => filtersSelector(state).dateFromMin,
  getDateToMax: (state) => filtersSelector(state).dateToMax,
  getFiltersPayload: (state) => filtersSelector(state).filtersPayload,
  getInitialFilters: (state) => filtersSelector(state).initialFilters,
  getAppliedFilters: (state) => filtersSelector(state).appliedFilters,
  getItemsFilter,
  getItemsFilterByFilterName: (state, filterName) => getItemsFilter(state)[filterName],
  isFetching: (state) => filtersSelector(state).isFetching,
};
