import { connect } from 'react-redux';
import { hideModal } from '@/condor/application/actions/finsight/filings/collection/actions';
import selectors from '@/condor/application/actions/finsight/filings/collection/selectors';

import FilingModal from './FilingModal';

const mapStateToProps = (state) => (
  {
    isModalVisible: selectors.getIsModalVisible(state),
    isDisableEmailModalVisible: selectors.getIsDisableEmailModalVisible(state),
    isResendEmailModalVisible: selectors.getIsResendEmailModalVisible(state),
    isSendEmailModalVisible: selectors.getIsSendEmailModalVisible(state),
    selectedFiling: selectors.getSelectedFiling(state),
  }
);

const mapDispatchToProps = {
  hideModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FilingModal);
