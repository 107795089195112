import React, { useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';
import { CONDOR_FINSIGHT_COMPANIES_LIST_BASE_URL } from '@/condor/ui/common/constants';
import Button, { variantTypes } from '@/ui/shared/components/Button';
import validateOnSubmit from '@/condor/ui/components/Finsight/DealProfile/validateOnSubmit';
import { NotificationManager } from '@/ui/shared/components/Notification';
import { getMessage } from '@/Framework/Message/Mapper/getMessage';
import styles from './dealProfileButtons.scss';

interface IProps {
  isSecFiling: boolean,
  isCreateNewDeal: boolean,
  currentDealPrimaryIssuerId: string,
  initialIssuerId: string,
  filingType: string,
  submitDisabled: boolean,
  formValues: any,
  openDeleteModal: () => void,
  openSubmitModal: () => void,
  unmapFiling: () => void,
  openIssuerDealsPage: (id: string) => void,
  openFilingsPage: (type: string) => void,
}

const DealProfileButtons = (
  {
    isSecFiling,
    isCreateNewDeal,
    currentDealPrimaryIssuerId,
    initialIssuerId,
    filingType,
    submitDisabled,
    formValues,
    openDeleteModal,
    openSubmitModal,
    unmapFiling,
    openIssuerDealsPage,
    openFilingsPage,
  }: IProps,
) => {
  const { push } = useHistory();

  const openCompaniesPage = () => push(CONDOR_FINSIGHT_COMPANIES_LIST_BASE_URL);

  const onCancelClick = useCallback(
    () => {
      if (isSecFiling) {
        openFilingsPage(filingType);
        return;
      }
      if (isCreateNewDeal) {
        if (initialIssuerId) {
          openIssuerDealsPage(initialIssuerId);
        } else {
          openCompaniesPage();
        }
      } else {
        openIssuerDealsPage(currentDealPrimaryIssuerId);
      }
    },
    [currentDealPrimaryIssuerId, initialIssuerId, isCreateNewDeal, isSecFiling],
  );

  return (
    <>
      <Button
        variant={ variantTypes.action }
        className={ styles.saveBtn }
        title="Save"
        disabled={ submitDisabled }
        dataTest="dealProfileSaveButton"
        onClick={ () => {
          const errors = validateOnSubmit(formValues);

          if (!isEmpty(errors)) {
            errors.forEach(({ text, data }) => {
              NotificationManager.error(getMessage(text, data));
            });
            return;
          }

          openSubmitModal();
        } }
      />
      <Button
        variant={ variantTypes.text }
        title="Cancel"
        onClick={ onCancelClick }
        dataTest="dealProfileSaveButton"
      />
      { !isSecFiling && !isCreateNewDeal && (
        <Button
          variant={ variantTypes.text }
          title="Delete Deal"
          className={ styles.deleteBtn }
          onClick={ openDeleteModal }
          dataTest="dealProfileDeleteButton"
        />
      ) }
      { isSecFiling && !isCreateNewDeal && (
        <Button
          variant={ variantTypes.text }
          title="Unmap Deal"
          className={ styles.deleteBtn }
          onClick={ unmapFiling }
          dataTest="dealProfileUnmapDealButton"
        />
      ) }
    </>
  );
};

export default DealProfileButtons;
