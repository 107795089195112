import React, { useState, useEffect } from 'react';
import { CheckboxInput } from '@dealroadshow/uikit';
import dataroomUrl from '@/dataroom/infrastructure/dataroomUrl';
import ConsentContextProvider, { useConsentContext } from '@/dataroom/application/ConsentContext';
import config from '@/dataroom/application/config/config';

import styles from './consentCheckbox.scss';

interface IProps {
  change?: (field: string, value: boolean) => void,
}

const ConsentCheckbox = (props: IProps) => {
  const { change, ...otherProps } = props;
  const { isConsentAccepted, isAcceptConsentFetching } = useConsentContext();
  const [isChecked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(isConsentAccepted);
    change('consentState', isConsentAccepted);
  }, [isConsentAccepted]);

  const handleChangeValue = () => {
    setChecked(!isChecked);
    change('consentState', !isChecked);
  };

  const label = (
    <>
      Consent to Electronic Delivery on VeriSend (
      <a
        href={ dataroomUrl(config.tenant.tenantVeriSend.code).getUrl() }
        target="_blank"
        data-test="profileViewVeriSendLink"
      >
        Learn More
      </a>
      )
    </>
  );

  return (
    <CheckboxInput
      { ...otherProps }
      className={ styles.checkbox }
      // @ts-ignore
      controlClassName={ styles.control }
      labelClassName={ styles.label }
      label={ label }
      dataTest="twoFactorLoginEnabled"
      input={ {
        checked: isChecked,
        onChange: handleChangeValue,
        disabled: isAcceptConsentFetching,
      } }
    />
  );
};

export default ({ contactId = null, ...restProps }) => (
  <ConsentContextProvider tenant={ config.tenant.tenantVeriSend.code } contactId={ contactId }>
    <ConsentCheckbox { ...restProps } />
  </ConsentContextProvider>
);
