import isIE from '@/Framework/browser/isIE';
import { CONTENT_WRP_ID } from '@/ui/shared/components/Layout/ContentWrp/constants';
import getOffset from '@/ui/shared/helpers/getOffset';

const HEADER_OFFSET_TOP = 100;

/**
 * @param {Object} errors
 * @param {Node|Null} panelGroup
 * @param {Object} mapFieldToGroup
 */
export const focusFieldWithError = (errors, panelGroup, mapFieldToGroup) => {
  const errorInGroup = panelGroup.getItems().reduce((acc, item) => (
    { ...acc, [item]: false }
  ), {});

  let needToFocusField = [];
  /**
   * @param {Array} pathToElementName
   */
  const addElementToError = (pathToElementName) => {
    let { pathType } = mapFieldToGroup[pathToElementName[0]];
    let name = '';
    if (pathToElementName.length === 1) {
      // eslint-disable-next-line prefer-destructuring
      name = pathToElementName[0];
    } else if (pathToElementName.length === 2 || pathType === 'object') {
      name = pathToElementName.join('.');
    } else if (pathType === 'section' && pathToElementName.length > 2) {
      name = `${ pathToElementName[0] }.${ pathToElementName[1] }[${ pathToElementName.slice(2, pathToElementName.length - 1).join('][') }].${ pathToElementName[pathToElementName.length - 1] }`;
    } else {
      name = `${ pathToElementName[0] }[${ pathToElementName.slice(1, pathToElementName.length - 1).join('][') }].${ pathToElementName[pathToElementName.length - 1] }`;
    }

    if (document.getElementsByName(name)[0]) {
      needToFocusField.push(name);
      let { group } = mapFieldToGroup[pathToElementName[0]];
      errorInGroup[group] = true;
    }
  };
  /**
   * @param {Object} errors
   * @param {Array} pathToElementName
   */
  const eachError = (errors, pathToElementName) => Object.keys(errors).forEach((fieldName) => {
    if (fieldName !== '_error') {
      if (typeof errors[fieldName] === 'object') {
        eachError(errors[fieldName], pathToElementName.concat(fieldName));
      } else {
        addElementToError(pathToElementName.concat(fieldName));
      }
    }
  });
  eachError(errors, []);

  let statusPanelGroup = panelGroup.getActiveKeys();
  let needToOpen = getTabsForOpen(errorInGroup, statusPanelGroup);
  if (needToOpen.length) {
    panelGroup.setActiveKey(needToOpen);

    setTimeout(() => {
      let isErrorShowed = false;
      needToFocusField.forEach((inputError) => {
        if (!isErrorShowed
            && inputError
            && document.getElementsByName(inputError)[0]
            && !document.getElementsByName(inputError)[0].disabled
        ) {
          isErrorShowed = true;
          const elem = document.getElementsByName(inputError)[0];
          if (elem.type === 'radio') {
            let elemToScroll = elem;
            while (
              elemToScroll &&
              elemToScroll.nodeName !== 'FORM' &&
              !elemToScroll.parentElement.className.includes('panelGroup')
            ) {
              elemToScroll = elemToScroll.parentElement;
            }
            elemToScroll.scrollIntoView();
          } else {
            elem.focus();
          }

          if (isIE() || navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
            const offset = getOffset(elem, document.getElementById(CONTENT_WRP_ID));
            setTimeout(() => document.getElementById(CONTENT_WRP_ID).scrollTo(0, offset.top - HEADER_OFFSET_TOP), 100);
          }
        }
      });
    }, 100);
  }
};

/**
 * @param {Node|Null} panelGroup
 * @param {Object} errorInGroup
 * @param {Array} needToFocusField
 */
export const focusUploadFieldWithError = (panelGroup, errorInGroup, needToFocusField) => {
  let statusPanelGroup = panelGroup.getActiveKeys();
  let needToOpen = getTabsForOpen(errorInGroup, statusPanelGroup);

  if (needToOpen.length && needToFocusField.length) {
    panelGroup.setActiveKey(needToOpen);

    setTimeout(() => {
      if (needToFocusField[0] && document.getElementsByClassName(needToFocusField[0])[0]) {
        document
          .getElementById(CONTENT_WRP_ID)
          .scrollTo(0, document.getElementsByClassName(needToFocusField[0])[0].offsetTop - HEADER_OFFSET_TOP);
      }
    }, 100);
  }
};

/**
 *
 * @param {{String:Boolean}} errorInGroup
 * @param {Array} panelGroupOpenTab
 * @return {[]}
 */
const getTabsForOpen = (errorInGroup, panelGroupOpenTab) => {
  let needToOpen = [].concat(panelGroupOpenTab);
  Object.keys(errorInGroup).forEach((group) => {
    if (errorInGroup[group]) {
      if (!isOpenTab(panelGroupOpenTab, group)) {
        const index = needToOpen.indexOf(group);
        if (index === -1) {
          needToOpen.push(group);
        }
      }
    }
  });
  return needToOpen;
};

const isOpenTab = (tabs, tabName) => {
  let open = false;
  tabs.forEach((tab) => {
    if (tab === tabName) {
      open = true;
    }
  });
  return open;
};

export const focusSection = (panelGroup, sectionName) => {
  const openedPanels = panelGroup.getActiveKeys();

  if (!openedPanels.includes(sectionName)) {
    panelGroup.setActiveKey([...openedPanels, sectionName]);
  }

  const offset = getOffset(
    document.getElementById(sectionName),
    document.getElementById(CONTENT_WRP_ID),
  );

  document.getElementById(CONTENT_WRP_ID).scrollTo({
    top: offset.top - HEADER_OFFSET_TOP,
    left: 0,
    behavior: 'smooth',
  });
};
