import React from 'react';
import { Switch } from 'react-router';
import URL from '@/finsight/Router/urlConstants';
import Inventory from './Inventory';
import Bwic from './Bwic';
import Route from '@/ui/shared/components/ReactRouter/Route';
import { IColumn } from '@dealroadshow/uikit';

interface IProps {
  baseUrl?: string,
  tableFooterLabel: React.ReactNode,
  bwicRow: React.ReactNode,
  inventoryRow: React.ReactNode,
  getBwicHeaderColumns: () => IColumn[],
  getInventoryHeaderColumns: ({ isNew }: { isNew: boolean }) => IColumn[],
}

const BondTables = ({
  baseUrl = '',
  tableFooterLabel,
  bwicRow,
  inventoryRow,
  getBwicHeaderColumns,
  getInventoryHeaderColumns,
}: IProps) => (
  <Switch>
    <Route
      path={ `${ baseUrl }${ URL.BONDS_INVENTORIES }-:assetClass` }
      render={ () => (
        <Inventory
          tableFooterLabel={ tableFooterLabel }
          getHeaderColumns={ getInventoryHeaderColumns }
          inventoryRow={ inventoryRow }
        />
      ) }
    />
    <Route
      path={ `${ baseUrl }${ URL.BONDS_BWICS }-:assetClass` }
      render={ () => (
        <Bwic
          tableFooterLabel={ tableFooterLabel }
          getHeaderColumns={ getBwicHeaderColumns }
          bwicRow={ bwicRow }
        />
      ) }
    />
  </Switch>
);

export default BondTables;
