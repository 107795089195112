const getUpload = (state) => state.condor.finsight.import.deal.upload;

export default {
  getUploadProcess: (state) => getUpload(state).process,
  getUploadFileProcessId: (state) => getUpload(state).process.uuid,
  getUploadFilePath: (state) => getUpload(state).file.path,
  getUploadFileName: (state) => getUpload(state).name,
  isUploaded: (state) => getUpload(state).uploaded,
  getUploadFileCancelCallback: (state) => getUpload(state).callback,
};
