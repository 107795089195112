/**
 *
 * @param {Object} filers
 * @param {String} cik
 * @returns {String}
 */
const getIssuerNameEe = (filers, cik) => {
  const name = filers.filter((filer) => filer['COMPANY-DATA'].CIK === cik)[0]['COMPANY-DATA']['CONFORMED-NAME'];
  return name || '';
};

/**
 *
 * @param {Object} filing
 * @returns {String}
 */
const getIssuerName15g = (filing) => filing.data['SEC-HEADER']['ISSUING-ENTITY-NAME'] ||
                                      filing.data['SEC-HEADER'].FILER['COMPANY-DATA']['CONFORMED-NAME'];

/**
 *
 * @param {Object} filing
 * @returns {String}
 */
export const getSecIssuerName = (filing) => {
  switch (filing.type) {
    case 'ABS-EE':
    case 'ABS-EE/A':
      let filers = filing.data['SEC-HEADER'].FILER;
      filers = Array.isArray(filers) ? filers : [filers];
      return getIssuerNameEe(filers, filing.filerCik);
    case 'ABS-15G':
    case 'ABS-15G/A':
      return getIssuerName15g(filing);
    default:
      return getIssuerName15g(filing);
  }
};
