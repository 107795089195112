import { connect, batch } from 'react-redux';
import {
  getApplicationBlacklistByFilter,

  setCurrentApplicationBlacklistItem,
  deleteApplicationBlacklistItem,
  setApplicationBlacklistIsDeleteModalVisible,
} from '@/condor/application/actions/blacklists/application/actions';
import selectors from '@/condor/application/actions/blacklists/application/selectors';
import DeleteModal from '@/condor/ui/common/Blacklist/DeleteModal';

const mapStateToProps = (state) => (
  {
    item: selectors.getItemData(state),
    isVisible: selectors.isDeleteModalVisible(state),
  }
);

const mapDispatchToProps = (dispatch) => {
  const onCancel = () => batch(() => {
    dispatch(setApplicationBlacklistIsDeleteModalVisible(false));
    dispatch(setCurrentApplicationBlacklistItem({}));
  });

  return {
    onDeleteIconClick: (cellProps) => batch(() => {
      dispatch(setApplicationBlacklistIsDeleteModalVisible(true));
      dispatch(setCurrentApplicationBlacklistItem(cellProps.row));
    }),
    onCancel,
    onDelete: async (item) => {
      await dispatch(deleteApplicationBlacklistItem(item));
      onCancel();
      dispatch(getApplicationBlacklistByFilter());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteModal);
