import { groupValidators } from '@/ui/shared/validators/helpers';
import { validateRequiredField } from '@/ui/shared/validators/validateRequiredField';
import { validateMinLength } from '@/ui/shared/validators/validateMinLength';
import { validateMaxLength } from '@/ui/shared/validators/validateMaxLength';
import { validateNumberMaxMinAmount } from '@/ui/shared/validators/validateNumberMaxMinAmount';

import { FieldsLabel, FieldsNames } from './constants';
import { IFormValues } from '@/openMarket/ui/components/Condor/Events/EventForm/interfaces';

export default (values: IFormValues) => groupValidators(
  // LINK TO ROADSHOW
  validateRequiredField({
    fieldName: FieldsNames.ROADSHOW_LINK,
    fieldCaption: FieldsLabel.ROADSHOW_LINK,
    value: values[FieldsNames.ROADSHOW_ID],
  }),
  // EVENT NAME
  validateRequiredField({
    fieldName: FieldsNames.NAME,
    fieldCaption: FieldsLabel.NAME,
    value: values[FieldsNames.NAME],
  }),
  validateMinLength({
    fieldName: FieldsNames.NAME,
    fieldCaption: FieldsLabel.NAME,
    value: values[FieldsNames.NAME],
    length: 3,
  }),
  validateMaxLength({
    fieldName: FieldsNames.NAME,
    fieldCaption: FieldsLabel.NAME,
    value: values[FieldsNames.NAME],
    length: 128,
  }),
  // DESCRIPTION
  validateRequiredField({
    fieldName: FieldsNames.DESCRIPTION,
    fieldCaption: FieldsLabel.DESCRIPTION,
    value: values[FieldsNames.DESCRIPTION],
  }),
  validateMinLength({
    fieldName: FieldsNames.DESCRIPTION,
    fieldCaption: FieldsLabel.DESCRIPTION,
    value: values[FieldsNames.DESCRIPTION],
    length: 3,
  }),
  // TENOR
  validateNumberMaxMinAmount({
    fieldName: FieldsNames.TENOR,
    fieldCaption: FieldsLabel.TENOR,
    value: Number(values[FieldsNames.TENOR]),
    minAmount: 0,
    maxAmount: 999999.999,
  }),
  // EQUITY TICKER
  validateMinLength({
    fieldName: FieldsNames.EQUITY_TICKER,
    fieldCaption: FieldsLabel.EQUITY_TICKER,
    value: values[FieldsNames.EQUITY_TICKER],
    length: 3,
  }),
  validateMaxLength({
    fieldName: FieldsNames.EQUITY_TICKER,
    fieldCaption: FieldsLabel.EQUITY_TICKER,
    value: values[FieldsNames.EQUITY_TICKER],
    length: 128,
  }),
);
