import { connect } from 'react-redux';
import {
  resetCondorFinsightCompanyDealItem,
} from '@/condor/application/actions/finsight/company/deals/item/actions';
import selectors from '@/condor/application/actions/finsight/company/deals/item/selectors';
import DealsList from './DealsList';

import { initDealsList } from '@/condor/application/actions/finsight/company/deals/collection/actions';

const mapStateToProps = (state) => ({
  isDeleteModalVisible: selectors.isDeleteModalVisible(state),
  currentItem: selectors.getItemData(state),
});

const mapDispatchToProps = {
  init: initDealsList,
  reset: resetCondorFinsightCompanyDealItem,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DealsList);
