import { createContext, useContext, ReactNode } from 'react';
import { useEventForm } from './useEventForm';

type EventFormContextType = ReturnType<typeof useEventForm>;

export const EventFormContext = createContext<EventFormContextType>(null);

export const useEventFormContext = () => {
  const context = useContext(EventFormContext);

  if (!context) {
    throw new Error('useEventFormContext must be used within the EventFormContext');
  }

  return context;
};

interface IProps {
  children: ReactNode,
  eventId?: string,
  initProfile?: {
    id: string,
    name: string,
  },
}

const EventFormContextProvider = ({ children, eventId, initProfile }: IProps) => (
  <EventFormContext.Provider value={ useEventForm({ eventId, initProfile }) }>
    { children }
  </EventFormContext.Provider>
);

export default EventFormContextProvider;
