import React from 'react';
import { OTHER_SECTOR } from '@/finsight/domain/Sector';
import TableSectorTooltip from './TableSectorTooltip';

const SectorCell = ({ row }) => {
  const sector = row?.deal?.sector || row?.sector || OTHER_SECTOR;
  const subsectorName = row?.deal?.subsector?.name;
  const tooltip = subsectorName ? `${ sector.name }: ${ subsectorName }` : sector.name;

  return (
    <TableSectorTooltip sector={ sector } tooltip={ tooltip } />
  );
};

export default SectorCell;
