import { Dependencies } from 'constitute';
import Request from '@/Framework/api/Rpc/Request';
import CondorRpcDispatcher from '@/condor/application/DI/Rpc/HttpDispatcher';
import DmPortalRpcDispatcher from '@/dmPortal/application/DI/Rpc/HttpDispatcher';
import SocketClient from '@/condor/application/DI/Socket/Client';
import { INotesPayload } from '@/condor/domain/vo/Accounts/AccountMappings/Notes';

@Dependencies(CondorRpcDispatcher, DmPortalRpcDispatcher, SocketClient)
class MappingsRepository {
  private condorRpc: any;

  private dmPortalRpc: any;

  private socket: any;

  private remappingSubscription: any;

  /**
   *
   * @param {Dispatcher} condorRpcDispatcher
   * @param {Dispatcher} DmPortalRpcDispatcher
   * @param {Dispatcher} SocketClient
   */
  constructor(condorRpcDispatcher, DmPortalRpcDispatcher, SocketClient) {
    this.condorRpc = condorRpcDispatcher;
    this.dmPortalRpc = DmPortalRpcDispatcher;
    this.socket = SocketClient;
    this.remappingSubscription = null;
  }

  /**
   * @param {object} payload
   * @returns {Promise<object>}
   */
  getDealsMappings = async (payload) => {
    const req = new Request('condor.analytics.account.get_deals', payload);
    const response = await this.condorRpc.call(req);

    return response.getResult().payload;
  };

  /**
   * @param {object} payload
   * @returns {Promise<object>}
   */
  getAllocationsMappings = async (payload) => {
    const req = new Request('allocate.deal_allocation.get_related', payload);
    const response = await this.dmPortalRpc.call(req);

    return response.getResult().payload;
  };

  /**
   * @param {object} payload
   * @returns {Promise<object>}
   */
  getEvercallsMappings = async (payload) => {
    const req = new Request('evercall.admin.get_call_account_relations', payload);
    const response = await this.dmPortalRpc.call(req);

    return response.getResult().payload;
  };

  getNotesMappings = async (payload: INotesPayload) => {
    const req = new Request('condor.account.get_note_stats', payload);
    const response = await this.condorRpc.call(req);

    return response.getResult().payload;
  };

  /**
   * Subscribe to remap process WS
   * @param {object} payload
   */
  subscribeToRemapProcess = async (payload) => {
    const subscribeReq = new Request('condor.socket.account.migration', {
      fromAccountId: parseInt(payload.fromAccountId),
      toAccountId: payload.toAccountId,
    });
    const callbackWrapper = (callback) => ({ params: { payload } }) => callback(payload);
    const finishCallbackWrapper = (callback) => ({ params: { payload } }) => {
      this.remappingSubscription.cancel();
      return callback(payload);
    };

    this.remappingSubscription = await this.socket.subscribe(subscribeReq);
    this.remappingSubscription.on('migration.contacts', callbackWrapper(payload.onMessage));
    this.remappingSubscription.on('migration.allocations', callbackWrapper(payload.onMessage));
    this.remappingSubscription.on('migration.evercalls', callbackWrapper(payload.onMessage));
    this.remappingSubscription.on('migration.notes', callbackWrapper(payload.onMessage));
    this.remappingSubscription.on('migration.leis', callbackWrapper(payload.onMessage));
    this.remappingSubscription.on('migration.emailDomains', callbackWrapper(payload.onMessage));
    this.remappingSubscription.on('migration.webDomains', callbackWrapper(payload.onMessage));
    this.remappingSubscription.on('migration.deals.17g5', callbackWrapper(payload.onMessage));
    this.remappingSubscription.on('migration.deals.dealvdr', callbackWrapper(payload.onMessage));
    this.remappingSubscription.on('migration.deals.investorset', callbackWrapper(payload.onMessage));
    this.remappingSubscription.on('migration.deals.verisend', callbackWrapper(payload.onMessage));
    this.remappingSubscription.on('migration.deals.dealroadshow', callbackWrapper(payload.onMessage));
    this.remappingSubscription.on('migration.deals.allocate', callbackWrapper(payload.onMessage));
    this.remappingSubscription.on('migration.deals.evercall', callbackWrapper(payload.onMessage));
    this.remappingSubscription.on('migration.finished', finishCallbackWrapper(payload.onFinish));
  };
}

export default MappingsRepository;
