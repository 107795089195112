import React, { useEffect } from 'react';

import { DataTable, dataTableStyles, Spinner } from '@dealroadshow/uikit';
import DeleteImportModal from './DeleteImportModal';
import TableWrapper from '@/ui/shared/components/Tables/TableWrapper';
import Paginator from './AuditTrailPaginatorContainer';
import columns from './columns';

import cardStyles from '@/ui/shared/styles/card.scss';

interface IProps {
  init: () => void,
  reset: () => void,
}

const AuditTrail = (
  {
    init,
    reset,
    ...otherProps
  }: IProps,
) => {
  useEffect(() => {
    init();
    return reset;
  }, []);

  return (
    <TableWrapper>
      <DeleteImportModal />
      <DataTable
        loadingComponent={ Spinner }
        className={ dataTableStyles.isHoverable }
        dataTest="dealAuditTrailDataTable"
        columns={ columns }
        { ...otherProps }
      />
      <Paginator
        className={ cardStyles.cardInner }
        dataTest="dealAuditTrail"
      />
    </TableWrapper>
  );
};

export default AuditTrail;
