import { getItemSelectors } from '@/Framework/State/Redux/itemFactory';
import actionTypes from './actionTypes';

export default {
  ...getItemSelectors('condor.finsight.company.item', actionTypes),
  isInlineEditing: (state) => state.condor.finsight.company.item.isInlineEditing,
  isDeleteModalVisible: (state) => state.condor.finsight.company.item.isDeleteModalVisible,
  isMigrateModalVisible: (state) => state.condor.finsight.company.item.isMigrateModalVisible,
  isInformationForMigrationFetching: (state) => state.condor.finsight.company.item.isInformationForMigrationFetching,
  isDealsMigrating: (state) => state.condor.finsight.company.item.isDealsMigrating,
  getInformationForMigration: (state) => state.condor.finsight.company.item.informationForMigration,
};
