import { validateMaxLength } from '@/ui/shared/validators/validateMaxLength';
import { validateRequiredField } from '@/ui/shared/validators/validateRequiredField';
import { IValidationError } from '@/ui/shared/validators/interfaces/ValidationError';
import { validateMinLength } from '@/ui/shared/validators/validateMinLength';
import { validateRequiredFieldWithArrayValue } from '@/ui/shared/validators/validateRequiredFieldWithArrayValue';
import { IRule } from '@/ui/shared/validators/interfaces/Rule';
import { IFormValues } from '@/openMarket/ui/components/Condor/Profiles/ProfileForm/interfaces';
import { IDisplayTab } from './interfaces';
import { DisplayTabFormFieldName } from './constants';
import { validateEmail } from '@/ui/shared/validators/user/validateEmail';
import { ProfileAccessType } from '@/openMarket/domain/Condor/ProfileAccessType';

const validateUniqueName = ({
  fieldName,
  allFieldsValue,
  allValue,
  value,
  fieldCaption,
}: {
  fieldName: string,
  allFieldsValue: { collection: IDisplayTab[] },
  allValue: IDisplayTab,
  value?: string,
  fieldCaption: string,
}): IValidationError => {
  const comparedValue = value?.trim().toLocaleLowerCase() ?? '';

  const isNameAlreadyUsageForAnotherTab = allFieldsValue.collection
    .filter(({ id, name }) => id !== allValue.id && name)
    .some(({ name }) => name.trim().toLowerCase() === comparedValue);

  if (isNameAlreadyUsageForAnotherTab) {
    return {
      [fieldName]: `${ fieldCaption } should be unique for profile`,
    };
  }

  return null;
};

const validateRequiredName = (params: {
  fieldName: string,
  allValue: IDisplayTab,
  value: string,
  fieldCaption: string,
}) => {
  const {
    allValue: { regions, transactionTypes },
  } = params;

  const isSomeFieldFilled = regions.length > 0 || transactionTypes.length > 0;

  if (isSomeFieldFilled) {
    return validateRequiredField(params);
  }

  return null;
};

const validateRequiredTransactionTypes = (params: {
  fieldName: string,
  allValue: IDisplayTab,
  value: string[],
  fieldCaption: string,
}) => {
  const {
    allValue: { regions, name },
  } = params;

  const isSomeFieldFilled = regions.length > 0 || name?.length > 0;

  if (isSomeFieldFilled) {
    const error = validateRequiredFieldWithArrayValue(params);
    return error;
  }

  return null;
};

export const getRules = (values: IFormValues): IRule[] => [
  {
    fieldName: DisplayTabFormFieldName.NAME,
    fieldCaption: 'Tab name',
    functionValidation: [
      {
        name: validateUniqueName,
        params: {},
      },
      {
        name: validateRequiredName,
        params: {},
      },
      {
        name: validateMaxLength,
        params: { length: 128 },
      },
      {
        name: validateMinLength,
        params: { length: 3 },
      },
    ],
  },
  {
    fieldName: DisplayTabFormFieldName.TRANSACTION_TYPES,
    fieldCaption: 'Transaction Type',
    functionValidation: [
      {
        name: validateRequiredTransactionTypes,
        params: {},
      },
    ],
  },
  {
    fieldName: DisplayTabFormFieldName.EMAIL,
    fieldCaption: 'Email',
    functionValidation: [
      {
        name: validateEmail,
        params: {},
      },
      ...(ProfileAccessType.isLoginWithWhitelist(values.restrictAccess)
        ? [
            {
              name: validateRequiredField,
              params: {},
            },
          ]
        : []),
    ],
  },
];
