import * as validationTypes from '../validationTypes';
import * as validationProcessTypes from '../validationProcessTypes';

/**
 * @param {String} types
 * @param {Object} payload
 */
export function convertValidationTypesToSting(types, payload) {
  switch (types) {
    case validationTypes.VALIDATION_START:
      return 'Preparing file...';
    case validationTypes.VALIDATION_PROCESS:
      switch (payload.data.status) {
        case validationProcessTypes.header:
          return 'Preparing file...';
        case validationProcessTypes.checking:
          return `Validate line - ${ payload.data.data }`;
        case validationProcessTypes.count:
          return `Save item - ${ payload.data.data }`;
        case validationProcessTypes.save:
          return `Save line - ${ payload.data.data }`;
        case validationProcessTypes.message:
          return payload.data.message;
        case validationProcessTypes.rowsInfo:
          let lineCount = Object.keys(payload.data.data).length;
          return `Added new ${ lineCount } line${ lineCount > 1 ? 's' : '' } to validations`;
        case validationProcessTypes.needCorrection:
          return payload.data.message;
        default:
          return undefined;
      }
    case validationTypes.VALIDATION_ERROR:
      return `Unable to validate file - ${ payload.error.message }`;
    case validationTypes.VALIDATION_COMPLETE:
      return '';
    default:
      return 'Preparing file...';
  }
}
