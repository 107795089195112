import React from 'react';
import MapTradeCell from './CellItems/MapTradeCell';
import CusipCell from './CellItems/CusipCell';

import alignStyles from '@/ui/shared/styles/helpers/align.scss';

const columns = [
  {
    name: 'class',
    title: 'CLASS',
    width: 113,
    className: alignStyles.alignLeft,
  },
  {
    name: 'cusips',
    title: 'CUSIP',
    className: alignStyles.alignLeft,
    cellCallback: (cellProps) => <CusipCell { ...cellProps } />,
  },
  {
    name: 'date',
    title: 'PRICING DATE',
    className: alignStyles.alignRight,
    cellCallback: (cellProps) => <MapTradeCell { ...cellProps } />,
  },
];

export default columns;
