const bwicSelector = (state) => state.condor.finsight.import.bond.bwic;

export default {
  getSortBy: (state) => bwicSelector(state).sortBy,
  getSortOrder: (state) => bwicSelector(state).sortOrder,
  getCollectionData: (state) => bwicSelector(state).data,
  isFetching: (state) => bwicSelector(state).isFetching,
  getTotalCount: (state) => bwicSelector(state).totalCount,
  getPage: (state) => bwicSelector(state).page,
  getPerPage: (state) => bwicSelector(state).perPage,
};
