import { IValidationError } from './interfaces/ValidationError';

interface IParams {
  value: string,
  fieldName: string,
  fieldCaption: string,
}

export const validateAum = (params: IParams): IValidationError => {
  if (Number(params?.value?.toString().replace(/[^\d]/g, '')) > 999_999_999_999_999) {
    return { [params.fieldName]: 'Too large amount. Max 999,999,999,999,999' };
  }
  if (params.value && params.value === '0') {
    return { [params.fieldName]: 'Invalid size' };
  }
  return null;
};
