import React, { createContext, useContext } from 'react';

import useDataroomDetails from '@/dataroom/application/condor/useDataroomDetails';
import useEditDataroom from '@/dataroom/application/condor/useEditDataroom';

const useDataroomCondorActions = (dataroomId: number) => ({
  details: useDataroomDetails(dataroomId),
  edit: useEditDataroom(),
});

type DataroomCondorActionsContextType = ReturnType<typeof useDataroomCondorActions>;

export const DataroomCondorActionsContext = createContext<DataroomCondorActionsContextType>(null);

export function useDataroomCondorActionsContext() {
  const context = useContext(DataroomCondorActionsContext);
  if (!context) {
    throw new Error('useDataroomCondorActionsContext must be used within a DataroomCondorActionsContextProvider');
  }
  return context;
}

interface IProps {
  children: React.ReactNode,
  dataroomId: number,
}

const DataroomCondorActionsContextProvider = ({ children, dataroomId }: IProps) => (
  <DataroomCondorActionsContext.Provider value={ useDataroomCondorActions(dataroomId) }>
    { children }
  </DataroomCondorActionsContext.Provider>
);

export default DataroomCondorActionsContextProvider;
