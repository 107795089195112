import { useParams } from 'react-router';
import ProfileForm from '../ProfileForm';

const EditProfile = () => {
  const profileId = useParams().profileId as string;

  return <ProfileForm profileId={ profileId } />;
};

export default EditProfile;
