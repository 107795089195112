import { Dependencies } from 'constitute';
import Request from '@/Framework/api/Rpc/Request';
import DmPortalRpcDispatcher from '@/dmPortal/application/DI/Rpc/HttpDispatcher';

@Dependencies(DmPortalRpcDispatcher)
class AccountTypeRepository {
  private dmPortalRpc: any;

  /**
   * @param {RpcDispatcher} rpc
   */
  constructor(rpc) {
    this.dmPortalRpc = rpc;
  }

  /**
   * @param {Object} payload
   * @return {Object}
   */
  listStats = async (payload = {}) => {
    let request = new Request('admin.analytics.account_type.get_account_types_stats', payload);
    let response = await this.dmPortalRpc.request(request);

    return response.getResult().payload;
  };
}

export default AccountTypeRepository;
