import { Dependencies } from 'constitute';
import Request from '@/Framework/api/Rpc/Request';
import CondorRpcDispatcher from '@/condor/application/DI/Rpc/HttpDispatcher';
import { IEnhancedTaggingFilters } from '@/condor/domain/vo/EnhancedTagging/EnhancedTaggingFilters';

@Dependencies(CondorRpcDispatcher)
class EnhancedTaggingFiltersRepository {
  constructor(private condorRpc: typeof CondorRpcDispatcher) {}

  getFilters = async (): Promise<IEnhancedTaggingFilters> => {
    let req = new Request('condor.enhanced_tagging.get_filters');
    let response = await this.condorRpc.call(req);

    return response.getResult().payload;
  };
}

export default EnhancedTaggingFiltersRepository;
