import { Dependencies } from 'constitute';
import format from 'date-fns/format';
import Request from '@/Framework/api/Rpc/Request';
import SocketDispatcher from '@/dealroadshow/application/DI/Rpc/SocketDispatcher';
import base from '@/Framework/url/baseUrl';
import getUTCDate from '@/Framework/DateTime/getUTCDate';

const DATE_FORMAT_COOKIE = "EEEE, dd-MMM-yyyy HH:mm:ss 'UTC'";

export interface IAnalyticsHeaders {
  ssid: string,
  viewSsid: string,
  fingerprint: string,
  userAgent: string,
  viewerId: string,
}

export interface IAnalyticsPayload {
  entryCode?: string,
  roadshowId: string,
  triggerEvent?: string,
  userId?: number,
  isPublicRoadshow?: boolean,
  viewTime?: number,
  componentId?: string,
  slideId?: string,
  pageNum?: number,
  documentName?: string,
  operationId?: string,
  title?: string,
  actionType?: string,
  isViewerReviewMode: boolean,
}

@Dependencies(SocketDispatcher)
class AnalyticsTrackRepository {
  protected constructor(protected socketRpc: typeof SocketDispatcher) {
    this.socketRpc = socketRpc;
  }

  /**
   * Send analytics event to server
   *
   */
  async track(subjectKey: string, payload: IAnalyticsPayload, headers: IAnalyticsHeaders, isBeacon: boolean = false) {
    const occurredAt = format(getUTCDate(), DATE_FORMAT_COOKIE);
    let request = new Request('analytics.track', {
      subjectKey,
      ...payload,
      occurredAt,
    }, headers);
    if (isBeacon) {
      navigator.sendBeacon(base.getRpcUrl(), JSON.stringify(request));
      return Promise.resolve();
    }
    return this.socketRpc.call(request);
  }

  async trackInvestorLogin(payload: IAnalyticsPayload, headers: IAnalyticsHeaders) {
    return this.track('roadshow.investor.login', payload, headers);
  }

  async trackViewTime(payload: IAnalyticsPayload, headers: IAnalyticsHeaders, isBeacon: boolean) {
    return this.track('roadshow.component.view', payload, headers, isBeacon);
  }

  async trackLinkView(payload: IAnalyticsPayload, headers: IAnalyticsHeaders) {
    return this.track('roadshow.component.view', payload, headers);
  }

  async trackCommercialVideoView(payload: IAnalyticsPayload, headers: IAnalyticsHeaders) {
    return this.track('roadshow.component.view', payload, headers);
  }

  async trackDocumentView(payload: IAnalyticsPayload, headers: IAnalyticsHeaders) {
    return this.track('roadshow.component.view', payload, headers);
  }

  async trackSlideView(payload: IAnalyticsPayload, headers: IAnalyticsHeaders, isBeacon: boolean) {
    return this.track('roadshow.slide.view', payload, headers, isBeacon);
  }

  async trackInvestorAccess(payload: IAnalyticsPayload, headers: IAnalyticsHeaders) {
    return this.track('roadshow.investor.access', payload, headers);
  }

  async trackDealManagerPreview(payload: IAnalyticsPayload, headers: IAnalyticsHeaders) {
    return this.track('roadshow.dealManager.preview', payload, headers);
  }

  async trackCustomModalAction(payload: IAnalyticsPayload, headers: IAnalyticsHeaders) {
    return this.track('roadshow.investor.customModal.option.choose', payload, headers);
  }

  async trackRoadshowDisclaimerDecline(payload: IAnalyticsPayload, headers: IAnalyticsHeaders) {
    return this.track('roadshow.investor.disclaimer.roadshow.disagree', payload, headers);
  }

  async trackDocumentDisclaimerDecline(payload: IAnalyticsPayload, headers: IAnalyticsHeaders) {
    return this.track('roadshow.investor.disclaimer.document.disagree', payload, headers);
  }
}

export default AnalyticsTrackRepository;
