import React from 'react';
import { TRACE_TABLE_CELL_MAP } from '@/condor/ui/components/Finsight/TraceManager/constants';
import { getExpandedCellData } from './CellItems/helpers';

import styles from './traceTable.scss';

export const ExpandableTraceTable = ({ rowData }) => (
  <div className={ styles.expandedContainer }>
    { TRACE_TABLE_CELL_MAP.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
      <div className={ styles.innerTableCol } key={ index }>
        { Object.keys(TRACE_TABLE_CELL_MAP[index]).map((inner) => (
          <div key={ inner }>
            <div>
              { TRACE_TABLE_CELL_MAP[index][inner] }
            </div>
            <div>
              { getExpandedCellData({ row: rowData })[inner] || '-' }
            </div>
            <div className={ styles.separator } />
          </div>
          )) }
      </div>
    )) }
  </div>
);
