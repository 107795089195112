import { useState, useMemo } from 'react';

import { useDIContext } from '@/Framework/DI/DIContext';
import EnhancedTaggingRoadshowsRepository from '@/condor/infrastructure/repository/EnhancedTagging/EnhancedTaggingRoadshowsRepository';
import { useEnhancedTaggingCondorContext } from '@/condor/application/EnhancedTaggingContexts/EnhancedTaggingCondorContext';
import useFetchCollection from '@/Framework/State/useFetchCollection';
import { useEnhancedTaggingFiltersContext } from '../EnhancedTaggingFiltersContext';
import { useEnhancedTaggingRoadshowsUpdate } from './useEnhancedTaggingRoadshowsUpdate';
import { IEnhancedTaggingRoadshow } from '@/condor/domain/vo/EnhancedTagging/Roadshow';

export default function useEnhancedTaggingRoadshows() {
  const roadshowRepository = useDIContext().container.get<EnhancedTaggingRoadshowsRepository>(
    EnhancedTaggingRoadshowsRepository,
  );

  const [checkedRoadshows, setCheckedRoadshows] = useState<string[]>([]);
  const [isBulkModalVisible, setIsBulkModalVisible] = useState(false);
  const [editableRowId, setEditableRowId] = useState<string | null>(null);
  const [isFetchingForm, setIsFetchingForm] = useState(false);

  const { isFetchingCountries, isFetchingCurrencies, isFetchingIndustries, isFetchingTransaction, isFetchingSectors } =
    useEnhancedTaggingCondorContext();

  const { getFiltersForTenant } = useEnhancedTaggingFiltersContext();

  const filters = useMemo(() => getFiltersForTenant('dealroadshow'), [getFiltersForTenant]);

  const {
    isFetching: isFetchingTable,
    collection,
    sortBy,
    sortOrder,
    totalCount,
    page,
    perPage,
    sort,
    paginate,
    setItemsPerPage,
    debouncedSearch,
    modifyCollection: setCollection,
  } = useFetchCollection<IEnhancedTaggingRoadshow>(
    roadshowRepository.getRoadshowList,
    {
      filters,
    },
    {
      sortBy: 'createdAt',
      sortOrder: 'desc',
    },
  );

  const updateCollection = (newCollection: IEnhancedTaggingRoadshow[]) => {
    const newCollectionMap = new Map(newCollection.map((roadshow) => [roadshow.id, roadshow]));
    setCollection((collection) => {
      return collection.map((item) => {
        if (newCollectionMap.has(item.id)) {
          return {
            ...newCollectionMap.get(item.id),
            variation: item.variation + 1,
          };
        }

        return item;
      });
    });
  };

  const { isUpdating, ...restRoadshowsUpdate } = useEnhancedTaggingRoadshowsUpdate({
    updateCollection,
    collection,
  });

  const isFetching =
    isFetchingCountries ||
    isFetchingCurrencies ||
    isFetchingIndustries ||
    isFetchingTransaction ||
    isFetchingSectors ||
    isFetchingTable ||
    isUpdating ||
    isFetchingForm;

  return {
    ...restRoadshowsUpdate,
    totalCount,
    page,
    perPage,
    isFetching,
    collection,
    sort,
    sortBy,
    sortOrder,
    paginate,
    setItemsPerPage,
    checkedRoadshows,
    setCheckedRoadshows,
    editableRowId,
    setEditableRowId,
    isBulkModalVisible,
    setIsBulkModalVisible,
    isFetchingForm,
    setIsFetchingForm,
    getBulkEditingCollection: roadshowRepository.getRoadshowList,
    debouncedSearch,
  };
}
