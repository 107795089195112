import { connect } from 'react-redux';
import UpdateStatusButtonsCell from './UpdateStatusButtonsCell';
import {
  setIsSendEmailModalVisible,
  setIsResendEmailModalVisible,
  setIsDisableModalVisible,
} from '@/condor/application/actions/finsight/filings/collection/actions';

const mapDispatchToProps = (dispatch, { filing }) => ({
  setIsSendEmailModalVisible: () => dispatch(setIsSendEmailModalVisible(filing.row)),
  setIsResendEmailModalVisible: () => dispatch(setIsResendEmailModalVisible(filing.row)),
  setIsDisableModalVisible: () => dispatch(setIsDisableModalVisible(filing.row)),
});

export default connect(
  null,
  mapDispatchToProps,
)(UpdateStatusButtonsCell);
