import React, { createContext, useContext } from 'react';
import useCallProfile from './useCallProfile';

export const CallProfileContext = createContext<ReturnType<typeof useCallProfile>>(null);

export const useCallProfileContext = () => {
  const context = useContext(CallProfileContext);
  if (!context) {
    throw new Error('useCallProfileContext must be used within the CallProfileContext');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

const CallProfileContextProvider = ({ children }: IProps) => (
  <CallProfileContext.Provider value={ useCallProfile() }>{ children }</CallProfileContext.Provider>
);

export default CallProfileContextProvider;
