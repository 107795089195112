import React from 'react';
import cn from 'classnames';

import config from '@/Framework/config';

import CopyToClipboardButton from '@/ui/shared/components/Button/CopyToClipboardButton';
import { Spinner, InputLabel as Label } from '@dealroadshow/uikit';
import Button, { variantTypes } from '@/ui/shared/components/Button';
import Textarea from '@/ui/shared/components/Form/Input/Textarea';
import Select from '@/ui/shared/components/Form/Select/legacy';
import InvalidatePasswordModal from './InvalidatePasswordModal';

import styles from './section.scss';
import spacesStyles from '@/ui/shared/styles/helpers/spaces.scss';
import headerStyles from '@/ui/shared/styles/headers.scss';

const options = [
  {
    label: config.tenant.tenant17g5.name,
    value: config.tenant.tenant17g5.code,
    callbackUrl: `${ config.protocol }//${ config.tenant.dmPortal.hostname }/17g5`,
  },
  {
    label: config.tenant.tenantDealvdr.name,
    value: config.tenant.tenantDealvdr.code,
    callbackUrl: `${ config.protocol }//${ config.tenant.dmPortal.hostname }/dealvdr`,
  },
  {
    label: config.tenant.tenantInvestorSet.name,
    value: config.tenant.tenantInvestorSet.code,
    callbackUrl: `${ config.protocol }//${ config.tenant.dmPortal.hostname }/investorset`,
  },
  {
    label: config.tenant.tenantVeriSend.name,
    value: config.tenant.tenantVeriSend.code,
    callbackUrl: `${ config.protocol }//${ config.tenant.dmPortal.hostname }/verisend`,
  },
  {
    label: config.tenant.dealroadshow.name,
    value: config.tenant.dealroadshow.code,
    callbackUrl: `${ config.protocol }//${ config.tenant.dmPortal.hostname }/roadshow`,
  },
  {
    label: config.tenant.finsight.name,
    value: config.tenant.finsight.code,
    callbackUrl: `${ config.protocol }//${ config.tenant.finsight.hostname }`,
  },
];

interface IProps {
  label: string,
  linkLabel: string,
  link: string,
  selectProps: any,
  isGenerating: boolean,
  onGenerateClick: () => void,
  openInvalidatePasswordModal: () => void,
  dataTest: string,
}

const Section = (props: IProps) => (
  <div className={ styles.section }>
    <Spinner
      isVisible={ props.isGenerating }
      overlay
    />
    <div className={ cn(headerStyles.isH3, spacesStyles.mbl) }>{ props.label }</div>
    <Select
      options={ options }
      isNarrow
      className={ styles.select }
      dataTest={ `${ props.dataTest }Select` }
      { ...props.selectProps }
    />
    <Button
      variant={ variantTypes.action }
      className={ styles.generateBtn }
      onClick={ props.onGenerateClick }
      title="Generate"
      dataTest={ `${ props.dataTest }GenerateButton` }
    />
    <Label>{ props.linkLabel }</Label>
    <Textarea
      // @ts-ignore
      input={ { value: props.link } }
      disabled
      className={ cn(styles.textArea, { [styles.filledTextArea]: !!props.link }) }
      dataTest={ `${ props.dataTest }Textarea` }
    />
    <CopyToClipboardButton
      className={ styles.copyBtnContainer }
      copyElemClassName={ styles.copyBtn }
      copiedElemClassName={ styles.copied }
      content={ props.link }
      labelBeforeCopy="Copy Link"
      labelAfterCopy="Copied"
      isDisabled={ !props.link }
      dataTest={ `${ props.dataTest }CopyToClipboard` }
    />
    { props.openInvalidatePasswordModal && (
    <>
      <Button
        variant={ variantTypes.action }
        className={ styles.invalidateBtn }
        onClick={ props.openInvalidatePasswordModal }
        title="Invalidate Password"
        dataTest={ `${ props.dataTest }InvalidatePassButton` }
      />
      <InvalidatePasswordModal />
    </>
    ) }
  </div>
);

export default Section;
