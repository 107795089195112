import { getCollectionActions } from '@/Framework/State/Redux/collectionFactory';
import AuditTrailRepository from '@/dealroadshow/infrastructure/repository/AuditTrailRepository';
import actionTypes from './actionTypes';
import selectors from './selectors';

export const {
  getCollection: getAuditTrail,
  sort,
  itemsPerPage,
  paginate,
  setSearchQuery,
  reset: resetAuditTrail,
} = getCollectionActions(
  actionTypes,
  (payload, dispatch, getState) => {
    let auditTrailRepository = getState().container.get(AuditTrailRepository);
    return auditTrailRepository.list(payload);
  }, {
    onError: () => (0),
  },
);

/**
 * @param {String} roadshowId
 * @param {Boolean} isAdmin
 */
export const getAuditTrailByFilter = (roadshowId, isAdmin) => async (dispatch, getState) => {
  const state = getState();
  return dispatch(getAuditTrail({
    roadshowId,
    isAdmin,
    perPage: selectors(isAdmin).getPerPage(state),
    page: selectors(isAdmin).getPage(state),
    query: selectors(isAdmin).getSearchQuery(state),
    sortBy: selectors(isAdmin).getSortBy(state),
    sortOrder: selectors(isAdmin).getSortOrder(state),
  }));
};
