import { Dependencies } from 'constitute';
import UploadDispatcher from '@/dmPortal/application/DI/FileUpload/UploadDispatcher';

@Dependencies(UploadDispatcher)
class LogoRepository {
  constructor(private uploadDispatcher: typeof UploadDispatcher) {}

  upload = (file: File) => {
    return this.uploadDispatcher.upload(file, { type: 'EVERCALL_CUSTOM_ASSET' });
  };
}

export default LogoRepository;
