import { createContext, useContext, useState } from 'react';
import { useParams } from 'react-router';

import EventRepository from '@/openMarket/infrastructure/repository/Condor/EventRepository';
import { useDIContext } from '@/Framework/DI/DIContext';
import useFetchCollection from '@/Framework/State/useFetchCollection';
import { getErrorMessage, getMessage } from '@/Framework/Message/Mapper/getMessage';
import { messageCodes } from '@/Framework/Message/messages';
import { NotificationManager } from '@/ui/shared/components/Notification';
import { IListEvent } from '@/openMarket/domain/Condor/vo/IEvent';

interface IProps {
  onDeleteSuccess?: () => void,
}

const useEventsList = ({ onDeleteSuccess }) => {
  const profileId = useParams().profileId as string;
  const { container } = useDIContext();
  const eventRepository = container.get<EventRepository>(EventRepository);

  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingEvent, setDeletingEvent] = useState<IListEvent | null>(null);

  const activity = useFetchCollection(eventRepository.getEvents, { profileId });

  const deleteEvent = async (id: string) => {
    try {
      setIsDeleting(true);

      await eventRepository.deleteEvent(id);
      NotificationManager.success(getMessage(messageCodes.CONDOR_SAVED));
      activity.getCollection();
      onDeleteSuccess();
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    } finally {
      closeDeleteModal();
      setIsDeleting(false);
    }
  };

  const openDeleteModal = (event: IListEvent) => setDeletingEvent(event);
  const closeDeleteModal = () => setDeletingEvent(null);

  return {
    profileId,
    activity,
    deletingEvent,
    isDeleting,
    deleteEvent,
    openDeleteModal,
    closeDeleteModal,
  };
};

export const EventsListContext = createContext<ReturnType<typeof useEventsList>>(null);

export const useEventsListContext = () => {
  const context = useContext(EventsListContext);
  if (!context) {
    throw new Error('useEventsListContext must be used within a EventsListContextProvider');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
  onDeleteSuccess?: () => void,
}

const EventsListContextProvider = ({ children, onDeleteSuccess }: IProps) => (
  <EventsListContext.Provider value={ useEventsList({ onDeleteSuccess }) }>{ children }</EventsListContext.Provider>
);

export default EventsListContextProvider;
