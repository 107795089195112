import React, { useState } from 'react';
import cn from 'classnames';
import SearchInput from '@/ui/shared/components/Form/Input/SearchInput/SearchInput';
import { Tooltip, Icon, IconType } from '@dealroadshow/uikit';
import { useSubscribersContext } from '@/condor/application/FinsightManager/Subscribers';
import { useAddSubscribersFormContext } from '@/condor/application/FinsightManager/Subscribers/AddSubscribersForm';
import CheckboxFiltersPlans
  from '@/condor/ui/components/FinsightManager/ManagePlans/Subscribers/CheckboxFiltersPlans/CheckboxFiltersPlans';

import btnStyles from '@/ui/shared/components/Button/button.scss';
import spacesStyles from '@/ui/shared/styles/helpers/spaces.scss';
import styles from './subscribers.scss';
import commonStyles from '../common/common.scss';

const Filters = () => {
  const [search, setSearch] = useState<string>('');
  const { debouncedSearch } = useSubscribersContext();
  const { setIsAddSubscribersModalVisible } = useAddSubscribersFormContext();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    debouncedSearch(e.target.value);
  };
  const openAddSubscribersModal = () => {
    setIsAddSubscribersModalVisible(true);
  };

  return (
    <div className={ styles.filtersWrapper }>
      <CheckboxFiltersPlans />
      <SearchInput
        formFieldClassName={ commonStyles.searchInputField }
        placeholder="Filter by Account, Contact, Email, Team  or Plan"
        dataTest="finsightManagePlansSubscribersSearch"
        value={ search }
        onChange={ onChange }
        isNarrow
        isClearable
      />
      <Tooltip
        content="Add Subscribers"
      >
        <button
          type="button"
          className={ cn(
            btnStyles.action,
            btnStyles.btnIcon,
            spacesStyles.prm,
            spacesStyles.plm,
            spacesStyles.mrn,
            spacesStyles.mlm,
          ) }
          onClick={ openAddSubscribersModal }
          data-test="finsightManagePlansAddSubscriberButton"
        >
          <Icon type={ IconType.plusBold } />
        </button>
      </Tooltip>
    </div>
  );
};

export default Filters;
