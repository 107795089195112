import React, { createContext, useContext } from 'react';
import useCustomRegistration from './useCustomRegistration';

export const CustomRegistrationContext = createContext<ReturnType<typeof useCustomRegistration>>(null);

export const useCustomRegistrationContext = () => {
  const context = useContext(CustomRegistrationContext);
  if (!context) {
    throw new Error('useCustomRegistrationContext must be used within the CustomRegistrationContext');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

const CustomRegistrationContextProvider = ({ children }: IProps) => (
  <CustomRegistrationContext.Provider value={ useCustomRegistration() }>
    { children }
  </CustomRegistrationContext.Provider>
);

export default CustomRegistrationContextProvider;
