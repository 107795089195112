import { connect } from 'react-redux';
import { deleteDeal } from '@/condor/application/actions/finsight/deal/actions';
import selectors from '@/condor/application/actions/finsight/deal/selectors';
import DeleteDealModal from './DeleteDealModal';

const mapStateToProps = (state) => (
  {
    isDeleting: selectors.isRemoving(state),
    dealId: selectors.getItemData(state).id,
  }
);

const mapDispatchToProps = {
  deleteDeal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteDealModal);
