import { connect, batch } from 'react-redux';
import {
  getCondorFilingItem,
  resetCondorFilingItem,
} from '@/condor/application/actions/finsight/filings/item/actions';
import { reset as resetCondorDeal } from '@/condor/application/actions/finsight/deal/actions';
import dealSelectors from '@/condor/application/actions/finsight/deal/selectors';
import filingItemSelectors from '@/condor/application/actions/finsight/filings/item/selectors';
import FilingProfile from './FilingProfile';

const mapStateToProps = (state, ownProps) => (
  {
    isFetching: filingItemSelectors.isFetching(state),
    isLinkToExistingDeal: ownProps.location.pathname.includes('link-to-existing-deal'),
    existingDeal: filingItemSelectors.getExistingDeal(state),
    filingId: filingItemSelectors.getFilingId(state),
    dealId: filingItemSelectors.getDealId(state),
    deal: dealSelectors.getItemData(state),
  }
);

const mapDispatchToProps = (dispatch, ownProps) => (
  {
    init: () => dispatch(getCondorFilingItem({ id: ownProps.match.params.filingId })),
    reset: () => batch(() => {
      dispatch(resetCondorFilingItem());
      dispatch(resetCondorDeal());
    }),
  }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FilingProfile);
