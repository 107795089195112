import { getCollectionSelectors } from '@/Framework/State/Redux/collectionFactory';
import actionTypes from './actionTypes';

const getListData = (state) => state.condor.finsight.import.deal.auditTrail;

export default {
  ...getCollectionSelectors('condor.finsight.import.company.auditTrail', actionTypes),
  isDeleteImportModalVisible: (state) => getListData(state).isDeleteImportModalVisible,
  getDeleteItem: (state) => getListData(state).deleteItem,
};
