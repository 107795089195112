import { getItemSelectors } from '@/Framework/State/Redux/itemFactory';
import actionTypes from './actionTypes';

const getCondorFinsightDeal = (state) => state.condor.finsight.deal;

export default {
  ...getItemSelectors('condor.finsight.deal', actionTypes),
  isInitialized: (state) => getCondorFinsightDeal(state).isInitialized,
  getRatingAgencies: (state) => getCondorFinsightDeal(state).ratingAgencies,
  getOptions: (state) => getCondorFinsightDeal(state).options,
  getFilteredCompaniesOptionsByFields: (state) => getCondorFinsightDeal(state).filteredCompaniesOptionsByFields,
  getInitialIssuer: (state) => getCondorFinsightDeal(state).initialIssuer,
  getInitialDeal: (state) => getCondorFinsightDeal(state).initialDeal,
};
