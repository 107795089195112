import getDateFromUnixTime from '@/Framework/DateTime/getDateFromUnixTime';
import { batch, connect } from 'react-redux';
import { withRouter } from 'react-router';
import debounce from 'lodash/debounce';
import {
  getAuditTrailByFilter,
  itemsPerPage,
  paginate,
  setSearchQuery,
  sort,
  resetAuditTrail,
} from '@/dealroadshow/application/actions/auditTrail/collection/actions';
import { initAuditTrail, handleChangeAccessType } from '@/dealroadshow/application/actions/auditTrail/actions';
import AuditTrail from './AuditTrail';
import auditTrailSelectors from '@/dealroadshow/application/actions/auditTrail/collection/selectors';

const isAdmin = true;

const mapStateToProps = (state) => ({
  perPage: auditTrailSelectors(isAdmin).getPerPage(state),
  page: auditTrailSelectors(isAdmin).getPage(state),
  totalCount: auditTrailSelectors(isAdmin).getTotalCount(state),
  isCollectionFetching: auditTrailSelectors(isAdmin).isFetching(state),
  sortBy: auditTrailSelectors(isAdmin).getSortBy(state),
  sortOrder: auditTrailSelectors(isAdmin).getSortOrder(state),
  collection: auditTrailSelectors(isAdmin).getCollectionData(state).map((item) => ({
    ...item,
    occurredAt: getDateFromUnixTime(item.occurredAt),
  })),
  query: auditTrailSelectors(isAdmin).getSearchQuery(state),
  timeZone: state.dealRoadshows.roadshow.roadshow.itemSettings?.timeZone,
  isAdmin,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const { roadshowId } = ownProps.match.params;
  const getListDebounced = debounce(() => dispatch(getAuditTrailByFilter(roadshowId, isAdmin)), 500);

  return {
    onMount: () => batch(() => {
      dispatch(paginate(1));
      dispatch(initAuditTrail(roadshowId, isAdmin));
    }),
    onPageChange: (page) => {
      dispatch(paginate(page.selected));
      dispatch(getAuditTrailByFilter(roadshowId, isAdmin));
    },
    onItemsPerPageChange: (perPage) => {
      dispatch(itemsPerPage(perPage.value));
      dispatch(getAuditTrailByFilter(roadshowId, isAdmin));
    },
    onSortChange: (sortBy, sortOrder) => {
      dispatch(sort({ sortBy, sortOrder }));
      dispatch(getAuditTrailByFilter(roadshowId, isAdmin));
    },
    onSearchChange: (e) => {
      dispatch(setSearchQuery(e.target.value));
      getListDebounced();
    },
    onChangeAccessType: (id, accessType) => {
      dispatch(handleChangeAccessType(id, accessType, roadshowId, isAdmin));
    },
    onReset: () => {
      dispatch(resetAuditTrail());
    },
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuditTrail));
