import { v4 as uuid } from 'uuid';
import { Dependencies } from 'constitute';
import { Subscription } from '@dealroadshow/socket-frontend-sdk';
import Request from '@/Framework/api/Rpc/Request';
import CondorRpcDispatcher from '@/condor/application/DI/Rpc/HttpDispatcher';
import SocketClient from '@/dealroadshow/application/DI/Socket/Client';
import RpcDispatcher from '@/dmPortal/application/DI/Rpc/HttpDispatcher';
import { IFetchCollectionPayload } from '@/Framework/State/useFetchCollection';
import { callbackWrapper } from '@/Framework/DI/Providers/Socket/helpers/callbackWrapper';
import {
  IEnhancedTaggingRoadshowUpdate,
  IEnhancedTaggingRoadshowList,
} from '@/condor/domain/vo/EnhancedTagging/Roadshow';

@Dependencies(CondorRpcDispatcher, RpcDispatcher, SocketClient)
class EnhancedTaggingRoadshowsRepository {
  private updateSubscription: Subscription;

  constructor(
    private condorRpc: typeof CondorRpcDispatcher,
    private dmPortalRpc: typeof RpcDispatcher,
    private socket: typeof SocketClient,
  ) {}

  getRoadshowList = async (payload: IFetchCollectionPayload): Promise<IEnhancedTaggingRoadshowList> => {
    let req = new Request('condor.enhanced_tagging.roadshow.get_list', payload);
    let response = await this.condorRpc.call(req);

    return response.getResult().payload;
  };

  updateRoadshows = async (payload: IEnhancedTaggingRoadshowUpdate[], onUpdate: (data: never) => void) => {
    const entityId = uuid();

    const subscribeReq = new Request('roadshow.enhanced_tagging.bulk_update.sub_unsub', {
      entityId,
    });
    this.updateSubscription = await this.socket.subscribe(subscribeReq);
    this.updateSubscription.on('enhanced_tagging.updated', callbackWrapper(onUpdate));

    let req = new Request('roadshow.enhanced_tagging.bulk_update', {
      entries: payload,
      entityId,
    });

    let response = await this.dmPortalRpc.call(req);

    return response.getResult().payload;
  };

  unsubscribeUpdateRoadshows = () => {
    if (this.updateSubscription instanceof Subscription) {
      this.updateSubscription.cancel();
    }
  };
}

export default EnhancedTaggingRoadshowsRepository;
