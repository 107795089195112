import React from 'react';
import { Tooltip, Icon, IconType } from '@dealroadshow/uikit';

import styles from '../subscribersTable.scss';

interface IProps {
  onClick: () => void,
}

const UnsubscribeButton = ({
  onClick,
}: IProps) => (
  <Tooltip content="Manage Alerts">
    <Icon
      type={ IconType.settings }
      onClick={ onClick }
      className={ styles.unsubscribeIcon }
    />
  </Tooltip>
);

export default UnsubscribeButton;
