import React from 'react';
import { Field } from 'redux-form';
import cn from 'classnames';
import { Modal } from '@dealroadshow/uikit';
import FormWrapper from '@/condor/ui/common/Layout/FormWrapper';
import {
  CONDOR_FINSIGHT_IMPORT_COMPANY_SELECT_FORM,
} from '@/condor/ui/common/constants';
import ReduxFormSelect from '@/ui/shared/components/Form/Select/legacy/ReduxFormSelect';
import formStyles from '@/ui/shared/components/Form/form.scss';
import styles from './companySelectModal.scss';

import CompanySelectModalButtons from './CompanySelectModalButtons';

interface IProps {
  activeColumnName: string,
  activeCellValue: string,
  options: any[],
  onSelect: (company: any) => void,
  onCreateModalOpen: () => void,
  onClose: () => void,
}

const CompanySelectModal = (
  {
    activeColumnName,
    activeCellValue,
    options,
    onSelect,
    onCreateModalOpen,
    onClose,
  }: IProps,
) => (
  <Modal
    isVisible
    title={ `Select [${ activeColumnName }] Company` }
    onCloseClicked={ onClose }
    className={ styles.companySelectModal }
    dataTest="selectCompanyModal"
  >
    <div className={ styles.migrateContent }>
      <div className={ styles.migrateInfo }>
        <span className={ cn(styles.migrateLabel, formStyles.formLabel) }>
          { activeColumnName } from File (does not exist on finsight.com)
        </span>
        <span className={ styles.migrateValue }>{ activeCellValue }</span>
      </div>
      <FormWrapper
        form={ CONDOR_FINSIGHT_IMPORT_COMPANY_SELECT_FORM }
        dataTest={ CONDOR_FINSIGHT_IMPORT_COMPANY_SELECT_FORM }
        onSubmit={ ({ company }) => onSelect(company) }
        submitButtonText="Save"
        withAreYouSureModal={ false }
        enableReinitialize
        buttonsHidden
      >
        <Field
          name="company"
          label={ `Select ${ activeColumnName } company` }
          component={ ReduxFormSelect }
          options={ options }
          isSelect
          dataTest="company"
        />
        <CompanySelectModalButtons
          onCancelClick={ onClose }
          onCreateCompanyClick={ onCreateModalOpen }
        />
      </FormWrapper>
    </div>
  </Modal>
);

export default CompanySelectModal;
