import React from 'react';
import { Field } from 'redux-form';
import { CONDOR_FINSIGHT_ACCOUNT_MAPPING_FORM } from '@/condor/ui/common/constants';
import FormWrapper from '@/condor/ui/common/Layout/FormWrapper';
import styles from './mappingModal.scss';
import AccountSelect from './AccountSelect';
import { Modal, Spinner } from '@dealroadshow/uikit';
import validate from './validate';

const mapTypeToName = {
  contacts: 'contacts',
  deals: 'deals',
  evercalls: 'evercalls',
  allocations: 'allocations',
  notes: 'notes',
  leis: 'LEIs',
  emailDomains: 'email domains',
  webDomains: 'web domains',
};

interface IProps {
  migrationTitle: string,
  onRemap: () => Promise<void>,
  onCancel: () => void,
  mappingsTypes: any[],
  accountName: string,
  isFetching: boolean,
}

const MappingsModal = (props: IProps) => {
  const getMappedItemsList = () => {
    let resultString = '';
    props.mappingsTypes.forEach((type, index) => {
      resultString += `${ props[type] } ${ (props[type] === 1) ? mapTypeToName[type].slice(0, -1) : mapTypeToName[type] }${
        index === props.mappingsTypes.length - 1 ? '' : ', '
      }`;
    });
    return resultString;
  };

  return (
    <Modal
      title={ `Re-Map ${ props.migrationTitle } to Other Account` }
      onCloseClicked={ props.onCancel }
      dataTest="mappingsModal"
      isVisible
    >
      <div className={ styles.mappingContent }>
        <p>
          There are <strong>{ getMappedItemsList() }</strong> assigned to <strong>{ props.accountName }</strong>.
          Please select a verified account to migrate {
          props.mappingsTypes.length > 1 ? 'everything' : mapTypeToName[props.mappingsTypes[0]]
        } to:
        </p>
        <FormWrapper
          form={ CONDOR_FINSIGHT_ACCOUNT_MAPPING_FORM }
          enableReinitialize
          onSubmit={ props.onRemap }
          onCancelClick={ props.onCancel }
          submitButtonText="Re-Map"
          withAreYouSureModal={ false }
          buttonsWrapperClassname={ styles.buttonsWrp }
          validate={ validate }
          dataTest="accountRemapForm"
        >
          <Field
            name="toAccountId"
            isSelect
            component={ AccountSelect }
            clearable={ false }
          />
        </FormWrapper>
      </div>
      { props.isFetching && (
        <Spinner
          isVisible
          overlay
        />
      ) }
    </Modal>
  );
};

export default MappingsModal;
