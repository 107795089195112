import React from 'react';
import { Redirect, Switch } from 'react-router';
import Route from '@/ui/shared/components/ReactRouter/Route';
import config from '@/Framework/config';
import PagePreloader from '@/dmPortal/ui/components/PagePreloader';
import ExternalLink from '@/ui/shared/components/Next/ExternalLink';
import Navigation from '@/condor/ui/common/Navigation';

import * as constants from '@/condor/ui/common/constants';

import {
  ContactsList,
  ContactProfile,
  AccountsList,
  AccountProfile,
  AccountTypesList,
  AccountTypeProfile,
  ApplicationBlacklist,
  GlobalBlacklist,
} from '@/condor/ui/components/AccountsAndContacts';

import CallsList from '@/evercall/ui/components/condor/CallsList';
import CallProfile from '@/evercall/ui/components/condor/CallProfile';

import BannersList from '@/condor/ui/components/Admin';

import {
  CompaniesList,
  CompanyProfile,
  DealProfile,
  DealNoPricingDatePage,
  Filings,
  FilingProfile,
  BondImport,
  DealImport,
  CompanyImport,
  Subscribers,
  TraceManager,
} from '@/condor/ui/components/Finsight';

import BondManagerAnalytics from '@/condor/ui/components/FinsightManager/Analytics';

import RoadshowContainer from '@/condor/ui/components/DealRoadshows/Roadshow/RoadshowContainer';
import RoadshowsContainer from '@/condor/ui/components/DealRoadshows/Roadshows';

import TechnicalSupport from '@/condor/ui/components/TechnicalSupport';

import DealSettingsContainer from '@/condor/ui/components/DealRoadshows/Roadshow/modules/DealSettings/DealSettingsForm';
import DealFilesContainer from '@/condor/ui/components/DealRoadshows/Roadshow/modules/DealFiles';
import DealSlidesContainer from '@/condor/ui/components/DealRoadshows/Roadshow/modules/DealSlides/DealSlidesForm';
import AudioVideoContainer from '@/condor/ui/components/DealRoadshows/Roadshow/modules/AudioVideo/AudioVideoForm';
import AnalyticsContainer from '@/condor/ui/components/DealRoadshows/Roadshow/modules/Analytics';
import MappingContainer from '@/condor/ui/components/DealRoadshows/Roadshow/modules/Mapping';
import CustomModalContainer from '@/condor/ui/components/DealRoadshows/Roadshow/modules/CustomModal/CustomModalForm';
import LoginLimitsContainer from '@/condor/ui/components/DealRoadshows/Roadshow/modules/LoginLimits';
import AuditTrail from '@/condor/ui/components/DealRoadshows/Roadshow/modules/AuditTrail';

import EnhancedTaggingRouter from '@/condor/ui/components/EnhancedTagging/EnhancedTaggingRouter';

import URL from '@/finsight/Router/urlConstants';
import { BWICS, INVENTORIES } from '@/finsight/domain/BondType';
import { useSessionContext } from '@/users/application/Session/SessionContext';
import ManagePlans from '@/condor/ui/components/FinsightManager/ManagePlans';

import DataroomRouter from '@/dataroom/Router/CondorRouter';
import OpenMarketRouter from '@/openMarket/Router/CondorRouter';

const {
  DatabasesContainer,
  DmPortalEventLogOffsetContainer,
  EventLogOffsetContainer,
  FinsightEventLogOffsetContainer,
  FinsightStratsEventLogOffsetContainer,
  FinsightAnalyticsEventLogOffsetContainer,
  CondorEventLogOffsetContainer,
  TechnicalSupportContainer,
} = TechnicalSupport.containers;

export default () => {
  const { switchToManagerAccess } = useSessionContext();

  return (
    <Switch>
      <Redirect exact from="/login/condor" to="/" />
      <Route exact path="/external-link" component={ () => <ExternalLink isNext={ false } /> } />
      <Route
        path="/"
        beforeEnter={ switchToManagerAccess }
        preloader={ <PagePreloader /> }
      >
        <Navigation>
          <Switch>
            <Redirect exact from="/" to="/core" />
            <Redirect exact from="/core" to={ constants.CONDOR_DRS_ROADSHOWS_URL } />
            <Redirect exact from={ config.drs.condor.base } to={ constants.CONDOR_DRS_ROADSHOWS_URL } />
            <Redirect
              exact
              from={ constants.OLD_CONDOR_ACCOUNTS_AND_CONTACTS_BASE_URL }
              to={ constants.CONDOR_ACCOUNTS_LIST_BASE_URL }
            />
            <Redirect
              exact
              from={ constants.OLD_CONDOR_ACCOUNTS_BASE_URL }
              to={ constants.CONDOR_ACCOUNTS_LIST_BASE_URL }
            />
            <Redirect
              exact
              from={ constants.OLD_CONDOR_ACCOUNTS_LIST_BASE_URL }
              to={ constants.CONDOR_ACCOUNTS_LIST_BASE_URL }
            />
            <Redirect
              exact
              from={ constants.OLD_CONDOR_CREATE_ACCOUNT_BASE_URL }
              to={ constants.CONDOR_CREATE_ACCOUNT_BASE_URL }
            />
            <Redirect
              exact
              from={ `${ constants.OLD_CONDOR_ACCOUNT_PROFILE_BASE_URL }/:id` }
              to={ `${ constants.CONDOR_ACCOUNT_PROFILE_BASE_URL }/:id` }
            />
            <Redirect
              exact
              from={ `${ constants.OLD_CONDOR_ACCOUNT_PROFILE_MAPPINGS_BASE_URL }/:id` }
              to={ `${ constants.CONDOR_ACCOUNT_PROFILE_MAPPINGS_BASE_URL }/:id` }
            />
            <Redirect
              exact
              from={ constants.CONDOR_ACCOUNTS_BASE_URL }
              to={ constants.CONDOR_ACCOUNTS_LIST_BASE_URL }
            />
            <Route
              path={ constants.CONDOR_ACCOUNTS_BASE_URL }
              render={ () => (
                <Switch>
                  <Route
                    path={ constants.CONDOR_ACCOUNTS_LIST_BASE_URL }
                    component={ AccountsList }
                  />
                  <Route
                    path={ constants.CONDOR_CREATE_ACCOUNT_BASE_URL }
                    component={ AccountProfile }
                  />
                  <Route
                    path={ `${ constants.CONDOR_ACCOUNT_PROFILE_BASE_URL }/:id` }
                    component={ AccountProfile }
                  />
                  <Route
                    path={ `${ constants.CONDOR_ACCOUNT_PROFILE_MAPPINGS_BASE_URL }/:id` }
                    component={ AccountProfile }
                  />
                  <Route
                    path={ `${ constants.CONDOR_ACCOUNT_PROFILE_SSO_BASE_URL }/:id` }
                    component={ AccountProfile }
                  />
                </Switch>
              ) }
            />
            <Redirect
              exact
              from={ constants.OLD_CONDOR_CONTACTS_BASE_URL }
              to={ constants.CONDOR_CONTACTS_LIST_BASE_URL }
            />
            <Redirect
              exact
              from={ constants.OLD_CONDOR_CONTACTS_LIST_BASE_URL }
              to={ constants.CONDOR_CONTACTS_LIST_BASE_URL }
            />
            <Redirect
              exact
              from={ `${ constants.OLD_CONDOR_CONTACT_PROFILE_BASE_URL }/:contactId` }
              to={ `${ constants.CONDOR_CONTACT_PROFILE_BASE_URL }/:contactId` }
            />
            <Redirect
              exact
              from={ `${ constants.OLD_CONDOR_CONTACT_LINKS_BASE_URL }/:contactId` }
              to={ `${ constants.CONDOR_CONTACT_LINKS_BASE_URL }/:contactId` }
            />
            <Redirect
              exact
              from={ `${ constants.OLD_CONDOR_CONTACT_ADVANCED_BASE_URL }/:contactId` }
              to={ `${ constants.CONDOR_CONTACT_ADVANCED_BASE_URL }/:contactId` }
            />
            <Redirect
              exact
              from={ `${ constants.OLD_CONDOR_CONTACT_USER_PERMISSIONS_BASE_URL }/:contactId` }
              to={ `${ constants.CONDOR_CONTACT_USER_PERMISSIONS_BASE_URL }/:contactId` }
            />
            <Redirect
              exact
              from={ constants.CONDOR_CONTACTS_BASE_URL }
              to={ constants.CONDOR_CONTACTS_LIST_BASE_URL }
            />
            <Route
              path={ constants.CONDOR_CONTACTS_BASE_URL }
              render={ () => (
                <Switch>
                  <Route
                    path={ constants.CONDOR_CONTACTS_LIST_BASE_URL }
                    component={ ContactsList }
                  />
                  <Route
                    path={ `${ constants.CONDOR_CONTACT_PROFILE_BASE_URL }/:contactId` }
                    component={ ContactProfile }
                  />
                  <Route
                    path={ `${ constants.CONDOR_CONTACT_LINKS_BASE_URL }/:contactId` }
                    component={ ContactProfile }
                  />
                  <Route
                    path={ `${ constants.CONDOR_CONTACT_ADVANCED_BASE_URL }/:contactId` }
                    component={ ContactProfile }
                  />
                  <Route
                    path={ `${ constants.CONDOR_CONTACT_USER_PERMISSIONS_BASE_URL }/:contactId` }
                    component={ ContactProfile }
                  />
                </Switch>
              ) }
            />
            <Redirect
              exact
              from={ constants.OLD_CONDOR_ACCOUNT_TYPES_BASE_URL }
              to={ constants.CONDOR_ACCOUNT_TYPES_LIST_BASE_URL }
            />
            <Redirect
              exact
              from={ constants.OLD_CONDOR_ACCOUNT_TYPES_LIST_BASE_URL }
              to={ constants.CONDOR_ACCOUNT_TYPES_LIST_BASE_URL }
            />
            <Redirect
              exact
              from={ constants.OLD_CONDOR_CREATE_ACCOUNT_TYPE_BASE_URL }
              to={ constants.CONDOR_CREATE_ACCOUNT_TYPE_BASE_URL }
            />
            <Redirect
              exact
              from={ `${ constants.OLD_CONDOR_ACCOUNT_TYPE_PROFILE_BASE_URL }/:id` }
              to={ `${ constants.CONDOR_ACCOUNT_TYPE_PROFILE_BASE_URL }/:id` }
            />
            <Redirect
              exact
              from={ constants.OLD_CONDOR_APPLICATION_BLACKLIST_BASE_URL }
              to={ constants.CONDOR_APPLICATION_BLACKLIST_BASE_URL }
            />
            <Redirect
              exact
              from={ constants.OLD_CONDOR_GLOBAL_BLACKLIST_BASE_URL }
              to={ constants.CONDOR_GLOBAL_BLACKLIST_BASE_URL }
            />
            <Redirect
              exact
              from={ constants.CONDOR_ADMIN_BASE_URL }
              to={ constants.CONDOR_ACCOUNT_TYPES_LIST_BASE_URL }
            />
            <Route
              path={ constants.CONDOR_ADMIN_BASE_URL }
              render={ () => (
                <Switch>
                  <Redirect
                    exact
                    from={ constants.CONDOR_ACCOUNT_TYPES_BASE_URL }
                    to={ constants.CONDOR_ACCOUNT_TYPES_LIST_BASE_URL }
                  />
                  <Route
                    path={ constants.CONDOR_ACCOUNT_TYPES_BASE_URL }
                    render={ () => (
                      <Switch>
                        <Route
                          path={ constants.CONDOR_ACCOUNT_TYPES_LIST_BASE_URL }
                          component={ AccountTypesList }
                        />
                        <Route
                          path={ constants.CONDOR_CREATE_ACCOUNT_TYPE_BASE_URL }
                          component={ AccountTypeProfile }
                        />
                        <Route
                          path={ `${ constants.CONDOR_ACCOUNT_TYPE_PROFILE_BASE_URL }/:id` }
                          component={ AccountTypeProfile }
                        />
                      </Switch>
                    ) }
                  />
                  <Route
                    path={ constants.CONDOR_APPLICATION_BLACKLIST_BASE_URL }
                    component={ ApplicationBlacklist }
                  />
                  <Route
                    path={ constants.CONDOR_ADMIN_BANNER_LIST_BASE_URL }
                    component={ BannersList }
                  />
                  <Route
                    path={ constants.CONDOR_GLOBAL_BLACKLIST_BASE_URL }
                    component={ GlobalBlacklist }
                  />
                </Switch>
              ) }
            />
            <Redirect
              exact
              from={ constants.CONDOR_FINSIGHT_BASE_URL }
              to={ constants.CONDOR_FINSIGHT_COMPANIES_BASE_URL }
            />
            <Route
              path={ constants.CONDOR_FINSIGHT_BASE_URL }
              render={ () => (
                <Switch>
                  <Redirect
                    exact
                    from={ constants.CONDOR_FINSIGHT_COMPANIES_BASE_URL }
                    to={ constants.CONDOR_FINSIGHT_COMPANIES_LIST_BASE_URL }
                  />
                  <Redirect
                    exact
                    from={ constants.CONDOR_FINSIGHT_FILINGS_BASE_URL }
                    to={ constants.CONDOR_FINSIGHT_FILINGS_15G_BASE_URL }
                  />
                  <Route
                    path={ constants.CONDOR_FINSIGHT_COMPANIES_BASE_URL }
                    render={ () => (
                      <Switch>
                        <Route
                          path={ constants.CONDOR_FINSIGHT_COMPANIES_LIST_BASE_URL }
                          component={ CompaniesList }
                        />
                        <Route
                          path={ constants.CONDOR_FINSIGHT_CREATE_COMPANY_BASE_URL }
                          component={ CompanyProfile }
                        />
                        <Route
                          exact
                          path={ [
                            `${ constants.CONDOR_FINSIGHT_COMPANY_PROFILE_BASE_URL }/:id`,
                            `${ constants.CONDOR_FINSIGHT_COMPANY_DEALS_BASE_URL }/:id`,
                          ] }
                          component={ CompanyProfile }
                        />
                      </Switch>
                    ) }
                  />
                  <Redirect
                    exact
                    from={ constants.CONDOR_FINSIGHT_IMPORT_BASE_URL }
                    to={ constants.CONDOR_FINSIGHT_IMPORT_BOND_BASE_URL }
                  />
                  <Redirect
                    exact
                    from={ constants.CONDOR_FINSIGHT_IMPORT_BOND_BASE_URL }
                    to={ constants.CONDOR_FINSIGHT_IMPORT_BOND_UPLOAD_BASE_URL }
                  />
                  <Redirect
                    exact
                    from={ constants.CONDOR_FINSIGHT_IMPORT_DEAL_BASE_URL }
                    to={ constants.CONDOR_FINSIGHT_IMPORT_DEAL_UPLOAD_BASE_URL }
                  />
                  <Redirect
                    exact
                    from={ constants.CONDOR_FINSIGHT_IMPORT_COMPANY_BASE_URL }
                    to={ constants.CONDOR_FINSIGHT_IMPORT_COMPANY_UPLOAD_BASE_URL }
                  />
                  <Route
                    path={ constants.CONDOR_FINSIGHT_IMPORT_BOND_BASE_URL }
                    render={ () => (
                      <Switch>
                        <Redirect
                          exact
                          from={ constants.CONDOR_FINSIGHT_IMPORT_BOND_LIST_BASE_URL }
                          to={ `${ constants.CONDOR_FINSIGHT_IMPORT_BOND_LIST_BASE_URL }${ URL.BONDS_INVENTORIES }-abs` }
                        />
                        <Route
                          path={ constants.CONDOR_FINSIGHT_IMPORT_BOND_UPLOAD_BASE_URL }
                          component={ BondImport }
                        />
                        <Route
                          path={ `${ constants.CONDOR_FINSIGHT_IMPORT_BOND_LIST_BASE_URL }/:bondType(${ BWICS }|${ INVENTORIES })-:assetClass?` }
                          component={ BondImport }
                        />
                      </Switch>
                    ) }
                  />
                  <Route
                    path={ constants.CONDOR_FINSIGHT_IMPORT_DEAL_BASE_URL }
                    render={ () => (
                      <Switch>
                        <Route
                          path={ constants.CONDOR_FINSIGHT_IMPORT_DEAL_UPLOAD_BASE_URL }
                          component={ DealImport }
                        />
                        <Route
                          path={ constants.CONDOR_FINSIGHT_IMPORT_DEAL_AUDIT_TRAIL_BASE_URL }
                          component={ DealImport }
                        />
                      </Switch>
                    ) }
                  />
                  <Route
                    path={ constants.CONDOR_FINSIGHT_IMPORT_COMPANY_BASE_URL }
                    render={ () => (
                      <Switch>
                        <Route
                          path={ constants.CONDOR_FINSIGHT_IMPORT_COMPANY_UPLOAD_BASE_URL }
                          component={ CompanyImport }
                        />
                        <Route
                          path={ constants.CONDOR_FINSIGHT_IMPORT_COMPANY_AUDIT_TRAIL_BASE_URL }
                          component={ CompanyImport }
                        />
                      </Switch>
                    ) }
                  />
                  <Route
                    path={ constants.CONDOR_FINSIGHT_FILINGS_BASE_URL }
                    render={ () => (
                      <Switch>
                        <Route
                          component={ FilingProfile }
                          path={ [
                            `${ constants.CONDOR_FINSIGHT_FILING_CREATE_URL }/:filingId`,
                            `${ constants.CONDOR_FINSIGHT_FILING_PROFILE_URL }/:filingId`,
                            `${ constants.CONDOR_FINSIGHT_FILING_LINK_TO_EXISTING_DEAL_URL }/:filingId`,
                          ] }
                        />
                        <Route
                          path={ [
                            constants.CONDOR_FINSIGHT_FILINGS_15G_BASE_URL,
                            constants.CONDOR_FINSIGHT_FILINGS_EE_BASE_URL,
                          ] }
                          component={ Filings }
                        />
                      </Switch>
                    ) }
                  />
                  <Route
                    path={ `${ constants.CONDOR_FINSIGHT_DEAL_PROFILE_BASE_URL }/:dealId` }
                    component={ DealProfile }
                  />
                  <Route
                    path={ constants.CONDOR_FINSIGHT_CREATE_DEAL_BASE_URL }
                    component={ DealProfile }
                  />
                  <Route
                    path={ constants.CONDOR_FINSIGHT_DEAL_NO_PRICING_DATE_BASE_URL }
                    component={ DealNoPricingDatePage }
                  />
                  <Route
                    path={ constants.CONDOR_FINSIGHT_SUBSCRIBERS_BASE_URL }
                    component={ Subscribers }
                  />
                  <Route
                    path={ [
                      constants.CONDOR_FINSIGHT_TRADES_BASE_URL,
                      constants.CONDOR_FINSIGHT_TRADES_ARCHIVED_BASE_URL,
                    ] }
                    component={ TraceManager }
                  />
                  <Redirect
                    from={ constants.CONDOR_FINSIGHT_TRACE_BASE_URL }
                    to={ constants.CONDOR_FINSIGHT_TRADES_BASE_URL }
                  />
                </Switch>
              ) }
            />
            <Route
              path={ constants.CONDOR_DRS_BASE_URL }
            >
              <Switch>
                <Route
                  exact
                  path={ constants.CONDOR_DRS_ROADSHOWS_URL }
                  component={ RoadshowsContainer }
                />
                <Redirect
                  exact
                  from={ `${ constants.CONDOR_DRS_BASE_URL }/blacklist` }
                  to={ constants.CONDOR_APPLICATION_BLACKLIST_BASE_URL }
                />
                <Route
                  path={ `${ constants.CONDOR_DRS_ROADSHOW_URL }/:roadshowId` }
                  render={ (props) => (
                    <RoadshowContainer { ...props }>
                      <Switch>
                        <Route
                          exact
                          path={ `${ constants.CONDOR_DRS_ROADSHOW_URL }/:roadshowId/deal-settings` }
                          component={ DealSettingsContainer }
                        />
                        <Route
                          exact
                          path={ `${ constants.CONDOR_DRS_ROADSHOW_URL }/:roadshowId/deal-files` }
                          component={ DealFilesContainer }
                        />
                        <Route
                          exact
                          path={ `${ constants.CONDOR_DRS_ROADSHOW_URL }/:roadshowId/deal-slides` }
                          component={ DealSlidesContainer }
                        />
                        <Route
                          exact
                          path={ `${ constants.CONDOR_DRS_ROADSHOW_URL }/:roadshowId/audio-video` }
                          component={ AudioVideoContainer }
                        />
                        <Route
                          exact
                          path={ `${ constants.CONDOR_DRS_ROADSHOW_URL }/:roadshowId/analytics` }
                          component={ AnalyticsContainer }
                        />
                        <Route
                          exact
                          path={ `${ constants.CONDOR_DRS_ROADSHOW_URL }/:roadshowId/audit-trail` }
                          component={ AuditTrail }
                        />
                        <Route
                          exact
                          path={ `${ constants.CONDOR_DRS_ROADSHOW_URL }/:roadshowId/mapping` }
                          component={ MappingContainer }
                        />
                        <Route
                          exact
                          path={ `${ constants.CONDOR_DRS_ROADSHOW_URL }/:roadshowId/custom-modal` }
                          component={ CustomModalContainer }
                        />
                        <Route
                          exact
                          path={ `${ constants.CONDOR_DRS_ROADSHOW_URL }/:roadshowId/login-limits` }
                          component={ LoginLimitsContainer }
                        />
                      </Switch>
                    </RoadshowContainer>
                  ) }
                />
              </Switch>
            </Route>
            <Redirect
              exact
              from={ constants.CONDOR_FINSIGHT_MANAGER_BASE_URL }
              to={ constants.CONDOR_FINSIGHT_MANAGER_MANAGE_PLANS_BASE_URL }
            />
            <Redirect
              exact
              from={ constants.CONDOR_FINSIGHT_MANAGER_MANAGE_PLANS_BASE_URL }
              to={ constants.CONDOR_FINSIGHT_MANAGER_MANAGE_PLANS_REQUESTS_URL }
            />
            <Route
              path={ constants.CONDOR_FINSIGHT_MANAGER_MANAGE_PLANS_BASE_URL }
              component={ ManagePlans }
            />
            <Redirect
              exact
              from={ constants.CONDOR_FINSIGHT_MANAGER_ANALYTICS_URL }
              to={ constants.CONDOR_FINSIGHT_MANAGER_ANALYTICS_BI_URL }
            />
            <Route
              path={ constants.CONDOR_FINSIGHT_MANAGER_ANALYTICS_URL }
              component={ BondManagerAnalytics }
            />
            <Route
              path={ constants.CONDOR_FINSIGHT_OPEN_MARKET }
              component={ OpenMarketRouter }
            />
            <Route
              path={ constants.CONDOR_TECHNICAL_BASE_URL }
            >
              <Switch>
                <Redirect
                  exact
                  from={ constants.CONDOR_TECHNICAL_BASE_URL }
                  to={ constants.CONDOR_TECHNICAL_DRS_EVENT_LOG_OFFSET_URL }
                />
                <Route
                  path={ constants.CONDOR_TECHNICAL_BASE_URL }
                  render={ (props) => (
                    <TechnicalSupportContainer { ...props }>
                      <Switch>
                        <Route
                          exact
                          path={ constants.CONDOR_TECHNICAL_DRS_EVENT_LOG_OFFSET_URL }
                          component={ EventLogOffsetContainer }
                        />
                        <Route
                          exact
                          path={ constants.CONDOR_TECHNICAL_FINSIGHT_EVENT_LOG_OFFSET_URL }
                          component={ FinsightEventLogOffsetContainer }
                        />
                        <Route
                          exact
                          path={ constants.CONDOR_TECHNICAL_FINSIGHT_STRATS_EVENT_LOG_OFFSET_URL }
                          component={ FinsightStratsEventLogOffsetContainer }
                        />
                        <Route
                          exact
                          path={ constants.CONDOR_TECHNICAL_FINSIGHT_ANALYTICS_EVENT_LOG_OFFSET_URL }
                          component={ FinsightAnalyticsEventLogOffsetContainer }
                        />
                        <Route
                          exact
                          path={ constants.CONDOR_TECHNICAL_DM_PORTAL_EVENT_LOG_OFFSET_URL }
                          component={ DmPortalEventLogOffsetContainer }
                        />
                        <Route
                          exact
                          path={ constants.CONDOR_TECHNICAL_CONDOR_EVENT_LOG_OFFSET_URL }
                          component={ CondorEventLogOffsetContainer }
                        />
                        <Route
                          exact
                          path={ `${ constants.CONDOR_TECHNICAL_DATABASES_URL }/:dataSource?/:database?/:table?` }
                          component={ DatabasesContainer }
                        />
                      </Switch>
                    </TechnicalSupportContainer>
                  ) }
                />
              </Switch>
            </Route>
            <Redirect
              exact
              from={ constants.CONDOR_EVERCALL_BASE_URL }
              to={ constants.CONDOR_EVERCALL_LIST }
            />
            <Redirect
              exact
              from={ constants.CONDOR_EVERCALL_PROFILE }
              to={ constants.CONDOR_EVERCALL_LIST }
            />
            <Route path={ constants.CONDOR_EVERCALL_BASE_URL }>
              <Switch>
                <Route
                  path={ constants.CONDOR_EVERCALL_LIST }
                  component={ CallsList }
                />
                <Route
                  path={ `${ constants.CONDOR_EVERCALL_PROFILE }/:callId` }
                  component={ CallProfile }
                />
              </Switch>
            </Route>
            <Route path={ constants.CONDOR_ENHANCED_TAGGING_BASE_URL } component={ EnhancedTaggingRouter } />
            <DataroomRouter />
          </Switch>
        </Navigation>
      </Route>
    </Switch>
  );
};
