import { useProfileFormInit } from './useProfileFormInit';
import { useProfileFormSubmit } from './useProfileFormSubmit';

export interface IUseProfileFormProps {
  profileId?: string,
}

export const useProfileForm = ({ profileId }: IUseProfileFormProps) => {
  const isCreate = !profileId;
  const title = `${ isCreate ? 'Create' : 'Edit' } Profile`;

  return {
    ...useProfileFormInit(),
    ...useProfileFormSubmit({
      isCreate,
    }),
    title,
    profileId,
  };
};
