import { Component, PropsWithChildren } from 'react';
import { withRouter } from 'react-router';
import { NavLink as ReactNavLink } from 'react-router-dom';

interface IProps {
  children?: React.ReactNode,
  onBecomeActive?: () => void,
  onClick?: (event: React.MouseEvent) => void,
  location?: { [key: string]: any },
  className?: string,
  activeClassName?: string,
  disabled?: boolean,
  dataTest?: string,
  isActive?: (match: any, location: any) => boolean,
  to: string | ((props: any) => string),
}

@withRouter
class NavLink extends Component<PropsWithChildren<IProps>> {
  constructor(props: IProps) {
    super(props);

    this.onBecomeActiveTriggered = false;
  }

  componentDidMount() {
    this.handleBecomeActive();
  }

  componentDidUpdate() {
    this.handleBecomeActive();
  }

  onBecomeActiveTriggered: boolean;

  handleClick = (e) => {
    if (this.props.disabled) {
      e.preventDefault();
      return;
    }

    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };

  onBecomeActive = () => {
    if (this.props.onBecomeActive && !this.onBecomeActiveTriggered) {
      this.onBecomeActiveTriggered = true;
      this.props.onBecomeActive();
    }
  };

  handleBecomeActive = () => {
    if (this.props.location.pathname.startsWith(this.props.to)) {
      this.onBecomeActive();
      return;
    }
    this.onBecomeActiveTriggered = false;
  };

  render() {
    return (
      <ReactNavLink
        to={ this.props.to }
        data-test={ `${ this.props.dataTest }NavLink` }
        activeClassName={ this.props.activeClassName }
        className={ this.props.className }
        onClick={ this.handleClick }
        disabled={ this.props.disabled }
        isActive={ this.props.isActive }
      >
        { this.props.children }
      </ReactNavLink>
    );
  }
}

export default NavLink;
