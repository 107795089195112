import { Dependencies } from 'constitute';
import Request from '@/Framework/api/Rpc/Request';
import ExternalDispatcher from '@/Framework/DI/Providers/Rpc/externalApi/HttpDispatcher';

@Dependencies(
  ExternalDispatcher,
)
class ExternalRepository {
  private external: typeof ExternalDispatcher;

  constructor(external) {
    /**
     * @type {Dispatcher}
     */
    this.external = external;
  }

  /**
   * @param {Number} userId
   *
   * @return {Promise.<*>}
   */
  getApiAccessKey = async (userId) => {
    let req = new Request('internal.user.get_token', { userId });
    let response = await this.external.call(req);

    return response.getResult().token;
  };

  /**
   * @param {Number} userId
   *
   * @return {Promise.<*>}
   */
  setApiAccessKey = async (userId) => {
    let req = new Request('internal.user.set_token', { userId });
    let response = await this.external.call(req);

    return response.getResult().user.token;
  };
}

export default ExternalRepository;
