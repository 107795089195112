import { useDispatch, useSelector } from 'react-redux';
import {
  getRoadshows,
  resetRoadshows,
  setRoadshowsTableFilterPagination,
  setRoadshowsTableFilterPerPage,
  setRoadshowsTableFilterRadio,
  setRoadshowsTableFilterSearch,
  setRoadshowsTableSort,
  toggleRoadshowActions,
} from './actions';

export const useRoadshowTable = () => {
  const dispatch = useDispatch();
  const selector = useSelector((state: any) => ({
    roadshows: state.dealRoadshows.roadshows.all.list,
    roadshowTabs: state.dealRoadshows.roadshow.tabs,
    tableFilter: state.dealRoadshows.roadshows.tableFilter,
    totalCount: state.dealRoadshows.roadshows.all.totalCount,
    isFetching: state.dealRoadshows.roadshows.all.isFetching,
  }));
  const actions = {
    getRoadshows: (payload) => dispatch(getRoadshows(payload)),
    resetRoadshows: () => dispatch(resetRoadshows()),
    setRoadshowsTableFilterPagination: (payload) => dispatch(setRoadshowsTableFilterPagination(payload)),
    setRoadshowsTableFilterPerPage: (payload) => dispatch(setRoadshowsTableFilterPerPage(payload)),
    setRoadshowsTableFilterRadio: (payload) => dispatch(setRoadshowsTableFilterRadio(payload)),
    setRoadshowsTableFilterSearch: (payload) => dispatch(setRoadshowsTableFilterSearch(payload)),
    setRoadshowsTableSort: (payload) => dispatch(setRoadshowsTableSort(payload)),
    toggleRoadshowActions: (payload, second) => dispatch(toggleRoadshowActions(payload, second)),
  };

  return { ...selector, ...actions };
};
