import ErrorCodeHelper from '@finsight/error-codes';
import { getErrorMessage, getMessage } from '@/Framework/Message/Mapper/getMessage';
import RoadshowRepository from '@/dealroadshow/infrastructure/repository/RoadshowRepository';
import { SLIDE_TYPES } from '@/dealroadshow/domain/SlideType';
import { messageCodes } from '@/Framework/Message/messages';
import * as actionTypes from './actionTypes';
import { NotificationManager } from '@/ui/shared/components/Notification';
import Upload from '@/ui/shared/modules/upload';
import filterDealSlidesFormData from './DealSlidesForm/helpers/filterDealSlidesFromData';
import createAction from '@/Framework/State/Redux/createAction';

/**
 * @type {RoadshowRepository}
 */

export function getSlideTypes() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_SLIDE_TYPE,
      payload: SLIDE_TYPES,
    });
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function requestDealSlidesSuccess(payload) {
  return {
    type: actionTypes.REQUEST_DEAL_SLIDES_SUCCESS,
    payload: {
      initialValues: {
        ...payload,
      },
      data: payload,
    },
  };
}

/**
 * @param {String} error
 * @return {Object}
 */
function requestDealSlidesError(error) {
  return {
    type: actionTypes.REQUEST_DEAL_SLIDES_ERROR,
    error,
  };
}

/**
 * @param {String} roadshowId
 */
export function getDealSlides(roadshowId) {
  return async (dispatch, getState) => {
    const roadshowRepository = getState().container.get(RoadshowRepository);
    dispatch({ type: actionTypes.REQUEST_DEAL_SLIDES });
    try {
      let response = await roadshowRepository.getAdminDealSlides(roadshowId);
      dispatch(requestDealSlidesSuccess(response));
    } catch (err) {
      dispatch(requestDealSlidesError(err));
    }
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function requestDealSlidesUpdateSuccess(payload) {
  return {
    type: actionTypes.REQUEST_DEAL_SLIDES_UPDATE_SUCCESS,
    payload: {
      initialValues: {
        ...payload,
      },
      data: payload,
    },
  };
}

/**
 * @param {String} error
 * @return {Object}
 */
function requestDealSlidesUpdateError(error) {
  return {
    type: actionTypes.REQUEST_DEAL_SLIDES_UPDATE_ERROR,
    error,
  };
}

export const setDealSlidesNeedToRefreshModalVisible = (isVisible) => (
  createAction(actionTypes.SET_DEAL_SLIDES_NEED_TO_REFRESH_MODAL_VISIBLE, isVisible));

/**
 * @param {Object} formData
 */
export function updateDealSlides(formData) {
  return async (dispatch, getState) => {
    const roadshowRepository = getState().container.get(RoadshowRepository);
    dispatch({ type: actionTypes.REQUEST_DEAL_SLIDES_UPDATE });
    try {
      if (formData.presentation &&
          formData.presentation.slideShow.length !==
          getState().upload.presentation.slideShow.length) {
        dispatch(requestDealSlidesUpdateError(messageCodes.DIFFERENT_NUMBER_OF_SLIDES));
        NotificationManager.error(getMessage(messageCodes.DIFFERENT_NUMBER_OF_SLIDES, {
          previousCountSlides: formData.presentation.slideShow.length,
          currentCountSlides: getState().upload.presentation.slideShow.length,
        }));
        return;
      }

      if (getState().upload.presentation.canSave) {
        formData.upload = getState().upload;
        let saveData = filterDealSlidesFormData(formData);
        let response;
        if (formData.presentation) {
          let moveSlides = getMoveDealSlides(formData);

          if (moveSlides) {
            await roadshowRepository.moveAdminDealSlides(moveSlides);
          }

          if (moveSlides) {
            (await roadshowRepository.updateAdminDealSlides(saveData));
          } else if (formData.slideType !== getState().dealRoadshows.roadshow.dealSlides.data.initialValues.slideType) {
            (await roadshowRepository.updateAdminDealSlides(saveData));
          }

          response = await roadshowRepository.getAdminDealSlides(formData.id);
        } else if (!formData.presentation && getState().upload.presentation.slideShow.length) {
          (await roadshowRepository.updateAdminDealSlides(saveData));
          response = await roadshowRepository.getAdminDealSlides(formData.id);
        } else if (formData.slideType !== getState().dealRoadshows.roadshow.dealSlides.data.initialValues.slideType) {
          (await roadshowRepository.updateAdminDealSlides(saveData));
        }

        dispatch(uploadClear());
        dispatch(requestDealSlidesUpdateSuccess(response));
        NotificationManager.success(getMessage(messageCodes.CONDOR_SAVED));
      } else {
        dispatch(requestDealSlidesUpdateError(messageCodes.PRESENTATION_UPLOADING));
        NotificationManager.warning(getMessage(messageCodes.PRESENTATION_UPLOADING));
      }
    } catch (errorResponse) {
      if (
        errorResponse.error &&
        errorResponse.error.code === ErrorCodeHelper.getCodeByName('ROADSHOW_IS_ALREADY_UPDATED')
      ) {
        dispatch(setDealSlidesNeedToRefreshModalVisible(true));
      } else {
        dispatch(requestDealSlidesUpdateError(errorResponse));
        NotificationManager.error(getErrorMessage(errorResponse));
      }
    }
  };
}

/**
 * @param {Object} formData
 */
function getMoveDealSlides(formData) {
  const PRESENTATION_MOVE_SLIDE = 'presentation_move_slide';
  const PRESENTATION_RELOAD = 'presentation_reload';
  let move = {};
  let { upload } = formData;
  if (!upload.presentation.uploadFile) {
    let oldSlideShow = {};
    let slideToMove = [];
    formData.presentation.slideShow.forEach((slide) => {
      oldSlideShow[slide.id] = slide;
    });
    upload.presentationSlides.sort.forEach((slide) => {
      Object.keys(upload.presentationSlides.files[slide.uuid].types).forEach((type) => {
        if (oldSlideShow[slide.uuid].urls[type] !== upload.presentationSlides.files[slide.uuid].types[type].slide) {
          slideToMove.push({
            from: upload.presentationSlides.files[slide.uuid].types[type].slide,
            to: oldSlideShow[slide.uuid].urls[type],
          });
        }
      });
    });
    if (!slideToMove.length) {
      return false;
    }
    move = {
      type: PRESENTATION_MOVE_SLIDE,
      slideToMove,
    };
  } else {
    move = {
      type: PRESENTATION_RELOAD,
      old: formData.presentation,
      new: {
        origin: upload.presentation.presentationOrigin,
        slideShow: upload.presentation.slideShow,
      },
    };
  }

  return move;
}

/**
 * @return {Object}
 */
function uploadClear() {
  return {
    type: Upload.actionTypes.UPLOAD_CLEAR,
  };
}

export function resetDealSlides() {
  return async (dispatch) => {
    dispatch(uploadClear());
    dispatch({ type: actionTypes.DEAL_SLIDES_RESET });
  };
}
