import React from 'react';
import cn from 'classnames';
import { isEqual } from 'lodash';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  MeasuringStrategy,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToParentElement } from '@dnd-kit/modifiers';

import { useForm } from '@/ui/shared/components/Form/FinalForm';
import SectionItem from './SectionItem';

import { DETAILS_SECTIONS } from './constants';
import { ICustomRegistration } from '@/evercall/domain/vo/call/admin/CustomRegistration';

import styles from './detailsSections.scss';

const DetailsSections = () => {
  const form = useForm<ICustomRegistration>();
  const { values } = form.getState();

  const sensors = useSensors(
    useSensor(TouchSensor),
    useSensor(MouseSensor),
  );

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over.id) {
      const oldIndex = Number(active.id);
      const newIndex = Number(over.id);

      const copy = [...values.detailsOrder];
      const value = copy[oldIndex];
      copy.splice(oldIndex, 1);
      copy.splice(newIndex, 0, value);
      form.change('detailsOrder', copy);
      form.blur('detailsOrder');
    }
  };

  const sections = (values.detailsOrder || []).map(
    (sectionName, index) => {
      const section = DETAILS_SECTIONS.find(({ id }) => isEqual(id, sectionName));
      return (
        <SectionItem
          key={ sectionName }
          rowCls={ cn({ [styles.lastSectionItemRow]: index === (values.detailsOrder.length - 1) }) }
          uniqueIdentifier={ index.toString() }
        >
          { section.renderSection(values) }
        </SectionItem>
      );
    },
  );

  return (
    <div className={ styles.detailsSectionsContainer }>
      <DndContext
        modifiers={ [restrictToParentElement] }
        onDragEnd={ onDragEnd }
        collisionDetection={ closestCenter }
        sensors={ sensors }
        measuring={ {
          droppable: {
            strategy: MeasuringStrategy.WhileDragging,
          },
        } }
      >
        <div>
          <SortableContext
            items={ (values.detailsOrder || []).map((section, index) => index.toString()) }
            strategy={ verticalListSortingStrategy }
          >
            { sections }
          </SortableContext>
        </div>
      </DndContext>
    </div>
  );
};

export default DetailsSections;
