import React, { createContext, useContext, useState } from 'react';

const useSSOEnabled = () => {
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [isCertificatesExist, setIsCertificatesExist] = useState<boolean>(true);

  const onEnableClick = () => {
    if (isCertificatesExist) {
      setIsEnabled((prevState) => !prevState);
    }
  };

  return {
    isCertificatesExist,
    onEnableClick,
    isEnabled,
    setIsCertificatesExist,
    setIsEnabled,
  };
};

type EnableSSOContextType = ReturnType<typeof useSSOEnabled>;

const EnableSSOContext = createContext<EnableSSOContextType>(null);

export const useEnableSSOContext = () => {
  const context = useContext(EnableSSOContext);

  if (!context) {
    throw new Error('useEnableSSOContext must be used within the EnableSSOContext');
  }
  return context;
};

const EnableSSOContextProvider = ({ children }) => (
  <EnableSSOContext.Provider value={ useSSOEnabled() }>
    { children }
  </EnableSSOContext.Provider>
);

export default EnableSSOContextProvider;
