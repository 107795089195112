import React, { createContext, useContext, useState, useMemo, useCallback } from 'react';
import { TRANCHE_GROUP_IDS } from '@/condor/ui/components/Finsight/DealProfile/constants';

interface IProps {
  children: React.ReactNode,
  isStickyHeaderVisible: boolean,
}

export const TranchesContext = createContext(null);

export const useTranchesContext = () => {
  const context = useContext(TranchesContext);
  if (!context) {
    throw new Error('useTranchesContext must be used within the TranchesContext');
  }
  return context;
};

const TranchesContextProvider = ({ children, isStickyHeaderVisible }: IProps) => {
  const [tranchesViewOffset, setTranchesViewOffset] = useState(0);
  const [collapsedGroupIds, setCollapsedGroupIds] = useState([
    TRANCHE_GROUP_IDS.RATING_AGENCIES,
    TRANCHE_GROUP_IDS.CREDIT_ENHANCMENT,
    TRANCHE_GROUP_IDS.CUSIP,
    TRANCHE_GROUP_IDS.COMMENTS,
  ]);
  const [tranchesFieldArrayLength, setTranchesFieldArrayLength] = useState(0);

  const toggleTrancheGroupIsCollapsed = useCallback((groupId) => setCollapsedGroupIds(
    (prev) => (prev.includes(groupId)
      ? prev.filter((prevGroupId) => prevGroupId !== groupId)
      : [...prev, groupId]),
  ), []);

  const contextProviderValue = useMemo(
    () => (
      {
        tranchesViewOffset,
        setTranchesViewOffset,
        isStickyHeaderVisible,
        collapsedGroupIds,
        toggleTrancheGroupIsCollapsed,
        tranchesFieldArrayLength,
        setTranchesFieldArrayLength,
      }
    ),
    [tranchesViewOffset, isStickyHeaderVisible, collapsedGroupIds, tranchesFieldArrayLength],
  );

  return (
    <TranchesContext.Provider value={ contextProviderValue }>{ children }</TranchesContext.Provider>
  );
};

export default TranchesContextProvider;
