import { connect } from 'react-redux';
import { isSubmitting, isPristine, isInvalid } from 'redux-form';
import { CONDOR_FINSIGHT_IMPORT_COMPANY_SELECT_FORM } from '@/condor/ui/common/constants';
import CompanySelectModalButtons from './CompanySelectModalButtons';

const mapStateToProps = (state) => (
  {
    submitDisabled: (
      isSubmitting(CONDOR_FINSIGHT_IMPORT_COMPANY_SELECT_FORM)(state) ||
      isPristine(CONDOR_FINSIGHT_IMPORT_COMPANY_SELECT_FORM)(state) ||
      isInvalid(CONDOR_FINSIGHT_IMPORT_COMPANY_SELECT_FORM)(state)
    ),
  }
);

export default connect(
  mapStateToProps,
)(CompanySelectModalButtons);
