import createAction from '@/Framework/State/Redux/createAction';
import * as actionTypes from '@/users/application/actions/title/actionTypes';
import TitleRepository from '@/users/infrastructure/repository/TitleRepository';

/**
 *
 * @return {function(...[*]=)}
 */
export function getTitles() {
  return async (dispatch, getState) => {
    dispatch(createAction(actionTypes.GET_TITLES));

    try {
      const titleRepository = getState().container.get(TitleRepository);
      const titles = await titleRepository.list();

      dispatch(createAction(actionTypes.GET_TITLES_SUCCESS, titles));
    } catch (err) {
      dispatch(createAction(actionTypes.GET_TITLES_ERROR, { error: err }));
    }
  };
}
