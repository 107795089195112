import React from 'react';
import FinalFormSelect from '@/ui/shared/components/Form/Select/legacy/FinalFormSelect';
import { validateDomain } from '@/ui/shared/validators/validateDomain';
import { validateMaxLength } from '@/ui/shared/validators/validateMaxLength';
import { validateMinLength } from '@/ui/shared/validators/validateMinLength';

interface IProps {
  fieldComponent: React.ElementType,
  label: string,
  name: string,
  placeholder: string,
  className: string,
  dataTest: string,
}

const DomainField = ({ fieldComponent: Field, ...otherProps }:IProps) => {
  const normalizeValue = (value: string = ''): string => {
    if (value.indexOf('http://') !== -1) {
      value = value.replace('http://', '');
    }

    if (value.indexOf('https://') !== -1) {
      value = value.replace('https://', '');
    }

    if (value.indexOf('www.') !== -1) {
      value = value.replace('www.', '');
    }

    return value;
  };

  const normalize = (value) => value?.map((domain) => ({
    ...domain,
    id: normalizeValue(domain.id),
    domain: normalizeValue(domain.domain || domain.value),
  }));

  const formatCreateLabel = (label) => `Create a "${ normalizeValue(label) }" by Enter`;

  const isValidNewOption = (newElement) => {
    const label = normalizeValue(newElement);

    const validMinLength = !validateMinLength({
      fieldName: 'domain',
      value: label,
      length: 4,
    });

    const validMaxLength = !validateMaxLength({
      fieldName: 'domain',
      value: label,
      length: 253,
    });

    const validDomain = !validateDomain({
      fieldName: 'domain',
      value: label,
    });

    return !!label && validMinLength && validMaxLength && validDomain;
  };

  const isOptionUnique = ({
    option,
    options,
    labelKey,
  }:{ option: { domain: string }, options: string[], labelKey: string }):boolean => (
    !options.some((item) => item[labelKey] === normalizeValue(option.domain))
  );

  return (
    <Field
      component={ FinalFormSelect }
      creatable
      components={ { DropdownIndicator: () => null } }
      isMulti
      backspaceRemovesValue={ false }
      options={ [] }
      noOptionsMessage={ () => null }
      parse={ normalize }
      formatCreateLabel={ formatCreateLabel }
      isValidNewOption={ isValidNewOption }
      isOptionUnique={ isOptionUnique }
      { ...otherProps }
    />
  );
};

export default DomainField;
