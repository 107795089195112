import { connect } from 'react-redux';
import DeleteBondModal from './DeleteBondModal';
import selectors from '@/condor/application/actions/finsight/import/bond/list/selectors';
import {
  changeDeleteBondModalVisible,
  deleteBond,
} from '@/condor/application/actions/finsight/import/bond/list/actions';

const mapStateToProps = (state) => ({
  bond: selectors.getDeleteItem(state),
  isVisible: selectors.isDeleteBondModalVisible(state),
});

const mapDispatchToProps = {
  deleteBond,
  changeModalVisible: changeDeleteBondModalVisible,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteBondModal);
