import React, { useMemo } from 'react';
import cn from 'classnames';
import { Checkbox } from '@dealroadshow/uikit';
import FilterSection from '@/ui/shared/modules/Filters/components/FilterSection';
import FilterItem from '@/ui/shared/modules/Filters/components/FilterItem';
import FilterGroup from '@/ui/shared/modules/Filters/components/FilterGroup';
import { useEnhancedTaggingFiltersContext } from '@/condor/application/EnhancedTaggingContexts/EnhancedTaggingFiltersContext';
import { ICommonSectionProps } from '../types';
import { labels } from './contants';

import filtersStyles from '@/ui/shared/modules/Filters/filters.scss';

interface IProps extends ICommonSectionProps<Record<string, string[]>> {}

const TransactionType = ({
  sectionId,
  fieldComponent: Field,
  filterName,
  isVisible,
  onSectionToggle,
  sectionTitle,
  selected,
  setFieldValue,
}: IProps) => {
  const {
    filtersData: { transactionTypes },
  } = useEnhancedTaggingFiltersContext();

  const transactionTypesArray = useMemo(
    () => (Object.keys(transactionTypes) as Array<keyof typeof transactionTypes>).map((tenant) => ({
        tenant,
        options: transactionTypes[tenant],
      })),
    [transactionTypes],
  );

  const allOptionsCount = useMemo(
    () => transactionTypesArray.flatMap(({ options }) => options).length,
    [transactionTypes],
  );
  const selectedOptionsCount = useMemo(() => Object.values(selected).flat().length, [selected]);
  const allOptionsSelected = useMemo(
    () => transactionTypesArray.reduce(
        (acc, { options, tenant }) => ({
          ...acc,
          [tenant]: options.map(({ id }) => id),
        }),
        {},
      ),
    [transactionTypes],
  );

  const headerComponent = useMemo(() => {
    const handleFilterSectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setFieldValue(event.target.checked ? allOptionsSelected : {});
    };

    return (
      <Checkbox
        name={ sectionId }
        dataTest={ sectionId }
        label={ sectionTitle }
        supportIndeterminate
        onChange={ handleFilterSectionChange }
        checked={ allOptionsCount > 0 && allOptionsCount === selectedOptionsCount }
        indeterminate={ selectedOptionsCount > 0 && selectedOptionsCount < allOptionsCount }
      />
    );
  }, [sectionId, sectionTitle, allOptionsSelected, selected]);

  return (
    <FilterSection
      sectionId={ sectionId }
      headerComponent={ headerComponent }
      isVisible={ isVisible }
      onSectionToggle={ onSectionToggle }
    >
      { transactionTypesArray.map(({ tenant, options }) => {
        const allTenantOptions = transactionTypes[tenant].map(({ id }) => id);
        const allTenantOptionsCount = allTenantOptions.length;
        const selectedTenantOptionsCount = (selected[tenant] ?? []).length;

        const handleTanantFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue({
            ...selected,
            [tenant]: event.target.checked ? allTenantOptions : [],
          });
        };

        return (
          <FilterGroup
            key={ tenant }
            groupId={ `${ filterName }.${ tenant }` }
            itemsCount={ allTenantOptionsCount }
            selectedItemsCount={ selectedTenantOptionsCount }
            checkboxComponent={ (
              <Checkbox
                name={ tenant }
                dataTest={ tenant }
                label={ labels[tenant] }
                supportIndeterminate
                onChange={ handleTanantFilterChange }
                checked={ allTenantOptionsCount > 0 && allTenantOptionsCount === selectedTenantOptionsCount }
                indeterminate={ selectedTenantOptionsCount > 0 && selectedTenantOptionsCount < allTenantOptionsCount }
              />
            ) }
          >
            { options.map(({ id, name }) => (
              <FilterItem key={ id } className={ cn(filtersStyles.filterItem, filtersStyles.filterNestedItem) }>
                <Field
                  name={ `${ filterName }.${ tenant }` }
                  component={ ({ input, meta }) => (
                    <Checkbox
                      dataTest={ `${ filterName } ${ tenant } ${ name } checkbox` }
                      { ...input }
                      meta={ meta }
                      checked={ selected[tenant]?.includes(id) }
                      value={ id }
                      label={ name }
                      onChange={ (event) => {
                        const { checked } = event.target;
                        if (checked) {
                          setFieldValue({
                            ...selected,
                            [tenant]: [...(selected[tenant] ? selected[tenant] : []), id],
                          });
                        } else {
                          setFieldValue({
                              ...selected,
                              [tenant]: selected[tenant]?.filter((selectedValue) => selectedValue !== id),
                            });
                        }
                      } }
                    />
                  ) }
                />
              </FilterItem>
            )) }
          </FilterGroup>
        );
      }) }
    </FilterSection>
  );
};

export default TransactionType;
