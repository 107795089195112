import { groupValidators, applyValidatorToMultipleFields } from '@/ui/shared/validators/helpers';
import { validateRequiredField } from '@/ui/shared/validators/validateRequiredField';
import { validateMinLength } from '@/ui/shared/validators/validateMinLength';
import { validateMaxLength } from '@/ui/shared/validators/validateMaxLength';
import { validateNumberMaxMinAmount } from '@/ui/shared/validators/validateNumberMaxMinAmount';
import { IField } from '@/ui/shared/validators/interfaces/Field';
import { ICallDetailsFormValues } from '../interfaces';

const estimatedDuration: IField = {
  fieldName: 'estimatedDuration',
  fieldCaption: 'EST. Duration',
};

const speakersCount: IField = {
  fieldName: 'approximateNumberOfSpeakers',
  fieldCaption: 'Approximate # of speakers',
};

const participantsCount: IField = {
  fieldName: 'approximateNumberOfParticipants',
  fieldCaption: 'Approximate # of participants',
};

const callName: IField = {
  fieldName: 'name',
  fieldCaption: 'Call Name',
};

export const validateCallName = (values, params = callName) => (
  groupValidators(
    validateMinLength({ ...callName, value: values[params.fieldName], length: 3 }),
    validateMaxLength({ ...callName, value: values[params.fieldName], length: 128 }),
    validateRequiredField({ ...callName, value: values.name }),
  )
);

export default (values: ICallDetailsFormValues) => (
  groupValidators(
    validateCallName(values),
    applyValidatorToMultipleFields(
      {
        values,
        validator: validateNumberMaxMinAmount,
        fields: [
          {
            ...speakersCount,
            maxAmount: 100,
            minAmount: 1,
          },
          {
            ...participantsCount,
            maxAmount: 1000,
            minAmount: 1,
          },
          {
            ...estimatedDuration,
            maxAmount: 1000,
            minAmount: 1,
          },
        ],
      },
    ),
    applyValidatorToMultipleFields(
      {
        values,
        validator: validateRequiredField,
        fields: [
          { fieldName: 'transactionTypeId', fieldCaption: 'Transaction Type' },
          { fieldName: 'eventTypeId', fieldCaption: 'Event Type' },
          estimatedDuration,
          speakersCount,
          participantsCount,
        ],
      },
    ),
  )
);
