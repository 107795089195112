import * as actionTypes from './actionTypes';
import IndustryRepository from '@/dealroadshow/infrastructure/repository/industry/IndustryRepository';

export function getIndustryList() {
  return async (dispatch, getState) => {
    dispatch(requestIndustryList());
    try {
      const industryRepository = getState().container.get(IndustryRepository);
      const payload = await industryRepository.list();
      dispatch(requestIndustryListSuccess(payload));
    } catch (error) {
      dispatch(requestIndustryListError(error));
      throw error;
    }
  };
}

/**
 * @return {Object}
 */
function requestIndustryList() {
  return {
    type: actionTypes.GET_INDUSTRY_HIERARCHY_LIST,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function requestIndustryListSuccess(payload) {
  return {
    type: actionTypes.GET_INDUSTRY_HIERARCHY_LIST_SUCCESS,
    payload,
  };
}

/**
 * @param {String} error
 * @return {Object}
 */
function requestIndustryListError(error) {
  return {
    type: actionTypes.GET_INDUSTRY_HIERARCHY_LIST_ERROR,
    error,
  };
}
