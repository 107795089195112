import { connect } from 'react-redux';
import {
  getCondorFinsightIndustries,
  resetCondorFinsightIndustries,
} from '@/condor/application/actions/finsight/industry/collection/actions';
import { submitCondorFinsightCompanyFormModal } from '@/condor/application/actions/finsight/company/item/actions';

import industryCollectionSelectors from '@/condor/application/actions/finsight/industry/collection/selectors';

import CompanyCreateModal from './CompanyCreateModal';

const mapStateToProps = (state) => ({
  industries: industryCollectionSelectors.getCollectionData(state),
});

const mapDispatchToProps = {
  init: getCondorFinsightIndustries,
  reset: resetCondorFinsightIndustries,
  onSubmit: (formData, onSelect) => submitCondorFinsightCompanyFormModal(formData, onSelect),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompanyCreateModal);
