import React, { useCallback } from 'react';
import { FormSection, Field } from 'redux-form';
import ReduxFormSelect from '@/ui/shared/components/Form/Select/legacy/ReduxFormSelect';
import { getOptionMapper } from '@/Framework/dataHelpers/selectOptionMappers';
import { DEAL_PROFILE_FIELDS } from '../../../constants';
import withTrancheCell from '../../../helpers/withTrancheCell';

const Select = withTrancheCell(ReduxFormSelect);
const createOption = getOptionMapper({ pathToLabel: 'abbreviation' });

interface IProps {
  ratingAgencies: any[],
  field: string,
  isExcluded: boolean,
  trancheIndex: number,
}

const RatingAgenciesFields = (
  {
    ratingAgencies,
    field,
    isExcluded,
    trancheIndex,
  }: IProps,
) => {
  const renderField = useCallback(
    (agency) => (
      <Field
        key={ agency.abbreviation }
        name={ agency.abbreviation }
        placeholder="Select"
        options={ agency.ratings.map(createOption) }
        component={ Select }
        disabled={ isExcluded }
        dataTest={ `tranche${ trancheIndex }RatingAgency${ agency.abbreviation }` }
      />
    ),
    [isExcluded],
  );

  return (
    <FormSection name={ `${ field }.${ DEAL_PROFILE_FIELDS.TRANCHE.RATINGS.name }` }>
      { ratingAgencies.map(renderField) }
    </FormSection>
  );
};

export default RatingAgenciesFields;
