import React, { useEffect } from 'react';
import { useRequestCountContext } from '@/condor/application/FinsightManager/RequestCount';

const FinsightManager = ({ navContent: NavContent }: { navContent: React.ElementType }) => {
  const { requestCount, getRequestCount } = useRequestCountContext();
  useEffect(() => {
    getRequestCount();
  }, []);
  return <NavContent requestsCount={ requestCount } />;
};

export default FinsightManager;
