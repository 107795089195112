import React from 'react';
import {
  mapPlanToIcon,
} from '@/condor/ui/components/FinsightManager/ManagePlans/Subscribers/AddSubscribersModal/planIndicators';
import { UserPlanType } from '@/users/domain/UserPlan';

import styles from './styles.scss';

interface IProps {
  plan: UserPlanType,
  counter: number,
}

const mapPlanToText = {
  [UserPlanType.PRO]: 'Professional',
  [UserPlanType.PREMIUM]: 'Premium',
  [UserPlanType.STARTER]: 'Starter',
};

const PlanWithIndicator = ({ plan, counter }: IProps) => {
  if (!counter) return null;
  const Indicator = mapPlanToIcon[plan.toLowerCase()];
  return (
    <div className={ styles.warningBlockItem }>
      <Indicator />
      <p>{ `${ mapPlanToText[plan] } - ${ counter }` }</p>
    </div>
    );
};

export const mapPlanToPlanWithIndicator = {
  [UserPlanType.PRO]: (counter: number) => <PlanWithIndicator plan={ UserPlanType.PRO } counter={ counter } />,
  [UserPlanType.PREMIUM]: (counter: number) => <PlanWithIndicator plan={ UserPlanType.PREMIUM } counter={ counter } />,
  [UserPlanType.STARTER]: (counter: number) => <PlanWithIndicator plan={ UserPlanType.STARTER } counter={ counter } />,
};
