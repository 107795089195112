import React, { memo } from 'react';
import CheckboxFiltersBlock from '@/condor/ui/common/Layout/CheckboxFiltersBlock';
import { useRequestCountContext } from '@/condor/application/FinsightManager/RequestCount';
import { useRequestsContext } from '@/condor/application/FinsightManager/Requests';

import styles from '../requests.scss';

const CheckboxFilters = () => {
  const { requestCount } = useRequestCountContext();
  const {
    includePending,
    includeApproved,
    includeDenied,
    setApproved,
    setDenied,
    setPending,
  } = useRequestsContext();

  const filters = [
    {
      label: 'Approved',
      dataTest: 'approvedRequests',
      checked: includeApproved,
      onClick: setApproved,
    },
    {
      label: 'Denied',
      dataTest: 'deniedRequests',
      checked: includeDenied,
      onClick: setDenied,
    },
    {
      label: 'Pending',
      dataTest: 'pendingRequests',
      checked: includePending,
      onClick: setPending,
      count: requestCount,
    },
  ];
  return (
    <CheckboxFiltersBlock items={ filters } className={ styles.checkboxFilters } />
  );
};

export default memo(CheckboxFilters);
