import { isInvalid, isPristine, isSubmitting, submit } from 'redux-form';
import { connect, batch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CONDOR_ACCOUNT_TABLE_INLINE_EDIT_FORM_NAME } from '@/condor/ui/common/constants';
import itemSelectors from '@/condor/application/actions/accounts/item/selectors';
import UpdatedAtCell from './UpdatedAtCell';
import {
  setCondorAccountCurrentItem,
  setCondorAccountsIsInlineEditing,
  setCondorAccountsIsDeleteModalVisible,
  restoreCondorAccountsItem,
  resetCondorAccountsItem,
} from '@/condor/application/actions/accounts/item/actions';

const mapStateToProps = (state, ownProps) => ({
  isSelectedForEditing: (
    itemSelectors.isInlineEditing(state) && (itemSelectors.getItemData(state).id === ownProps.cellProps?.row?.id)
  ),
  isUpdating: itemSelectors.isUpdating(state),
  inlineEditFormState: {
    pristine: isPristine(CONDOR_ACCOUNT_TABLE_INLINE_EDIT_FORM_NAME)(state),
    invalid: isInvalid(CONDOR_ACCOUNT_TABLE_INLINE_EDIT_FORM_NAME)(state),
    submitting: isSubmitting(CONDOR_ACCOUNT_TABLE_INLINE_EDIT_FORM_NAME)(state),
  },
});

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators(
  {
    onEditClick: () => () => batch(() => {
      dispatch(setCondorAccountCurrentItem(ownProps.cellProps.row));
      dispatch(setCondorAccountsIsInlineEditing(true));
    }),
    onRestore: () => restoreCondorAccountsItem({
      id: ownProps.cellProps.row.id,
      name: ownProps.cellProps.row.name,
    }),
    onSubmit: () => submit(CONDOR_ACCOUNT_TABLE_INLINE_EDIT_FORM_NAME),
    onDeleteClick: () => () => batch(() => {
      dispatch(setCondorAccountCurrentItem(ownProps.cellProps.row));
      dispatch(setCondorAccountsIsDeleteModalVisible(true));
    }),
    onReset: resetCondorAccountsItem,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdatedAtCell);
