import { Button, ButtonVariantType, Modal } from '@dealroadshow/uikit';
import WhitelistContextProvider, { IWhitelistContextProps, useWhitelistContext } from '../WhitelistContext';
import WhitelistAddNew from '../WhitelistAddNew';
import WhitelistTable from '../WhitelistTable';

import styles from './whitelistModal.scss';

const WhitelistModal = () => {
  const { submit, close, tabName } = useWhitelistContext();

  return (
    <Modal
      className={ styles.modal }
      modalDialogClassName={ styles.modalDialog }
      usePortal
      dataTest="whitelistModal"
      isVisible
      closeOnEsc
      onCloseClicked={ close }
      title="Whitelist By Domain Or Email"
      footer={ (
        <>
          <Button dataTest="save" variant={ ButtonVariantType.action } onClick={ submit }>
            Save
          </Button>
          <Button dataTest="cancel" variant={ ButtonVariantType.text } onClick={ close }>
            Cancel
          </Button>
        </>
      ) }
    >
      <div className={ styles.modalHeading }>
        <span className={ styles.modalHeadingLabel }>Tab</span>
        <span className={ styles.modalHeadingValue }>{ tabName || '-' }</span>
      </div>
      <div className={ styles.addNewForm }>
        <WhitelistAddNew />
      </div>
      <WhitelistTable />
    </Modal>
  );
};
export default (props: IWhitelistContextProps) => (
  <WhitelistContextProvider { ...props }>
    <WhitelistModal />
  </WhitelistContextProvider>
);
