import { connect } from 'react-redux';
import MappingsProgressModal from './MappingsProgressModal';
import {
  closeCondorAccountMappingProgressModal,
} from '@/condor/application/actions/accounts/mappings/metadata/actions';
import contactsSelectors from '@/condor/application/actions/accounts/mappings/contacts/selectors';
import dealsSelectors from '@/condor/application/actions/accounts/mappings/deals/selectors';
import allocationsSelectors from '@/condor/application/actions/accounts/mappings/allocations/selectors';
import evercallsSelectors from '@/condor/application/actions/accounts/mappings/evercalls/selectors';
import { getNotesTotalCount } from '@/condor/application/actions/accounts/mappings/notes/selectors';
import leisSelectors from '@/condor/application/actions/accounts/mappings/leis/selectors';
import webDomainsSelectors from '@/condor/application/actions/accounts/mappings/webDomains/selectors';
import emailDomainsSelectors from '@/condor/application/actions/accounts/mappings/emailDomains/selectors';
import {
  getMappingsTypes,
  getRemappedItemCounts,
  isMappingFinished,
  isError,
} from '@/condor/application/actions/accounts/mappings/metadata/selectors';

const mapStateToProps = (state) => ({
  contacts: contactsSelectors.getTotalCount(state),
  deals: dealsSelectors.getTotalCount(state),
  allocations: allocationsSelectors.getTotalCount(state),
  evercalls: evercallsSelectors.getTotalCount(state),
  notes: getNotesTotalCount(state),
  leis: leisSelectors.getTotalCount(state),
  emailDomains: emailDomainsSelectors.getTotalCount(state),
  webDomains: webDomainsSelectors.getTotalCount(state),
  mappingsTypes: getMappingsTypes(state),
  remappedItemCounts: getRemappedItemCounts(state),
  isFinished: isMappingFinished(state),
  isError: isError(state),
});

const mapDispatchToProps = {
  onClose: closeCondorAccountMappingProgressModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MappingsProgressModal);
