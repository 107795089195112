import React from 'react';
import { format } from 'date-fns';
import utcToZonedDate from '@/Framework/DateTime/utcToZonedDate';
import { MONTH_DAY_YEAR_DATE_FULLTIME_FORMAT } from '@/Framework/DateTime/dateFormats';
import { statusCellWrapper } from '@/condor/ui/common/Layout/helpers';
import styles from './subscribersTable.scss';
import { LastTableCell } from '@/ui/shared/components/Tables/TableWrapper';
import { Tooltip } from '@dealroadshow/uikit';
import UnsubscribeButton
  from '@/condor/ui/components/Finsight/Subscribers/SubscribersList/SubscribersTable/UnsubscribeButton';
import {
  ONE_OFFS_SUBSCRIPTION_ID,
  REAL_TIME_SUBSCRIPTION_ID,
  DAILY_SUBSCRIPTION_ID,
  WEEKLY_SUBSCRIPTION_ID,
} from '@/condor/ui/components/Finsight/Subscribers/constants';

const getWeeklyCellContent = (subgroups) => subgroups.reduce(
  (acc, { shortName, subscriptions }, subgroupIndex) => {
    const subscriptionsString = subscriptions
      .reduce(
        (acc, { name }, subscriptionIndex) => {
          const isLastSubgroup = subgroupIndex === subgroups.length - 1;
          const isLastSubscription = subscriptionIndex === subscriptions.length - 1;
          return `${ acc }${ shortName }-${ name }${ isLastSubgroup && isLastSubscription ? '' : '; ' }`;
        },
        '',
      );
    return `${ acc }${ subscriptionsString }`;
  }, '',
);

const getDailyCellContent = (subgroups) => subgroups.reduce(
  (acc, { shortName, subscriptions }, subgroupIndex) => {
    const subscriptionsString = subscriptions
      .reduce(
        (acc, { name }, subscriptionIndex) => {
          const isLastSubscription = subscriptionIndex === subscriptions.length - 1;
          return `${ acc }${ name }${ isLastSubscription ? '' : ', ' }`;
        },
        '',
      );
    const isLastSubgroup = subgroupIndex === subgroups.length - 1;
    return `${ acc }${ shortName }-${ subscriptionsString }${ isLastSubgroup ? '' : '; ' }`;
  }, '',
);

const getTooltipContent = (subscription, isShortName?) => (
  <table className={ styles.subscriptionTooltipTable }>
    <tbody>
      { subscription.subgroups.map((subgroup) => (
        <tr key={ subgroup.id }>
          <td className={ styles.subscriptionRowName }>{ isShortName ? subgroup.shortName : subgroup.name }:</td>
          <td>
            { subgroup.subscriptions.map((subscriptionItem, index) => (
              <span key={ subscriptionItem.id }>
                { `${ subscriptionItem.name }${ index === subgroup.subscriptions.length - 1 ? '' : ', ' }` }
              </span>
          )) }
          </td>
        </tr>
    )) }
    </tbody>
  </table>
);

const getStatus = ({ row }) => (row.status !== 'subscribed' ? {
  tooltipContent: 'Unsubscribed',
  className: styles.unsubscribed,
} : {
  tooltipContent: 'Subscribed to Alerts',
  className: styles.subscribed,
});

export const cellStatusCallback = (cellProps) => statusCellWrapper(
  getStatus(cellProps),
);

export const lastTableCell = ({ row }) => {
  const { subscriptionCreatedAt } = row;

  return (
    <LastTableCell
      childrenToShowOnHover={ <UnsubscribeButton item={ row } /> }
      className={ styles.updatedAt }
    >
      { subscriptionCreatedAt
        ? `${ format(utcToZonedDate(
            subscriptionCreatedAt.timezone.timeZone,
              new Date(subscriptionCreatedAt.dateTime),
          ), MONTH_DAY_YEAR_DATE_FULLTIME_FORMAT) } ET`
        : '-' }
    </LastTableCell>
  );
};

export const oneOffsCellCallback = ({ row: { subscriptions } }) => {
  if (subscriptions.find((subscription) => subscription.id === ONE_OFFS_SUBSCRIPTION_ID)) {
    return (
      <div className={ styles.noWrap }>
        FINSIGHT new feature and product updates
      </div>
    );
  }
  return '-';
};

export const realTimeCellCallback = ({ row: { subscriptions } }) => {
  const realTimeAlerts = subscriptions.find((subscription) => subscription.id === REAL_TIME_SUBSCRIPTION_ID);
  if (realTimeAlerts) {
    return (
      <div className={ styles.noWrap }>
        { realTimeAlerts.subgroups[0].shortName }
      </div>
    );
  }
  return '-';
};

export const dailyCellCallback = ({ row: { subscriptions } }) => {
  const dailySubscriptions = subscriptions.find((subscription) => subscription.id === DAILY_SUBSCRIPTION_ID);
  if (!dailySubscriptions) {
    return '-';
  }
  return (
    <Tooltip content={ getTooltipContent(dailySubscriptions) }>
      <div className={ styles.noWrap }>{ getDailyCellContent(dailySubscriptions.subgroups) }</div>
    </Tooltip>
  );
};

export const weeklyCellCallback = ({ row: { subscriptions } }) => {
  const weeklySubscriptions = subscriptions.find((subscription) => subscription.id === WEEKLY_SUBSCRIPTION_ID);
  if (!weeklySubscriptions) {
    return '-';
  }
  return (
    <Tooltip content={ getTooltipContent(weeklySubscriptions, true) }>
      <div className={ styles.weeklyCell }>{ getWeeklyCellContent(weeklySubscriptions.subgroups) }</div>
    </Tooltip>
  );
};
