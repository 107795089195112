import { connect } from 'react-redux';
import { arrayMove } from 'redux-form';
import Tranches from './Tranches';
import { CONDOR_DEAL_PROFILE_FORM_NAME } from '@/condor/ui/common/constants';
import { DEAL_PROFILE_FIELDS } from '@/condor/ui/components/Finsight/DealProfile/constants';

const mapDispatchToProps = {
  moveTranche: (oldIndex, newIndex) => arrayMove(
    CONDOR_DEAL_PROFILE_FORM_NAME,
    DEAL_PROFILE_FIELDS.TRANCHES.name,
    oldIndex,
    newIndex,
  ),
};

export default connect(
  null,
  mapDispatchToProps,
)(Tranches);
