import DealRepository from '@/finsight/infrastructure/repository/DealRepository';
import { getCollectionActions } from '@/Framework/State/Redux/collectionFactory';

import actionTypes from './actionTypes';
import selectors from './selectors';

export const {
  getCollection: getDealsCollection,
  sort,
  itemsPerPage,
  paginate,
  reset: resetDealsCollection,
} = getCollectionActions(
  actionTypes,
  (payload, dispatch, getState) => {
    const dealRepository = getState().container.get(DealRepository);
    return dealRepository.getDealsForCondorAdmin(payload);
  },
);

export const getDealsByFilter = () => (dispatch, getState) => {
  const state = getState();

  dispatch(getDealsCollection(
    {
      filterType: 'missingPricing',
      filters: {},
      perPage: selectors.getPerPage(state),
      page: selectors.getPage(state),
      sortBy: selectors.getSortBy(state),
      sortOrder: selectors.getSortOrder(state),
    },
  ));
};
