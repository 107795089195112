import React from 'react';
import TableRowActions from '@/condor/ui/common/Layout/TableRowActions';
import { LastTableCell } from '@/ui/shared/components/Tables/TableWrapper';
import { Spinner, IconType } from '@dealroadshow/uikit';

interface IProps {
  isSelectedForEditing: boolean,
  isUpdating: boolean,
  inlineEditFormState: any,
  onSubmit: () => void,
  onCancel: () => void,
  onEditClick: () => void,
  onDeleteClick: () => void,
}

const ActionsCell = (props: IProps) => {
  const actions = props.isSelectedForEditing ?
    [
      {
        tooltipContent: 'Save',
        iconType: IconType.check,
        onClick: props.onSubmit,
        disabled: props.inlineEditFormState.submitting ||
                  props.inlineEditFormState.pristine ||
                  props.inlineEditFormState.invalid,
        dataTest: 'saveButton',
      },
      {
        tooltipContent: 'Cancel',
        iconType: IconType.cancel,
        onClick: props.onCancel,
        dataTest: 'cancelButton',
      },
    ] : [
      {
        tooltipContent: 'Edit',
        iconType: IconType.pencil,
        onClick: props.onEditClick,
        dataTest: 'editButton',
      },
      {
        tooltipContent: 'Delete',
        iconType: IconType.trash,
        onClick: props.onDeleteClick,
        dataTest: 'deleteButton',
      },
    ];

  return (
    <LastTableCell
      showHoverContent={ props.isSelectedForEditing }
      childrenToShowOnHover={
        !props.isUpdating
          ? <TableRowActions actions={ actions } />
          : (
            <Spinner
              size="small"
              isVisible={ props.isSelectedForEditing }
            />
          )
      }
    />
  );
};

export default ActionsCell;
