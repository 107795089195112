import { UserPlanType } from '@/users/domain/UserPlan';

export default {
  [UserPlanType.STARTER]: [
    { text: 'Keep', plan: UserPlanType.STARTER },
    { text: 'Upgrade to', plan: UserPlanType.PREMIUM },
    { text: 'Upgrade to', plan: UserPlanType.PRO },
  ],
  [UserPlanType.PREMIUM]: [
    { text: 'Keep', plan: UserPlanType.PREMIUM },
    { text: 'Upgrade to', plan: UserPlanType.PRO },
    { text: 'Downgrade to', plan: UserPlanType.STARTER },
  ],
  [UserPlanType.PRO]: [
    { text: 'Keep', plan: UserPlanType.PRO },
    { text: 'Downgrade to', plan: UserPlanType.PREMIUM },
    { text: 'Downgrade to', plan: UserPlanType.STARTER },
  ],
  All: [
    { text: UserPlanType.PROFESSIONAL.toLowerCase(), plan: UserPlanType.PRO },
    { text: UserPlanType.PREMIUM.toLowerCase(), plan: UserPlanType.PREMIUM },
    { text: UserPlanType.STARTER.toLowerCase(), plan: UserPlanType.STARTER },
  ],
};
