import URL from '@/finsight/Router/urlConstants';
import { INVENTORIES, BWICS, BWIC, INVENTORY } from '@/finsight/domain/BondType';

/**
 * @param {Object} location
 * @return {String}
 */
export const getBondTypeFromLocation = (location = '') => (
  location.indexOf(URL.BONDS_INVENTORIES) !== -1 ? INVENTORIES : BWICS
);

/**
 * @param {String} bondType
 * @return {String}
 */
export const bondTypeMapping = (bondType) => (bondType === BWICS ? BWIC : INVENTORY);
