import MappingsRepository from '@/users/infrastructure/repository/mappingsRepository';
import { getCollectionActions } from '@/Framework/State/Redux/collectionFactory';

import actionTypes from './actionTypes';
import selectors from './selectors';
import { getAccountId } from '../helpers';

export const {
  getCollection: getMappingsContactsCollection,
  sort,
  itemsPerPage,
  paginate,
  reset: resetMappingsContactsCollection,
} = getCollectionActions(
  actionTypes,
  (payload, dispatch, getState) => {
    const mappingsRepository: MappingsRepository = getState().container.get(MappingsRepository);
    return mappingsRepository.getContactsMappings(payload);
  },
);

export const getMappingsContactsByFilter = () => (dispatch, getState) => {
  const state = getState();
  const id = getAccountId(state);
  dispatch(getMappingsContactsCollection(
    {
      companyId: id,
      limit: selectors.getPerPage(state),
      offset: (selectors.getPage(state) - 1) * selectors.getPerPage(state),
      orderBy: selectors.getSortBy(state),
      orderAsc: selectors.getSortOrder(state) === 'asc',
    },
  ));
};
