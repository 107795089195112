import { getCollectionActions } from '@/Framework/State/Redux/collectionFactory';

import SubscriptionsRepository from '@/finsight/infrastructure/repository/SubscriptionsRepository';

import actionTypes from './actionTypes';
import selectors from './selectors';

export const {
  getCollection: getCondorFinsightSubsribersList,
  sort,
  paginate,
  itemsPerPage,
  setSearchQuery: setCondorFinsightSubscribersListSearch,
  reset: resetCondorFinsightSubscribersList,
} = getCollectionActions(
  actionTypes,
  (payload, dispatch, getState) => {
    const subscriptionsRepository = getState().container.get(SubscriptionsRepository);
    return subscriptionsRepository.getSubscribersListByAdmin(payload);
  },
);

export const getCondorFinsightSubsribersListByFilter = () => (dispatch, getState) => {
  const state = getState();
  const sortBy = selectors.getSortBy(state);

  const payload = {
    sortBy,
    sortOrder: selectors.getSortOrder(state),
    page: selectors.getPage(state),
    perPage: selectors.getPerPage(state),
    search: selectors.getSearchQuery(state),
  };
  dispatch(getCondorFinsightSubsribersList(payload));
};
