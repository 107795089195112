import { connect } from 'react-redux';
import BulkDeleteIcon from './BulkDeleteIcon';
import { bulkDeleteAccounts } from '@/condor/application/actions/accounts/collection/actions';
import selectors from '@/condor/application/actions/accounts/collection/selectors';

const mapStateToProps = (state) => ({
  bulkDeleteItems: selectors.bulkDeleteItems(state),
});

const mapDispatchToProps = {
  bulkDelete: (ids) => bulkDeleteAccounts(ids),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BulkDeleteIcon);
