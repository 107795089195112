import { format } from 'date-fns';
import FinsightDealRepository from '@/finsight/infrastructure/repository/DealRepository';
import { getItemActions } from '@/Framework/State/Redux/itemFactory';
import actionTypes from './actionTypes';
import createAction from '@/Framework/State/Redux/createAction';
import selectors from './selectors';
import companySelectors from '../../item/selectors';

import NotificationManager from '@/ui/shared/components/Notification/NotificationManager';

import { YEAR_MONTH_DAY_DASH_FORMAT } from '@/Framework/DateTime/dateFormats';
import { getCondorFinsightCompanyDealsByFilter } from '@/condor/application/actions/finsight/company/deals/collection/actions';
import { getCompanyInformationForMigration } from '@/condor/application/actions/finsight/company/item/actions';

/**
 * @param {Boolean} payload
 */
export const setCondorFinsightCompanyDealIsInlineEditing = (payload) => createAction(
  actionTypes.SET_CONDOR_FINSIGHT_COMPANY_DEAL_IS_INLINE_EDITING,
  payload,
);

/**
 * @param {Boolean} payload
 */
export const setCondorFinsightCompanyDealIsDeleteModalVisible = (payload) => createAction(
  actionTypes.SET_CONDOR_FINSIGHT_COMPANY_DEAL_IS_DELETE_MODAL_VISIBLE,
  payload,
);

export const {
  setCurrentItem: setCondorFinsightCompanyCurrentDeal,
  reset: resetCondorFinsightCompanyDealItem,
  updateItem: updateCondorFinsightCompanyDeal,
  removeItem: removeCondorFinsightCompanyDeal,
} = getItemActions(
  actionTypes,
  {
    update: {
      method: (payload, dispatch, getState) => {
        const finsightDealRepository = getState().container.get(FinsightDealRepository);
        return finsightDealRepository.partialDealUpdate(payload);
      },
      callbacks: {
        onError: ({ error }) => {
          NotificationManager.error(error.error.message);
        },
      },
    },
    remove: {
      method: (payload, dispatch, getState) => {
        const finsightDealRepository = getState().container.get(FinsightDealRepository);
        return finsightDealRepository.deleteDealById(payload);
      },
      callbacks: {
        onSuccess: ({ dispatch, getState }) => {
          dispatch(setCondorFinsightCompanyDealIsInlineEditing(false));
          dispatch(setCondorFinsightCompanyDealIsDeleteModalVisible(false));
          dispatch(getCompanyInformationForMigration({ id: companySelectors.getItemData(getState()).id }));
          dispatch(getCondorFinsightCompanyDealsByFilter());
        },
      },
    },
  },
);

/**
 * @param {Object} formData
 */
export const submitCondorFinsightCompanyDealsInlineForm = (formData) => async (dispatch, getState) => {
  const deal = selectors.getItemData(getState());

  const payload = {
    countryId: deal.country.id,
    dealId: deal.id,
    parentId: formData.parent.value,
    seriesName: formData.seriesName,
    productId: formData.product.value,
    sectorId: formData.sector?.sectorId || null,
    subsectorId: formData.sector?.value || null,
    primaryIssuerId: formData.primaryIssuer.value,
    pricingDate: formData.pricingDate ?
      format(new Date(formData.pricingDate), YEAR_MONTH_DAY_DASH_FORMAT)
      : null,
  };
  await dispatch(updateCondorFinsightCompanyDeal(payload));
};
