import React from 'react';
import TableRowActions from '@/condor/ui/common/Layout/TableRowActions';
import { LastTableCell } from '@/ui/shared/components/Tables/TableWrapper';
import { Spinner, IconType } from '@dealroadshow/uikit';
import spacesStyles from '@/ui/shared/styles/helpers/spaces.scss';
import { useLocation } from 'react-router';
import styles from './actionsCell.scss';

import { CONDOR_FINSIGHT_COMPANY_DEALS_BASE_URL, CONDOR_FINSIGHT_CREATE_DEAL_BASE_URL } from '@/condor/ui/common/constants';

interface IProps {
  row: any,
  isSelectedForEditing: boolean,
  isInlineEditing: boolean,
  isUpdating: boolean,
  inlineEditFormState: any,
  onSubmit: () => void,
  onCancel: () => void,
  onEditClick: () => void,
  onDeleteClick: (pathname: string) => void,
}

const ActionsCell = (props: IProps) => {
  const { pathname } = useLocation();

  const actions = props.isSelectedForEditing && props.isInlineEditing ?
    [
      {
        tooltipContent: 'Save',
        iconType: IconType.check,
        onClick: props.onSubmit,
        disabled: props.inlineEditFormState.submitting ||
                  props.inlineEditFormState.pristine ||
                  props.inlineEditFormState.invalid,
        dataTest: 'saveChangesAction',
      },
      {
        tooltipContent: 'Cancel',
        iconType: IconType.cancel,
        onClick: props.onCancel,
        dataTest: 'cancelChangesAction',
      },
    ] : [
      {
        tooltipContent: 'Add New Deal',
        iconType: IconType.addCircle,
        url: `${ CONDOR_FINSIGHT_CREATE_DEAL_BASE_URL }?issuerId=${ props.row.id }`,
        className: spacesStyles.mrl,
        dataTest: 'addNewDealAction',
      },
      {
        tooltipContent: 'View deals',
        iconType: IconType.list,
        url: `${ CONDOR_FINSIGHT_COMPANY_DEALS_BASE_URL }/${ props.row.id }`,
        className: spacesStyles.mrl,
        dataTest: 'viewDealsAction',
      },
      {
        tooltipContent: 'Edit',
        iconType: IconType.pencil,
        onClick: props.onEditClick,
        className: spacesStyles.mrl,
        dataTest: 'editAction',
      },
      {
        tooltipContent: 'Delete',
        iconType: IconType.trash,
        onClick: () => props.onDeleteClick(pathname),
        dataTest: 'deleteAction',
      },
    ];

  return (
    <LastTableCell
      showHoverContent={ props.isSelectedForEditing }
      childrenToShowOnHover={
        !props.isUpdating
          // @ts-ignore
          ? <TableRowActions actions={ actions } tableRowActionsClassName={ styles.actions } />
          : (
            <Spinner
              size="small"
              isVisible={ props.isSelectedForEditing }
            />
          )
      }
    />
  );
};

export default ActionsCell;
