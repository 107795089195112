import { useParams, useHistory } from 'react-router';
import EvercallAdminRepository from '@/evercall/infrastructure/repository/EvercallAdminRepository';
import { useDIContext } from '@/Framework/DI/DIContext';
import useItemFactory from '@/Framework/State/useItemFactory';
import { IAdminCall } from '@/evercall/domain/vo/call/admin/AdminCall';
import { CONDOR_EVERCALL_LIST } from '@/condor/ui/common/constants';
import { INITIAL_CALL_ITEM_STATE } from './constants';

export default () => {
  const { container } = useDIContext();
  const evercallAdminRepository: EvercallAdminRepository = container.get(EvercallAdminRepository);

  const history = useHistory();
  const { callId: callIdFromUrl } = useParams();

  const {
    state: itemState,
    getItemData,
    setItem,
  } = useItemFactory<IAdminCall, typeof evercallAdminRepository.getCallData>(
    {
      initialItemState: INITIAL_CALL_ITEM_STATE,
      getRequestConfig: {
        method: evercallAdminRepository.getCallData,
        callbacks: {
          onError: () => {
            history.push(CONDOR_EVERCALL_LIST);
          },
        },
      },
    },
  );

  return {
    state: {
      ...itemState,
    },
    setItem,
    initCallProfile: () => getItemData({ id: callIdFromUrl }),
  };
};
