import memoize from '@/ui/shared/helpers/memoize';
import styles from './contacts.scss';

/**
 * @param {Object} contactData
 */
export const getStatus = memoize((contactData) => {
  const {
    banned,
    activated,
    emailVerified,
  } = contactData;

  if (banned) {
    return {
      id: 'banned-contact',
      tooltipContent: 'Banned Contact',
      className: styles.bannedContact,
    };
  }
  if (activated && emailVerified) {
    return {
      id: 'registered-contact',
      tooltipContent: 'Registered Contact',
      className: styles.activatedContact,
    };
  }
  if (!activated && !emailVerified) {
    return {
      id: 'unregistered-contact',
      tooltipContent: 'Unregistered Contact',
      className: styles.unactivatedContact,
    };
  }
  if (!activated && emailVerified) {
    return {
      id: 'verified-investor',
      tooltipContent: 'Verified Investor',
      className: styles.verifiedInvestor,
    };
  }

  return {
    id: 'default-status',
    tooltipContent: 'Unknown Status',
    className: styles.defaultStatus,
  };
});
