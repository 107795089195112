import { createContext, useContext, ReactNode, useState, useMemo } from 'react';

const useUploadImageInput = () => {
  const [canSaveState, setCanSaveState] = useState<Record<string, boolean>>({});

  const isAllUploadCanSave = useMemo(() => {
    const allLoaders = Object.values(canSaveState);

    return allLoaders.length === 0 || allLoaders.every(Boolean);
  }, [canSaveState]);

  const setCanSave = (key: string, value: boolean) => {
    setCanSaveState((canSaveState) => ({
      ...canSaveState,
      [key]: value,
    }));
  };

  return {
    isAllUploadCanSave,
    setCanSave,
  };
};

type UploadImageInputContextType = ReturnType<typeof useUploadImageInput>;

export const UploadImageInputContext = createContext<UploadImageInputContextType>(null);

export const useUploadImageInputContext = () => {
  const context = useContext(UploadImageInputContext);

  if (!context) {
    throw new Error('useUploadImageInputContext must be used within the UploadImageInputContext');
  }

  return context;
};

interface IProps {
  children: ReactNode,
}

const UploadImageInputContextProvider = ({ children }: IProps) => (
  <UploadImageInputContext.Provider value={ useUploadImageInput() }>{ children }</UploadImageInputContext.Provider>
);

export default UploadImageInputContextProvider;
