import React, { memo } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { CONDOR_FINSIGHT_CREATE_COMPANY_BASE_URL } from '@/condor/ui/common/constants';
import Button, { variantTypes } from '@/ui/shared/components/Button';

import PageWrapper from '@/condor/ui/common/Layout/PageWrapper';
import TableWrapper from '@/ui/shared/components/Tables/TableWrapper';
import { Spinner, Icon, IconType } from '@dealroadshow/uikit';

import CompaniesTable from './CompaniesTable';
import CompaniesTablePaginator from './CompaniesTable/CompaniesTablePaginatorContainer';
import SearchInputContainer from './SearchInputContainer';
import DeleteModal from './DeleteModal';
import MigrateModal from '../MigrateModal';

import styles from './companiesList.scss';
import spacesStyles from '@/ui/shared/styles/helpers/spaces.scss';
import btnStyles from '@/ui/shared/components/Button/button.scss';
import cardStyles from '@/ui/shared/styles/card.scss';
import floatStyles from '@/ui/shared/styles/helpers/floats.scss';

interface IProps {
  isDeleteModalVisible: boolean,
  isMigrateModalVisible: boolean,
  isInformationForMigrationFetching: boolean,
  reset: () => void,
  init: () => void,
}

const CompaniesList = (props: IProps) => {
  const { mlm, plxl, prxl } = spacesStyles;
  return (
    <PageWrapper
      label="Companies"
      onMount={ props.init }
      onUnmount={ props.reset }
      headerComponent={ (
        <Link to={ CONDOR_FINSIGHT_CREATE_COMPANY_BASE_URL }>
          <Button
            dataTest="condorCompaniesListActionButton"
            variant={ variantTypes.action }
            className={ cn(btnStyles.btnIcon, plxl, prxl, floatStyles.pullRight) }
          >
            <span>Create Company</span>
            <Icon
              type={ IconType.plusBold }
              className={ mlm }
            />
          </Button>
        </Link>
      ) }
    >
      <TableWrapper
        label="Companies"
        className={ styles.tableWrapper }
        filterComponent={ (
          <SearchInputContainer
            className={ styles.searchFilterInput }
            placeholder="Filter by ID, Name, Industry, UW abbr or Ticker"
            isNarrow
            dataTest="companiesListSearchInput"
          />
        ) }
      >
        <CompaniesTable />
        <CompaniesTablePaginator className={ cardStyles.cardInner } dataTest="finsightCompanies" />
        { props.isDeleteModalVisible && (
          <DeleteModal />
        ) }
        { props.isMigrateModalVisible && (
          <MigrateModal />
        ) }
        { props.isInformationForMigrationFetching && (
          <Spinner isVisible overlay isFixed />
        ) }
      </TableWrapper>
    </PageWrapper>
  );
};

export default memo(CompaniesList);
