import { connect } from 'react-redux';
import { submitContactProfile } from '@/condor/application/actions/contacts/item/actions';
import ProfileForm from './ProfileForm';
import * as userSelectors from '@/users/application/actions/data/selectors';
import { generateTwoFactorCode } from '@/users/application/actions/data/actions';
import { bindActionCreators } from 'redux';

const mapStateToProps = (state) => (
  {
    contact: userSelectors.getUser(state),
    isTwoFactorCodeGenerating: userSelectors.isTwoFactorCodeGenerating(state),
  }
);

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onSubmit: submitContactProfile,
    generateTwoFactorCode: (id) => dispatch(generateTwoFactorCode(id)),
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileForm);
