export const HEX_COLOR_WHITE = '#fff';
export const HEX_COLOR_WHITE_SHORT = '#fff';
export const HEX_COLOR_BLACK = '#000';
export const HEX_COLOR_BLACK_SHORT = '#000';

export const FILL_COLORS =
  [
    HEX_COLOR_WHITE, HEX_COLOR_WHITE_SHORT,
    HEX_COLOR_BLACK, HEX_COLOR_BLACK_SHORT,
    '',
  ];

export const DEFAULT_PRESET_COLORS =
  [
    '#f3f3f3',
    '#f2fff2',
    '#ffeded',
    '#f7b7b7',
    '#ffe484',
    '#84deaa',
    '#d1389b',
    '#5b97d7',
    '#38ac9f',
    '#1f4f95',
    '#2d2d2d',
  ];

export const HEX_COLOR_TRANSPARENT = 'transparent';

export const COLOR_PICKER_PANEL = {
  INPUT_STYLE: {
    input: {
      border: 'none',
      width: '100%',
      padding: '4px 10% 3px',
      fontSize: '14px',
      lineHeight: '20px',
      height: 34,
      color: '#2d2d2d',
      alignItems: 'center',
      letterSpacing: '0.01em',
    },
    label: {
      display: 'block',
      textAlign: 'center',
      fontSize: '11px',
      color: '#222',
      paddingTop: '3px',
      paddingBottom: '4px',
      textTransform: 'capitalize',
    },
  },
};

export const COLOR_PICKER_PRESET_COLORS = {
  swatch: {
    borderRadius: '3px',
  },
  selected: {
    border: '1px solid #8C8C8C',
  },
  focus: {
    border: '1px solid #8C8C8C',
  },
};

export const COLOR_TYPES_NAMES = {
  HEX: {
    value: 'hex',
    label: 'HEX',
  },
  RGB: {
    value: 'rgb',
    label: 'RGB',
  },
  HSL: {
    value: 'hsl',
    label: 'HSL',
  },
};
