import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Links from './Links';
import {
  generateUserPasswordResetLink,
  generateUserActivationLink,
  setUserLinksSectionApplication,
  setUserInvalidatePasswordModalIsVisible,
} from '@/users/application/actions/links/actions';

const mapStateToProps = (state) => (
  {
    passwordReset: state.user.links.passwordReset,
    activation: state.user.links.activation,
    verification: state.user.links.verification,
  }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    setUserPasswordResetApplication: (value) => setUserLinksSectionApplication('passwordReset', value),
    setUserActivationApplication: (value) => setUserLinksSectionApplication('activation', value),
    openInvalidatePasswordModal: () => setUserInvalidatePasswordModalIsVisible({ isVisible: true }),
    generateResetLink: generateUserPasswordResetLink,
    generateActivationLink: generateUserActivationLink,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Links);
