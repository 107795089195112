import { useEffect, useReducer } from 'react';
import getActions from './getActions';
import reducer, { IReducerState, initialState } from './reducer';
import { IReactReducerDispatch } from '@/Framework/State/createAsyncRequest';
import { useUploadImageInputContext } from '../UploadImageInputContext';
import { TUploadMethod } from '../interfaces';

/**
 * This hook can be used to implement UploadLogo, UploadLogoDisclaimer and UploadBackgroundImage without Redux.
 * The outdated logic in the related "actions" and "reducer" files is almost identical for these components.
 * */
export default (
  name: string,
  uploadMethod: TUploadMethod,
): { state: IReducerState } & ReturnType<typeof getActions> => {
  const [state, dispatch]: [IReducerState, IReactReducerDispatch] = useReducer(reducer, initialState);

  const { setCanSave } = useUploadImageInputContext();

  useEffect(() => {
    setCanSave(name, state.canSave);
  }, [name, state.canSave]);

  return {
    state,
    ...getActions({ imageProcessUuid: state.process.uuid, uploadMethod }, dispatch),
  };
};
