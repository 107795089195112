import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import Tranche from './Tranche';

import { CONDOR_DEAL_PROFILE_FORM_NAME } from '@/condor/ui/common/constants';
import { DEAL_PROFILE_FIELDS } from '../../constants';
import Product from '@/finsight/domain/Product';
import Sector from '@/finsight/domain/Sector';

import { mapOptionToValue } from '@/Framework/dataHelpers/selectOptionMappers';
import selectors from '@/condor/application/actions/finsight/deal/selectors';

const formSelector = formValueSelector(CONDOR_DEAL_PROFILE_FORM_NAME);

const mapStateToProps = (state, ownProps) => {
  const product = formSelector(state, DEAL_PROFILE_FIELDS.PRODUCT.name);
  const sectorSubsector = formSelector(state, DEAL_PROFILE_FIELDS.ABS_SECTOR_SUBSECTOR.name);
  return (
    {
      options: selectors.getOptions(state),
      isSectorCmbs: Sector.isCmbsSectorId(sectorSubsector?.sectorId),
      isProductAbs: Product.isAbsProductId(mapOptionToValue(product)),
      isExcluded: formSelector(state, `${ ownProps.field }.${ DEAL_PROFILE_FIELDS.TRANCHE.IS_EXCLUDED.name }`),
    }
  );
};

export default connect(
  mapStateToProps,
)(Tranche);
