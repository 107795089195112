import { IDisplayTab } from './interfaces';
import { transactionTypes } from '@/dealroadshow/domain/TransactionTypes';
import { createNewTab } from './helpers';

export enum DisplayTabFormFieldName {
  NAME = 'name',
  DISPLAY = 'display',
  TRANSACTION_TYPES = 'transactionTypes',
  REGIONS = 'regions',
  EMAIL = 'email',
  WHITELIST = 'whitelist',
}

export const getDefaultDisplayTabs = (): IDisplayTab[] => [
  createNewTab({
    name: 'Asset Backed Securities',
    transactionTypes: [transactionTypes.BondSecuritization, transactionTypes.Other],
  }),
  createNewTab({
    name: 'Investment Grade',
    transactionTypes: [transactionTypes.BondInvestmentGrade],
  }),
  createNewTab({
    name: 'High Yield',
    transactionTypes: [transactionTypes.BondHighYield],
  }),
  createNewTab({
    name: 'Equities',
    transactionTypes: [
      transactionTypes.EquityFollowOn,
      transactionTypes.EquityGDR,
      transactionTypes.EquityIPO,
      transactionTypes.EquityPDIE,
      transactionTypes.EquityPrivate,
      transactionTypes.EquitySPAC,
      transactionTypes.EquityEarlyStage,
      transactionTypes.EquityPilotFishing,
      transactionTypes.EquityWallCross,
    ],
  }),
];
