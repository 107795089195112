import { isValidElementType } from 'react-is';
import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';

/**
 * This one will do all the magic
 * Reuse Route priorities children -> component -> render and wrap child with layout
 *
 * @param props
 * @return {{render}|*}
 */
export const calculateProps = (props) => {
  const newProps = { ...props };
  let { layout: Layout, component: Component, children, render } = newProps;
  const getComponent = (props) => (
    isValidElementType(Component)
      ? <Component { ...props } />
      : Component

  );
  if (Layout) {
    newProps.render = (props) => (
      <Layout
        { ...newProps }
        { ...props }
      >
        { children || getComponent(props) || render(props) }
      </Layout>
    );
  }
  if (newProps.render) {
    delete newProps.component;
    delete newProps.children;
  }

  return newProps;
};

/**
 * @param {undefined|Function|Array} beforeEnter
 */
export const getShouldRenderInitialValue = (beforeEnter) => {
  if (Array.isArray(beforeEnter)) {
    return !beforeEnter.length;
  }
  return !beforeEnter || isEqual(noop, beforeEnter);
};
