import { useCallback, useEffect } from 'react';
import { DndContext, useSensor, useSensors, TouchSensor, MouseSensor, closestCenter } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import cn from 'classnames';
import {
  TRANCHES_VISIBLE,
} from '@/condor/ui/components/Finsight/DealProfile/constants';
import Tranche from './Tranche';
import { useTranchesContext } from './TranchesContext';

import styles from './tranches.scss';

interface IProps {
  moveTranche: (oldIndex: number, newIndex: number) => void,
  fields: { [key: string]: any },
}

const TranchesSortableList = ({ fields, moveTranche }: IProps) => {
  const { tranchesViewOffset, setTranchesFieldArrayLength } = useTranchesContext();

  const someArrowVisible = fields.length > TRANCHES_VISIBLE;
  const leftArrowVisible = someArrowVisible && tranchesViewOffset > 0;
  const rightArrowVisible = someArrowVisible && fields.length !== tranchesViewOffset + TRANCHES_VISIBLE;

  const onDragEnd = useCallback(({ active, over }) => {
    if (active.id !== over.id) {
      const oldIndex = Number(active.id);
      const newIndex = Number(over.id);

      moveTranche(oldIndex, newIndex);
    }
  }, []);

  useEffect(() => {
    setTranchesFieldArrayLength(fields.length);
  }, [fields.length]);

  const sensors = useSensors(useSensor(TouchSensor), useSensor(MouseSensor));

  return (
    <DndContext
      modifiers={ [restrictToParentElement] }
      onDragEnd={ onDragEnd }
      collisionDetection={ closestCenter }
      sensors={ sensors }
    >
      <div
        className={ cn(styles.tranchesContainer, {
          [styles.withRightArrowButton]: rightArrowVisible,
          [styles.withLeftArrowButton]: leftArrowVisible,
        }) }
      >
        <SortableContext items={ fields.map((_, idx) => idx.toString()) }>
          { fields.map((field, idx) => (
            <Tranche
              // eslint-disable-next-line react/no-array-index-key
              key={ idx }
              id={ idx.toString() }
              field={ field }
              trancheIndex={ idx }
            />
          )) }
        </SortableContext>
      </div>
    </DndContext>
  );
};

export default TranchesSortableList;
