import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import RoadshowRepository from '@/dealroadshow/infrastructure/repository/RoadshowRepository';
import { NotificationManager } from '@/ui/shared/components/Notification';
import * as actionTypes from './actionTypes';

/**
 * ROADSHOWS_TABLE_FILTER
 */

/**
 *
 * @param {{value:string}} payload
 * @return {function(*)}
 */
export function setAnalyticsTableFilterSearch(payload) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ANALYTICS_TABLE_FILTER_SEARCH,
      payload,
    });
  };
}

/**
 *
 * @param {{value:string}} payload
 * @return {function(*)}
 */
export function setAnalyticsTableFilterPagination(payload) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ANALYTICS_TABLE_FILTER_PAGINATION,
      payload,
    });
  };
}

/**
 *
 * @param {{value:string}} payload
 * @return {function(*)}
 */
export function setAnalyticsTableFilterPerPage(payload) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ANALYTICS_TABLE_FILTER_PER_PAGE,
      payload,
    });
  };
}

/**
 *
 * @param {{sortBy: string, sortOrder: string}} payload
 * @return {function(*)}
 */
export function setAnalyticsTableSort(payload) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ANALYTICS_TABLE_SORT,
      payload,
    });
  };
}

/**
 * @return {Object}
 * @param {Array} collection
 * @param {Object} timeZone
 */
function requestAnalyticsSuccess(collection, timeZone) {
  return {
    type: actionTypes.REQUEST_ANALYTICS_SUCCESS,
    payload: {
      collection,
      timeZone,
    },
  };
}

/**
 * @param {Number} payload
 * @return {Object}
 */
function requestAnalyticsTotalCountSuccess(payload) {
  return {
    type: actionTypes.REQUEST_ANALYTICS_TOTAL_COUNT,
    payload,
  };
}

/**
 * @param {Error} errorResponse
 * @return {Object}
 */
function requestAnalyticsError(errorResponse) {
  NotificationManager.error(getErrorMessage(errorResponse));
  return {
    type: actionTypes.REQUEST_ANALYTICS_ERROR,
    error: errorResponse,
  };
}

/**
 * @param {String} roadshowId
 */
export function getAnalytics(roadshowId) {
  return async (dispatch, getState) => {
    const roadshowRepository = getState().container.get(RoadshowRepository);
    dispatch({ type: actionTypes.REQUEST_ANALYTICS });
    try {
      let filterState = getState().dealRoadshows.roadshow.analytics.tableFilter;
      let roadshowDealSettings = await roadshowRepository.getAdminDealSettings(roadshowId);
      const requestData = {
        filters: {
          roadshowId,
          search: filterState.search,
        },
        sortBy: filterState.sort.sortBy,
        sortOrder: filterState.sort.sortOrder.toUpperCase(),
        page: filterState.paginationGroup.activePage,
        perPage: filterState.paginationGroup.perPage,
        timeZone: roadshowDealSettings.timeZone.timeZone,
      };
      let roadshows = await roadshowRepository.getAdminAnalytics(requestData);
      dispatch(requestAnalyticsSuccess(roadshows.collection, roadshowDealSettings.timeZone));
      dispatch(requestAnalyticsTotalCountSuccess(roadshows.totalCount));
    } catch (err) {
      dispatch(requestAnalyticsError(err));
    }
  };
}

/**
 * @param {{
 *  roadshowId: String,
 *  contactId: Number|undefined
 * }} requestData
 */
export function roadshowClearStats(requestData) {
  return async (dispatch, getState) => {
    const roadshowRepository = getState().container.get(RoadshowRepository);
    dispatch({ type: actionTypes.REQUEST_ANALYTICS });
    try {
      await roadshowRepository.roadshowAdminClearStats(requestData);
      dispatch(getAnalytics(requestData.roadshowId));
    } catch (err) {
      dispatch(requestAnalyticsError(err));
    }
  };
}

export function resetAnalytics() {
  return async (dispatch) => {
    dispatch({ type: actionTypes.ANALYTICS_RESET });
    dispatch({ type: actionTypes.ANALYTICS_TABLE_RESET });
  };
}
