import { connect } from 'react-redux';
import {
  removeCondorFinsightCompanyDeal,
  setCondorFinsightCompanyDealIsDeleteModalVisible,
  resetCondorFinsightCompanyDealItem,
} from '@/condor/application/actions/finsight/company/deals/item/actions';
import selectors from '@/condor/application/actions/finsight/company/deals/item/selectors';
import DeleteDealModal from './DeleteDealModal';

const mapStateToProps = (state) => (
  {
    isVisible: selectors.isDeleteModalVisible(state),
    isDeleting: selectors.isRemoving(state),
    dealId: selectors.getItemData(state).id,
  }
);

const mapDispatchToProps = (dispatch) => ({
  deleteDeal: (dealId) => dispatch(removeCondorFinsightCompanyDeal(dealId)),
  onClose: () => {
    dispatch(resetCondorFinsightCompanyDealItem());
    dispatch(setCondorFinsightCompanyDealIsDeleteModalVisible(false));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteDealModal);
