import React from 'react';
import { Tooltip, Icon, IconType } from '@dealroadshow/uikit';

import styles from './styles.scss';

const CusipCell = ({ row: tranche }) => {
  const cusips = [...(tranche.cusips || []), ...(tranche.cusipsRegs || [])];

  return (
    <>
      {
        cusips.map((cusip: string) => (
          <div key={ cusip }>
            { cusip }
            <Tooltip content="Due to CUSIP conflict, mapping this trade to this tranche requires manual CUSIP removal">
              <Icon type={ IconType.warning } className={ styles.conflictIcon } />
            </Tooltip>
          </div>
        ))
      }
    </>
  );
};

export default CusipCell;
