import { connect } from 'react-redux';
import BulkDeleteCell from './BulkDeleteCell';
import itemSelectors from '@/condor/application/actions/accounts/collection/selectors';
import { toggleBulkDelete } from '@/condor/application/actions/accounts/collection/actions';

const mapStateToProps = (state) => ({
  bulkDeleteItems: itemSelectors.bulkDeleteItems(state),
});

const mapDispatchToProps = {
  toggleBulkDelete,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BulkDeleteCell);
