import { connect } from 'react-redux';
import {
  setManageSubscriptionsModalData,
} from '@/condor/application/actions/finsight/subscriptions/metadata/actions';

import UnsubscribeButton from './UnsubscribeButton';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClick: () => {
    dispatch(setManageSubscriptionsModalData({
      isModalOpen: true,
      item: ownProps.item,
    }));
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(UnsubscribeButton);
