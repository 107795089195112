export const dealFilesTableFilterConfig = {
  search: {
    placeholder: 'Filter by Name, File Name or File Type',
    debounceValue: 500,
  },
  radio: {
    type: {
      placeholder: 'File Type',
      group: [
        {
          label: 'Presentation',
          value: 'presentation',
        },
        {
          label: 'Synced Audio/Video',
          value: 'presentationMedia',
        },
        {
          label: 'Deal Documents',
          value: 'file',
        },
        {
          label: 'Media',
          value: 'commercial_media',
        },
        {
          label: 'Logo',
          value: 'logo',
        },
        {
          label: 'All',
          value: 'all',
        },
      ],
    },
    status: {
      placeholder: 'Status',
      group: [
        {
          label: 'Active Files',
          value: 'active',
        },
        {
          label: 'Deleted Files',
          value: 'deleted',
        },
        {
          label: 'All',
          value: 'all',
        },
      ],
    },
  },
  paginationGroup: {
    perPage: {
      list: [50, 100, 200],
    },
  },
};

export const roadShowsTableFilterConfig = {
  search: {
    placeholder: 'Filter by Deal, Entry Code & Managers',
    debounceValue: 500,
  },
  radio: {
    default:
      {
        group: [
          {
            text: 'Active',
            value: 'active',
          },
          {
            text: 'Inactive',
            value: 'inactive',
          },
          {
            text: 'Hidden',
            value: 'hidden',
          },
          {
            text: 'Deleted',
            value: 'deleted',
          },
          {
            text: 'All',
            value: 'all',
          },
        ],
      },
  },
  paginationGroup: {
    perPage: {
      list: [10, 25, 50, 100, 200],
    },
  },
};
