import { NAME_MIN_LENGTH } from '@/users/domain/User';
import { groupValidators } from '@/ui/shared/validators/helpers';
import { validateRequiredField } from '@/ui/shared/validators/validateRequiredField';
import { validateMinLength } from '@/ui/shared/validators/validateMinLength';
import { validateMaxLength } from '@/ui/shared/validators/validateMaxLength';

export default ({ industrySubindustry, abbreviation }) => (values) => groupValidators(
  validateMinLength(
    {
      fieldName: 'name',
      fieldCaption: 'Company Name',
      value: values.name,
      length: NAME_MIN_LENGTH,
    },
  ),
  validateMaxLength(
    {
      fieldName: 'name',
      fieldCaption: 'Company Name',
      value: values.name,
      length: 128,
    },
  ),
  industrySubindustry && validateRequiredField({
    fieldName: 'industrySubindustry',
    fieldCaption: 'Industry / Subindustry',
    value: values.industrySubindustry,
  }),
  abbreviation && validateRequiredField({
    fieldName: 'abbreviation',
    fieldCaption: 'Underwriter 3-letter abbreviation',
    value: values.abbreviation,
  }),
  validateRequiredField(
    {
      fieldName: 'name',
      fieldCaption: 'Company Name',
      value: values.name,
    },
  ),
);
