import React from 'react';
import { Redirect, Switch } from 'react-router';
import Route from '@/ui/shared/components/ReactRouter/Route';
import Datarooms from '@/dataroom/ui/components/Condor/Datarooms';
import EditDataroom from '@/dataroom/ui/components/Condor/EditDataroom';
import TenantContext from '@/Framework/Tenant/TenantContext';
import config from '@/dataroom/application/config/config';

const CondorRouter = () => (
  <Route
    path="/core/:tenant"
    render={ (props) => {
        const tenants = [
          config.tenant.tenant17g5.code,
          config.tenant.tenantDealvdr.code,
          config.tenant.tenantInvestorSet.code,
          config.tenant.tenantVeriSend.code,
        ];

        if (!tenants.includes(props.match.params.tenant)) {
          return <Redirect to={ '/' } />;
        }

        return (
          <TenantContext tenant={ props.match.params.tenant } useReduxStore={ false }>
            <Switch>
              <Route
                exact
                path="/core/:tenant"
                render={ () => (<Datarooms key={ props.match.params.tenant } />) }
              />
              <Route
                exact
                path={ '/core/:tenant/:dataroomId/details' }
                component={ EditDataroom }
              />
            </Switch>
          </TenantContext>
        );
    } }
  />
);

export default CondorRouter;
