import actionTypes from './actionTypes';
import { getCollectionActions } from '@/Framework/State/Redux/collectionFactory';
import IndustryRepository from '@/finsight/infrastructure/repository/IndustryRepository';

export const {
  getCollection: getCondorFinsightIndustries,
  reset: resetCondorFinsightIndustries,
} = getCollectionActions(
  actionTypes,
  (payload, dispatch, getState) => {
    const industryRepository = getState().container.get(IndustryRepository);
    return industryRepository.getList(payload);
  },
);
