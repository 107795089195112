import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getAccountTypesStatsByFilter,
  resetAccountTypesCollection,
} from '@/condor/application/actions/accountTypes/collection/actions';
import AccountTypesList from './AccountTypesList';

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    init: getAccountTypesStatsByFilter,
    reset: resetAccountTypesCollection,
  },
  dispatch,
);

export default connect(
  null,
  mapDispatchToProps,
)(AccountTypesList);
