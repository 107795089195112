import React from 'react';
import { RenderLeafProps } from 'slate-react';
import { Text } from 'slate';

const Leaf = (
  {
    attributes,
    children,
    leaf,
  }: RenderLeafProps,
) => {
  const {
    bold,
    italic,
    underline,
    fontSize,
    color,
    type,
    href,
  } = leaf as Text & {
    bold: boolean, italic: boolean, underline: boolean, fontSize: string, color: string, type: string, href: string,
  };

  if (bold) {
    children = <b>{ children }</b>;
  }
  if (italic) {
    children = <i>{ children }</i>;
  }
  if (underline) {
    children = <u>{ children }</u>;
  }
  if (fontSize) {
    children = <span style={ { fontSize } }>{ children }</span>;
  }
  if (color) {
    children = <span style={ { color } }>{ children }</span>;
  }
  if (type === 'link') {
    children = <a href={ href }>{ children }</a>;
  }
  return <span { ...attributes }>{ children }</span>;
};

export default Leaf;
