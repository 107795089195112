import { useState, useEffect, useCallback } from 'react';
import throttle from 'lodash/throttle';
import { CONTENT_WRP_ID } from '@/ui/shared/components/Layout/ContentWrp/constants';

/**
 * @param {Object} containerRef
 * @param {Number} scrollTopOffset
 */
const useStickyHeader = (containerRef, scrollTopOffset = 250) => {
  const [isStickyHeaderVisible, setIsStickyHeaderVisible] = useState(false);
  const [stickyHeaderWidth, setStickyHeaderWidth] = useState(0);

  const handleScroll = useCallback(
    throttle(() => {
      if (containerRef.current) {
        const contentWrp = document.getElementById(CONTENT_WRP_ID);
        const containerWidth = containerRef.current.getBoundingClientRect().width;
        const containerHeight = containerRef.current.getBoundingClientRect().height;

        if ((contentWrp.offsetHeight + contentWrp.scrollTop) >= containerHeight) {
          setIsStickyHeaderVisible(false);
        } else if (!isStickyHeaderVisible && contentWrp.scrollTop > scrollTopOffset) {
          setIsStickyHeaderVisible(true);
          setStickyHeaderWidth(containerWidth);
        } else if (contentWrp.scrollTop < scrollTopOffset) {
          setIsStickyHeaderVisible(false);
        }
      }
    }, 250),
    [isStickyHeaderVisible],
  );

  useEffect(
    () => {
      const contentWrp = document.getElementById(CONTENT_WRP_ID);
      contentWrp.addEventListener('scroll', handleScroll);
      return () => contentWrp.removeEventListener('scroll', handleScroll);
    },
    [handleScroll],
  );

  return {
    isStickyHeaderVisible,
    stickyHeaderWidth,
  };
};

export default useStickyHeader;
