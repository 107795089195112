import { groupValidators } from '@/ui/shared/validators/helpers';
import { validateFieldsArrayForReduxForm } from '@/ui/shared/validators/validateFieldsArrayForReduxForm';
import { validateNumber } from '@/ui/shared/validators/validateNumber';
import { validateMinLengthWithStrippedTags } from '@/ui/shared/validators/validateMinLengthWithStrippedTags';

const paramsCuePoints = [
  {
    fieldName: 'slideNumber',
    fieldCaption: 'Slide #',
    functionValidation: [
      {
        name: validateNumber,
        params: {},
      },
    ],
  },
  {
    fieldName: 'time',
    fieldCaption: 'Start Time (Seconds)',
    functionValidation: [
      {
        name: validateNumber,
        params: {},
      },
    ],
  },
  {
    fieldName: 'displayName',
    fieldCaption: 'Display Text (Optional)',
    functionValidation: [
      {
        name: validateMinLengthWithStrippedTags,
        params: { length: 3 },
      },
    ],
  },
];

export default (values) => groupValidators(
  validateFieldsArrayForReduxForm('cuePoints', paramsCuePoints, { collection: values.cuePoints }),
);
