import { useState } from 'react';
import getActions from './getActions';
import { ICustomRegistration } from '@/evercall/domain/vo/call/admin/CustomRegistration';

export default () => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [settings, setSettings] = useState<ICustomRegistration>(null);
  const [defaultSettings, setDefaultSettings] = useState<ICustomRegistration>(null);

  return {
    state: {
      isFetching,
      isUpdating,
      settings,
      defaultSettings,
    },
    ...getActions(
      {
        setIsUpdating,
        setIsFetching,
        setSettings,
        setDefaultSettings,
      },
    ),
  };
};
