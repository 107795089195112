import { Dependencies } from 'constitute';
import UploadDispatcher from '@/finsight/application/DI/FileUpload/UploadDispatcher';
import SocketPush from '@/finsight/application/DI/Socket/Client';
import FinsightWebRpcDispatcher from '@/finsight/application/DI/Rpc/finsight-web/HttpDispatcher';
import Request from '@/Framework/api/Rpc/Request';

@Dependencies(UploadDispatcher, FinsightWebRpcDispatcher, SocketPush)
class ImportRepository {
  /**
   * @param {UploadDispatcher} uploadDispatcher
   * @param {FinsightWebRpcDispatcher} finsightWebRpcDispatcher
   * @param {SocketPush} socketPush
   */
  constructor(uploadDispatcher, finsightWebRpcDispatcher, socketPush) {
    this.uploadDispatcher = uploadDispatcher;
    this.finsightWebRpc = finsightWebRpcDispatcher;
    this.socketPush = socketPush;
    this.fileImportingChangeSubscribtion = null;
    this.materializedViewRefreshSubscribtion = null;
  }

  /**
   * @param {File} file
   * @return {Upload}
   */
  bondUpload(file) {
    this.fileImportingChangeSubscribtion = null;
    return this.uploadDispatcher.upload(file, { type: 'FINSIGHT_BOND_IMPORT' });
  }

  /**
   * @param {File} file
   * @return {Upload}
   */
  dealUpload(file) {
    this.fileImportingChangeSubscribtion = null;
    return this.uploadDispatcher.upload(file, { type: 'FINSIGHT_DEAL_IMPORT' });
  }

  /**
   * @param {File} file
   * @return {Upload}
   */
  companyUpload(file) {
    this.fileImportingChangeSubscribtion = null;
    return this.uploadDispatcher.upload(file, { type: 'FINSIGHT_COMPANY_IMPORT' });
  }

  subscribeToFileImportingChange = async (callback = () => {}, fileId) => {
    const socketSubscribeReq = new Request('finsight_web.socket.notifications', {
      entityId: fileId,
    });
    this.fileImportingChangeSubscribtion = await this.socketPush.subscribe(socketSubscribeReq);
    const callbackWrapper = (callback) => ({ params: { payload } }) => callback(payload);
    this.fileImportingChangeSubscribtion.on('admin.file.importing', callbackWrapper(callback));
  };

  unsubscribeToFileImportingChange() {
    this.fileImportingChangeSubscribtion?.cancel();
  }

  bondImport = async (payload = {}) => {
    let req = new Request('finsight_web.import.bond_import', payload);
    let response = await this.finsightWebRpc.call(req);
    return response.getResult().payload;
  };

  dealImport = async (payload = {}) => {
    let req = new Request('finsight_web.import.deal_import', payload);
    let response = await this.finsightWebRpc.call(req);
    return response.getResult().payload;
  };

  dealsImport = async (payload = {}) => {
    let req = new Request('finsight_web.import.get_deal_imports', payload);
    let response = await this.finsightWebRpc.call(req);
    return response.getResult().payload;
  };

  companyImport = async (payload = {}) => {
    let req = new Request('finsight_web.import.company_import', payload);
    let response = await this.finsightWebRpc.call(req);
    return response.getResult().payload;
  };

  companiesImport = async (payload = {}) => {
    let req = new Request('finsight_web.import.get_company_imports', payload);
    let response = await this.finsightWebRpc.call(req);
    return response.getResult().payload;
  };

  deleteDealImport = async ({ importId }) => {
    let req = new Request('finsight_web.import.delete_deals_by_import_id', { importId });
    let response = await this.finsightWebRpc.call(req);
    return response.getResult().payload;
  };

  deleteCompanyImport = async (importId = {}) => {
    let req = new Request('finsight_web.import.delete_companies_by_import_id', { ...importId });
    let response = await this.finsightWebRpc.call(req);
    return response.getResult().payload;
  };
}

export default ImportRepository;
