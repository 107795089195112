import React from 'react';

export const domainCellCallback = (cellProps) => (
  <a
    href={ `http://${ cellProps.cellData }` }
    target="_blank"
  >
    { cellProps.cellData }
  </a>
);
