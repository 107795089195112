import React, { useState } from 'react';
import cn from 'classnames';
import { EditableInput } from 'react-color/lib/components/common';

import ColorType from './ColorType';
import { getColorUpdater } from './helpers';
import Select from '@/ui/shared/components/Form/Select/legacy';
import { COLOR_PICKER_PANEL } from './constants';

import styles from './colorPicker.scss';

interface IProps {
  rgb: any,
  hsl: any,
  hex: string,
  disableAlpha: boolean,
  onChange: (payload: any) => void,
}

/**
 * @deprecated This component has been chosen for migration to UIKit, meaning any further changes or updates
 * must be completed within the migration process.
 * @see https://www.notion.so/finsight-group/Component-Migration-Process-f4475950481d429ba0dc450d0bb0cb8b
 */
const ColorPickerPanel = (
  {
    rgb,
    hsl,
    hex,
    disableAlpha,
    onChange,
  }: IProps,
) => {
  const [view, setView] = useState(ColorType.getHex());
  const changeColorOptions = ({ value }) => setView(value);

  const isColorType = (type) => view && view === type;

  const handleChange = getColorUpdater(onChange, { rgb, hsl });

  return (
    <div className={ cn(styles.fields, 'flexbox-fix') }>
      <Select
        searchable={ false }
        isNarrow
        value={ view }
        className={ styles.select }
        options={ Object.values(ColorType.getColorTypesName()) }
        onChange={ changeColorOptions }
        dataTest="colorPickerPanelSelect"
      />
      { isColorType(ColorType.getHex()) && (
        <div>
          <div className={ styles.editableField }>
            <EditableInput
              style={ COLOR_PICKER_PANEL.INPUT_STYLE }
              label="hex"
              hideLabel
              value={ hex }
              onChange={ handleChange }
            />
          </div>
        </div>
      ) }
      { isColorType(ColorType.getRgb()) && (
        <div className={ styles.rgb }>
          <div className={ styles.editableField }>
            <EditableInput
              style={ COLOR_PICKER_PANEL.INPUT_STYLE }
              value={ rgb.r }
              label="r"
              hideLabel
              onChange={ handleChange.bind(this, rgb, hsl) }
              dragLabel
              dragMax="255"
            />
          </div>
          <div className={ styles.editableField }>
            <EditableInput
              style={ COLOR_PICKER_PANEL.INPUT_STYLE }
              value={ rgb.g }
              label="g"
              hideLabel
              onChange={ handleChange }
              dragLabel
              dragMax="255"
            />
          </div>
          <div className={ styles.editableField }>
            <EditableInput
              style={ COLOR_PICKER_PANEL.INPUT_STYLE }
              value={ rgb.b }
              label="b"
              hideLabel
              onChange={ handleChange }
              dragLabel
              dragMax="255"
            />
          </div>
          <div className={ cn(styles.editableField, { [styles.noAlpha]: disableAlpha }) }>
            <EditableInput
              style={ COLOR_PICKER_PANEL.INPUT_STYLE }
              value={ Math.round(rgb.a * 100) }
              label="a"
              hideLabel
              onChange={ handleChange }
              dragLabel
              dragMax="100"
            />
          </div>
        </div>
      ) }
      { isColorType(ColorType.getHsl()) && (
        <div className={ styles.rgb }>
          <div className={ styles.editableField }>
            <EditableInput
              style={ COLOR_PICKER_PANEL.INPUT_STYLE }
              value={ Math.round(hsl.h) }
              label="h"
              hideLabel
              onChange={ handleChange }
            />
          </div>
          <div className={ styles.editableField }>
            <EditableInput
              style={ COLOR_PICKER_PANEL.INPUT_STYLE }
              value={ `${ Math.round(hsl.s * 100) }%` }
              label="s"
              hideLabel
              onChange={ handleChange }
            />
          </div>
          <div className={ styles.editableField }>
            <EditableInput
              style={ COLOR_PICKER_PANEL.INPUT_STYLE }
              value={ `${ Math.round(hsl.l * 100) }%` }
              label="l"
              hideLabel
              onChange={ handleChange }
            />
          </div>
          <div className={ cn(styles.editableField, { [styles.noAlpha]: disableAlpha }) }>
            <EditableInput
              style={ COLOR_PICKER_PANEL.INPUT_STYLE }
              value={ Math.round(hsl.a * 100) }
              label="a"
              hideLabel
              arrowOffset={ 0.01 }
              onChange={ handleChange }
            />
          </div>
        </div>
      ) }
    </div>
  );
};

export default ColorPickerPanel;
