import NavLink from '@/ui/shared/components/ReactRouter/NavLink';
import { CONDOR_FINSIGHT_OPEN_MARKET_PROFILES } from '@/condor/ui/common/constants';
import styles from './profileFormHeaderButtons.scss';
import { useProfileDataContext } from '@/openMarket/application/Condor/ProfileDataContext';

interface IProps {
  className?: string,
}

const ProfileFormHeaderButtons = ({ className }: IProps) => {
  const { profileId } = useProfileDataContext();

  return (
    <div className={ className }>
      <NavLink
        className={ styles.item }
        activeClassName={ styles.active }
        to={ `${ CONDOR_FINSIGHT_OPEN_MARKET_PROFILES }/${ profileId }/edit` }
        dataTest="editProfileNavLink"
      >
        Profile
      </NavLink>
      <NavLink
        activeClassName={ styles.active }
        to={ `${ CONDOR_FINSIGHT_OPEN_MARKET_PROFILES }/${ profileId }/events` }
        dataTest="eventsListNavLink"
      >
        Events
      </NavLink>
    </div>
  );
};

export default ProfileFormHeaderButtons;
