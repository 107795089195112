import { createContext, useContext } from 'react';
import useOpenMarketList from './useOpenMarketList';

interface IProps {
  children: React.ReactNode,
}

export const OpenMarketListContext = createContext<ReturnType<typeof useOpenMarketList>>(null);

export const useOpenMarketListContext = () => {
  const context = useContext(OpenMarketListContext);
  if (!context) {
    throw new Error('useOpenMarketListContext must be used within a OpenMarketListContextProvider');
  }
  return context;
};

const OpenMarketListContextProvider = ({ children }: IProps) => (
  <OpenMarketListContext.Provider value={ useOpenMarketList() }>
    { children }
  </OpenMarketListContext.Provider>
);

export default OpenMarketListContextProvider;
