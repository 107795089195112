import { Class, Transient, Value, Method } from 'constitute';
import ProcessingAdapter from './SocketProcessingAdapter';
import files from '@/finsight/infrastructure/finsightWebUrl';
import { MultipartUploadStrategy, MultipartUploadAdapter, UploadDispatcher } from '@dealroadshow/file-uploader';

const UploadAdapter = new Class(MultipartUploadAdapter, Transient.with([
  new Value({ url: files.getUploadUrl() }),
]));

export default new Method((processingAdapter, uploadAdapter) => {
  return new UploadDispatcher(new MultipartUploadStrategy(uploadAdapter, processingAdapter));
}, [ProcessingAdapter, UploadAdapter]);
