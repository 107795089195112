import React, { useEffect } from 'react';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';

import { Spinner, DataTable, dataTableStyles } from '@dealroadshow/uikit';
import Paginator from '../PaginatorContainer';

import columns from './columns';
import Filing from '@/finsight/domain/Filing';

import styles from '../tableStyles.scss';

interface IProps {
  init: () => void,
  reset: () => void,
}

const AbsEETable = ({ init, reset, ...otherProps }: IProps) => {
  const location = useLocation().pathname;
  const types = Filing.getFilingTypesArray(location);

  useEffect(() => {
    init();
    return reset;
  }, []);

  return (
    <>
      <DataTable
        loadingComponent={ Spinner }
        className={ cn(dataTableStyles.isHoverable, styles.tableRow) }
        dataTest="absEEDataTable"
        columns={ columns }
        { ...otherProps }
      />
      <Paginator
        types={ types }
        dataTest="absEETable"
      />
    </>
  );
};

export default AbsEETable;
