import { connect, batch } from 'react-redux';
import { bindActionCreators } from 'redux';
import ApplicationBlacklist from './ApplicationBlacklist';
import {
  getApplicationBlacklistByFilter,
  setApplicationBlacklistIsDeleteModalVisible,
  setCurrentApplicationBlacklistItem,
  reset,
} from '@/condor/application/actions/blacklists/application/actions';

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    init: getApplicationBlacklistByFilter,
    reset,
    onDeleteIconClick: (cellProps) => () => batch(() => {
      dispatch(setApplicationBlacklistIsDeleteModalVisible(true));
      dispatch(setCurrentApplicationBlacklistItem(cellProps.row));
    }),
  },
  dispatch,
);

export default connect(
  null,
  mapDispatchToProps,
)(ApplicationBlacklist);
