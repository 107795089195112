import { getErrorMessage, getMessage } from '@/Framework/Message/Mapper/getMessage';
import RoadshowRepository from '@/dealroadshow/infrastructure/repository/RoadshowRepository';
import { messageCodes } from '@/Framework/Message/messages';
import { NotificationManager } from '@/ui/shared/components/Notification';
import * as actionTypes from './actionTypes';

/**
 * ROADSHOWS_TABLE_FILTER
 */

/**
 *
 * @param {{value:string}} payload
 * @return {function(*)}
 */
export function setRoadshowsTableFilterSearch(payload) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ROADSHOWS_TABLE_FILTER_SEARCH,
      payload,
    });
  };
}

/**
 *
 * @param {{value:string}} payload
 * @return {function(*)}
 */
export function setRoadshowsTableFilterPagination(payload) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ROADSHOWS_TABLE_FILTER_PAGINATION,
      payload,
    });
  };
}

/**
 *
 * @param {{value:string}} payload
 * @return {function(*)}
 */
export function setRoadshowsTableFilterPerPage(payload) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ROADSHOWS_TABLE_FILTER_PER_PAGE,
      payload,
    });
  };
}

/**
 *
 * @param {{value:string, group: string}} payload
 * @return {function(*)}
 */
export function setRoadshowsTableFilterRadio(payload) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ROADSHOWS_TABLE_FILTER_RADIO,
      payload,
    });
  };
}

/**
 *
 * @param {{sortBy: string, sortOrder: string}} payload
 * @return {function(*)}
 */
export function setRoadshowsTableSort(payload) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ROADSHOWS_TABLE_SORT,
      payload,
    });
  };
}

/**
 * @param {Array} payload
 * @return {Object}
 */
function requestRoadshowsSuccess(payload) {
  return {
    type: actionTypes.REQUEST_ROADSHOWS_SUCCESS,
    payload,
  };
}

/**
 * @param {Number} payload
 * @return {Object}
 */
function requestRoadshowsTotalCountSuccess(payload) {
  return {
    type: actionTypes.REQUEST_ROADSHOWS_TOTAL_COUNT,
    payload,
  };
}

/**
 * @param {String} payload
 * @return {Object}
 */
function requestRoadshowsError(payload) {
  return {
    type: actionTypes.REQUEST_ROADSHOWS_ERROR,
    payload,
  };
}

export function getRoadshows(paramsToRequest) {
  return async (dispatch, getState) => {
    const roadshowRepository = getState().container.get(RoadshowRepository);
    dispatch({ type: actionTypes.REQUEST_ROADSHOWS });
    try {
      let roadshows = await roadshowRepository.getAdminList(paramsToRequest);
      dispatch(requestRoadshowsSuccess(roadshows.data));
      dispatch(requestRoadshowsTotalCountSuccess(roadshows.totalCount));
    } catch (errorResponse) {
      dispatch(requestRoadshowsError(errorResponse));
      NotificationManager.error(getErrorMessage(errorResponse));
    }
  };
}

/**
 * @return {Object}
 */
function requestToggleRoadshowActionsSuccess() {
  return {
    type: actionTypes.REQUEST_TOGGLE_ROADSHOWS_ACTIONS_SUCCESS,
  };
}

/**
 * @param {String} payload
 * @return {Object}
 */
function requestToggleRoadshowActionsError(payload) {
  return {
    type: actionTypes.REQUEST_TOGGLE_ROADSHOWS_ACTIONS_ERROR,
    payload,
  };
}

/**
 * @param {{
 *  id: String,
 *  type: String,
 *  value: boolean
 * }} toggleParams
 * @param {Object} filterRequest
 * @return {function(*)}
 */
export function toggleRoadshowActions(toggleParams, filterRequest) {
  return async (dispatch, getState) => {
    const roadshowRepository = getState().container.get(RoadshowRepository);
    dispatch({ type: actionTypes.REQUEST_TOGGLE_ROADSHOWS_ACTIONS });
    try {
      await roadshowRepository.toggleAdminListActions(toggleParams);
      dispatch(requestToggleRoadshowActionsSuccess());
      NotificationManager.success(getMessage(messageCodes.CONDOR_SAVED));
      dispatch(getRoadshows(filterRequest));
    } catch (errorResponse) {
      dispatch(requestToggleRoadshowActionsError(errorResponse));
      dispatch(getRoadshows(filterRequest));
      NotificationManager.error(getErrorMessage(errorResponse));
    }
  };
}

export function resetRoadshows() {
  return async (dispatch) => {
    dispatch({ type: actionTypes.ROADSHOWS_RESET });
    dispatch({ type: actionTypes.ROADSHOWS_TABLE_RESET });
  };
}
