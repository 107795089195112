import { combineFieldsWithValues, groupValidators } from '@/ui/shared/validators/helpers';
import { validateRequiredFields } from '@/ui/shared/validators/validateRequiredFields';
import { validateRegExp } from '@/ui/shared/validators/validateRegExp';

const fieldsList = [
  { fieldName: 'subdomain', fieldCaption: 'Subdomain' },
  { fieldName: 'shortname', fieldCaption: 'Short Name' },
  { fieldName: 'loginUrl', fieldCaption: 'Login Url' },
  { fieldName: 'emailJsonPath', fieldCaption: 'Email JSON Path' },
];

const validateJsonPath = (value) => validateRegExp({
  ...value,
  expression: /^(\$?(\.|\[))/,
});

export default (values) => {
  let fields = combineFieldsWithValues(fieldsList, values);
  return groupValidators(
    validateRequiredFields(
      fieldsList,
      values,
    ),
    validateJsonPath(fields.emailJsonPath),
  );
};
