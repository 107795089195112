import React from 'react';
import Link from '@/ui/shared/components/ReactRouter/Link';
import { CONDOR_FINSIGHT_COMPANY_PROFILE_BASE_URL } from '@/condor/ui/common/constants';

import styles from '../traceTable.scss';

export const ExpandedCell = (cellProps) => {
  const issuerId = cellProps.row.mappedData?.issuerId;
  let issuerName = cellProps.cellData;
  if (cellProps.row?.issuerTicker) {
    issuerName = `${ issuerName } (${ cellProps.row.issuerTicker })`;
  }

  return (
    issuerId ? (
      <Link
        to={ `${ CONDOR_FINSIGHT_COMPANY_PROFILE_BASE_URL }/${ issuerId }` }
        className={ styles.issuerName }
      >
        { issuerName }
      </Link>
    ) : <span className={ styles.issuerName }>{ issuerName }</span>
  );
};
