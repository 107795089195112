import isEmpty from 'lodash/isEmpty';
import { getTime } from 'date-fns';
import utcToZonedDate from '@/Framework/DateTime/utcToZonedDate';

/**
 * @param {Object} item
 */
export const getCompanyInlineFormInitialValues = (item) => {
  let industrySubindustry = null;
  const industryId = item?.industry?.id;
  const subindustryId = item?.subindustry?.id;

  if (industryId || subindustryId) {
    industrySubindustry = {
      label: `${ item.industry.name }${ subindustryId ? ` - ${ item.subindustry.name }` : '' }`,
      value: {
        industryId,
        subindustryId,
      },
    };
  }

  return {
    ...item,
    industrySubindustry,
  };
};

/**
 * @param {Boolean} isCreating
 * @param {Boolean} isCreateCompanyModal
 * @param {Object} item
 * @param {Array} industries
 * @param {Object} dealsInformation
 */
export const getCompanyInitialValues = ({
  isCreating,
  isCreateCompanyModal,
  item,
  industries,
  dealsInformation,
}) => {
  if (isCreating && !isCreateCompanyModal) {
    return {};
  }

  let industrySubindustry = null;
  const { industryId, subindustryId } = item;

  if ((industryId || subindustryId) && industries.length) {
    const industry = industries.find((ind) => ind.id === industryId);
    const subindustry = industry?.subindustryList.find((subind) => subind.id === subindustryId);

    industrySubindustry = {
      label: `${ industry.name }${ subindustry ? ` - ${ subindustry.name }` : '' }`,
      value: {
        industryId,
        subindustryId,
      },
    };
  }

  return {
    ...item,
    dealsTotalCount: dealsInformation?.deal?.total || 0,
    industrySubindustry,
  };
};

/**
 * @param {Object} item
 */
export const getDealInitialValues = (item) => {
  if (isEmpty(item)) {
    return {};
  }
  const {
    seriesName,
    parent = {},
    product,
    primaryIssuer,
    country: { region },
    pricingDate,
  } = item;
  let industrySubindustry = '';
  let sectorSubsector = null;

  if (parent.industry.id || parent.subindustry.id) {
    industrySubindustry = `${ parent.industry.name }${ parent.subindustry.id ? ` - ${ parent.subindustry.name }` : '' }`;
  }
  if (item.sector?.id && item.subsector?.id) {
    sectorSubsector = {
      value: item.subsector.id,
      label: `${ item.sector.name } - ${ item.subsector.name }`,
      sectorId: item.sector.id,
    };
  }

  return {
    seriesName,
    product: {
      label: product.abbreviation,
      value: product.id,
    },
    issuerTicker: primaryIssuer.issuerTicker,
    industry: industrySubindustry,
    region: {
      label: region.abbreviation,
      value: region.id,
    },
    pricingDate: pricingDate ? getTime(
      utcToZonedDate(pricingDate.timezone.timeZone,
      new Date(pricingDate.dateTime)),
    ) : null,
    parent: {
      value: parent.id,
      label: parent.name,
    },
    primaryIssuer: {
      value: primaryIssuer.id,
      label: primaryIssuer.name,
    },
    sector: sectorSubsector,
  };
};
