export const FILING_TYPE_ABS_15G = '15g';
export const FILING_TYPES_ABS_15G = ['ABS-15G', 'ABS-15G/A'];
export const FILING_TYPE_ABS_EE = 'ee';
export const FILING_TYPES_ABS_EE = ['ABS-EE', 'ABS-EE/A'];

class Filing {
  /**
   * Check is ABS 15G filing
   * @param {String} value (pathname || filingType)
   * @return {Boolean}
   */
  static isAbs15G(value) {
    return value.toLowerCase().includes(FILING_TYPE_ABS_15G);
  }

  /**
   * Check is ABS EE filing
   * @param {String} value (pathname || filingType)
   * @return {Boolean}
   */
  static isAbsEe(value) {
    return value.toLowerCase().includes(FILING_TYPE_ABS_EE);
  }

  /**
   * get filing type
   * @param {String} value (pathname || filingType)
   * @return {String}
   */
  static getFilingType(value) {
    return this.isAbs15G(value) ? FILING_TYPE_ABS_15G : FILING_TYPE_ABS_EE;
  }

  /**
   * get filing types for end-point payload
   * @param {String} value (pathname || filingType)
   * @return Array<String, String>
   */
  static getFilingTypesArray(value) {
    return this.isAbs15G(value) ? FILING_TYPES_ABS_15G : FILING_TYPES_ABS_EE;
  }
}

export default Filing;
