import React from 'react';
import Input from '@/ui/shared/components/Form/Input';
import { IconType } from '@dealroadshow/uikit';

function SearchInput(props) {
  return (
    <Input
      iconType={ IconType.filtering }
      { ...props }
    />
  );
}

export default SearchInput;
