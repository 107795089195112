import { groupValidators } from '@/ui/shared/validators/helpers';
import { validateCallName } from '@/evercall/ui/common/CallForm/helpers/validateCallDetailsFields';
import validateConferenceId from '@/evercall/ui/common/Oacc/validateConferenceId';
import { validateRegExp } from '@/ui/shared/validators/validateRegExp';
import { validateMinLength } from '@/ui/shared/validators/validateMinLength';
import { validateMaxLength } from '@/ui/shared/validators/validateMaxLength';
import { validateAlphanumeric } from '@/ui/shared/validators/validateAlphanumeric';
import { IAdminCall } from '@/evercall/domain/vo/call/admin/AdminCall';

const groupNameField = { fieldName: 'groupName', fieldCaption: 'Group name' };

const validateNameToSlug = (values: IAdminCall) => {
  if (values.groupName?.length >= 3 && values.groupPath?.length === 0) {
    return { groupName: 'Group name must form a valid URL path' };
  }
  return null;
};

const validateGroupName = (values: IAdminCall) => (
  groupValidators(
    validateMinLength({ ...groupNameField, length: 3, value: values.groupName }),
    validateMaxLength({ ...groupNameField, length: 128, value: values.groupName }),
    validateNameToSlug(values),
  )
);

export default (values: IAdminCall) => groupValidators(
  validateCallName(values),
  validateConferenceId(
    {
      fieldName: 'participantConferenceId',
      value: values.participantConferenceId,
      fieldCaption: 'Conference ID',
    },
  ),
  values.participantConferenceId && validateGroupName(values),
  values.roadshowId && validateRegExp(
    {
      fieldName: 'roadshowId',
      fieldCaption: 'Roadshow ID',
      value: values.roadshowId,
      expression: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
    },
  ),
  validateMinLength({
    fieldName: 'roadshowEntryCode',
    fieldCaption: 'Entry code',
    value: values.roadshowEntryCode,
    length: 3,
  }),
  validateMaxLength({
    fieldName: 'roadshowEntryCode',
    fieldCaption: 'Entry code',
    value: values.roadshowEntryCode,
    length: 32,
  }),
  validateAlphanumeric({
    fieldName: 'roadshowEntryCode',
    fieldCaption: 'Entry code',
    value: values.roadshowEntryCode,
  }),
);
