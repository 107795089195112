import React, { memo } from 'react';
import cn from 'classnames';
import { Modal } from '@dealroadshow/uikit';
import Button, { variantTypes } from '@/ui/shared/components/Button';
import modalStyles from '@/condor/ui/common/Layout/modal.scss';
import spacesStyles from '@/ui/shared/styles/helpers/spaces.scss';

interface IProps {
  isVisible: boolean,
  item: any,
  warningText: string,
  onDelete: (item: any) => void,
  onCancel: () => void,
}

const DeleteModal = (props: IProps) => (
  <Modal
    title="Delete Domain "
    isVisible={ props.isVisible }
    onCloseClicked={ props.onCancel }
    dataTest="deleteDomainModal"
    footer={ (
      <>
        <Button
          variant={ variantTypes.action }
          title="Delete Domain"
          onClick={ () => props.onDelete(props.item) }
          dataTest="condorBlacklistDomainDeleteButton"
        />
        <Button
          variant={ variantTypes.text }
          title="Cancel"
          onClick={ props.onCancel }
          dataTest="condorBlacklistDomainCancelButton"
        />
      </>
    ) }
  >
    <div className={ cn(modalStyles.warningSection, spacesStyles.mbl) }>
      { props.warningText }
    </div>
    <span className={ modalStyles.areYouSureLabel }>
      Are you sure you want to delete <b>{ props.item ? props.item.domain : '' }</b>?
    </span>
  </Modal>
);

export default memo(DeleteModal);
