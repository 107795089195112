import React, { useEffect } from 'react';
import { Paginator, Spinner, DataTable } from '@dealroadshow/uikit';

import PageWrapper from '@/evercall/ui/common/PageWrapper';
import TableWrapper from '@/ui/shared/components/Tables/TableWrapper';
import CallsListFilters from './CallsListFilters';

import { CONDOR_EVERCALL_LIST_COLUMNS } from './constants';
import CallsCollectionContext, {
  useCallsCollectionContext,
} from '@/evercall/application/condor/call/collection/CallsCollectionContext';

import cardStyles from '@/ui/shared/styles/card.scss';

const CallsList = () => {
  const {
    state: {
      isInitialized,
      isFetching,
      collection,
      totalCount,
      page,
      perPage,
      sortBy,
      sortOrder,
    },
    setIsInitialized,
    paginate,
    setItemsPerPage,
    sort,
  } = useCallsCollectionContext();

  useEffect(
    () => {
      if (!isFetching && !isInitialized) {
        setIsInitialized(true);
      }
    },
    [isInitialized, isFetching],
  );

  return (
    <PageWrapper>
      <TableWrapper
        label="Evercall"
        filterComponent={ <CallsListFilters /> }
      >
        <DataTable
          data={ collection }
          dataTest="evercallTable"
          columns={ CONDOR_EVERCALL_LIST_COLUMNS }
          loadingComponent={ Spinner }
          isFetching={ isFetching || !isInitialized }
          sortBy={ sortBy }
          sortOrder={ sortOrder }
          onSortChange={ sort }
        />
        <Paginator
          dataTest="evercallTablePaginator"
          className={ cardStyles.cardInner }
          totalCount={ totalCount }
          page={ page }
          perPage={ perPage }
          onPageChange={ (page) => paginate(page.selected) }
          onItemsPerPageChange={ (perPage) => setItemsPerPage(perPage.value) }
        />
      </TableWrapper>
    </PageWrapper>
  );
};

export default () => (
  <CallsCollectionContext>
    <CallsList />
  </CallsCollectionContext>
);
