import { useEffect, useRef } from 'react';
import Highlighter from 'react-highlight-words/dist/main';

import { useEventFormContext } from '@/openMarket/ui/components/Condor/Events/EventForm/EventFormContext';
import { Field } from '@/ui/shared/components/Form/FinalForm';
import AutocompleteInput from '@/ui/shared/components/Form/AutocompleteInput';
import { FieldsLabel, FieldsNames } from '@/openMarket/ui/components/Condor/Events/EventForm/constants';
import { ILinkToRoadshow } from '@/openMarket/domain/Condor/vo/IEvent';
import decodeSymbols from '@/Framework/dataHelpers/string/decodeSymbols';
import { IFormValues } from '@/openMarket/ui/components/Condor/Events/EventForm/interfaces';

import inputStyles from '@/ui/shared/components/Form/Input/input.scss';
import styles from './eventForm.scss';

const renderSuggestion = (suggestion: ILinkToRoadshow, value: string, initRoadshow: string) => {
  if (suggestion.id === initRoadshow || !suggestion.disabled) {
    return (
      <Highlighter
        autoEscape
        searchWords={ [value] }
        textToHighlight={ decodeSymbols(suggestion.name) }
        highlightClassName={ inputStyles.matchingText }
      />
    );
  }

  return (
    <div className={ styles.linkToRoadshowDisabled }>
      { decodeSymbols(suggestion.name) }
    </div>
  );
};

const LinkToRoadshowField = () => {
  const {
    roadshowData,
    getRoadshowsList,
    getRoadshowData,
    setRoadshowData,
    initialValues,
  } = useEventFormContext();

  const initialValuesRef = useRef<IFormValues>(null);
  useEffect(() => {
    initialValuesRef.current = initialValues;
  }, [initialValues]);

  const handleBlur = (input, value: string, suggestion: ILinkToRoadshow) => {
    if (suggestion?.disabled && initialValues.roadshowId !== suggestion.id) {
      return input.onChange('');
    }
    if (roadshowData?.name === value) {
      return '';
    }
    if (suggestion?.name) {
      return value;
    }
    if (roadshowData && roadshowData.name !== value) {
      setRoadshowData(null);
      input.onChange('');
    }
    return value;
  };

  const handleSelect = (input, suggestion: ILinkToRoadshow) => {
    if (suggestion?.disabled && initialValuesRef.current.roadshowId !== suggestion.id) {
      return input.onChange('');
    }
    if (suggestion) {
      getRoadshowData(suggestion.id);
    }
    return null;
  };

  return (
    <Field
      name={ FieldsNames.ROADSHOW_LINK }
      label={ FieldsLabel.ROADSHOW_LINK }
      dataTest={ FieldsNames.ROADSHOW_LINK }
      placeholder="Select Roadshow"
      isNarrow
      render={
        ({ input, ...otherProps }) => (
          <AutocompleteInput
            { ...otherProps }
            input={ {
              ...input,
              value: decodeSymbols(input.value),
              onBlur: (value: string, suggestion: ILinkToRoadshow) => handleBlur(input, value, suggestion),
            } }
            useAsyncSuggestions
            onSuggestionSelected={ (_, suggestion) => handleSelect(input, suggestion) }
            fetchAsyncSuggestions={ getRoadshowsList }
            renderSuggestion={ (suggestion) => renderSuggestion(suggestion, input.value, initialValues.roadshowId) }
            dataTest={ `${ FieldsNames.ROADSHOW_LINK }Input` }
          />
        )
      }
    />
  );
};

export default LinkToRoadshowField;
