import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector } from 'redux-form';
import RoadshowType from '@/dealroadshow/domain/RoadshowType';
import Upload from '@/ui/shared/modules/upload';

import { roadshowTabEnabled } from '../../../actions';
import {
  getAudioVideo,
  resetAudioVideo,
  shiftCuePoints,
  shiftVideoOnlyCuePoints,
  updateAudioVideo,
  uploadClearForForm,
} from '../actions';
import AudioVideoForm from './AudioVideoForm';

const {
  initUploadPresentationMediaAudio,
  initUploadPresentationMediaVideo,
  initUploadPresentationMediaThumbnail,
  initUploadPresentationCuePointsList,
} = Upload.actions;
const selector = formValueSelector('audioVideoForm');

function canSaveAudioVideo(state, type) {
  if (RoadshowType.isSlidesAndAudio(type) &&
      !state.upload.presentationMediaAudio.canSave
  ) {
    return false;
  }
  if ((RoadshowType.isSlidesAndVideo(type) || RoadshowType.isVideoOnly(type)) &&
      !state.upload.presentationMediaVideo.canSave) {
    return false;
  }
  return true;
}

const getUploadedMedia = ({
  presentationMediaAudio,
  presentationMediaVideo,
}) => ({ presentationMediaAudio, presentationMediaVideo });

function getCuePointsList({ presentationCuePoints }) {
  return presentationCuePoints.cuePointsList;
}

function getUploadedCuePointsFileName({ presentationCuePoints }) {
  return presentationCuePoints.name;
}

function mapStateToProps(state) {
  return {
    cuePoints: selector(state, 'cuePoints') || [],
    videoOnlyCuePoints: selector(state, 'videoOnlyCuePoints') || [],
    type: selector(state, 'type'),
    disabled: !canSaveAudioVideo(state, selector(state, 'type')),
    initialValues: state.dealRoadshows.roadshow.audioVideo.initialValues,
    isFetching: state.dealRoadshows.roadshow.audioVideo.isFetching,
    uploadedMedia: getUploadedMedia(state.upload),
    uploadedCuePoints: getCuePointsList(state.upload),
    canBeChangedVideoType: selector(state, 'canBeChangedVideoType'),
    isModerated: selector(state, 'moderated'),
    uploadedCuePointsFileName: getUploadedCuePointsFileName(state.upload),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    roadshowTabEnabled,
    getAudioVideo,
    initUploadPresentationMediaAudio,
    initUploadPresentationMediaVideo,
    initUploadPresentationMediaThumbnail,
    initUploadPresentationCuePointsList,
    uploadClearForForm,
    resetAudioVideo,
    onSubmit: updateAudioVideo,
    shiftCuePoints,
    shiftVideoOnlyCuePoints,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AudioVideoForm);
