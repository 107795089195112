import { connect } from 'react-redux';
import { getTransactionTypes } from '@/dealroadshow/application/actions/transactionType/data/actions';

import config from '@/Framework/config';
import ReduxFormSelect from '@/ui/shared/components/Form/Select/legacy/ReduxFormSelect';

function mapStateToProps(state) {
  return {
    dataTest: 'transactionTypes',
    options: state.transactionTypes.data[config.tenant.dealroadshow.code].collection.map((option) => ({
      value: option.id,
      label: option.name,
    })),
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const { dispatch } = dispatchProps;
  return {
    ...ownProps,
    ...stateProps,
    onDidMount: () => dispatch(getTransactionTypes(config.tenant.dealroadshow.code)),
  };
}

export default connect(mapStateToProps, null, mergeProps)(ReduxFormSelect);
