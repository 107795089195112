import find from 'lodash/find';
import { IAssetClass } from '@/finsight/domain/vo/IAssetClass';

export const ABS = 'abs';

class AssetClass {
  static isValid(collection: IAssetClass[], assetClass: string): boolean {
    return !!assetClass && collection.some((item) => item.abbreviation.toLowerCase() === assetClass.toLowerCase());
  }

  static getDefaultFromCollection(collection: IAssetClass[]): IAssetClass | undefined {
    // eslint-disable-next-line no-restricted-syntax
    for (let i in collection) {
      if (collection[i].active) {
        return collection[i];
      }
    }

    return undefined;
  }

  static getByAbbreviation(collection: IAssetClass[], abbreviation: string) {
    return find(collection, (item) => item.abbreviation.toLowerCase() === abbreviation.toLowerCase());
  }
}

export default AssetClass;
