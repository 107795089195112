import { connect } from 'react-redux';
import Button from './ModalButton';
import { updateStatus } from '@/condor/application/actions/finsight/filings/collection/actions';
import selectors from '@/condor/application/actions/finsight/filings/collection/selectors';
import { FILINGS_STATUS } from '../../../constants';

const mapStateToProps = (state) => ({
  variant: 'action',
  title: 'Resend Email',
  isFetching: selectors.getIsModalFetching(state),
  isAlreadyUpdated: selectors.getIsStatusUpdated(state),
});

const mapDispatchToProps = {
  onClick: () => updateStatus(FILINGS_STATUS.PENDING),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Button);
