import React, { memo } from 'react';
import CheckboxFiltersBlock from '@/condor/ui/common/Layout/CheckboxFiltersBlock';

interface IProps {
  filters: any,
  setIsVerified: () => void,
  setIsUnverified: () => void,
  setIsDeleted: () => void,
}

const CheckboxFilters = (props: IProps) => {
  const verified = [
    {
      label: 'Verified',
      dataTest: 'verifiedAccounts',
      checked: props.filters.isVerified,
      onClick: props.setIsVerified,
    },
    {
      label: 'Unverified',
      dataTest: 'unverifiedAccounts',
      checked: props.filters.isUnverified,
      onClick: props.setIsUnverified,
    },
  ];
  const deleted = [
    {
      label: 'Deleted',
      dataTest: 'deletedAccounts',
      checked: props.filters.isDeleted,
      onClick: props.setIsDeleted,
    },
  ];
  return (
    <>
      <CheckboxFiltersBlock items={ verified } />
      <CheckboxFiltersBlock items={ deleted } />
    </>
  );
};

export default memo(CheckboxFilters);
