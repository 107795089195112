import { cellActionCallback, cellFileNameCallback, cellUploadDateCallback } from './cellCallbacks';

export default [
  {
    name: 'name',
    title: 'File Name',
    width: 300,
    sortable: false,
    cellCallback: cellFileNameCallback,
  },
  {
    name: 'createdAt',
    title: 'Upload Date',
    width: 275,
    sortable: false,
    cellCallback: cellUploadDateCallback,
  },
  {
    name: 'actions',
    sortable: false,
    cellCallback: cellActionCallback,
  },
];
