import { CheckboxInput } from '@dealroadshow/uikit';
import { Field, useField } from '@/ui/shared/components/Form/FinalForm';
import { Textarea } from '@/ui/shared/components/Form/Input';
import UploadImageInput from '@/openMarket/ui/common/Condor/UploadImageInput';
import ColorPicker from '@/ui/shared/components/Form/ColorPicker';
import { FormFieldName } from '../../constants';
import { DISCLAIMER_LENGTH } from './constants';
import * as UPLOAD_CONSTANTS from '@/ui/shared/modules/upload/constants';

import cardStyles from '@/ui/shared/styles/card.scss';
import styles from '../../profileForm.scss';
import detailsStyle from './details.scss';

const customColorFields = [
  { label: 'Background color', name: FormFieldName.BACKGROUND_COLOR },
  { label: 'Tile color', name: FormFieldName.TILE_COLOR },
  { label: 'Primary text color', name: FormFieldName.PRIMARY_TEXT_COLOR },
  { label: 'Secondary text color', name: FormFieldName.SECONDARY_TEXT_COLOR },
  { label: 'Link color', name: FormFieldName.LINK_COLOR },
  { label: 'Input label text color', name: FormFieldName.INPUT_LABEL_COLOR },
  { label: 'Button text color', name: FormFieldName.BUTTON_TEXT_COLOR },
  { label: 'Button color', name: FormFieldName.BUTTON_COLOR, isNarrow: true },
];

const LoginAndDisclaimer = () => {
  const { input: { value: isCustomized } } = useField(FormFieldName.IS_CUSTOMIZED);

  return (
    <div className={ cardStyles.cardInnerHorizontal } data-test="loginAndDisclaimerSection">
      <div data-test="disclaimerLogo">
        <Field label="Logo" name={ FormFieldName.DISCLAIMER_LOGO } uploadName="logo" component={ UploadImageInput } />
      </div>
      <Field
        label="Profile disclaimer"
        name={ FormFieldName.DISCLAIMER }
        dataTest={ FormFieldName.DISCLAIMER }
        component={ Textarea }
        textareaClassName={ styles.disclaimerTextarea }
        isEnabledCountdownMessage
        maxLength={ DISCLAIMER_LENGTH }
      />
      <Field
        label="Customize Login & Disclaimer"
        name={ FormFieldName.IS_CUSTOMIZED }
        dataTest={ FormFieldName.IS_CUSTOMIZED }
        component={ CheckboxInput }
        className={ isCustomized ? detailsStyle.isCustomizedTrue : detailsStyle.isCustomizedFalse }
        type="checkbox"
      />
      { isCustomized && (
        <>
          <div data-test="backgroundImage">
            <Field
              label="Background image"
              name={ FormFieldName.BACKGROUND_IMAGE }
              uploadName="logo"
              component={ UploadImageInput }
              allowedFileText={ UPLOAD_CONSTANTS.UPLOAD_LOGO_ALLOWED_FILES_TEXT }
            />
          </div>
          { customColorFields.map((field) => (
            <Field
              key={ field.name }
              dataTest={ field.name }
              component={ ColorPicker }
              containerClassName={ detailsStyle.colorPicker }
              { ...field }
            />
          )) }
        </>
      ) }
    </div>
  );
};

export default LoginAndDisclaimer;
