import { Dependencies } from 'constitute';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import RpcDispatcher from '@/finsight/application/DI/Rpc/finsight-web/HttpDispatcher';
import Request from '@/Framework/api/Rpc/Request';
import BaseFinsightRepository from '@/finsight/infrastructure/repository/BaseFinsightRepository';

@Dependencies(RpcDispatcher)
class FilingRepository extends BaseFinsightRepository {
  constructor(protected rpc: typeof RpcDispatcher) {
    super(rpc);
  }

  /**
   * Gets the active ABS 15G filings with subscription status.
   * @param {Number} page
   * @param {Number} perPage
   * @param {String} searchString
   * @param {String} type
   * @return {object}
   */
  getFilingsIssuePipeline = async ({ page = 1, perPage = 25, searchString = null }) => {
    const payload = {
      filters: {
        search: searchString,
        types: [
          'ABS-15G',
          'ABS-15G/A',
        ],
      },
      page,
      perPage,
    };
    return this.abortableCall(new Request('finsight_web.market.filing.get_filings_with_subscription', payload));
  };

  /**
   * Gets the active ABS 15G filings.
   * @param {Number} page
   * @param {Number} perPage
   * @param {String} search
   * @param {boolean} newFilings
   * @return {object}
   */
  getFilingsLoanLevel = async ({ page = 1, perPage = 25, search = null, isNewFilings = false }) => {
    const payload = {
      filters: {
        search,
        new: isNewFilings,
        types: [
          'ABS-EE',
          'ABS-EE/A',
        ],
      },
      page,
      perPage,
    };
    return this.abortableCall(new Request('finsight_web.market.filing.get_filings', payload));
  };

  /**
   * @return {object}
   */
  getXml2csvTplUrl = async (payload = {}) => {
    const response = await this.rpc.call<RpcSuccess>(new Request('finsight_web.filing.file.get_url_convert_xml2csv', payload));
    return response.getResult().payload;
  };

  /**
   * @param {string} email
   * @returns {object}
   */
  async subscribeToNewIssuePipelineFilings(email) {
    const payload = {
      email,
      type: 'NEW_15G_FILING',
    };
    const response = await this.rpc.call<RpcSuccess>(new Request('finsight_web.user_subscriptions.subscribe', payload));
    return response.getResult().payload;
  }

  /**
   * @param {string} hash
   * @param {string} type
   * @param {string} email
   * @returns {object}
   */

  /* eslint-disable camelcase */
  async unsubscribe(hash, type, email) {
    const payload = { unsubscribe_hash: hash, type, email };
    const response = await this.rpc.call<RpcSuccess>(new Request('finsight_web.user_subscriptions.unsubscribe', payload));
    return response.getResult().payload;
  }

  /**
   * @param {Object} payload
   * @return {Array}
   */
  getAllFilings = async (payload) => {
    const req = new Request('finsight_web.filing.get_all_filings', payload);
    const response = await this.rpc.call<RpcSuccess>(req);

    return response.getResult().payload;
  };

  /**
   * @param {Object} payload
   * @return {object}
   */
  updateStatus = async (payload) => {
    const req = new Request('finsight_web.filing.update_status', payload);
    const response = await this.rpc.call<RpcSuccess>(req);

    return response.getResult().payload;
  };

  /**
   * @param {Object} payload
   * @returns {Promise<object>}
   */
  getFilingById = async (payload = {}) => {
    const response = await this.rpc.call<RpcSuccess>(new Request('finsight_web.filing.get_filing_by_id', payload));

    return response.getResult().payload;
  };

  /**
   *
   * @param {Object} payload
   * @returns {Promise<object>}
   */
  mapFilingToDeal = async (payload = {}) => {
    const response = await this.rpc.call<RpcSuccess>(new Request('finsight_web.filing.update_deal_id', payload));

    return response.getResult().payload;
  };
}

export default FilingRepository;
