import get from 'lodash/get';

/**
 * @param {String} key
 * @returns {*}
 */
const createSelector = (key) => (state) => get(state, `condor.finsightManager.analytics.contacts.${ key }`);

export const getCollectionData = createSelector('data');

export const isFetching = createSelector('isFetching');

export const isExporting = createSelector('isExporting');

export const getTotalCount = createSelector('totalCount');

export const getPage = createSelector('page');

export const getPerPage = createSelector('perPage');

export const getSortBy = createSelector('sortBy');

export const getSortOrder = createSelector('sortOrder');
