import React from 'react';
import Banner from '@/condor/domain/banner/Banner';
import Status from '@/condor/ui/common/Layout/Status';

import styles from './bannerHeader.scss';

interface IProps {
  status: string,
  name: string,
}

const BannerHeader = ({ status = Banner.getNotLive(), name = '' }: IProps) => {
  const getStatus = (status) => ({
    className: (Banner.isLive(status)
      ? styles.liveBanner
      : styles.notLiveBanner),
    tooltipContent: Banner.statusTooltip(status),
  });

  const getStatusIndicator = (status) => (
    <span className={ styles.statusLabel }>
      <Status
        status={ getStatus(status) }
        className={ styles.statusIndicator }
        circleClassName={ styles.statusCircleBig }
      />
    </span>
  );

  return (
    <>
      { getStatusIndicator(status) }
      <span>{ name }</span>
    </>
  );
};

export default BannerHeader;
