import React, { useState } from 'react';
import CheckboxFilters from './CheckboxFilters';
import SearchInput from '@/ui/shared/components/Form/Input/SearchInput/SearchInput';
import { useRequestsContext } from '@/condor/application/FinsightManager/Requests';

import styles from './requests.scss';
import commonStyles from '../common/common.scss';

const Filters = () => {
  const [search, setSearch] = useState<string>('');
  const {
    debouncedSearch,
  } = useRequestsContext();
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    debouncedSearch(e.target.value);
  };
  return (
    <div className={ styles.filtersWrapper }>
      <CheckboxFilters />
      <SearchInput
        formFieldClassName={ commonStyles.searchInputField }
        placeholder="Filter by Account, Contact, Email or Plan"
        dataTest="finsightManagePlansRequestsSearch"
        value={ search }
        onChange={ onChange }
        isNarrow
        isClearable
      />
    </div>
  );
};

export default Filters;
