import React from 'react';
import { Toggle } from '@dealroadshow/uikit';

import { useEnableSSOContext } from '@/condor/application/Accounts/AccountProfile/AccountSSO/EnableSSO';

import styles from './styles.scss';

const EnableSSO = () => {
  const { isEnabled, isCertificatesExist, onEnableClick } = useEnableSSOContext();
  return (
    <div
      className={ styles.enableSSo }
    >
      { /* @ts-ignore */ }
      <Toggle
        dataTest="enableSSO"
        checked={ isCertificatesExist ? isEnabled : false }
        onChange={ onEnableClick }
        disabled={ !isCertificatesExist }
      />
      <p className={ styles.label }>Enable SSO</p>
    </div>
  );
};

export default EnableSSO;
