import React, { useEffect, useCallback } from 'react';
import cn from 'classnames';
import { Field, FieldArray } from 'redux-form';
import ColorPicker from '@/ui/shared/components/Form/ColorPicker';
import * as dealSettingsFormConstants
  from '@/condor/ui/components/DealRoadshows/Roadshow/modules/DealSettings/constants';
import { CheckboxInput, FormField } from '@dealroadshow/uikit';
import formStyles from '@/ui/shared/components/Form/form.scss';
import Input from '@/ui/shared/components/Form/Input';
import RichTextInput from '@/ui/shared/components/Form/RichTextInput';
import Textarea from '@/ui/shared/components/Form/Input/Textarea';
import Upload from '@/ui/shared/modules/upload';
import files from '@/Framework/url/filesUrl';
import CustomButtonTypeSwitch
  from '@/condor/ui/components/DealRoadshows/Roadshow/modules/DealSettings/components/CustomButtonTypeSwitch/CustomButtonTypeSwitch';
import ExternalLinksContainer
  from '@/condor/ui/components/DealRoadshows/Roadshow/modules/DealSettings/components/ExternalLinks/ExternalLinksContainer';
import styles from '@/condor/ui/components/DealRoadshows/Roadshow/modules/DealSettings/DealSettingsForm/dealSettingsForm.scss';

const { UploadViewerCustomFilesContainer, UploadLogoDisclaimer, UploadDisclaimerBackground } = Upload.containers;

interface IProps {
  isViewerDisclaimerCustomizationEnabled: boolean,
  createTopButton: boolean,
  topButtonType: string,
  preSetColors: any,
  isEvercallTemplateEnabled: boolean,
  viewerCustomizationEnabled: boolean,
  viewerCustomSettings: any,
  viewerDisclaimerSettings: any,
  change: (name: string, value: any) => void,
  disabledEvercallTemplate: () => void,
}

const ViewerSettings = ({
  isViewerDisclaimerCustomizationEnabled,
  createTopButton,
  topButtonType,
  preSetColors,
  viewerCustomizationEnabled,
  isEvercallTemplateEnabled,
  viewerCustomSettings,
  viewerDisclaimerSettings,
  change,
  disabledEvercallTemplate,
}: IProps) => {
  const hasSettings = !!viewerDisclaimerSettings?.disclaimerLogo?.length;
  const getUrl = (url) => {
    return url?.length ? files.getFileUrl(url) : '';
  };

  const getData = useCallback((settings) => {
    if (settings) {
     return Object.entries(settings).reduce((acc, [key, value]) => {
       if (value === null) {
         value = '';
       }
       acc.push([key, value]);
       return acc;
      }, []);
    }
    return null;
  }, [viewerCustomSettings, viewerDisclaimerSettings]);

  const setFormValue = (values) => {
    const arr1 = getData(values);
    arr1.forEach(([name, value]) => {
      change(`${ name }`, value);
    });
  };

  useEffect(() => {
    if (isEvercallTemplateEnabled) {
      setFormValue({ ...viewerCustomSettings, ...viewerDisclaimerSettings });
      disabledEvercallTemplate();
      change('topButtonType', dealSettingsFormConstants.DEAL_FILES);
    }
  });

  return (
    <div className={ formStyles.formInnerSection }>
      <Field
        name="viewerCustomizationEnabled"
        dataTest="viewerCustomizationEnabled"
        label="Customize Viewer"
        component={ CheckboxInput }
        type="checkbox"
        className={ cn(formStyles.formCheckbox, styles.roadshowViewerCustomizeCheckbox) }
        isNarrow
      />
      { viewerCustomizationEnabled && (
        <>
          <Field
            name="customSectionHeaderText"
            label="Custom section header text"
            dataTest="customSectionHeaderText"
            placeholder="Description"
            component={ Input }
            maxLength={ 100 }
            className={ styles.customSectionHeaderText }
          />
          <Field
            name="customSectionText"
            label="custom section text"
            placeholder=""
            component={ RichTextInput }
            maxLength={ 2000 }
            isEnabledCountdownMessage
            dataTest="customSectionText"
            editableWrpCls={ formStyles.textArea }
          />
          <Field
            name="presentationBackgroundColor"
            label="Background Color"
            component={ ColorPicker }
            containerClassName={ formStyles.formInput }
          />
          <Field
            name="toolbarColor"
            label="Toolbar color"
            component={ ColorPicker }
            containerClassName={ formStyles.formInput }
          />
          <Field
            name="toolbarAccentColor"
            label="Toolbar accent color"
            preSetColor={ preSetColors.toolbarColor }
            component={ ColorPicker }
            containerClassName={ formStyles.formInput }
            enableAutofill
          />
          <Field
            name="toolbarButtonsColor"
            label="Toolbar buttons color"
            component={ ColorPicker }
            containerClassName={ formStyles.formInput }
          />
          <Field
            name="buttonTextColor"
            label="Button text color"
            component={ ColorPicker }
            containerClassName={ formStyles.formInput }
          />
          <Field
            name="dealNameColor"
            label="Deal name color"
            component={ ColorPicker }
            containerClassName={ formStyles.formInput }
          />
          <Field
            name="rightPanelBackgroundColor"
            label="Right panel background color"
            component={ ColorPicker }
            containerClassName={ formStyles.formInput }
          />
          <Field
            name="rightPanelAccentColor"
            label="Right panel accent color"
            preSetColor={ preSetColors.rightPanelBackgroundColor }
            component={ ColorPicker }
            containerClassName={ formStyles.formInput }
            enableAutofill
          />
          <Field
            name="rightPanelIconsColor"
            label="Right panel icons color"
            component={ ColorPicker }
            containerClassName={ formStyles.formInput }
          />
          <Field
            name="sectionHeaderColor"
            label="Section Header Text Color"
            component={ ColorPicker }
            containerClassName={ formStyles.formInput }
          />
          <Field
            name="customSectionHeaderBackgroundColor"
            label="Section Header Background Color"
            component={ ColorPicker }
            containerClassName={ formStyles.formInput }
          />
          <Field
            name="rightPanelTextAndDealRoadshowLogoColor"
            label="Right Panel Text & Logo Color"
            component={ ColorPicker }
            containerClassName={ formStyles.formInput }
          />
        </>
      ) }
      <Field
        name="createTopButton"
        dataTest="createTopButton"
        label="Create Top Button"
        component={ CheckboxInput }
        type="checkbox"
        className={ formStyles.formCheckbox }
      />
      { createTopButton && (
        <div
          className={ formStyles.formInnerSection }
          data-test="condorTopButtonSection"
        >
          <CustomButtonTypeSwitch />
          { topButtonType === dealSettingsFormConstants.DEAL_FILES && (
            <FieldArray
              name="viewerCustomFiles"
              component={ UploadViewerCustomFilesContainer }
            />
          ) }
          { topButtonType === dealSettingsFormConstants.EXTERNAL_LINKS && (
            <ExternalLinksContainer />
          ) }
          <Field
            name="topButtonColor"
            label="Button Color"
            component={ ColorPicker }
            containerClassName={ formStyles.formInput }
          />
          <Field
            name="topButtonTextColor"
            label="Button Text Color"
            component={ ColorPicker }
            containerClassName={ formStyles.formInput }
          />
          <Field
            label="Button Text"
            name="topButtonText"
            component={ Input }
            containerClassName={ formStyles.formInput }
            maxLength={ 25 }
            dataTest="viewerSettingTopButtonTextInput"
          />
        </div>
      ) }
      <Field
        name="viewerDisclaimerCustomizationEnabled"
        dataTest="viewerDisclaimerCustomizationEnabled"
        label="Customize Investor Login, First Time Form, Disclaimer & Custom Modal"
        component={ CheckboxInput }
        type="checkbox"
        className={ formStyles.formCheckbox }
      />
      { isViewerDisclaimerCustomizationEnabled && (
        <div className={ cn(formStyles.formInnerSection, formStyles.borderedSection) }>
          <FormField>
            <div className={ formStyles.formLabel }>
              DISCLAIMER LOGO
            </div>
            <UploadLogoDisclaimer />
            { hasSettings && (
              <div className={ formStyles.textLink }>
                For Evercall branding, please download and upload
                <a
                  href={ getUrl(viewerDisclaimerSettings?.disclaimerLogo[0]) }
                  className={ formStyles.link }
                  data-test="showMoreButton"
                >
                  this PNG image.
                </a>
              </div>
            ) }
          </FormField>
          <FormField>
            <div className={ formStyles.formLabel }>
              BACKGROUND IMAGE
            </div>
            <UploadDisclaimerBackground />
            { hasSettings && (
              <div className={ formStyles.textLink }>
                For Evercall branding, please download and upload
                <a
                  className={ formStyles.link }
                  data-test="showMoreButton"
                  href={ getUrl(viewerDisclaimerSettings?.disclaimerBackgroundImage[0]) }
                >
                  this PNG image.
                </a>
              </div>
            ) }
          </FormField>
          <Field
            name="customMessage"
            label="Custom Message"
            placeholder=""
            component={ RichTextInput }
            maxLength={ 5000 }
            isEnabledCountdownMessage
            dataTest="condorAdminBannerFormEditor"
            editableWrpCls={ formStyles.textArea }
          />
          <Field
            name="disclaimerBackgroundColor"
            label="Background Color"
            component={ ColorPicker }
            containerClassName={ formStyles.formInput }
          />
          <Field
            name="disclaimerTileColor"
            label="Tile Color"
            component={ ColorPicker }
            containerClassName={ formStyles.formInput }
          />
          <Field
            name="disclaimerPrimaryTextColor"
            label="Primary Text Color"
            component={ ColorPicker }
            containerClassName={ formStyles.formInput }
          />
          <Field
            name="disclaimerSecondaryTextColor"
            label="Secondary Text Color"
            component={ ColorPicker }
            containerClassName={ formStyles.formInput }
          />
          <Field
            name="disclaimerLinkColor"
            label="Link Color"
            component={ ColorPicker }
            containerClassName={ formStyles.formInput }
          />
          <Field
            name="disclaimerAccentColor"
            label="Accent Color (Custom Modal)"
            component={ ColorPicker }
            containerClassName={ formStyles.formInput }
          />
          <Field
            name="disclaimerLabelTextColor"
            label="Input Label Text Color"
            component={ ColorPicker }
            containerClassName={ formStyles.formInput }
          />
          <Field
            name="disclaimerButtonTextColor"
            label="Button Text Color"
            component={ ColorPicker }
            containerClassName={ formStyles.formInput }
          />
          <Field
            name="disclaimerButtonColor"
            label="Button Color"
            component={ ColorPicker }
            containerClassName={ formStyles.formInput }
          />
          <Field
            label="Investor Login Primary Text"
            name="loginPrimaryText"
            component={ Input }
            dataTest="loginPrimaryTextInput"
            className={ formStyles.formInput }
            placeholder="Investor Login"
          />
          <Field
            label="Investor Login secondary text"
            name="loginSecondaryText"
            rows={ 8 }
            component={ Textarea }
            placeholder="To access a Deal or Non-Deal Roadshow, login below"
            maxLength={ 5000 }
            isEnabledCountdownMessage
            dataTest="loginSecondaryTextInput"
            textareaClassName={ styles.textArea }
          />
          <Field
            label="First Time Form Primary Text"
            name="firstTimeFormPrimaryText"
            component={ Input }
            dataTest="firstTimeFormPrimaryTextInput"
            className={ formStyles.formInput }
            placeholder="Welcome"
          />
          <Field
            label="First Time Form Secondary Text"
            name="firstTimeFormSecondaryText"
            rows={ 8 }
            component={ Textarea }
            placeholder="This is your first time using Deal Roadshow. Please complete the following form."
            maxLength={ 1000 }
            isEnabledCountdownMessage
            dataTest="firstTimeFromSecondaryTextInput"
            textareaClassName={ styles.textArea }
          />
        </div>
      ) }
    </div>
  );
};

export default ViewerSettings;
