import { batch } from 'react-redux';
import ErrorCodeHelper from '@finsight/error-codes';

import createAction from '@/Framework/State/Redux/createAction';
import * as actionTypes from './actionTypes';
import createAsyncRequest from '@/Framework/State/Redux/createAsyncRequest';
import MappingsRepository from '@/condor/infrastructure/repository/mappings/mappingsRepository';
import UsersMappingsRepository from '@/users/infrastructure/repository/mappingsRepository';
import {
  getMappingsTypes,
  getRemappedItemCounts,
} from '@/condor/application/actions/accounts/mappings/metadata/selectors';
import { getAccountId, createAccountsOptions } from '../helpers';
import { getMappingsAllocationsByFilter } from '@/condor/application/actions/accounts/mappings/allocations/actions';
import { getMappingsEvercallsByFilter } from '@/condor/application/actions/accounts/mappings/evercalls/actions';
import { getMappingsNotesByFilter } from '@/condor/application/actions/accounts/mappings/notes/actions';
import { getMappingsContactsByFilter } from '@/condor/application/actions/accounts/mappings/contacts/actions';
import { getMappingsDealsByFilter } from '@/condor/application/actions/accounts/mappings/deals/actions';
import { getMappingsEmailDomainsByFilter } from '@/condor/application/actions/accounts/mappings/emailDomains/actions';
import { getMappingsLeisByFilter } from '@/condor/application/actions/accounts/mappings/leis/actions';
import { getMappingsWebDomainsByFilter } from '@/condor/application/actions/accounts/mappings/webDomains/actions';
import itemSelectors from '@/condor/application/actions/accounts/item/selectors';

import { messageCodes } from '@/Framework/Message/messages';
import { NotificationManager } from '@/ui/shared/components/Notification';
import { getMessage, getMessageByErrorCode } from '@/Framework/Message/Mapper/getMessage';
import CompaniesRepository from '@/users/infrastructure/repository/CompaniesRepository';

/**
 * @param {Object} payload
 */
export const openCondorAccountMappingModal = (payload) => createAction(
  actionTypes.OPEN_CONDOR_ACCOUNT_MAPPING_MODAL,
  payload,
);

export const openCondorAccountMappingProgressModal = () => createAction(
  actionTypes.OPEN_CONDOR_ACCOUNT_MAPPING_PROGRESS_MODAL,
);

export const closeCondorAccountMappingModal = () => createAction(
  actionTypes.CLOSE_CONDOR_ACCOUNT_MAPPING_MODAL,
);

const resetMetadata = () => createAction(
  actionTypes.RESET_MAPPINGS_METADATA,
);

export const closeCondorAccountMappingProgressModal = () => (dispatch) => {
  batch(() => {
    dispatch(resetMetadata());
    dispatch(getMappingsAllocationsByFilter());
    dispatch(getMappingsEvercallsByFilter());
    dispatch(getMappingsNotesByFilter());
    dispatch(getMappingsContactsByFilter());
    dispatch(getMappingsDealsByFilter());
    dispatch(getMappingsEmailDomainsByFilter());
    dispatch(getMappingsLeisByFilter());
    dispatch(getMappingsWebDomainsByFilter());
  });
};

/**
 * @param {String} query
 * @param {Function} callback
 */
export const fetchAccountsForRemapTo = (query, callback) => async (dispatch, getState) => {
  const companiesRepository = getState().container.get(CompaniesRepository);

  dispatch(createAsyncRequest(
    {
      payload: {
        query,
        excludedIds: [getAccountId(getState())],
        verified: true,
        limit: 50,
      },
      method: companiesRepository.list,
      types: [
        actionTypes.GET_CONDOR_FILTERED_ACCOUNTS_OPTIONS,
        actionTypes.GET_CONDOR_FILTERED_ACCOUNTS_OPTIONS_SUCCESS,
        actionTypes.GET_CONDOR_FILTERED_ACCOUNTS_OPTIONS_ERROR,
      ],
      callbacks: {
        onSuccess: ({ response }) => {
          if (callback) {
            callback(response.collection.map(createAccountsOptions));
          }
        },
      },
    },
  ));
};

/**
 * @param {Object} payload
 */
const subscribeToRemapping = (payload) => async (dispatch, getState) => {
  const mappingsRepository = getState().container.get(MappingsRepository);

  dispatch(createAsyncRequest({
    payload,
    types: [
      actionTypes.SUBSCRIBE_TO_SOCKET_REMAPPING,
      actionTypes.SUBSCRIBE_TO_SOCKET_REMAPPING_SUCCESS,
      actionTypes.SUBSCRIBE_TO_SOCKET_REMAPPING_ERROR,
    ],
    method: mappingsRepository.subscribeToRemapProcess,
    callbacks: {
      onSuccess: ({ dispatch }) => {
        dispatch(closeCondorAccountMappingModal());
        dispatch(openCondorAccountMappingProgressModal());
      },
    },
  }));
};

/**
 * @param {Object} payload
 */
const onRemapProgressMessage = (payload) => createAction(actionTypes.REMAP_PROCESS_UPDATE, payload);

/**
 * @param {Object} payload
 * @param {Object} formData
 */
const onRemapFinished = (payload, formData) => (dispatch, getState) => {
  const state = getState();
  const totalCount = Object.values(getRemappedItemCounts(state)).reduce<number>(
    // @ts-ignore
    (acc, item) => acc + item, 0,
  );

  if (payload.status === 'success') {
    NotificationManager.success(
      getMessage(
        totalCount > 1
          ? messageCodes.REMAPPING_PROCESS_SUCCESS_PLURAL
          : messageCodes.REMAPPING_PROCESS_SUCCESS,
        { totalCount },
      ),
    );
  }

  if (payload.status === 'error') {
    if (payload.error.code === ErrorCodeHelper.getCodeByName('CONDOR_ACCOUNT_MIGRATION_LOCKED')) {
      NotificationManager.error(getMessageByErrorCode(payload.error.code, {
        fromAccountName: itemSelectors.getItemData(state).name,
        toAccountName: formData.toAccountId.label,
      }));
    } else {
      NotificationManager.error(
        getMessage(messageCodes.REMAPPING_PROCESS_ERROR),
      );
    }
  }
  dispatch(createAction(actionTypes.REMAP_PROCESS_FINISHED, { hasError: payload.status === 'error' }));
};

/**
 * @param {Object} formData
 */
export const remapAccountData = (formData) => async (dispatch, getState) => {
  const state = getState();
  const mappingsRepository = state.container.get(UsersMappingsRepository);

  dispatch(createAsyncRequest(
    {
      payload: {
        fromAccountId: getAccountId(state),
        toAccountId: formData.toAccountId.value,
        migrateData: getMappingsTypes(state),
      },
      method: mappingsRepository.remapToAnotherAccount,
      types: [
        actionTypes.START_REMAPPING_PROCESS,
        actionTypes.START_REMAPPING_PROCESS_SUCCESS,
        actionTypes.START_REMAPPING_PROCESS_ERROR,
      ],
      callbacks: {
        onSuccess: ({ payload, dispatch }) => {
          dispatch(subscribeToRemapping({
            fromAccountId: payload.fromAccountId,
            toAccountId: payload.toAccountId,
            onMessage: (payload) => dispatch(onRemapProgressMessage(payload)),
            onFinish: (payload) => dispatch(onRemapFinished(payload, formData)),
          }));
        },
        onError: ({ error }) => {
          if (error.error.code === ErrorCodeHelper.getCodeByName('CONDOR_ACCOUNT_MIGRATION_LOCKED')) {
            NotificationManager.error(getMessageByErrorCode(error.error.code, {
              fromAccountName: itemSelectors.getItemData(state).name,
              toAccountName: formData.toAccountId.label,
            }));
          } else {
            NotificationManager.error(
              getMessage(messageCodes.REMAPPING_PROCESS_ERROR),
            );
          }
        },
      },
    },
  ));
};
