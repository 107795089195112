import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import {
  getDealSettings,
  resetDealSettings,
  getRoadshowMapping,
  onSubmit,
  changeEntryCodeLoginLimit,
  sortEntryCodes,
  setEvercallError,
  getAdminEvercallSetting,
} from '../actions';
import { roadshowTabEnabled } from '../../../actions';
import { DealSettingsForm } from '../components';
import Upload from '@/ui/shared/modules/upload';
import { getIndustryList } from '@/dealroadshow/application/actions/industry/data/actions';
import { getAbsSectorList } from '@/dealroadshow/application/actions/absSector/data/actions';
import { getCdnList } from '@/condor/application/actions/cdn/actions';
import * as dealSettingsFormConstants from '../constants';

const { initUploadLogo, initUploadDisclaimerBackground, initUploadDisclaimerLogo } = Upload.actions;
const selector = formValueSelector(dealSettingsFormConstants.DEAL_SETTINGS_FORM_NAME);

function mapStateToProps(state) {
  let initialValues = {
    ...state.dealRoadshows.roadshow.dealSettings.initialValues,
    ...state.dealRoadshows.roadshow.roadshowMapping.initialValues,
  };
  let isFetching = state.dealRoadshows.roadshow.dealSettings.isFetching
                   || state.dealRoadshows.roadshow.roadshowMapping.isFetching;
  let viewerCustomSettings = {
    ...state.dealRoadshows.roadshow.dealSettings.initialValues?.viewerCustomSettings,
    ...state.dealRoadshows.roadshow.roadshowMapping.initialValues?.viewerCustomSettings,
  };
  let viewerDisclaimerSettings = {
    ...state.dealRoadshows.roadshow.dealSettings.initialValues?.viewerDisclaimerSettings,
    ...state.dealRoadshows.roadshow.roadshowMapping.initialValues?.viewerDisclaimerSettings,
  };

  return {
    transactionTypes: state.transactionTypes.options,
    initialValues,
    viewerCustomSettings,
    viewerDisclaimerSettings,
    isFetching,
    entryCodes: selector(state, 'entryCodes'),
    roadshowLoginLimit: selector(state, 'loginsMaxNumber'),
    rightPanelDescriptionShown: !!selector(state, 'rightPanelDescriptionShown'),
    inRetailTable: selector(state, 'inRetailTable'),
    isPublic: !!selector(state, 'public'),
    viewerCustomizationEnabled: !!selector(state, 'viewerCustomizationEnabled'),
    createTopButton: !!selector(state, 'createTopButton'),
    isViewerDisclaimerCustomizationEnabled: !!selector(state, 'viewerDisclaimerCustomizationEnabled'),
    topButtonType: selector(state, 'topButtonType'),
    toggleIsLoginsMaxNumber: !!selector(state, 'isLoginsMaxNumber'),
    useProxy: selector(state, 'useProxy'),
    industryList: state.dealRoadshows.roadshow.industry.collection,
    absSectorList: state.dealRoadshows.roadshow.absSector.collection,
    cdnList: state.dealRoadshows.roadshow.cdnList.collection,
    uploadedLogo: state.upload.logo,
    uploadedDisclaimerLogo: state.upload.disclaimerLogo,
    uploadedDisclaimerBackgroundLogo: state.upload.disclaimerBackgroundImage,
    currentLogoInForm: selector(state, 'retailTableLogo'),
    currentDisclaimerLogoInForm: selector(state, 'disclaimerLogo'),
    currentDisclaimerBacgroundInForm: selector(state, 'disclaimerBackgroundImage'),
    preSetColors: selector(state, 'toolbarColor', 'rightPanelBackgroundColor'),
    type: selector(state, 'type'),
    canBeChangedVideoType: selector(state, 'canBeChangedVideoType'),
    hasEvercallError: state.dealRoadshows.roadshow.dealSettings.hasEvercallError,
    isRoadshowViewerCustomizeEnabled: !!selector(state, 'roadshowViewerCustomizeEnabled'),
    disclaimerBackgroundImage: selector(state, 'disclaimerBackgroundImage'),
    isCuePointsAdded: selector(state, 'cuePoints')?.length > 0 || selector(state, 'videoOnlyCuePoints')?.length > 0,
    isTransactionTypeLocked: !!selector(state, 'orderBookId'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({
      roadshowTabEnabled,
      resetDealSettings,
      getDealSettings,
      initUploadDisclaimerLogo,
      initUploadDisclaimerBackground,
      initUploadLogo,
      onSubmit,
      getIndustryList,
      getAbsSectorList,
      getRoadshowMapping,
      getCdnList,
      changeEntryCodeLoginLimit,
      sortEntryCodes,
      setEvercallError,
      getAdminEvercallSetting,
    }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DealSettingsForm);
