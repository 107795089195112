import { batch, connect } from 'react-redux';
import { getAccountTypes, resetAccountTypesCollection } from '@/condor/application/actions/accountTypes/collection/actions';
import {
  getCondorAccountById,
  resetCondorAccountsItem,
} from '@/condor/application/actions/accounts/item/actions';
import AccountProfile from './AccountProfile';
import selectors from '@/condor/application/actions/accounts/item/selectors';
import contactsSelectors from '@/condor/application/actions/accounts/mappings/contacts/selectors';
import dealsSelectors from '@/condor/application/actions/accounts/mappings/deals/selectors';
import allocationsSelectors from '@/condor/application/actions/accounts/mappings/allocations/selectors';
import evercallsSelectors from '@/condor/application/actions/accounts/mappings/evercalls/selectors';
import notesSelectors from '@/condor/application/actions/accounts/mappings/notes/selectors';
import leisSelectors from '@/condor/application/actions/accounts/mappings/leis/selectors';
import webDomainsSelectors from '@/condor/application/actions/accounts/mappings/webDomains/selectors';
import emailDomainsSelectors from '@/condor/application/actions/accounts/mappings/emailDomains/selectors';
import {
  isMappingsModalVisible,
  isMappingsProgressModalVisible,
} from '@/condor/application/actions/accounts/mappings/metadata/selectors';
import { openCondorAccountMappingModal } from '@/condor/application/actions/accounts/mappings/metadata/actions';
import { getCurrentUserPermissions } from '@/users/application/actions/permissions/actions';

const mapStateToProps = (state) => (
  {
    isCreating: window.location.pathname.includes('create'),
    isSpinnerVisible: selectors.isCreating(state) || selectors.isUpdating(state) || selectors.isFetching(state),
    item: selectors.getItemData(state),
    isMappingsModalVisible: isMappingsModalVisible(state),
    isMappingsProgressModalVisible: isMappingsProgressModalVisible(state),
    isRemapAllBtnDisabled: !contactsSelectors.getCollectionData(state).length
                           && !dealsSelectors.getCollectionData(state).length
                           && !allocationsSelectors.getCollectionData(state).length
                           && !evercallsSelectors.getCollectionData(state).length
                           && !notesSelectors.getCollectionData(state).length
                           && !leisSelectors.getCollectionData(state).length
                           && !emailDomainsSelectors.getCollectionData(state).length
                           && !webDomainsSelectors.getCollectionData(state).length,
  }
);

const mapDispatchToProps = (dispatch, ownProps) => {
  const isCreating = window.location.pathname.includes('create');
  return {
    init: () => {
      if (!isCreating) {
        dispatch(getCondorAccountById({ id: ownProps.match.params.id }));
        dispatch(getCurrentUserPermissions());
      }

      dispatch(getAccountTypes({ limit: 50, orderBy: 'name', orderAsc: true }));
    },
    reset: () => batch(() => {
      dispatch(resetAccountTypesCollection());
      dispatch(resetCondorAccountsItem());
    }),
    openMappingsModal: () => {
      dispatch(openCondorAccountMappingModal({
        mappingsTitle: 'All',
        mappingsTypes: [
          'contacts',
          'allocations',
          'evercalls',
          'notes',
          'deals',
          'leis',
          'webDomains',
          'emailDomains',
        ],
      }));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountProfile);
