import RoadshowRepository from '@/dealroadshow/infrastructure/repository/RoadshowRepository';
import DocumentsRepository from '@/dealroadshow/infrastructure/repository/DocumentsRepository';
import download from '@/Framework/api/downloadFile';
import * as actionTypes from './actionTypes';

/**
 *
 * @param {{value:string}} payload
 * @return {function(*)}
 */
export function setDealFilesTableFilterSearch(payload) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DEAL_FILES_TABLE_FILTER_SEARCH,
      payload,
    });
  };
}

/**
 *
 * @param {{value:string}} payload
 * @return {function(*)}
 */
export function setDealFilesTableFilterPagination(payload) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DEAL_FILES_TABLE_FILTER_PAGINATION,
      payload,
    });
  };
}

/**
 *
 * @param {{value:string}} payload
 * @return {function(*)}
 */
export function setDealFilesTableFilterPerPage(payload) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DEAL_FILES_TABLE_FILTER_PER_PAGE,
      payload,
    });
  };
}

/**
 *
 * @param {{value:string, group: string}} payload
 * @return {function(*)}
 */
export function setDealFilesTableFilterRadio(payload) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DEAL_FILES_TABLE_FILTER_RADIO,
      payload,
    });
  };
}

/**
 *
 * @param {{sortBy: string, sortOrder: string}} payload
 * @return {function(*)}
 */
export function setDealFilesTableSort(payload) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DEAL_FILES_TABLE_SORT,
      payload,
    });
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function requestDealFilesSuccess(payload) {
  return {
    type: actionTypes.REQUEST_DEAL_FILES_SUCCESS,
    payload,
  };
}

/**
 * @param {Number} payload
 * @return {Object}
 */
function requestDealFilesTotalCountSuccess(payload) {
  return {
    type: actionTypes.REQUEST_DEAL_FILES_TOTAL_COUNT,
    payload,
  };
}

/**
 * @param {String} error
 * @return {Object}
 */
function requestDealFilesError(error) {
  return {
    type: actionTypes.REQUEST_DEAL_FILES_ERROR,
    error,
  };
}

/**
 * @param {{
 *  roadshowId: String,
 *  sortBy: String,
 *  sortOrder: String,
 *  search: String,
 *  filter: String,
 *  page: Number,
 *  perPage: Number
 * }} paramsToRequest
 */
export function getDealFiles(paramsToRequest) {
  return async (dispatch, getState) => {
    const roadshowRepository = getState().container.get(RoadshowRepository);
    dispatch({ type: actionTypes.REQUEST_DEAL_FILES });
    try {
      let roadshows = await roadshowRepository.getAdminDealFiles(paramsToRequest);
      dispatch(requestDealFilesSuccess(roadshows.data));
      dispatch(requestDealFilesTotalCountSuccess(roadshows.totalCount));
    } catch (err) {
      dispatch(requestDealFilesError(err));
    }
  };
}

/**
 * @param {{
 *  url: String,
 *  name: String
 * }} file
 */
export function downloadFile(file) {
  return async (dispatch, getState) => {
    const documentsRepository = getState().container.get(DocumentsRepository);
    dispatch({ type: actionTypes.REQUEST_FILE_TEMPORARY_DOWNLOAD_URL });
    try {
      let { url, originalName } = file;
      let downloadUrl = await documentsRepository.getAdminDownloadUrl(url, originalName);
      download(downloadUrl);
      dispatch({ type: actionTypes.REQUEST_FILE_TEMPORARY_DOWNLOAD_URL_SUCCESS });
    } catch (err) {
      dispatch({ type: actionTypes.REQUEST_FILE_TEMPORARY_DOWNLOAD_URL_ERROR });
    }
  };
}

export function resetDealFiles() {
  return async (dispatch) => {
    dispatch({ type: actionTypes.DEAL_FILES_RESET });
    dispatch({ type: actionTypes.DEAL_FILES_TABLE_RESET });
  };
}
