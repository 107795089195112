import { useState } from 'react';

import { useDIContext } from '@/Framework/DI/DIContext';
import EnhancedTaggingRoadshowsRepository from '@/condor/infrastructure/repository/EnhancedTagging/EnhancedTaggingRoadshowsRepository';
import { NotificationManager } from '@/ui/shared/components/Notification';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { IEnhancedTaggingRoadshow } from '@/condor/domain/vo/EnhancedTagging/Roadshow';
import { IUpdateProgress } from './interface';
import { clearRoadshowForUpdate } from './helpers';
import { TaggingStateTypes } from '@/condor/domain/vo/EnhancedTagging/TaggingState';

interface IProps {
  updateCollection: (collection: IEnhancedTaggingRoadshow[]) => void,
  collection: IEnhancedTaggingRoadshow[],
}

export const useEnhancedTaggingRoadshowsUpdate = ({ updateCollection, collection }: IProps) => {
  const { container } = useDIContext();

  const roadshowRepository = container.get<EnhancedTaggingRoadshowsRepository>(EnhancedTaggingRoadshowsRepository);

  const updateRoadshows = async (
    roadshows: IEnhancedTaggingRoadshow[],
    {
      onProgressUpdate,
    }: {
      onProgressUpdate?: (progress: IUpdateProgress) => void,
    } = {},
  ) => {
    onProgressUpdate?.({
      total: roadshows.length,
      success: 0,
      fail: 0,
      failed: {},
    });

    try {
      const payload = roadshows.map(clearRoadshowForUpdate);

      return await new Promise<IUpdateProgress>((resolve) => {
        const updateStatusHandler = (progress: IUpdateProgress) => {
          onProgressUpdate?.(progress);

          const isFinished = progress.total === progress.fail + progress.success;

          if (isFinished) {
            resolve(progress);
          }
        };

        roadshowRepository.updateRoadshows(payload, updateStatusHandler);
      });
    } finally {
      roadshowRepository.unsubscribeUpdateRoadshows();
    }
  };

  /**
   * Bulk update
   */
  const [bulkUpdateStatus, setBulkUpdateStatus] = useState<IUpdateProgress>(null);
  const clearUpdateStatus = () => setBulkUpdateStatus(null);

  const bulkUpdateRoadshows = async (roadshows: IEnhancedTaggingRoadshow[]) => {
    try {
      const { failed } = await updateRoadshows(roadshows, { onProgressUpdate: setBulkUpdateStatus });
      const savedRoadshows = roadshows.filter((roadshow) => !failed[roadshow.id]);
      updateCollection(savedRoadshows);
    } catch (error) {
      setBulkUpdateStatus(null);
      NotificationManager.error(getErrorMessage(error));
    }
  };

  /**
   * Single update
   */
  const [isUpdating, setIsUpdating] = useState(false);
  const updateRoadshow = async (roadshow: IEnhancedTaggingRoadshow, showLoader = true) => {
    if (showLoader) {
      setIsUpdating(true);
    }

    try {
      const roadshows = [roadshow];
      const { success, failed: errors } = await updateRoadshows(roadshows);

      if (!success) {
        throw Object.values(errors)[0];
      }

      updateCollection(roadshows);
      NotificationManager.success('Changes saved!');
    } catch (error) {
      NotificationManager.error(typeof error === 'string' ? error : getErrorMessage(error));
      throw error;
    } finally {
      if (showLoader) {
        setIsUpdating(false);
      }
    }
  };

  /**
   * Update tagging state
   */
  const updateTaggingState = async (roadshowId: string, taggingState: TaggingStateTypes) => {
    try {
      const updatedRoadshow = collection.find(({ id }) => id === roadshowId);

      updateRoadshow(
        {
          ...updatedRoadshow,
          taggingState,
        },
        false,
      );
    } catch (e) {
      // do nothing
    }
  };

  /**
   * Update underwriters
   */
  const updateUnderwriters = async (
    roadshowId: string,
    enhancedTaggingUnderwriters: IEnhancedTaggingRoadshow['enhancedTaggingUnderwriters'],
  ) => {
    try {
      const originEntity = collection.find(({ id }) => id === roadshowId);

      await updateRoadshow(
        {
          ...originEntity,
          enhancedTaggingUnderwriters,
        },
        false,
      );
    } catch (e) {
      // do nothing
    }
  };

  return {
    clearUpdateStatus,
    bulkUpdateStatus,
    bulkUpdateRoadshows,
    updateRoadshow,
    updateTaggingState,
    updateUnderwriters,
    isUpdating,
  };
};
