import { connect, batch } from 'react-redux';
import {
  getGlobalBlacklistByFilter,

  setCurrentGlobalBlacklistItem,
  deleteGlobalBlacklistItem,
  setGlobalBlacklistIsDeleteModalVisible,
} from '@/condor/application/actions/blacklists/global/actions';
import selectors from '@/condor/application/actions/blacklists/global/selectors';
import DeleteModal from '@/condor/ui/common/Blacklist/DeleteModal';

const mapStateToProps = (state) => (
  {
    item: selectors.getItemData(state),
    isVisible: selectors.isDeleteModalVisible(state),
  }
);

const mapDispatchToProps = (dispatch) => {
  const onCancel = () => batch(() => {
    dispatch(setGlobalBlacklistIsDeleteModalVisible(false));
    dispatch(setCurrentGlobalBlacklistItem({}));
  });

  return {
    onDeleteIconClick: (cellProps) => batch(() => {
      dispatch(setGlobalBlacklistIsDeleteModalVisible(true));
      dispatch(setCurrentGlobalBlacklistItem(cellProps.row));
    }),
    onCancel,
    onDelete: async (item) => {
      await dispatch(deleteGlobalBlacklistItem(item));
      onCancel();
      dispatch(getGlobalBlacklistByFilter());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteModal);
