import { connect } from 'react-redux';
import DeleteImportModal from './DeleteImportModal';
import selectors from '@/condor/application/actions/finsight/import/company/auditTrail/selectors';
import {
  changeDeleteImportModalVisible,
  deleteImport,
} from '@/condor/application/actions/finsight/import/company/auditTrail/actions';

const mapStateToProps = (state) => ({
  importItem: selectors.getDeleteItem(state),
  isVisible: selectors.isDeleteImportModalVisible(state),
});

const mapDispatchToProps = {
  onDelete: deleteImport,
  changeModalVisible: changeDeleteImportModalVisible,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteImportModal);
