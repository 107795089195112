import React from 'react';
import cn from 'classnames';
import {
  TRANCHES_VISIBLE,
} from '@/condor/ui/components/Finsight/DealProfile/constants';
import AddTrancheButton from './AddTrancheButton';
import Button, { variantTypes } from '@/ui/shared/components/Button';
import { Icon, IconType } from '@dealroadshow/uikit';
import { useTranchesContext } from '../TranchesContext';
import styles from './tranchesHeader.scss';

interface IProps {
  tranchesLength: number,
}

const TranchesHeader = ({ tranchesLength }: IProps) => {
  const { tranchesViewOffset, setTranchesViewOffset, isStickyHeaderVisible } = useTranchesContext();

  const someArrowVisible = tranchesLength > TRANCHES_VISIBLE;
  const leftArrowVisible = someArrowVisible && tranchesViewOffset > 0;
  const rightArrowVisible = someArrowVisible && tranchesLength !== tranchesViewOffset + TRANCHES_VISIBLE;

  return (
    <div className={ cn(styles.tranchesHeader, { [styles.withStickyHeader]: isStickyHeaderVisible }) }>
      <AddTrancheButton />
      { someArrowVisible && (
        <>
          { leftArrowVisible && (
            <Button
              className={ cn(styles.leftArrowBtn, { [styles.withShadow]: tranchesViewOffset > 0 }) }
              variant={ variantTypes.text }
              onClick={ () => setTranchesViewOffset(tranchesViewOffset - 1) }
              dataTest="condorTranchesHeaderDeleteOffsetButton"
            >
              <Icon
                className={ styles.arrowIcon }
                type={ IconType.arrowLeftBold }
              />
            </Button>
          ) }
          { rightArrowVisible && (
            <Button
              className={ styles.rightArrowBtn }
              variant={ variantTypes.text }
              onClick={ () => setTranchesViewOffset(tranchesViewOffset + 1) }
              dataTest="condorTranchesHeaderAddOffsetButton"
            >
              <Icon
                className={ styles.arrowIcon }
                type={ IconType.arrowRightBold }
              />
            </Button>
          ) }
        </>
      ) }
    </div>
  );
};

export default TranchesHeader;
