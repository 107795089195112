import React from 'react';
import { Field } from 'redux-form';
import {
  getYear,
  getMonth,
  getDate,
  set,
} from 'date-fns';
import DatePicker from '@/ui/shared/components/Form/DatePicker/DatePicker';
import { ArrowAlign } from '@/ui/shared/components/Form/DatePicker/constants';
import Upload from '@/ui/shared/modules/upload';
import { CheckboxInput, FormField, IconType, Input } from '@dealroadshow/uikit';
import { MONTH_DAY_YEAR_SLASH_FORMAT } from '@/Framework/DateTime/dateFormats';
import Textarea from '@/ui/shared/components/Form/Input/Textarea';
import utcToZonedDate from '@/Framework/DateTime/utcToZonedDate';
import getZonedUTCDate from '@/Framework/DateTime/getZonedUTCDate';

import formStyles from '@/ui/shared/components/Form/form.scss';

const { UploadLogoContainer } = Upload.containers;

interface IProps {
  timeZone: string,
}

const RetailTable = ({ timeZone }: IProps) => {
  const timeZoneName = timeZone || 'America/New_York';

  const formatSelectedDate = (value) => {
    if (value) {
      return utcToZonedDate(timeZoneName, value);
    }
    return null;
  };

  const onSelectDate = (timeZone, value) => {
    let date = new Date(value);
    const endOfChosenDay = set(new Date(), {
      year: getYear(date),
      month: getMonth(date),
      date: getDate(date),
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });
    return getZonedUTCDate(timeZone, endOfChosenDay);
  };

  return (
    <>
      <Field
        name="inRetailTable"
        dataTest="inRetailTable"
        label="Show in Retail Table:"
        component={ CheckboxInput }
        type="checkbox"
      />
      <FormField>
        <div className={ formStyles.formLabel }>
          Retail Table Logo:
          <span className="asterisk">*</span>
        </div>
        <UploadLogoContainer />
      </FormField>
      <Field
        label="Retail Table Description:"
        name="retailTableDescription"
        component={ Textarea }
        dataTest="condorRetailTableDescriptionTextArea"
      />
      <Field
        name="expectedPricingDate"
        label="Expected Pricing Date:"
        component={ (fieldProps) => (
          <FormField>
            <div className={ formStyles.formLabel }>{ fieldProps.label }</div>
            <div className="form-input-date">
              { /* @ts-ignore */ }
              <DatePicker
                customInput={ (
                  // @ts-ignore
                  <Input
                    meta={ fieldProps.meta }
                    name={ fieldProps.input.name }
                    iconClassName={ IconType.calendar }
                    isClearable={ false }
                    dataTest="retailTableInput"
                  />
                ) }
                selected={ formatSelectedDate(fieldProps.input.value) }
                minDate={ utcToZonedDate(timeZoneName) }
                dateFormat={ MONTH_DAY_YEAR_SLASH_FORMAT }
                onSelect={ (value) => {
                  fieldProps.input.onChange(onSelectDate(timeZoneName, value));
                } }
                className={ formStyles.formInput }
                placeholderText="Select Expected Pricing Date"
                popperPlacement="bottom-start"
                arrowAlign={ ArrowAlign.Left }
                dataTest="roadshowRetailTableDatePicker"
              />
            </div>
          </FormField>
        ) }
      />
      <Field
        label="Size (in millions):"
        name="dealSizeMillions"
        component={ Input }
        dataTest="dealSizeMillionsInput"
        maxLength={ 6 }
      />
    </>
  );
};

export default RetailTable;
