import React, { memo } from 'react';
import cn from 'classnames';
import { Switch, NavLink, useLocation } from 'react-router-dom';

import {
  CONDOR_FINSIGHT_IMPORT_DEAL_UPLOAD_BASE_URL,
  CONDOR_FINSIGHT_IMPORT_DEAL_AUDIT_TRAIL_BASE_URL,
} from '@/condor/ui/common/constants';

import Route from '@/ui/shared/components/ReactRouter/Route';
import PageWrapper from '@/condor/ui/common/Layout/PageWrapper';
import ProfileWrapper from '@/condor/ui/common/Layout/ProfileWrapper';
import Upload from './Upload';
import AuditTrail from './AuditTrail';

import styles from './dealImport.scss';
import floatStyles from '@/ui/shared/styles/helpers/floats.scss';
import spacesStyles from '@/ui/shared/styles/helpers/spaces.scss';
import pageStyles from '@/ui/shared/styles/page.scss';
import { getIsUploadPage } from '@/condor/ui/components/Finsight/Import/helpers';

const DealImport = () => {
  const isUploadPage = getIsUploadPage(useLocation().pathname);

  return (
    <PageWrapper
      label="Bulk Upload Deal Data"
      contentCls={ cn(styles.pageWrapper, { [styles.pageWrapperUpload]: isUploadPage }) }
      headerComponent={ (
        <div className={ cn(pageStyles.pageNavigation, floatStyles.pullLeft, spacesStyles.mbn) }>
          <div className={ cn(pageStyles.pageNavigationLinks, spacesStyles.mts, spacesStyles.ptm) }>
            <NavLink
              activeClassName={ pageStyles.isActiveNavLink }
              to={ CONDOR_FINSIGHT_IMPORT_DEAL_UPLOAD_BASE_URL }
            >
              Upload database
            </NavLink>
            <NavLink
              activeClassName={ pageStyles.isActiveNavLink }
              to={ CONDOR_FINSIGHT_IMPORT_DEAL_AUDIT_TRAIL_BASE_URL }
            >
              Audit trail
            </NavLink>
          </div>
        </div>
      ) }
    >
      <ProfileWrapper
        contentClassName={ styles.form }
        containerClassName={ styles.profileWrapperContainer }
        isSpinnerVisible={ false }
      >
        <Switch>
          <Route
            path={ CONDOR_FINSIGHT_IMPORT_DEAL_UPLOAD_BASE_URL }
            component={ Upload }
          />
          <Route
            path={ CONDOR_FINSIGHT_IMPORT_DEAL_AUDIT_TRAIL_BASE_URL }
            component={ AuditTrail }
          />
        </Switch>
      </ProfileWrapper>
    </PageWrapper>
  );
};

export default memo(DealImport);
