import React from 'react';
import cn from 'classnames';
import { useTraceContext } from '@/condor/application/Trace/TraceContextProvider';
import { ExpandableTable, Paginator } from '@dealroadshow/uikit';
import { ITraceTranche } from '@/condor/domain/vo/Trace/TraceTranche';
import { TraceDeal } from '@/condor/domain/vo/Trace/DealsResponse';
import MapTradeExpandableRowContent from './MapTradeExpandableRowContent';
import columns from './mapTradeItemsColumns';
import { DEFAULT_EMPTY_MESSAGE } from '@/ui/shared/components/Tables/constants';

import styles from './styles.scss';
import alignStyles from '@/ui/shared/styles/helpers/align.scss';
import cardStyles from '@/ui/shared/styles/card.scss';

const MapTradeItems = () => {
  const {
    traceMapData: { collection, totalCount },
    mapTradeFetching,
    searchValue,
    mapTradeData: {
      handlePageChange,
      handleItemsPerPageChange,
      page,
      perPage,
    },
  } = useTraceContext();

  if (!mapTradeFetching && !collection.length) {
    return (
      <div className={ cn(styles.tradeItemsTable, alignStyles.flexCenter) }>
        { DEFAULT_EMPTY_MESSAGE }
      </div>
    );
  }

  if (!mapTradeFetching && !searchValue) {
    return (
      <div
        className={ cn(styles.tradeItemsTable, alignStyles.flexCenter) }
      >
        Enter text above to search by deal
      </div>
    );
  }

  const tableCollection = collection.map(({
    id,
    product,
    sector,
    primaryIssuer,
    pricingDate,
    tranches,
    seriesName,
  }: TraceDeal) => ({
      dealId: id,
      abbreviation: product.abbreviation,
      sector: sector?.abbreviation,
      color: sector?.color,
      issuer: primaryIssuer,
      seriesName,
      tranches: tranches.map((tranche: ITraceTranche) => ({
        ...tranche,
        pricingDate: pricingDate?.dateTime,
        dealId: id,
      })),
    }));

  return (
    <>
      <ExpandableTable
        columns={ columns }
        collection={ tableCollection }
        isFetching={ mapTradeFetching }
        className={ styles.tradeItemsTable }
        expandableRowContent={ MapTradeExpandableRowContent }
        dataTest="traceMapTradesTable"
      />
      <Paginator
        className={ cardStyles.cardInner }
        totalCount={ totalCount }
        dataTest="traceMapTradesTablePaginator"
        onItemsPerPageChange={ handleItemsPerPageChange }
        onPageChange={ handlePageChange }
        page={ page }
        perPage={ perPage }
      />
    </>
  );
};

export default MapTradeItems;
