import React, { useCallback, useRef } from 'react';
import cn from 'classnames';
import SharedFooter from '@/ui/shared/modules/Filters/FiltersPanel/Footer';
import FinalForm from '@/ui/shared/components/Form/FinalForm';
import { IFinalFormApi } from '@/ui/shared/components/Form/FinalForm/interfaces';
import { Spinner, Icon, IconType } from '@dealroadshow/uikit';
import PortalWrp, { PortalId } from '@/ui/shared/components/Layout/PortalWrp';
import {
  FiltersType,
  useEnhancedTaggingFiltersContext,
} from '@/condor/application/EnhancedTaggingContexts/EnhancedTaggingFiltersContext';
import DatePeriod from './Sections/DatePeriod';
import Sections from './Sections';

import styles from '@/ui/shared/modules/Filters/FiltersPanel/sidebar.scss';
import headerStyles from '@/ui/shared/styles/headers.scss';
import Tags from './Tags';

const EnhancedTaggingFiltersSidebar = () => {
  const {
    isSidebarOpen,
    closeSidebar,
    filters,
    defaultFilters,
    applyFilters,
    resetFilters,
    isFiltersLoading,
    isFiltersLoaded,
  } = useEnhancedTaggingFiltersContext();

  const formRef = useRef<ReturnType<IFinalFormApi['useForm']>>();

  const submitHandler = (values: FiltersType) => {
    closeSidebar();
    applyFilters(values);
  };

  const closeSidebarHandler = () => {
    closeSidebar();
    formRef.current.reset(filters);
  };

  const renderFields = useCallback(
    ({ values, handleSubmit, form }, { Field }) => {
      formRef.current = form;

      const setFieldValue = (fieldName: string, value: any) => form.change(fieldName, value);
      const onDateFromChange = (value: number) => form.change('dateFrom', value);
      const onDateToChange = (value: number) => form.change('dateTo', value);

      const resetHandler = () => {
        resetFilters();
        form.reset(defaultFilters);
      };

      return (
        <>
          <div className={ styles.sidebarHeader }>
            <div className={ styles.sidebarHeaderContent }>
              <div className={ styles.headerTitle }>
                <div className={ headerStyles.isH2 }>Filters</div>
              </div>
              { isFiltersLoaded && (
                <div className={ styles.headerPeriod }>
                  <div className={ styles.headerPeriodWrp }>
                    <DatePeriod
                      onDateFromChange={ onDateFromChange }
                      onDateToChange={ onDateToChange }
                      dateFrom={ values.dateFrom }
                      dateTo={ values.dateTo }
                    />
                  </div>
                </div>
              ) }
              <div className={ styles.headerClose }>
                <span onClick={ closeSidebarHandler }>
                  <Icon type={ IconType.close } />
                </span>
              </div>
            </div>
          </div>

          <div className={ styles.sidebarWrp }>
            <Spinner overlay overlayClassName={ styles.spinnerOverlay } isVisible={ isFiltersLoading } />
            { isFiltersLoaded && (
              <Sections
                fieldComponent={ Field }
                values={ values }
                setFieldValue={ setFieldValue }
              />
            ) }
          </div>

          <SharedFooter
            // @ts-ignore
            appliedFilters={ filters }
            initialFilters={ defaultFilters }
            filters={ values }
            onApply={ handleSubmit }
            onReset={ resetHandler }
            className={ styles.footerContent }
            tagsComponent={ Tags }
            onTagRemove={ setFieldValue }
          />
        </>
      );
    },
    [filters, defaultFilters, isFiltersLoaded, isFiltersLoading],
  );

  return (
    <PortalWrp portalId={ PortalId.PORTAL_OVERLAY_ID }>
      <div
        className={ cn(styles.sidebarOverlay, {
          [styles.isSidebarOpen]: isSidebarOpen,
        }) }
        onClick={ closeSidebarHandler }
      />
      <div className={ cn(styles.sidebar, { [styles.isSidebarOpen]: isSidebarOpen }) }>
        <FinalForm
          name="filters"
          dataTest="filters"
          className={ styles.responsiveContainer }
          initialValues={ filters }
          render={ renderFields }
          onSubmit={ submitHandler }
        />
      </div>
    </PortalWrp>
  );
};

export default EnhancedTaggingFiltersSidebar;
