import { Editor, Element as SlateElement, Range, Transforms } from 'slate';
import { TLinkElement, TSlateNode } from '@/ui/shared/components/Form/RichTextInput/interfaces';
import { addHttpToUrl } from '@/Framework/url/helpers';

/**
 * HOC wrap all component in editor in link
 */
export const withLinks = (editor: Editor): Editor => {
  const { isInline } = editor;
  editor.isInline = (el: SlateElement & { type?: string }) => (el.type === 'link' ? true : isInline(el));
  return editor;
};

export const isLinkActive = (editor: Editor) => {
  const [link] = Array.from(Editor.nodes(editor, {
    match: (n: TSlateNode) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  }));
  return !!link;
};

const wrapLink = (editor: Editor, url: string) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link: TLinkElement = {
    type: 'link',
    href: addHttpToUrl(url),
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

export const unwrapLink = (editor: Editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n: TSlateNode) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  });
};

export const insertLink = (editor: Editor, url: string) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};
