import React, { useState } from 'react';
import cn from 'classnames';
import type { FieldArrayProps } from 'redux-form';
import { format as formatDate } from 'date-fns';
import { Field } from 'redux-form';
import CopyToClipboard from '@/ui/shared/components/CopyToClipboard';
import {
  Tooltip,
  DataTable,
  dataTableStyles,
  Button,
  ButtonVariantType,
  Input,
  FormField,
  Icon,
  IconType,
} from '@dealroadshow/uikit';
import RichTextInput from '@/ui/shared/components/Form/RichTextInput';
import Upload from '@/ui/shared/modules/upload';
import RoadshowType, { RoadshowTypes } from '@/dealroadshow/domain/RoadshowType';
import { ICuePoint } from './types';
import {
  cellTimeCheck,
} from '@/condor/ui/components/DealRoadshows/Roadshow/modules/AudioVideo/AudioVideoForm/helpers/cellTimeCheck';

import alignStyles from '@/ui/shared/styles/helpers/align.scss';
import styles from './cuePoints.scss';
import formStyles from '@/ui/shared/components/Form/form.scss';

const { UploadPresentationCuePointsListContainer } = Upload.containers;

interface IProps {
  shiftCuePoints: (e: FieldArrayProps, i: number) => void,
  fields: FieldArrayProps,
  cuePoints: ICuePoint[],
  type: RoadshowTypes,
  disabled: boolean,
}

const CuePoints = ({
  shiftCuePoints,
  fields,
  cuePoints,
  type,
  disabled,
}: IProps) => {
  const [localCuePoints, setLocalCuePoints] = useState('');
  const typeName = RoadshowType.isVideoOnly(type) ? 'number' : 'slideNumber';

  const handleAddCuePoints = (e) => setLocalCuePoints(e.target.value);

  const addCuePointsLine = () => {
    let countNewLine = parseInt(localCuePoints);
    if (countNewLine > 0) {
      for (let i = 0; i < countNewLine; i++) {
        fields.push({ [typeName]: fields.length + i + 1 });
      }
      setLocalCuePoints('');
    }
  };

  const removeCuePointsLine = (index) => {
    fields.remove(index);
    shiftCuePoints(fields, index);
  };

  const getCellActionsComponent = ({ rowIndex }) => (
    <Icon
      type={ IconType.trash }
      onClick={ () => removeCuePointsLine(rowIndex) }
      className={ styles.actionsIcon }
    />
  );

  const cellSlideNumberCallback = ({ rowIndex }) => (
    <Field
      name={ `${ fields.name }[${ rowIndex }].${ typeName }` }
      component={ Input }
      dataTest={ `${ fields.name }[${ rowIndex }].numberInput` }
      parse={ (value) => +value }
      disabled
      formFieldClassName={ styles.cuePointsTimeField }
    />
  );

  const cellSlideTimeCallback = ({ rowIndex }) => (
    <Field
      name={ `${ fields.name }[${ rowIndex }].time` }
      component={ Input }
      dataTest={ `${ fields.name }[${ rowIndex }].timeInput` }
      parse={ (value) => (Number.isInteger(+value) ? +value : '') }
      formFieldClassName={ styles.cuePointsTimeField }
      isClearable={ false }
    />
  );

  const cellSectionNameCallback = ({ rowIndex }) => (
    <Field
      name={ `${ fields.name }[${ rowIndex }].sectionName` }
      component={ Input }
      dataTest={ `${ fields.name }[${ rowIndex }].sectionNameInput` }
      maxLength={ 200 }
      formFieldClassName={ styles.cuePointsTimeField }
    />
  );

  const cellSlideTimeStringCallback = ({ rowIndex }) => {
    if (!fields.get(rowIndex)) {
      return null;
    }
    const [time, format] = cellTimeCheck(fields, rowIndex);

    if (time) {
      return (
        <span>{ formatDate(new Date(Number(time) * 1000), String(format)) }</span>
      );
    }

    return null;
  };

  const cellDisplayTextCallback = ({ rowIndex, cellData }) => (
    <Field
      name={ `${ fields.name }[${ rowIndex }].displayName` }
      component={ RichTextInput }
      dataTest={ `${ fields.name }[${ rowIndex }].displayName` }
      formFieldCls={ styles.displayTextField }
      maxLength={ 1000 }
      toolbarPosition="popover"
      editableWrpCls={ styles.displayTextEditableWrp }
      initialValue={ cellData ?? '' }
    />
  );

  const tableColumns = [
    {
      name: typeName,
      title: 'Slide #',
      cellCallback: cellSlideNumberCallback,
      width: 112,
    },
    {
      name: 'time',
      title: (
        <div className={ styles.timeCellHeader }>
          Start Time (Seconds)
          <CopyToClipboard content={ cuePoints.map((cuePoint) => cuePoint.time).join(',\n') }>
            <Icon type={ IconType.copy } />
          </CopyToClipboard>
        </div>
      ),
      className: alignStyles.alignCenter,
      cellCallback: cellSlideTimeCallback,
      width: 180,
    },
    (!RoadshowType.isVideoOnly(type) && {
      name: 'displayName',
      title: 'Display Text (Optional)',
      className: alignStyles.alignLeft,
      cellCallback: cellDisplayTextCallback,
      width: 810,
    }),
    (RoadshowType.isVideoOnly(type) && {
      name: 'sectionName',
      title: 'Section Name',
      className: alignStyles.alignCenter,
      cellCallback: cellSectionNameCallback,
    }),
    {
      name: 'timeMoment',
      title: 'Start Time (MM:SS)',
      className: alignStyles.alignCenter,
      cellCallback: cellSlideTimeStringCallback,
    },
    {
      name: 'actions',
      title: 'Actions',
      className: alignStyles.alignCenter,
      cellCallback: getCellActionsComponent,
    },
  ];

  const isTooltipHidden = !disabled;

  return (
    <>
      <FormField className={ styles.formField }>
        { /* @ts-ignore */ }
        <Input
          type="text"
          name="addCuePoints"
          formFieldClassName={ cn(formStyles.formInput, styles.addCuePoints) }
          inputClassName={ styles.addCuePointsInput }
          onChange={ handleAddCuePoints }
          value={ localCuePoints }
          isNarrow
          dataTest="addCuePointsInput"
          disabled={ disabled }
        />
        <Tooltip
          disabled={ isTooltipHidden }
          content="Audio/video cues cannot be applied to a Roadshow with Moderation activated."
        >
          <Button
            variant={ ButtonVariantType.action }
            onClick={ addCuePointsLine }
            dataTest="addCuePointsButton"
            className={ styles.addCuePointsButton }
            disabled={ disabled }
          >
            <Icon type={ IconType.plus } />
          </Button>
        </Tooltip>
      </FormField>
      <FormField>
        <div className={ formStyles.formLabel }>Drag & Drop Cue Points or</div>
        <UploadPresentationCuePointsListContainer
          disabled={ disabled }
          disabledTooltip="Audio/video cues cannot be applied to a Roadshow with Moderation activated."
        />
      </FormField>
      <DataTable
        containerClassName={ styles.tableContainer }
        className={ cn(dataTableStyles.isHoverable, styles.tableRow) }
        columns={ tableColumns }
        dataTest="addVideoOnlyCuePointsDataTable"
        data={ cuePoints }
      />
    </>
  );
};

export default CuePoints;
