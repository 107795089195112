import { getItemSelectors } from '@/Framework/State/Redux/itemFactory';
import actionTypes from './actionTypes';

const getCondorFilingItem = (state) => state.condor.finsight.filings.item;

export default {
  ...getItemSelectors('condor.finsight.filings.item', actionTypes),
  isUnmapping: (state) => getCondorFilingItem(state).isUnmapping,
  isMapping: (state) => getCondorFilingItem(state).isMapping,
  getFilingId: (state) => getCondorFilingItem(state).data.id,
  getFilingType: (state) => getCondorFilingItem(state).data.type,
  getDealId: (state) => getCondorFilingItem(state).data.dealId,
  getFilingData: (state) => getCondorFilingItem(state).data,
  getSecAssetClass: (state) => getCondorFilingItem(state).data.data['SEC-HEADER']['ABS-ASSET-CLASS'],
  getExistingDealsOptions: (state) => getCondorFilingItem(state).existingDealsOptions,
  getExistingDeal: (state) => getCondorFilingItem(state).existingDeal,
};
