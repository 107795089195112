import { Dependencies } from 'constitute';
import { withCache } from '@/Framework/withCache';
import RpcDispatcher from '@/finsight/application/DI/Rpc/finsight-web/HttpDispatcher';
import Request from '@/Framework/api/Rpc/Request';

@Dependencies(RpcDispatcher)
class RegionRepository {
  /**
   * @param {Dispatcher} rpcDispatcher
   */
  constructor(rpcDispatcher) {
    this.rpc = rpcDispatcher;
  }

  @withCache
  async getList() {
    let response = await this.rpc.request(new Request('finsight_web.market.get_regions', {}));
    return response.getResult().payload;
  }
}

export default RegionRepository;
