import React, { useEffect } from 'react';
import { FormField } from '@dealroadshow/uikit';
import Select, { IProps as ISelectProps } from './Select';
import AsyncSelect from './ReactAsyncSelect';

interface IProps extends ISelectProps {
  input: { [key: string]: any },
  isAsync?: boolean,
  onDidMount?: () => void,
}

/**
 * @deprecated This component is DEPRECATED. Use ui/shared/components/Form/Fields/Select
 */
const ReduxFormSelect = ({ isAsync: propIsAsync = false, ...otherProps }: IProps) => {
  const props = { isAsync: propIsAsync, ...otherProps };
  const { input, options = [], dataTest, isAsync, onDidMount } = props;
  const SelectComponent = isAsync ? AsyncSelect : Select;

  useEffect(() => {
    if (onDidMount) {
      onDidMount();
    }
  }, []);

  return (
    // @ts-ignore
    <FormField
      { ...props }
      dataTest={ `${ dataTest }FormField` }
    >
      { /* @ts-ignore */ }
      <SelectComponent
        { ...props }
        name={ input.name }
        value={ input.value }
        onBlur={ () => input.onBlur() }
        onChange={ input.onChange }
        options={ options }
        dataTest={ dataTest }
      />
    </FormField>
  );
};

export default ReduxFormSelect;
