import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import PermissionRow from './PermissionRow';
import { HeaderWrapper, HeaderRow, BodyWrapper, dataTableStyles } from '@dealroadshow/uikit';
import * as userPermissionsSelectors from '@/users/application/actions/permissions/selectors';

import permissionStyles from './userPermissions.scss';
import alignStyles from '@/ui/shared/styles/helpers/align.scss';

interface IPermissionTableProps {
  activeTab: number,
  hasPermissionToUpdate: boolean,
  updateOwnContact: boolean,
}

const headerColumns = [
  {
    name: 'Functionality',
    title: 'Functionality',
    width: 200,
    className: alignStyles.alignLeft,
  },
  {
    name: 'Description',
    title: 'Description',
    className: alignStyles.alignLeft,
  },
  {
    name: 'Admin',
    title: 'Admin',
    width: 105,
    className: alignStyles.alignCenter,
  },
];

const PermissionTable: FC<IPermissionTableProps> = ({
  activeTab, hasPermissionToUpdate, updateOwnContact,
}) => {
  const { modules } = useSelector(userPermissionsSelectors.getUserPermissionsState);
  const activeModule = Object.keys(modules)[activeTab];
  const moduleContent = modules[activeModule];
  const tableClass = cn(
    dataTableStyles.dataTable,
    dataTableStyles.isHoverable,
    dataTableStyles.nowrapCells,
    permissionStyles.tableContainer,
  );

  return (
    <table className={ tableClass }>
      <HeaderWrapper>
        <HeaderRow columns={ headerColumns } />
      </HeaderWrapper>
      <BodyWrapper>
        { moduleContent.map((item, index) => (
          <PermissionRow
            key={ item.description }
            hasPermissionToUpdate={ hasPermissionToUpdate }
            updateOwnContact={ updateOwnContact }
            id={ item.key }
            rowIndex={ index }
            { ...item }
          />
        )) }
      </BodyWrapper>
    </table>
  );
};

export default PermissionTable;
