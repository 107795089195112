import { connect } from 'react-redux';
import { CONDOR_ACCOUNT_TABLE_INLINE_EDIT_FORM_NAME } from '@/condor/ui/common/constants';
import {
  sort,
  getAccountsByFilter,
} from '@/condor/application/actions/accounts/collection/actions';
import {
  submitAccountForm,
  resetCondorAccountsItem,
} from '@/condor/application/actions/accounts/item/actions';
import columns from './columns';
import validate from '@/condor/ui/components/AccountsAndContacts/Accounts/AccountProfile/profileFormValidators';
import EditableDataTable from '@/condor/ui/common/Layout/EditableDataTable';
import { dataTableStyles } from '@dealroadshow/uikit';
import collectionSelectors from '@/condor/application/actions/accounts/collection/selectors';
import itemSelectors from '@/condor/application/actions/accounts/item/selectors';
import filterSelector from '@/condor/application/actions/accounts/filters/selectors';

const mapStateToProps = (state) => {
  const tableItemSelectedForEditing = itemSelectors.getItemData(state);
  const isDeletedFilter = filterSelector.getFilters(state).isDeleted;

  return {
    className: dataTableStyles.isHoverable,
    columns: isDeletedFilter ? columns.filter((column) => column.name !== 'bulkDelete') : columns,
    collection: collectionSelectors.getCollectionData(state),
    isCollectionFetching: collectionSelectors.isFetching(state),
    sortBy: collectionSelectors.getSortBy(state),
    sortOrder: collectionSelectors.getSortOrder(state),
    selectedItemId: itemSelectors.isInlineEditing(state) ? tableItemSelectedForEditing?.id : undefined,
    isUpdating: itemSelectors.isUpdating(state),
    formProps: {
      form: CONDOR_ACCOUNT_TABLE_INLINE_EDIT_FORM_NAME,
      isSpinnerFixed: true,
      validate,
      initialValues: !tableItemSelectedForEditing ? null : {
        ...tableItemSelectedForEditing,
        accountTypeId: !tableItemSelectedForEditing.accountType ? null : {
          value: tableItemSelectedForEditing.accountType.id,
          label: tableItemSelectedForEditing.accountType.name,
        },
      },
    },
  };
};

const mapDispatchToProps = (dispatch) => (
  {
    onSubmit: async (formData) => {
      await dispatch(submitAccountForm(formData));
      dispatch(resetCondorAccountsItem());
      dispatch(getAccountsByFilter());
    },
    onSortChange: (sortBy, sortOrder) => {
      dispatch(sort({ sortBy, sortOrder }));
      dispatch(getAccountsByFilter());
      dispatch(resetCondorAccountsItem());
    },
  }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditableDataTable);
