import React from 'react';
import { NavLink } from 'react-router-dom';
import { useLocation, useParams } from 'react-router';
import cn from 'classnames';
import URL from '@/finsight/Router/urlConstants';
import { BWICS, INVENTORIES } from '@/finsight/domain/BondType';
import AssetClass from '@/finsight/domain/AssetClass';
import metaTags from '@/finsight/ui/common/metaTagsConstants';
import BwicCountLabel from '@/condor/ui/components/Finsight/Import/Bond/List/Bwic/BwicCountLabelContainer';
import InventoryCountLabel from '@/condor/ui/components/Finsight/Import/Bond/List/Inventory/InventoryCountLabelContainer';
import { useDispatch, useSelector } from 'react-redux';
import { setMetaTags } from '@/finsight/application/actions/metaTags/actions';
import metadataSelectors from '@/finsight/application/actions/bonds/metadata/selectors';

import styles from './bondTypes.scss';
import tabsStyles from '@/ui/shared/styles/tabs.scss';

interface ICondorBondTypes {
  baseUrl: string,
}

const CondorBondTypes = ({ baseUrl = '' }: ICondorBondTypes) => {
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const assetClassCollection = useSelector(metadataSelectors.getAssetClassCollection);

  const linkClickHandler = (e: {
    title: string,
    description: string,
  }) => dispatch(setMetaTags(e));

  return (
    <div className={ styles.tabsWrapper }>
      <NavLink
        to={ `${ baseUrl }${ URL.BONDS_BWICS }-${ params.assetClass }${ location.search }` }
        className={ cn(styles.bondTab, tabsStyles.tab) }
        activeClassName={ tabsStyles.isTabActive }
        onClick={ () => linkClickHandler(metaTags.getBwicsInventory(
                BWICS,
                AssetClass.getByAbbreviation(assetClassCollection, params.assetClass)?.abbreviation,
            )) }
      >
        <span>BWICS</span>
        <BwicCountLabel className={ tabsStyles.tabCountLabel } />
      </NavLink>
      <NavLink
        to={ `${ baseUrl }${ URL.BONDS_INVENTORIES }-${ params.assetClass }${ location.search }` }
        className={ cn(styles.bondTab, tabsStyles.tab) }
        activeClassName={ tabsStyles.isTabActive }
        onClick={ () => linkClickHandler(metaTags.getBwicsInventory(
                INVENTORIES,
                AssetClass.getByAbbreviation(assetClassCollection, params.assetClass)?.abbreviation,
            )) }
      >
        <span>Inventory</span>
        <InventoryCountLabel className={ tabsStyles.tabCountLabel } />
      </NavLink>
    </div>
  );
};

export default CondorBondTypes;
