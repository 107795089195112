import { format, isValid } from 'date-fns';
import { YEAR_MONTH_DAY_DASH_FORMAT } from '@/Framework/DateTime/dateFormats';

/**
 * @param {Object|String|null} date
 * @returns {String|null}
 */
const mapDateToValue = (date) => {
  if (date) {
    const dateObj = new Date(date);
    if (isValid(dateObj)) {
      return format(dateObj, YEAR_MONTH_DAY_DASH_FORMAT);
    }
  }

  return null;
};

export default mapDateToValue;
