import { createContext, useContext, useEffect, useState } from 'react';
import { useUsersContext } from '@/openMarket/application/Condor/UsersContext';
import { IWhitelist, IWhitelistFull } from '../interfaces';

export interface IProps {
  tabName: string,
  close: () => void,
  whitelist: IWhitelist[],
  setWhitelist: (whitelist: IWhitelist[]) => void,
}

const useWhitelist = ({ whitelist: initialWhitelist, setWhitelist: saveWhitelist, close, tabName }: IProps) => {
  const [whitelist, setWhitelist] = useState<IWhitelistFull[]>(
    initialWhitelist.map(({ domain, emailUserName }) => {
      const email = emailUserName ? [emailUserName, domain].join('@') : null;
      return {
        domain,
        emailUserName,
        email,
        emailOrDomain: email ?? domain,
      };
    }),
  );

  const { getUsersByEmails } = useUsersContext();

  useEffect(() => {
    const emails = whitelist.map(({ email }) => email).filter(Boolean);

    if (emails.length > 0) {
      getUsersByEmails(emails);
    }
  }, [whitelist]);

  const submit = () => {
    saveWhitelist(whitelist.map(({ domain, emailUserName }) => ({ domain, emailUserName })));
    close();
  };

  const removeWhitelist = (record: IWhitelist) => {
    setWhitelist((whitelist) => whitelist.filter((item) => record !== item));
  };

  const addNewDomainsAndEmails = (newDomainsAndEmails: IWhitelistFull[]) => {
    setWhitelist((whitelist) => [...newDomainsAndEmails, ...whitelist]);
  };

  return {
    tabName,
    submit,
    close,
    whitelist,
    removeWhitelist,
    addNewDomainsAndEmails,
  };
};

export const WhitelistContext = createContext<ReturnType<typeof useWhitelist>>(null);

export const useWhitelistContext = () => {
  const context = useContext(WhitelistContext);
  if (!context) {
    throw new Error('useWhitelistContext must be used within a WhitelistContext');
  }
  return context;
};

const WhitelistContextProvider = ({ children, ...props }: IProps & { children: React.ReactNode }) => (
  <WhitelistContext.Provider value={ useWhitelist(props) }>{ children }</WhitelistContext.Provider>
);

export default WhitelistContextProvider;
