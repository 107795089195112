import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { Field } from 'redux-form';
import noop from 'lodash/noop';
import FormWrapper from '@/condor/ui/common/Layout/FormWrapper';
import PageWrapper from '@/condor/ui/common/Layout/PageWrapper';
import ProfileWrapper from '@/condor/ui/common/Layout/ProfileWrapper';
import {
  CONDOR_ACCOUNT_TYPE_PROFILE_FORM_NAME,
  CONDOR_ACCOUNT_TYPES_LIST_BASE_URL,
} from '@/condor/ui/common/constants';
import ColorPicker from '@/ui/shared/components/Form/ColorPicker';
import Input from '@/ui/shared/components/Form/Input';
import validate from './profileFormValidators';

import layoutStyles from '@/condor/ui/common/Layout/layout.scss';

const commonInputProps = {
  placeholder: '',
  component: Input,
  className: layoutStyles.inputField,
};

interface IProps {
  isSpinnerVisible: boolean,
  item: any,
  init: () => void,
  reset: () => void,
  onSubmit: () => void,
}

const AccountTypeProfile = (
  {
    isSpinnerVisible,
    item,
    init,
    reset,
    onSubmit,
  }: IProps,
) => {
  const { push } = useHistory();
  const isCreating = window.location.pathname.includes('create');
  const onCancel = () => push(CONDOR_ACCOUNT_TYPES_LIST_BASE_URL);

  return (
    <PageWrapper
      label={ isCreating ? 'Create Account Type' : item?.name }
      onMount={ isCreating ? noop : init }
      onUnmount={ reset }
    >
      <ProfileWrapper isSpinnerVisible={ isSpinnerVisible }>
        <FormWrapper
          form={ CONDOR_ACCOUNT_TYPE_PROFILE_FORM_NAME }
          dataTest={ CONDOR_ACCOUNT_TYPE_PROFILE_FORM_NAME }
          headerText="Account Type Details"
          initialValues={ isCreating ? { verified: true } : { ...item } }
          enableReinitialize
          validate={ validate }
          onSubmit={ onSubmit }
          onCancelClick={ onCancel }
          submitButtonText={ isCreating ? 'Create' : 'Save' }
        >
          { !isCreating && (
            <Field
              name="id"
              label="Unique Id"
              disabled
              { ...commonInputProps }
            />
          ) }
          <Field
            { ...commonInputProps }
            name="name"
            label={ !isCreating ? 'Account Type Name' : null }
            placeholder="Account Type Name"
          />
          <Field
            name="color"
            label={ !isCreating ? 'Account Type Color' : null }
            isNarrow
            component={ ColorPicker }
            containerClassName={ layoutStyles.inputField }
            placeholder="#ffffff"
          />
        </FormWrapper>
      </ProfileWrapper>
    </PageWrapper>
  );
};

export default memo(AccountTypeProfile);
