import { Dependencies } from 'constitute';
import Request from '@/Framework/api/Rpc/Request';
import UsersRpcDispatcher from '@/users/application/DI/Rpc/HttpDispatcher';

@Dependencies(UsersRpcDispatcher)
class MappingsRepository {
  private usersRpc: typeof UsersRpcDispatcher;

  constructor(usersRpcDispatcher: typeof UsersRpcDispatcher) {
    this.usersRpc = usersRpcDispatcher;
  }

  /**
   * @param {object} payload
   * @returns {Promise<object>}
   */
  getContactsMappings = async (payload) => {
    const req = new Request('user.list', payload);
    const response = await this.usersRpc.call(req);

    return response.getResult();
  };

  /**
   * @param {object} payload
   * @returns {Promise<object>}
   */
  getEmailDomainsMappings = async (payload) => {
    const req = new Request('account.email_domains.list', payload);
    const response = await this.usersRpc.call(req);

    return response.getResult();
  };

  /**
   * @param {object} payload
   * @returns {Promise<object>}
   */
  getWebDomainsMappings = async (payload) => {
    const req = new Request('account.web_domains.list', payload);
    const response = await this.usersRpc.call(req);

    return response.getResult();
  };

  /**
   * @param {object} payload
   * @returns {Promise<object>}
   */
  getLeisMappings = async (payload) => {
    const req = new Request('account.lei.list', payload);
    const response = await this.usersRpc.call(req);

    return response.getResult();
  };

  /**
   * @param {object} payload
   * @returns {Promise<object>}
   */
  remapToAnotherAccount = async (payload) => {
    const req = new Request('company.migrate_account_data', payload);
    const response = await this.usersRpc.call(req);

    return response.getResult();
  };
}

export default MappingsRepository;
