import { Dependencies } from 'constitute';
import Request from '@/Framework/api/Rpc/Request';
import FinsightWebRpcDispatcher from '@/finsight/application/DI/Rpc/finsight-web/HttpDispatcher';

@Dependencies(FinsightWebRpcDispatcher)
class SubscriptionsRepository {
  /**
   *
   * @param {Dispatcher} finsightWebRpcDispatcher
   */
  constructor(finsightWebRpcDispatcher) {
    this.finsightWebRpc = finsightWebRpcDispatcher;
  }

  /**
  * @param {Object} payload
   * @returns {Promise}
   */
  getSubscribersListByAdmin = async (payload) => {
    let request = new Request('finsight_web.subscription.get_subscribers', payload);
    let response = await this.finsightWebRpc.request(request);
    return response.getResult().payload;
  };

  /**
  * @param {Object} payload
   * @returns {Promise}
   */
  updateUserSubscriptionsByAdmin = async (payload) => {
    let request = new Request('finsight_web.subscription.update_by_admin', payload);
    let response = await this.finsightWebRpc.request(request);
    return response.getResult().payload;
  };

  /**
  * @param {Object} payload
   * @returns {Promise}
   */
  subscribeMultipleUsersByAdmin = async (payload) => {
    let request = new Request('finsight_web.subscription.multiple_subscriptions', payload);
    let response = await this.finsightWebRpc.request(request);
    return response.getResult().payload;
  };

  /**
   * @param {Object} payload
   * @returns {object}
   */
  getUserSubscriptionsByAdmin = async (payload) => {
    let request = new Request('finsight_web.subscription.get_subscription_list_by_admin', payload);
    let response = await this.finsightWebRpc.request(request);
    return response.getResult().payload;
  };

  /**
   * @returns {object}
   */
  getSubscriptionsListByAdmin = async (payload = {}) => {
    let request = new Request('finsight_web.subscription.get_subscriptions', payload);
    let response = await this.finsightWebRpc.request(request);
    return response.getResult().payload;
  };

  /**
   * @param {Object} payload
   * @returns {object}
   */
  getUserSubscriptionsBySSID = async () => {
    let request = new Request('finsight_web.subscription.get_my_subscriptions_by_ssid');
    let response = await this.finsightWebRpc.request(request);
    return response.getResult().payload;
  };

  /**
   * @param {Object} payload
   * @returns {object}
   */
  getUserSubscriberHash = async (payload) => {
    let request = new Request('finsight_web.subscription.get_subscriber_by_subscription_hash', payload);
    let response = await this.finsightWebRpc.request(request);
    return response.getResult().payload;
  };

  /**
   * @param {object} payload
   * @returns {object}
   */
  updateUserSubscriptionsBySSID = async (payload) => {
    let request = new Request('finsight_web.subscription.update_my_subscriptions_by_ssid', payload);
    let response = await this.finsightWebRpc.request(request);
    return response.getResult().payload;
  };

  /**
   * @param {object} payload
   * @returns {object}
   */
  updateUserSubscriptionsByHash = async (payload) => {
    let request = new Request('finsight_web.subscription.update_my_subscriptions_by_hash', payload);
    let response = await this.finsightWebRpc.request(request);
    return response.getResult().payload;
  };

  /**
   * @param {Object} payload
   * @return {Promise<*>}
   */
  createUserSubscriptionBySSID = async (payload) => {
    let response = await this.finsightWebRpc.request(new Request('finsight_web.subscription.subscribe_by_ssid', payload));
    return response.getResult().payload;
  };

  /**
   * @param {Object} payload
   * @return {Promise<*>}
   */
  createUserSubscriptionByEmail = async (payload) => {
    let response = await this.finsightWebRpc.request(new Request('finsight_web.subscription.subscribe_by_email', payload));
    return response.getResult().payload;
  };

  /**
   * @param {string} payload
   * @returns {object}
   */
  subscribeMultipleUsers = async (payload) => {
    let request = new Request('finsight_web.user_subscription.bond.create_bond_digest_multiple_subscriptions', payload);
    let response = await this.finsightWebRpc.request(request);
    return response.getResult().payload;
  };
}

export default SubscriptionsRepository;
