import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import DealComments from './DealComments';
import { CONDOR_DEAL_PROFILE_FORM_NAME } from '@/condor/ui/common/constants';
import { DEAL_PROFILE_FIELDS } from '@/condor/ui/components/Finsight/DealProfile/constants';
import { mapOptionToValue } from '@/Framework/dataHelpers/selectOptionMappers';
import Product from '@/finsight/domain/Product';

const formSelector = formValueSelector(CONDOR_DEAL_PROFILE_FORM_NAME);

const mapStateToProps = (state) => (
  {
    isProductAbs: Product.isAbsProductId(mapOptionToValue(formSelector(state, DEAL_PROFILE_FIELDS.PRODUCT.name))),
  }
);

export default connect(
  mapStateToProps,
)(DealComments);
