import { connect } from 'react-redux';
import Inventory from '@/finsight/ui/components/bonds/common/BondTables/Inventory/Inventory';
import { sort, paginate, itemsPerPage, fetch } from '@/condor/application/actions/finsight/import/bond/inventory/actions';
import inventorySelectors from '@/condor/application/actions/finsight/import/bond/inventory/selectors';
import metadataSelectors from '@/finsight/application/actions/bonds/metadata/selectors';

const mapStateToProps = (state) => ({
  isFetching: inventorySelectors.isFetching(state),
  data: inventorySelectors.getCollectionData(state),
  totalCount: inventorySelectors.getTotalCount(state),
  sortBy: inventorySelectors.getSortBy(state),
  sortOrder: inventorySelectors.getSortOrder(state),
  page: inventorySelectors.getPage(state),
  perPage: inventorySelectors.getPerPage(state),

  assetClass: metadataSelectors.getActiveAssetClass(state),
  pathname: state.router.location.pathname,
});

const mapDispatchToProps = (dispatch) => ({
  sort: (sortBy, sortOrder) => {
    dispatch(sort(sortBy, sortOrder));
    dispatch(fetch());
  },
  paginate: (page) => {
    dispatch(paginate(page));
    dispatch(fetch());
  },
  itemsPerPage: (perPage) => {
    dispatch(itemsPerPage(perPage));
    dispatch(fetch());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Inventory);
