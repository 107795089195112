import { getCellContent } from './cellCallbacks';

/**
 * @param {object} header
 * @param {object} body
 * @param {object} bodyExistsColumn
 * @param {function} getCellValueByType
 * @returns {array}
 */
const columns = (header, body, bodyExistsColumn, getCellValueByType) => {
  let columns = [];
  Object.keys(header).forEach((columnIndex) => {
    const headerCell = header[columnIndex];
    if (bodyExistsColumn[columnIndex] || headerCell.numberColumn) {
      columns.push({
        width: headerCell.numberColumn ? 56 : 150,
        maxWidth: 150,
        name: headerCell.name,
        title: headerCell.name,
        cellCallback: (data) => {
          const { rowIndex } = data.row;
          const cellData = body[rowIndex][columnIndex];
          return getCellContent(cellData, headerCell, rowIndex, columnIndex, getCellValueByType);
        },
      });
    }
  });
  return columns;
};

export default columns;
