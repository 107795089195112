import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import Input from '@/ui/shared/components/Form/Input';
import { Tooltip, Spinner, Icon, IconType, Modal } from '@dealroadshow/uikit';
import Button, { variantTypes } from '@/ui/shared/components/Button';

import headerStyles from '@/ui/shared/styles/headers.scss';
import spacesStyles from '@/ui/shared/styles/helpers/spaces.scss';
import styles from './advanced.scss';

interface IProps {
  apiAccessKey: string,
  isLoading: boolean,
  getApiAccessKey: () => void,
  setApiAccessKey: () => void,
}

const Advanced = ({ apiAccessKey, getApiAccessKey, setApiAccessKey, isLoading }: IProps) => {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [apiKey, setApiKey] = useState('');

  useEffect(() => {
    getApiAccessKey();
  }, []);

  useEffect(() => {
    if (apiAccessKey) setApiKey(apiAccessKey);
  }, [apiAccessKey]);

  const handleModal = (status) => {
    setIsVisibleModal(status);
  };

  const generateApiKey = () => {
    handleModal(false);
    setApiAccessKey();
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div className={ cn(headerStyles.isH3, spacesStyles.mbl) }>API Controls</div>
      <div>
        <Input
          isNarrow
          isReadOnly
          label="Api access key"
          // @ts-ignore
          input={ {
            name: 'apiAccessKeyInput',
          } }
          type="text"
          name="apiAccessKeyInput"
          dataTest="apiAccessKeyInput"
          placeholder="No api access key assigned to user"
          value={ apiKey }
          // @ts-ignore
          isClearable="false"
          className={ styles.apiKeyInput }
          appendComponent={ (
            <Tooltip
              content="Generate new key"
              containerClassName={ styles.tooltipContainer }
            >
              <div
                className={ styles.refreshButton }
                onClick={ () => handleModal(true) }
              >
                <Icon
                  type={ IconType.refresh }
                  className={ styles.refreshIcon }
                />
              </div>
            </Tooltip>
          ) }
        />
      </div>
      <Modal
        isVisible={ isVisibleModal }
        title="Are you sure?"
        dataTest="getKeyApiModal"
        onCloseClicked={ () => handleModal(false) }
        footer={ (
          <>
            <Button
              onClick={ generateApiKey }
              variant={ variantTypes.action }
              title="Generate"
              dataTest="getKeyApiModalSubmitButton"
              className={ styles.submitButton }
            />
            <Button
              onClick={ () => handleModal(false) }
              variant={ variantTypes.link }
              title="Cancel"
              dataTest="getKeyApiModalCancelButton"
            />
          </>
        ) }
      >
        Are you sure you want to generate a new API Access Key?
        Once the new key is generated, the prior key will no longer work.
      </Modal>
    </>
  );
};

export default Advanced;
