import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

import useFetchCollection from '@/Framework/State/useFetchCollection';
import { useDIContext } from '@/Framework/DI/DIContext';
import ContactRepository from '@/finsight/infrastructure/repository/ContactRepository';
import { TUserPlanTypes } from '@/finsight/application/User/UserPlan/helpers';
import { UserPlanType } from '@/users/domain/UserPlan';

export enum FilterNames {
  IncludeStarter = 'includeStarter',
  IncludePremium = 'includePremium',
  IncludeProfessional = 'includeProfessional',
}

type FilterName = typeof FilterNames[keyof typeof FilterNames];

interface IFiltersState extends Record<FilterName, boolean> {
  includeStarter: boolean,
  includePremium: boolean,
  includeProfessional: boolean,
}

const filterNameToPlanType = {
  [FilterNames.IncludeStarter]: UserPlanType.STARTER,
  [FilterNames.IncludePremium]: UserPlanType.PREMIUM,
  [FilterNames.IncludeProfessional]: UserPlanType.PRO,
};

const useSubscribers = () => {
  const [filtersPlans, setFiltersPlans] = useState<IFiltersState>({
    [FilterNames.IncludeStarter]: false,
    [FilterNames.IncludePremium]: true,
    [FilterNames.IncludeProfessional]: true,
  });
  const { container } = useDIContext();
  const contactRepository = container.get(ContactRepository);

  const checkedPlans = useMemo<TUserPlanTypes>(() => {
    return Object.entries(filtersPlans).reduce((plans, [filterName, isFilterChecked]) => {
      if (isFilterChecked) {
        plans.push(filterNameToPlanType[filterName]);
      }
      return plans;
    }, []);
  }, [filtersPlans]);

  const subscribersCollection = useFetchCollection(
    contactRepository.getSubscribersList,
    { filters: { includedSubscriptionPlans: checkedPlans } },
    { sortBy: 'startedAt', sortOrder: 'desc' },
  );

  const setPlanFilter = (planKey: FilterName) => {
    setFiltersPlans((prevState) => ({
      ...prevState,
      [planKey]: !prevState[planKey],
    }));
  };

  useEffect(() => subscribersCollection.reset, []);

  return {
    ...filtersPlans,
    setPlanFilter,
    ...subscribersCollection,
  };
};

const SubscribersContext = createContext<ReturnType<typeof useSubscribers>>(null);

export const useSubscribersContext = () => {
  const context = useContext(SubscribersContext);
  if (!context) {
    throw new Error('useSubscribersContext must be used within a SubscribersContextProvider');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

const SubscribersContextProvider = ({ children }: IProps) => (
  <SubscribersContext.Provider value={ useSubscribers() }>
    { children }
  </SubscribersContext.Provider>
);

export default SubscribersContextProvider;
