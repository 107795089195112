import { IDatetime } from '@/Framework/DateTime/Datetime';
import { TaggingStateTypes } from './TaggingState';

type Nullable<T extends any> = null | T;

export interface IEnhancedTaggingRoadshow {
  id: string,
  published: boolean,
  transactionTypeId: string,
  name: string,
  companyId: Nullable<number>,
  parent: Nullable<string>,
  subsectorId: Nullable<string>,
  subindustryId: Nullable<string>,
  countryId: Nullable<string>,
  currencyId: Nullable<string>,
  dealSize: Nullable<number>,
  variation: number,
  enhancedTaggingUnderwriters: Array<{
    companyId: number | string,
    name: string,
    abbreviation: string | null,
    lead: boolean,
  }>,
  taggingState: TaggingStateTypes,
  finishedAt: IDatetime,
  createdAt: IDatetime,
}

export interface IEnhancedTaggingRoadshowList {
  collection: IEnhancedTaggingRoadshow[],
  totalCount: number,
}

export const UPDATABLE_FIELDS = [
  'id',
  'transactionTypeId',
  'enhancedTaggingUnderwriters',
  'name',
  'companyId',
  'subsectorId',
  'subindustryId',
  'countryId',
  'currencyId',
  'dealSize',
  'taggingState',
  'variation',
] as const;

export type IEnhancedTaggingRoadshowUpdate = Pick<IEnhancedTaggingRoadshow, (typeof UPDATABLE_FIELDS)[number]>;
