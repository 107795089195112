import { connect } from 'react-redux';
import CondorAsyncSelect from '@/ui/shared/components/Form/Select/legacy/CondorAsyncSelect/CondorAsyncSelect';
import {
  fetchDealCardFieldCompaniesOptions,
  clearDealCardCompanyFieldOptions,
} from '@/condor/application/actions/finsight/deal/actions';
import selectors from '@/condor/application/actions/finsight/deal/selectors';

const mapStateToProps = (state, ownProps) => (
  {
    filteredOptions: selectors.getFilteredCompaniesOptionsByFields(state)[ownProps.input.name],
  }
);

const mapDispatchToProps = (dispatch, ownProps) => (
  {
    fetchOptions: (query, callback) => dispatch(fetchDealCardFieldCompaniesOptions(
      {
        query,
        fieldName: ownProps.input.name,
        includedRole: ownProps.includedRole,
        callback,
      },
    )),
    onBlur: () => dispatch(clearDealCardCompanyFieldOptions(ownProps.input.name)),
  }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CondorAsyncSelect);
