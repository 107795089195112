import { batch } from 'react-redux';
import createAction from '@/Framework/State/Redux/createAction';
import { getAccountsByFilter, paginate } from '../collection/actions';
import * as actionTypes from './actionTypes';
import selectors from './selectors';

/**
 * @param {String} filterName
 */
export const toggleAccountsTableVerifiedFilter = (filterName) => (dispatch, getState) => {
  batch(() => {
    dispatch(createAction(
      actionTypes.SET_CONDOR_ACCOUNTS_TABLE_FILTER_STATE,
      { [filterName]: !selectors.getFilters(getState())[filterName] },
    ));
    dispatch(paginate(1));
  });

  dispatch(getAccountsByFilter());
};

export const resetAccountsFilters = () => createAction(actionTypes.RESET_CONDOR_ACCOUNTS_TABLE_FILTERS);
