import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Tooltip, Button, ButtonVariantType } from '@dealroadshow/uikit';
import ProfileWrapper from '@/condor/ui/common/Layout/ProfileWrapper';
import selectors from '@/condor/application/actions/accounts/item/selectors';
import useReduxSelector from '@/ui/shared/hooks/useReduxSelector';
import { submitAccountForm } from '@/condor/application/actions/accounts/item/actions';
import { IAccountProfileItem } from '@/condor/domain/vo/Accounts/AccountProfileItem';
import { getIsAccountRestrictedToEdit, getStatus, initialAccountProfileValues } from '../../helpers';
import FinalFormWrapper from '@/condor/ui/common/Layout/FormWrapper/FinalFormWrapper';
import { CONDOR_ACCOUNT_PROFILE_FORM_NAME, CONDOR_ACCOUNTS_LIST_BASE_URL } from '@/condor/ui/common/constants';
import validate from '@/condor/ui/components/AccountsAndContacts/Accounts/AccountProfile/profileFormValidators';
import AccountProfileFields, { IValues as IAccountProfileFieldsValues } from './AccountProfileFields';
import { TFinalFormRenderProp } from '@/ui/shared/components/Form/FinalForm/interfaces';
import { RestrictedEditAccountTooltip } from '@/condor/ui/components/AccountsAndContacts/Accounts/constants';
import * as userPermissionsSelectors from '@/users/application/actions/permissions/selectors';
import { ICurrentUserPermission } from '@/users/domain/vo/IContactPermission';

import styles from '../accountProfile.scss';

interface IReduxProps {
  isSpinnerVisible: boolean,
  item: IAccountProfileItem,
  currentUserPermissions: ICurrentUserPermission[],
}

const stateSelector = (state): IReduxProps => ({
  isSpinnerVisible: selectors.isCreating(state) || selectors.isUpdating(state) || selectors.isFetching(state),
  item: selectors.getItemData(state),
  currentUserPermissions: userPermissionsSelectors.getUserPermissionsState(state).currentUserPermissions,
});

const AccountProfileForm = () => {
  const dispatch = useDispatch();
  const { push, location } = useHistory();
  const isCreating = location.pathname.includes('create');
  const onCancel = () => push(CONDOR_ACCOUNTS_LIST_BASE_URL);
  const { isSpinnerVisible, item, currentUserPermissions } = useReduxSelector(stateSelector);
  const [currencyList, setCurrencyList] = useState([]);

  const isAccountRestrictedToEdit = getIsAccountRestrictedToEdit(item, currentUserPermissions);

  const initialValues = useMemo(
    () => initialAccountProfileValues(isCreating, item),
    [isCreating, item],
  );

  const renderForm: TFinalFormRenderProp<IAccountProfileFieldsValues> = ({ values }, { Field }) => (
    <AccountProfileFields
      fieldComponent={ Field }
      isCreating={ isCreating }
      values={ values }
      onSetCurrencyList={ (data) => (setCurrencyList(data)) }
    />
  );

  return (
    <ProfileWrapper
      isSpinnerVisible={ isSpinnerVisible || !currencyList?.length }
      status={ !isCreating ? getStatus(item.verified) : null }
      contentClassName={ styles.form }
    >
      <FinalFormWrapper<IAccountProfileFieldsValues>
        name={ CONDOR_ACCOUNT_PROFILE_FORM_NAME }
        dataTest={ CONDOR_ACCOUNT_PROFILE_FORM_NAME }
        headerText="Account Details"
        initialValues={ initialValues }
        validate={ validate }
        onSubmit={ (formData) => dispatch(submitAccountForm(formData)) }
        onCancelClick={ onCancel }
        submitButtonText={ isCreating ? 'Create' : 'Save' }
        render={ renderForm }
        customActionButton={ (isAccountRestrictedToEdit ? (
          <Tooltip content={ RestrictedEditAccountTooltip }>
            <Button
              type="submit"
              variant={ ButtonVariantType.action }
              disabled={ isAccountRestrictedToEdit }
              title="Save"
              dataTest="condorFormWrapperSubmitButton"
            />
          </Tooltip>
        ) : null
        ) }
      />
    </ProfileWrapper>
  );
};

export default AccountProfileForm;
