import { Dependencies } from 'constitute';
import Request from '@/Framework/api/Rpc/Request';
import UsersRpcDispatcher from '@/users/application/DI/Rpc/HttpDispatcher';

@Dependencies(UsersRpcDispatcher)
class GlobalBlacklistRepository {
  private usersRpc: typeof UsersRpcDispatcher;

  constructor(usersRpc: typeof UsersRpcDispatcher) {
    this.usersRpc = usersRpc;
  }

  /**
   * @return {Promise.<*>}
   */
  list = async (params) => {
    let req = new Request('blacklist.list', params);
    let response = await this.usersRpc.request(req);

    return response.getResult();
  };

  /**
   * @return {Promise.<*>}
   */
  create = async (params) => {
    let req = new Request('blacklist.create', { domain: params });
    let response = await this.usersRpc.request(req);

    return response.getResult();
  };

  /**
   * @param {Number} id
   * @return {Object}
   */
  deleteById = async (id) => {
    let request = new Request('blacklist.delete', { id });
    let response = await this.usersRpc.request(request);

    return response.getResult();
  };
}

export default GlobalBlacklistRepository;
