import { ICustomRegistration } from '@/evercall/domain/vo/call/admin/CustomRegistration';

export const getCustomRegistrationFormInitialValues = (settings: ICustomRegistration): ICustomRegistration => {
  const {
    customDetailsSectionSubfields,
    ...restSettings
  } = settings;

  return {
    ...restSettings,
    customDetailsSectionSubfields: customDetailsSectionSubfields.length !== 0
      ? customDetailsSectionSubfields
      : [
        {
          title: '',
          text: '',
          hyperlinkText: '',
        },
      ],
  };
};
