import { groupValidators } from '@/ui/shared/validators/helpers';
import { validateRequiredFields } from '@/ui/shared/validators/validateRequiredFields';
import { validateMinLength } from '@/ui/shared/validators/validateMinLength';
import { validateMaxLength } from '@/ui/shared/validators/validateMaxLength';

export default (values) => groupValidators(
  validateMinLength({ fieldName: 'seriesName', fieldCaption: 'Series Name', value: values.seriesName, length: 1 }),
  validateMaxLength({ fieldName: 'seriesName', fieldCaption: 'Series Name', value: values.seriesName, length: 255 }),
  validateRequiredFields([
    { fieldName: 'seriesName', fieldCaption: 'Series name' },
    { fieldName: 'sector', fieldCaption: 'Sector' },
    { fieldName: 'product', fieldCaption: 'Product' },
    { fieldName: 'parent', fieldCaption: 'Parent' },
    { fieldName: 'primaryIssuer', fieldCaption: 'Issuer' },
  ], values),
);
