import { Value, Method, Class, Transient } from 'constitute';
import JsonRpcDispatcher, { interceptors } from '@dealroadshow/json-rpc-dispatcher';
import { SockJsAdapter } from '@dealroadshow/socket-frontend-sdk';
import Container from '@/Framework/DI/Container';
import condorUrl from '@/condor/infrastructure/condorUrl';
import SocketConnectionProvider from '@/Framework/DI/Providers/Socket/Connection';
import addSessionData from '@/users/application/Session/interceptors/rpc/addSessionData';
import checkSession from '@/users/application/Session/interceptors/rpc/checkSession';
import parseWrappedError from '@/Framework/api/Rpc/parseWrappedError';
import userPermissionsErrorHandler
  from '@/condor/application/DI/Rpc/interceptors/userPermissionsErrorHandler';

export const SocketAdapterProvider = new Class(SockJsAdapter, Transient.with([
  new Value(condorUrl.getRpcUrl()),
  SocketConnectionProvider,
]));

export default new Method((socketAdapter, container) => (
  (new JsonRpcDispatcher(socketAdapter))
    .interceptRequest(addSessionData(container))
    .interceptResponse(checkSession(container))
    .interceptResponse(userPermissionsErrorHandler(container))
    .interceptResponse(parseWrappedError())
    .interceptResponse(interceptors.response.transformErrorToException)
), [SocketAdapterProvider, Container]);
