import { format } from 'date-fns';
import { MONTH_UNARY_DAY_YEAR_DATE_FULLTIME_FORMAT } from '@/Framework/DateTime/dateFormats';

interface IImportItem {
  importAt: number | string | Date,
  deletedAt: number | string | Date,
}

export const getImportTimestamp = ({ row: importItem }: { row: IImportItem }): string => (
  `${ format(new Date(importItem.importAt), MONTH_UNARY_DAY_YEAR_DATE_FULLTIME_FORMAT) }`
);

export const getDeletedAtTimestamp = (importItem: IImportItem): string => (
  `${ format(new Date(importItem.deletedAt), MONTH_UNARY_DAY_YEAR_DATE_FULLTIME_FORMAT) }`
);

export const getIsUploadPage = (pathname: string): boolean => pathname.includes('upload');
