import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Radio, RadioGroup } from '@dealroadshow/uikit';
import selectors from '@/condor/application/actions/finsight/filings/collection/selectors';
import { getFilingsByFilter, setDealMapping } from '@/condor/application/actions/finsight/filings/collection/actions';
import { FILINGS_MAP_FILTER } from '../FilingsTables/constants';

import styles from './styles.scss';

const stateSelector = (state) => ({
  dealMapping: selectors.getDealMapping(state),
  isFetching: selectors.isFetching(state),
});

const FilingsFilter = ({ types }) => {
  const {
    dealMapping,
    isFetching,
  } = useSelector(stateSelector);
  const dispatch = useDispatch();

  const onChange = (value) => {
    dispatch(setDealMapping(value));
    dispatch(getFilingsByFilter(types));
  };

  return (
    <RadioGroup
      name={ `filter ${ dealMapping } filings` }
      disabled={ isFetching }
      onChange={ onChange }
      isNarrow
      selectedValue={ dealMapping }
      dataTest={ `filter ${ dealMapping } filings` }
      className={ styles.radioGroup }
    >
      {
          FILINGS_MAP_FILTER.map((option) => (
            <Radio
              dataTest={ option.value }
              key={ option.value }
              value={ option.value }
              label={ option.label }
            />
          ))
        }
    </RadioGroup>
  );
};

export default FilingsFilter;
