import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getAccountTypeById,
  submitAccountTypeForm,
  resetAccountType,
} from '@/condor/application/actions/accountTypes/item/actions';
import AccountProfile from './AccountTypeProfile';
import selectors from '@/condor/application/actions/accountTypes/item/selectors';

const mapStateToProps = (state) => (
  {
    isSpinnerVisible: selectors.isCreating(state) || selectors.isUpdating(state) || selectors.isFetching(state),
    item: selectors.getItemData(state),
  }
);

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators(
  {
    init: () => getAccountTypeById(ownProps.match.params.id),
    reset: resetAccountType,
    onSubmit: submitAccountTypeForm,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountProfile);
