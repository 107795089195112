import { COMPANY, ISSUER, PARENT } from '@/finsight/application/actions/pageFilters/constants';
/**
 * @param {Object} actionPayload
 * return {Object}
 */
export const prepareGetDealsPayload = (actionPayload) => ({
  filterType: COMPANY,
  filters: {
    includedCompanyRole: [ISSUER, PARENT],
    ...actionPayload.filters,
  },
  ...actionPayload.paginationAndSearch,
});
