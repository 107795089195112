import React, { createContext, useContext } from 'react';
import useEnhancedTaggingRoadshows from './useEnhancedTaggingRoadshows';

interface IProps {
  children: React.ReactNode,
}

type TEnhancedTaggingRoadshowsContext = ReturnType<typeof useEnhancedTaggingRoadshows>;

export const EnhancedTaggingRoadshowsContext = createContext<TEnhancedTaggingRoadshowsContext>(null);

export const useEnhancedTaggingRoadshowsContext = () => {
  const context = useContext(EnhancedTaggingRoadshowsContext);
  if (!context) {
    throw new Error('useEnhancedTaggingRoadshowsContext must be used within a EnhancedTaggingRoadshowsContextProvider');
  }
  return context;
};

const EnhancedTaggingRoadshowsContextProvider = ({ children }: IProps) => (
  <EnhancedTaggingRoadshowsContext.Provider value={ useEnhancedTaggingRoadshows() }>
    { children }
  </EnhancedTaggingRoadshowsContext.Provider>
);

export default EnhancedTaggingRoadshowsContextProvider;
