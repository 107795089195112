import { Field } from 'redux-form';
import { connect } from 'react-redux';
import { mapOptionsMemoized } from '@/condor/ui/common/Layout/helpers';
import { Select } from '@/ui/shared/components/Form/Fields/Select';
import accountTypesSelectors from '@/condor/application/actions/accountTypes/collection/selectors';
import { getAccountTypes } from '@/condor/application/actions/accountTypes/collection/actions';

const mapStateToProps = (state, ownProps) => (
  {
    name: 'accountTypeId',
    component: Select,
    placeholder: 'Select Account Type',
    backspaceRemovesValue: false,
    options: mapOptionsMemoized(accountTypesSelectors.getCollectionData(state)),
    dataTest: 'accountType',
    ...ownProps,
  }
);

const mapDispatchToProps = {
  handleInputChange: (query) => getAccountTypes({ limit: 50, query }),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Field);
