export const LABEL_COLOR_NEW = '#4ab675';
export const LABEL_COLOR_COUNT = '#4a76b6';

export const INVENTORY_DATE_FORMAT = 'MMM dd, yyyy';
export const BWIC_DATE_FORMAT = 'MMM dd, hh:mm a';
export const BWIC_DATE_FORMAT_EXPIRED = 'MMM dd, yyyy';

export const BWIC_DATE_FORMAT_DUE_BY_TODAY = 'hh:mm a';
export const BWIC_DATE_FORMAT_DUE_BY = 'MMM dd, yyyy';
export const BOND_DATE_FORMAT_FULL_DATE = 'MMM dd, yyyy h:mm a';

export const SEARCH_PLACEHOLDER = 'Filter by CUSIP, Bond, Issuer or Dealer';

export const BONDS_PAGE_TITLE = 'BWICS & Inventory Monitor';

export const bondsNoAccessDescription = 'Instantly view actionable secondary market ABS trading opportunities across dealers with our award-winning BWIC & Inventory Monitor.';

export const bondsNoAccessFeatures = [
  'GlobalCapital’s Trading Data Provider of the Year in 2022 & 2023',
  'Search and filter by Bond, Issuer, Dealer, CUSIP and more to discover your next trade',
  'Analyze dealer & sector volume trends through dynamic visualizations',
];

export const bondsImageCaption = 'Identify marketing and liquidity trends across time by dealer, sector, issuer and deal with dynamic charting.';
