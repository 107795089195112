import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';
import {
  Select,
  SelectSingleValueWithCountryFlag,
  SelectOptionWithCountryFlag,
} from '@dealroadshow/uikit';
import { getCurrencies } from '@/condor/application/actions/accounts/item/actions';
import { ICurrency } from '@/condor/domain/vo/Currency';

import styles from './AccountProfile/accountProfile.scss';

const DEFAULT_CURRENCY = 'USD';

interface IProps {
  fieldComponent: React.ElementType,
  label: string,
  name: string,
  currentCurrencyId: string,
  onSetCurrencyList: (currencyList: ICurrency[]) => void,
}

const CurrencyField = ({ fieldComponent: Field, currentCurrencyId, onSetCurrencyList, ...otherProps }:IProps) => {
  const dispatch = useDispatch();
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [currencyOptions, setCurrencyOptions] = useState([]);

  const components = {
    Option: SelectOptionWithCountryFlag,
    SingleValue: SelectSingleValueWithCountryFlag,
  };

  const mapCurrencyToOption = (currency) => (
    {
      value: currency.id,
      label: currency.displayName,
      currencySymbol: currency.abbreviation,
      countryCode: currency.flagDisplayName,
    }
  );

  const setCurrency = (currencyList) => {
    onSetCurrencyList(currencyList);

    if (currencyList?.length) {
      const currency = currencyList.find((currency) => {
        if (currentCurrencyId) {
          return isEqual(currency.id, currentCurrencyId);
        }

        return isEqual(currency.displayName, DEFAULT_CURRENCY);
      });

      setSelectedCurrency(mapCurrencyToOption(currency));
      setCurrencyOptions(currencyList.map(mapCurrencyToOption));
    }
  };

  useEffect(() => {
    // @ts-ignore
    dispatch(getCurrencies()).then((response) => {
      setCurrency(response);
    });
  }, []);

  return (
    <Field
      component={ (fieldProps) => (
        <Select
          label={ fieldProps.label }
          name={ fieldProps.input.name }
          value={ fieldProps.input.value ? fieldProps.input.value : selectedCurrency }
          onChange={ fieldProps.input.onChange }
          onBlur={ () => fieldProps.input.onBlur(fieldProps.input.value) }
          options={ currencyOptions }
          components={ components }
          placeholder="Select"
          isSimpleValue
          className={ styles.currency }
          dataTest="aumCurrencySelect"
        />
      ) }
      { ...otherProps }
    />
  );
};

export default CurrencyField;
