import React from 'react';
import cn from 'classnames';

import { Tooltip, StatusIndicator } from '@dealroadshow/uikit';
import { getDeletedAtTimestamp } from '@/condor/ui/components/Finsight/Import/helpers';

import DateOfImportCell from './DateOfImportCell';
import styles from './auditTrail.scss';

import { IMPORT_STATUS } from '@/condor/ui/components/Finsight/Import/constants';

/**
 @param {object} cellProps
 @return {jsx}
 */
export const dateOfImportCellCallback = (cellProps) => (
  <DateOfImportCell cellProps={ cellProps } />
);

/**
 @param {object} cellProps
 @return {jsx}
 */
export const getStatusCellCallback = ({ row: companyImport }) => {
  const statusColorStyles = cn({
    [styles.deleted]: !!companyImport.deletedAt,
    [styles.started]: companyImport.importStatus === IMPORT_STATUS.STARTED && !companyImport.deletedAt,
    [styles.processing]: companyImport.importStatus === IMPORT_STATUS.PROCESSING && !companyImport.deletedAt,
    [styles.processed]: companyImport.importStatus === IMPORT_STATUS.PROCESSED && !companyImport.deletedAt,
    [styles.error]: companyImport.importStatus === IMPORT_STATUS.ERROR && !companyImport.deletedAt,
  });
  const status = companyImport.importStatus === IMPORT_STATUS.STARTED
    ? IMPORT_STATUS.PROCESSING
    : companyImport.importStatus;
  const tooltipContent = !companyImport.deletedAt
    ? <span className={ styles.statusText }>{ status }</span>
    : (
      <div className={ styles.statusDate }>
        Bulk Upload Deleted on:
        <div className={ styles.statusTimestamp }>{ getDeletedAtTimestamp(companyImport) }</div>
      </div>
    );

  return (
    <span className={ styles.statusLabel }>
      <Tooltip content={ tooltipContent }>
        <StatusIndicator className={ statusColorStyles } />
      </Tooltip>
    </span>
  );
};
