import React, { ReactNode, useEffect } from 'react';
import noop from 'lodash/noop';
import cn from 'classnames';

import pageStyles from '@/ui/shared/styles/page.scss';
import cardStyles from '@/ui/shared/styles/card.scss';
import spacesStyles from '@/ui/shared/styles/helpers/spaces.scss';
import headerStyles from '@/ui/shared/styles/headers.scss';

interface IProps {
  label?: string,
  className?: string,
  labelCls?: string,
  headerCls?: string,
  contentCls?: string,
  headerComponent?: ReactNode,
  children: ReactNode,
  onMount?: () => void,
  onUnmount?: () => void,
}

const PageWrapper = ({
    label,
    className,
    labelCls,
    headerCls,
    contentCls,
    headerComponent,
    children,
    onMount = noop,
    onUnmount = noop,
}: IProps) => {
  useEffect(() => {
    onMount();
    return onUnmount;
  }, []);

  return (
    <div className={ className }>
      { (label || headerComponent) && (
        <div className={ cn(pageStyles.pageHeader, spacesStyles.mbl, headerCls) }>
          <div
            className={ cn(pageStyles.pageHeaderTitle, headerStyles.isH2, labelCls) }
            data-test="pageHeaderTitle"
          >
            { label }
          </div>
          { headerComponent && (headerComponent) }
        </div>
      ) }
      <div className={ cn(cardStyles.cardContainer, spacesStyles.mbn, contentCls) }>
        { children }
      </div>
    </div>
  );
};

export default PageWrapper;
