import { Dependencies } from 'constitute';
import Request from '@/Framework/api/Rpc/Request';
import RpcDispatcher from '@/users/application/DI/Rpc/HttpDispatcher';

@Dependencies(RpcDispatcher)
class TitleRepository {
  private rpc: typeof RpcDispatcher;

  constructor(rpc) {
    this.rpc = rpc;
  }

  list = async (data = {}) => {
    let request = new Request('user.title.list', data);
    let response = await this.rpc.request(request);

    return response.getResult();
  };

  /**
   * @param {String} id
   *
   * @return {Promise.<*>}
   */
  async getById(id) {
    let req = new Request('user.title.get_by_id', { id });
    let response = await this.rpc.request(req);

    return response.getResult();
  }

  /**
   * @param {String} name
   * @return {Promise.<*>}
   */
  async getByName(name) {
    let req = new Request('user.title.get_by_name', { name });
    let response = await this.rpc.request(req);

    return response.getResult();
  }
}

export default TitleRepository;
