import React from 'react';
import Button, { variantTypes } from '@/ui/shared/components/Button';
import styles from './companySelectModalButtons.scss';
import { Icon, IconType } from '@dealroadshow/uikit';

interface IProps {
  submitDisabled: boolean,
  onCancelClick: () => void,
  onCreateCompanyClick: () => void,
}

const CompanySelectModalButtons = (
  {
    submitDisabled,
    onCancelClick,
    onCreateCompanyClick,
  }: IProps,
) => (
  <div className={ styles.buttonsWrp }>
    <div>
      <Button
        type="submit"
        variant={ variantTypes.action }
        title="Save"
        disabled={ submitDisabled }
        dataTest="companySelectModalSaveButton"
      />
      <Button
        variant={ variantTypes.text }
        title="Cancel"
        onClick={ onCancelClick }
        dataTest="companySelectModalCancelButton"
      />
    </div>
    <Button
      variant={ variantTypes.text }
      onClick={ onCreateCompanyClick }
      className={ styles.createBtn }
      dataTest="companySelectModalCreateCompanyButton"
    >
      <Icon
        className={ styles.addIcon }
        type={ IconType.add }
      />
      Create New Company
    </Button>
  </div>
);

export default CompanySelectModalButtons;
