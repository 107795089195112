import { Class, Method, Transient, Value } from 'constitute';
import JsonRpcDispatcher, { interceptors } from '@dealroadshow/json-rpc-dispatcher';
import openMarketUrl from '@/openMarket/infrastructure/openMarketUrl';
import addSessionData from '@/users/application/Session/interceptors/rpc/addSessionData';
import checkSession from '@/users/application/Session/interceptors/rpc/checkSession';
import parseWrappedError from '@/Framework/api/Rpc/parseWrappedError';
import Container from '@/Framework/DI/Container';
import AdapterFactory from '@/Framework/DI/Providers/Rpc/AdapterFactory';

const FetchAdapterFactory = new Class(
  AdapterFactory,
  Transient.with([new Value(openMarketUrl.getRpcUrl()), Container]),
);

export default new Method(
  (adapterFactory: AdapterFactory, container: Container) => new JsonRpcDispatcher(adapterFactory.getAdapter())
      .interceptRequest(addSessionData(container))
      .interceptResponse(checkSession(container))
      .interceptResponse(parseWrappedError())
      .interceptResponse(interceptors.response.transformErrorToException),
  [FetchAdapterFactory, Container],
);
