import React from 'react';
import styles from './industryCell.scss';
import Product from '@/finsight/domain/Product';

interface IProps {
  row: any,
}

const IndustryOrSectorCell = ({ row }: IProps) => {
  let cellText = '';
  if (Product.isAbsProductId(row.product.id)) {
    cellText = `${ row.sector.name } - ${ row.subsector.name }`;
  } else {
    cellText =
      `${ row.parent.industry.name }${ row.parent.subindustry.id ? ` - ${ row.parent.subindustry.name }` : '' }`;
  }
  return (
    <div className={ styles.industryCell }>
      { cellText }
    </div>
  );
};

export default IndustryOrSectorCell;
