import React from 'react';

import { CONDOR_CONTACT_LINKS_BASE_URL, CONDOR_CONTACT_PROFILE_BASE_URL } from '@/condor/ui/common/constants';
import { getCellDisplayDateFromString } from '@/condor/ui/common/Layout/helpers';
import { Spinner, IconType, ICellProps } from '@dealroadshow/uikit';
import TableRowActions from '@/condor/ui/common/Layout/TableRowActions';
import { LastTableCell } from '@/ui/shared/components/Tables/TableWrapper';

interface IProps {
  cellProps: ICellProps,
  isSelectedForEditing: boolean,
  isUserUpdating: boolean,
  onEditClick: () => void,
  onSubmit: () => void,
  onReset: () => void,
  inlineEditFormState: {
    submitting: boolean,
    pristine: boolean,
    invalid: boolean,
  },
}

const LastLoginAtCell = (props: IProps) => {
  const actions = props.isSelectedForEditing ? [
    {
      tooltipContent: 'Save',
      iconType: IconType.check,
      onClick: props.onSubmit,
      disabled: props.inlineEditFormState.submitting ||
                props.inlineEditFormState.pristine ||
                props.inlineEditFormState.invalid,
    },
    {
      tooltipContent: 'Cancel',
      iconType: IconType.cancel,
      onClick: props.onReset,
    },
  ] : [
    {
      tooltipContent: 'Edit',
      iconType: IconType.pencil,
      onClick: props.onEditClick,
    },
    {
      url: `${ CONDOR_CONTACT_PROFILE_BASE_URL }/${ props.cellProps.row.id }`,
      tooltipContent: 'Profile',
      iconType: IconType.settings,
    },
    {
      url: `${ CONDOR_CONTACT_LINKS_BASE_URL }/${ props.cellProps.row.id }`,
      tooltipContent: 'Links',
      iconType: IconType.link,
    },
  ];

  return (
    <LastTableCell
      showHoverContent={ props.isSelectedForEditing }
      childrenToShowOnHover={
          !props.isUserUpdating
            ? <TableRowActions actions={ actions } />
            : (
              <Spinner
                size="small"
                isVisible={ props.isSelectedForEditing }
              />
            )
        }
    >
      { getCellDisplayDateFromString(props.cellProps.row.lastLoginAt) }
    </LastTableCell>
  );
};

export default LastLoginAtCell;
