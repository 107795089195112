import createAction from '@/Framework/State/Redux/createAction';
import { getCollectionActions } from '@/Framework/State/Redux/collectionFactory';
import { NotificationManager } from '@/ui/shared/components/Notification';
import { messageCodes } from '@/Framework/Message/messages';
import { getMessage } from '@/Framework/Message/Mapper/getMessage';
import CompaniesRepository from '@/condor/infrastructure/repository/companies/CompaniesRepository';
import UsersCompaniesRepository from '@/users/infrastructure/repository/CompaniesRepository';
import createAsyncRequest from '@/Framework/State/Redux/createAsyncRequest';
import sleep from '@/ui/shared/helpers/sleep';
import actionTypes from './actionTypes';
import selectors from './selectors';
import filtersSelectors from '../filters/selectors';
import { IAccountProfileItem } from '@/condor/domain/vo/Accounts/AccountProfileItem';

export const {
  paginate,
  sort,
  itemsPerPage,
  getCollection: getAccountsStats,
  setSearchQuery: setCondorAccountsSearchQuery,
  reset: resetAccounts,
} = getCollectionActions(actionTypes,
  (payload, dispatch, getState) => {
    const companiesRepository = getState().container.get(CompaniesRepository);
    return companiesRepository.listStats(payload);
  },
);

export const bulkDeleteAccounts = (accountIds: number[] | string[]) => createAsyncRequest(
  {
    payload: { accountIds },
    method: (payload, dispatch, getState) => {
      const companiesRepository = getState().container.get(UsersCompaniesRepository);
      return companiesRepository.bulkDelete(payload);
    },
    types: [
      actionTypes.BULK_DELETE_ACCOUNTS,
      actionTypes.BULK_DELETE_ACCOUNTS_SUCCESS,
      actionTypes.BULK_DELETE_ACCOUNTS_ERROR,
    ],
    callbacks: {
      onSuccess: async ({ dispatch, getState }) => {
        const singleAccountName = selectors.getCollectionData(getState())
          .find((account) => account.id === accountIds[0])?.name;
        const accountName = accountIds.length > 1 ? `${ accountIds.length } accounts` : singleAccountName;

        NotificationManager.success(
          getMessage(messageCodes.DELETE_COMPANY_SUCCESS, { accountName }),
        );

        dispatch(toggleBulkDelete([]));
        await sleep(2500);
        dispatch(getAccountsByFilter());
      },
    },
  },
);

export const setBulkDeleteItems = (payload: IAccountProfileItem | IAccountProfileItem[]) => createAction(
    actionTypes.TOGGLE_BULK_DELETE, payload,
);

export const getAccountsByFilter = () => async (dispatch, getState) => {
  const state = getState();

  const searchQuery = selectors.getSearchQuery(state);
  const includeVerified = filtersSelectors.isVerified(state);
  const includeUnverified = filtersSelectors.isUnverified(state);
  let verifiedFilter = 'all';
  if ((includeVerified && !includeUnverified) || (!includeVerified && includeUnverified)) {
    verifiedFilter = includeVerified ? 'verified' : 'unverified';
  }

  const payload = {
    perPage: selectors.getPerPage(state),
    page: selectors.getPage(state),
    sortBy: selectors.getSortBy(state),
    sortOrder: selectors.getSortOrder(state),
    accountFilter: {
      ...(searchQuery && { search: searchQuery }),
      deletedOnly: filtersSelectors.isDeleted(state),
      verifiedFilter,
    },
  };

  dispatch(getAccountsStats(payload));
};

export const toggleBulkDelete = (data: IAccountProfileItem | IAccountProfileItem[]) => async (dispatch, getState) => {
  let prepareBulkDeleteItems;
  const state = getState();
  const bulkDeleteItems = selectors.bulkDeleteItems(state);

  if (Array.isArray(data)) {
    const dataIds = data.map(({ id }) => id);
    prepareBulkDeleteItems = data.length
     ? [...bulkDeleteItems, ...data]
     : bulkDeleteItems.filter((x) => dataIds.includes(x));
  } else {
    prepareBulkDeleteItems = bulkDeleteItems.find((item) => item.id === data.id)
                             ? bulkDeleteItems.filter((item) => item.id !== data.id)
                             : bulkDeleteItems.concat(data);
  }

  dispatch(setBulkDeleteItems(prepareBulkDeleteItems));
};
