export const DEFAULT_CUSTOMIZATION_COLORS = {
  backgroundColor: '#F3F7F6',
  tileColor: '#FFFFFF',
  primaryTextColor: '#2D2D2D',
  secondaryTextColor: '#616161',
  linkColor: '#3D9086',
  inputLabelColor: '#8C8C8C',
  buttonTextColor: '#FFFFFF',
  buttonColor: '#3D9086',
};
