import UsersAccountTypeRepository from '@/users/infrastructure/repository/AccountTypeRepository';
import AccountTypeRepository from '@/condor/infrastructure/repository/companies/AccountTypeRepository';
import createAsyncRequest from '@/Framework/State/Redux/createAsyncRequest';
import { getCollectionActions } from '@/Framework/State/Redux/collectionFactory';
import actionTypes from './actionTypes';
import selectors from './selectors';

/**
 * @param {Object} payload
 */
export const getAccountTypes = (payload = {}) => (dispatch, getState) => {
  const accountTypeRepository: UsersAccountTypeRepository = getState().container.get(UsersAccountTypeRepository);

  return dispatch(createAsyncRequest(
    {
      payload,
      method: accountTypeRepository.list,
      types: [
        actionTypes.GET_ACCOUNT_TYPES,
        actionTypes.GET_ACCOUNT_TYPES_SUCCESS,
        actionTypes.GET_ACCOUNT_TYPES_ERROR,
      ],
    },
  ));
};

export const {
  getCollection: getAccountTypesStats,
  sort,
  itemsPerPage,
  paginate,
  reset: resetAccountTypesCollection,
} = getCollectionActions(
  actionTypes,
  (payload, dispatch, getState) => {
    const accountTypeRepository: AccountTypeRepository = getState().container.get(AccountTypeRepository);
    return accountTypeRepository.listStats(payload);
  },
);

export const getAccountTypesStatsByFilter = () => (dispatch, getState) => {
  const state = getState();

  dispatch(getAccountTypesStats(
    {
      perPage: selectors.getPerPage(state),
      page: selectors.getPage(state),
      sortBy: selectors.getSortBy(state),
      sortOrder: selectors.getSortOrder(state),
    },
  ));
};
