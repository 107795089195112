import React, { useCallback, useEffect } from 'react';
import { Spinner, Modal } from '@dealroadshow/uikit';
import ManageSubscribeForm from './ManageSubscribeForm';

import styles from './manageSubscriptionsModal.scss';

interface IProps {
  isModalOpen: boolean,
  item: any,
  onCancel: (value: any) => void,
  initManageSubscriptionsModal: (payload: { email: string }) => void,
  submitForm: (email: string, values: any) => void,
  reset: () => void,
  isSpinnerVisible: boolean,
}

const ManageSubscriptionsModal = (
  {
    item: { email },
    isModalOpen,
    onCancel,
    isSpinnerVisible,
    initManageSubscriptionsModal,
    reset,
    submitForm,
  }: IProps,
) => {
  useEffect(() => {
    initManageSubscriptionsModal({
      email,
    });
    return reset;
  }, []);

  const onCancelClick = useCallback(() => {
    onCancel(null);
  }, []);

  const onFormSubmit = useCallback((formValues) => {
    submitForm(
      email,
      formValues,
    );
  }, [email]);
  return (
    <Modal
      title="Manage Finsight Email Alerts"
      isVisible={ isModalOpen }
      onCloseClicked={ onCancelClick }
      className={ styles.modal }
      dataTest="manageFinsightEmailAlertsModal"
    >
      <p className={ styles.modalData }>
        The following email alerts are set for <b>{ email }</b>:
      </p>
      { !isSpinnerVisible && (
        <ManageSubscribeForm
          onCancel={ onCancelClick }
          submitButtonTitle="Update Email Subscriptions"
          submitForm={ onFormSubmit }
          email={ email }
        />
      ) }
      { isSpinnerVisible && (<Spinner overlay />) }
    </Modal>
  );
};

export default ManageSubscriptionsModal;
