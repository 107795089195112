import { useEffect, useRef } from 'react';

const useFormReinitializeAfterSubmit = (onReinitialize: () => void, isSubmitting: boolean) => {
  const shouldReinitialize = useRef(false);

  useEffect(
    () => {
      if (isSubmitting) {
        shouldReinitialize.current = true;
      } else if (shouldReinitialize.current) {
        onReinitialize();
      }
    },
    [isSubmitting],
  );
};

export default useFormReinitializeAfterSubmit;
