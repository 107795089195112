import { connect } from 'react-redux';
import validationSelectors from '@/condor/application/actions/finsight/import/deal/validation/selectors';
import uploadSelectors from '@/condor/application/actions/finsight/import/deal/upload/selectors';
import DealUpload from './DealUpload';
import downloadFile from '@/Framework/api/downloadFile';
import files from '@/finsight/infrastructure/finsightWebUrl';
import { reset as resetAll } from '@/condor/application/actions/finsight/import/deal/upload/actions';

const mapStateToProps = (state) => (
  {
    validationTableVisible: validationSelectors.isValidationTableVisible(state),
    companySelectModalVisible: validationSelectors.isCompanySelectModalVisible(state),
    companyCreateModalVisible: validationSelectors.isCompanyCreateModalVisible(state),
    showAreYouSureModalIfNeeded: !!uploadSelectors.getUploadFileProcessId(state),
  }
);
const mapDispatchToProps = {
  onTemplateDownload: () => () => downloadFile(files.getDealImportTemplateUrl()),
  resetAll,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DealUpload);
