import React from 'react';
import cn from 'classnames';
import { addMinutes } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

import styles from './dateCell.scss';

const getDateFormat = ({
  withoutTime,
  withoutTimeZone,
}: {
  withoutTime: boolean,
  withoutTimeZone: boolean,
}): string => {
  let format = 'MMM dd, yyyy';

  if (!withoutTime) {
    format += ' hh:mm a';
  }

  if (!withoutTimeZone) {
    format += ' zzz';
  }

  return format;
};

interface IProps {
  date: string | number,
  withoutTime?: boolean,
  timeZone?: string,
  className?: string,
  timeZoneAbbr?: string,
  withoutFormat?: boolean,
  withoutAbbr?: boolean,
  isUtc?: boolean,
}

const DateCell = ({
  date,
  timeZone = 'America/New_York',
  withoutTime,
  className,
  timeZoneAbbr,
  withoutFormat,
  withoutAbbr,
  isUtc,
}: IProps) => {
  const dateObj = new Date((!withoutFormat && typeof date === 'string') ? date.replace(/ /g, 'T') : date);

  const localDate = isUtc ? addMinutes(dateObj, -dateObj.getTimezoneOffset()) : dateObj;

  const formattedDate = formatInTimeZone(
    localDate,
    timeZone,
    getDateFormat({
      withoutTime,
      withoutTimeZone: withoutAbbr || !!timeZoneAbbr,
    }),
  );

  return (
    <div className={ cn(styles.date, className) }>
      { formattedDate } { timeZoneAbbr }
    </div>
  );
};

export default DateCell;
