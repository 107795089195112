export const DEFAULT_WATERMARKS_OPACITY = 8;

export const SIDEBAR_WIDTHS = {
  STANDARD: 'standard',
  MEDIUM: 'medium',
  LARGE: 'large',
};

export const ROADSHOW_FORM_DISCLAIMER = `The information contained in this presentation may be confidential and is provided by the Company to you solely for your reference to allow you to make an evaluation of the Company. Any reproduction, dissemination or onward transmission of this presentation or the information contained herein is prohibited. By accepting delivery of this presentation, you acknowledge and agree to comply with the foregoing restrictions.\n
This presentation is only being provided to persons that are authorized and/or qualified to view the material, and may require that you be either a (i) "Qualified Institutional Buyers", as defined in Rule 144A under the U.S. Securities Act of 1933, as amended (the "Securities Act"), or (ii) non-US persons outside the United States, in compliance with Regulation S under the Securities Act. By viewing this presentation, you warrant and acknowledge that you are either authorized to access this material or that you fall within one of the applicable categories (i) and (ii) above.\n
In addition, this presentation may include forward-looking statements that reflect the Company’s current views with respect to future events and financial performance. These views may be based on a number of assumptions and are subject to various risks. Such forward-looking statements are not guarantees of future performances and no assurance can be given that any future events will occur, that projections will be achieved or that the Company’s assumptions will prove to be correct. Actual results may differ materially from those projected, and the Company does not undertake to revise any such forward-looking statements to reflect future events or circumstances.\n
By accessing the roadshow, you agree to only process the personal data contained in the documents to the extent necessary and in accordance with our Privacy Policy and Terms of Use.\n
By clicking “I Agree” below, you agree with the terms of this disclaimer.`;

export const ROADSHOW_FORM_DISCLAIMER_RETAIL = `U.S. SECURITIES AND EXCHANGE COMMISSION LEGEND\n
The issuer has filed a registration statement (including a prospectus) with the U.S. Securities and Exchange Commission (SEC) for the offering to which this communication relates. The prospectus in that registration statement is available on this site and may be printed. Before you invest, you should read the prospectus in that registration statement and other documents the issuer has filed with the SEC for more complete information about the issuer and this offering. You may get these documents for free by visiting EDGAR on the SEC website at http://www.sec.gov. Alternatively, you can obtain a copy of the prospectus by calling:\n
__underwriters__\n
Additional Information:  This bona fide electronic roadshow, as defined by the SEC, is a written communication transmitted by graphic means that is an offer pursuant to a free writing prospectus.\n
The SEC urges you to read the prospectus before making an investment decision. The prospectus is available for viewing within this roadshow.`;

export const ROADSHOW_FORM_DISCLAIMER_RETAIL_PUBLIC = `U.S. SECURITIES AND EXCHANGE COMMISSION LEGEND\n
The issuer has filed a registration statement (including a prospectus) with the U.S. Securities and Exchange Commission (SEC) for the offering to which this communication relates. The prospectus in that registration statement is available on this site and may be printed. Before you invest, you should read the prospectus in that registration statement and other documents the issuer has filed with the SEC for more complete information about the issuer and this offering. You may get these documents for free by visiting EDGAR on the SEC website at http://www.sec.gov. Alternatively, you can obtain a copy of the prospectus by calling:\n
__underwriters__\n
Additional Information: This bona fide electronic roadshow, as defined by the SEC, is a written communication transmitted by graphic means that is an offer pursuant to a free writing prospectus. The SEC urges you to read the prospectus before making an investment decision. The prospectus is available for viewing within this roadshow.\n
By accessing the roadshow, you agree to only process the personal data contained in the documents to the extent necessary and in accordance with our Privacy Policy and Terms of Use.\n
By clicking “I Agree” below, you agree with the terms of this disclaimer.`;
