import React from 'react';

import { useEnhancedTaggingRoadshowsContext } from '@/condor/application/EnhancedTaggingContexts/EnhancedTaggingRoadshowsContext';
import Checkbox from '@/ui/shared/components/Tables/Columns/Checkbox';
import { ICellProps } from '@dealroadshow/uikit';

const CheckboxCallback = ({ row: { id }, rowIndex }: ICellProps) => {
  const { editableRowId: isSingleEdit } = useEnhancedTaggingRoadshowsContext();

  return <Checkbox value={ id } dataTest={ `checkbox-${ rowIndex }` } disabled={ Boolean(isSingleEdit) } />;
};

export default CheckboxCallback;
