import React from 'react';
import cn from 'classnames';
import { format } from 'date-fns';
import utcToZonedDate from '@/Framework/DateTime/utcToZonedDate';
import getDateFromUnixTime from '@/Framework/DateTime/getDateFromUnixTime';
import { NavLink } from 'react-router-dom';

import { Icon, IconType, Spinner, Tooltip, DataTable, dataTableStyles, StatusIndicator } from '@dealroadshow/uikit';
import condor from '@/condor/infrastructure/condorUrl';
import decodeSymbols from '@/Framework/dataHelpers/string/decodeSymbols';
import { ROTATING_ENTRY_CODE_TYPE, SINGLE_ENTRY_CODE_TYPE } from '@/dealroadshow/domain/EntryCode';
import * as constants from '@/condor/ui/common/constants';
import RoadshowType from '@/dealroadshow/domain/RoadshowType';
import { getFilterStateToRequest } from './helpers';
import { useRoadshowTable } from '@/condor/ui/components/DealRoadshows/Roadshows/useRoadshowTable';

import alignStyles from '@/ui/shared/styles/helpers/align.scss';
import hText from '@/ui/shared/styles/helpers/text.scss';
import styles from '@/condor/ui/components/DealRoadshows/Roadshows/roadshows.scss';

const RoadshowTable = () => {
  const {
    roadshows,
    roadshowTabs,
    tableFilter,
    isFetching,
    setRoadshowsTableSort,
    toggleRoadshowActions,
  } = useRoadshowTable();

  const cellDealNameCallback = ({ row, cellData }) => (
    <div className={ styles.dealNameCell }>
      { row.internalNotes && (
        <NavLink
          className={ styles.notesIconWrp }
          to={ condor.getUrlPathname(`/roadshows/roadshow/${ row.id }/${ roadshowTabs[0].link }`) }
        >
          <Tooltip
            content={ row.internalNotes }
            placement="bottom-start"
          >
            <Icon type={ IconType.description } />
          </Tooltip>
        </NavLink>
      ) }
      <div className={ styles.dealNameCellWrapper }>
        <NavLink
          to={ condor.getUrlPathname(`/roadshows/roadshow/${ row.id }/${ roadshowTabs[0].link }`) }
        >
          { decodeSymbols(cellData) }
        </NavLink>
      </div>
    </div>
  );

  const cellEntryCodeCallback = ({ row, cellData }) => (
    <>
      { row.appliedEntryCodeType === SINGLE_ENTRY_CODE_TYPE && (
        <span>{ cellData ? cellData.code : '' }</span>
      ) }

      { row.appliedEntryCodeType === ROTATING_ENTRY_CODE_TYPE && (
        <div className={ styles.multiSched }>
          <span>[MULTI/SCHED]</span>
          <Tooltip
            placement="bottom"
            content={ `Total entry codes: ${ row.entryCodesCount }` }
          >
            <Icon type={ IconType.question } className={ styles.multiIcon } />
          </Tooltip>
        </div>
      ) }
    </>
  );

  const cellIdCallback = ({ cellData }) => (
    <div className="roadshow-id-clip">
      { cellData }
    </div>
  );

  const cellPublishedCallback = ({ row }) => {
    let className = 'published';
    if (!row.published) {
      className = 'unpublished';
    }
    const today = utcToZonedDate(row.timeZone.timeZone);
    const finish = utcToZonedDate(row.timeZone.timeZone, getDateFromUnixTime(row.finishedAt));
    if (finish < today) {
      className = 'expired';
    }
    return (
      <div className={ styles.statusCell }>
        <StatusIndicator status={ className } />
      </div>
    );
  };

  const cellDateCallback = ({ row }) => {
    const { timeZone, startedAt, finishedAt } = row;
    const startDate = utcToZonedDate(timeZone.timeZone, new Date(startedAt * 1000));
    const endDate = utcToZonedDate(timeZone.timeZone, new Date(finishedAt * 1000));
    const formattedStartDate = format(startDate, constants.DATE_PICKER_CONDOR_DATE_FORMAT);
    const formattedEndDate = format(endDate, constants.DATE_PICKER_CONDOR_DATE_FORMAT);
    return (
      <div>
        { `${ formattedStartDate } - ${ formattedEndDate }` }
      </div>
    );
  };

  const toggleRoadshow = (toggleParams) => toggleRoadshowActions(toggleParams, getFilterStateToRequest(tableFilter));

  const handleTableSortChange = (sortBy, sortOrder) => setRoadshowsTableSort({ sortBy, sortOrder });

  const cellActionCallback = ({ row }) => {
    const isAudioVideoDisabled = !(
      RoadshowType.isSlidesAndAudio(row.type) ||
      RoadshowType.isSlidesAndVideo(row.type) ||
      RoadshowType.isVideoOnly(row.type)
    );
    const audioVideoActionCls = cn(dataTableStyles.actionItem, dataTableStyles.isActionItemVisible, {
      [dataTableStyles.isActionDisabled]: isAudioVideoDisabled,
    });
    const actionCls = cn(dataTableStyles.actionItem, dataTableStyles.isActionItemVisible);
    const lockIcon = row.lock ? IconType.unlock : IconType.lock;

    const displayIcon = row.hide ? IconType.notDisplay : IconType.display;
    const trashIcon = row.deletedAt ? IconType.refresh : IconType.trash;

    return (
      <>
        <div
          className={ actionCls }
          onClick={ () => toggleRoadshow({
            id: row.id,
            type: 'lock',
            value: !row.lock,
          }) }
          data-test={ row.lock ? 'removeSupportAsDealManagerButton' : 'addSupportAsDealManagerButton' }
        >
          <Tooltip
            content={ row.lock ? 'Remove Support as Deal Manager' : 'Add Support as Deal Manager' }
          >
            <Icon
              type={ lockIcon }
            />
          </Tooltip>
        </div>
        <div className={ audioVideoActionCls }>
          <NavLink
            to={ condor.getUrlPathname(`/roadshow/${ row.id }/audio-video`) }
            data-test="goToAudioVideoLink"
          >
            <Tooltip
              content="Go to Audio/Video"
              disabled={ isAudioVideoDisabled }
            >
              <Icon type={ IconType.play } />
            </Tooltip>
          </NavLink>
        </div>
        <div
          className={ actionCls }
          onClick={ () => toggleRoadshow({
            id: row.id,
            type: 'hide',
            value: !row.hide,
          }) }
          data-test={ row.hide ? 'moveToActiveInactiveFilterButton' : 'moveToHiddenFilterButton' }
        >
          <Tooltip
            placement="top"
            content={ row.hide ? 'Move to Active / Inactive filter' : 'Move to Hidden filter' }
          >
            <Icon type={ displayIcon } />
          </Tooltip>
        </div>
        <div
          className={ actionCls }
          onClick={ () => toggleRoadshow({
            id: row.id,
            type: 'delete',
            value: !row.deletedAt,
          }) }
          data-test={ row.deletedAt ? 'restoreRoadshowButton' : 'deleteRoadshowButton' }
        >
          <Tooltip
            placement="top"
            content={ row.deletedAt ? 'Restore Roadshow' : 'Delete Roadshow' }
          >
            <Icon type={ trashIcon } />
          </Tooltip>
        </div>
      </>
    );
  };

  const tableColumns = [
    {
      name: 'published',
      title: 'status',
      className: alignStyles.alignCenter,
      cellCallback: cellPublishedCallback,
      sortable: true,
      width: 80,
    },
    {
      name: 'name',
      title: 'Deal Name',
      className: styles.tableName,
      cellCallback: cellDealNameCallback,
      sortable: true,
      maxWidth: 450,
    },
    {
      name: 'id',
      title: 'Roadshow ID',
      width: 155,
      className: cn('wrapper-id', hText.nowrap, styles.roadshowId),
      cellCallback: cellIdCallback,
      sortable: true,
    },
    {
      name: 'singleEntryCode',
      title: 'Entry Code',
      width: 170,
      cellCallback: cellEntryCodeCallback,
    },
    {
      name: 'type',
      title: 'Type',
      width: 150,
      className: cn(alignStyles.alignRight, styles.typeCell),
      sortable: true,
    },
    {
      name: 'date',
      title: 'Active Dates',
      minWidth: 200,
      width: 200,
      className: alignStyles.alignLeft,
      cellCallback: cellDateCallback,
      sortable: true,
    },
    {
      name: 'action',
      minWidth: 160,
      width: 160,
      title: 'Actions',
      className: cn('actions', alignStyles.alignRight),
      cellCallback: cellActionCallback,
    },
  ];

  return (
    <DataTable
      className={ dataTableStyles.isHoverable }
      dataTest="dealRoadshowDataTable"
      columns={ tableColumns }
      data={ roadshows }
      loadingComponent={ Spinner }
      isFetching={ isFetching }
      sortBy={ tableFilter.sort.sortBy }
      sortOrder={ tableFilter.sort.sortOrder }
      onSortChange={ handleTableSortChange }
    />
  );
};

export default RoadshowTable;
