import { connect } from 'react-redux';
import Bwic from '@/finsight/ui/components/bonds/common/BondTables/Bwic/Bwic';
import { sort, paginate, itemsPerPage, fetchBwic } from '@/condor/application/actions/finsight/import/bond/bwic/actions';
import bwicSelectors from '@/condor/application/actions/finsight/import/bond/bwic/selectors';
import metadataSelectors from '@/finsight/application/actions/bonds/metadata/selectors';

const mapStateToProps = (state) => ({
  isFetching: bwicSelectors.isFetching(state),
  data: bwicSelectors.getCollectionData(state),
  totalCount: bwicSelectors.getTotalCount(state),
  sortBy: bwicSelectors.getSortBy(state),
  sortOrder: bwicSelectors.getSortOrder(state),
  page: bwicSelectors.getPage(state),
  perPage: bwicSelectors.getPerPage(state),

  assetClass: metadataSelectors.getActiveAssetClass(state),
  pathname: state.router.location.pathname,
});

const mapDispatchToProps = (dispatch) => ({
  sort: (sortBy, sortOrder) => {
    dispatch(sort(sortBy, sortOrder));
    dispatch(fetchBwic());
  },
  paginate: (page) => {
    dispatch(paginate(page));
    dispatch(fetchBwic());
  },
  itemsPerPage: (perPage) => {
    dispatch(itemsPerPage(perPage));
    dispatch(fetchBwic());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Bwic);
