import React from 'react';

import {
  StatusCallback,
  TransactionTypeCallback,
  DealNameCallback,
  CompanyCallback,
  SectorCallback,
  CountryCallback,
  UnderwritersCallback,
  DateCallback,
  BulkEditActionButtons,
  IndustryCallback,
  CurrencyCallback,
  DealSizeCallback,
} from './cellCallbacks';
import TaggingCell from './cellCallbacks/TaggingCell';
import { IColumn } from '@dealroadshow/uikit';

import alignStyles from '@/ui/shared/styles/helpers/align.scss';
import styles from './roadshows.scss';

const columns: IColumn[] = [
  {
    name: 'id',
    title: 'Unique id',
    maxWidth: 96,
    stickyLeft: true,
    cellCallback: (cellProps) => <div className={ styles.oneLine }>{ cellProps.cellData }</div>,
  },
  {
    name: 'published',
    title: 'Status',
    stickyLeft: true,
    cellCallback: StatusCallback,
  },
  {
    name: 'tagged',
    title: 'Tagged',
    minWidth: 84,
    maxWidth: 84,
    stickyLeft: true,
    cellCallback: TaggingCell,
  },
  {
    name: 'transactionTypeId',
    title: 'Trns Type',
    className: alignStyles.alignCenter,
    stickyLeft: true,
    minWidth: 95,
    maxWidth: 95,
    cellCallback: TransactionTypeCallback,
  },
  {
    name: 'name',
    title: 'Deal Name',
    minWidth: 130,
    maxWidth: 330,
    stickyLeft: true,
    cellCallback: DealNameCallback,
  },
  {
    name: 'companyId',
    title: 'Company / Parent',
    minWidth: 190,
    maxWidth: 240,
    cellCallback: CompanyCallback,
  },
  {
    name: 'subsectorId',
    title: 'Sector / Subsector',
    minWidth: 300,
    maxWidth: 400,
    cellCallback: SectorCallback,
  },
  {
    name: 'subindustryId',
    title: 'Industry / Subindustry',
    minWidth: 300,
    maxWidth: 400,
    cellCallback: IndustryCallback,
  },
  {
    name: 'countryId',
    title: 'Country',
    minWidth: 185,
    cellCallback: CountryCallback,
  },
  {
    name: 'currencyId',
    title: 'CCY',
    minWidth: 240,
    cellCallback: CurrencyCallback,
  },
  {
    name: 'dealSize',
    title: 'Size',
    className: alignStyles.alignRight,
    minWidth: 135,
    cellCallback: DealSizeCallback,
  },
  {
    name: 'enhancedTaggingUnderwriters',
    title: 'UW',
    className: alignStyles.alignRight,
    minWidth: 60,
    maxWidth: 100,
    cellCallback: UnderwritersCallback,
  },
  {
    name: 'createdAt',
    title: 'Date created',
    className: alignStyles.alignRight,
    minWidth: 130,
    cellCallback: (props) => {
      return <DateCallback date={ props.cellData } actions={ <BulkEditActionButtons { ...props } /> } />;
    },
  },
  {
    name: 'mobileActions',
    className: styles.mobileActions,
    cellCallback: BulkEditActionButtons,
  },
];

export default columns;
