export interface IDimensions {
  width: number,
  height: number,
}

const getImageDimensions = (url: string, timeout?: number): Promise<IDimensions> => {
  return new Promise((resolve, reject) => {
    let timer: NodeJS.Timeout | null = null;

    const img = new Image();

    img.addEventListener('load', () => {
      if (timer) {
        clearTimeout(timer);
      }

      resolve({ width: img.naturalWidth, height: img.naturalHeight });
    });

    img.addEventListener('error', (event) => {
      if (timer) {
        clearTimeout(timer);
      }

      reject(Error(`${ event.type }: ${ event.message }`));
    });

    img.src = url;

    if (timeout) {
      timer = setTimeout(() => reject(Error('Timeout')), timeout);
    }
  });
};

export default getImageDimensions;
