export const setEmailsFromChecked = (subscribersList, checkedEmails) => {
  if (!subscribersList) {
    return [];
  }

  return subscribersList.map((email) => {
    const groups = Object.keys(checkedEmails);
    groups.forEach((group) => {
      checkedEmails[group].forEach((checkedEmail: string) => {
        if (checkedEmail.toLowerCase() === email.value.toLowerCase()) {
          email.userPlan = group;
        }
      });
    });
    return email;
  });
};
