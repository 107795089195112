import { useState } from 'react';
import getActions from './getActions';
import { IAdminCall } from '@/evercall/domain/vo/call/admin/AdminCall';

interface IProps {
  setItem: (payload: IAdminCall) => void,
}

export default ({ setItem }: IProps) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUpdateSucceeded, setIsUpdateSucceeded] = useState(false);

  return {
    state: {
      isUpdating,
      isUpdateSucceeded,
    },
    setIsUpdating,
    ...getActions(
      {
        setIsUpdating,
        setIsUpdateSucceeded,
        setItem,
      },
    ),
  };
};
