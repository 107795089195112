import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import {
  initUploadRelatedRoadshowLogo,
  uploadClear,
  uploadRelatedRoadshowLogoCancel,
} from '@/ui/shared/modules/upload/actions';
import { toggleDeleteRelatedRoadshow, getRoadshowForCustomModal, resetRoadshowForCustomModal } from '../actions';

import RelatedRoadshows from './RelatedRoadshows';

const selector = formValueSelector('customModalForm');
function mapStateToProps(state) {
  const items = selector(state, 'relatedRoadshows') || [];
  return {
    logo: state.upload.relatedRoadshowLogo,
    relatedRoadshows: state.dealRoadshows.roadshow.customModal.initialValues.relatedRoadshows || [],
    newRoadshow: state.dealRoadshows.roadshow.customModal.newRoadshow,
    items,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    initUploadRelatedRoadshowLogo,
    uploadRelatedRoadshowLogoCancel,
    toggleDeleteRelatedRoadshow,
    getRoadshowForCustomModal,
    resetRoadshowForCustomModal,
    uploadClear,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RelatedRoadshows);
