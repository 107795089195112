import { connect } from 'react-redux';

import Filing from '@/finsight/domain/Filing';
import {
  getExistingDeal,
  resetExistingDealForm,
  mapFilingToDeal,
  openFilingsPage,
} from '@/condor/application/actions/finsight/filings/item/actions';
import selectors from '@/condor/application/actions/finsight/filings/item/selectors';
import { getSecIssuerName } from '@/condor/ui/components/Finsight/Filings/FilingProfile/ExistingDealForm/helpers';

import ExistingDealForm from './ExistingDealForm';

const mapStateToProps = (state) => ({
  initialValues: {
    secIssuerName: getSecIssuerName(selectors.getFilingData(state)),
    secAssetClass: selectors.getSecAssetClass(state),
  },
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const filingType = Filing.getFilingType(ownProps.location.pathname);

  return {
    reset: () => dispatch(resetExistingDealForm()),
    onSelectChange: ({ value }) => dispatch(getExistingDeal(value)),
    onSubmit: ({ existingDeal: { value } }) => dispatch(mapFilingToDeal(value)),
    onCancelClick: () => dispatch(openFilingsPage(filingType)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExistingDealForm);
