import React, { memo, useCallback, useMemo } from 'react';
import { Field } from 'redux-form';
import cn from 'classnames';
import { BodyCellPure, Input } from '@dealroadshow/uikit';

import styles from './editableDataTable.scss';
import spacesStyles from '@/ui/shared/styles/helpers/spaces.scss';

interface IProps {
  index: number,
  row: any,
  isSelected: boolean,
  isUpdating: boolean,
  tableRef: any,
  columns: {
    isEditable: boolean,
    editableFieldCallback: () => void,
    editableClassName: string,
  }[],
}

const EditableTableRow = (props: IProps) => {
  const trCls = useMemo(
    () => cn(
      props.index % 2 === 0 ? 'odd' : 'even',
      { 'is-first-child': props.index === 0 },
      { [styles.selectedRow]: props.isSelected || (props.isSelected && props.isUpdating) },
      { [styles.updatingRow]: props.isSelected && props.isUpdating },
    ),
    [props.isSelected, props.isUpdating, props.index],
  );

  const isEditing = props.isSelected && !props.isUpdating;

  /**
   * @param {Object} columnData
   */
  const getEditableCellCallback = useCallback(
    (columnData) => () => (
      columnData.editableFieldCallback
        ? columnData.editableFieldCallback({ ...columnData, tableRef: props.tableRef }, isEditing)
        : (
          <Field
            name={ columnData.name }
            placeholder={ columnData.title }
            dataTest={ columnData.name }
            component={ Input }
            inputClassName={ styles.editableCellInput }
            isClearable={ typeof columnData.isFieldClearable === 'undefined' ? true : columnData.isFieldClearable }
            isNarrow
          />
        )
    ),
    [isEditing, props.tableRef],
  );

  /**
   * @param {Object} columnData
   */
  const renderCell = useCallback(
    (columnData) => {
      const { pbs, pts, pls, prs } = spacesStyles;

      if (isEditing && columnData.isEditable) {
        columnData = {
          ...columnData,
          className: cn(styles.editingCell, pbs, pts, pls, prs, columnData.editableClassName),
          cellCallback: getEditableCellCallback(columnData),
        };
      }

      return (
        <BodyCellPure
          cellCallback={ columnData.cellCallback }
          className={ columnData.className }
          key={ columnData.name }
          name={ columnData.name }
          title={ columnData.title }
          defaultContent={ columnData.defaultContent }
          rowIndex={ props.index }
          rowData={ props.row }
          isVisible={ columnData.isVisible }
          isDraggable={ columnData.isDraggable }
          width={ columnData.width }
          maxWidth={ columnData.maxWidth }
          minWidth={ columnData.minWidth }
        />
      );
    },
    [isEditing, props.index, props.row, getEditableCellCallback],
  );

  return (
    <tr className={ trCls }>
      { props.columns.map(renderCell) }
    </tr>
  );
};

export default memo(EditableTableRow);
