import { connect } from 'react-redux';
import MappingsModal from './MappingsModal';
import {
  closeCondorAccountMappingModal,
  remapAccountData,
} from '@/condor/application/actions/accounts/mappings/metadata/actions';
import contactsSelectors from '@/condor/application/actions/accounts/mappings/contacts/selectors';
import dealsSelectors from '@/condor/application/actions/accounts/mappings/deals/selectors';
import allocationsSelectors from '@/condor/application/actions/accounts/mappings/allocations/selectors';
import evercallsSelectors from '@/condor/application/actions/accounts/mappings/evercalls/selectors';
import { getNotesTotalCount } from '@/condor/application/actions/accounts/mappings/notes/selectors';
import leisSelectors from '@/condor/application/actions/accounts/mappings/leis/selectors';
import webDomainsSelectors from '@/condor/application/actions/accounts/mappings/webDomains/selectors';
import emailDomainsSelectors from '@/condor/application/actions/accounts/mappings/emailDomains/selectors';
import accountSelectors from '@/condor/application/actions/accounts/item/selectors';
import {
  getMappingsModalTitle,
  getMappingsTypes,
  isFetching,
} from '@/condor/application/actions/accounts/mappings/metadata/selectors';

const mapStateToProps = (state) => ({
  migrationTitle: getMappingsModalTitle(state),
  contacts: contactsSelectors.getTotalCount(state),
  deals: dealsSelectors.getTotalCount(state),
  allocations: allocationsSelectors.getTotalCount(state),
  evercalls: evercallsSelectors.getTotalCount(state),
  notes: getNotesTotalCount(state),
  leis: leisSelectors.getTotalCount(state),
  emailDomains: emailDomainsSelectors.getTotalCount(state),
  webDomains: webDomainsSelectors.getTotalCount(state),
  mappingsTypes: getMappingsTypes(state),
  accountName: accountSelectors.getItemData(state).name,
  isFetching: isFetching(state),
});

const mapDispatchToProps = {
  onCancel: closeCondorAccountMappingModal,
  onRemap: remapAccountData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MappingsModal);
