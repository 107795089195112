import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { ISelectOption } from '@dealroadshow/uikit';
import { useDIContext } from '@/Framework/DI/DIContext';
import RegionRepository from '@/openMarket/infrastructure/repository/OpenMarket/RegionRepository';
import TransactionTypeRepository from '@/openMarket/infrastructure/repository/OpenMarket/TransactionTypeRepository';
import { NotificationManager } from '@/ui/shared/components/Notification';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';

const useDisplayTabs = () => {
  const tableRef = useRef<HTMLDivElement>(null);
  const { container } = useDIContext();
  const regionRepository = container.get<RegionRepository>(RegionRepository);
  const transactionTypeRepository = container.get<TransactionTypeRepository>(TransactionTypeRepository);

  const [isTransactionTypesLoading, setIsTransactionTypesLoading] = useState(false);
  const [transactionTypesOptions, setTransactionTypesOptions] = useState<ISelectOption[]>([]);

  const [isRegionsLoading, setIsRegionsLoading] = useState(false);
  const [regionsOptions, setRegionsOptions] = useState<ISelectOption[]>([]);

  const getTransactionTypes = async () => {
    setIsTransactionTypesLoading(true);

    try {
      const response = await transactionTypeRepository.getTransactionTypesList();
      setTransactionTypesOptions(response.map((item) => ({
        label: item.name,
        value: item.id,
      })));
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    } finally {
      setIsTransactionTypesLoading(false);
    }
  };

  const getRegions = async () => {
    setIsRegionsLoading(true);

    try {
      const response = await regionRepository.getRegionsList();

      setRegionsOptions(response.map((item) => ({
        label: item.abbreviation,
        value: item.id,
      })));
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    } finally {
      setIsRegionsLoading(false);
    }
  };

  useEffect(() => {
    getTransactionTypes();
    getRegions();
  }, []);

  return {
    transactionTypesOptions,
    regionsOptions,
    isTransactionTypesLoading,
    isRegionsLoading,
    tableRef,
  };
};

export const DisplayTabsContext = createContext<ReturnType<typeof useDisplayTabs>>(null);

export const useDisplayTabsContext = () => {
  const context = useContext(DisplayTabsContext);
  if (!context) {
    throw new Error('useDisplayTabsContext must be used within a DisplayTabsContext');
  }
  return context;
};

const DisplayTabsContextProvider = ({ children }: { children: React.ReactNode }) => (
  <DisplayTabsContext.Provider value={ useDisplayTabs() }>{ children }</DisplayTabsContext.Provider>
);

export default DisplayTabsContextProvider;
