import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  deleteAccount,
  resetCondorAccountsItem,
} from '@/condor/application/actions/accounts/item/actions';
import selectors from '@/condor/application/actions/accounts/item/selectors';
import DeleteModal from './DeleteModal';

const mapStateToProps = (state) => ({
  isVisible: selectors.isDeleteModalVisible(state),
  item: selectors.getItemData(state),
  isSpinnerVisible: selectors.isRemoving(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    onDelete: deleteAccount,
    onCancel: resetCondorAccountsItem,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteModal);
