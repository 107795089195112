import { FormApi, SubmissionErrors } from 'final-form';
import { Button, ButtonVariantType, Icon, Input, IconType } from '@dealroadshow/uikit';
import FinalForm, { Field } from '@/ui/shared/components/Form/FinalForm';
import { TFinalFormRenderProp } from '@/ui/shared/components/Form/FinalForm/interfaces';
import { isEnterKey } from '@/Framework/browser/checkPressedKey';
import { extractDomainsAndEmailsFromString } from '@/Framework/dataHelpers/string/extractDomainsAndEmailsFromString';

import { useWhitelistContext } from '../WhitelistContext';
import validation from './validation';

import styles from './whitelistAddNew.scss';

interface IFormValues {
  input: string,
}

const WhitelistAddNewFields: TFinalFormRenderProp<IFormValues> = ({ handleSubmit }) => {
  const onKeyPress = (event) => {
    if (isEnterKey(event)) {
      handleSubmit();
    }
  };

  return (
    <>
      <Field
        name="input"
        render={ (props) => (
          <Input
            { ...props }
            name="input"
            dataTest="input"
            onKeyPress={ onKeyPress }
            placeholder="Add domain(s) or email(s)"
            isNarrow
            appendComponent={ (
              <Button
                onClick={ handleSubmit }
                className={ styles.button }
                variant={ ButtonVariantType.action }
                dataTest="addNew"
              >
                <Icon type={ IconType.plus } />
              </Button>
            ) }
          />
        ) }
      />
    </>
  );
};

const initialValues: IFormValues = {
  input: '',
};

const WhitelistAddNew = () => {
  const { addNewDomainsAndEmails, whitelist } = useWhitelistContext();

  const handleSubmitForm = async (
    values: IFormValues,
    form: FormApi<IFormValues>,
    setErrors: (errors?: SubmissionErrors) => void,
  ) => {
    const input = values.input ?? '';

    const validationErrors = validation(input, whitelist);

    if (validationErrors) {
      setErrors(validationErrors);
    } else {
      const extractedDomainsAndEmails = extractDomainsAndEmailsFromString(input);

      if (extractedDomainsAndEmails.length > 0) {
        addNewDomainsAndEmails(
          extractedDomainsAndEmails.map(({ domain, emailUserName, email, emailOrDomain }) => ({
            domain,
            emailOrDomain,
            email,
            emailUserName,
          })),
        );
        form.reset();
      }
    }
  };

  return (
    <FinalForm<IFormValues>
      name="whitelistAddNewForm"
      dataTest="whitelistAddNewForm"
      initialValues={ initialValues }
      onSubmit={ handleSubmitForm }
      render={ WhitelistAddNewFields }
    />
  );
};

export default WhitelistAddNew;
