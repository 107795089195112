import React, { useCallback, useEffect } from 'react';
import { Field } from 'redux-form';
import cn from 'classnames';

import { CONDOR_FINSIGHT_FILING_LINK_TO_EXISTING_DEAL_FORM_NAME } from '@/condor/ui/common/constants';

import FormWrapper from '@/condor/ui/common/Layout/FormWrapper';
import Input from '@/ui/shared/components/Form/Input';
import ExistingDealsSelect from './ExistingDealsSelectContainer';

import cardStyles from '@/ui/shared/styles/card.scss';
import layoutStyles from '@/condor/ui/common/Layout/layout.scss';

const commonInputProps = {
  placeholder: '',
  component: Input,
  className: layoutStyles.inputField,
};

interface IProps {
  initialValues: any,
  onSelectChange: (value: any) => void,
  onSubmit: () => void,
  onCancelClick: () => void,
  reset: () => void,
}

const ExistingDealForm = (
  {
    initialValues,
    onSelectChange,
    onSubmit,
    onCancelClick,
    reset,
  }: IProps,
) => {
  useEffect(() => reset, []);

  const onChangeHandler = useCallback((value) => {
    if (!value) return;
    onSelectChange(value);
  }, []);

  return (
    <div className={ cn(
      cardStyles.cardContent,
      cardStyles.cardInner,
    ) }
    >
      <FormWrapper
        form={ CONDOR_FINSIGHT_FILING_LINK_TO_EXISTING_DEAL_FORM_NAME }
        dataTest={ CONDOR_FINSIGHT_FILING_LINK_TO_EXISTING_DEAL_FORM_NAME }
        headerText="Deal Details"
        enableReinitialize
        initialValues={ initialValues }
        onSubmit={ onSubmit }
        onCancelClick={ onCancelClick }
        submitButtonText="Save"
      >
        <Field
          { ...commonInputProps }
          name="secIssuerName"
          label="Sec Issuer Name"
          disabled
        />
        <Field
          { ...commonInputProps }
          name="secAssetClass"
          label="Sec Asset Class"
          disabled
        />
        <Field
          isNarrow
          name="existingDeal"
          label="Select Existing Deal"
          placeholder="Search for existing Finsight Deals"
          component={ ExistingDealsSelect }
          className={ layoutStyles.inputField }
          onChange={ onChangeHandler }
        />
      </FormWrapper>
    </div>
  );
};

export default ExistingDealForm;
