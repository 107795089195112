import cn from 'classnames';
import { connect } from 'react-redux';
import { CONDOR_FINSIGHT_COMPANY_TABLE_INLINE_EDIT_FORM_NAME } from '@/condor/ui/common/constants';
import {
  sort,
  getCondorFinsightCompaniesByFilter,
} from '@/condor/application/actions/finsight/company/collection/actions';
import {
  submitCondorFinsightCompanyForm,
  resetCondorFinsightCompanyItem,
} from '@/condor/application/actions/finsight/company/item/actions';
import columns from './columns';
import validate from '@/condor/ui/components/AccountsAndContacts/Accounts/AccountProfile/profileFormValidators';
import EditableDataTable from '@/condor/ui/common/Layout/EditableDataTable';
import { getCompanyInlineFormInitialValues } from '../../helpers';
import selectors from '@/condor/application/actions/finsight/company/collection/selectors';
import itemSelectors from '@/condor/application/actions/finsight/company/item/selectors';

import { dataTableStyles } from '@dealroadshow/uikit';
import styles from './companiesTable.scss';

const mapStateToProps = (state) => (
  {
    className: cn(dataTableStyles.isHoverable, styles.companiesTable),
    columns,
    collection: selectors.getCollectionData(state),
    isCollectionFetching: selectors.isFetching(state),
    sortBy: selectors.getSortBy(state),
    sortOrder: selectors.getSortOrder(state),
    selectedItemId: itemSelectors.isInlineEditing(state) ? itemSelectors.getItemData(state)?.id : undefined,
    isUpdating: itemSelectors.isUpdating(state),
    formProps: {
      form: CONDOR_FINSIGHT_COMPANY_TABLE_INLINE_EDIT_FORM_NAME,
      validate,
      initialValues: getCompanyInlineFormInitialValues(
        itemSelectors.getItemData(state),
      ),
    },
  }
);

const mapDispatchToProps = (dispatch) => (
  {
    onSubmit: async (formData) => {
      await dispatch(submitCondorFinsightCompanyForm(formData));
      dispatch(resetCondorFinsightCompanyItem());
      dispatch(getCondorFinsightCompaniesByFilter());
    },
    onSortChange: (sortBy, sortOrder) => {
      dispatch(sort({ sortBy, sortOrder }));
      dispatch(getCondorFinsightCompaniesByFilter());
      dispatch(resetCondorFinsightCompanyItem());
    },
  }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditableDataTable);
