import React from 'react';
import UserPlanTag from '@/ui/shared/components/User/UserPlanTag';
import Link from '@/ui/shared/components/ReactRouter/Link';
import { CONDOR_CONTACT_PROFILE_BASE_URL } from '@/condor/ui/common/constants';
import { ICellProps as IDataTableCellProps } from '@dealroadshow/uikit';

import { UserPlanType } from '@/users/domain/UserPlan';

import styles from './common.scss';
import { IManagePlansSubscriberItem, IManagePlansRequestItem } from '@/condor/domain/vo/FinsightManager/ManagePlans';

export const cellContactCallback = ({
  row,
}: IDataTableCellProps<IManagePlansSubscriberItem | IManagePlansRequestItem>) => {
  const isNameExist = row.firstName || row.lastName;
  return (
    <div>
      <Link to={ `${ CONDOR_CONTACT_PROFILE_BASE_URL }/${ row.contactId }` }>
        { isNameExist ? `${ row.firstName } ${ row.lastName }` : row.email }
      </Link>
      { isNameExist && <p className={ styles.email }>{ row.email }</p> }
    </div>
  );
};

export const cellUserPlanCallback = ({ cellData }: { cellData: UserPlanType }) => {
  return (
    <div className={ styles.userPlanWrapper }>
      <UserPlanTag userPlan={ cellData } className={ styles.userPlan } />
    </div>
  );
};
