import { format } from 'date-fns';
import getDateFromUnixTime from '@/Framework/DateTime/getDateFromUnixTime';
import { getErrorMessage, getMessage } from '@/Framework/Message/Mapper/getMessage';
import RoadshowRepository from '@/dealroadshow/infrastructure/repository/RoadshowRepository';
import { NotificationManager } from '@/ui/shared/components/Notification';
import * as constants from '@/condor/ui/common/constants';
import * as actionTypes from './actionTypes';
import { messageCodes } from '@/Framework/Message/messages';

/**
 * ROADSHOWS_TABLE_FILTER
 */

/**
 * @return {Object}
 */
function requestCustomModal() {
  return {
    type: actionTypes.REQUEST_CUSTOM_MODAL,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function requestCustomModalSuccess(payload) {
  return {
    type: actionTypes.REQUEST_CUSTOM_MODAL_SUCCESS,
    payload,
  };
}

/**
 * @param {Error} errorResponse
 * @return {Object}
 */
function requestCustomModalError(errorResponse) {
  NotificationManager.error(getErrorMessage(errorResponse));
  return {
    type: actionTypes.REQUEST_CUSTOM_MODAL_ERROR,
    error: errorResponse,
  };
}

/**
 * @param {String} roadshowId
 */
export function getCustomModal(roadshowId) {
  return async (dispatch, getState) => {
    const roadshowRepository = getState().container.get(RoadshowRepository);
    dispatch(requestCustomModal());
    try {
      let response = await roadshowRepository.getAdminCustomModal(roadshowId);
      dispatch(requestCustomModalSuccess({
        initialValues: response,
        data: response,
      }));
    } catch (err) {
      dispatch(requestCustomModalError(err));
    }
  };
}

/**
 * @param {Object} data
 */
export function saveCustomModal(data) {
  return async (dispatch, getState) => {
    const roadshowRepository = getState().container.get(RoadshowRepository);
    dispatch(requestCustomModal());
    try {
      (await roadshowRepository.saveAdminCustomModal(data));
      dispatch(getCustomModal(data.id));
      NotificationManager.success(getMessage(messageCodes.CONDOR_SAVED));
    } catch (err) {
      dispatch(requestCustomModalError(err));
      NotificationManager.error(getErrorMessage(err));
    }
  };
}

/**
 * @param {Object} data
 */
export function toggleDeleteRelatedRoadshow(data) {
  return async (dispatch, getState) => {
    const roadshowRepository = getState().container.get(RoadshowRepository);
    dispatch(requestCustomModal());
    try {
      (await roadshowRepository.toggleDeleteRelatedRoadshow(data));
      dispatch(getCustomModal(data.parentId));
    } catch (err) {
      dispatch(requestCustomModalError(err));
    }
  };
}

export function resetCustomModal() {
  return { type: actionTypes.CUSTOM_MODAL_RESET };
}

export function resetRoadshowForCustomModal() {
  return { type: actionTypes.REQUEST_ROADSHOW_CUSTOM_MODAL_RESET };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function requestRoadshowForCustomModalSuccess(payload) {
  return {
    type: actionTypes.REQUEST_ROADSHOW_CUSTOM_MODAL_SUCCESS,
    payload,
  };
}

/**
 * @param {Error|null} errorResponse
 * @return {Object}
 */
function requestRoadshowForCustomModalError(errorResponse = null) {
  if (errorResponse) {
    NotificationManager.error(getErrorMessage(errorResponse));
  }
  return {
    type: actionTypes.REQUEST_ROADSHOW_CUSTOM_MODAL_ERROR,
    error: errorResponse,
  };
}

/**
 * @param {String} roadshowId
 */
export function getRoadshowForCustomModal(roadshowId) {
  return async (dispatch, getState) => {
    const roadshowRepository = getState().container.get(RoadshowRepository);
    dispatch({ type: actionTypes.REQUEST_ROADSHOW_CUSTOM_MODAL });

    try {
      let response = await roadshowRepository.getAdminDealSettings(roadshowId);
      if (response) {
        dispatch(requestRoadshowForCustomModalSuccess({
          ...response,
          startedAt: format(getDateFromUnixTime(response.startedAt), constants.DATE_PICKER_CONDOR_DATE_FORMAT)
            .toString(),
          finishedAt: getDateFromUnixTime(response.finishedAt),
          expectedPricingDate: response.expectedPricingDate ? getDateFromUnixTime(response.expectedPricingDate) : null,
        }));
      } else {
        NotificationManager.error(getMessage(messageCodes.ROADSHOW_NOT_FOUND));
        dispatch(requestRoadshowForCustomModalError());
      }
    } catch (err) {
      dispatch(requestRoadshowForCustomModalError(err));
    }
  };
}
