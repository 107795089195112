import React, { useCallback, useMemo, useState, useEffect } from 'react';

import { useEnhancedTaggingCondorContext } from '@/condor/application/EnhancedTaggingContexts/EnhancedTaggingCondorContext';
import Button, { variantTypes } from '@/ui/shared/components/Button';
import FinalForm from '@/ui/shared/components/Form/FinalForm';
import { Modal } from '@dealroadshow/uikit';
import Underwriters from '@/ui/shared/modules/EnhancedTagging/Field/Underwriters';
import { findCompanyByName } from '@/users/domain/company/Company';
import validate from './validate';

import styles from './underwritersModal.scss';

export interface IFormData {
  companyId: number | string,
  name: string,
  abbreviation: string | null,
  lead: boolean,
}

interface IProps {
  isVisible: boolean,
  setIsVisible: (isVisible: boolean) => void,
  collection: object[],
  onSubmit: (formData: IFormData[]) => void,
}

const UnderwritersModal = ({
  isVisible,
  setIsVisible,
  collection,
  onSubmit,
}: IProps) => {
  const { companies } = useEnhancedTaggingCondorContext();
  const [verifiedCompaniesList, setVerifiedCompaniesList] = useState([]);

  useEffect(() => {
    if (companies.verifiedCompanies.length) {
      setVerifiedCompaniesList((prevState) => (
        [...Array.from(new Set([...prevState, ...companies.verifiedCompanies]))]
      ));
    }
  }, [companies.verifiedCompanies]);

  const initialValues = useMemo(() => ({
    enhancedTagging: {
      underwriters: [...collection, { lead: false, name: '' }],
    },
  }), [isVisible]);

  const handleCloseModal = () => setIsVisible(false);

  const handleOnSubmit = useCallback(async (
    { enhancedTagging: { underwriters } }: { enhancedTagging: { underwriters: IFormData[] } },
  ) => {
    const payload = underwriters
      .filter((underwriter) => {
        if (!Object.keys(underwriter).length) {
          return false;
        }
        return !!(underwriter.name || underwriter.lead);
      })
      .map((underwriter) => {
        const company = findCompanyByName(verifiedCompaniesList, underwriter.name);

        if (company) {
          return {
            companyId: company.id,
            name: company.name,
            abbreviation: company.abbreviation,
            lead: underwriter.lead || false,
          };
        }

        return underwriter;
      });

    onSubmit(payload);
    setIsVisible(false);
  }, [verifiedCompaniesList]);

  const renderFields = useCallback(({ errors }, { Field, FieldArray }) => (
    <>
      <Underwriters
        fieldArrayComponent={ FieldArray }
        fieldComponent={ Field }
        companies={ companies }
        clearOnBlur
        titles={ {
          name: 'Underwriter Name',
          placeholder: 'Underwriter Name',
        } }
      />
      <div className={ styles.modalFooter }>
        <Button
          type="submit"
          variant={ variantTypes.action }
          title="Save"
          dataTest="save"
          disabled={ !!errors.enhancedTagging }
        />
        <Button
          onClick={ handleCloseModal }
          variant={ variantTypes.text }
          title="Cancel"
          dataTest="cancel"
        />
      </div>
    </>
  ), [isVisible, companies]);

  return (
    <Modal
      isVisible={ isVisible }
      className={ styles.modal }
      title="Edit Underwriter"
      onCloseClicked={ handleCloseModal }
      dataTest="underwritersModal"
    >
      <FinalForm
        initialValues={ initialValues }
        name="underwriterFormsModal"
        dataTest="form"
        role="form"
        id="underwritersFormModal"
        onSubmit={ handleOnSubmit }
        render={ renderFields }
        validate={ validate }
        withArrays
      />
    </Modal>
  );
};

export default UnderwritersModal;
