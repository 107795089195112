import React, { memo, useCallback, useMemo } from 'react';
import { CheckboxInput } from '@dealroadshow/uikit';

import TogglingFilters from '@/finsight/ui/common/components/TogglingFilters/TogglingFilters';
import styles from './checkboxWithFilters.scss';

interface IProps {
  label: string,
  name: string,
  isDisabled: boolean,
  input: any,
  meta: any,
  filterSet: {
    key: string,
    items: any[],
  },
}

const CheckboxWithFilters = ({
  label,
  name,
  input,
  filterSet,
  isDisabled,
  meta,
}: IProps) => {
  const onFiltersChangeHandler = useCallback((filterName, selectedId) => {
    let selectedFiltersIds = input.value?.filters || [];
    if (selectedFiltersIds.includes(selectedId)) {
      selectedFiltersIds = selectedFiltersIds.filter((filter) => filter !== selectedId);
    } else {
      selectedFiltersIds = [...selectedFiltersIds, selectedId];
    }
    if (input.value?.checked) {
      input.onChange({ checked: !!input.value?.checked, filters: selectedFiltersIds });
    } else {
      input.onChange({ checked: true, filters: selectedFiltersIds });
    }
  }, [input.value, input.onChange]);

  const onCheckboxChangeHandler = (val) => {
    if (val.target.checked) {
      if (filterSet.items.length > 1) {
        input.onChange({ checked: val.target.checked, filters: input.value?.filters || [] });
      } else {
        input.onChange({
          checked: val.target.checked,
          filters: [filterSet.items[0].subscriptionId || filterSet.items[0].id],
        });
      }
    } else {
      input.onChange({ checked: val.target.checked, filters: [] });
    }
  };

  const memoizedFilterSet = useMemo(() => {
    const { filters = [] } = input.value;
    return {
      items: {
        key: filterSet.key,
        items: filterSet.items.map((item) => ({
          ...item,
          id: item.subscriptionId || item.id,
          abbreviation: item.subscriptionName || item.name,
          isActive: filters.includes(item.subscriptionId) || filters.includes(item.id),
        })),
      },
    };
  }, [filterSet]);
  return (
    <div className={ styles.wrapper }>
      <CheckboxInput
        label={ label }
        className={ styles.checkboxItem }
        input={ {
          name,
          checked: !!input.value?.checked,
          onChange: onCheckboxChangeHandler,
        } }
        dataTest={ `subscription form ${ label } checkbox` }
        disabled={ isDisabled }
      />

      <div>
        { filterSet.items.length > 1 && (
          <TogglingFilters
            isDisabled={ isDisabled }
            // @ts-ignore
            filterSet={ memoizedFilterSet }
            applyFilter={ onFiltersChangeHandler }
            isTooltipEnabled={ false }
          />
        ) }

        { meta.error && (
          <div className={ styles.errorMessage }>
            { meta.error }
          </div>
        ) }
      </div>
    </div>
  );
};

export default memo(CheckboxWithFilters);
