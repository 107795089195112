import React, { useMemo } from 'react';
import styles from './industryCell.scss';

interface IProps {
  industryId: string,
  subindustryId: string,
  industries: any[],
}

const IndustryCell = (
  {
    industryId,
    subindustryId,
    industries,
  }: IProps,
) => {
  const industry = useMemo(
    () => industries.find((industry) => industry.id === industryId),
    [industries, industryId],
  );

  const subindustry = useMemo(
    () => industry?.subindustryList.find((subindustry) => subindustry.id === subindustryId),
    [industries, industry, subindustryId],
  );

  if (!industry && !subindustry) {
    return '-';
  }

  return (
    <div className={ styles.industryCell }>
      { `${ industry.name }${ subindustry ? ` - ${ subindustry.name }` : '' }` }
    </div>
  );
};

export default IndustryCell;
