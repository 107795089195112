export const SLIDE_TYPES = {
  png: 'PNG',
  jpeg: 'JPEG',
};

class SlideType {
  isMain(roadshow) {
    return roadshow.type === SLIDE_TYPES.png;
  }
}

export default SlideType;
