/**
 * @param {Object} formData
 * @param {Object} upload
 * @return {Object}
 */
function filterPresentation(formData, upload) {
  let newPresentation = {
    origin: null,
    slideShow: [],
  };

  if (formData.presentation) {
    if (!upload.presentation.uploadFile) {
      let presentationFolder = formData.presentation.origin.url.match(/\/(.*)\//i)[1];
      newPresentation.origin = formData.presentation.origin;

      let oldSlideShow = {};
      formData.presentation.slideShow.forEach((slide) => {
        oldSlideShow[slide.id] = slide;
      });

      upload.presentationSlides.sort.forEach((slide) => {
        let newSlide = {
          ...oldSlideShow[slide.uuid],
          urls: {
            ...oldSlideShow[slide.uuid].urls,
          },
        };
        Object.keys(upload.presentationSlides.files[slide.uuid].types).forEach((type) => {
          if (oldSlideShow[slide.uuid].urls[type] !== upload.presentationSlides.files[slide.uuid].types[type].slide) {
            // /uuid/name
            let newFileName = upload.presentationSlides.files[slide.uuid].types[type].slide.match(/\/.*\/(.*)$/i)[1];
            newSlide.urls[type] = `/${ presentationFolder }/slideshow/${ newFileName }`;
          }
        });
        newPresentation.slideShow.push(newSlide);
      });
    } else {
      let presentationFolder = formData.presentation.origin.url.match(/\/(.*)\//i)[1];

      let newPdfName = upload.presentation.presentationOrigin.url.match(/\/.*\/(.*)$/i)[1];
      newPresentation.origin = {
        ...formData.presentation.origin,
        url: `/${ presentationFolder }/${ newPdfName }`,
      };

      upload.presentation.slideShow.forEach((slide, index) => {
        let newSlide = {
          urls: {},
        };
        if (formData.presentation.slideShow[index]) {
          newSlide = {
            ...formData.presentation.slideShow[index],
            ...newSlide,
          };
        }
        Object.keys(slide.urls).forEach((type) => {
          // /uuid/slideshow/name
          let newFileName = slide.urls[type].match(/\/.*\/(.*)$/i)[1];
          newSlide.urls[type] = `/${ presentationFolder }/slideshow/${ newFileName }`;
        });

        newPresentation.slideShow.push(newSlide);
      });
    }
  } else {
    newPresentation.origin = upload.presentation.presentationOrigin;
    newPresentation.slideShow = upload.presentation.slideShow;
  }
  return newPresentation;
}

/**
 * @param {Object} formData
 * @return {Object}
 */
function filterDealSlidesFormData(formData) {
  let saveData = {
    id: formData.id,
    slideType: formData.slideType,
    originalVersion: formData.version,
    variation: formData.variation,
    presentation: filterPresentation(formData, formData.upload),
  };
  return {

    ...saveData,
  };
}

export default filterDealSlidesFormData;
