import React from 'react';
import flatten from 'lodash/flatten';
import ReduxFormSelect from '@/ui/shared/components/Form/Select/legacy/ReduxFormSelect';

interface IProps {
  collection: any[],
  dataTest: string,
}

const Subsector = (props: IProps) => {
  const options = flatten(props.collection.map((sector) => sector.subsectorList.map((subSector) => ({
    value: subSector.id,
    label: `${ sector.name } - ${ subSector.name }`,
  }))));

  // @ts-ignore
  return <ReduxFormSelect { ...props } options={ options } dataTest={ props.dataTest } />;
};

export default Subsector;
