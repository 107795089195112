import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  setAnalyticsTableFilterSearch,
  setAnalyticsTableFilterPagination,
  setAnalyticsTableFilterPerPage,
  setAnalyticsTableSort,
  getAnalytics,
  roadshowClearStats,
  resetAnalytics,
} from './actions';
import Analytics from './Analytics';

function mapStateToProps(state) {
  return {
    tableFilter: state.dealRoadshows.roadshow.analytics.tableFilter,
    analytics: state.dealRoadshows.roadshow.analytics.all.list,
    timeZone: state.dealRoadshows.roadshow.analytics.all.timeZone,
    totalCount: state.dealRoadshows.roadshow.analytics.all.totalCount,
    isFetching: state.dealRoadshows.roadshow.analytics.all.isFetching,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setAnalyticsTableFilterSearch,
    setAnalyticsTableFilterPagination,
    setAnalyticsTableFilterPerPage,
    setAnalyticsTableSort,
    getAnalytics,
    roadshowClearStats,
    resetAnalytics,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Analytics);
