import { connect } from 'react-redux';
import {
  changeDeleteBondModalVisible,
  setSelectedBondIdAndName,
} from '@/condor/application/actions/finsight/import/bond/list/actions';
import BwicRow from './BwicRow';

const mapDispatchToProps = {
  changeDeleteBondModalVisible,
  setSelectedBondIdAndName,
};

export default connect(
  null,
  mapDispatchToProps,
)(BwicRow);
