import React from 'react';
import { RequestSubmitType, REQUEST_DEFAULT_SUBJECT } from '@/condor/application/FinsightManager/ManagePlans/constants';
import { InputLabel as Label } from '@dealroadshow/uikit';

import styles from './common.scss';

const ApproveHeaderMessage = ({ name, planName }) => (
  <div className={ styles.previewBlockGreeting }>
    { `Hello${ name ? ` ${ name }` : '' },` }
    <div>
      { REQUEST_DEFAULT_SUBJECT[RequestSubmitType.APPROVE] }
      <br />
      { `Your current subscription plan is: ${ planName }` }
    </div>
  </div>
);

const DenyHeaderMessage = ({ name, planName }) => (
  <div className={ styles.previewBlockGreeting }>
    { `Hello${ name ? ` ${ name }` : '' },` }
    <div>
      { REQUEST_DEFAULT_SUBJECT[RequestSubmitType.DENY] }
      <br />
      { `Your current subscription plan is: ${ planName }` }
    </div>
  </div>
);

const UpgradeHeaderMessage = ({ name, planName }) => (
  <div className={ styles.previewBlockGreeting }>
    { `Hello${ name ? ` ${ name }` : '' },` }
    <div>
      { REQUEST_DEFAULT_SUBJECT[RequestSubmitType.UPGRADE] }
      <br />
      { `Your current subscription plan is: ${ planName }` }
    </div>
  </div>
);

const DenyFooterMessage = () => (
  <span>
    If you feel this is an error, please email <a type="email">support@finsight.com</a>.
  </span>
);

const REQUEST_DEFAULT_HEADER_MESSAGES = {
  [RequestSubmitType.APPROVE]: ApproveHeaderMessage,
  [RequestSubmitType.UPGRADE]: UpgradeHeaderMessage,
  [RequestSubmitType.DENY]: DenyHeaderMessage,
};

const REQUEST_DEFAULT_FOOTER_MESSAGE = {
  [RequestSubmitType.APPROVE]: null,
  [RequestSubmitType.UPGRADE]: null,
  [RequestSubmitType.DENY]: DenyFooterMessage,
};

export const PreviewText = ({ emailText, type, name = '', planName }) => {
  const PreviewHeader = REQUEST_DEFAULT_HEADER_MESSAGES[type];
  const PreviewFooter = REQUEST_DEFAULT_FOOTER_MESSAGE[type];

  return (
    <div>
      <Label>message preview:</Label>
      <div className={ styles.previewBlockTop }>
        { PreviewHeader && <PreviewHeader name={ name } planName={ planName } /> }
      </div>
      {
        emailText && (
          <>
            <div className={ styles.previewBlockMessage }>
              <div className={ styles.titleMessage }>
                Message from Finsight Support <br />
                ********************************************************* <br />
              </div>
              <div
                className={ styles.textMessage }
                dangerouslySetInnerHTML={ { __html: emailText } }
              />
            </div>
          </>
        )
      }
      {
        PreviewFooter && (
          <div className={ styles.previewBlockBottom }>
            <PreviewFooter />
          </div>
        )
      }
    </div>
  );
};
