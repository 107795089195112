import { injectable, inject } from 'inversify';
import Request from '@/Framework/api/Rpc/Request';
import RpcDispatcher from '@/dmPortal/application/DI/Rpc/HttpDispatcher';

@injectable()
class EntityRepository {
  constructor(@inject(RpcDispatcher) private readonly rpc: typeof RpcDispatcher) {}

  getCounters = async () => {
    const request = new Request('open_market.entities.total_count');
    const response = await this.rpc.call(request);

    return response.getResult().payload;
  };
}

export default EntityRepository;
