import finsightWebUrl from '@/finsight/infrastructure/finsightWebUrl';

/**
 * @param {String} pathname
 * @param {String} bondType
 * @param {String} assetClass
 * @return {Object}
 */

const getBwicsInventoryMetaSchema = (pathname, bondType, assetClass) => {
  if (!assetClass) {
    return null;
  }
  return {
    '@type': 'WebPage',
    url: finsightWebUrl.getUrl(pathname),
    name: `Fixed Income ${ assetClass } ${ bondType }`,
    description: 'Supercharge your ABS BWIC & Inventory discovery process. Seamlessly explore BWICS & Inventory from participating dealers.',
    keywords: [assetClass, 'fixed income', 'inventories', 'bwics'],
  };
};

export default getBwicsInventoryMetaSchema;
