import { CONDOR_FINSIGHT_FILINGS_BASE_URL } from '@/condor/ui/common/constants';

/**
 * @param {String} filingType
 * @return {String}
 */
export const getFilingsUrl = (filingType) => (
  `${ CONDOR_FINSIGHT_FILINGS_BASE_URL }/${ filingType }`
);

/**
 * @param {String} filingType
 * @param {String} filingId
 * @return {String}
 */
export const getFilingProfileUrl = (filingType, filingId) => (
  `${ CONDOR_FINSIGHT_FILINGS_BASE_URL }/${ filingType }/profile/${ filingId }`
);

/**
 * @param {String} filingType
 * @param {String} filingId
 * @return {String}
 */
export const getFilingCreateUrl = (filingType, filingId) => (
  `${ CONDOR_FINSIGHT_FILINGS_BASE_URL }/${ filingType }/create/${ filingId }`
);

/**
 * @param {String} filingType
 * @param {String} filingId
 * @return {String}
 */
export const getFilingLinkToExistingDealUrl = (filingType, filingId) => (
  `${ CONDOR_FINSIGHT_FILINGS_BASE_URL }/${ filingType }/link-to-existing-deal/${ filingId }`
);
