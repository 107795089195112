import { connect } from 'react-redux';
import {
  changeCompanySelectModalCollection,
  initCompanyCreateModalCompanyData,
  changeCompanySelectModalVisibleState,
  changeActiveValidateCell,
} from '@/condor/application/actions/finsight/import/company/validation/actions';
import SelectCompanyIcon
  from '@/condor/ui/components/Finsight/Import/modules/Validation/ValidationTable/SelectCompanyIcon';

const mapDispatchToProps = {
  initCollection: changeCompanySelectModalCollection,
  initCompany: initCompanyCreateModalCompanyData,
  setActiveCell: changeActiveValidateCell,
  onSelectModalOpen: () => changeCompanySelectModalVisibleState(true),
};

export default connect(
  null,
  mapDispatchToProps,
)(SelectCompanyIcon);
