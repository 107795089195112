import React from 'react';
import { useSlate } from 'slate-react';
import { Editor } from 'slate';
import Button from './Button';
import { IconType, Icon } from '@dealroadshow/uikit';
import { TToolbarItemFormat } from '../interfaces';

interface IProps {
  format: TToolbarItemFormat,
  iconType: IconType,
  className?: string,
  activeClassName?: string,
}

const MarkButton = ({ format, iconType, className, activeClassName }: IProps) => {
  const editor = useSlate();
  const isMarkActive = (): boolean => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
  };
  return (
    <Button
      activeClassName={ activeClassName }
      active={ isMarkActive() }
      format={ format }
      className={ className }
      onMouseDown={ (e) => {
        e.preventDefault();
        const isActive = isMarkActive();

        if (isActive) {
          Editor.removeMark(editor, format);
        } else {
          Editor.addMark(editor, format, true);
        }
      } }
    >
      <Icon type={ iconType } />
    </Button>
  );
};

export default MarkButton;
