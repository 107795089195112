import React, { Fragment } from 'react';
import cn from 'classnames';
import { DEAL_PROFILE_FIELDS } from '@/condor/ui/components/Finsight/DealProfile/constants';
import { Icon, IconType, Tooltip } from '@dealroadshow/uikit';
import { useTranchesContext } from '@/condor/ui/components/Finsight/DealProfile/Tranches/TranchesContext';

import styles from './properties.scss';

interface IPropertyProps {
  name: string,
  labelTooltipContent: React.ReactNode,
  label: string,
  isSeparator?: boolean,
  isExpandable?: boolean,
  groupId: number,
  handleClickCollapseIcon: () => void,
}

const Property = ({
   name,
   labelTooltipContent,
   label,
   isSeparator,
   isExpandable,
   groupId,
   handleClickCollapseIcon,
}: IPropertyProps) => {
  const { collapsedGroupIds } = useTranchesContext();

  return (
    <Fragment key={ name }>
      <div className={ cn(styles.property,
        {
          [styles.commentProperty]: name === DEAL_PROFILE_FIELDS.TRANCHE.COMMENTS.name,
          [styles.collapsedProperty]: collapsedGroupIds.includes(groupId),
        },
      ) }
      >
        { isExpandable && (
          <Icon
            type={ collapsedGroupIds.includes(groupId) ? IconType.expand : IconType.collapse }
            className={ styles.expandIcon }
            onClick={ handleClickCollapseIcon }
          />
        ) }
        <Tooltip content={ labelTooltipContent || label }>
          { label }
        </Tooltip>
      </div>
      { isSeparator && (
        <div className={ styles.stripe } />
      ) }
    </Fragment>
  );
};

export default Property;
