import React, { useCallback } from 'react';
import CondorAsyncSelect from '@/ui/shared/components/Form/Select/legacy/CondorAsyncSelect';
import { ISSUER } from '@/finsight/application/actions/pageFilters/constants';
import { mapOptionToValue } from '@/Framework/dataHelpers/selectOptionMappers';

interface IProps {
  filteredOptions: any[],
  fetchDealCardFieldCompaniesOptions: (payload: any) => void,
  formValues: any,
  input: any,
}

const IssuerSelect = (
  {
    filteredOptions,
    fetchDealCardFieldCompaniesOptions,
    formValues: { primaryIssuer, additionalIssuers },
    input,
    ...otherProps
  }: IProps,
) => {
  const fetchOptions = useCallback(
    (query, callback) => fetchDealCardFieldCompaniesOptions(
      {
        query,
        fieldName: input.name,
        includedRole: ISSUER,
        excludedIds: [
          ...(primaryIssuer ? [mapOptionToValue(primaryIssuer)] : []),
          ...additionalIssuers.map(mapOptionToValue),
        ],
        callback,
      },
    ),
    [primaryIssuer, additionalIssuers.length, input.name],
  );
  return (
    <CondorAsyncSelect
      { ...otherProps }
      filteredOptions={ filteredOptions }
      fetchOptions={ fetchOptions }
      input={ input }
    />
  );
};

export default IssuerSelect;
