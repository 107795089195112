import React from 'react';
import SearchInput from '@/ui/shared/components/Form/Fields/SearchInput';
import { useTraceContext } from '@/condor/application/Trace/TraceContextProvider';

import styles from './styles.scss';

const MapTradeSearchInput = () => {
  const { searchValue, setSearchValue } = useTraceContext();
  const handleOnChange = (v) => {
    setSearchValue(v);
  };

  return (
    <div className={ styles.searchInputContainer }>
      <SearchInput placeholder="Search by deal" onChange={ handleOnChange } value={ searchValue } debounceWaitTime={ 500 } />
    </div>
  );
};

export default MapTradeSearchInput;
