import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  resetCompaniesImportCollection,
  getCompaniesImportByFilter,
  sort,
  paginate,
} from '@/condor/application/actions/finsight/import/company/auditTrail/actions';

import AuditTrail from './AuditTrail';
import selectors from '@/condor/application/actions/finsight/import/company/auditTrail/selectors';

const mapStateToProps = (state) => ({
  isFetching: selectors.isFetching(state),
  data: selectors.getCollectionData(state),
  sortBy: selectors.getSortBy(state),
  sortOrder: selectors.getSortOrder(state),
});

const mapDispatchToProps = (dispatch) => ({
  init: () => dispatch(getCompaniesImportByFilter()),
  reset: () => dispatch(resetCompaniesImportCollection()),
  onSortChange: (sortBy, sortOrder) => {
    dispatch(sort({ sortBy, sortOrder }));
    dispatch(paginate(1));
    dispatch(getCompaniesImportByFilter());
  },
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuditTrail));
