import React from 'react';
import cn from 'classnames';
import styles from '../Tranches/Tranche/tranche.scss';

/**
 * @param InputComponent
 */
const withTrancheCell = (InputComponent) => (
  {
    cellClassName,
    ...otherProps
  },
) => (
  <div className={ cn(styles.trancheCell, cellClassName) }>
    <InputComponent
      { ...otherProps }
      isNarrow
    />
  </div>
);

export default withTrancheCell;
