import { connect } from 'react-redux';
import uploadSelectors from '@/condor/application/actions/finsight/import/company/upload/selectors';
import validationSelectors from '@/condor/application/actions/finsight/import/company/validation/selectors';
import UploadProcess from '@/condor/ui/components/Finsight/Import/modules/Process/UploadProcess';

const mapStateToProps = (state) => (
  {
    uploadProcess: uploadSelectors.getUploadProcess(state),
    validationProcess: validationSelectors.getValidationProcess(state),
  }
);

export default connect(
  mapStateToProps,
  null,
)(UploadProcess);
