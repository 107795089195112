import { Component } from 'react';

interface IProps {
  abbr: string,
}

class TimeZoneAbbr extends Component<IProps, any> {
  /**
   * @param {Object} nextProps
   */
  shouldComponentUpdate(nextProps) {
    return nextProps.abbr !== this.props.abbr;
  }

  render() {
    return this.props.abbr;
  }
}

export default TimeZoneAbbr;
