import React, { Component } from 'react';
import cn from 'classnames';
import styles from './counter.scss';

interface IProps {
  onMount?: (isCountChangeSubscribe: boolean) => void,
  onUnmount?: (isCountChangeSubscribe: boolean) => void,
  className: string,
  isCountChangeSubscribe?: boolean,
  value: number | string,
}

const defaultProps = {
  isCountChangeSubscribe: false,
  onMount: () => {},
  onUnmount: () => {},
};

class Counter extends Component<IProps, any> {
  componentDidMount() {
    this.props.onMount(this.props.isCountChangeSubscribe);
  }

  componentWillUnmount() {
    if (this.props.onUnmount) {
      this.props.onUnmount(this.props.isCountChangeSubscribe);
    }
  }

  render() {
    if (+this.props.value === 0) {
      return null;
    }

    return (
      <div className={ cn(styles.container, this.props.className) }>
        { +this.props.value > 99 ? '99+' : this.props.value }
      </div>
    );
  }
}
// @ts-ignore
Counter.defaultProps = defaultProps;

export default Counter;
