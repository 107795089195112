import React from 'react';
import cn from 'classnames';
import { Icon, IconType, DataTable, dataTableStyles, IRowData } from '@dealroadshow/uikit';

import Button, { variantTypes } from '@/ui/shared/components/Button';

import getColumns from './getColumns';

import { IFinalFormFieldArrayInputComponentProps } from '@/ui/shared/components/Form/FinalForm/interfaces';
import { ICustomSectionSubfield } from '@/evercall/domain/vo/call/admin/CustomRegistration';

import styles from './customSectionSubfieldsTable.scss';

const CustomSectionSubfieldsTable = ({ fields }: IFinalFormFieldArrayInputComponentProps<ICustomSectionSubfield>) => {
  const columns = getColumns(fields);
  const data = fields.map((fieldName, fieldIndex) => (
    {
      fieldName,
      fieldIndex,
      className: styles.row,
      ...fields.value[fieldIndex],
    }
  ));
  const orderCallback = (oldIndex, newIndex) => fields.move(oldIndex, newIndex);
  return (
    <>
      <DataTable
        isDraggable
        dataTest="customDetailsSectionSubfieldsTable"
        className={ cn(dataTableStyles.isSecondaryTable, styles.customSectionDetailsTable) }
        data={ data as IRowData[] }
        columns={ columns }
        orderCallback={ orderCallback }
      />
      <Button
        dataTest="subfieldAddButton"
        variant={ variantTypes.text }
        className={ styles.addButton }
        onClick={ () => fields.push(
          {
            title: '',
            text: '',
            hyperlinkText: '',
          },
        ) }
      >
        <Icon
          type={ IconType.add }
          className={ styles.addIcon }
        />
        Add Subfield
      </Button>
    </>
  );
};

export default CustomSectionSubfieldsTable;
