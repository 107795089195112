import { Dependencies } from 'constitute';
import Request from '@/Framework/api/Rpc/Request';
import CondorRpcDispatcher from '@/condor/application/DI/Rpc/HttpDispatcher';
import SocketClient from '@/condor/application/DI/Socket/Client';

@Dependencies(
  CondorRpcDispatcher,
  SocketClient,
)
class UserRepository {
  private condorRpc: typeof CondorRpcDispatcher;

  private permissionSubscription: any;

  private socket: any;

  constructor(condorRpc, SocketClient) {
    /**
     * @type {Dispatcher}
     */
    this.condorRpc = condorRpc;
    /**
     * @type {Dispatcher}
     */
    this.socket = SocketClient;
    this.permissionSubscription = null;
  }

  getUserPermissionModules = async () => {
    let req = new Request('condor.user_permissions.module.get_modules');
    let response = await this.condorRpc.call(req);

    return response.getResult().payload;
  };

  getUserPermissionGroup = async () => {
    let req = new Request('condor.user_permissions.permission_groups.get_permission_groups');
    let response = await this.condorRpc.call(req);

    return response.getResult().payload;
  };

  /**
   * @param {Number} contactId
   *
   * @return {Promise.<*>}
   */
  getContactPermissions = async (contactId) => {
    let req = new Request('condor.user_permissions.contact.get_permissions', { contactId });
    let response = await this.condorRpc.call(req);

    return response.getResult().payload;
  };

  /**
   * @return {Promise.<*>}
   */
  getCurrentUserPermissions = async () => {
    let req = new Request('condor.user_permissions.contact.get_current_permissions');
    let response = await this.condorRpc.call(req);

    return response.getResult().payload;
  };

  /**
   * @param payload
   * @param payload.contactId Number
   * @param {Object[]} payload.userPermissions
   *
   * @return {Promise.<*>}
   */
  updateContactPermissions = async (payload) => {
    let req = new Request('condor.user_permissions.contact.save_permissions_data', { ...payload });
    let response = await this.condorRpc.call(req);

    return response.getResult().payload;
  };

  /**
   * @param payload
   * @param payload.contactId Number
   * @param {Object[]} payload.userPermissions
   * @param callback callback
   * @return {Promise.<*>}
   */
  subScribeToUpdateContactPermissions = async ({ payload, callback }) => {
    const subscribeReq = new Request('condor.socket.user_permissions.save_permissions_data', {
      contactId: payload.contactId,
      permissionCollection: payload.userPermissions,
    });

    const finishCallbackWrapper = () => ({ params: { payload } }) => {
      this.permissionSubscription.cancel();
      callback(payload);
    };

    this.permissionSubscription = await this.socket.subscribe(subscribeReq);
    this.permissionSubscription.on('finished', finishCallbackWrapper());
  };
}

export default UserRepository;
