import { connect } from 'react-redux';
import cn from 'classnames';
import { exportToExcelManagerAnalyticsContacts } from '@/condor/application/actions/finsightManager/analytics/contactsCollection/actions';
import { TRACE_MODULE } from '@/condor/ui/components/FinsightManager/constants';
import ExportButtonWithSpinner from '@/ui/shared/components/Button/ExportButtonWithLoader';
import * as selectors from '@/condor/application/actions/finsightManager/analytics/contactsCollection/selectors';
import styles from './contacts.scss';
import pageStyles from '@/ui/shared/styles/page.scss';

const mapStateToProps = (state) => {
  const disabled = selectors.isFetching(state) || (selectors.getTotalCount(state) === 0);
  return (
    {
      className: cn(
        pageStyles.pageNavigationLinks,
        styles.exportBtnWrapper,
      ),
      tooltipContainerClassName: cn(
        styles.exportBtn,
        { [styles.isDisabled]: disabled },
      ),
      label: '',
      isDisabled: disabled,
      isFetching: selectors.isExporting(state),
    }
  );
};

const mapDispatchToProps = {
  onClick: () => exportToExcelManagerAnalyticsContacts(TRACE_MODULE),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExportButtonWithSpinner);
