import React from 'react';

import AddItemsInputContainer from './AddItemsInputContainer';
import DeleteModalContainer from './DeleteModalContainer';
import PaginatorContainer from './PaginatorContainer';
import DataTableContainer from './DataTableContainer';
import SearchInputContainer from './SearchInputContainer';

import Blacklist from '@/condor/ui/common/Blacklist';

const APPLICATION_BLACKLIST_DELETE_MODAL_WARNING_TEXT = `If Domain is on Global Blacklist, deleting domain from
Application Blacklist will no longer allow any contact with that email domain from accessing roadshow with
“Allow Non-Corporates” security option selected. If Domain is not on Global Blacklist, Deleting domain from
Application Blacklist will allow any contact with that email domain to access all Finsight platforms.`;

interface IProps {
  init: () => void,
  reset: () => void,
  onDeleteIconClick: () => void,
}

const ApplicationBlacklist = (props: IProps) => (
  <Blacklist
    pageLabel="Application Blacklist"
    tableLabel="Application Blacklist"
    onMount={ props.init }
    onUnmount={ props.reset }
    onDeleteIconClick={ props.onDeleteIconClick }
    deleteModalWarningText={ APPLICATION_BLACKLIST_DELETE_MODAL_WARNING_TEXT }
    components={
      {
        addItemsInput: AddItemsInputContainer,
        deleteModal: DeleteModalContainer,
        paginator: PaginatorContainer,
        dataTable: DataTableContainer,
        searchInput: SearchInputContainer,
      }
    }
  />
);

export default ApplicationBlacklist;
