import cn from 'classnames';
import styles from './tableWrapper.scss';

interface IProps {
  className?: string,
  children?: React.ReactNode,
  childrenToShowOnHover?: React.ReactNode,
  showHoverContent?: boolean,
}

const LastTableCell = (props: IProps) => (
  props.showHoverContent
    ? props.childrenToShowOnHover
    : (
      <div className={ props.className }>
        <div className={ cn({ [styles.hideOnRowHoverHelper]: !!props.childrenToShowOnHover }) }>
          { props.children }
        </div>
        <div className={ cn({ [styles.showOnRowHoverHelper]: !!props.childrenToShowOnHover }) }>
          { props.childrenToShowOnHover }
        </div>
      </div>
    )
);

export default LastTableCell;
