import React from 'react';
import cn from 'classnames';
import { UserPlanType } from '@/users/domain/UserPlan';

import styles from './styles.scss';

const PlanIndicator = ({ userPlan }) => (
  <div className={ cn(styles.indicator, {
    [styles.starter]: userPlan === UserPlanType.STARTER,
    [styles.premium]: userPlan === UserPlanType.PREMIUM,
    [styles.pro]: userPlan === UserPlanType.PRO,
  }) }
  />
);

export const mapPlanToIcon = {
  pro: () => <PlanIndicator userPlan={ UserPlanType.PRO } />,
  starter: () => <PlanIndicator userPlan={ UserPlanType.STARTER } />,
  premium: () => <PlanIndicator userPlan={ UserPlanType.PREMIUM } />,
};
