import { connect } from 'react-redux';
import { getDealsByFilter, resetDealsCollection } from '@/condor/application/actions/finsight/dealNoPricingDate/actions';

import DealNoPricingDatePage from './DealNoPricingDatePage';

const mapDispatchToProps = {
  init: getDealsByFilter,
  reset: resetDealsCollection,
};

export default connect(
  null,
  mapDispatchToProps,
)(DealNoPricingDatePage);
