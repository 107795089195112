import React, { createContext, useContext } from 'react';
import useCallsCollection from './useCallsCollection';

export const CallsCollectionContext = createContext<ReturnType<typeof useCallsCollection>>(null);

export function useCallsCollectionContext() {
  const context = useContext(CallsCollectionContext);
  if (!context) {
    throw new Error('useCallsCollectionContext must be used within a CallsCollectionContext');
  }
  return context;
}

interface IProps {
  children: React.ReactNode,
}

const CallsCollectionContextProvider = ({ children }: IProps) => (
  <CallsCollectionContext.Provider value={ useCallsCollection() }>
    { children }
  </CallsCollectionContext.Provider>
);

export default CallsCollectionContextProvider;
