export default {
  NAME: {
    name: 'name',
    label: 'Name',
    placeholder: 'name',
  },
  COLOR: {
    name: 'color',
    label: 'color',
  },
  CONTENT: {
    name: 'content',
    label: 'content',
    placeholder: 'Add banner text...',
  },
  PREVIEW: {
    label: 'preview',
  },
  POSITIONS: {
    name: 'positionIds',
    label: 'show banner on the following sites',
  },
  SAVE_DRAFT: {
    title: 'Save Draft',
  },
  SAVE_PUBLISH: {
    title: 'Save & Publish',
  },
  DISABLE: {
    title: 'Disable',
  },
  DELETE: {
    title: 'Delete',
  },
  CANCEL: {
    title: 'Cancel',
  },
  ARE_YOU_SURE: {
    title: 'Are You Sure',
    dataTest: 'deleteBanner',
  },
  PASSING: {
    label: 'After pressing ‘Save & Publish’ banner will be displayed on selected platforms.',
  },
};
