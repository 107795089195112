import addHours from 'date-fns/addHours';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import { MONTH_DAY_YEAR_DATETIME_FORMAT } from '@/Framework/DateTime/dateFormats';

export const getFormatInTimeZoneDate = (
  date: Date | string | number | undefined,
  timeZone: string,
  format = MONTH_DAY_YEAR_DATETIME_FORMAT,
): string => {
  const newDate = date ? new Date(date) : new Date();
  const newDateWithOffsetTime = addHours(newDate, -(newDate.getTimezoneOffset() / 60));
  return formatInTimeZone(newDateWithOffsetTime, timeZone, format);
};
