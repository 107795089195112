/**
 * Converts string to inline styles object
 * @param string
 * @returns {Object}
 */

const nodeStringToStyleAttrObject = (string) => {
  let styles = [];
  try {
    const dom = (new DOMParser()).parseFromString(string, 'text/html');
    dom.querySelectorAll('[style]').forEach((node) => styles.push(node.getAttribute('style')));
    return styles.reduce((acc, x) => {
      const [prop, value] = x.split(':');
      return { ...acc, [prop]: value.trim() };
    }, {});
  } catch (e) {
    return {};
  }
};

export default nodeStringToStyleAttrObject;
