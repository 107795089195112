/**
 * @param {Boolean} isCreateNewDeal
 * @param {Object} deal
 * @param {Boolean} isSecFiling
 * @return {String}
 */
const getDealPageTitle = ({ isCreateNewDeal, deal, isSecFiling }) => {
  if (!isCreateNewDeal && !isSecFiling) {
    return `${ deal.primaryIssuer?.name || '' } ${ deal.seriesName || '' }`;
  }

  if (isCreateNewDeal && !isSecFiling) {
    return 'Create New Deal';
  }

  return '';
};

export default getDealPageTitle;
