import { useState } from 'react';
import { useProfileDataContext } from '@/openMarket/application/Condor/ProfileDataContext';
import ProfileRepository from '@/openMarket/infrastructure/repository/Condor/ProfileRepository';
import getMessage from '@/Framework/Message/getMessage';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { useDIContext } from '@/Framework/DI/DIContext';
import { IProfile } from '@/openMarket/domain/Condor/vo/IProfile';
import { NotificationManager } from '@/ui/shared/components/Notification';
import { messageCodes } from '@/Framework/Message/messages';
import { DEFAULT_CUSTOMIZATION_COLORS } from '@/users/ui/common/colors';
import { IFormValues } from '../interfaces';
import { FormFieldName } from '../constants';

const getProfilePayload = (values: IFormValues): IProfile => ({
  id: values.id,
  alias: values.url,
  name: values.name?.trim() ?? '',
  restrictAccess: values.restrictAccess,
  headerDescription: values.headerDescription?.trim() ?? '',
  headerLogo: values.headerLogo,
  disclaimer: values.disclaimer?.trim() ?? '',
  disclaimerLogo: values.disclaimerLogo,
  published: values.published,
  displayTabs: (values.displayTabs ?? [])
    .filter((tab) => !!tab.name && tab.transactionTypes.length > 0)
    .map((tab) => ({
      ...tab,
      name: tab.name.trim(),
    })),
  isCustomized: values.isCustomized,
  backgroundImage: values.isCustomized ? values.backgroundImage : null,
  ...(values.isCustomized ? {
    backgroundColor: values.backgroundColor,
    tileColor: values.tileColor,
    primaryTextColor: values.primaryTextColor,
    secondaryTextColor: values.secondaryTextColor,
    linkColor: values.linkColor,
    inputLabelColor: values.inputLabelColor,
    buttonTextColor: values.buttonTextColor,
    buttonColor: values.buttonColor,
  } : DEFAULT_CUSTOMIZATION_COLORS),
});

const parseSubmitErrors = (errorResponse: {
  error?: {
    message: string,
  },
}) => {
  const fieldsErrorsMapping: { [key in FormFieldName]?: string[] } = {
    name: ['OPEN_MARKET_PROFILE_NAME_ALREADY_EXISTS'],
    url: ['OPEN_MARKET_PROFILE_ALIAS_ALREADY_EXISTS'],
  };

  return Object.keys(fieldsErrorsMapping).reduce((errors, key) => {
    const fieldErrors = fieldsErrorsMapping[key] as string[];
    const errorMessageKey = fieldErrors.find((errorMessage) => errorMessage === errorResponse.error?.message);

    if (errorMessageKey) {
      return {
        ...errors,
        [key]: getMessage(messageCodes[errorMessageKey]),
      };
    }

    return errors;
  }, {});
};

export const useProfileFormSubmit = ({ isCreate }: { isCreate: boolean }) => {
  const { redirectToList } = useProfileDataContext();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { container } = useDIContext();
  const profileRepository = container.get<ProfileRepository>(ProfileRepository);

  const handleSubmitForm = async (values: IFormValues, _, setErrors: (errors: any) => void) => {
    const submitValues = getProfilePayload(values);

    try {
      setIsSubmitting(true);

      if (isCreate) {
        await profileRepository.createProfile(submitValues);
      } else {
        await profileRepository.updateProfile(submitValues);
      }

      const message = isCreate ? messageCodes.OPEN_MARKET_PROFILE_CREATED : messageCodes.OPEN_MARKET_PROFILE_UPDATED;
      NotificationManager.success(getMessage(message, { profileName: submitValues.name }));
      redirectToList();
    } catch (error) {
      const parsedErrors = parseSubmitErrors(error);

      if (Object.values(parsedErrors).length > 0) {
        setErrors(parsedErrors);
      } else {
        NotificationManager.error(getErrorMessage(error));
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    isSubmitting,
    handleSubmitForm,
  };
};
