const formatEventDescription = (action = '') => {
  const split = action.indexOf(':');

  if (!split) return { title: undefined, description: action };

  return {
    title: action.slice(0, split),
    description: action.slice(split + 2),
  };
};

export default formatEventDescription;
