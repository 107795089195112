import React from 'react';
import FinalForm from '@/ui/shared/components/Form/FinalForm';
import FormFields from './FormFields';

import validate from './validator';
import SSOContextProvider, { useSSOContext } from '@/condor/application/Accounts/AccountProfile/AccountSSO/AccountSSOForm';

import styles from './styles.scss';

const AccountSSOForm = () => {
  const { onSubmit, initialValues } = useSSOContext();

  return (
    <>
      <div className={ styles.ssoFormTitle }>Assertion Url</div>
      <FinalForm
        className={ styles.ssoForm }
        name="AccountSSOForm"
        dataTest="AccountSSOForm"
        render={ () => <FormFields /> }
        onSubmit={ onSubmit }
        validate={ validate }
        initialValues={ initialValues }
      />
    </>
  );
};

export default () => (
  <SSOContextProvider>
    <AccountSSOForm />
  </SSOContextProvider>
);
