import React from 'react';
import cn from 'classnames';
import { Checkbox } from '@dealroadshow/uikit';
import Counter from '@/condor/ui/common/Counter';

import styles from './checkboxFiltersBlock.scss';

interface IProps {
  items: any[],
  className?: string,
}

const CheckboxFiltersBlock = ({ items = [], className = '' }:IProps) => (
  <div className={ cn(styles.filtersBlock, className) }>
    { items.map((item) => (
      <div
        className={ styles.checkboxWrapper }
        key={ item.label }
      >
        <Checkbox
          className={ styles.checkbox }
          { ...item }
        />
        { item.count !== undefined && (
          <Counter value={ item.count } className={ styles.counter } />
        ) }
      </div>
    )) }
  </div>
);

export default CheckboxFiltersBlock;
