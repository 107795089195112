import React from 'react';
import Button, { variantTypes } from '@/ui/shared/components/Button';

import styles from '../traceTable.scss';

interface IProps {
  onClick: (e: React.SyntheticEvent) => void,
}
const ArchivedActionButtons = ({ onClick }: IProps) => (
  <div className={ styles.hoveredContainer }>
    <Button
      variant={ variantTypes.action }
      className={ styles.mapBtn }
      onClick={ onClick }
      title="Restore"
      dataTest="tradeRestoreButton"
    />
  </div>
);

export default ArchivedActionButtons;
