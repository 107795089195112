import { v4 as uuid } from 'uuid';
import { SINGLE_ENTRY_CODE_TYPE } from '@/dealroadshow/domain/EntryCode';
import { DEFAULT_WATERMARKS_OPACITY, SIDEBAR_WIDTHS, ROADSHOW_FORM_DISCLAIMER } from './constants';

export const DEFAULT_DEAL_MANAGER = {
  corporateEmail: null,
  dealDigestInTZ: {
    11: true,
    15: true,
  },
  loginAlerts: false,
  inviteByEmail: true,
};

export enum VerificationState {
    BYPASS_ACTIVATED = 'bypassActivated',
    BYPASS_DEACTIVATED = 'bypassDeactivated',
    PROMPT_VERIFICATION_ACTIVATED = 'promptVerificationActivated',
}

export const ROADSHOW_FORM_DEFAULT_VALUE = {
  id: null,
  published: false,
  // Quick Setup
  name: '',
  transactionTypeId: '',
  finishedAt: null,
  onceExpired: false,
  entryCodeRotatingInitialCollection: [],
  entryCodeRotatingInitialMap: {},
  entryCodeRotatingCollection: [],
  entryCodeRotatingMap: {},
  caseSensitiveEntryCodes: false,
  appliedEntryCodeType: SINGLE_ENTRY_CODE_TYPE,
  entryCode: '',
  type: 'Slides',
  timeZoneApplied: {
    id: '',
    timeZone: 'America/New_York',
    abbr: 'ET',
  },
  autoActivationMultipleEntryCodes: true,
  autoActivationMultipleEntryCodesStartTimeShift: 10,
  autoActivationMultipleEntryCodesFinishTimeShift: 0,
  dialInEnabled: false,
  dialInPhones: [],
  dialInCode: null,
  dialInPhone: null,
  dealManagers: [],
  // Deal Documents, Videos & External Links
  uploadDocumentsList: [],
  uploadCommercialVideoList: [],
  externalLinks: [{ uuid: uuid() }],
  // Additional Security, Features & Disclaimer
  watermarkEnabled: true,
  pdfWatermarkEnabled: false,
  investorEmailVerification: VerificationState.BYPASS_DEACTIVATED,
  mobileDisabled: false,
  corporateEmailChecked: false,
  sidebarWidth: SIDEBAR_WIDTHS.STANDARD,
  disclaimer: ROADSHOW_FORM_DISCLAIMER,
  watermarkOpacity: `${ DEFAULT_WATERMARKS_OPACITY }%`,
  // Restrict Access
  restrictWhiteOrBlacklistSwitcher: 'none',
  whitelistUsersAndDomains: [],
  // Other (Underwriters & Logo)
  underwriters: [
    {
      uuid: uuid(),
      lead: false,
      phone: {
        value: '',
        code: '+1',
      },
    },
  ],
  // Enhanced Tagging for Analytics
  enhancedTagging: {
    sponsorName: '',
    subsectorId: '',
    subindustryId: '',
    countryId: '',
    currencyId: '',
    underwriters: [{ uuid: uuid(), lead: false }],
    dealSize: '',
  },
  reviewEntryCode: null,
  reviewEntryCodeEnabled: false,
};
