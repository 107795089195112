import { useDIContext } from '@/Framework/DI/DIContext';
import AssetRepository from '@/openMarket/infrastructure/repository/Condor/AssetRepository';
import { IFinalFormFieldInputComponentProps } from '@/ui/shared/components/Form/FinalForm/interfaces';
import SharedUploadImageInput, {
  TUploadPreviewFormValue,
} from '@/ui/shared/modules/upload/components/UploadImageInput';
import { BYTES_IN_TWO_MEGABYTES } from '@/ui/shared/modules/upload/constants';

interface IProps extends IFinalFormFieldInputComponentProps<TUploadPreviewFormValue> {
  label: string,
}

const unmodifyUrl = (url: string) => url;

const UploadImageInput = (props: IProps) => {
  const { container } = useDIContext();
  const assetRepository = container.get<AssetRepository>(AssetRepository);

  return (
    <SharedUploadImageInput
      // @ts-ignore
      uploadMethod={ assetRepository.uploadAsset }
      maxSize={ BYTES_IN_TWO_MEGABYTES }
      allowedFileText="JPG, PNG, SVG"
      maxSizeUnits="MB"
      showMaxSizeInfo
      maxSizeInfo="The max file size supported is 2 megabytes."
      urlHelper={ {
        getFileUrl: unmodifyUrl,
      } }
      shouldUrlStartWithSlash={ false }
      errorClassName="upload_error"
      { ...props }
    />
  );
};

export default UploadImageInput;
