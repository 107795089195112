import { Dependencies } from 'constitute';
import Request from '@/Framework/api/Rpc/Request';
import FinsightWebRpcDispatcher from '@/finsight/application/DI/Rpc/finsight-web/HttpDispatcher';

@Dependencies(FinsightWebRpcDispatcher)
class CountryRepository {
  /**
   *
   * @param {Dispatcher} finsightWebRpcDispatcher
   */
  constructor(finsightWebRpcDispatcher) {
    this.rpc = finsightWebRpcDispatcher;
  }

  /**
   *
   * @param {Object} payload
   * @return {Promise<*>}
   */
  getCountriesList = async (payload = {}) => {
    const response = await this.rpc.request(new Request('finsight_web.get_countries', payload));
    return response.getResult().payload;
  };
}

export default CountryRepository;
