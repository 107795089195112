import { connect } from 'react-redux';
import Subscribers from './Subscribers';
import {
  resetCondorFinsightSubscribersList,
  getCondorFinsightSubsribersListByFilter,
} from '@/condor/application/actions/finsight/subscriptions/collection/actions';
import {
  setIsSubscribeModalVisible,
} from '@/condor/application/actions/finsight/subscriptions/metadata/actions';
import metadataSelectors from '@/condor/application/actions/finsight/subscriptions/metadata/selectors';

const mapStateToProps = (state) => ({
  isSubscribeModalVisible: metadataSelectors.isSubscribeModalVisible(state),
});

const mapDispatchToProps = {
  setIsSubscribeModalVisible,
  reset: resetCondorFinsightSubscribersList,
  getSubscribersList: getCondorFinsightSubsribersListByFilter,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Subscribers);
