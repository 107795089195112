import createAction from '@/Framework/State/Redux/createAction';
import { getContactsByFilter } from '@/condor/application/actions/contacts/collection/actions';
import * as filterActionTypes from './actionTypes';
import selectors from './selectors';

export const toggleContactsTableFilterState = (name) => (dispatch, getState) => {
  dispatch(createAction(
    filterActionTypes.SET_CONTACTS_TABLE_FILTER_STATE,
    { [name]: !selectors.getFilters(getState())[name] },
  ));
  dispatch(getContactsByFilter());
};

export const resetContactsFilters = () => createAction(filterActionTypes.RESET_CONTACTS_TABLE_FILTERS);
