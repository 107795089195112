import { connect } from 'react-redux';
import { getBannersPositionsList } from '@/condor/application/actions/banners/actions';
import { getBannersPositions } from '@/condor/application/actions/banners/selectors';
import BannerForm from './BannerForm';

const mapStateToProps = (state) => ({
  positions: getBannersPositions(state),
});

const mapDispatchToProps = {
  getBannersPositionsList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BannerForm);
