import React, { createContext, useContext, useState } from 'react';
import { IManagePlansRequestItem } from '@/condor/domain/vo/FinsightManager/ManagePlans';
import {
  RequestSubmitType,
} from '@/condor/application/FinsightManager/ManagePlans/constants';
import { useDIContext } from '@/Framework/DI/DIContext';
import ContactRepository from '@/finsight/infrastructure/repository/ContactRepository';
import { NotificationManager } from '@/ui/shared/components/Notification';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { useRequestsContext } from '@/condor/application/FinsightManager/Requests';
import { useRequestCountContext } from '@/condor/application/FinsightManager/RequestCount';
import stripTags from '@/Framework/browser/stripTags';

interface ISubmitData {
  cellData: IManagePlansRequestItem | Partial<IManagePlansRequestItem>,
  typeSubmit: RequestSubmitType,
}

export const initialSubmitData = { typeSubmit: null, cellData: {} };

const useApproveDenyRequest = () => {
  const { getCollection } = useRequestsContext();
  const { getRequestCount } = useRequestCountContext();
  const [selectedSubmitData, setSelectedSubmitData] = useState<ISubmitData>(initialSubmitData);
  const [isNotificationEnabled, setIsNotificationEnabled] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { container } = useDIContext();

  const onSubmit = async ({ subject, emailText }: { subject: string, emailText: string }) => {
    setIsSubmitting(true);
    const { cellData, typeSubmit } = selectedSubmitData;
    const contactRepository = container.get(ContactRepository);
    const payload = {
      id: cellData.id,
      sendEmail: isNotificationEnabled,
      subject,
      emailText: (emailText && !(stripTags(emailText) as string).length) ? '' : emailText,
    };
    try {
      const isApprove = typeSubmit === RequestSubmitType.APPROVE;
      const method = isApprove
        ? contactRepository.approveUserRequest
        : contactRepository.denyUserRequest;
      await method(payload);
      if (isApprove) {
        NotificationManager.success(
          `${ cellData.email } was successfully subscribed to the ${ cellData.requestedPlan } Plan`,
        );
      } else {
        NotificationManager.info(`${ cellData.email } was denied and reverted back to ${ cellData.currentPlan } Plan`);
      }
      setSelectedSubmitData(initialSubmitData);
      getCollection();
      getRequestCount();
    } catch (e) {
      NotificationManager.error(getErrorMessage(e));
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    isSubmitting,
    selectedSubmitData,
    setSelectedSubmitData,
    isNotificationEnabled,
    setIsNotificationEnabled,
    onSubmit,
  };
};

type ApproveDenyFormContextType = ReturnType<typeof useApproveDenyRequest>;

const ApproveDenyFormContext = createContext<ApproveDenyFormContextType>(null);

export const useApproveDenyFormContext = () => {
  const context = useContext(ApproveDenyFormContext);

  if (!context) {
    throw new Error('useApproveDenyFormContext must be used within the ApproveDenyFormContext');
  }
  return context;
};

export const ApproveDenyFormContextProvider = ({ children }) => (
  <ApproveDenyFormContext.Provider value={ useApproveDenyRequest() }>
    { children }
  </ApproveDenyFormContext.Provider>
);
