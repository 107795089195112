import React from 'react';
import cn from 'classnames';
import { Swatch } from 'react-color/lib/components/common';

import ColorType from './ColorType';
import { compareHex } from './helpers';
import { COLOR_PICKER_PRESET_COLORS } from './constants';

import styles from './colorPicker.scss';

interface IProps {
  colors: string[] | { color: string, title: string }[],
  hex: string,
  onClick: (payload: { hex: string, source: string }, e: any) => void,
  onSwatchHover: () => void,
}

/**
 * @deprecated This component has been chosen for migration to UIKit, meaning any further changes or updates
 * must be completed within the migration process.
 * @see https://www.notion.so/finsight-group/Component-Migration-Process-f4475950481d429ba0dc450d0bb0cb8b
 */
const ColorPickerPresetColors = (
  {
    colors,
    hex,
    onClick,
    onSwatchHover,
  }: IProps,
) => {
  const handleClick = (hex, e) => onClick({ hex, source: ColorType.getHex() }, e);

  return (
    <div className={ cn(styles.colors, 'flexbox-fix') }>
      { colors.map((colorObjOrString) => {
        const c = typeof colorObjOrString === 'string'
          ? { color: colorObjOrString }
          : colorObjOrString;
        const key = `${ c.color }${ c.title || '' }`;
        // @ts-ignore
        const selected = compareHex(hex, colorObjOrString) && COLOR_PICKER_PRESET_COLORS.selected;
        return (
          <div
            key={ key }
            className={ styles.swatchWrap }
          >
            <Swatch
              { ...c }
              style={ { ...COLOR_PICKER_PRESET_COLORS.swatch, ...selected } }
              onClick={ handleClick }
              onHover={ onSwatchHover }
              focusStyle={ COLOR_PICKER_PRESET_COLORS.focus }
            />
          </div>
        );
      }) }
    </div>
  );
};

export default ColorPickerPresetColors;
