import tinycolor from 'tinycolor2';
import isUndefined from 'lodash/isUndefined';
import ColorType from './ColorType';

/**
 * Check color hex code is valid
 * @param {String} hex
 * @returns {Boolean}
 */
export const isValidHex = (hex) => {
  if (ColorType.isHexTransparent(hex)) {
    return true;
  }
  const lh = (String(hex).charAt(0) === '#') ? 1 : 0;
  return hex.length !== (4 + lh) && hex.length < (7 + lh) && tinycolor(hex).isValid();
};

/**
 * @param {Object} hexOne
 * @param {String} hexOne.hex
 * @param {Object} hexOne.rgb
 * @param {Object} hexOne.hsl
 * @param {String} hexSecond
 * @param {String} hexSecond.hex
 * @param {Object} hexSecond.rgb
 * @param {Object} hexSecond.hsl
 * @returns {Boolean}
 */
export const compareHex = (hexOne, hexSecond) => getHexCode(hexOne) === getHexCode(hexSecond);

/**
 * Get hex code from color types
 * @param {String|{hex: String, rgb: Object, hsl: Object}} color
 * @param {String} color.hex
 * @param {Object} color.rgb
 * @param {Object} color.hsl
 * @returns {String}
 */
export const getHexCode = (color) => tinycolor(color).toHex();

/**
 * Calculate pointer position for color slider
 * @param {Object} e
 * @param {String} direction
 * @param {Object} hsl
 * @param {Object} container
 * @returns {Object|undefined}
 */
export const calculateChange = (e, direction, hsl, container) => {
  const containerWidth = container.clientWidth;
  const containerHeight = container.clientHeight;
  const x = typeof e.pageX === 'number' ? e.pageX : e.touches[0].pageX;
  const y = typeof e.pageY === 'number' ? e.pageY : e.touches[0].pageY;
  const left = x - (container.getBoundingClientRect().left + window.pageXOffset);
  const top = y - (container.getBoundingClientRect().top + window.pageYOffset);

  if (direction === 'vertical') {
    let h;
    if (top < 0) {
      h = 359;
    } else if (top > containerHeight) {
      h = 0;
    } else {
      const percent = -((top * 100) / containerHeight) + 100;
      h = ((360 * percent) / 100);
    }

    if (hsl.h !== h) {
      return {
        h,
        s: hsl.s,
        l: hsl.l,
        a: hsl.a,
        source: ColorType.getHsl(),
      };
    }
  } else {
    let h;
    if (left < 0) {
      h = 0;
    } else if (left > containerWidth) {
      h = 359;
    } else {
      const percent = (left * 100) / containerWidth;
      h = ((360 * percent) / 100);
    }

    if (hsl.h !== h) {
      return {
        h,
        s: hsl.s,
        l: hsl.l,
        a: hsl.a,
        source: ColorType.getHsl(),
      };
    }
  }
  return null;
};

/**
 * @param {Function} onChange
 * @param {Object} rgb
 * @param {Object} hsl
 * @returns {Function}
 */
export const getColorUpdater = (onChange, { rgb, hsl }) => (data, e) => {
  if (data.hex) {
    isValidHex(data.hex) && onChange({
      hex: data.hex.toLowerCase(),
      source: ColorType.getHex(),
    }, e);
  } else if (data.r || data.g || data.b) {
    onChange({
      r: data.r || rgb.r,
      g: data.g || rgb.g,
      b: data.b || rgb.b,
      a: rgb.a,
      source: ColorType.getRgb(),
    }, e);
  } else if (data.a) {
    if (data.a < 0) {
      data.a = 0;
    } else if (data.a > 100) {
      data.a = 100;
    }

    data.a /= 100;
    onChange({
      h: hsl.h,
      s: hsl.s,
      l: hsl.l,
      a: data.a,
      source: ColorType.getRgb(),
    }, e);
  } else if (data.h || data.s || data.l) {
    if (typeof (data.s) === 'string' && data.s.includes('%')) { data.s = data.s.replace('%', ''); }
    if (typeof (data.l) === 'string' && data.l.includes('%')) { data.l = data.l.replace('%', ''); }

    if (data.s === 1) {
      data.s = 0.01;
    } else if (data.l === 1) {
      data.l = 0.01;
    }

    onChange({
      h: data.h || hsl.h,
      s: Number(!isUndefined(data.s) ? data.s : hsl.s),
      l: Number(!isUndefined(data.l) ? data.l : hsl.l),
      source: ColorType.getHsl(),
    }, e);
  }
};

/**
 * Auto fill preset color
 * @param {Function} onChange
 * @param {String} value
 * @param {String} preSetColor
 * @returns {Function}
 */
export const autofillColor = ({ onChange, value }, preSetColor) => {
  if (
    ColorType.getFillColors().includes(value) && tinycolor(preSetColor).isValid()
  ) {
    if (tinycolor(preSetColor).isDark()) {
      onChange(ColorType.getLightColor());
    } else {
      onChange(ColorType.getDarkColor());
    }
  }
};

/**
 * set default color
 * @param {function} onChange
 * @param {String} preSetColor
 * @returns {Function}
 */

export const setDefaultColor = ({ onChange }, preSetColor) => {
  if (tinycolor(preSetColor).isValid()) onChange(preSetColor);
};
