import * as constants from '@/condor/ui/common/constants';

export const NAVIGATION_ITEMS = [
  // ACCOUNTS
  {
    title: 'Accounts',
    id: 'accounts',
    href: constants.CONDOR_ACCOUNTS_BASE_URL,
  },
  // CONTACTS
  {
    title: 'Contacts',
    id: 'contacts',
    href: constants.CONDOR_CONTACTS_BASE_URL,
  },
  // ADMIN
  {
    title: 'Admin',
    id: 'admin',
    href: constants.CONDOR_ADMIN_BASE_URL,
  },
  {
    title: 'Account Types',
    id: 'account_types',
    href: constants.CONDOR_ACCOUNT_TYPES_BASE_URL,
  },
  {
    title: 'Application Blacklist',
    id: 'application_blacklist',
    href: constants.CONDOR_APPLICATION_BLACKLIST_BASE_URL,
  },
  {
    title: 'Banner Display',
    id: 'banners_list',
    href: constants.CONDOR_ADMIN_BANNER_LIST_BASE_URL,
  },
  {
    title: 'Global Blacklist',
    id: 'global_blacklist',
    href: constants.CONDOR_GLOBAL_BLACKLIST_BASE_URL,
  },
  // TECHNICAL
  {
    title: 'Technical Support',
    id: 'technical',
    href: constants.CONDOR_TECHNICAL_BASE_URL,
  },
  {
    title: 'Drs EL Offset',
    id: 'event_log_offset',
    href: constants.CONDOR_TECHNICAL_DRS_EVENT_LOG_OFFSET_URL,
  },
  {
    title: 'Finsight EL Offset',
    id: 'finsight_event_log_offset',
    href: constants.CONDOR_TECHNICAL_FINSIGHT_EVENT_LOG_OFFSET_URL,
  },
  {
    title: 'Finsight S EL Offset',
    id: 'finsight_strats_event_log_offset',
    href: constants.CONDOR_TECHNICAL_FINSIGHT_STRATS_EVENT_LOG_OFFSET_URL,
  },
  {
    title: 'Finsight A EL Offset',
    id: 'finsight_analytics_event_log_offset',
    href: constants.CONDOR_TECHNICAL_FINSIGHT_ANALYTICS_EVENT_LOG_OFFSET_URL,
  },
  {
    title: 'DmPortal EL Offset',
    id: 'dm_portal_event_log_offset',
    href: constants.CONDOR_TECHNICAL_DM_PORTAL_EVENT_LOG_OFFSET_URL,
  },
  {
    title: 'Condor EL Offset',
    id: 'condor_event_store_offset',
    href: constants.CONDOR_TECHNICAL_CONDOR_EVENT_LOG_OFFSET_URL,
  },
  {
    title: 'Databases',
    id: 'databases',
    href: constants.CONDOR_TECHNICAL_DATABASES_URL,
  },
  // ENHANCED TAGGING
  {
    title: 'Enhanced Tagging',
    id: 'tagging',
    href: constants.CONDOR_ENHANCED_TAGGING_BASE_URL,
  },
  {
    title: 'Deal Roadshow',
    id: 'tagging_deal_roadshows',
    href: constants.CONDOR_ENHANCED_TAGGING_DRS,
  },
  {
    title: 'Applications',
    id: 'prod_label',
  },
  // DEAL ROADSHOW
  {
    title: 'Deal Roadshow',
    id: 'roadshows',
    href: constants.CONDOR_DRS_ROADSHOWS_URL,
  },
  // EVERCALL
  {
    title: 'Evercall',
    id: 'evercall',
    href: constants.CONDOR_EVERCALL_BASE_URL,
  },
  // FINSIGHT
  {
    title: 'Finsight',
    id: 'finsight',
    href: constants.CONDOR_FINSIGHT_BASE_URL,
  },
  {
    title: 'Companies',
    id: 'companies',
    href: constants.CONDOR_FINSIGHT_COMPANIES_BASE_URL,
  },
  {
    title: 'Deal - No Px Date',
    id: 'dealsMissingPxDate',
    href: constants.CONDOR_FINSIGHT_DEAL_NO_PRICING_DATE_BASE_URL,
  },
  {
    title: 'SEC Filings',
    id: 'filings',
    href: constants.CONDOR_FINSIGHT_FILINGS_BASE_URL,
  },
  {
    title: 'Subscribers',
    id: 'subscribers',
    href: constants.CONDOR_FINSIGHT_SUBSCRIBERS_BASE_URL,
  },
  {
    title: 'TRACE Manager',
    id: 'trace_manager',
    href: constants.CONDOR_FINSIGHT_TRACE_BASE_URL,
  },
  {
    title: 'Uplder - B&I',
    id: 'bond_import',
    href: constants.CONDOR_FINSIGHT_IMPORT_BOND_BASE_URL,
  },
  {
    title: 'Uplder - Companies',
    id: 'company_import',
    href: constants.CONDOR_FINSIGHT_IMPORT_COMPANY_BASE_URL,
  },
  {
    title: 'Uplder - Deals',
    id: 'deal_import',
    href: constants.CONDOR_FINSIGHT_IMPORT_DEAL_BASE_URL,
  },
  // Finsight Manager
  {
    title: 'Finsight Manager',
    id: 'finsight_manager',
    href: constants.CONDOR_FINSIGHT_MANAGER_BASE_URL,
  },
  {
    title: 'Analytics',
    id: 'finsight_manager_users_analytics',
    href: constants.CONDOR_FINSIGHT_MANAGER_ANALYTICS_URL,
  },
  {
    title: 'Manage Plans',
    id: 'finsight_manager_manage_plans',
    href: constants.CONDOR_FINSIGHT_MANAGER_MANAGE_PLANS_BASE_URL,
  },
  // DATAROOMS
  {
    title: 'DealVDR',
    id: 'dealvdr',
    href: constants.CONDOR_DEALVDR_LIST_URL,
  },
  {
    title: 'InvestorSet',
    id: 'investorset',
    href: constants.CONDOR_INVESTORSET_LIST_URL,
  },
  {
    title: 'VeriSend',
    id: 'verisend',
    href: constants.CONDOR_VERISEND_LIST_URL,
  },
  {
    title: '17g5.com',
    id: 'dealvdr_17g5',
    href: constants.CONDOR_17G5_LIST_URL,
  },
  // OpenMarket
  {
    title: 'OpenMarket',
    id: 'open_market',
    href: constants.CONDOR_FINSIGHT_OPEN_MARKET,
  },
];
