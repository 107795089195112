import { connect } from 'react-redux';
import { touch } from 'redux-form';
import CondorDatePicker from './CondorDatePicker';

const mapDispatchToProps = {
  reduxFormTouchField: (formName, fieldName) => touch(formName, fieldName),
};

export default connect(
  null,
  mapDispatchToProps,
)(CondorDatePicker);
