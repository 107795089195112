import React from 'react';
import { useTraceContext } from '@/condor/application/Trace/TraceContextProvider';
import SearchInput from '@/ui/shared/components/Form/Fields/SearchInput';

import styles from './tableSearch.scss';

export const TableSearch = () => {
  const { onSearch, query } = useTraceContext();

  return (
    <SearchInput
      onChange={ onSearch }
      value={ query }
      placeholder="Filter by Issuer or CUSIP"
      isNarrow
      isClearable
      inputClassName={ styles.searchInput }
    />
  );
};
