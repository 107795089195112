import { CURRENT_FACE_RANGE_PRESET } from '@/finsight/ui/components/bonds/common/BondsSidebar/constants';

/**
 * @param {String|Number} currentFaceId
 * @return {Object}
 */
export const getPresetCurrentFaceRangeValues = (currentFaceId) => {
  const { currentFaceRangeFromValue, currentFaceRangeToValue } = CURRENT_FACE_RANGE_PRESET.find(
    (item) => item.currentFaceRangeId === currentFaceId,
  );
  return {
    from: currentFaceRangeFromValue,
    to: currentFaceRangeToValue,
  };
};

/**
 * @param {Object} filters
 * @return {Array}
 */
export const prepareIncludedCurrentFaces = (filters) => {
  if (filters.currentFaceRangeIds && filters.currentFaceRangeIds.length) {
    return filters.currentFaceRangeIds.reduce((acc, item) => {
      const { from, to } = getPresetCurrentFaceRangeValues(item);
      acc.push({
        includedCurrentFaceFrom: from,
        includedCurrentFaceTo: to,
      });
      return acc;
    }, []);
  }
  return [{
    includedCurrentFaceFrom: filters.currentFaceRange.range.min,
    includedCurrentFaceTo: filters.currentFaceRange.range.max,
  }];
};

/**
 * @param {Object} includedCurrentFace
 * @return {Object}
 */
export const findIncludedCurrentFaceInPresets = (includedCurrentFace) => CURRENT_FACE_RANGE_PRESET.find(
  (preset) => preset.currentFaceRangeFromValue === includedCurrentFace.includedCurrentFaceFrom &&
              preset.currentFaceRangeToValue === includedCurrentFace.includedCurrentFaceTo,
);

/**
 * @param {Array} includedCurrentFaces
 * @return {Boolean}
 */
export const isCurrentFacesFromPresets = (includedCurrentFaces) => includedCurrentFaces.length > 1;
