import actionTypes from './actionTypes';
import createAction from '@/Framework/State/Redux/createAction';
import createAsyncRequest from '@/Framework/State/Redux/createAsyncRequest';
import ImportRepository from '@/finsight/infrastructure/repository/ImportRepository';
import selectors from './selectors';
import { NotificationManager } from '@/ui/shared/components/Notification';
import { getCollectionActions } from '@/Framework/State/Redux/collectionFactory';

/**
 * @param {String} importId
 */
export const deleteImport = (importId) => (dispatch, getState) => {
  const importRepository = getState().container.get(ImportRepository);

  return dispatch(createAsyncRequest({
    method: importRepository.deleteDealImport,
    types: [
      actionTypes.DELETE_CONDOR_FINSIGHT_IMPORT_DEAL_AUDIT_TRAIL,
      actionTypes.DELETE_CONDOR_FINSIGHT_IMPORT_DEAL_AUDIT_TRAIL_SUCCESS,
      actionTypes.DELETE_CONDOR_FINSIGHT_IMPORT_DEAL_AUDIT_TRAIL_ERROR,
    ],
    payload: {
      importId,
    },
    callbacks: {
      onSuccess: ({ dispatch, getState }) => {
        const deletedItem = selectors.getDeleteItem(getState());
        dispatch(changeDeleteImportModalVisible(false));
        dispatch(resetSelectedImport());
        NotificationManager.success(
          `You have successfully deleted import # ${ deletedItem.importNumber }
          with ${ deletedItem.dealCount } deal${ deletedItem.dealCount > 1 ? 's' : '' }.`,
        );
        dispatch(getDealsImportByFilter());
      },
    },
  }));
};

/**
 * @param {Boolean} payload
 */
export const changeDeleteImportModalVisible = (payload) => createAction(
  actionTypes.CHANGE_CONDOR_FINSIGHT_IMPORT_DEAL_AUDIT_TRAIL_DELETE_MODAL_VISIBLE,
  payload,
);

/**
 * @param {String} payload
 */
export const setSelectedImport = (payload) => createAction(
  actionTypes.SET_CONDOR_FINSIGHT_IMPORT_DEAL_AUDIT_TRAIL_DELETE_ITEM,
  payload,
);

export const resetSelectedImport = () => createAction(
  actionTypes.SET_CONDOR_FINSIGHT_IMPORT_DEAL_AUDIT_TRAIL_DELETE_ITEM,
  {},
);

export const {
  getCollection: getDealsImportCollection,
  sort,
  itemsPerPage,
  paginate,
  reset: resetDealsImportCollection,
} = getCollectionActions(
  actionTypes,
  (payload, dispatch, getState) => {
    const importRepository = getState().container.get(ImportRepository);
    return importRepository.dealsImport(payload);
  },
);

export const getDealsImportByFilter = () => async (dispatch, getState) => {
  const state = getState();

  dispatch(getDealsImportCollection({
    perPage: selectors.getPerPage(state),
    page: selectors.getPage(state),
    sortBy: selectors.getSortBy(state),
    sortOrder: selectors.getSortOrder(state),
  }));
};
