import { connect } from 'react-redux';
import {
  addGlobalBlacklistItemsWithValidation,
  setGlobalBlacklistAddItemsInputState,
} from '@/condor/application/actions/blacklists/global/actions';
import selectors from '@/condor/application/actions/blacklists/global/selectors';
import AddItemsInput from '@/condor/ui/common/Blacklist/AddItemsInput';

const mapStateToProps = (state) => (
  {
    inputValue: selectors.getAddInputValue(state),
    errors: selectors.getAddingErrors(state),
    isAdding: selectors.isAdding(state),
    isFocused: selectors.isAddInputFocused(state),
  }
);

const mapDispatchToProps = {
  onInputChange: (e) => setGlobalBlacklistAddItemsInputState({ value: e.target.value }),
  onInputFocus: () => setGlobalBlacklistAddItemsInputState({ errors: [], isFocused: true }),
  onInputBlur: () => setGlobalBlacklistAddItemsInputState({ isFocused: false }),
  onAddClick: addGlobalBlacklistItemsWithValidation,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddItemsInput);
