import { ReactNode } from 'react';
import cn from 'classnames';

import pageStyles from '@/ui/shared/styles/page.scss';
import headersStyles from '@/ui/shared/styles/headers.scss';
import styles from './stickyHeader.scss';

interface IProps {
  isVisible: boolean,
  title: string,
  buttons: ReactNode,
  showDefaultHeader?: boolean,
  showStickyHeader?: boolean,
}

const StickyHeader = ({ buttons, isVisible, title, showDefaultHeader = true, showStickyHeader = true }: IProps) => {
  const headerCls = cn(styles.stickyHeader, {
    [styles.visible]: isVisible,
  });

  return (
    <>
      { showStickyHeader && (
        <div className={ headerCls }>
          <div className={ styles.content }>
            { title }
            <div className={ styles.buttons } data-test="stickyHeaderButtons">
              { buttons }
            </div>
          </div>
        </div>
      ) }
      { showDefaultHeader && (
        <div className={ cn(pageStyles.pageHeader) }>
          <div className={ cn(pageStyles.pageHeaderTitle, headersStyles.isH2) } data-test="pageHeaderTitle">
            { title }
          </div>
        </div>
      ) }
    </>
  );
};

export default StickyHeader;
