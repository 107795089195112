import React from 'react';
import flatten from 'lodash/flatten';
import ReduxFormSelect from '@/ui/shared/components/Form/Select/legacy/ReduxFormSelect';

interface IProps {
  collection: any[],
  dataTest: string,
}

const Subindustry = (props: IProps) => {
  const options = flatten(props.collection.map((industry) => industry.subindustryList.map((subIndustry) => ({
    value: subIndustry.id,
    label: `${ industry.name } - ${ subIndustry.name }`,
  }))));

  // @ts-ignore
  return <ReduxFormSelect { ...props } options={ options } dataTest={ props.dataTest } />;
};

export default Subindustry;
