export function getCompanies(state) {
  return state.companies.data.collection;
}

export function isCompaniesFetching(state) {
  return state.companies.data.isCollectionFetching;
}

export function getCompaniesTotalCount(state) {
  return state.companies.data.totalCount;
}
