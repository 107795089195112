import React from 'react';
import DatePeriodComponent from '@/ui/shared/modules/Filters/FiltersPanel/DatePeriod';
import { useEnhancedTaggingFiltersContext } from '@/condor/application/EnhancedTaggingContexts/EnhancedTaggingFiltersContext';
import { useDatePeriod } from '@/Framework/dataHelpers/hooks/useDatePeriod';

interface IProps {
  onDateFromChange: (value: number) => void,
  onDateToChange: (value: number) => void,
  dateFrom: number | null,
  dateTo: number | null,
}

const DatePeriod = ({ onDateFromChange, onDateToChange, dateFrom, dateTo }: IProps) => {
  const {
    filtersData: { dateMax, dateMin, timeZone },
  } = useEnhancedTaggingFiltersContext();

  const datePeriodProps = useDatePeriod({
    dateToMax: dateMax,
    dateFromMin: dateMin,
    timeZone,
    dateFrom: dateFrom ?? dateMin,
    dateTo: dateTo ?? dateMax,
    onDateFromChange,
    onDateToChange,
  });

  return <DatePeriodComponent { ...datePeriodProps } />;
};

export default DatePeriod;
