import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { assetClassChanged } from '@/condor/application/actions/finsight/import/bond/actions';
import AssetClasses from './AssetClasses';
import { toSlug } from '@/Framework/url/helpers/toSlug';
import metadataSelectors from '@/finsight/application/actions/bonds/metadata/selectors';
import bwicSelectors from '@/condor/application/actions/finsight/import/bond/bwic/selectors';
import inventorySelectors from '@/condor/application/actions/finsight/import/bond/inventory/selectors';

const mapStateToProps = (state) => (
  {
    assetClassCollection: metadataSelectors.getAssetClassCollection(state),
    isCollectionsFetching: bwicSelectors.isFetching(state) || inventorySelectors.isFetching(state),
  }
);

const mapDispatchToProps = (dispatch, ownProps) => (
  {
    handleAssetClassClick: (assetClass) => {
      const { assetClass: assetClassSelected } = ownProps.match.params;
      const sluggedAssetClassAbbr = toSlug(assetClass.abbreviation);

      if (sluggedAssetClassAbbr !== assetClassSelected) {
        dispatch(assetClassChanged(assetClass, ownProps.match, ownProps.baseUrl));
      }
    },
  }
);

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AssetClasses));
