import React, { memo } from 'react';
import CheckboxFiltersBlock from '@/condor/ui/common/Layout/CheckboxFiltersBlock';
import { useSubscribersContext } from '@/condor/application/FinsightManager/Subscribers';
import { FilterNames } from '@/condor/application/FinsightManager/Subscribers/SubscribersContext';

import styles from '../subscribers.scss';

const CheckboxFiltersPlans = () => {
  const {
    includeStarter,
    includePremium,
    includeProfessional,
    setPlanFilter,
  } = useSubscribersContext();

  const filters = [
    {
      label: 'Starter',
      dataTest: 'starter',
      checked: includeStarter,
      onClick: () => setPlanFilter(FilterNames.IncludeStarter),
    },
    {
      label: 'Premium',
      dataTest: 'premium',
      checked: includePremium,
      onClick: () => setPlanFilter(FilterNames.IncludePremium),
    },
    {
      label: 'Professional',
      dataTest: 'professional',
      checked: includeProfessional,
      onClick: () => setPlanFilter(FilterNames.IncludeProfessional),
    },
  ];
  return (
    <CheckboxFiltersBlock items={ filters } className={ styles.plansFilters } />
  );
};

export default memo(CheckboxFiltersPlans);
