import { connect } from 'react-redux';

import { Paginator } from '@dealroadshow/uikit';

import { itemsPerPage, paginate, getDealsByFilter } from '@/condor/application/actions/finsight/dealNoPricingDate/actions';
import selectors from '@/condor/application/actions/finsight/dealNoPricingDate/selectors';

const mapStateToProps = (state) => (
  {
    totalCount: selectors.getTotalCount(state),
    page: selectors.getPage(state),
    perPage: selectors.getPerPage(state),
  }
);

const mapDispatchToProps = (dispatch) => (
  {
    onItemsPerPageChange: ({ value: perPage }) => {
      dispatch(itemsPerPage(perPage));
      dispatch(getDealsByFilter());
    },
    onPageChange: ({ selected: page }) => {
      dispatch(paginate(page));
      dispatch(getDealsByFilter());
    },
  }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Paginator);
