import { useDIContext } from '@/Framework/DI/DIContext';
import SessionStorageRepository from '@/Framework/browser/storage/SessionStorageRepository';
import { FiltersType } from './types';

const ENHANCED_TAGGING_FILTERS = 'enhancedTaggingFilters';

export const useEnhancedTaggingFiltersStorage = () => {
  const { container } = useDIContext();
  const sessionStorageRepository = container.get<SessionStorageRepository>(SessionStorageRepository);

  const getFiltersFromStorage = (): FiltersType | null => {
    const filters = sessionStorageRepository.getItem(ENHANCED_TAGGING_FILTERS);
    return filters ? JSON.parse(filters) : null;
  };

  const saveFiltersToStorage = (filters: FiltersType): void => {
    sessionStorageRepository.setItem(ENHANCED_TAGGING_FILTERS, JSON.stringify(filters));
  };

  return {
    getFiltersFromStorage,
    saveFiltersToStorage,
  };
};
