import React, { PropsWithChildren } from 'react';
import cn from 'classnames';
import { Icon, IconType } from '@dealroadshow/uikit';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import styles from './detailsSections.scss';

interface IProps {
  uniqueIdentifier: string,
  rowCls?: string,
}

const SectionItem = ({ uniqueIdentifier, children, rowCls }: PropsWithChildren<IProps>) => {
  const { active, attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable(
    { id: uniqueIdentifier } as Parameters<typeof useSortable>[0],
  );

  const style = {
    ...(active && {
      transition,
      transform: CSS.Translate.toString(transform),
    }),
    ...(isDragging && {
      position: 'relative',
      zIndex: 1,
    }),
  };

  return (
    <div
      ref={ setNodeRef }
      // @ts-ignore
      style={ style }
    >
      <div className={ cn(styles.detailsSectionItemRow, rowCls) }>
        <span
          className={ styles.detailsSectionDragIconContainer }
          { ...listeners }
          { ...attributes }
        >
          <Icon type={ IconType.draggable } />
        </span>
        { children }
      </div>
    </div>
  );
};

export default SectionItem;
