import { formValueSelector } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getCustomModal,
  resetCustomModal,
  saveCustomModal,
} from '../actions';
import CustomModalForm from './CustomModalForm';

const selector = formValueSelector('customModalForm');

function mapStateToProps(state) {
  return {
    initialValues: state.dealRoadshows.roadshow.customModal.initialValues,
    isFetching: state.dealRoadshows.roadshow.customModal.isFetching,
    isEnableCustomModal: !!selector(state, 'enableCustomModal'),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getData: getCustomModal,
    resetData: resetCustomModal,
    onSubmit: saveCustomModal,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomModalForm);
