import { connect } from 'react-redux';
import BannersList from './BannersList';
import {
  getBannersPositionsList,
  deleteBanner,
  submitBannerForm,
} from '@/condor/application/actions/banners/actions';
import { getBannersData } from '@/condor/application/actions/banners/selectors';

const mapStateToProps = (state) => ({
  banners: getBannersData(state),
});

const mapDispatchToProps = {
  getBannersPositionsList,
  deleteBanner,
  submit: submitBannerForm,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BannersList);
