import React, { useState } from 'react';
import { RadioGroup, Radio } from '@dealroadshow/uikit';
import SearchInput from '@/ui/shared/components/Form/Input/SearchInput/SearchInput';
import { CONDOR_EVERCALL_LIST_FILTERS_RADIO_OPTIONS } from './constants';
import { useCallsCollectionContext } from '@/evercall/application/condor/call/collection/CallsCollectionContext';
import styles from './callsList.scss';

const CallsListFilters = () => {
  const [searchValue, setSearchValue] = useState('');

  const {
    state: {
      isFetching,
      selectedFilter,
    },
    debouncedSearch,
    setSelectedFilter,
  } = useCallsCollectionContext();

  return (
    <div className={ styles.filtersContainer }>
      <RadioGroup
        name="evercallsListFiltersRadioGroup"
        disabled={ isFetching }
        onChange={ setSelectedFilter }
        isNarrow
        selectedValue={ selectedFilter }
        dataTest="evercallsListFiltersRadioGroup"
      >
        {
          CONDOR_EVERCALL_LIST_FILTERS_RADIO_OPTIONS.map((option) => (
            <Radio
              dataTest={ option.value }
              key={ option.value }
              value={ option.value }
              label={ option.label }
            />
          ))
        }
      </RadioGroup>
      <SearchInput
        isNarrow
        dataTest="searchField"
        name="search"
        inputClassName={ styles.searchFilterInput }
        value={ searchValue }
        placeholder="Filter by Name, Group Name, Roadshow ID or Speaker/Participant ID"
        onChange={ (event) => {
          setSearchValue(event.target.value);
          debouncedSearch(event.target.value);
        } }
      />
    </div>
  );
};

export default CallsListFilters;
