export enum ProfileAccessTypes {
  LOGIN = 'login',
  LOGIN_WITH_WHITELIST = 'login_whitelist',
}

export class ProfileAccessType {
  static isLogin(type: ProfileAccessTypes) {
    return type === ProfileAccessTypes.LOGIN;
  }

  static isLoginWithWhitelist(type: ProfileAccessTypes) {
    return type === ProfileAccessTypes.LOGIN_WITH_WHITELIST;
  }
}
