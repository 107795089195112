import { useLocation } from 'react-router';
import EventForm from '../EventForm';

const CreateEvent = () => {
  const profile = useLocation()?.state?.profile;

  return <EventForm initProfile={ profile } />;
};

export default CreateEvent;
