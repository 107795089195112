import { getCollectionActions } from '@/Framework/State/Redux/collectionFactory';
import UserRepository from '@/users/infrastructure/repository/UserRepository';
import actionTypes from './actionTypes';
import selectors from './selectors';
import filterSelectors from '../filters/selectors';

export const {
  getCollection: getContacts,
  sort,
  itemsPerPage,
  paginate,
  setSearchQuery: setContactsSearchQuery,
  reset: resetContacts,
} = getCollectionActions(
  actionTypes,
  (payload, dispatch, getState) => {
    const userRepository: UserRepository = getState().container.get(UserRepository);
    return userRepository.list(payload);
  },
);

export const getContactsByFilter = () => async (dispatch, getState) => {
  /**
   * @param {String} key
   * @param {Boolean} value
   * @param {Boolean} oppositeValue
   * @return {Object}
   */
  const getCheckboxFilterValue = (key, value, oppositeValue) => (value === oppositeValue ? {} : { [key]: !!value });
  const state = getState();
  const perPage = selectors.getPerPage(state);

  dispatch(getContacts(
    {
      aggregateCompany: true,
      limit: perPage,
      offset: (selectors.getPage(state) - 1) * perPage,
      query: selectors.getSearchQuery(state),
      orderBy: selectors.getSortBy(state),
      orderAsc: selectors.getSortOrder(state) === 'asc',
      ...getCheckboxFilterValue('banned', filterSelectors.isBanned(state), filterSelectors.isUnbanned(state)),
      ...getCheckboxFilterValue('emailVerified', filterSelectors.isVerified(state), filterSelectors.isUnverified(state)),
      ...getCheckboxFilterValue('activated', filterSelectors.isActivated(state), filterSelectors.isUnactivated(state)),
    },
  ));
};
