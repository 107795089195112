import { useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import TitleRepository from '@/users/infrastructure/repository/TitleRepository';

export default function useTitles() {
  const { container } = useDIContext();
  const titleRepository = container.get<TitleRepository>(TitleRepository);

  const [isCollectionFetching, setIsCollectionFetching] = useState(false);
  const [collection, setCollection] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  const [isItemFetching, setIsItemFetching] = useState(false);
  const [item, setItem] = useState({});

  const getTitles = async () => {
    setIsCollectionFetching(true);

    try {
      const titles = await titleRepository.list();
      setCollection(titles?.collection);
      setTotalCount(titles?.totalCount);
      setIsCollectionFetching(false);
    } catch (err) {
      setIsCollectionFetching(false);
    }
  };

  const getTitleById = async (id?: string) => {
    setIsItemFetching(true);

    try {
      const title = await titleRepository.getById(id);
      setItem(title);
      setIsItemFetching(false);
    } catch (err) {
      setIsItemFetching(false);
    }
  };

  const getTitleByName = async (name?: string) => {
    setIsItemFetching(true);

    try {
      const title = await titleRepository.getByName(name);
      setItem(title);
      setIsItemFetching(false);
    } catch (err) {
      setIsItemFetching(false);
    }
  };

  return {
    isCollectionFetching,
    collection,
    totalCount,
    isItemFetching,
    item,
    getTitles,
    getTitleById,
    getTitleByName,
  };
}
