import React from 'react';
import cn from 'classnames';
import { Editor } from 'slate';
import { useSlate } from 'slate-react';
import { TToolbarItemFormat } from '../interfaces';
import styles from '../toolbar.scss';

interface IProps {
  format: TToolbarItemFormat,
  options: string[],
  defaultValue: string,
  className?: string,
  mapOptionToMarkValue?: (value: string) => string,
  mapMarkValueToOption?: (value: string) => string,
}

const Dropdown = (
  {
    format,
    options,
    defaultValue,
    className = '',
    mapOptionToMarkValue = (selectedOption: string) => `${ selectedOption }px`,
    mapMarkValueToOption = (value) => value?.replace('px', '') || '',
  }: IProps,
) => {
  const editor = useSlate();

  const changeMarkData = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    Editor.removeMark(editor, format);
    Editor.addMark(editor, format, mapOptionToMarkValue(event.target.value));
  };

  const marks = Editor.marks(editor);
  const value = (marks && marks[format]) ? mapMarkValueToOption(marks[format]) : defaultValue;

  return (
    <select
      className={ cn(styles.select, className) }
      value={ value }
      onChange={ changeMarkData }
      data-test={ `${ format }Dropdown` }
    >
      { options.map((optionValue) => (
        <option
          key={ optionValue }
          value={ optionValue }
        >
          { optionValue }
        </option>
      )) }
    </select>
  );
};

export default Dropdown;
