import React, { useCallback, useState } from 'react';
import {
  useEnhancedTaggingFiltersContext,
  FiltersType,
} from '@/condor/application/EnhancedTaggingContexts/EnhancedTaggingFiltersContext';
import CheckboxSection from './CheckboxSection';
import TransactionType from './TransactionType';
import { MAP_FILTERS_NAME } from '../constants';

interface IProps {
  fieldComponent: any,
  values: FiltersType,
  setFieldValue: (fieldName: string, value: any) => void,
}

const Sections = ({ fieldComponent, values, setFieldValue }: IProps) => {
  const [hiddenSections, setHiddenSections] = useState<string[]>([]);

  const {
    filtersData: { statuses, taggedBy, untaggedBy },
  } = useEnhancedTaggingFiltersContext();

  const handleSectionToggle = useCallback((activeKey: string) => {
    setHiddenSections((sections) => {
      if (sections.includes(activeKey)) {
        return sections.filter((section) => section !== activeKey);
      }

      return [...sections, activeKey];
    });
  }, []);

  const getSectionProps = (sectionId: string) => ({
    sectionId,
    isVisible: !hiddenSections.includes(sectionId),
    onSectionToggle: handleSectionToggle,
    setFieldValue: (value: any) => setFieldValue(sectionId, value),
    fieldComponent,
    filterName: sectionId,
    selected: values[sectionId] || [],
    sectionTitle: MAP_FILTERS_NAME[sectionId] ?? sectionId,
  });

  return (
    <>
      <CheckboxSection columnsCount={ 3 } options={ statuses } { ...getSectionProps('statuses') } />
      <CheckboxSection columnsCount={ 3 } options={ untaggedBy } { ...getSectionProps('untaggedBy') } />
      <CheckboxSection columnsCount={ 3 } options={ taggedBy } { ...getSectionProps('taggedBy') } />
      <TransactionType { ...getSectionProps('transactionTypeIds') } />
    </>
  );
};

export default Sections;
