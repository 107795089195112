import React from 'react';
import Head from 'next/head';
import favicon16 from '@/dealroadshow/ui/common/Favicon/assets/favicon-16.png';
import favicon32 from '@/dealroadshow/ui/common/Favicon/assets/favicon-32.png';
import favicon64 from '@/dealroadshow/ui/common/Favicon/assets/favicon-64.png';
import favicon128 from '@/dealroadshow/ui/common/Favicon/assets/favicon-128.png';
import drsIosIcon120 from '@/dealroadshow/ui/common/Favicon/assets/drs-ios-icon-120.png';
import drsIosIcon152 from '@/dealroadshow/ui/common/Favicon/assets/drs-ios-icon-152.png';
import drsIosIcon167 from '@/dealroadshow/ui/common/Favicon/assets/drs-ios-icon-167.png';
import drsIosIcon180 from '@/dealroadshow/ui/common/Favicon/assets/drs-ios-icon-180.png';

const Favicon = () => (
  <Head>
    <link rel="icon" href={ favicon16 } sizes="16x16" type="image/x-icon" />
    <link rel="icon" href={ favicon32 } sizes="32x32" type="image/x-icon" />
    <link rel="icon" href={ favicon64 } sizes="64x64" type="image/x-icon" />
    <link rel="icon" href={ favicon128 } sizes="128x128" type="image/x-icon" />
    <link rel="apple-touch-icon" href={ drsIosIcon120 } sizes="120x120" />
    <link rel="apple-touch-icon" href={ drsIosIcon152 } sizes="152x152" />
    <link rel="apple-touch-icon" href={ drsIosIcon167 } sizes="167x167" />
    <link rel="apple-touch-icon" href={ drsIosIcon180 } sizes="180x180" />
  </Head>
);

export default Favicon;
