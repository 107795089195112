// @ts-nocheck
import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { IconType } from '@dealroadshow/uikit';
import Input from '@/ui/shared/components/Form/Input';
import { FilterContext } from '../Filter';

import styles from '../filterGroup.scss';

class Search extends Component<{}, { searchText: string }> {
  // do not rewrite to functional component, until this is used with ref in
  // ui/condor/components/DealRoadshows/Roadshows/Roadshows.tsx
  constructor(props, context) {
    super(props, context);
    this.state = {
      searchText: '',
    };

    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.debounceValue = context.config.search.debounceValue;
    this.debouncedSearchHandler = debounce(
      (searchText) => {
        context.onChange('search', searchText);
      },
      this.debounceValue,
    );
  }

  handleFilterChange(evt) {
    let searchText = evt.target.value;
    this.setState({ searchText });
    const searchHandler = this.debounceValue
      ? this.debouncedSearchHandler
      : (searchText: string) => {
        searchText = searchText.trim();
        this.context.onChange('search', searchText);
      };

    searchHandler(searchText);
  }

  clearSearch = () => {
    this.setState({ searchText: '' });
    this.context.onChange('search', '');
  };

  /**
   * @return {ReactElement}
   */
  render() {
    return (
      <div className={ styles.searchWrp }>
        <Input
          type="text"
          placeholder={ this.context.config.search.placeholder }
          onChange={ this.handleFilterChange }
          iconType={ IconType.search }
          value={ this.state.searchText }
          isNarrow
          dataTest="filterGroupSearchInput"
        />
      </div>
    );
  }
}

Search.contextType = FilterContext;

export default Search;
