import { injectable, inject } from 'inversify';
import UploadDispatcher from '@/openMarket/application/DI/FileUpload/UploadDispatcher';

@injectable()
class AssetRepository {
  constructor(@inject(UploadDispatcher) private readonly uploadDispatcher: typeof UploadDispatcher) {}

  uploadAsset = (file: File) => {
    return this.uploadDispatcher.upload(file, { type: 'OPEN_MARKET_ASSET' });
  };
}

export default AssetRepository;
