import { v4 as uuid } from 'uuid';
import { format } from 'date-fns';
import { components as ReactSelectComponents } from 'react-select';

import decodeSymbols from '@/Framework/dataHelpers/string/decodeSymbols';
import { convertNumberWithCurrencyToNumber } from '@/ui/shared/helpers/convertTypes';
import utcToZonedDate from '@/Framework/DateTime/utcToZonedDate';
import getDateFromUnixTime from '@/Framework/DateTime/getDateFromUnixTime';
import { defaultOption, FieldsNames } from '@/openMarket/ui/components/Condor/Events/EventForm/constants';
import {
  IDisplayedProfile,
  IEventPayload,
  IRanking,
  IEventResponse,
} from '@/openMarket/domain/Condor/vo/IEvent';
import { IFormValues } from '@/openMarket/ui/components/Condor/Events/EventForm/interfaces';
import { ISelectOption } from '@dealroadshow/uikit';
import { FULL_DATE_FORMAT } from '@/Framework/DateTime/dateFormats';

export const ratedOptions = [
  { value: 'none', label: 'None' },
  { value: 'rated', label: 'Rated' },
  { value: 'unrated', label: 'Unrated' },
];

export const getUpdateFormValues = (data: IEventResponse): IFormValues => ({
  ...data,
  [FieldsNames.NAME]: decodeSymbols(data.name),
  [FieldsNames.DESCRIPTION]: decodeSymbols(data.description),
  [FieldsNames.DISPLAY_OF_PROFILE]: data.profiles.map(({ id, name }): ISelectOption => {
    return {
      value: id,
      label: decodeSymbols(name),
    };
  }),
  [FieldsNames.RANKING]: data.ranking ? { value: data.ranking.rankingId, label: data.ranking.name } : defaultOption,
  [FieldsNames.RATED]: data.rated ? { value: data.rated, label: data.rated } : defaultOption,
  [FieldsNames.ROADSHOW_ID]: data.roadshowId,
});

export const getInitialValues = (initProfile: { id: string, name: string }) => ({
  id: uuid(),
  published: false,
  [FieldsNames.NAME]: '',
  [FieldsNames.DESCRIPTION]: '',
  [FieldsNames.LOGO]: null,
  [FieldsNames.TILE_IMAGE]: null,
  [FieldsNames.TENOR]: null,
  [FieldsNames.EQUITY_TICKER]: '',
  [FieldsNames.RANKING]: defaultOption,
  [FieldsNames.RATED]: defaultOption,
  [FieldsNames.DISPLAY_OF_PROFILE]:
    initProfile
      ? [{ value: initProfile.id, label: decodeSymbols(initProfile.name) }]
      : [],
  [FieldsNames.ROADSHOW_ID]: '',
});

export const getModifyKeyNames = (data) => ({
  ...data,
  name: decodeSymbols(data.name),
  expectedPricingDate: format(
    utcToZonedDate(data.timezone.timeZone, getDateFromUnixTime(data?.finishedAt)),
    FULL_DATE_FORMAT,
  ),
  dealSize: data.dealSize ? convertNumberWithCurrencyToNumber(String(data.dealSize)) : '',
});

export const abbreviationValueRenderer = ({ data, ...otherProps }) => (
  // @ts-ignore
  <ReactSelectComponents.MultiValueLabel { ...otherProps }>{ data.label }</ReactSelectComponents.MultiValueLabel>
);

export const formatOptionLabel = ({ label }) => <div>{ label }</div>;

export const getParentsOptions = (options: IDisplayedProfile[]): ISelectOption[] => options.map(({ id, name }) => ({
    value: id,
    label: decodeSymbols(name),
  }));

export const getRankingOptions = (options: IRanking[]): ISelectOption[] => {
  const rankingOptions = options.map(({ rankingId, name }) => ({
    value: rankingId,
    label: name,
  }));

  return [{ value: 'none', label: 'None' }, ...rankingOptions];
};

export const getEventPayload = (values: IFormValues): IEventPayload => ({
  id: values.id,
  roadshow_id: values[FieldsNames.ROADSHOW_ID],
  name: values[FieldsNames.NAME],
  description: values[FieldsNames.DESCRIPTION],
  logo: values[FieldsNames.LOGO],
  tileImage: values[FieldsNames.TILE_IMAGE],
  tenor: Number(values[FieldsNames.TENOR]),
  equity_ticker: values[FieldsNames.EQUITY_TICKER],
  ranking_id: values[FieldsNames.RANKING].value === 'none' ? null : (values[FieldsNames.RANKING].value as string),
  rated: values[FieldsNames.RATED].value as string,
  published: values.published,
  profiles: values[FieldsNames.DISPLAY_OF_PROFILE].map(({ value }) => value) as string[],
});
