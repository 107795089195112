import { connect } from 'react-redux';
import { getFormValues, getFormInitialValues, getFormMeta, submit } from 'redux-form';
import selectors from '@/condor/application/actions/finsight/deal/selectors';
import filingSelectors from '@/condor/application/actions/finsight/filings/item/selectors';
import { CONDOR_DEAL_PROFILE_FORM_NAME } from '@/condor/ui/common/constants';
import SubmitDealModal from './SubmitDealModal';

const mapStateToProps = (state) => (
  {
    formMeta: getFormMeta(CONDOR_DEAL_PROFILE_FORM_NAME)(state),
    formValues: getFormValues(CONDOR_DEAL_PROFILE_FORM_NAME)(state),
    initialValues: getFormInitialValues(CONDOR_DEAL_PROFILE_FORM_NAME)(state),
    ratingAgencies: selectors.getRatingAgencies(state),
    isCreating: selectors.isCreating(state),
    isUpdating: selectors.isUpdating(state),
    isMapping: filingSelectors.isMapping(state),
  }
);

const mapDispatchToProps = {
  reduxFormSubmit: () => submit(CONDOR_DEAL_PROFILE_FORM_NAME),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubmitDealModal);
