import actionTypes from './actionTypes';
import selectors from './selectors';
import { extractDomainsFromStringWithValidation, getBlacklistActions } from '../helpers';
import createAsyncRequest from '@/Framework/State/Redux/createAsyncRequest';
import { getErrorMessage, getMessage } from '@/Framework/Message/Mapper/getMessage';
import GlobalBlacklistRepository from '@/users/infrastructure/repository/GlobalBlacklistRepository';
import { NotificationManager } from '@/ui/shared/components/Notification';
import { messageCodes } from '@/Framework/Message/messages';

export const {
  sort,
  itemsPerPage,
  paginate,
  getCollection: getGlobalBlacklist,
  setSearchQuery: setGlobalBlacklistSearchQuery,
  resetCollection: resetGlobalBlacklistCollection,

  setCurrentItem: setCurrentGlobalBlacklistItem,
  removeItem: deleteGlobalBlacklistItem,
  resetItem: resetGlobalBlacklistItem,
  setIsDeleteModalVisible: setGlobalBlacklistIsDeleteModalVisible,

  setAddItemsInputState: setGlobalBlacklistAddItemsInputState,
  resetAddItemsInputState: resetGlobalBlacklistAddItemsInputState,
} = getBlacklistActions(
  actionTypes,
  (payload, dispatch, getState) => {
    const globalBlacklistRepository = getState().container.get(GlobalBlacklistRepository);
    return globalBlacklistRepository.list(payload);
  },
  {
    method: (payload, dispatch, getState) => {
      const globalBlacklistRepository = getState().container.get(GlobalBlacklistRepository);
      return globalBlacklistRepository.deleteById(payload.id);
    },
    callbacks: {
      onSuccess: ({ payload }) => NotificationManager.success(
        getMessage(messageCodes.DELETE_GLOBAL_BLACKLIST_ITEM, { domain: payload.domain }),
      ),
    },
  },
);

export const getGlobalBlacklistByFilter = () => (dispatch, getState) => {
  const state = getState();
  const perPage = selectors.getPerPage(state);

  dispatch(getGlobalBlacklist(
    {
      limit: perPage,
      offset: (selectors.getPage(state) - 1) * perPage,
      orderBy: selectors.getSortBy(state),
      orderAsc: selectors.getSortOrder(state) === 'asc',
      query: selectors.getSearchQuery(state),
    },
  ));
};

/**
 * @param {Array} payload
 */
export const addGlobalBlacklistItems = (payload) => (dispatch, getState) => {
  const globalBlacklistRepository = getState().container.get(GlobalBlacklistRepository);

  return dispatch(createAsyncRequest(
    {
      payload,
      method: globalBlacklistRepository.create,
      types: [actionTypes.ADD_ITEMS, actionTypes.ADD_ITEMS_SUCCESS, actionTypes.ADD_ITEMS_ERROR],
      callbacks: {
        onSuccess: ({ dispatch }) => {
          dispatch(getGlobalBlacklistByFilter());
          dispatch(resetGlobalBlacklistAddItemsInputState());

          NotificationManager.success(getMessage(
            messageCodes.ADD_DOMAINS_TO_GLOBAL_BLACKLIST_SUCCESS,
            { domains: payload.join(', ') },
          ));
        },
        onError: ({ error, dispatch }) => {
          if (error?.getCode() === 50602) {
            dispatch(setGlobalBlacklistAddItemsInputState({
              errors: error.getData().domains.map((item) => `${ item } is already in blacklist`),
            }));
          } else {
            NotificationManager.error(getErrorMessage(error));
          }
        },
      },
    },
  ));
};

export const addGlobalBlacklistItemsWithValidation = () => async (dispatch, getState) => {
  const state = getState();
  const domains = extractDomainsFromStringWithValidation(
    selectors.getAddInputValue(state),
    selectors.getCollectionData(state),
  );

  if (domains.errors.length) {
    dispatch(setGlobalBlacklistAddItemsInputState({ errors: domains.errors }));
    return;
  }

  await dispatch(addGlobalBlacklistItems(domains.items.map((item) => item.domain)));
};

export const reset = () => (dispatch) => {
  dispatch(resetGlobalBlacklistItem());
  dispatch(resetGlobalBlacklistCollection());
  dispatch(resetGlobalBlacklistAddItemsInputState());
};
