import React, { createContext, useContext } from 'react';
import useEnhancedTaggingCondor from './useEnhancedTaggingCondor';

interface IProps {
  children: React.ReactNode,
}

type TEnhancedTaggingCondorContext = ReturnType<typeof useEnhancedTaggingCondor>;

export const EnhancedTaggingCondorContext = createContext<TEnhancedTaggingCondorContext>(null);

export const useEnhancedTaggingCondorContext = () => {
  const context = useContext(EnhancedTaggingCondorContext);
  if (!context) {
    throw new Error('useEnhancedTaggingCondorContext must be used within a EnhancedTaggingCondorContextProvider');
  }
  return context;
};

const EnhancedTaggingCondorContextProvider = ({ children }: IProps) => (
  <EnhancedTaggingCondorContext.Provider value={ useEnhancedTaggingCondor() }>
    { children }
  </EnhancedTaggingCondorContext.Provider>
);

export default EnhancedTaggingCondorContextProvider;
