import React, { useMemo, useCallback } from 'react';
import { Field } from 'redux-form';
import cn from 'classnames';
import { components as ReactSelectComponents } from 'react-select';

import Product from '@/finsight/domain/Product';
import { DEAL_PROFILE_FIELDS } from '../constants';
import { ISSUER, PARENT, UNDERWRITER } from '@/finsight/application/actions/pageFilters/constants';

import withTooltipOnInputLabel from '@/ui/shared/components/Form/withTooltipOnInputLabel';
import { mapOptionToValue } from '@/Framework/dataHelpers/selectOptionMappers';
import { NotificationManager } from '@/ui/shared/components/Notification';
import { getMessage } from '@/Framework/Message/Mapper/getMessage';
import { messageCodes } from '@/Framework/Message/messages';

import TableRowFields from '@/condor/ui/common/Layout/TableRowFields';
import Input from '@/ui/shared/components/Form/Input';
import ReduxFormSelect from '@/ui/shared/components/Form/Select/legacy/ReduxFormSelect';
import { Tooltip, Icon, IconType, CheckboxInput } from '@dealroadshow/uikit';
import CompanySelect from './CompanySelectContainer';
import IssuerSelect from './IssuerSelect';
import CondorDatePicker from '@/condor/ui/common/CondorDatePicker';
import CopyToClipboardButton from '@/ui/shared/components/Button/CopyToClipboardButton';

import styles from '../dealProfile.scss';
import alignStyles from '@/ui/shared/styles/helpers/align.scss';
import spacesStyles from '@/ui/shared/styles/helpers/spaces.scss';
import layoutStyles from '@/condor/ui/common/Layout/layout.scss';

const IssuerSelectWithTooltipOnLabel = withTooltipOnInputLabel(IssuerSelect);
const CompanySelectWithTooltipOnLabel = withTooltipOnInputLabel(CompanySelect);

interface IFieldValue {
  abbreviation: string,
  issuerTicker: string,
  label: string,
  name: string,
  value: string,
}

interface IProps {
  isSecFiling: boolean,
  isCreateNewDeal: boolean,
  options: any,
  formValues: any,
  setPrimaryRegion: (region: any) => void,
  setAdditionalRegions: (regions: any[]) => void,
  clearSectorSubsector: () => void,
  onIssuerSelectChange: (value: any) => void,
}

const DealDetails = (
  {
    isSecFiling,
    isCreateNewDeal,
    options,
    setPrimaryRegion,
    setAdditionalRegions,
    clearSectorSubsector,
    formValues: {
      primaryRegion,
      product,
      additionalRegions,
      structuringLeads,
      jointLeads,
    },
    onIssuerSelectChange,
  }: IProps,
) => {
  const requiredFieldCellCls = cn(styles.smallInputsContainer, styles.requiredField);
  const requiredFieldCls = cn(layoutStyles.inputField, styles.requiredField);
  const isProductAbs = Product.isAbsProductId(mapOptionToValue(product));

  const onCountryChange = useCallback(
    (country) => {
      if (additionalRegions.find(({ value }) => value === country?.region?.value)) {
        setAdditionalRegions(additionalRegions.filter(({ value }) => value !== country?.region.value));
        NotificationManager.success(getMessage(
          messageCodes.CONDOR_DEAL_PROFILE_DUPLICATE_REGION_REMOVED,
          {
            additionalRegionsLabel: DEAL_PROFILE_FIELDS.ADDITIONAL_REGIONS.label,
            newCountryRegion: country?.region.displayName,
          },
        ));
      }
      setPrimaryRegion(country?.region || null);
    },
    [additionalRegions.length],
  );

  const onProductChange = useCallback(
    (product) => {
      if (!Product.isAbsProductId(product?.value)) {
        clearSectorSubsector();
      }
    },
    [],
  );

  const getMultiValueRemoveRenderer = (props) => (
    <ReactSelectComponents.MultiValueRemove { ...props }>
      <Icon type={ IconType.cancel } className={ styles.multiValueRemoveIcon } />
    </ReactSelectComponents.MultiValueRemove>
  );

  const abbreviationValueRenderer = ({ data, ...otherProps }) => (
    // @ts-ignore
    <ReactSelectComponents.MultiValueLabel { ...otherProps }>
      <Tooltip content={ data.name }>
        { data.abbreviation || data.label }
      </Tooltip>
    </ReactSelectComponents.MultiValueLabel>
  );
  const issuerTickerValueRenderer = ({ data, ...otherProps }) => (
    // @ts-ignore
    <ReactSelectComponents.MultiValueLabel { ...otherProps }>
      { data.issuerTicker || data.label };
    </ReactSelectComponents.MultiValueLabel>
  );
  const additionalRegionsOptions = useMemo(
    () => (
      options.regions
        ? options.regions.filter((region) => region.value !== mapOptionToValue(primaryRegion))
        : []
    ),
    [primaryRegion],
  );

  const onIssuerChange = useCallback((value) => {
    if (!isSecFiling || !isCreateNewDeal || !value) {
      return;
    }
    onIssuerSelectChange(value);
  }, []);

  const getTextToCopy = (fieldValues: IFieldValue[]) => fieldValues.map((val) => val.abbreviation).join(', ');

  return (
    <div className={ styles.panelContent }>
      <Field
        { ...DEAL_PROFILE_FIELDS.PRIMARY_ISSUER }
        placeholder="Select Issuer"
        className={ requiredFieldCls }
        component={ IssuerSelectWithTooltipOnLabel }
        includedRole={ ISSUER }
        onChange={ onIssuerChange }
        dataTest="dealPrimaryIssuer"
      />
      <Field
        { ...DEAL_PROFILE_FIELDS.PARENT }
        placeholder="Select Parent / Sponsor"
        className={ requiredFieldCls }
        component={ CompanySelectWithTooltipOnLabel }
        includedRole={ PARENT }
        dataTest="dealParent"
      />
      <TableRowFields
        fields={
          [
            {
              ...DEAL_PROFILE_FIELDS.SERIES,
              placeholder: '20XX-1',
              component: Input,
              cellClassName: requiredFieldCellCls,
              width: 160,
              maxWidth: 160,
              size: 'small',
            },
            {
              ...DEAL_PROFILE_FIELDS.COUNTRY,
              placeholder: 'Select country',
              component: ReduxFormSelect,
              isSlim: true,
              options: options.countries,
              className: styles.requiredField,
              cellClassName: requiredFieldCellCls,
              onChange: onCountryChange,
              width: 150,
              maxWidth: 150,
              dataTest: 'country',
            },
            {
              ...DEAL_PROFILE_FIELDS.PRIMARY_REGION,
              placeholder: 'Select country',
              component: ReduxFormSelect,
              isSlim: true,
              options: options.regions,
              disabled: true,
              cellClassName: styles.smallInputsContainer,
              width: 150,
              maxWidth: 150,
              dataTest: 'primaryRegionCountry',
            },
            {
              ...DEAL_PROFILE_FIELDS.PRODUCT,
              placeholder: 'Select one',
              component: ReduxFormSelect,
              isSlim: true,
              options: options.products,
              width: 130,
              maxWidth: 130,
              cellClassName: requiredFieldCellCls,
              onChange: onProductChange,
              dataTest: 'product',
            },
            ...(
              !isProductAbs
                ? []
                : [{
                  ...DEAL_PROFILE_FIELDS.ABS_SECTOR_SUBSECTOR,
                  placeholder: 'Select one',
                  component: ReduxFormSelect,
                  isSlim: true,
                  maxWidth: 160,
                  options: options.sectors,
                  cellClassName: requiredFieldCellCls,
                  dataTest: 'absSectorSubsector',
                }]
            ),
            ...(isSecFiling ? [] : [
              {
                ...DEAL_PROFILE_FIELDS.ANNOUNCED_DATE,
                component: CondorDatePicker,
                cellClassName: styles.smallInputsContainer,
                width: 160,
                maxWidth: 160,
                dataTest: 'announcedDate',
              },
              {
                ...DEAL_PROFILE_FIELDS.PRICING_DATE,
                component: CondorDatePicker,
                cellClassName: styles.smallInputsContainer,
                width: 160,
                maxWidth: 160,
                dataTest: 'pricingDate',
              },
              {
                ...DEAL_PROFILE_FIELDS.SETTLEMENT_DATE,
                component: CondorDatePicker,
                cellClassName: styles.smallInputsContainer,
                width: 160,
                maxWidth: 160,
                dataTest: 'settlementDate',
              },
              {
                ...DEAL_PROFILE_FIELDS.VERIFIED,
                cellClassName: alignStyles.alignCenter,
                component: CheckboxInput,
                type: 'checkbox',
                width: 75,
                maxWidth: 75,
              },
            ]),
          ]
        }
      />
      <div className={ spacesStyles.mbl } />
      <TableRowFields
        fields={
          [
            {
              ...DEAL_PROFILE_FIELDS.ADDITIONAL_ISSUERS,
              placeholder: 'Select issuers',
              component: IssuerSelect,
              isSlim: true,
              isMulti: true,
              components: {
                MultiValueLabel: issuerTickerValueRenderer,
                MultiValueRemove: getMultiValueRemoveRenderer,
              },
              cellClassName: styles.smallInputsContainer,
              width: 300,
              maxWidth: 300,
              dataTest: 'additionalIssuers',
            },
            {
              ...DEAL_PROFILE_FIELDS.ADDITIONAL_REGIONS,
              placeholder: 'Select regions',
              component: ReduxFormSelect,
              isSlim: true,
              options: additionalRegionsOptions,
              cellClassName: styles.smallInputsContainer,
              isMulti: true,
              width: 300,
              maxWidth: 300,
              dataTest: 'additionalRegions',
            },
            {
              ...DEAL_PROFILE_FIELDS.STRUCTURING_LEADS,
              placeholder: 'Select leads',
              component: CompanySelect,
              isSlim: true,
              isMulti: true,
              components: {
                MultiValueLabel: abbreviationValueRenderer,
                MultiValueRemove: getMultiValueRemoveRenderer,
              },
              cellClassName: styles.smallInputsContainer,
              includedRole: UNDERWRITER,
              width: 200,
              minWidth: 200,
              labelSuffix: (
                <CopyToClipboardButton
                  content={ getTextToCopy(structuringLeads) }
                  dataTest="actionCopyButton"
                  customStyles={ styles.copyButton }
                />
              ),
            },
            {
              ...DEAL_PROFILE_FIELDS.JOINT_LEADS,
              placeholder: 'Select joint leads',
              component: CompanySelect,
              isSlim: true,
              isMulti: true,
              components: {
                MultiValueLabel: abbreviationValueRenderer,
                MultiValueRemove: getMultiValueRemoveRenderer,
              },
              cellClassName: styles.smallInputsContainer,
              includedRole: UNDERWRITER,
              width: 200,
              minWidth: 200,
              labelSuffix: (
                <CopyToClipboardButton
                  content={ getTextToCopy(jointLeads) }
                  dataTest="actionCopyButton"
                  customStyles={ styles.copyButton }
                />
              ),
            },
            ...(isSecFiling ? [] : [
              {
                ...DEAL_PROFILE_FIELDS.INV_SET_AVAILABLE,
                cellClassName: alignStyles.alignCenter,
                component: CheckboxInput,
                type: 'checkbox',
                width: 75,
                maxWidth: 75,
              },
            ]),
          ]
        }
      />
    </div>
  );
};

export default DealDetails;
