import tinyColor from 'tinycolor2';
import { IValidationError } from './interfaces/ValidationError';

/**
 * Validate colors
 */
export const validateColors = (
  colorFields: ({ fieldName: string })[],
  values: { [key: string] : string },
): IValidationError => {
  let errors = {};
  colorFields.forEach((field) => {
    if (!tinyColor(values[field.fieldName]).isValid()) {
      errors[field.fieldName] = 'Invalid Hex code';
    }
  });
  if (Object.keys(errors).length) {
    return errors;
  }
  return null;
};
