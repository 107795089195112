import { useCallback, useEffect, useRef, useState } from 'react';
import { CONTENT_WRP_ID } from '@/ui/shared/components/Layout/ContentWrp/constants';
import throttle from 'lodash/throttle';

export const useStickyHeader = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [isStickyHeaderVisible, setIsStickyHeaderVisible] = useState<boolean>(false);

  const handleScroll = useCallback(
    throttle(() => {
      const contentWrp = document.getElementById(CONTENT_WRP_ID);
      const formHeight = ref.current?.getBoundingClientRect().height;
      const scrollTopOffset = 250;

      if (contentWrp.offsetHeight + contentWrp.scrollTop >= formHeight) {
        setIsStickyHeaderVisible(false);
      } else if (!isStickyHeaderVisible && contentWrp.scrollTop > scrollTopOffset) {
        setIsStickyHeaderVisible(true);
      } else if (isStickyHeaderVisible && contentWrp.scrollTop < scrollTopOffset) {
        setIsStickyHeaderVisible(false);
      }
    }, 100),
    [isStickyHeaderVisible, ref],
  );

  useEffect(() => {
    document.getElementById(CONTENT_WRP_ID).addEventListener('scroll', handleScroll);
    return () => {
      document.getElementById(CONTENT_WRP_ID)?.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return {
    ref,
    isStickyHeaderVisible,
  };
};
