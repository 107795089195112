import React from 'react';
import cn from 'classnames';
import { useSlate } from 'slate-react';
import { Editor, Element as SlateElement, Transforms } from 'slate';

import Button from './Button';
import { Icon, IconType } from '@dealroadshow/uikit';

import { isBlockActive } from './helpers';
import { TToolbarItemFormat } from '../interfaces';
import { TSlateNode } from '@/ui/shared/components/Form/RichTextInput/interfaces';

import toolbarStyles from '../toolbar.scss';

const listTypes = ['numbered-list', 'bulleted-list'];
const alignment = ['alignLeft', 'alignRight', 'alignCenter'];

interface IProps {
  format: TToolbarItemFormat,
  iconType: IconType,
  className?: string,
  activeClassName?: string,
}

const BlockButton = ({ format, iconType, className, activeClassName }: IProps) => {
  const editor = useSlate();

  const isActive = isBlockActive(editor, format);

  const toggleBlock = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    const isList = listTypes.includes(format);

    Transforms.unwrapNodes(editor, {
      match: (n: TSlateNode) => (
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        listTypes.includes(n.type) &&
        !alignment.includes(format)
      ),
      split: true,
    });

    const getType = () => {
      if (isList) {
        if (isActive) {
          return 'paragraph';
        }
        return 'list-item';
      }
      return format;
    };

    Transforms.setNodes<SlateElement>(editor, { type: getType() } as Partial<SlateElement>);

    if (!isActive && isList) {
      Transforms.wrapNodes(editor, { type: format, children: [] } as SlateElement & { type?: string });
    }
  };

  return (
    <Button
      activeClassName={ activeClassName }
      active={ isActive }
      format={ format }
      className={ cn(toolbarStyles.blockToolButton, className) }
      onMouseDown={ toggleBlock }
    >
      <Icon type={ iconType } />
    </Button>
  );
};

export default BlockButton;
