import { IValidationError } from './interfaces/ValidationError';

interface IParams {
  fieldName: string,
  fieldCaption?: string,
  value: string,
  expression: string | RegExp,
  flags?: string,
}

export const validateRegExp = (
  { fieldName, fieldCaption, value, expression, flags }: IParams,
): IValidationError => {
  const regex = new RegExp(expression, flags);

  if (!regex.test(value)) {
    return { [fieldName]: `${ fieldCaption || 'Field' } is invalid` };
  }
  return null;
};
