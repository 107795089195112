import pickBy from 'lodash/pickBy';
import { MONTH_DAY_YEAR_FORMAT } from '@/Framework/DateTime/dateFormats';
import formatUnixtimestamp from '@/Framework/dataHelpers/formatters/formatUnixtimestamp';
import {
  FiltersDataType,
  FiltersType,
} from '@/condor/application/EnhancedTaggingContexts/EnhancedTaggingFiltersContext';
import { MAP_FILTERS_NAME } from './constants';

const getFilterFieldName = (fieldName: string) => MAP_FILTERS_NAME[fieldName];

const formatFilterItem = (formatValue: (value: any) => string) => (fieldName: string, value: any) => {
  return `${ getFilterFieldName(fieldName) }: ${ formatValue(value) }`;
};

const detailFormatFilterArrayItem = formatFilterItem((values: string[]) => values.join(', '));

const formatFilterArrayItem = (fieldName: string, values: string[]) => {
  const isPlural = values.length > 1;

  return `${ values.length } ${ getFilterFieldName(fieldName) }${ isPlural ? 's' : '' }`;
};

const formatDateRange = (timeZone: string) => {
  return formatFilterItem(({ dateFrom, dateTo }: { dateFrom: number, dateTo: number }) => {
    return [dateFrom, dateTo]
      .map((date) => formatUnixtimestamp(date, { timeZone, format: MONTH_DAY_YEAR_FORMAT }))
      .join(' - ');
  });
};

export const getFiltersFormatter = (filtersData: FiltersDataType) => (fieldName: string, value: FiltersType) => {
  const formatters = {
    statuses: detailFormatFilterArrayItem,
    untaggedBy: detailFormatFilterArrayItem,
    taggedBy: detailFormatFilterArrayItem,
    transactionTypeIds: formatFilterArrayItem,
    dates: formatDateRange(filtersData.timeZone),
  };

  return formatters[fieldName]?.(fieldName, value) ?? fieldName;
};

export const getFilterValues = (
  { dateTo, dateFrom, transactionTypeIds, ...restFilters }: FiltersType,
  filtersData?: FiltersDataType,
) => {
  const filledFilters = pickBy(
    {
      ...restFilters,
      transactionTypeIds: Object.values(transactionTypeIds).flat(),
    },
    (value) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }

      return true;
    },
  );

  const { dateMin, dateMax } = filtersData ?? {};
  const showDateRange = (dateTo && dateTo !== dateMax) || (dateFrom && dateFrom !== dateMin);

  return {
    ...filledFilters,
    ...(showDateRange && {
      dates: {
        dateFrom: dateFrom || dateMin,
        dateTo: dateTo || dateMax,
      },
    }),
  };
};
