import React, { useState, useEffect } from 'react';
import { Route as ReactRoute } from 'react-router';
import noop from 'lodash/noop';
import { useSessionContext } from '@/users/application/Session/SessionContext';
import {
  calculateProps,
  getShouldRenderInitialValue,
} from './helpers';

interface IProps {
  children?: React.ReactNode | ((props: any) => React.ReactNode),
  component?: React.ReactNode | ((props: any) => React.ReactNode),
  exact?: boolean,
  location?: any,
  path?: string | string[],
  render?: (props: any) => void,
  sensitive?: boolean,
  strict?: boolean,
  layout?: React.ReactNode,
  beforeEnter?: (() => React.ReactNode) | (() => void | React.ReactNode)[],
  afterEnter?: (() => React.ReactNode) | (() => void | React.ReactNode)[],
  preloader?: React.ReactNode,
  fallback?: React.ReactNode,
}

const Route = (props: IProps): React.ReactNode => {
  const {
    preloader = null,
    fallback = null,
    beforeEnter = noop,
    afterEnter = noop,
  } = props;

  const { satisfiesCurrentAccessPermissions } = useSessionContext();
  const [shouldRender, setShouldRender] = useState(getShouldRenderInitialValue(beforeEnter));
  const [error, setError] = useState(null);

  async function reducerCallback(acc, callback) {
    await acc;
    if (typeof callback === 'function') {
      return callback(props);
    }
    return Promise.resolve();
  }

  async function execBeforeEnter() {
    try {
      await (Array.isArray(beforeEnter) ? beforeEnter : [beforeEnter]).reduce(reducerCallback, Promise.resolve());

      setShouldRender(true);
    } catch (e) {
      setError(e);
    }
  }

  async function execAfterEnter() {
    try {
      await (Array.isArray(afterEnter) ? afterEnter : [afterEnter]).reduce(reducerCallback, Promise.resolve());
    } catch (e) {
      setError(e);
    }
  }

  useEffect(
    () => {
      if (!shouldRender) {
        execBeforeEnter();
      } else {
        execAfterEnter();
      }
    },
    [shouldRender],
  );

  if (error) {
    return fallback;
  }
  if (!shouldRender || !satisfiesCurrentAccessPermissions()) {
    return preloader;
  }
  return (
    <ReactRoute
      { ...calculateProps(props) }
    />
  );
};

export default Route;
