import React from 'react';

import { useEnhancedTaggingRoadshowsContext } from '@/condor/application/EnhancedTaggingContexts/EnhancedTaggingRoadshowsContext';
import DealSize, { normalizeDealSizeValue } from '@/ui/shared/modules/EnhancedTagging/Field/DealSize';
import FieldWithErrorTooltip from '../../components/FieldWithErrorTooltip';
import { ICellProps } from '@dealroadshow/uikit';

import spaces from '@/ui/shared/styles/helpers/spaces.scss';

const BULK_TABLE_NAME = 'bulkEditingForm';

const DealSizeCallback = ({ row: { id }, rowIndex, customCellProps, cellData }: ICellProps) => {
  const fieldRowName = `editableTableArray.${ rowIndex }`;

  const { editableRowId } = useEnhancedTaggingRoadshowsContext();

  if (editableRowId === id || customCellProps.formProps.id === BULK_TABLE_NAME) {
    return (
      <DealSize
        name={ `${ fieldRowName }.dealSize` }
        formFieldClassName={ spaces.mbn }
        fieldComponent={ FieldWithErrorTooltip }
        dataTest={ `dealSize[${ rowIndex }]` }
        label=""
        isErrorMessage={ false }
      />
    );
  }

  if (!cellData) {
    return '-';
  }

  return normalizeDealSizeValue(cellData);
};

export default DealSizeCallback;
