import Product, { ABS_PRODUCT_ID } from '@/finsight/domain/Product';
import Region from '@/finsight/domain/Region';
import { fromUnixTime, format } from 'date-fns';
import { validateUrlFilter } from '@/finsight/application/actions/pageFilters/productRegionFilters/actions';
import { IDictionariesState } from './Dictionaries/helpers';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';
import * as FILTER_TYPES from '@/finsight/application/actions/pageFilters/constants';
import { YEAR_FORMAT } from '@/Framework/DateTime/dateFormats';

interface IPrapareProductsRegions {
  pathname: string,
  products: string,
  regions: string,
  regionsList: Region[],
  productsList: Product[],
}

export const getValidProductsRegions = ({ pathname = '', query, dictionaries }): {
  productIds: string[],
  regionIds: string[],
} => {
  const { regionsList, productsList } = dictionaries as IDictionariesState;
  const { products, regions, companyViewId } = query;
  const prapareProductsRegions: IPrapareProductsRegions = {
    pathname,
    products,
    regions,
    regionsList,
    productsList,
  };

  if (companyViewId && (!products || !regions)) {
    prapareProductsRegions.regions = sortBy(regionsList.map((product) => product.abbreviation)).join(',');
    prapareProductsRegions.products = sortBy(productsList.map((region) => region.abbreviation)).join(',');
  }

  const { validProducts, validRegions } = getValidChangedProductsRegions(prapareProductsRegions);
  const productIds = Product.getByAbbreviations(productsList, validProducts, true);
  const regionIds = Region.getByAbbreviations(regionsList, validRegions, true);

  return {
    productIds,
    regionIds,
  };
};

export const getValidChangedProductsRegions = ({
  products,
  productsList,
  regions,
  regionsList,
  pathname,
}) => {
  const {
    validKeys: validProducts,
    keysChanged: productsChanged,
  } = getValidChanged(
      products,
      productsList,
      Product.getDefaultProductByPath(pathname),
  );

  const {
    validKeys: validRegions,
    keysChanged: regionsChanged,
  } = getValidChanged(
      regions,
      regionsList,
      Region.getDefault(),
  );

  return {
    validProducts,
    productsChanged,
    validRegions,
    regionsChanged,
  };
};

export const getValidChanged = (urlKeys, collection, defaultValue) => {
  const {
    updatedQuery: validKeys,
    hasChanged: keysChanged,
  } = validateUrlFilter(urlKeys, collection, defaultValue);

  return {
    validKeys,
    keysChanged,
  };
};

export const getValidChangedProducts = (
    products,
    productsList,
    regions,
    regionsList,
    pathname,
) => {
  const {
    validKeys: validProducts,
    keysChanged: productsChanged,
  } = getValidChanged(
      products,
      productsList,
      Product.getDefaultProductByPath(pathname),
  );

  const {
    validKeys: validRegions,
    keysChanged: regionsChanged,
  } = getValidChanged(
      regions,
      regionsList,
      Region.getDefault(),
  );

  return {
    validProducts,
    productsChanged,
    validRegions,
    regionsChanged,
  };
};

export const insertCompanyFilters = async (context, filter) => {
  const { query } = context;
  const {
    sponsorName,
    issuerName,
  } = query;

  const issuer = context?.company;
  if (sponsorName) filter.includedParentIds = [issuer?.id];
  if (issuerName) filter.includedIssuerIds = [issuer.id];

  return filter;
};

export const insertSectorFilters = (context, filter) => {
  const { query, dictionaries } = context;
  const {
    sector,
  } = query;
  const sectorItem = dictionaries.sectorsList[sector];

  if (sectorItem.sectorId) {
    filter.includedSectorId = sectorItem.sectorId;
  }
  if (sectorItem.subsectorId) {
    filter.includedSubsectorId = sectorItem.subsectorId;
  }
  return filter;
};

export const insertIndustryFilters = (context, filter) => {
  const { query, dictionaries } = context;
  const {
    industry,
  } = query;

  if (industry) {
    const industryItem = dictionaries.industriesList[industry];

    if (industryItem.industryId) {
      filter.includedIndustryId = industryItem.industryId;
    }
    if (industryItem.subindustryId) {
      filter.includedSubindustryId = industryItem.subindustryId;
    }
  }

  return filter;
};

// TODO: remove after create global redirect
export const isChangedProductsRegions = (
  regions: string = '', // params from url
  products: string = '', // params from url
  validRegions: string[], // array of abbreviation
  validProducts: string[], // array of abbreviation
) => {
  const sortRegions = sortBy(regions.split(','));
  const sortProducts = sortBy(products.split(','));
  const sortValidRegions = sortBy(validRegions);
  const sortValidProducts = sortBy(validProducts);

  return !isEqual(sortRegions, sortValidRegions) || !isEqual(sortProducts, sortValidProducts);
};

interface IAdditionalParams {
  dateFrom?: number,
  dateTo?: number,
}

export const getAdditionalParams = (filtersPayload:IAdditionalParams):IAdditionalParams => {
  const additionalParams:IAdditionalParams = {};

  if (filtersPayload.dateFrom) {
    additionalParams.dateFrom = filtersPayload.dateFrom;
  }

  if (filtersPayload.dateTo) {
    additionalParams.dateTo = filtersPayload.dateTo;
  }

  return additionalParams;
};

export const getSectorFilters = (context) => {
  const { query: { sector }, dictionaries } = context;
  const sectorItem = dictionaries.sectorsList[sector];

  if (sectorItem.subsectorId) {
    return { includedSubsectorIds: [sectorItem.subsectorId] };
  }

  return { includedSectorIds: [sectorItem.sectorId] };
};

export const getIndustryFilters = (context) => {
  const { query: { industry }, dictionaries } = context;

  const industryItem = dictionaries.industriesList[industry];

  if (industryItem.subindustryId) {
    return { includedSubindustryIds: [industryItem.subindustryId] };
  }

  return { includedIndustryIds: [industryItem.industryId] };
};

export const getMarketChartFilterType = (context) => {
  const { productsRegions: { productIds } } = context;

  return productIds.includes(ABS_PRODUCT_ID)
      ? FILTER_TYPES.SECTOR
      : FILTER_TYPES.INDUSTRY;
};

export const formatSSRLoggerError = (error) => {
  if (error.jsonrpc) {
    return JSON.stringify(error);
  }

  return error;
};

export const toYearsFormatter = (value) => (value ? format(fromUnixTime(value), YEAR_FORMAT) : null);
