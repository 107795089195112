import React, { memo } from 'react';
import Section from './Section';

interface IProps {
  passwordReset: any,
  activation: any,
  setUserPasswordResetApplication: () => void,
  setUserActivationApplication: () => void,
  openInvalidatePasswordModal: () => void,
  generateResetLink: () => void,
  generateActivationLink: () => void,
}

const Links = (props: IProps) => (
  <>
    <Section
      label="Reset Password"
      linkLabel="PASSWORD RESET LINK"
      link={ props.passwordReset.link }
      // @ts-ignore
      dataTest="resetPasswords"
      selectProps={ {
        name: 'resetPasswordsSelect',
        value: props.passwordReset.application,
        onChange: props.setUserPasswordResetApplication,
      } }
      openInvalidatePasswordModal={ props.openInvalidatePasswordModal }
      isGenerating={ props.passwordReset.isGenerating }
      onGenerateClick={ props.generateResetLink }
    />
    { /* @ts-ignore */ }
    <Section
      label="Contact Activation"
      linkLabel="ACTIVATION LINK"
      link={ props.activation.link }
      dataTest="contactActivations"
      selectProps={ {
        name: 'contactActivationsSelect',
        value: props.activation.application,
        onChange: props.setUserActivationApplication,
      } }
      isGenerating={ props.activation.isGenerating }
      onGenerateClick={ props.generateActivationLink }
    />
  </>
);

export default memo(Links);
