import React from 'react';
import styles from '@/condor/ui/components/FinsightManager/Analytics/BIContacts/ContactsTable/contactsTable.scss';
import { Icon, IconType, Tooltip } from '@dealroadshow/uikit';

/**
 * @param cellProps
 */
export const nameCellCallback = (cellProps) => (
  <>
    { `${ cellProps.row.contactFirstName || '' } ${ cellProps.row.contactLastName || '' }` }
    <div className={ styles.contactEmail }>
      { cellProps.row.contactEmail }
    </div>
  </>
);

/**
 * @param props
 */
export const agingHeadCallback = (props) => (
  <div className={ styles.agingHeaderWrp }>
    <span className={ styles.agingTitle }>{ props.title }</span>
    <span className={ styles.questionWrapper }>
      <Tooltip content="Aging corresponds to the number of hours or days since the Contact has logged into the B&I Module">
        <Icon
          className={ styles.questionIcon }
          type={ IconType.question }
        />
      </Tooltip>
    </span>
  </div>
);
