import { connect } from 'react-redux';

import ManageSubscriptionsModal from './ManageSubscriptionsModal';
import metadataSelectors from '@/condor/application/actions/finsight/subscriptions/metadata/selectors';
import {
  setManageSubscriptionsModalData,
  initSubscriptionsModal,
  resetMetadata,
  updateUserSubscriptionsByAdmin,
} from '@/condor/application/actions/finsight/subscriptions/metadata/actions';

const mapStateToProps = (state) => ({
  isModalOpen: metadataSelectors.isManageSubscriptionsModalVisible(state),
  item: metadataSelectors.getManageSubscriptionsModalItem(state),
  isSpinnerVisible: metadataSelectors.isFetching(state),
});

const mapDispatchToProps = {
  initManageSubscriptionsModal: initSubscriptionsModal,
  onCancel: setManageSubscriptionsModalData,
  reset: resetMetadata,
  submitForm: updateUserSubscriptionsByAdmin,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManageSubscriptionsModal);
