import { getBondTypeFromLocation } from '@/finsight/application/actions/bonds/helpers';
import { History } from '@/Framework/DI/Providers/History';
import BondType from '@/finsight/domain/BondType';
import * as actionTypes from './actionTypes';
import createAction from '@/Framework/State/Redux/createAction';
import createAsyncRequest from '@/Framework/State/Redux/createAsyncRequest';
import BondsRepository from '@/finsight/infrastructure/repository/BondsRepository';
import { fetchBwic } from '../bwic/actions';
import { fetch as fetchInventory } from '../inventory/actions';

/**
 * @param {String} bondId
 */
export const deleteBond = (bondId) => async (dispatch, getState) => {
  const history = getState().container.get(History);
  const isBwic = BondType.isBwics(getBondTypeFromLocation(history.location.pathname));
  const bondType = isBwic ? 'BWIC' : 'INVENTORY';

  const bondsRepository = getState().container.get(BondsRepository);

  dispatch(createAsyncRequest({
    method: isBwic ? bondsRepository.deleteBwic : bondsRepository.deleteInventory,
    types: [
      actionTypes[`DELETE_CONDOR_FINSIGHT_IMPORT_BOND_${ bondType }`],
      actionTypes[`DELETE_CONDOR_FINSIGHT_IMPORT_BOND_${ bondType }_SUCCESS`],
      actionTypes[`DELETE_CONDOR_FINSIGHT_IMPORT_BOND_${ bondType }_ERROR`],
    ],
    payload: {
      [isBwic ? 'bwicId' : 'inventoryId']: bondId,
    },
    callbacks: {
      onSuccess: ({ dispatch }) => {
        dispatch(changeDeleteBondModalVisible(false));
        dispatch(resetSelectedBond());
        dispatch(isBwic ? fetchBwic() : fetchInventory());
      },
    },
  }));
};

/**
 * @param {Boolean} payload
 */
export const changeDeleteBondModalVisible = (payload) => createAction(
  actionTypes.CHANGE_CONDOR_FINSIGHT_IMPORT_BOND_DELETE_MODAL_VISIBLE,
  payload,
);

/**
 * @param {String} payload
 */
export const setSelectedBondIdAndName = (payload) => createAction(
  actionTypes.SET_CONDOR_FINSIGHT_IMPORT_BOND_DELETE_ITEM,
  payload,
);

export const resetSelectedBond = () => createAction(
  actionTypes.SET_CONDOR_FINSIGHT_IMPORT_BOND_DELETE_ITEM,
  {},
);
