import { Dependencies } from 'constitute';
import Request from '@/Framework/api/Rpc/Request';
import { withCache } from '@/Framework/withCache';
import RpcDispatcher from '@/finsight/application/DI/Rpc/finsight-web/HttpDispatcher';

@Dependencies(RpcDispatcher)
class IndustryRepository {
  /**
   * @param {Dispatcher} rpcDispatcher
   */
  constructor(rpcDispatcher) {
    this.rpc = rpcDispatcher;
  }

  @withCache
    getList = async () => {
      let response = await this.rpc.request(new Request('finsight_web.market.get_industries', {}));
      return response.getResult().payload;
    };
}

export default IndustryRepository;
