import React, { useMemo } from 'react';
import { Field } from 'redux-form';
import { Select } from '@/ui/shared/components/Form/Fields/Select';

interface IProps {
  industries: any[],
}

const IndustrySubindustryField = ({ industries, ...otherProps }: IProps) => {
  const options = useMemo(
    () => industries.reduce(
      (resultOptions, currentIndustry) => (
        [
          ...resultOptions,
          ...currentIndustry.subindustryList.map(
            (subindustry) => (
              {
                label: `${ currentIndustry.name } - ${ subindustry.name }`,
                value: {
                  industryId: currentIndustry.id,
                  subindustryId: subindustry.id,
                },
              }
            ),
          ),
        ]
      ),
      [],
    ),
    [industries.length],
  );

  return (
    <Field
      name="industrySubindustry"
      options={ options }
      component={ Select }
      placeholder=""
      backspaceRemovesValue={ false }
      deleteRemoves={ false }
      dataTest="industrySubindustryReduxFormSelect"
      { ...otherProps }
    />
  );
};

export default IndustrySubindustryField;
