import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { IProfile } from '@/openMarket/domain/Condor/vo/IProfile';
import { ProfileAccessTypes } from '@/openMarket/domain/Condor/ProfileAccessType';
import { useProfileDataContext } from '@/openMarket/application/Condor/ProfileDataContext';
import decodeSymbols from '@/Framework/dataHelpers/string/decodeSymbols';
import { IFormValues } from '../interfaces';
import { getDefaultDisplayTabs } from '../fields/DisplayTabs/constants';
import { DEFAULT_CUSTOMIZATION_COLORS } from '@/users/ui/common/colors';

const getDefaultFormValues = (): IFormValues => ({
  id: uuid(),
  name: '',
  url: '',
  restrictAccess: ProfileAccessTypes.LOGIN,
  headerDescription: '',
  headerLogo: null,
  disclaimer: '',
  disclaimerLogo: null,
  published: false,
  displayTabs: getDefaultDisplayTabs(),
  isCustomized: false,
  backgroundImage: null,
  backgroundColor: DEFAULT_CUSTOMIZATION_COLORS.backgroundColor,
  tileColor: DEFAULT_CUSTOMIZATION_COLORS.tileColor,
  primaryTextColor: DEFAULT_CUSTOMIZATION_COLORS.primaryTextColor,
  secondaryTextColor: DEFAULT_CUSTOMIZATION_COLORS.secondaryTextColor,
  linkColor: DEFAULT_CUSTOMIZATION_COLORS.linkColor,
  inputLabelColor: DEFAULT_CUSTOMIZATION_COLORS.inputLabelColor,
  buttonTextColor: DEFAULT_CUSTOMIZATION_COLORS.buttonTextColor,
  buttonColor: DEFAULT_CUSTOMIZATION_COLORS.buttonColor,
});

const getUpdateFormValues = (profile: IProfile): IFormValues => ({
  id: profile.id,
  name: decodeSymbols(profile.name),
  url: profile.alias,
  restrictAccess: profile.restrictAccess,
  headerDescription: decodeSymbols(profile.headerDescription),
  headerLogo: profile.headerLogo,
  disclaimer: decodeSymbols(profile.disclaimer),
  disclaimerLogo: profile.disclaimerLogo,
  published: profile.published,
  displayTabs: profile.displayTabs.map((tab) => ({
    ...tab,
    name: decodeSymbols(tab.name),
  })),
  isCustomized: profile.isCustomized,
  backgroundImage: profile.backgroundImage,
  backgroundColor: profile.backgroundColor,
  tileColor: profile.tileColor,
  primaryTextColor: profile.primaryTextColor,
  secondaryTextColor: profile.secondaryTextColor,
  linkColor: profile.linkColor,
  inputLabelColor: profile.inputLabelColor,
  buttonTextColor: profile.buttonTextColor,
  buttonColor: profile.buttonColor,
});

export const useProfileFormInit = () => {
  const { profile, profileId } = useProfileDataContext();
  const [initialFormValues, setInitialFormValues] = useState(getDefaultFormValues());
  const [isInitialized, setIsInitialized] = useState(!profileId);

  useEffect(() => {
    if (profile) {
      setInitialFormValues(getUpdateFormValues(profile));
      setIsInitialized(true);
    }
  }, [profile]);

  return {
    initialFormValues,
    isInitialized,
  };
};
