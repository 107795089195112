import { useState } from 'react';
import EvercallAdminRepository from '@/evercall/infrastructure/repository/EvercallAdminRepository';
import useFetchCollection from '@/Framework/State/useFetchCollection';
import { useDIContext } from '@/Framework/DI/DIContext';
import { IAdminCallListItem } from '@/evercall/domain/vo/call/admin/AdminCallListItem';
import { CallsListFilterValues } from './constants';
import getActions from './getActions';

export default () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<CallsListFilterValues>(CallsListFilterValues.All);
  const { container } = useDIContext();
  const evercallAdminRepository: EvercallAdminRepository = container.get(EvercallAdminRepository);

  const {
    isFetching,
    isZeroCase,
    collection,
    sortBy,
    sortOrder,
    totalCount,
    page,
    perPage,
    sort,
    paginate,
    setItemsPerPage,
    debouncedSearchString,
    debouncedSearch,
    setIsFetching,
    getCollection,
  } = useFetchCollection<IAdminCallListItem>(
    evercallAdminRepository.getCallsList,
    { filter: selectedFilter },
    {
      sortBy: null, // Default sorting by the createdAt db column
      sortOrder: 'desc',
    },
  );

  return {
    state: {
      isInitialized,
      isFetching,
      isZeroCase,
      collection,
      sortBy,
      sortOrder,
      totalCount,
      page,
      perPage,
      debouncedSearchString,
      selectedFilter,
    },
    sort,
    paginate,
    setItemsPerPage,
    debouncedSearch,
    setIsInitialized,
    setSelectedFilter,
    ...getActions({ setIsFetching, getCollection }),
  };
};
