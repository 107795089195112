import { connect, batch } from 'react-redux';
import { formValueSelector, change, touch } from 'redux-form';
import { CONDOR_DEAL_PROFILE_FORM_NAME } from '@/condor/ui/common/constants';
import { DEAL_PROFILE_FIELDS } from '../constants';
import DealDetails from './DealDetails';
import selectors from '@/condor/application/actions/finsight/deal/selectors';
import { initDealProfile } from '@/condor/application/actions/finsight/deal/actions';

const formSelector = formValueSelector(CONDOR_DEAL_PROFILE_FORM_NAME);

const mapStateToProps = (state) => (
  {
    options: selectors.getOptions(state),
    formValues: {
      product: formSelector(state, DEAL_PROFILE_FIELDS.PRODUCT.name),
      primaryRegion: formSelector(state, DEAL_PROFILE_FIELDS.PRIMARY_REGION.name),
      additionalRegions: formSelector(state, DEAL_PROFILE_FIELDS.ADDITIONAL_REGIONS.name),
      structuringLeads: formSelector(state, DEAL_PROFILE_FIELDS.STRUCTURING_LEADS.name),
      jointLeads: formSelector(state, DEAL_PROFILE_FIELDS.JOINT_LEADS.name),
    },
  }
);

/*
 "touch" is needed in order to make the affected (but not manually touched) field appear in formMeta object,
 that is used in useDealChangesList.
 */
const mapDispatchToProps = (dispatch) => (
  {
    setPrimaryRegion: (regionId) => batch(() => {
      dispatch(touch(
        CONDOR_DEAL_PROFILE_FORM_NAME,
        DEAL_PROFILE_FIELDS.PRIMARY_REGION.name,
      ));
      dispatch(change(
        CONDOR_DEAL_PROFILE_FORM_NAME,
        DEAL_PROFILE_FIELDS.PRIMARY_REGION.name,
        regionId,
      ));
    }),
    setAdditionalRegions: (regions) => batch(() => {
      dispatch(touch(
        CONDOR_DEAL_PROFILE_FORM_NAME,
        DEAL_PROFILE_FIELDS.ADDITIONAL_REGIONS.name,
      ));
      dispatch(change(
        CONDOR_DEAL_PROFILE_FORM_NAME,
        DEAL_PROFILE_FIELDS.ADDITIONAL_REGIONS.name,
        regions,
      ));
    }),
    clearSectorSubsector: () => batch(() => {
      dispatch(touch(
        CONDOR_DEAL_PROFILE_FORM_NAME,
        DEAL_PROFILE_FIELDS.ABS_SECTOR_SUBSECTOR.name,
      ));
      dispatch(change(
        CONDOR_DEAL_PROFILE_FORM_NAME,
        DEAL_PROFILE_FIELDS.ABS_SECTOR_SUBSECTOR.name,
        null,
      ));
    }),
    onIssuerSelectChange: ({ value: issuerId }) => dispatch(initDealProfile(null, issuerId)),
  }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DealDetails);
