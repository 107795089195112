import React from 'react';
import { reduxForm } from 'redux-form';
import cn from 'classnames';
import noop from 'lodash/noop';

import Button, { variantTypes } from '@/ui/shared/components/Button';
import AreYouSureModal from './AreYouSureModal';

import styles from './formWrapper.scss';
import cardStyles from '@/ui/shared/styles/card.scss';
import headerStyles from '@/ui/shared/styles/headers.scss';
import spacesStyles from '@/ui/shared/styles/helpers/spaces.scss';

interface IProps {
  dataTest: string,
  handleSubmit: () => void,
  submitting: boolean,
  pristine: boolean,
  invalid: boolean,
  submitSucceeded: boolean,
  submitButtonText?: string,
  children: React.ReactNode,
  onCancelClick?: () => void,
  buttonsHidden?: boolean,
  headerText?: string,
  withAreYouSureModal?: boolean,
  className?: string,
  buttonsWrapperClassname?: string,
}

const FormWrapper = ({
  dataTest,
  handleSubmit,
  submitting,
  pristine,
  invalid,
  submitSucceeded,
  submitButtonText = 'Save',
  children,
  onCancelClick = noop,
  buttonsHidden = false,
  headerText,
  withAreYouSureModal = true,
  className,
  buttonsWrapperClassname,
}: IProps) => (
  <form
    className={ cn(cardStyles.cardContent, className) }
    onSubmit={ handleSubmit }
    data-test={ dataTest }
  >
    { headerText && (
      <div className={ cn(headerStyles.isH3, spacesStyles.mbl) }>{ headerText }</div>
    ) }
    { children }
    { !buttonsHidden && (
      <div className={ cn(styles.formButtonsContainer, buttonsWrapperClassname) }>
        <Button
          type="submit"
          variant={ variantTypes.action }
          className={ styles.successBtn }
          disabled={ submitting || pristine || invalid }
          title={ submitButtonText }
          dataTest="condorFormWrapperSubmitButton"
        />
        <Button
          variant={ variantTypes.text }
          onClick={ onCancelClick }
          title="Cancel"
          dataTest="condorFormWrapperCancelButton"
        />
      </div>
    ) }
    { withAreYouSureModal && (
      <AreYouSureModal
        when={ !pristine && !submitting && !submitSucceeded }
        disableNative={ false }
      />
    ) }
  </form>
);

export default reduxForm()(FormWrapper);
