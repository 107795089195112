import { batch, connect } from 'react-redux';
import {
  getCondorFinsightCompaniesByFilter,
  resetCondorFinsightCompanies,
} from '@/condor/application/actions/finsight/company/collection/actions';
import {
  getCondorFinsightIndustries,
} from '@/condor/application/actions/finsight/industry/collection/actions';
import { resetCondorFinsightCompanyItem } from '@/condor/application/actions/finsight/company/item/actions';
import CompaniesList from './CompaniesList';
import selectors from '@/condor/application/actions/finsight/company/item/selectors';

const mapStateToProps = (state) => ({
  isMigrateModalVisible: selectors.isMigrateModalVisible(state),
  isDeleteModalVisible: selectors.isDeleteModalVisible(state),
  isInformationForMigrationFetching: selectors.isInformationForMigrationFetching(state),
});

const mapDispatchToProps = (dispatch) => (
  {
    init: () => {
      dispatch(getCondorFinsightCompaniesByFilter());
      dispatch(getCondorFinsightIndustries());
    },
    reset: () => batch(() => {
      dispatch(resetCondorFinsightCompanies());
      dispatch(resetCondorFinsightCompanyItem());
    }),
  }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompaniesList);
