/**
 * @param {Array} cuePointsData
 * @return {Array}
 */
function filterCuePoints(cuePointsData) {
  let cuePoints = [];
  cuePointsData.forEach((cue) => {
    if (typeof cue.slideNumber !== 'undefined'
         && cue.slideNumber !== ''
         && typeof cue.time !== 'undefined'
         && cue.time !== '') {
      cuePoints.push({
        ...cue,
        displayName: cue.displayName?.length ? cue.displayName : null,
      });
    }
  });
  return cuePoints;
}

/**
 * @param {Array} cuePointsData
 * @return {Array}
 */
function filterVideoOnlyCuePoints(cuePointsData) {
  let cuePoints = [];
  cuePointsData.forEach((cue) => {
    if (typeof cue.number !== 'undefined'
        && cue.number !== ''
        && typeof cue.time !== 'undefined'
        && cue.time !== '') {
      cuePoints.push(cue);
    }
  });
  return cuePoints;
}

/**
 * @param {Object} formData
 * @return {Object}
 */
function filterDealAudioVideoFormData(formData) {
  let saveData = {
    id: formData.id,
    type: formData.type,
    presentationMedia: {
      mediaAudio: formData.upload.presentationMediaAudio.audio,
      mediaVideo: formData.upload.presentationMediaVideo.video,
      mediaThumbnail: formData.upload.presentationMediaThumbnail.thumbnail,
    },
    cuePoints: filterCuePoints(formData.cuePoints),
    videoOnlyCuePoints: filterVideoOnlyCuePoints(formData.videoOnlyCuePoints),
    audioConferenceCode: formData.audioConferenceCode || null,
  };
  return {

    ...saveData,
  };
}

export default filterDealAudioVideoFormData;
