import React from 'react';
import { ICellProps as IDataTableCellProps } from '@dealroadshow/uikit';
import { IManagePlansSubscriberItem } from '@/condor/domain/vo/FinsightManager/ManagePlans';
import SubscriberAtCell from './SubscriberAtCell';

export const cellStartedAtCallback = (cellProps: IDataTableCellProps<IManagePlansSubscriberItem>) => (
  <SubscriberAtCell { ...cellProps } />
);

export const teamCellCallback = (cellProps: IDataTableCellProps<IManagePlansSubscriberItem>) => {
  return cellProps.row.teams.length > 0 ? (
    <p>
      {
        cellProps.row.teams.map((team, index) => `${ team.name }${ index === cellProps.row.teams.length - 1 ? '' : ', ' }`)
      }
    </p>
  ) : <>-</>;
};
