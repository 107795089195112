import { commonCellCallback } from '@/condor/ui/common/Layout/helpers';
import { createdAtCellCallback, nameCellCallback, updatedAtCellCallback } from './cellCallbacks';
import alignStyles from '@/ui/shared/styles/helpers/align.scss';

const columns = [
  {
    name: 'name',
    title: 'Name',
    sortable: true,
    cellCallback: nameCellCallback,
  },
  {
    name: 'accountCount',
    title: '# of Accounts',
    className: alignStyles.alignRight,
    sortable: true,
    width: 145,
    cellCallback: commonCellCallback,
  },
  {
    name: 'createdAt',
    title: 'Created At',
    className: alignStyles.alignRight,
    sortable: true,
    width: 145,
    cellCallback: createdAtCellCallback,
  },
  {
    name: 'updatedAt',
    title: 'Updated At',
    className: alignStyles.alignRight,
    width: 145,
    cellCallback: updatedAtCellCallback,
    sortable: true,
  },
];

export default columns;
