import AuditTrailRepository from '@/dealroadshow/infrastructure/repository/AuditTrailRepository';
import { NotificationManager } from '@/ui/shared/components/Notification';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import downloadFile from '@/Framework/api/downloadFile';
import * as actionTypes from './actionTypes';
import createAction from '@/Framework/State/Redux/createAction';

/**
 * @param {String} roadshowId
 * @param {boolean} isAdmin
 * @return {Promise}
 */

export const exportToExcelAuditTrail = (roadshowId, isAdmin) => async (dispatch, getState) => {
  dispatch(createAction(actionTypes.REQUEST_DOWNLOAD_EXCEL));
  try {
    const auditTrailRepository = getState().container.get(AuditTrailRepository);
    const exportUrl = await auditTrailRepository.getExportToExcelLink(roadshowId, isAdmin);
    downloadFile(exportUrl);

    dispatch(createAction(actionTypes.REQUEST_DOWNLOAD_EXCEL_SUCCESS));
  } catch (e) {
    dispatch(createAction(actionTypes.REQUEST_DOWNLOAD_EXCEL_ERROR, e));
    NotificationManager.error(getErrorMessage(e));
  }
};
