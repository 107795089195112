import { useParams } from 'react-router';
import createAsyncRequest from '@/Framework/State/createAsyncRequest';
import { IAdminCall } from '@/evercall/domain/vo/call/admin/AdminCall';
import EvercallAdminRepository from '@/evercall/infrastructure/repository/EvercallAdminRepository';
import { useDIContext } from '@/Framework/DI/DIContext';
import ErrorCodeHelper from '@finsight/error-codes';
import { getMessageByErrorCode, getMessage } from '@/Framework/Message/Mapper/getMessage';
import { NotificationManager } from '@/ui/shared/components/Notification';
import { messageCodes } from '@/Framework/Message/messages';

interface IProps {
  setIsUpdating: (payload: boolean) => void,
  setIsUpdateSucceeded: (payload: boolean) => void,
  setItem: (payload: IAdminCall) => void,
}

export default (
  {
    setIsUpdating,
    setIsUpdateSucceeded,
    setItem,
  }: IProps,
) => {
  const { container } = useDIContext();
  const evercallAdminRepository: EvercallAdminRepository = container.get(EvercallAdminRepository);

  const { callId: callIdFromUrl } = useParams();

  const updateCallData = async (payload: IAdminCall): Promise<{ [key: string]: string }> => {
    let submitErrors = {};
    const request = createAsyncRequest<typeof evercallAdminRepository.updateCallData>(
      {
        method: evercallAdminRepository.updateCallData,
        callbacks: {
          onSuccess: ({ response }) => {
            setItem(response);
            setIsUpdating(false);
            setIsUpdateSucceeded(true);
            NotificationManager.success(getMessage(messageCodes.CONDOR_SAVED));
          },
          onError: ({ error }) => {
            if (error?.error?.code === ErrorCodeHelper.getCodeByName('EVERCALL_ADMIN_CONFERENCE_ALREADY_LINKED')) {
              submitErrors = {
                participantConferenceId: getMessageByErrorCode(
                  ErrorCodeHelper.getCodeByName('EVERCALL_ADMIN_CONFERENCE_ALREADY_LINKED'),
                ),
              };
            }
            if (error?.error?.code === ErrorCodeHelper.getCodeByName('EVERCALL_ADMIN_ROADSHOW_ALREADY_LINKED')) {
              submitErrors = {
                ...submitErrors,
                roadshowId: getMessageByErrorCode(
                  ErrorCodeHelper.getCodeByName('EVERCALL_ADMIN_ROADSHOW_ALREADY_LINKED'),
                ),
              };
            }
            setIsUpdating(false);
            setIsUpdateSucceeded(false);
          },
        },
      },
    );
    setIsUpdating(true);
    await request({ ...payload, id: callIdFromUrl });
    return submitErrors;
  };

  return {
    updateCallData,
  };
};
