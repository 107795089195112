import { v4 as uuid } from 'uuid';
import {
  calculateBodyErrors,
  checkUiCorrectionOnly,
  modifyCompanyDataAndBody,
} from '@/condor/application/actions/finsight/import/helpers';
import ImportRepository from '@/finsight/infrastructure/repository/ImportRepository';
import * as actionTypes from './actionTypes';
import selectors from './selectors';
import uploadSelectors from '../upload/selectors';
import createAction from '@/Framework/State/Redux/createAction';
import * as validationProcessTypes
  from '@/condor/ui/components/Finsight/Import/modules/Validation/validationProcessTypes';
import * as validationTypes from '@/condor/ui/components/Finsight/Import/modules/Validation/validationTypes';
import NotificationManager from '@/ui/shared/components/Notification/NotificationManager';

/**
 * @param {Object} payload
 */
export const savePayload = (payload) => createAction(
  actionTypes.CONDOR_FINSIGHT_IMPORT_BOND_VALIDATION_PAYLOAD,
  payload,
);

export const resetValidation = () => createAction(
  actionTypes.CONDOR_FINSIGHT_IMPORT_BOND_VALIDATION_RESET,
);

export const resetValidationWithoutCorrectionData = () => createAction(
  actionTypes.CONDOR_FINSIGHT_IMPORT_BOND_VALIDATION_RESET_WITHOUT_CORRECTION_DATA,
);

/**
 * @param {Boolean} payload
 */
export const changeCorrectionData = (payload) => createAction(
  actionTypes.CONDOR_FINSIGHT_IMPORT_BOND_VALIDATION_SET_CORRECTION_DATA,
  payload,
);

/**
 * @param {Boolean} payload
 */
export const changeUiCorrectionOnly = (payload) => createAction(
  actionTypes.CONDOR_FINSIGHT_IMPORT_BOND_VALIDATION_NEED_CORRECTION_FLAG,
  payload,
);

/**
 * @param {Boolean} payload
 */
export const changeReloadFileFlag = (payload) => createAction(
  actionTypes.CONDOR_FINSIGHT_IMPORT_BOND_VALIDATION_CAN_REIMPORT_CHANGE,
  payload,
);

/**
 * @param {Boolean} payload
 */
export const changeValidationTableVisibleState = (payload) => createAction(
  actionTypes.CONDOR_FINSIGHT_IMPORT_BOND_VALIDATION_TABLE_VISIBLE,
  payload,
);

/**
 * @param {Boolean} payload
 */
export const changeCompanySelectModalVisibleState = (payload) => createAction(
  actionTypes.CONDOR_FINSIGHT_IMPORT_BOND_VALIDATION_COMPANY_SELECT_VISIBLE,
  payload,
);

/**
 * @param {Array} payload
 */
export const changeCompanySelectModalCollection = (payload) => createAction(
  actionTypes.CONDOR_FINSIGHT_IMPORT_BOND_VALIDATION_COMPANY_COLLECTION_CHANGE,
  payload,
);

/**
 * @param {Boolean} payload
 */
export const changeCompanyCreateModalVisibleState = (payload) => createAction(
  actionTypes.CONDOR_FINSIGHT_IMPORT_BOND_VALIDATION_COMPANY_CREATE_VISIBLE,
  payload,
);

/**
 * @param {Object} payload
 */
export const initCompanyCreateModalCompanyData = (payload) => createAction(
  actionTypes.CONDOR_FINSIGHT_IMPORT_BOND_VALIDATION_COMPANY_CREATE_DATA_INIT,
  payload,
);

/**
 * @param {Object} payload
 */
export const changeActiveValidateCell = (payload) => createAction(
  actionTypes.CONDOR_FINSIGHT_IMPORT_BOND_VALIDATION_ACTIVE_VALIDATE_CELL_CHANGE,
  payload,
);

/**
 * @param {Object} payload
 */
const addBodyRows = (payload) => createAction(
  actionTypes.CONDOR_FINSIGHT_IMPORT_BOND_VALIDATION_ADD_BODY_ROWS,
  payload,
);
/**
 * @param {Object} payload
 */
const setBodyRows = (payload) => createAction(
  actionTypes.CONDOR_FINSIGHT_IMPORT_BOND_VALIDATION_SET_BODY_ROWS,
  payload,
);

/**
 * @param {Object} payload
 */
const setHeader = (payload) => createAction(
  actionTypes.CONDOR_FINSIGHT_IMPORT_BOND_VALIDATION_SET_HEADER,
  payload,
);

export const subscribeToFileImportingChange = (resetCallBack, { uuid }) => (dispatch, getState) => {
  const importRepository = getState().container.get(ImportRepository);

  importRepository.subscribeToFileImportingChange(
    (payload) => {
      dispatch(savePayload(payload));
      const errorsCount = calculateBodyErrors(selectors.getTableBody(getState()));
      // eslint-disable-next-line default-case
      switch (payload.status) {
        case validationTypes.VALIDATION_PROCESS:
          // eslint-disable-next-line default-case
          switch (payload.data.status) {
            case validationProcessTypes.header:
              dispatch(setHeader(payload.data));
              break;
            case validationProcessTypes.rowsInfo:
              dispatch(addBodyRows(payload.data));
              break;
            case validationProcessTypes.needCorrection:
              NotificationManager.error(
                `We have found ${ errorsCount } error${ errorsCount > 1 ? 's' : '' }. Please correct XLS template and reupload.`,
              );
              const body = selectors.getTableBody(getState());
              const header = selectors.getTableHeader(getState());
              dispatch(checkUiCorrectionOnly(body, header, changeUiCorrectionOnly));
              dispatch(changeValidationTableVisibleState(true));
              break;
          }
          break;
        case validationTypes.VALIDATION_ERROR:
          NotificationManager.error(
            `Unable to validate file - ${ payload.error.message }`,
          );
          if (errorsCount === 0) {
            dispatch(resetCallBack());
          } else {
            dispatch(changeValidationTableVisibleState(true));
          }
          break;
        case validationTypes.VALIDATION_COMPLETE:
          NotificationManager.success(
            `You have successfully uploaded ${ payload.data.count } bond${ payload.data.count > 1 ? 's' : '' }.`,
          );
          dispatch(resetCallBack());
          break;
      }
    },
    uuid,
  );
};

/**
 * @param {Object} selectCompany
 * @returns {function(*, *): void}
 */
export const onSelectCompany = (selectCompany) => (dispatch, getState) => {
  const { body, correctionData } = modifyCompanyDataAndBody(selectCompany, getState(), selectors);

  dispatch(setBodyRows(body));
  dispatch(changeCorrectionData(correctionData));
  dispatch(changeActiveValidateCell(null));
  dispatch(initCompanyCreateModalCompanyData({}));
  dispatch(changeCompanyCreateModalVisibleState(false));
  dispatch(changeCompanySelectModalVisibleState(false));
  if (selectors.isUiCorrectionOnly(getState()) && calculateBodyErrors(selectors.getTableBody(getState())) === 0) {
    dispatch(changeReloadFileFlag(true));
  }
};

export const bondImport = (correctionData = {}) => async (dispatch, getState) => {
  const importRepository = getState().container.get(ImportRepository);

  await importRepository.bondImport({
    importId: uuid(),
    filePath: uploadSelectors.getUploadFilePath(getState()),
    fileName: uploadSelectors.getUploadFileName(getState()),
    fileId: uploadSelectors.getUploadFileProcessId(getState()),
    correctionData,
  });
};

export const unsubscribeToFileImportingChange = () => async (dispatch, getState) => {
  const importRepository = getState().container.get(ImportRepository);
  await importRepository.unsubscribeToFileImportingChange();
};

export const reimportFile = () => (dispatch, getState) => {
  const correctionData = selectors.getCorrectionData(getState());
  dispatch(resetValidationWithoutCorrectionData());
  dispatch(bondImport(correctionData));
};
