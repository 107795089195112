import { ITradeItem } from '@/condor/domain/vo/types';
import format from 'date-fns/format';
import { YEAR_MONTH_DAY_DASH_FORMAT } from '@/Framework/DateTime/dateFormats';
import { TRACE_TABLE_CELL_MAP } from '@/condor/ui/components/Finsight/TraceManager/constants';

export const getExpandedCellData = ({ row }: { row: ITradeItem }) => Object.entries(row)
    .reduce((acc, [key, value]: [key: string, value: any]) => {
      if (key.toLowerCase().includes('date') && value) {
        const valueToFormat = value?.dateTime || value;
        return { ...acc, [key]: format(new Date(valueToFormat), YEAR_MONTH_DAY_DASH_FORMAT) };
      }

      if (TRACE_TABLE_CELL_MAP.some((group) => !!group[key])) {
        const v = typeof value === 'object' ? (value?.name || value?.abbreviation || value?.text) : value;
        return { ...acc, [key]: v };
      }

      return acc;
    }, {});
