import { validateMaxLength } from '@/ui/shared/validators/validateMaxLength';
import { validateMinLength } from '@/ui/shared/validators/validateMinLength';
import { validateRequiredFields } from '@/ui/shared/validators/validateRequiredFields';
import {
  groupValidators,
} from '@/ui/shared/validators/helpers';

const paramsRequiredFields = [
  { fieldName: 'title', fieldCaption: 'Header Text' },
];

export default (values) => groupValidators(
  validateRequiredFields(paramsRequiredFields, values),
  validateMinLength({ fieldName: 'title', fieldCaption: 'Deal Name:', value: values.title, length: 3 }),
  validateMaxLength({ fieldName: 'title', fieldCaption: 'Deal Name:', value: values.title, length: 255 }),
);
