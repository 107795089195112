import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAbsSectorList } from '@/dealroadshow/application/actions/absSector/data/actions';
import * as absSectorSelectors from '@/dealroadshow/application/actions/absSector/selectors';
import Subsector from './Subsector';

function mapStateToProps(state) {
  return {
    collection: absSectorSelectors.getCollection(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onDidMount: getAbsSectorList,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Subsector);
