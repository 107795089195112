import React from 'react';
import { Element as SlateElement } from 'slate';
import { RenderElementProps } from 'slate-react';
import Link from './Link';

const Element = (props: RenderElementProps) => {
  const { attributes, children, element } = props;

  switch ((element as SlateElement & { type?: string }).type) {
    case 'alignLeft':
      return <div style={ { textAlign: 'left' } } { ...attributes }>{ children }</div>;
    case 'alignCenter':
      return <div style={ { textAlign: 'center' } } { ...attributes }>{ children }</div>;
    case 'alignRight':
      return <div style={ { textAlign: 'right' } } { ...attributes }>{ children }</div>;
    case 'link':
      return <Link { ...props }>{ children }</Link>;
    case 'bulleted-list':
      return (
        <ul { ...attributes }>
          { children }
        </ul>
      );
    case 'list-item':
      return (
        <li { ...attributes }>
          { children }
        </li>
      );
    case 'numbered-list':
      return (
        <ol { ...attributes }>
          { children }
        </ol>
      );
    default:
      return <p { ...attributes }>{ children }</p>;
  }
};

export default Element;
