import flatten from 'lodash/flatten';
import urlJoin from 'url-join';
import config from '@/Framework/config';

export default {
  getUrl(uris: Array<string> | string = []) {
    if (!Array.isArray(uris)) {
      uris = [uris];
    }
    uris = flatten(uris).filter((uri) => !!uri);
    return urlJoin(`${ config.protocol }//${ config.tenant.openmarket.hostname }`, ...uris);
  },

  getEncodedUrl(url: string): string {
    const callbackUrl = this.getUrl(url);
    return encodeURIComponent(callbackUrl);
  },

  getRpcUrl(): string {
    return `${ config.tenant.openmarket.api.external }/proxy/api/rpc`;
  },
};
