import isValid from 'date-fns/isValid';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import { getFormatInTimeZoneDate } from '@/Framework/DateTime/getFormatInTimeZoneDate';
import parseDateString from '@/Framework/DateTime/parseDateString';
import * as dateFnsDateFormats from '@/Framework/DateTime/dateFormats';

export const getDisplayDate = (
  value: Date,
  timeZone = 'America/New_York',
): {
  day: string,
  today: string,
  format?: string,
} => {
  if (value instanceof Date && isValid(value)) {
    return {
      day: formatInTimeZone(value, timeZone, dateFnsDateFormats.MONTH_DAY_YEAR_FORMAT),
      today: formatInTimeZone(value, timeZone, dateFnsDateFormats.TIME_FORMAT),
    };
  }

  return { day: '-', today: '-' };
};

export const getCellDisplayDate = (
  value: string | Date,
  timeZone = 'America/New_York',
  withoutTime = false,
  inputFormat = 'yyyy-MM-dd HH:mm:ss',
) => {
  const date = value instanceof Date ? value : parseDateString(value, null, inputFormat);
  const { day, today } = getDisplayDate(date, timeZone);
  return withoutTime ? day : `${ day } ${ today } `;
};

export const getDisplayFormatDate = (
  dateStr: string,
  timeZone = 'America/New_York',
) => {
  const date = dateStr.replace(' ', 'T');

  if (isValid(new Date(date))) {
    return {
      day: getFormatInTimeZoneDate(date, timeZone, dateFnsDateFormats.MONTH_DAY_YEAR_FORMAT),
      today: getFormatInTimeZoneDate(date, timeZone, dateFnsDateFormats.TIME_FORMAT),
    };
  }

  return { day: '-', today: '-' };
};
