import { Switch } from 'react-router';

import Route from '@/ui/shared/components/ReactRouter/Route';
import * as constants from '@/condor/ui/common/constants';

import ProfilesList from '../Profiles/ProfilesList';
import EventsList from '@/openMarket/ui/common/Condor/EventsList';
import { useOpenMarketListContext } from '@/openMarket/ui/components/Condor/OpenMarketList/OpenMarketListContext';

const OpenMarketBody = () => {
  const { getCounters } = useOpenMarketListContext();

  return (
    <Switch>
      <Route exact path={ constants.CONDOR_FINSIGHT_OPEN_MARKET_PROFILES } component={ ProfilesList } />
      <Route
        exact
        path={ constants.CONDOR_FINSIGHT_OPEN_MARKET_EVENTS }
        component={ () => <EventsList onDeleteSuccess={ getCounters } /> }
      />
    </Switch>
  );
};

export default OpenMarketBody;
