export const CMBS_SECTOR_ID = 'd023f155-2b59-46d8-8332-82d8f4ff33d5';
export const CMBS_SECTOR_ABBREVIATION = 'CMBS';
export const OTHER_SECTOR = {
  name: 'Other',
  abbreviation: 'OTHR',
  color: '#BFCCD8',
};

class Sector {
  static isCmbsSectorId(sectorId) {
    return sectorId === CMBS_SECTOR_ID;
  }
}

export default Sector;
