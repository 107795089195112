import { groupValidators } from '@/ui/shared/validators/helpers';
import { validateMinLength } from '@/ui/shared/validators/validateMinLength';
import { validateMaxLength } from '@/ui/shared/validators/validateMaxLength';

const validateConferenceId = (conferenceIdField) => groupValidators(
  validateMinLength({ ...conferenceIdField, length: 4 }),
  validateMaxLength({ ...conferenceIdField, length: 10 }),
);

export default validateConferenceId;
