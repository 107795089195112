import { History } from '@/Framework/DI/Providers/History';

import createAsyncRequest from '@/Framework/State/Redux/createAsyncRequest';
import createAction from '@/Framework/State/Redux/createAction';
import { getItemActions } from '@/Framework/State/Redux/itemFactory';
import { getQueryStringParams } from '@/Framework/url/helpers/queryString';

import actionTypes from './actionTypes';
import selectors from './selectors';
import filingSelectors from '@/condor/application/actions/finsight/filings/item/selectors';
import * as constants from '@/condor/ui/common/constants';
import { ISSUER_QUERY_PARAM_NAME } from './constants';
import { COMPANY, ISSUER } from '@/finsight/application/actions/pageFilters/constants';
import { DEAL_PROFILE_FIELDS } from '@/condor/ui/components/Finsight/DealProfile/constants';
import { getCompanyFieldOptionMapperByFieldName, prepareGetDealByIdPayload } from './helpers';
import { mapFilingToDeal } from '@/condor/application/actions/finsight/filings/item/actions';

import DealRepository from '@/finsight/infrastructure/repository/DealRepository';
import RatingAgencyRepository from '@/finsight/infrastructure/repository/RatingAgencyRepository';
import CountryRepository from '@/finsight/infrastructure/repository/CountryRepository';
import RegionRepository from '@/finsight/infrastructure/repository/RegionRepository';
import ProductRepository from '@/finsight/infrastructure/repository/ProductRepository';
import SectorRepository from '@/finsight/infrastructure/repository/SectorRepository';
import CurrencyRepository from '@/finsight/infrastructure/repository/CurrencyRepository';
import FinsightCompanyRepository from '@/finsight/infrastructure/repository/CompanyRepository';

/**
 * @param {String} issuerId
 */
export const openIssuerDealsPage = (issuerId) => (dispatch, getState) => {
  const history = getState().container.get(History);
  const url = `${ constants.CONDOR_FINSIGHT_COMPANY_DEALS_BASE_URL }/${ issuerId }`;

  history.push(url);
};

export const {
  getItem: getDealById,
  createItem: createDeal,
  updateItem: updateDeal,
  removeItem: deleteDeal,
  reset,
} = getItemActions(
  actionTypes,
  {
    get: {
      method: (id, dispatch, getState) => {
        const dealRepository = getState().container.get(DealRepository);
        return dealRepository.getDealsForCondorAdmin(prepareGetDealByIdPayload(id));
      },
      callbacks: {
        responseHandler: (response) => response.collection[0],
      },
    },
    create: {
      method: (payload, dispatch, getState) => {
        const dealRepository = getState().container.get(DealRepository);
        return dealRepository.createDeal(payload);
      },
      callbacks: {
        onSuccess: ({ dispatch, payload }) => dispatch(openIssuerDealsPage(
          payload[DEAL_PROFILE_FIELDS.PRIMARY_ISSUER.name],
        )),
      },
    },
    update: {
      method: (payload, dispatch, getState) => {
        const dealRepository = getState().container.get(DealRepository);
        return dealRepository.updateDeal(payload);
      },
      callbacks: {
        onSuccess: ({ dispatch, payload }) => dispatch(openIssuerDealsPage(
          payload[DEAL_PROFILE_FIELDS.PRIMARY_ISSUER.name],
        )),
      },
    },
    remove: {
      method: (payload, dispatch, getState) => {
        const dealRepository = getState().container.get(DealRepository);
        return dealRepository.deleteDealById(payload);
      },
      callbacks: {
        onSuccess: ({ dispatch, getState }) => dispatch(openIssuerDealsPage(
          selectors.getItemData(getState()).primaryIssuer.id,
        )),
      },
    },
  },
);

/**
 * @param {Object} initialIssuerId
 */
export const getDealCardInitialData = (initialIssuerId) => (dispatch, getState) => {
  const dealRepository = getState().container.get(DealRepository);
  const finsightCompanyRepository = getState().container.get(FinsightCompanyRepository);

  return dispatch(createAsyncRequest(
    {
      types: [
        actionTypes.GET_CONDOR_DEAL_CARD_INITIAL_DATA,
        actionTypes.GET_CONDOR_DEAL_CARD_INITIAL_DATA_SUCCESS,
        actionTypes.GET_CONDOR_DEAL_CARD_OPTIONS_DATA_ERROR,
      ],
      method: () => Promise.all(
        [
          finsightCompanyRepository.getCompanyById({ id: initialIssuerId }),
          dealRepository.getDealsForCondorAdmin(
            {
              perPage: 1,
              filterType: COMPANY,
              sortBy: 'pricingDate',
              sortOrder: 'desc',
              filters: {
                includedCompanyId: initialIssuerId,
                includedCompanyRole: ISSUER,
                hasPricingDate: true,
              },
            },
          ),
        ],
      ),
    },
  ));
};

/**
 * @param {String} dealId
 * @param {String} issuerId
 */
export const initDealProfile = (dealId, issuerId) => async (dispatch, getState) => {
  const history = getState().container.get(History);
  const isCreateNewDeal = history.location.pathname.includes('create');

  if (isCreateNewDeal || issuerId) {
    const initialIssuerId = issuerId || getQueryStringParams(history.location)[ISSUER_QUERY_PARAM_NAME];

    if (initialIssuerId) {
      await dispatch(getDealCardInitialData(initialIssuerId));
    }
  }

  const dealRepository = getState().container.get(DealRepository);
  const ratingAgencyRepository = getState().container.get(RatingAgencyRepository);
  const countryRepository = getState().container.get(CountryRepository);
  const regionRepository = getState().container.get(RegionRepository);
  const productRepository = getState().container.get(ProductRepository);
  const sectorRepository = getState().container.get(SectorRepository);
  const currencyRepository = getState().container.get(CurrencyRepository);

  await Promise.all([
    ...(isCreateNewDeal ? [] : [dispatch(getDealById(dealId))]),
    dispatch(createAsyncRequest(
      {
        types: [
          actionTypes.GET_CONDOR_DEAL_CARD_OPTIONS_DATA,
          actionTypes.GET_CONDOR_DEAL_CARD_OPTIONS_DATA_SUCCESS,
          actionTypes.GET_CONDOR_DEAL_CARD_OPTIONS_DATA_ERROR,
        ],
        method: () => Promise.all(
          [
            ratingAgencyRepository.getRatingAgencies(),
            countryRepository.getCountriesList(),
            productRepository.getList(),
            regionRepository.getList(),
            sectorRepository.getList(),
            currencyRepository.getList(),
            dealRepository.getRankingsList(),
            dealRepository.getCouponTypesList(),
            dealRepository.getRegistrationsList(),
            dealRepository.getBenchmarksList(),
          ],
        ),
      },
    )),
  ]);

  dispatch(createAction(actionTypes.INITIALIZE_CONDOR_DEAL_CARD));
};

/**
 * @param {String} query
 * @param {String} fieldName
 * @param {String} includedRole
 * @param {Function} callback
 * @param {Array} [excludedIds]
 */
export const fetchDealCardFieldCompaniesOptions = (
  {
    query,
    fieldName,
    includedRole,
    excludedIds,
    callback,
  },
) => (dispatch, getState) => {
  const finsightCompanyRepository = getState().container.get(FinsightCompanyRepository);

  return dispatch(createAsyncRequest(
    {
      payload: {
        page: 1,
        perPage: 50,
        query,
        orderBy: 'name',
        sortOrder: 'asc',
        filter: {
          includedRole,
          excludedIds,
        },
      },
      method: finsightCompanyRepository.getCompaniesListAggregate,
      types: [
        actionTypes.FETCH_CONDOR_DEAL_CARD_FIELD_COMPANIES_OPTIONS,
        actionTypes.FETCH_CONDOR_DEAL_CARD_FIELD_COMPANIES_OPTIONS_SUCCESS,
        actionTypes.FETCH_CONDOR_DEAL_CARD_FIELD_COMPANIES_OPTIONS_ERROR,
      ],
      callbacks: {
        /* TODO @konstantinov check please to use redux store here after merged ticket FIN-7369
        * because react-select in ver >2.0 get async options from callback inside api in action
        * loadOptions(query, callback). Maybe needed to remove reducer and save in store options?
        */
        responseHandler: (response) => ({ fieldName, ...response }),
        onSuccess: ({ response }) => {
          if (callback) {
            callback(response.collection.map(getCompanyFieldOptionMapperByFieldName(fieldName)));
          }
        },
      },
    },
  ));
};

/**
 * @param {String} fieldName
 */
export const clearDealCardCompanyFieldOptions = (fieldName) => createAction(
  actionTypes.CLEAR_DEAL_CARD_COMPANY_FIELD_OPTIONS,
  fieldName,
);

/**
 * @param {Object} payload
 */
export const createDealWithMapToFiling = (payload) => (dispatch, getState) => {
  const dealRepository = getState().container.get(DealRepository);

  return dispatch(createAsyncRequest(
    {
      payload,
      method: dealRepository.createDeal,
      types: [
        actionTypes.CREATE_DEAL_WITH_MAP_TO_FILING,
        actionTypes.CREATE_DEAL_WITH_MAP_TO_FILING_SUCCESS,
        actionTypes.CREATE_DEAL_WITH_MAP_TO_FILING_ERROR,
      ],
      callbacks: {
        onSuccess: ({ dispatch, response }) => dispatch(mapFilingToDeal(response.collection[0].id)),
      },
    },
  ));
};

/**
 * @param {Object} formData
 */
export const updateDealWithMapToFiling = (formData) => async (dispatch, getState) => {
  const dealId = filingSelectors.getDealId(getState());
  const payload = {
    dealId,
    primaryIssuerId: formData.primaryIssuerId,
    parentId: formData.parentId,
    seriesName: formData.seriesName,
    countryId: formData.countryId,
    productId: formData.productId,
    sectorId: formData?.sectorId || null,
    subsectorId: formData?.subsectorId || null,
    additionalIssuerIds: formData.additionalIssuerIds,
    regionIds: formData.regionIds,
    structuringLeads: formData.structuringLeads,
    jointLeads: formData.jointLeads,
  };

  const dealRepository = getState().container.get(DealRepository);

  dispatch(createAsyncRequest(
    {
      payload,
      method: dealRepository.partialDealUpdate,
      types: [
        actionTypes.UPDATE_DEAL_WITH_MAP_TO_FILING,
        actionTypes.UPDATE_DEAL_WITH_MAP_TO_FILING_SUCCESS,
        actionTypes.UPDATE_DEAL_WITH_MAP_TO_FILING_ERROR,
      ],
      callbacks: {
        onSuccess: ({ dispatch, response }) => dispatch(mapFilingToDeal(response.collection[0].id)),
      },
    },
  ));
};
