const getMetaData = (state) => state.condor.accounts.mappings.metadata;

export const isMappingsModalVisible = (state) => getMetaData(state).isMappingsModalVisible;

export const isMappingsProgressModalVisible = (state) => getMetaData(state).isMappingsProgressModalVisible;

export const isFetching = (state) => getMetaData(state).isFetching;

export const getRemappedItemCounts = (state) => getMetaData(state).remappedItemCounts;

export const getMappingsModalTitle = (state) => getMetaData(state).mappingsTitle;

export const getAccountsOptions = (state) => getMetaData(state).accountsOptions;

export const getMappingsTypes = (state) => getMetaData(state).mappingsTypes;

export const isMappingFinished = (state) => getMetaData(state).isFinished;

export const isError = (state) => getMetaData(state).isError;
