import startsWith from 'lodash/startsWith';

export default class PhoneNumber {
  /**
   * @param {String} phone
   * @return {String}
   */
  static getPhoneCode(phone = '') {
    if (phone && startsWith(phone, '+')) {
      return phone.substr(0, phone.indexOf(' '));
    }

    return '+1';
  }

  /**
   * Gets prepared phone number.
   * If phone number is 10 digits and doesn't have "+" at the beginning
   * we should assume US format and include US country code.
   *
   * @param {String} phone
   * @return {String}
   */
  static fixUsPhoneNumber(phone) {
    if (phone && !startsWith(phone, '+') &&
        phone.replace(/\D/g, '').length === 10
    ) {
      return `+1 ${ phone }`;
    }

    return phone;
  }
}

/**
 * Gets prepared phone number.
 * If phone number is 10 digits and doesn't have "+" at the beginning
 * we should assume US format and include US country code.
 *
 * @param {String} phone
 * @return {String}
 */
export function fixUsPhoneNumber(phone) {
  if (phone && !startsWith(phone, '+') &&
      phone.replace(/\D/g, '').length === 10
  ) {
    return `+1 ${ phone }`;
  }

  return phone;
}
