import { connect } from 'react-redux';
import { change } from 'redux-form';
import IssuerField from './IssuerField';

import {
  CONDOR_FINSIGHT_COMPANY_DEAL_TABLE_INLINE_EDIT_FORM_NAME,
} from '@/condor/ui/common/constants';

const mapDispatchToProps = {
  setIssuerTicker: (issuer) => change(
    CONDOR_FINSIGHT_COMPANY_DEAL_TABLE_INLINE_EDIT_FORM_NAME,
    'issuerTicker',
    (issuer.issuerTicker || ''),
  ),
};

export default connect(
  null,
  mapDispatchToProps,
)(IssuerField);
