import pick from 'lodash/pick';
import decodeSymbols from '@/Framework/dataHelpers/string/decodeSymbols';
import {
  IEnhancedTaggingRoadshow,
  IEnhancedTaggingRoadshowUpdate,
  UPDATABLE_FIELDS,
} from '@/condor/domain/vo/EnhancedTagging/Roadshow';

const getDealSize = (dealSize) => {
  if (!dealSize) {
    return null;
  }

  if (typeof dealSize === 'string') {
    return Number(dealSize.replace(/,/g, ''));
  }

  return dealSize;
};

export const clearRoadshowForUpdate = (roadshow: IEnhancedTaggingRoadshow): IEnhancedTaggingRoadshowUpdate => ({
  ...pick(roadshow, ...UPDATABLE_FIELDS),
  dealSize: getDealSize(roadshow.dealSize),
  name: decodeSymbols(roadshow.name),
});
