import { ReactNode, createContext, useContext } from 'react';

interface IProps {
  config: { [key: string]: any },
  state: { [key: string]: any },
  onChange: (type: string, value: string, name: string) => void,
  children: ReactNode,
}

export interface IFilterContextTypes {
  config: { [key: string]: any },
  state: { [key: string]: any },
  onChange: (...args: any) => void,
}

export const FilterContext = createContext<IFilterContextTypes>(null);

const Filter = ({ children, ...childProps }: IProps) => (
  <FilterContext.Provider value={ childProps }>
    { children }
  </FilterContext.Provider>
);

export const useFilterContext = () => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error('useFilterContext must be used within the FilterContext');
  }
  return context;
};

export default Filter;
