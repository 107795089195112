import config from '@/Framework/config';
import { IMeetingQuestions, IOaccQuestions, IRadioButtonAnswer, IWebinarQuestions } from './interfaces';
import { CallEventType } from '@/evercall/domain/vo/call/CallEventType';
import { IDefaultSelectOption } from '@dealroadshow/uikit';

export const TIME_INTERVAL = 5;
export const DEFAULT_TIME_ZONE = {
  id: '64953181-82fa-4413-9f94-0c47a4b2365b',
  timeZone: 'America/New_York',
  abbr: 'ET',
  name: 'Eastern Time',
  priorityCount: 0,
};

export const CALL_SCHEDULE_DISCLAIMER = 'Calls must be scheduled at least 6 hours in advance. A 50% fee applies if call is cancelled or rescheduled within 24 hours of call start.';
export const WEBINAR_SCHEDULE_DISCLAIMER = 'Webinars must be scheduled at least 6 hours in advance. A 50% fee applies if event is cancelled or rescheduled within 24 hours of event start.';

export const DEFAULT_COUNTRY_NAME = 'United States';

export const DEFAULT_WEBINAR_PLATFORM_NAME = 'Zoom';

export const EVERCALL_EVENT_TYPE_OPTIONS: IDefaultSelectOption[] = [
  {
    value: CallEventType.Oacc,
    label: 'Audio Conference',
  },
  {
    value: CallEventType.Webinar,
    label: 'Video Conference (Webinar)',
  },
  {
    value: CallEventType.Meeting,
    label: 'Video Meeting (Small Group)',
  },
];

export const QUESTION_TYPES = {
  CHECKBOX_ONLY: 1,
  RADIO_ONLY: 2,
  PSEUDO_RADIO: 3, // looks as 2-option radio button on Evercall and as checkbox on DM Portal
};

const getPseudoRadioButtonsAnswers = (
  clarificationForPositive = '',
  clarificationForNegative = '',
): Array<IRadioButtonAnswer> => (
  [
    {
      value: 'Yes',
      label: `Yes${ clarificationForPositive ? `, ${ clarificationForPositive }` : '' }`,
      dataTest: 'yes',
    },
    {
      value: 'No',
      label: `No${ clarificationForNegative ? `, ${ clarificationForNegative }` : '' }`,
      dataTest: 'no',
    },
  ]
);

export const QUESTIONS_WEBINAR: IWebinarQuestions = {
  // Participant check-in process
  DRS_REGISTRATION: {
    type: QUESTION_TYPES.CHECKBOX_ONLY,
    name: 'Registration',
    explanation: 'Deal Managers are responsible for sharing the registration link ' +
      'with investors. Upon registering, investors receive join credentials for automatic check-in. Deal Managers can gather essential ' +
      'information from investors, such as their Name, Email, Company and Title.',
    title: {
      [config.tenant.tenantEvercall.code]: 'Via a registration form',
      [config.tenant.dmPortal.code]: 'Registration',
    },
    dataTest: 'drsRegistation',
  },
  JOIN_VIA_LINK: {
    type: QUESTION_TYPES.CHECKBOX_ONLY,
    name: 'Join_Via_Link',
    explanation: 'Deal Managers must provide investors with the ' +
      'join link and a password to join the webinar seamlessly. Note that this may result in inaccurate or insufficient ' +
      'investor analytics being collected.',
    title: {
      [config.tenant.tenantEvercall.code]: 'Via a direct link',
      [config.tenant.dmPortal.code]: 'Join via link',
    },
    dataTest: 'joinViaLink',
  },
  INVESTOR_BACKUP: {
    type: QUESTION_TYPES.PSEUDO_RADIO,
    name: 'backupPhoneDialIn',
    title: {
      [config.tenant.tenantEvercall.code]: 'Do you wish to offer investors backup phone dial-ins?',
      [config.tenant.dmPortal.code]: 'Offer investors backup phone dial-ins',
    },
    radioButtonAnswers: getPseudoRadioButtonsAnswers('offer investors backup phone dial-ins'),
  },
  CUSTOM_SCRIPT: {
    type: QUESTION_TYPES.PSEUDO_RADIO,
    name: 'customScriptWebinar',
    title: {
      [config.tenant.tenantEvercall.code]: 'Will you provide a custom script for the Evercall Moderator to introduce the call?',
      [config.tenant.dmPortal.code]: 'Client to provide custom script for call introduction',
    },
    radioButtonAnswers: getPseudoRadioButtonsAnswers(
      'a custom script will be provided',
      'use Evercall’s standard professional introduction',
    ),
  },
  QUESTION_VERBAL: {
    type: QUESTION_TYPES.CHECKBOX_ONLY,
    name: 'verbalQaSession',
    title: {
      [config.tenant.tenantEvercall.code]: 'Yes, Verbal',
      [config.tenant.dmPortal.code]: 'Verbal Question & Answer (Q&A) session',
    },
  },
  QUESTION_WRITTEN: {
    type: QUESTION_TYPES.CHECKBOX_ONLY,
    name: 'writtenQaSession',
    title: {
      [config.tenant.tenantEvercall.code]: 'Yes, Written',
      [config.tenant.dmPortal.code]: 'Written Question & Answer (Q&A) session',
    },
  },
  SPEAKER_PRESENTING_PROCESS: {
    type: QUESTION_TYPES.RADIO_ONLY,
    name: 'speakerPresentingProcessWebinar',
    title: 'Do the presenters wish to present live or use pre-recorded video?',
    radioButtonAnswers: [
      {
        value: 'Speak_Live',
        label: {
          [config.tenant.tenantEvercall.code]: 'Speakers will present live',
          [config.tenant.dmPortal.code]: 'Speakers will present live',
        },
        dataTest: 'speakLive',
      },
      {
        value: 'Playback_By_Client',
        label: 'Use recorded video playback, with client provide the fully edited media file to Evercall',
        dataTest: 'playbackByClient',
      },
      {
        value: 'Playback_By_Evercall',
        label: 'Use recorded video playback, with Evercall to record, edit and produce the media',
        dataTest: 'playbackByEvercall',
      },
    ],
  },
  SEPARATED_SESSION_LINES: {
    type: QUESTION_TYPES.PSEUDO_RADIO,
    name: 'separatedSessions',
    title: {
      [config.tenant.tenantEvercall.code]: 'Would you like this call to be separated into Public & Private sessions?',
      [config.tenant.dmPortal.code]: 'Public and Private Sessions',
    },
    radioButtonAnswers: getPseudoRadioButtonsAnswers(
      'I would like to have participants be placed into separate Public and Private sessions',
      'not applicable',
    ),
  },
  CALL_TRANSCRIPTION: {
    type: QUESTION_TYPES.PSEUDO_RADIO,
    name: 'transcriptionServicesWebinar',
    title: {
      [config.tenant.tenantEvercall.code]: 'Would you like Evercall to transcribe this call?',
      [config.tenant.dmPortal.code]: 'Call Transcription',
    },
    radioButtonAnswers: getPseudoRadioButtonsAnswers('send me the call transcription', 'not applicable'),
  },
  MP4: {
    type: QUESTION_TYPES.RADIO_ONLY,
    name: 'callRecordingProcess',
    title: {
      [config.tenant.tenantEvercall.code]: 'Would you like a MP4 recording of this call?',
      [config.tenant.dmPortal.code]: 'Would you like to record this event as MP4?',
    },
    radioButtonAnswers: [
      {
        value: 'Unedited_MP4',
        label: 'Yes, unedited MP4',
        dataTest: 'uneditedMP4',
      },
      {
        value: 'Edited_MP4',
        label: 'Yes, edit MP4 (replica of attendee view plus edits)',
        dataTest: 'editedMp4',
      },
      {
        value: 'Not_Applicable',
        label: 'No, not applicable',
        dataTest: 'notApplicable',
      },
    ],
  },
  REPLAY_STREAM: {
    type: QUESTION_TYPES.CHECKBOX_ONLY,
    name: 'uneditedReplayHostedByThirdParty',
    title: {
      [config.tenant.tenantEvercall.code]: 'Replay stream available via webinar platform (unedited)',
      [config.tenant.dmPortal.code]: 'Replay stream available via webinar platform (unedited)',
    },
  },
  REPLAY_PLAYER: {
    type: QUESTION_TYPES.CHECKBOX_ONLY,
    name: 'editedReplayHostedOnDrs',
    title: {
      [config.tenant.tenantEvercall.code]: 'Replay available via Clickable Media Player on DealRoadshow (edited)',
      [config.tenant.dmPortal.code]: 'Replay available via Clickable Media Player on Deal Roadshow (edited)',
    },
  },
  ADDITIONAL_REPLAY: {
    type: QUESTION_TYPES.CHECKBOX_ONLY,
    name: 'additionalPremiumReplay',
    explanation: 'Webinar replay edited and hosted on Deal Roadshow (synced with slides, if applicable). ' +
      'Note: Additional costs may apply.',
    title: {
      [config.tenant.tenantEvercall.code]: 'Additional premium Replay options available ',
      [config.tenant.dmPortal.code]: 'Additional premium Replay options available',
    },
  },
};

export const QUESTIONS_OACC: IOaccQuestions = {
  // Participant check-in process
  DRS_WITH_EMBEDDED_DIAL_INS: {
    type: QUESTION_TYPES.CHECKBOX_ONLY,
    name: 'Deal_Roadshow_With_Embedded_Dial_Ins',
    explanation: 'Deal Manager must provide Investors with Deal Roadshow direct link. They will obtain individualized dial-in details after logging into the roadshow and will be automatically checked-in upon dialing into the call.',
    title: {
      [config.tenant.tenantEvercall.code]: 'Via Deal Roadshow with embedded dial-ins, and live operator check-in as backup',
      [config.tenant.dmPortal.code]: 'Deal Roadshow with Embedded Dial-Ins (Recommended. Includes Listen via Web.)',
    },
    dataTest: 'drsWithEmbeddedDialIns',
  },
  INSTANT_CHECK_IN: {
    type: QUESTION_TYPES.CHECKBOX_ONLY,
    name: 'Pre_Registration', // TODO: replace with "Instant_Check_In" as a part of tech task in the future
    explanation: 'Deal Managers must provide investors with the Event Page link where they can input their email address to receive conference access details instantly.',
    title: 'Instant Check-In',
    dataTest: 'instantCheckIn',
  },
  LIVE_OPERATOR: {
    type: QUESTION_TYPES.CHECKBOX_ONLY,
    name: 'Live_Operator',
    explanation: 'Deal Manager must provide Investors with dial-in details. They will then be checked-in manually by a live operator. Note that this method may result in longer check-in times.',
    title: {
      [config.tenant.tenantEvercall.code]: 'Via live operator only',
      [config.tenant.dmPortal.code]: 'Live Operator',
    },
    dataTest: 'liveOperator',
  },
  // Advanced call options
  REGISTRATION_AHEAD: {
    type: QUESTION_TYPES.PSEUDO_RADIO,
    name: 'registrationAhead',
    title: {
      [config.tenant.tenantEvercall.code]: 'Would you like to allow investors to register ahead of the call?',
      [config.tenant.dmPortal.code]: 'Allow investors to register ahead of the call',
    },
    radioButtonAnswers: getPseudoRadioButtonsAnswers('allow investors to register ahead of the call'),
  },
  CUSTOM_SCRIPT: {
    type: QUESTION_TYPES.PSEUDO_RADIO,
    name: 'customScript',
    title: {
      [config.tenant.tenantEvercall.code]: 'Will you provide a custom script for the Evercall Moderator to introduce the call?',
      [config.tenant.dmPortal.code]: 'Client to provide custom script for call introduction',
    },
    radioButtonAnswers: getPseudoRadioButtonsAnswers(
      'a custom script will be provided',
      'use Evercall’s standard professional introduction',
    ),
  },
  ROADSHOW_SHARED_PRESENTATION: {
    type: QUESTION_TYPES.PSEUDO_RADIO,
    name: 'roadshowSharedPresentation',
    title: {
      [config.tenant.tenantEvercall.code]: 'Will a presentation be shared via Deal Roadshow simultaneously with this call?',
      [config.tenant.dmPortal.code]: 'Presentation to be shared via Deal Roadshow for this call',
    },
    radioButtonAnswers: getPseudoRadioButtonsAnswers('this call will use Deal Roadshow'),
  },
  QA_SESSION: {
    type: QUESTION_TYPES.PSEUDO_RADIO,
    name: 'qaSession',
    title: {
      [config.tenant.tenantEvercall.code]: 'Will there be a live Q&A session after the prepared remarks?',
      [config.tenant.dmPortal.code]: 'Live Question & Answer (Q&A) session after the presentation',
    },
    radioButtonAnswers: getPseudoRadioButtonsAnswers('there will be a Q&A session'),
  },
  // Add-ons
  SPEAKER_PRESENTING_PROCESS: {
    type: QUESTION_TYPES.RADIO_ONLY,
    name: 'speakerPresentingProcess',
    title: {
      [config.tenant.tenantEvercall.code]: 'Will Speakers be presenting live or use a pre-recorded audio?',
      [config.tenant.dmPortal.code]: 'Do the presenters wish to speak live or use pre-recorded audio?',
    },
    radioButtonAnswers: [
      {
        value: 'Speak_Live',
        label: {
          [config.tenant.tenantEvercall.code]: 'Speakers will present live',
          [config.tenant.dmPortal.code]: 'Speak Live',
        },
        dataTest: 'speakLive',
      },
      {
        value: 'Playback_By_Client',
        label: 'Use recorded audio playback, with client provide the fully edited audio file to Evercall',
        dataTest: 'playbackByClient',
      },
      {
        value: 'Playback_By_Evercall',
        label: 'Use recorded audio playback, with Evercall to record, edit and produce the audio',
        dataTest: 'playbackByEvercall',
      },
    ],
  },
  SEPARATED_CONFERENCE_LINES: {
    type: QUESTION_TYPES.PSEUDO_RADIO,
    name: 'separatedConferenceLines',
    title: {
      [config.tenant.tenantEvercall.code]: 'Would you like this call to be separated into Public & Private conferences?',
      [config.tenant.dmPortal.code]: 'Public and Private Conference Lines',
    },
    radioButtonAnswers: getPseudoRadioButtonsAnswers(
      'I would like to have participants be placed into separate Public and Private conference lines',
      'not applicable',
    ),
  },
  QA_DASHBOARD: {
    type: QUESTION_TYPES.PSEUDO_RADIO,
    name: 'qaDashboard',
    explanation: 'Allows you to chat with the moderator, view participants and choose who to select for Q&A.',
    title: {
      [config.tenant.tenantEvercall.code]: 'Would you like to use our Q&A Dashboard?',
      [config.tenant.dmPortal.code]: 'Use the Speaker/Q&A Dashboard',
    },
    radioButtonAnswers: getPseudoRadioButtonsAnswers(
      'I would like to chat with the moderator, view participants and choose who to call on for Q&A',
      'not applicable',
    ),
  },
  ROADSHOW_ASK_QUESTION: {
    type: QUESTION_TYPES.PSEUDO_RADIO,
    name: 'roadshowAskQuestion',
    explanation: 'Enabling Ask a Question will allow Investors on your Roadshow to submit written questions directly to the speakers. These questions can be viewed in the Q&A dashboard and in the roadshow’s deal-specific analytics.',
    title: {
      [config.tenant.tenantEvercall.code]: 'If Deal Roadshow is being used, do you want Investors to be able to submit written questions?',
      [config.tenant.dmPortal.code]: 'Enable ‘Ask a Question’ within the Roadshow',
    },
    radioButtonAnswers: getPseudoRadioButtonsAnswers(
      'allow Investors to submit written questions that can only be viewed by Speakers in the Q&A dashboard',
      'not applicable',
    ),
  },
  CALL_RECORDING: {
    type: QUESTION_TYPES.PSEUDO_RADIO,
    name: 'callRecording',
    title: {
      [config.tenant.tenantEvercall.code]: 'Would you like a recording of this call?',
      [config.tenant.dmPortal.code]: 'Record this call (as an mp3)',
    },
    radioButtonAnswers: getPseudoRadioButtonsAnswers(
      'send me an MP3 recording of the call',
      'not applicable',
    ),
  },
  RECORDED_REPLAY: {
    type: QUESTION_TYPES.RADIO_ONLY,
    name: 'recordedReplay',
    title: {
      [config.tenant.tenantEvercall.code]: 'Would you like Evercall to make a replay available to your Participants?',
      [config.tenant.dmPortal.code]: 'Would you like Evercall to make a replay available to your callers?',
    },
    radioButtonAnswers: [
      {
        value: 'Telephonic_Replay',
        label: {
          [config.tenant.tenantEvercall.code]: 'Yes, a telephonic replay accessible via dial-in',
          [config.tenant.dmPortal.code]: 'Telephonic Replay with Dial-In',
        },
        dataTest: 'telephonicReplay',
      },
      {
        value: 'Web_Replay',
        label: {
          [config.tenant.tenantEvercall.code]: 'Yes, a web replay via Deal Roadshow',
          [config.tenant.dmPortal.code]: 'Web Replay via Deal Roadshow',
        },
        dataTest: 'webReplay',
      },
      {
        value: 'No',
        label: 'No, not applicable',
        dataTest: 'no',
      },
    ],
  },
  CALL_TRANSCRIPTION: {
    type: QUESTION_TYPES.PSEUDO_RADIO,
    name: 'transcriptionServices',
    title: {
      [config.tenant.tenantEvercall.code]: 'Would you like Evercall to transcribe this call?',
      [config.tenant.dmPortal.code]: 'Call Transcription',
    },
    radioButtonAnswers: getPseudoRadioButtonsAnswers(
      'send me the call transcription',
      'not applicable',
    ),
  },
};

export const QUESTIONS_MEETING: IMeetingQuestions = {
  REGISTRATION_AHEAD: {
    type: QUESTION_TYPES.PSEUDO_RADIO,
    name: 'registrationAheadMeeting',
    explanation: 'Deal Managers must provide investors with the Event Page link where they can register ahead of the event with their email address to receive conference access details instantly.',
    title: {
      [config.tenant.dmPortal.code]: 'Allow investors to register ahead of the call',
    },
  },
  CUSTOMIZE_REGISTRATION: {
    type: QUESTION_TYPES.PSEUDO_RADIO,
    name: 'registrationCustomization',
    title: {
      [config.tenant.dmPortal.code]: 'Customize registration',
    },
  },
  SLIDES_ONLY_ROADSHOW_REQUIRED: {
    type: QUESTION_TYPES.PSEUDO_RADIO,
    name: 'slidesOnlyRoadshowRequired',
    title: {
      [config.tenant.dmPortal.code]: 'Slides-only presentation to be shared via Deal Roadshow for this call',
    },
  },
  MODERATION: {
    type: QUESTION_TYPES.PSEUDO_RADIO,
    name: 'moderation',
    title: {
      [config.tenant.dmPortal.code]: 'Client to self-host this meeting (If unchecked, Evercall will provide a Moderator)',
    },
  },
  CALL_TRANSCRIPTION: {
    type: QUESTION_TYPES.PSEUDO_RADIO,
    name: 'transcription',
    title: {
      [config.tenant.dmPortal.code]: 'Call Transcription (additional charges may apply)',
    },
  },
  CALL_RECORDING: {
    type: QUESTION_TYPES.PSEUDO_RADIO,
    name: 'recording',
    title: {
      [config.tenant.dmPortal.code]: 'Record this call (as an mp4)',
    },
  },
};
