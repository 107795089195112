import React, { Component } from 'react';
import { Field } from 'redux-form';
import { v4 as uuid } from 'uuid';
import cn from 'classnames';
import { DataTable, dataTableStyles, Input, Icon, IconType, Tooltip } from '@dealroadshow/uikit';
import styles from './externalLinks.scss';
import cardStyles from '@/ui/shared/styles/card.scss';
import linkStyles from '@/ui/shared/styles/links.scss';
import decodeSymbols from '@/Framework/dataHelpers/string/decodeSymbols';

interface IProps {
  fieldName: string,
  collection: any[],
  onHandleAdd: (payload: any) => void,
  onHandleRemove: (index: number) => void,
  onHandleMove: () => void,
}

class ExternalLinks extends Component<IProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      lastAddedFieldUuid: null,
    };
    this.getColumns = this.getColumns.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.getFieldSubNameFromCellProps = this.getFieldSubNameFromCellProps.bind(this);
    this.getFieldSubNameFromString = this.getFieldSubNameFromString.bind(this);
  }

  /**
   * @param {Object} nextProps
   */
  shouldComponentUpdate(nextProps) {
    if (nextProps.collection.length !== this.props.collection.length) {
      return true;
    }

    return nextProps.collection.some(
      (nextCollectionItem, index) => nextCollectionItem.uuid !== this.props.collection[index].uuid,
    );
  }

  /**
   * @param {Object} cellProps
   * @return {string}
   */
  getFieldSubNameFromCellProps(cellProps) {
    return this.getFieldSubNameFromString(cellProps.rowIndex, cellProps.cellName);
  }

  /**
   * @param {Number} rowIndex
   * @param {String} cellName
   * @return {string}
   */
  getFieldSubNameFromString(rowIndex, cellName) {
    return `${ this.props.fieldName }[${ rowIndex }].${ cellName }`;
  }

  getColumns() {
    return [
      {
        name: 'url',
        width: '43%',
        title: this.props.fieldName === 'viewerCustomLinks' ? 'External link (URL)' : 'External links (URL)',
        className: dataTableStyles.inputCol,
        cellCallback: (cellProps) => (
          <div>
            <Icon
              type={ IconType.link }
              className={ styles.linkIcon }
            />
            <Field
              name={ this.getFieldSubNameFromCellProps(cellProps) }
              component={ Input }
              placeholder="e.g. www.dealroadshow.com"
              className={ styles.inputContainer }
              isNarrow
              autoFocus={ cellProps.row.uuid === this.state.lastAddedFieldUuid }
              dataTest={ `${ this.getFieldSubNameFromCellProps(cellProps) }LinkInput` }
            />
          </div>
        ),
      },
      {
        name: 'name',
        width: '42%',
        className: dataTableStyles.inputCol,
        title: (
          <div>
            Display Name
            <span className={ dataTableStyles.dataTableHeaderIcon }>
              <Tooltip content="Link name seen by investors">
                <Icon type={ IconType.question } />
              </Tooltip>
            </span>
          </div>
        ),
        cellCallback: (cellProps) => (
          <Field
            name={ this.getFieldSubNameFromCellProps(cellProps) }
            component={ Input }
            format={ (value) => decodeSymbols(value) }
            dataTest={ `${ this.getFieldSubNameFromCellProps(cellProps) }Input` }
            placeholder="e.g. Deal Roadshow"
            isNarrow
          />
        ),
      },
      {
        name: 'actions',
        width: '10%',
        title: '',
        className: dataTableStyles.actionCol,
        cellCallback: (cellProps) => (
          <div
            className={ dataTableStyles.actionItem }
            onClick={ () => this.handleRemove(cellProps) }
            data-test="removeExternalLinkButton"
          >
            <Tooltip
              content="Delete link"
            >
              <Icon type={ IconType.trash } />
            </Tooltip>
          </div>
        ),
      },
      {
        name: 'dragAndDrop',
        width: '5%',
        isVisible: this.props.fieldName !== 'viewerCustomLinks',
        className: dataTableStyles.draggableCol,
        isDraggable: true,
        title: '',
      },
    ];
  }

  handleRemove(cellProps) {
    this.props.onHandleRemove(cellProps.rowIndex);
    if (this.props.collection.length === 1) {
      this.props.onHandleAdd({ uuid: uuid() });
    }
  }

  handleAdd() {
    const newUuid = uuid();
    this.setState({ lastAddedFieldUuid: newUuid });
    this.props.onHandleAdd({ uuid: newUuid });
  }

  /**
   * @return {ReactElement}
   */
  render() {
    return (
      <>
        <DataTable
          isDraggable
          className={ dataTableStyles.isSecondaryTable }
          dataTest="dealFilesCommercialAddLinkDataTable"
          columns={ this.getColumns() }
          data={ this.props.collection }
          orderCallback={ this.props.onHandleMove }
          isUsePureUpdateComponent
        />
        { this.props.fieldName === 'externalLinks' && (
          <div className={ cardStyles.cardInnerHorizontal }>
            <button
              type="button"
              className={ cn(linkStyles.link, styles.addBtn) }
              onClick={ this.handleAdd }
              data-test="dealFilesCommercialAddLinkButton"
            >
              <Icon type={ IconType.add } />
              Add link
            </button>
          </div>
        ) }
      </>
    );
  }
}

export default ExternalLinks;
