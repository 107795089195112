import React from 'react';
import cn from 'classnames';
import { Icon, IconType, ICellProps as IDataTableCellProps } from '@dealroadshow/uikit';
import { UserPlanRequestStatus } from '@/finsight/application/User/UserPlan/helpers';
import { IManagePlansRequestItem } from '@/condor/domain/vo/FinsightManager/ManagePlans';
import RequestedAtCell from './RequestedAtCell';

import styles from './requests.scss';

interface ICellProps extends IDataTableCellProps<IManagePlansRequestItem> {}

export const cellStatusCallback = (cellProps: ICellProps) => {
  const { requestStatus } = cellProps.row;
  if (!requestStatus) {
    return '-';
  }

  return (
    <span className={ cn(styles.status, {
      [styles.statusPending]: requestStatus === UserPlanRequestStatus.Pending,
      [styles.statusApproved]: requestStatus === UserPlanRequestStatus.Approved,
      [styles.statusDenied]: requestStatus === UserPlanRequestStatus.Denied,
    }) }
    >
      { requestStatus }
    </span>
  );
};

export const cellArrowCallback = () => {
  return <Icon type={ IconType.arrowRight } className={ styles.iconRight } />;
};

export const cellRequestedAtCallback = (cellProps: ICellProps) => (
  <RequestedAtCell { ...cellProps } />
);
