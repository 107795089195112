import { connect } from 'react-redux';
import RegionField from './RegionField';
import selectors from '@/condor/application/actions/finsight/company/deals/collection/selectors';

const mapStateToProps = (state) => (
  {
    options: selectors.getOptions(state).regions,
  }
);

export default connect(
  mapStateToProps,
)(RegionField);
