import { FieldArray } from 'redux-form';
import cn from 'classnames';
import TranchesNavigation
  from '@/condor/ui/components/Finsight/DealProfile/Tranches/TranchesNavigation/TranchesNavigation';

import { useTranchesContext } from './TranchesContext';
import { DEAL_PROFILE_FIELDS } from '../constants';

import TranchesSortableList from './TranchesSortableList';
import TranchesHeader from './TranchesHeader';
import Properties from './Properties';

import styles from './tranches.scss';
import trancheStyles from './Tranche/tranche.scss';
import headerStyles from '@/ui/shared/styles/headers.scss';
import spacesStyles from '@/ui/shared/styles/helpers/spaces.scss';
import alignStyles from '@/ui/shared/styles/helpers/align.scss';

interface IProps {
  moveTranche: () => void,
}

const Tranches = ({ moveTranche }: IProps) => {
  const { isStickyHeaderVisible } = useTranchesContext();

  return (
    <div className={ styles.tranchesWrp }>
      <div className={ alignStyles.flexSpaceBetween }>
        <div className={ cn(headerStyles.isH3, spacesStyles.pbl, spacesStyles.ptxl, spacesStyles.pll) }>
          { DEAL_PROFILE_FIELDS.TRANCHES.label }
        </div>
        <TranchesNavigation />
      </div>
      <TranchesHeader />
      <Properties />
      <FieldArray
        name={ DEAL_PROFILE_FIELDS.TRANCHES.name }
        component={ TranchesSortableList }
        moveTranche={ moveTranche }
        helperClass={ cn(
          trancheStyles.hoveredTranche,
          trancheStyles.isDragging,
          { [trancheStyles.withStickyHeader]: !isStickyHeaderVisible },
        ) }
      />
    </div>
  );
};

export default Tranches;
