import cn from 'classnames';
import {
  cellStatusCallback,
  cellRequestedAtCallback,
  cellArrowCallback,
} from './cellCallbacks';
import {
  contactColumn,
  accountColumn,
  titleColumn,
  phoneColumn,
  planColumn,
} from '../common/columns';

import styles from '../common/common.scss';
import alignStyles from '@/ui/shared/styles/helpers/align.scss';

const columns = [
  {
    ...contactColumn,
  },
  {
    ...accountColumn,
  },
  {
   ...titleColumn,
  },
  {
    ...phoneColumn,
  },
  {
    name: 'status',
    title: 'Status',
    minWidth: 93,
    width: 93,
    cellCallback: cellStatusCallback,
    sortable: true,
  },
  {
    name: 'currentPlan',
    title: 'Curr Plan',
    tooltipContent: 'Current Plan',
    ...planColumn,
  },
  {
    name: 'arrow',
    width: 29,
    minWidth: 29,
    cellCallback: cellArrowCallback,
  },
  {
    name: 'requestedPlan',
    title: 'REQ Plan',
    tooltipContent: 'Requested Plan',
    ...planColumn,
  },
  {
    name: 'requestedAt',
    title: 'Requested At',
    className: cn(styles.dateCol, alignStyles.alignRight),
    cellCallback: cellRequestedAtCallback,
    sortable: true,
  },
];

export default columns;
