import cn from 'classnames';

import { nameCellCallback, statusCellCallback, ActionButtons } from './cellCallbacks';
import { ICellProps, IColumn } from '@dealroadshow/uikit';

import alignStyles from '@/ui/shared/styles/helpers/align.scss';
import styles from './profilesList.scss';

export const columns: IColumn[] = [
  {
    name: 'isPublished',
    title: 'Status',
    className: alignStyles.alignCenter,
    cellCallback: statusCellCallback,
    width: 77,
  },
  {
    name: 'viewId',
    title: 'ID',
    className: alignStyles.alignLeft,
    cellCallback: (cellProps: ICellProps) => cellProps.cellData,
    width: 58,
  },
  {
    name: 'name',
    title: 'Name',
    className: alignStyles.alignLeft,
    cellCallback: nameCellCallback,
    minWidth: 200,
  },
  {
    name: 'displayedEvents',
    title: '# Displayed Events',
    className: cn(alignStyles.alignRight, styles.hideOnHover),
    cellCallback: (cellProps: ICellProps) => cellProps.cellData,
    width: 128,
    isVisible: false, // temporary hidden column, should be removed in FIN-24773
  },
  {
    name: 'totalEvents',
    title: '# Total Events',
    className: cn(alignStyles.alignRight, styles.hideOnHover),
    cellCallback: (cellProps: ICellProps) => cellProps.cellData,
    width: 110,
  },
  {
    name: 'actionButtons',
    className: styles.actionButtonsCell,
    cellCallback: ActionButtons,
    isVisible: true,
  },
];
