import React, { useCallback } from 'react';
import debounce from 'lodash/debounce';
import { Modal } from '@dealroadshow/uikit';
import Button, { variantTypes } from '@/ui/shared/components/Button';

import styles from './deleteBondModal.scss';

interface IProps {
  isVisible: boolean,
  bond: any,
  changeModalVisible: (visible: boolean) => void,
  deleteBond: (id: string) => void,
}

const DeleteBondModal = ({
  bond,
  isVisible,
  changeModalVisible,
  deleteBond,
}: IProps) => {
  const handleDeleteClick = useCallback(debounce(() => deleteBond(bond.id), 500), [bond]);

  const onCloseHandler = () => changeModalVisible(false);

  return (
    <Modal
      title="Delete Confirmation"
      isVisible={ isVisible }
      className={ styles.modalWindow }
      onCloseClicked={ onCloseHandler }
      dataTest="deleteBondConfirmationModal"
      footer={ (
        <>
          <Button
            title="Delete"
            dataTest="deleteBond"
            onClick={ handleDeleteClick }
            variant={ variantTypes.warning }
          />
          <Button
            title="Cancel"
            dataTest="cancelBond"
            onClick={ onCloseHandler }
            variant={ variantTypes.text }
          />
        </>
      ) }
    >
      <p>
        Are you sure you want to delete this bond - { bond.name }?
      </p>
    </Modal>
  );
};

export default DeleteBondModal;
