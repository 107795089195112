/**
 * @param {String} filingType
 * @param {Object} deal
 * @param {String} dealId
 * @param {Boolean} isLinkToExistingDeal
 * @return {String}
 */
export const getFilingPageTitle = ({ filingType, deal, existingDeal, isLinkToExistingDeal }) => {
  const dealData = isLinkToExistingDeal ? existingDeal : deal;
  if (!isLinkToExistingDeal && !dealData.id) {
    return `ABS ${ filingType.toUpperCase() } - Create Deal`;
  }
  if (isLinkToExistingDeal && !dealData.id) {
    return `ABS ${ filingType.toUpperCase() } - Link to Existing Deal`;
  }
  return `ABS ${ filingType.toUpperCase() } - ${ dealData.primaryIssuer?.name || '' } ${ dealData.seriesName || '' } `;
};
