/**
 * @param {Object} location
 * @return {Object}
 */
export function getQueryStringParams(location) {
  const res = {};
  const query = new URLSearchParams(location.search);
  query.forEach((value, key) => {
    res[key] = value;
  });
  return res;
}

/**
 * @param {Object} location
 * @param {Object} queryParams
 * @return {String} ?searchString=friend
 */
export function addQueryStringParam(location, queryParams = {}) {
  let currentQuery = getQueryStringParams(location);
  let params = { ...currentQuery, ...queryParams };

  // Wipe out empty fields from URL
  // eslint-disable-next-line no-restricted-syntax
  for (let key in params) {
    if (!params[key].toString().length) {
      delete params[key];
    }
  }
  const paramsObject = new URLSearchParams(params);
  return paramsObject.toString();
}

/**
 * @param {Object} location
 * @param {Object} queryParams
 * @return {String} ?searchString=friend
 */
export function removeQueryStringParam(location, queryParams = []) {
  let currentQuery = getQueryStringParams(location);
  let params = new URLSearchParams({ ...currentQuery });
  queryParams.forEach((queryKey) => {
    params.delete(queryKey);
  });

  return params.toString();
}
