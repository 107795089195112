import { connect } from 'react-redux';
import { arrayPush, formValueSelector } from 'redux-form';
import AddTrancheButton from './AddTrancheButton';
import { CONDOR_DEAL_PROFILE_FORM_NAME } from '@/condor/ui/common/constants';
import { DEAL_PROFILE_FIELDS } from '../../../constants';
import selectors from '@/condor/application/actions/finsight/deal/selectors';

const selector = formValueSelector(CONDOR_DEAL_PROFILE_FORM_NAME);

const mapStateToProps = (state) => (
  {
    disabled: false,
    ratingAgencies: selectors.getRatingAgencies(state),
    tranchesLength: selector(state, DEAL_PROFILE_FIELDS.TRANCHES.name)?.length || 0,
  }
);

const mapDispatchToProps = {
  addTranche: (emptyTranche) => arrayPush(
    CONDOR_DEAL_PROFILE_FORM_NAME,
    DEAL_PROFILE_FIELDS.TRANCHES.name,
    emptyTranche,
  ),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddTrancheButton);
