import React from 'react';
import { Element as SlateElement } from 'slate';
import { useFocused, useSelected, useSlateStatic, RenderElementProps } from 'slate-react';

import { unwrapLink } from '@/ui/shared/components/Form/RichTextInput/helpers/links';
import { Icon, IconType } from '@dealroadshow/uikit';
import Button, { variantTypes } from '@/ui/shared/components/Button';

import styles from './link.scss';

const Link = (
  {
    attributes,
    element,
    children,
  }: RenderElementProps,
) => {
  const editor = useSlateStatic();
  const selected = useSelected();
  const focused = useFocused();
  const { href } = element as SlateElement & { href: string };

  // Put this at the start and end of an inline component to work around this Chromium bug:
  // https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
  // https://github.com/ianstormtaylor/slate/issues/3421
  // src: https://github.com/ianstormtaylor/slate/blob/2fba2d93a1dabb93cf34b9ddfcc0244804cdeefb/site/examples/inlines.tsx#L233
  const inlineChromiumBugfix = (
    <span
      contentEditable={ false }
      style={ { fontSize: 0, userSelect: 'none' } }
    >
      { String.fromCodePoint(160) /* Non-breaking space */ }
    </span>
  );

  return (
    <span className={ styles.link }>
      <a href={ href } { ...attributes }>
        { inlineChromiumBugfix }
        { children }
        { inlineChromiumBugfix }
      </a>
      { selected && focused && (
        <span className={ styles.linkPopup }>
          <div className={ styles.linkText }>{ href }</div>
          <Button
            dataTest="removeLinkButton"
            className={ styles.removeLinkButton }
            onMouseDown={ () => unwrapLink(editor) }
            variant={ variantTypes.text }
          >
            <Icon type={ IconType.delinkText } />
          </Button>
        </span>
      ) }
    </span>
  );
};

export default Link;
