import { isInvalid, isPristine, isSubmitting, submit } from 'redux-form';
import { connect, batch } from 'react-redux';
import { CONDOR_FINSIGHT_COMPANY_TABLE_INLINE_EDIT_FORM_NAME } from '@/condor/ui/common/constants';
import ActionsCell from './ActionsCell';
import {
  setCondorFinsightCurrentCompany,
  resetCondorFinsightCompanyItem,
  setCondorFinsightCompanyIsInlineEditing,
  getCompanyInformationForMigration,
} from '@/condor/application/actions/finsight/company/item/actions';
import selectors from '@/condor/application/actions/finsight/company/item/selectors';

const mapStateToProps = (state, ownProps) => ({
  isSelectedForEditing: selectors.getItemData(state).id === ownProps.row?.id,
  isInlineEditing: selectors.isInlineEditing(state),
  isUpdating: selectors.isUpdating(state),
  inlineEditFormState: {
    pristine: isPristine(CONDOR_FINSIGHT_COMPANY_TABLE_INLINE_EDIT_FORM_NAME)(state),
    invalid: isInvalid(CONDOR_FINSIGHT_COMPANY_TABLE_INLINE_EDIT_FORM_NAME)(state),
    submitting: isSubmitting(CONDOR_FINSIGHT_COMPANY_TABLE_INLINE_EDIT_FORM_NAME)(state),
  },
});

const mapDispatchToProps = (dispatch, ownProps) => (
  {
    onSubmit: () => dispatch(submit(CONDOR_FINSIGHT_COMPANY_TABLE_INLINE_EDIT_FORM_NAME)),
    onCancel: () => dispatch(resetCondorFinsightCompanyItem()),
    onDeleteClick: (pathname) => batch(() => {
      dispatch(setCondorFinsightCurrentCompany(ownProps.row));
      dispatch(getCompanyInformationForMigration({ id: ownProps.row.id, pathname }));
    }),
    onEditClick: () => batch(() => {
      dispatch(setCondorFinsightCompanyIsInlineEditing(true));
      dispatch(setCondorFinsightCurrentCompany(ownProps.row));
    }),
  }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ActionsCell);
