import { batch } from 'react-redux';
import { matchPath } from 'react-router';
import { History } from '@/Framework/DI/Providers/History';
import {
  fetchBwic,
  paginate as paginateBwic,
  partialStateReset as partialResetBwicState,
  resetState as resetBwic,
} from '@/condor/application/actions/finsight/import/bond/bwic/actions';
import {
  getFiltersPayload,
  getInitialState,
} from '@/finsight/application/actions/bonds/filters/actions';
import {
  fetch as fetchInventory,
  paginate as paginateInventory,
  partialStateReset as partialResetInventoryState,
  resetState as resetInventory,
} from '@/condor/application/actions/finsight/import/bond/inventory/actions';
import {
  getAssetClassList,
  setAssetClass,
  setSearch,
  resetState as resetMetadata,
} from '@/finsight/application/actions/bonds/metadata/actions';
import {
  addQueryStringParam,
  getQueryStringParams,
} from '@/Framework/url/helpers/queryString';
import AssetClass from '@/finsight/domain/AssetClass';
import BondType from '@/finsight/domain/BondType';
import metaTags from '@/finsight/ui/common/metaTagsConstants';
import { setMetaTags } from '@/finsight/application/actions/metaTags/actions';
import URL from '@/finsight/Router/urlConstants';
import { toSlug } from '@/Framework/url/helpers/toSlug';
import { getBondTypeFromLocation } from '@/finsight/application/actions/bonds/helpers';

/**
 * @param {String} search
 * @return {Object}
 */
export const onSearchChange = (search) => (dispatch, getState) => {
  dispatch(setSearch(search));
  const history = getState().container.get(History);
  const locationSearch = addQueryStringParam(history.location, { q: search });
  history.push(`${ history.location.pathname }?${ locationSearch }`);

  batch(() => {
    dispatch(paginateBwic(1));
    dispatch(paginateInventory(1));
    dispatch(fetchBwic());
    dispatch(fetchInventory());
  });
};

/**
 * @param {String} baseUrl
 * Prepare everything for bonds list
 * @return {Function}
 */
export const initBonds = (baseUrl = '') => async (dispatch, getState) => {
  const history = getState().container.get(History);
  const { location: { pathname, search } } = history;
  let bondType = getBondTypeFromLocation(pathname);
  const match = matchPath(pathname, {
    path: [
      `${ baseUrl }${ URL.BONDS_BWICS }-:assetClass?`,
      `${ baseUrl }${ URL.BONDS_INVENTORIES }-:assetClass?`,
    ],
  });
  if (!match) return;

  const queryParams = getQueryStringParams(pathname);
  let { assetClass } = match?.params;

  let redirect = false;
  let redirectParams = { assetClass, bondType };

  let assetClassCollection = await dispatch(getAssetClassList());

  if (!AssetClass.isValid(assetClassCollection, assetClass)) {
    redirect = true;
    redirectParams.assetClass = AssetClass.getDefaultFromCollection(assetClassCollection);
    assetClass = redirectParams.assetClass;
  } else {
    assetClass = AssetClass.getByAbbreviation(assetClassCollection, assetClass);
  }

  if (!BondType.isValid(bondType)) {
    redirect = true;
    bondType = BondType.getDefault();
    redirectParams.bondType = bondType;
    redirectParams.assetClass = assetClass;
  }

  dispatch(setAssetClass(assetClass));
  dispatch(setMetaTags(metaTags.getBwicsInventory(bondType, assetClass.abbreviation)));

  if ('q' in queryParams) {
    dispatch(setSearch(queryParams.q));
  }

  let filtersState = getInitialState();
  let initialFiltersPayload = dispatch(getFiltersPayload(filtersState));

  if (assetClass.id) {
    initialFiltersPayload.includedAssetClassId = assetClass.id;
  }

  dispatch(fetchBwic());
  dispatch(fetchInventory());

  if (redirect) {
    history.replace(`${ baseUrl }/${ redirectParams.bondType }-${ toSlug(redirectParams.assetClass.abbreviation) }${ search }`);
  }
};

/**
 * @param {Object} assetClass
 * @param {Object} match
 * @param {String} baseUrl
 * @return {Function}
 */
export const assetClassChanged = (assetClass, match, baseUrl = '') => async (dispatch, getState) => {
  const history = getState().container.get(History);
  const { location } = history;
  const { bondType } = match.params;

  // if valid AND stored asset class is different
  batch(() => {
    dispatch(setAssetClass(assetClass));
    dispatch(partialResetInventoryState());
    dispatch(partialResetBwicState());
    dispatch(setSearch(''));
    dispatch(setMetaTags(metaTags.getBwicsInventory(bondType, assetClass.abbreviation)));
  });

  dispatch(fetchBwic());
  dispatch(fetchInventory());

  return history.replace(`${ baseUrl }/${ bondType }-${ toSlug(assetClass.abbreviation) }${ location.search }`);
};

export const fullResetBondsPage = () => (dispatch) => batch(() => {
  dispatch(resetMetadata());
  dispatch(resetInventory());
  dispatch(resetBwic());
});
