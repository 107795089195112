import React from 'react';
import { useSelector } from 'react-redux';
import FinalFormSelect from '@/ui/shared/components/Form/Select/legacy/FinalFormSelect';
import { mapOptionsMemoized } from '@/condor/ui/common/Layout/helpers';
import accountTypesSelectors from '@/condor/application/actions/accountTypes/collection/selectors';

interface IAccountType {
  label: string,
  value: string,
}

interface IProps {
  fieldComponent: React.ElementType,
  label: string,
  className: string,
  onInputChange?: (query: string) => void,
}

const TypeField = ({ fieldComponent: Field, ...otherProps }:IProps) => {
  const options:IAccountType[] = useSelector(
    (state) => mapOptionsMemoized(accountTypesSelectors.getCollectionData(state)),
  );
  return (
    <Field
      component={ FinalFormSelect }
      name="accountTypeId"
      placeholder="Select Account Type"
      backspaceRemovesValue={ false }
      options={ options }
      dataTest="accountType"
      { ...otherProps }
    />
  );
};

export default TypeField;
