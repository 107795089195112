import React from 'react';
import * as correctionTypes from '@/condor/ui/components/Finsight/Import/modules/Validation/correctionTypes';
import {
  getCellValue,
} from '@/condor/ui/components/Finsight/Import/modules/Validation/ValidationTable/cellCallbacks';
import SelectCompanyIconContainer from './SelectCompanyIconContainer';
import { Icon, IconType } from '@dealroadshow/uikit';
import styles from '@/condor/ui/components/Finsight/Import/Deal/dealImport.scss';

/**
 * @param {object} cell
 * @returns {string|*}
 */
export const getCellValueByType = (cell) => {
  let type;
  if (!cell.corrected) {
    switch (cell.correctionType) {
      case correctionTypes.companyLead:
        type = (
          <SelectCompanyIconContainer
            activeCell={ cell }
            companiesCollection={ cell.data.collection }
            company={ { abbreviation: cell.value } }
          />
        );
        break;
      case correctionTypes.companyParent:
      case correctionTypes.companySelect:
        type = (
          <SelectCompanyIconContainer
            activeCell={ cell }
            companiesCollection={ cell.data.collection }
            company={ { name: cell.data.name, issuerTicker: cell.data.issuerTicker } }
          />
        );
        break;
      default:
        type = null;
        break;
    }
  } else {
    switch (cell.correctionType) {
      case correctionTypes.companyLead:
      case correctionTypes.companyParent:
      case correctionTypes.companySelect:
        type = (
          <Icon
            type={ IconType.check }
            className={ styles.checkIcon }
          />
        );
        break;
      default:
        type = null;
        break;
    }
  }
  const value = getCellValue(cell);
  return (
    <>
      <span>{ value }</span>
      { type }
    </>
  );
};
