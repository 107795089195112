import createAsyncRequest from '@/Framework/State/createAsyncRequest';
import EvercallAdminRepository from '@/evercall/infrastructure/repository/EvercallAdminRepository';
import { useDIContext } from '@/Framework/DI/DIContext';

interface IProps {
  setIsFetching: (payload: boolean) => void,
  getCollection: () => Promise<void>,
}

export default (
  {
    setIsFetching,
    getCollection,
  }: IProps,
) => {
  const { container } = useDIContext();
  const evercallAdminRepository: EvercallAdminRepository = container.get(EvercallAdminRepository);

  const toggleCallIsHidden = (payload: { id: string }) => {
    const request = createAsyncRequest<typeof evercallAdminRepository.toggleCallIsHidden>(
      {
        method: evercallAdminRepository.toggleCallIsHidden,
        callbacks: {
          onSuccess: getCollection,
        },
      },
    );
    setIsFetching(true);
    return request(payload);
  };

  const toggleCallIsDeleted = (payload: { id: string }) => {
    const request = createAsyncRequest<typeof evercallAdminRepository.toggleCallIsDeleted>(
      {
        method: evercallAdminRepository.toggleCallIsDeleted,
        callbacks: {
          onSuccess: getCollection,
        },
      },
    );
    setIsFetching(true);
    return request(payload);
  };

  const toggleCallIsSupported = (payload: { id: string }) => {
    const request = createAsyncRequest<typeof evercallAdminRepository.toggleCallIsSupported>(
      {
        method: evercallAdminRepository.toggleCallIsSupported,
        callbacks: {
          onSuccess: getCollection,
        },
      },
    );
    setIsFetching(true);
    return request(payload);
  };

  return {
    toggleCallIsHidden,
    toggleCallIsDeleted,
    toggleCallIsSupported,
  };
};
