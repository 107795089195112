import React, { useMemo, useCallback } from 'react';
import { useTranchesContext } from '@/condor/ui/components/Finsight/DealProfile/Tranches/TranchesContext';
import Property from './Property';
import { DEAL_PROFILE_FIELDS } from '../../constants';
import getDealTrancheProperties from '../../helpers/getDealTrancheProperties';

import styles from './properties.scss';

interface IProps {
  ratingAgencies: any[],
  isSectorCmbs: boolean,
  isProductAbs: boolean,
}

const Properties = ({ ratingAgencies, isProductAbs, isSectorCmbs }: IProps) => {
  const { toggleTrancheGroupIsCollapsed } = useTranchesContext();
  const properties = useMemo(
    () => getDealTrancheProperties(ratingAgencies),
    [ratingAgencies.length],
  );

  const checkIsPropertyVisible = useCallback(
    (property) => {
      if (property.isHidden) {
        return false;
      }
      // Interests only is visible when ABS SECTOR / SUBSECTOR field's value's sector is CMBS
      if (property.name === DEAL_PROFILE_FIELDS.TRANCHE.INTEREST_ONLY.name) {
        return isSectorCmbs;
      }
      // For ABS product all fields are visible
      if (isProductAbs) {
        return true;
      }

      return ![
        DEAL_PROFILE_FIELDS.TRANCHE.WAL_YRS.name,
        DEAL_PROFILE_FIELDS.TRANCHE.WAL_EXT.name,
        DEAL_PROFILE_FIELDS.TRANCHE.CREDIT_ENHANCEMENT.name,
        DEAL_PROFILE_FIELDS.TRANCHE.LOAN_TO_VALUE.name,
        DEAL_PROFILE_FIELDS.TRANCHE.ERISA_ELIGABLE.name,
      ].includes(property.name);
    },
    [isSectorCmbs, isProductAbs],
  );

  return (
    <div className={ styles.properties }>
      { Object.entries(properties).map(([groupId, propertiesByGroupId]) => (
        <div
          key={ groupId }
          className={ styles.propertiesGroup }
        >
          { propertiesByGroupId.filter(checkIsPropertyVisible).map(
            (item, i) => (
              <Property
                key={ item.name }
                handleClickCollapseIcon={ () => toggleTrancheGroupIsCollapsed(Number(groupId)) }
                isExpandable={ i === 0 }
                { ...item }
              />
          )) }
        </div>
      )) }

    </div>
  );
};

export default Properties;
