import * as constants from '@/condor/ui/common/constants';

export const TABS_LIST = [
  {
    title: 'Profiles',
    key: 'profiles',
    path: constants.CONDOR_FINSIGHT_OPEN_MARKET_PROFILES,
  },
  {
    title: 'Events',
    key: 'events',
    path: constants.CONDOR_FINSIGHT_OPEN_MARKET_EVENTS,
  },
];
