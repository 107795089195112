import { connect, batch } from 'react-redux';
import { reset } from 'redux-form';
import {
  setCondorFinsightCompanyIsMigrateModalVisible,
  migrateCompanyDeals,
  resetCondorFinsightCompanyItem,
} from '@/condor/application/actions/finsight/company/item/actions';
import selectors from '@/condor/application/actions/finsight/company/item/selectors';
import MigrateModal from './MigrateModal';
import {
  CONDOR_FINSIGHT_MIGRATE_COMPANY_DEALS_FORM,
  CONDOR_FINSIGHT_COMPANIES_LIST_BASE_URL,
} from '@/condor/ui/common/constants';

const mapStateToProps = (state) => ({
  isVisible: selectors.isMigrateModalVisible(state),
  item: selectors.getItemData(state),
  informationForMigration: selectors.getInformationForMigration(state),
  isSpinnerVisible: selectors.isDealsMigrating(state),
});

const mapDispatchToProps = (dispatch) => ({
  onMigrate: (formData) => {
    dispatch(migrateCompanyDeals(formData));
  },
  resetForm: () => dispatch(reset(CONDOR_FINSIGHT_MIGRATE_COMPANY_DEALS_FORM)),
  onCancel: () => {
    batch(() => {
      dispatch(setCondorFinsightCompanyIsMigrateModalVisible(false));
      if (window.location.pathname === CONDOR_FINSIGHT_COMPANIES_LIST_BASE_URL) {
        dispatch(resetCondorFinsightCompanyItem());
      }
    });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MigrateModal);
