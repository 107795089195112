import { format } from 'date-fns';
import { YEAR_MONTH_DAY_DASH_FORMAT } from '@/Framework/DateTime/dateFormats';

import alignStyles from '@/ui/shared/styles/helpers/align.scss';

const columns = [
  {
    name: 'issuerName',
    title: 'issuer description',
    className: alignStyles.alignLeft,
  },
  {
    name: 'seriesName',
    title: 'Series',
    width: 94,
    className: alignStyles.alignRight,
  },
  {
    name: 'trancheName',
    title: 'Tranche',
    width: 94,
    className: alignStyles.alignRight,
  },
  {
    name: 'cusip',
    title: 'CUSIP',
    width: 109,
    className: alignStyles.alignRight,
  },
  {
    name: 'date',
    title: 'settlement date',
    width: 135,
    className: alignStyles.alignRight,
    cellCallback: ({ row: { tradeDate } }) => format(new Date(tradeDate), YEAR_MONTH_DAY_DASH_FORMAT),
  },
];

export default columns;
