import { Class, Transient, Value, Method } from 'constitute';
import { MultipartUploadStrategy, MultipartUploadAdapter, UploadDispatcher } from '@dealroadshow/file-uploader';
import ProcessingAdapter from '@/dmPortal/application/DI/FileUpload/SocketProcessingAdapter';
import condorUrl from '@/condor/infrastructure/condorUrl';

const UploadAdapter = new Class(
  MultipartUploadAdapter,
  Transient.with([new Value({ url: condorUrl.getOpenMarketFilesUrl() })]),
);

export default new Method(
  (processingAdapter, uploadAdapter) => {
    return new UploadDispatcher(new MultipartUploadStrategy(uploadAdapter, processingAdapter));
  },
  [ProcessingAdapter, UploadAdapter],
);
