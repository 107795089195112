import React from 'react';
import CheckboxFiltersBlock from '@/condor/ui/common/Layout/CheckboxFiltersBlock';
import { useTraceContext } from '@/condor/application/Trace/TraceContextProvider';

export const CheckboxHeadFilters = () => {
  const { filters: { includedMapped, includedUnmapped }, onCheckFilters } = useTraceContext();

  const items = [
    {
      label: 'Show Mapped',
      dataTest: 'mappedTrades',
      checked: includedMapped,
      onClick: () => onCheckFilters('includedMapped'),
    },
    {
      label: 'Show Unmapped',
      dataTest: 'unmappedTrades',
      checked: includedUnmapped,
      onClick: () => onCheckFilters('includedUnmapped'),
    },
  ];

  return <CheckboxFiltersBlock items={ items } />;
};
