import { injectable, inject } from 'inversify';
import { withCache } from '@/Framework/withCache';
import Request from '@/Framework/api/Rpc/Request';
import RpcDispatcher from '@/openMarket/application/DI/Rpc/HttpDispatcher';
import { IRegion } from '@/openMarket/domain/vo/IRegion';

@injectable()
class RegionRepository {
  constructor(@inject(RpcDispatcher) private readonly rpc: typeof RpcDispatcher) {}

  @withCache
  async getRegionsList(): Promise<IRegion[]> {
    const request = new Request('open_market.market.regions.list');
    const response = await this.rpc.call(request);

    return response.getResult().payload;
  }
}

export default RegionRepository;
