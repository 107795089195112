import React from 'react';
import { Tooltip, StatusIndicator } from '@dealroadshow/uikit';
import capitalize from '@/Framework/dataHelpers/string/capitalize';

import styles from './styles.scss';

const StatusCell = ({ content = null, cellData }) => (
  <Tooltip
    placement="bottom"
    content={ content || capitalize(cellData) }
  >
    <StatusIndicator
      className={ styles.statusIndicator }
      status={ cellData }
    />
  </Tooltip>
);

export default StatusCell;
