import FinsightCompanyRepository from '@/finsight/infrastructure/repository/CompanyRepository';
import * as actionTypes from './actionTypes';
import selectors from '../item/selectors';

import createAsyncRequest from '@/Framework/State/Redux/createAsyncRequest';
import createAction from '@/Framework/State/Redux/createAction';
import {
  getCompanyFieldOptionMapperByFieldName,
} from '@/condor/application/actions/finsight/company/filteredCompaniesOptionsByFields/helpers';

/**
 * @param {Object} payload
 */
export const fetchCompaniesOptions = (
  {
    query,
    fieldName,
    includedRole,
    withoutExcludedIds,
    callback,
  },
) => (dispatch, getState) => {
  let excludedId = '';
  if (!withoutExcludedIds) {
    excludedId = selectors.getItemData(getState()).id;
  }

  const filter = {
    includedRole,
    ...(!withoutExcludedIds && { excludedIds: [excludedId] }),
  };

  const finsightCompanyRepository = getState().container.get(FinsightCompanyRepository);

  dispatch(createAsyncRequest(
    {
      payload: {
        page: 1,
        perPage: 50,
        query,
        orderBy: 'name',
        sortOrder: 'asc',
        filter,
      },
      method: finsightCompanyRepository.getCompaniesListAggregate,
      types: [
        actionTypes.GET_CONDOR_FILTERED_COMPANIES_OPTIONS,
        actionTypes.GET_CONDOR_FILTERED_COMPANIES_OPTIONS_SUCCESS,
        actionTypes.GET_CONDOR_FILTERED_COMPANIES_OPTIONS_ERROR,
      ],
      callbacks: {
        responseHandler: (response) => ({ fieldName, ...response }),
        onSuccess: ({ response }) => {
          if (callback) {
            callback(response.collection.map(getCompanyFieldOptionMapperByFieldName(fieldName)));
          }
        },
      },
    },
  ));
};

export const resetFilteredCompaniesOptions = () => createAction(actionTypes.RESET_CONDOR_FILTERED_COMPANIES_OPTIONS);
