import React, { memo } from 'react';
import PageWrapper from '@/condor/ui/common/Layout/PageWrapper';
import TableWrapper from '@/ui/shared/components/Tables/TableWrapper';
import CheckboxFiltersContainer from './CheckboxFilters';
import SearchInputContainer from './SearchInputContainer';

import ContactsTable from './ContactsTable';
import ContactsTablePaginator from './ContactsTable/ContactsTablePaginatorContainer';

import styles from './contactsList.scss';
import cardStyles from '@/ui/shared/styles/card.scss';

interface IProps {
  init: () => void,
  reset: () => void,
}

const ContactsList = (props: IProps) => (
  <PageWrapper
    label="Contacts"
    headerComponent={ (
      <div className={ styles.checkboxFiltersContainer }>
        <CheckboxFiltersContainer />
      </div>
      ) }
    onMount={ props.init }
    onUnmount={ props.reset }
  >
    <TableWrapper
      label="Contacts"
      filterComponent={ <SearchInputContainer /> }
    >
      { /* @ts-ignore */ }
      <ContactsTable />
      { /* @ts-ignore */ }
      <ContactsTablePaginator className={ cardStyles.cardInner } />
    </TableWrapper>
  </PageWrapper>
);

export default memo(ContactsList);
