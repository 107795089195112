import {
  format,
  formatDuration,
  differenceInSeconds,
} from 'date-fns';
import { IDatetime } from '@/Framework/DateTime/Datetime';
import getZonedUTCDate from '@/Framework/DateTime/getZonedUTCDate';
import { BONDS_DATE_FORMAT, MONTH_DAY_YEAR_DATETIME_FORMAT } from '@/Framework/DateTime/dateFormats';
import getUTCDate from '@/Framework/DateTime/getUTCDate';
import { getFormatInTimeZoneDate } from '@/Framework/DateTime/getFormatInTimeZoneDate';

export const RATING_AGENCIES_MAP = {
  ratingMoodys: '319286e9-b15c-476b-b265-bf3c33592cc4',
  ratingSAndP: 'c30be82f-1530-423d-a1c6-b1dc11214f94',
  ratingFitch: '6659ca2f-d217-42b6-ae16-e047ba430439',
  ratingDBRS: '1f435932-7d91-4c8d-9369-c040327aaf9b',
  ratingKroll: '2417fb0f-db90-48a2-8b2e-2ea980a3e215',
  ratingOtherOrUnspecified: '7f55440a-bf87-4905-a785-e8051fa7c2f2',
};

/**
 * @param {String} cellName
 * @param {Object} ratings
 * @returns {String}
 */
export const getRatingValue = ({ cellName, row: { ratings } }) => {
  /**
   * @param {String} ratingAgencyId
   */
  const getRating = (ratingAgencyId) => (
    ratings[ratingAgencyId] !== undefined
      ? ratings[ratingAgencyId].map((rating) => (rating.abbreviation)).join('/')
      : '-'
  );

  return getRating(RATING_AGENCIES_MAP[cellName]) || '-';
};

export const getBondsFullDate = ({ dateTime, timezone }: IDatetime): string => {
  const date = getFormatInTimeZoneDate(
      getUTCDate(new Date(dateTime)),
      timezone.timeZone,
      MONTH_DAY_YEAR_DATETIME_FORMAT,
  );

  return `${ date } ${ timezone?.abbr || ' ET' }`;
};

export const getBondsDate = (dateObject: IDatetime, isShortDate = false) => {
  const zonedDate = getZonedUTCDate(dateObject.timezone.timeZone, new Date(dateObject.dateTime));
  const timeNow = getZonedUTCDate(dateObject.timezone.timeZone, new Date());

  const differenceSeconds = differenceInSeconds(timeNow, zonedDate);
  const differenceMinutes = differenceSeconds / 60;
  const differenceHours = differenceMinutes / 60;
  const differenceDays = differenceHours / 24;

  if (differenceDays >= 6) {
    return isShortDate ? format(new Date(dateObject.dateTime), BONDS_DATE_FORMAT) : getBondsFullDate(dateObject);
  }

  if (differenceDays >= 1) {
    const days = Math.round(differenceDays);
    return `${ days } ${ days > 1 ? 'days' : 'day' } ago`;
  }

  if (differenceHours >= 1) {
    const hours = Math.round(differenceHours);
    return `${ hours } ${ hours > 1 ? 'hours' : 'hour' } ago`;
  }

  return formatDuration({ minutes: Math.round(differenceMinutes) }, { format: ['minutes'], zero: false }).replace('minutes', 'min ago');
};

/**
 * @param {Boolean} isSubscribed
 * @param {String} abbreviation
 * @returns {String}
 */
export const getLabelContent = (isSubscribed, abbreviation) => {
  if (!abbreviation) {
    return null;
  }
  return isSubscribed
    ? `Subscribed to ${ abbreviation } Daily Digest`
    : `Subscribe to ${ abbreviation } Daily Digest`;
};

/**
 * @param {String} url
 * @returns {String}
 */
export const getFormatUrl = (url) => {
  const nextPath = url.split('?')[0];
  const query = url.split('?')[1];
  return query ? url : nextPath;
};
