import stripTags from '@/Framework/browser/stripTags';
import { validateMaxLength } from '@/ui/shared/validators/validateMaxLength';
import { validateMinLength } from '@/ui/shared/validators/validateMinLength';
import { validateRequiredFields } from '@/ui/shared/validators/validateRequiredFields';
import { groupValidators } from '@/ui/shared/validators/helpers';
import { validateColors } from '@/ui/shared/validators/validateColors';
import { validateRequiredFieldWithArrayValue } from '@/ui/shared/validators/validateRequiredFieldWithArrayValue';

const paramsRequiredFields = [
  { fieldName: 'name', fieldCaption: 'Name' },
  { fieldName: 'color', fieldCaption: 'Color' },
  { fieldName: 'content', fieldCaption: 'Content' },
  { fieldName: 'status' },
];

const conditionalRequiredFields = [
  { fieldName: 'positionIds', fieldCaption: 'Positions' },
];

/**
 * Draft validation
 * @param {Object} values
 */
export const conditionalValidators = (values) => groupValidators(
  validateRequiredFields(paramsRequiredFields, { ...values, content: stripTags(values.content) }),
  validateMinLength({ fieldName: 'name', fieldCaption: 'Name:', value: values.name, length: 3 }),
  validateMaxLength({ fieldName: 'name', fieldCaption: 'Name:', value: values.name, length: 255 }),
  validateColors([{ fieldName: 'color' }], values),
  validateMinLength({ fieldName: 'content', fieldCaption: 'Content:', value: stripTags(values.content), length: 5 }),
  validateMaxLength({ fieldName: 'content', fieldCaption: 'Content:', value: stripTags(values.content), length: 500 }),
);

export default (values) => {
  const fields = [...paramsRequiredFields, ...conditionalRequiredFields];

  return (
    groupValidators(
      validateRequiredFields(fields, { ...values, content: stripTags(values.content) }),
      validateMinLength({ fieldName: 'name', fieldCaption: 'Name:', value: values.name, length: 3 }),
      validateMaxLength({ fieldName: 'name', fieldCaption: 'Name:', value: values.name, length: 255 }),
      validateColors([{ fieldName: 'color' }], values),
      validateMinLength({ fieldName: 'content', fieldCaption: 'Content:', value: stripTags(values.content), length: 5 }),
      validateMaxLength({ fieldName: 'content', fieldCaption: 'Content:', value: stripTags(values.content), length: 500 }),
      validateRequiredFieldWithArrayValue({ fieldName: 'positionIds', fieldCaption: 'positionIds:', value: values.positionIds }),
    )
  );
};
