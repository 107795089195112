import { NAME_MIN_LENGTH } from '@/users/domain/User';
import { groupValidators } from '@/ui/shared/validators/helpers';
import { validateRequiredFields } from '@/ui/shared/validators/validateRequiredFields';
import { validateMinLength } from '@/ui/shared/validators/validateMinLength';
import { validateMaxLength } from '@/ui/shared/validators/validateMaxLength';
import { validateColors } from '@/ui/shared/validators/validateColors';

export default (values) => groupValidators(
  validateMinLength({
    fieldName: 'name',
    fieldCaption: 'Account Type Name',
    value: values.name,
    length: NAME_MIN_LENGTH,
  }),
  validateMaxLength({
    fieldName: 'name',
    fieldCaption: 'Account Type Name',
    value: values.name,
    length: 128,
  }),
  validateRequiredFields([
    { fieldName: 'name', fieldCaption: 'Account Type Name' },
    { fieldName: 'color', fieldCaption: 'Account Type Color' },
  ], values),
  validateColors([{ fieldName: 'color' }], values),
);
