import Head from 'next/head';
import cn from 'classnames';
import noop from 'lodash/noop';

import { Spinner, Paginator, DataTable, dataTableStyles, IColumn } from '@dealroadshow/uikit';
import EventsListContextProvider, { useEventsListContext } from './EventsListContext';
import DeleteModal from '@/openMarket/ui/common/Condor/DeleteModal';
import { columns as columnsArray } from './columns';
import decodeSymbols from '@/Framework/dataHelpers/string/decodeSymbols';

import cardStyles from '@/ui/shared/styles/card.scss';
import styles from './eventsList.scss';

interface IProps {
  columns?: IColumn[],
  // eslint-disable-next-line react/no-unused-prop-types
  onDeleteSuccess?: () => void,
}

const EventsList = ({
  columns = columnsArray,
}: IProps) => {
  const { activity, isDeleting, deletingEvent, deleteEvent, closeDeleteModal } = useEventsListContext();

  const handlePageChange = (page: { selected: number }) => {
    activity.paginate(page.selected);
  };

  const handleItemsPerPageChange = (perPage: { value: number }) => {
    activity.setItemsPerPage(perPage.value);
  };

  return (
    <>
      <Head>
        <title>Events | OpenMarket</title>
      </Head>
      <DataTable
        containerClassName={ cn(styles.dataTableContainer, dataTableStyles.dataTableContainer) }
        className={ dataTableStyles.isHoverable }
        rowClassNameCallback={ () => styles.tableRow }
        columns={ columns }
        data={ activity.collection }
        isFetching={ activity.isFetching }
        loadingComponent={ Spinner }
        onSortChange={ activity.sort }
        sortBy={ activity.sortBy }
        sortOrder={ activity.sortOrder }
        dataTest="openMarketTable"
        emptyMessage="Events not found"
      />
      <Paginator
        dataTest="openMarketPaginator"
        className={ cardStyles.cardInner }
        totalCount={ activity.totalCount }
        page={ activity.page }
        perPage={ activity.perPage }
        onItemsPerPageChange={ handleItemsPerPageChange }
        onPageChange={ handlePageChange }
      />
      { deletingEvent && (
        <DeleteModal
          title="Delete Event"
          disclaimer={ (
            <>
              Please confirm you would like to delete
              the <b>{ decodeSymbols(deletingEvent.name) }</b> Event
              from OpenMarket and Finsight.com.
            </>
          ) }
          isLoading={ isDeleting }
          confirm={ () => deleteEvent(deletingEvent.eventId) }
          close={ closeDeleteModal }
        />
      ) }
    </>
  );
};

export default ({ columns, onDeleteSuccess = noop }: IProps) => (
  <EventsListContextProvider onDeleteSuccess={ onDeleteSuccess }>
    <EventsList columns={ columns } />
  </EventsListContextProvider>
);
