import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  search,
  paginate,
  itemsPerPage,
  sort,
  getLoginLimits,
  resetAdminLoginLimits,
  resetAdminInvestorLoginsCountByEntryCode,
  resetLoginLimits,
} from './actions';
import LoginLimits from './LoginLimits';

function mapStateToProps(state) {
  return {
    loginLimits: state.dealRoadshows.roadshow.loginLimits,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    search,
    paginate,
    itemsPerPage,
    sort,
    getLoginLimits,
    resetAdminLoginLimits,
    resetAdminInvestorLoginsCountByEntryCode,
    resetLoginLimits,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginLimits);
