import { IDatetime } from '@/Framework/DateTime/Datetime';
import ISector from '@/finsight/domain/vo/ISector';

export interface TableBodyInterface {
  corrected: boolean,
  numberColumn: boolean,
  value: string,
  correctionType: string,
  data: Record<string, any>,
  position: {
    rowIndex: string,
    columnIndex: string,
  },
}

export interface ITraceResponse {
  lastImportAt: IDatetime | null,
  trades: ITraceCollection,
}

interface ITraceCollection {
  collection: ITradeItem[],
  totalCount: number,
}

export interface ITradeItem {
  agency: string | null,
  asOfIndicator: string | null,
  atsIndicator: string | null,
  commission: string | null,
  contraPartyType: string | null,
  coupon: number,
  couponType: string,
  cusip: string,
  factor: number | null,
  fitchRating: Record<string, any> | null,
  id: string,
  issuerName: string,
  issuerTicker: string | null,
  lastTradeDate: null,
  lastTradePrice: number | null,
  mappedData: {
    dealId: string,
    issuerId: string,
    trancheI: string,
  },
  maturityDate: string,
  moodyRating: Record<string, any> | null,
  poolNumber: number | string,
  price: number,
  quantityIndicator: string,
  rdid: string | null,
  remuneration: Record<string, any> | null,
  reportingPartySide: number | null,
  reportingPartyType: string | null,
  saleCondition3: string | null,
  saleCondition4: string | null,
  securityId: string,
  seriesName: string,
  settlementDate: string,
  specialPrice: string | null,
  standardAndPoorRating: Record<string, any> | null,
  stringTradeSize: string,
  subProductType: string | null,
  symbol: string | null,
  tradeDate: string,
  tradeSize: number,
  tradeStatus: string,
  trancheName: string,
}

export interface IMapTradePayload {
  tradeId: string,
  dealId: string,
  trancheId: string,
}

export enum SubProductType {
  ABS = 'ABS',
  CMO = 'CMO',
}

export interface ITracePayload {
  filters: {
    includedMapped: boolean,
    includedUnmapped: boolean,
    includedArchived: boolean,
    includedNotArchived: boolean,
    spdType: SubProductType,
  },
  page: number,
  perPage: number,
  query: string,
  sortBy: string,
  sortOrder: string,
}

export interface IMapTradedParams {
  page: number,
  perPage: number,
  sortBy: string,
  sortOrder: string,
  filterType: string,
}

export interface ITraceFilterByHashResponse {
  includedAssetClassId: string | null,
  dateFrom: string | null,
  dateTo: string | null,
  includedTimeFrom: number | null,
  includedTimeTo: number | null,
  maturityDateFrom: string | null,
  maturityDateTo: string | null,
  includedSizeFrom: number | null,
  includedSizeTo: number | null,
  includedPriceFrom: number | null,
  includedPriceTo: number | null,
  includedCouponFrom: number | null,
  includedCouponTo: number | null,
  includedSectorIds: string[],
  includedSubsectorIds: string[],
  includedIssuerIds: string[],
  includedContraPartyTypes: [],
  includedRemunerations: string[],
  includedReportingPartySides: string[],
  includedReportingPartyTypes: string[],
  includedTradeStatuses: string[],
}

interface ITraceFilterSectionListItem {
  name: string,
  value: string,
  canChangeResponse: boolean,
}

interface ITraceFilterRangeItem {
  range: {
    min: number,
    max: number,
  },
  canChangeResponse: boolean,
}

interface IIssuerItem {
  canChangeResponse: boolean,
  id: string,
  issuerTicker: string,
  name: string,
}

interface IIssuerList {
  allowed: IIssuerItem[],
  selected: IIssuerItem[],
}

export interface ITraceFilterResponse {
  filter: {
    tradeDate: ITraceFilterRangeItem,
    tradeTime: ITraceFilterRangeItem,
    maturityDate: ITraceFilterRangeItem,
    size: ITraceFilterRangeItem,
    price: ITraceFilterRangeItem,
    coupon: ITraceFilterRangeItem,
    issuerList: IIssuerList,
    sectorList: ISector[],
    contraPartyTypeList: ITraceFilterSectionListItem[],
    remunerationList: ITraceFilterSectionListItem[],
    reportingPartySideList: ITraceFilterSectionListItem[],
    reportingPartyTypeList: ITraceFilterSectionListItem[],
    tradeStatusList: ITraceFilterSectionListItem[],
  },
  hash: string,
}

interface ITraceFilterIssuer {
  id: string,
  name: string,
  issuerTicker: string | null,
  canChangeResponse: boolean,
}

export interface ITraceIssuersResponse {
  allowed: ITraceFilterIssuer[],
  selected: ITraceFilterIssuer[],
}

export enum ArchiveByOptions {
  Cusip = 'cusip',
  Issuer = 'issuer',
}
