/* eslint-disable react/no-unused-prop-types */
import React, { useMemo } from 'react';
import cn from 'classnames';
import { Modal, ProgressBar } from '@dealroadshow/uikit';
import Button, { variantTypes } from '@/ui/shared/components/Button';

import styles from './mappingProgressModal.scss';

interface IProps {
  onClose: () => void,
  contacts: number,
  deals: number,
  allocations: number,
  evercalls: number,
  notes: number,
  leis: number,
  emailDomains: number,
  webDomains: number,
  mappingsTypes: any[],
  remappedItemCounts: any,
  isFinished: boolean,
  isError: boolean,
}

const MappingsProgressModal = (props: IProps) => {
  const getItemsTotalCount = () => {
    let result = 0;
    props.mappingsTypes.forEach((type) => {
      result += props[type];
    });
    return result;
  };

  const remappedItemsSummary = useMemo(() => Object.values(props.remappedItemCounts).reduce(
    // @ts-ignore
    (acc, item) => acc + item, 0,
  ), [props.remappedItemCounts]);
  const totalCount = getItemsTotalCount();

  const getModalText = () => {
    if (props.isError) {
      return (
        <span>An error has occurred and has been reported to our engineering team.<br />
          Please refresh the page and try again.
        </span>
      );
    }
    if (props.isFinished) {
      return `You successfully remapped ${ remappedItemsSummary } ${ remappedItemsSummary === 1 ? 'item' : 'items' }`;
    }
    return `Remapped ${ remappedItemsSummary } out of ${ totalCount } ${ totalCount === 1 ? 'item' : 'items' }`;
  };

  const getOnClickHandler = () => {
    if (props.isFinished && !props.isError) {
      return props.onClose;
    }
    return () => {
      window.location.reload();
    };
  };

  const getModalFooter = () => (
    <Button
      disabled={ !props.isFinished }
      variant={ variantTypes.action }
      onClick={ getOnClickHandler() }
      dataTest="mappingProgressModalButton"
    >
      { !props.isError ? 'Ok' : 'Reload page' }
    </Button>
  );

  return (
    <Modal
      title="Re-Mapping Account's Related Data to Other Account"
      onCloseClicked={ props.onClose }
      closeOnEsc={ false }
      hideOnOverlayClicked={ false }
      showCloseButton={ false }
      className={ styles.progressModal }
      modalBodyClassName={ styles.modalBody }
      dataTest="mappingProgressModal"
      footer={ getModalFooter() }
      isVisible
    >
      <p>{ getModalText() }</p>
      <ProgressBar
        // @ts-ignore
        value={ Math.ceil((remappedItemsSummary / totalCount) * 100) }
        filledClassName={ cn({
          [styles.mappingsProgressBar]: !props.isFinished,
          [styles.mappingsFinishSuccess]: props.isFinished && !props.isError,
          [styles.mappingsFinishError]: props.isError,
        }) }
      />
    </Modal>
  );
};

export default MappingsProgressModal;
