import React from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { Spinner, IconType, ICellProps as IDataTableCellProps } from '@dealroadshow/uikit';

import {
  CONDOR_ACCOUNT_PROFILE_BASE_URL,
  CONDOR_ACCOUNT_PROFILE_MAPPINGS_BASE_URL,
} from '@/condor/ui/common/constants';
import { getCellDisplayDateFromString } from '@/condor/ui/common/Layout/helpers';
import TableRowActions from '@/condor/ui/common/Layout/TableRowActions';
import { LastTableCell } from '@/ui/shared/components/Tables/TableWrapper';
import * as userPermissionsSelectors from '@/users/application/actions/permissions/selectors';
import { IAccountProfileItem } from '@/condor/domain/vo/Accounts/AccountProfileItem';
import { getIsAccountRestrictedToEdit } from '@/condor/ui/components/AccountsAndContacts/Accounts/helpers';
import { RestrictedEditAccountTooltip } from '@/condor/ui/components/AccountsAndContacts/Accounts/constants';

import styles from './updatedAtCell.scss';
import accountTableStyles from '../accountsTable.scss';

interface IProps {
  cellProps: IDataTableCellProps<IAccountProfileItem>,
  isSelectedForEditing: boolean,
  isUpdating: boolean,
  inlineEditFormState: {
    pristine: boolean,
    invalid: boolean,
    submitting: boolean,
  },
  onSubmit: () => void,
  onReset: () => void,
  onRestore: () => void,
  onEditClick: () => void,
  onDeleteClick: () => void,
}

const getDeleteTooltipContent = (
    canBeDeleted: boolean,
    hasSsoIntegration: boolean,
    isAccountRestrictedToEdit: boolean,
): string => {
  if (hasSsoIntegration) {
    return 'This Account must be removed from SSO integration before deleting';
  }
  if (isAccountRestrictedToEdit) {
    return RestrictedEditAccountTooltip;
  }
  return canBeDeleted ? 'Delete' : 'This Account must be re-mapped before Deleting';
};

const getMappingTooltipContent = (
    isContactPermission: boolean,
    isAccountRestrictedToEdit: boolean,
): string => {
  if (isAccountRestrictedToEdit) {
    return RestrictedEditAccountTooltip;
  }
  return isContactPermission ? 'Account Mappings' : 'No Access';
};

const UpdatedAtCell = (props: IProps) => {
  const { canBeDeleted, hasSsoIntegration } = props.cellProps.row;
  const { currentUserPermissions } = useSelector(userPermissionsSelectors.getUserPermissionsState);
  const isContactPermission = currentUserPermissions.find((permission) => permission.moduleKey === 'contacts');

  const isAccountRestrictedToEdit = getIsAccountRestrictedToEdit(props.cellProps.row, currentUserPermissions);

  let actions;
  if (props.cellProps.row.deletedAt) {
    actions = [
      {
        tooltipContent: 'Restore Account',
        iconType: IconType.rotate,
        onClick: props.onRestore,
        dataTest: 'restoreAccountAction',
      },
    ];
  } else {
    actions = props.isSelectedForEditing ?
      [
        {
          tooltipContent: 'Save',
          iconType: IconType.check,
          onClick: props.onSubmit,
          dataTest: 'saveAccountAction',
          disabled: props.inlineEditFormState.submitting ||
                    props.inlineEditFormState.pristine ||
                    props.inlineEditFormState.invalid,
        },
        {
          tooltipContent: 'Cancel',
          iconType: IconType.cancel,
          onClick: props.onReset,
          dataTest: 'cancelEditAction',
        },
      ] : [
        {
          tooltipContent: isAccountRestrictedToEdit ? RestrictedEditAccountTooltip : 'Edit',
          iconType: IconType.pencil,
          onClick: props.onEditClick,
          disabled: isAccountRestrictedToEdit,
          dataTest: 'editAccountAction',
        },
        {
          url: `${ CONDOR_ACCOUNT_PROFILE_BASE_URL }/${ props.cellProps.row.id }`,
          tooltipContent: isContactPermission ? 'Profile' : 'No Access',
          iconType: IconType.settings,
          disabled: !isContactPermission,
          dataTest: 'profileAccountAction',
        },
        {
          url: `${ CONDOR_ACCOUNT_PROFILE_MAPPINGS_BASE_URL }/${ props.cellProps.row.id }`,
          tooltipContent: getMappingTooltipContent(isContactPermission, isAccountRestrictedToEdit),
          iconType: IconType.mappings,
          disabled: !isContactPermission || isAccountRestrictedToEdit,
          dataTest: 'accountMappingsAction',
        },
        {
          onClick: props.onDeleteClick,
          tooltipContent: getDeleteTooltipContent(canBeDeleted, hasSsoIntegration, isAccountRestrictedToEdit),
          disabled: !canBeDeleted || hasSsoIntegration || isAccountRestrictedToEdit,
          iconType: IconType.trash,
          dataTest: 'deleteAccountAction',
        },
      ];
  }

  return (
    <LastTableCell
      showHoverContent={ props.isSelectedForEditing }
      childrenToShowOnHover={
        !props.isUpdating
          ? (
            <TableRowActions
              actions={ actions }
              tableRowActionsClassName={
                props.isSelectedForEditing || props.cellProps.row.deletedAt
                  ? ''
                  : styles.updatedAtWrapper
              }
            />
          )
          : (
            <Spinner
              size="small"
              isVisible={ props.isSelectedForEditing }
            />
          )
      }
    >
      <span className={ cn({
        [accountTableStyles.deletedAccountField]: props.cellProps.row.deletedAt,
      }) }
      >
        { getCellDisplayDateFromString(props.cellProps.row.updatedAt) }
      </span>
    </LastTableCell>
  );
};

export default UpdatedAtCell;
