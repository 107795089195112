import React from 'react';
import { Redirect, Switch } from 'react-router';
import useRouter from '@/ui/shared/hooks/useNextRouter';

import PageWrapper from '@/condor/ui/common/Layout/PageWrapper';
import TableWrapper from '@/ui/shared/components/Tables/TableWrapper';

import TraceContextProvider, { useTraceContext } from '@/condor/application/Trace/TraceContextProvider';
import { getBondsDate } from '@/finsight/ui/components/bonds/helpers';
import { renderRoute } from '@/condor/ui/components/FinsightManager/helpers';
import { Tabs, Tab } from '@dealroadshow/uikit';
import * as constants from '@/condor/ui/common/constants';
import { SubProductType } from '@/condor/domain/vo/types';

import { TableSearch } from './TableSearch';
import { CheckboxHeadFilters } from './CheckboxHeadFilters';
import MapTradeModal from '@/condor/ui/components/Finsight/TraceManager/common/TraceTable/MapTrade/MapTradeModal';
import { TABS } from './constants';

import styles from './traceManager.scss';
import tabsStyles from '@/ui/shared/styles/tabs.scss';

const TraceManager = () => {
  const { traceData: { lastImportAt } } = useTraceContext();
  const { push, asPath } = useRouter();

  const onClickTab = (path) => {
    if (!asPath.includes(path)) {
      push(path);
    }
  };

  const defaultTab = TABS.find(({ path }) => asPath.includes(path))?.title || TABS[0].title;

  return (
    <PageWrapper
      label="TRACE Manager"
      headerCls={ styles.traceHeader }
      headerComponent={ (
        <div className={ styles.checkboxFiltersContainer }>
          <CheckboxHeadFilters />
        </div>
      ) }
    >
      <TableWrapper
        labelClassName={ styles.label }
        className={ styles.tableHeaderWrapper }
        filterComponent={ (
          <div className={ styles.toolbar }>
            { lastImportAt?.timezone && (
              <div className={ styles.updated }>
                { `Last Updated: ${ getBondsDate(lastImportAt, true) }` }
              </div>
            ) }
            <div className={ styles.filterWrapper }>
              <TableSearch />
            </div>
          </div>
        ) }
      >
        <Tabs
          dataTest="condorTraceTablesTabs"
          className={ styles.tabs }
          defaultTab={ defaultTab }
        >
          { TABS.map(({ title, path }) => (
            <Tab
              key={ title }
              onClick={ () => onClickTab(path) }
              tabFor={ title }
              className={ tabsStyles.tab }
              activeClassName={ tabsStyles.isTabActive }
            >
              { title }
            </Tab>
          )) }
        </Tabs>
        <div className={ tabsStyles.tabsFooter } />
        <Switch>
          { TABS.map(renderRoute) }
          <Redirect
            from={ constants.CONDOR_FINSIGHT_TRACE_BASE_URL }
            to={ `${ constants.CONDOR_FINSIGHT_TRADES_BASE_URL }/${ SubProductType.ABS }` }
          />
        </Switch>
      </TableWrapper>
      <MapTradeModal />
    </PageWrapper>
  );
};

export default () => (
  <TraceContextProvider>
    <TraceManager />
  </TraceContextProvider>
);
