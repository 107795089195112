import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Spinner } from '@dealroadshow/uikit';
import useReduxSelector from '@/ui/shared/hooks/useReduxSelector';
import metadataSelectors from '@/condor/application/actions/finsight/subscriptions/metadata/selectors';
import {
  initSubscriptionsModal,
  setIsSubscribeModalVisible,
  resetMetadata,
} from '@/condor/application/actions/finsight/subscriptions/metadata/actions';

import SubscribeForm from './AddSubscribersForm';

import styles from './AddSubscribersModal.scss';

interface IProps {
  isVisible: boolean,
}

const AddSubscribersModal = ({
  isVisible,
}: IProps) => {
  const dispatch = useDispatch();
  const isSpinnerVisible = useReduxSelector(metadataSelectors.isFetching);

  const onClose = () => dispatch(setIsSubscribeModalVisible(false));

  useEffect(() => {
    dispatch(initSubscriptionsModal());
    return () => {
      dispatch(resetMetadata());
    };
  }, []);

  return (
    <Modal
      title="Add Subscriber(s) to Finsight Email Alerts"
      isVisible={ isVisible }
      onCloseClicked={ onClose }
      className={ styles.modal }
      dataTest="addSubscriberModal"
    >
      <p className={ styles.description }>Add Subscriber(s) by their corporate email</p>
      { !isSpinnerVisible && (
        <SubscribeForm
          onCancel={ onClose }
          withUnsubscribe={ false }
          submitButtonTitle="Add Subscriber(s)"
          withEmailSelect
        />
      ) }
      { isSpinnerVisible && (<Spinner overlay />) }
    </Modal>
  );
};

export default AddSubscribersModal;
