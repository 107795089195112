import { isInvalid, isPristine, isSubmitting, submit } from 'redux-form';
import { connect, batch } from 'react-redux';
import { CONDOR_FINSIGHT_COMPANY_DEAL_TABLE_INLINE_EDIT_FORM_NAME } from '@/condor/ui/common/constants';
import ActionsCell from './ActionsCell';
import {
  setCondorFinsightCompanyCurrentDeal,
  resetCondorFinsightCompanyDealItem,
  setCondorFinsightCompanyDealIsInlineEditing,
  setCondorFinsightCompanyDealIsDeleteModalVisible,
} from '@/condor/application/actions/finsight/company/deals/item/actions';
import selectors from '@/condor/application/actions/finsight/company/deals/item/selectors';

const mapStateToProps = (state, ownProps) => ({
  isSelectedForEditing: selectors.getItemData(state).id === ownProps.row?.id,
  isUpdating: selectors.isUpdating(state),
  inlineEditFormState: {
    pristine: isPristine(CONDOR_FINSIGHT_COMPANY_DEAL_TABLE_INLINE_EDIT_FORM_NAME)(state),
    invalid: isInvalid(CONDOR_FINSIGHT_COMPANY_DEAL_TABLE_INLINE_EDIT_FORM_NAME)(state),
    submitting: isSubmitting(CONDOR_FINSIGHT_COMPANY_DEAL_TABLE_INLINE_EDIT_FORM_NAME)(state),
  },
});

const mapDispatchToProps = (dispatch, ownProps) => (
  {
    onSubmit: () => dispatch(submit(CONDOR_FINSIGHT_COMPANY_DEAL_TABLE_INLINE_EDIT_FORM_NAME)),
    onCancel: () => dispatch(resetCondorFinsightCompanyDealItem()),
    onDeleteClick: () => batch(() => {
      dispatch(setCondorFinsightCompanyDealIsDeleteModalVisible(true));
      dispatch(setCondorFinsightCompanyCurrentDeal(ownProps.row));
    }),
    onEditClick: () => batch(() => {
      dispatch(setCondorFinsightCompanyDealIsInlineEditing(true));
      dispatch(setCondorFinsightCompanyCurrentDeal(ownProps.row));
    }),
  }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ActionsCell);
