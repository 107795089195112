import React from 'react';
import Button, { variantTypes } from '@/ui/shared/components/Button';
import styles from './deleteModal.scss';
import { Spinner, Modal } from '@dealroadshow/uikit';

interface IProps {
  isModalVisible: boolean,
  item: any,
  onDelete: (payload: { id: string }) => void,
  onCancel: () => void,
  isSpinnerVisible: boolean,
}

const DeleteModal = (
  {
    item,
    isModalVisible,
    onDelete,
    onCancel,
    isSpinnerVisible,
  }: IProps,
) => {
  const onDeleteClick = () => {
    onDelete({ id: item.id });
  };

  const getModalFooter = () => (
    <>
      <Button
        disabled={ isSpinnerVisible }
        variant={ variantTypes.action }
        className={ styles.deleteBtn }
        onClick={ onDeleteClick }
        dataTest="deleteModalClickButton"
      >
        Delete
      </Button>
      <Button
        variant={ variantTypes.text }
        onClick={ onCancel }
        dataTest="deleteModalCancelButton"
      >
        Cancel
      </Button>
    </>
  );
  return (
    <Modal
      title="Delete Confirmation"
      isVisible={ isModalVisible }
      onCloseClicked={ onCancel }
      footer={ getModalFooter() }
      className={ styles.modal }
      dataTest="deleteConfirmationModal"
    >
      <span>
        There are <strong>0 deals</strong> tagged to
        <strong> { item.name } </strong>.
        Are you sure you want to delete this company?
      </span>
      { isSpinnerVisible && (<Spinner isVisible overlay />) }
    </Modal>
  );
};

export default DeleteModal;
