import React from 'react';
import AddItemsInputContainer from './AddItemsInputContainer';
import DeleteModalContainer from './DeleteModalContainer';
import PaginatorContainer from './PaginatorContainer';
import DataTableContainer from './DataTableContainer';
import SearchInputContainer from './SearchInputContainer';

import Blacklist from '@/condor/ui/common/Blacklist';

const GLOBAL_BLACKLIST_DELETE_MODAL_WARNING_TEXT = `Deleting domains from Global Blacklist will allow any contact
with that email domain to access all Finsight platforms.`;

interface IProps {
  init: () => void,
  reset: () => void,
  onDeleteIconClick: () => void,
}

const GlobalBlacklist = (props: IProps) => (
  <Blacklist
    pageLabel="Global Blacklist"
    tableLabel="Blacklist Across All Finsight Platforms"
    deleteModalWarningText={ GLOBAL_BLACKLIST_DELETE_MODAL_WARNING_TEXT }
    onMount={ props.init }
    onUnmount={ props.reset }
    onDeleteIconClick={ props.onDeleteIconClick }
    components={
      {
        addItemsInput: AddItemsInputContainer,
        deleteModal: DeleteModalContainer,
        paginator: PaginatorContainer,
        dataTable: DataTableContainer,
        searchInput: SearchInputContainer,
      }
    }
  />
);

export default GlobalBlacklist;
