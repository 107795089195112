import React, { memo } from 'react';
import cn from 'classnames';
import styles from './togglingFilters.scss';
import { Tooltip } from '@dealroadshow/uikit';
import { isScreenM } from '@/ui/shared/styles/screen/screen';

interface IProps {
  isDisabled: boolean,
  isTooltipEnabled?: boolean,
  applyFilter: (name: string, id: string) => any,
  filterSet: {
    items: any[],
    isVisible: boolean,
  },
}

const TogglingFilters = ({
  filterSet,
  isDisabled = false,
  isTooltipEnabled = true,
  applyFilter,
}: IProps) => Object.keys(filterSet).map((filterName) => {
  const filter = filterSet[filterName];

  if (filter?.isVisible === false) {
    return null;
  }

  /**
   * Proxy function for disabled functionality but leave tooltips work
   * @param {String} filterName
   * @param {String} id
   */
  const onFilterApply = (filterName, id) => {
    if (isDisabled) {
      return;
    }

    applyFilter(filterName, id);
  };

  return (
    <div key={ filterName } className={ cn(styles.filtersWrapper, { isDisabled }) }>
      {
        filter.items.map((filter) => (
          <Tooltip
            key={ filter.id }
            content={ filter.tooltip }
            disabled={ !isTooltipEnabled || isScreenM() }
          >
            <button
              type="button"
              onClick={ () => onFilterApply(filterName, filter.id) }
              className={ cn(styles.filter, {
                [styles.isActive]: filter.isActive,
                [styles.isDisabled]: isDisabled,
              }) }
              data-test="togglingFilterApplyButton"
            >
              { filter.abbreviation }
            </button>
          </Tooltip>
        ))
      }
    </div>
  );
});

export default memo(TogglingFilters);
