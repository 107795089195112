import React from 'react';
import { components as ReactSelectComponents, SingleValueProps, OptionProps } from 'react-select';
import { Icon, IconType } from '@dealroadshow/uikit';
import { TaggingStateTypes } from '@/condor/domain/vo/EnhancedTagging/TaggingState';
import FinalFormSelect from '@/ui/shared/components/Form/Select/legacy/FinalFormSelect';

import styles from './taggingStateSelect.scss';

interface ITaggingStateOption {
  value: TaggingStateTypes,
  label: string,
  icon: IconType,
}

const taggingStateOptions: ITaggingStateOption[] = [
  {
    value: TaggingStateTypes.FULLY_TAGGED,
    label: 'Mark all as Fully Tagged',
    icon: IconType.tag,
  },
  {
    value: TaggingStateTypes.PARTIALLY_TAGGED,
    label: 'Mark all as Partially Tagged',
    icon: IconType.partiallyTagged,
  },
  {
    value: TaggingStateTypes.NOT_TAGGED,
    label: 'Mark all as Untagged',
    icon: IconType.notTagged,
  },
];

const Option = ({ children, ...props }: OptionProps<ITaggingStateOption>) => (
  <ReactSelectComponents.Option { ...props } className={ styles.selectAccountOptions }>
    <Icon type={ props.data.icon } className={ styles.selectIcon } />
    { children }
  </ReactSelectComponents.Option>
);

const SingleValue = ({ children, ...props }: SingleValueProps<ITaggingStateOption>) => (
  <ReactSelectComponents.SingleValue { ...props }>
    { props.data.icon && <Icon type={ props.data.icon } className={ styles.selectIcon } /> }
  </ReactSelectComponents.SingleValue>
);

interface IProps {
  value: TaggingStateTypes,
}

const TaggingStateSelect = ({ value, ...restProps }: IProps & Record<string, any>) => {
  return (
    // @ts-ignore
    <FinalFormSelect
      components={ {
        Option,
        SingleValue,
      } }
      selectClassName={ styles.select }
      options={ taggingStateOptions }
      placeholder="Tag"
      searchable={ false }
      { ...restProps }
    />
  );
};

export default TaggingStateSelect;
