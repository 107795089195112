import FinsightCompanyRepository from '@/finsight/infrastructure/repository/CompanyRepository';
import { getCollectionActions } from '@/Framework/State/Redux/collectionFactory';
import actionTypes from './actionTypes';
import selectors from './selectors';

export const {
  getCollection: getCondorFinsightCompanies,
  sort,
  paginate,
  itemsPerPage,
  setSearchQuery: setCondorFinsightSearch,
  reset: resetCondorFinsightCompanies,
} = getCollectionActions(
  actionTypes,
  (payload, dispatch, getState) => {
    const finsightCompanyRepository = getState().container.get(FinsightCompanyRepository);
    return finsightCompanyRepository.getCompaniesListAggregate(payload);
  },
);

export const getCondorFinsightCompaniesByFilter = () => (dispatch, getState) => {
  const state = getState();

  const payload = {
    page: selectors.getPage(state),
    perPage: selectors.getPerPage(state),
    query: selectors.getSearchQuery(state),
    orderBy: selectors.getSortBy(state),
    sortOrder: selectors.getSortOrder(state),
  };

  dispatch(getCondorFinsightCompanies(payload));
};
