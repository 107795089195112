import { Dependencies } from 'constitute';
import { v4 as uuid } from 'uuid';
import { RpcSuccess, RpcError } from '@dealroadshow/json-rpc-dispatcher';
import { Subscription } from '@dealroadshow/socket-frontend-sdk';
import FinsightRpcDispatcher from '@/finsight/application/DI/Rpc/finsight-web/HttpDispatcher';
import Request from '@/Framework/api/Rpc/Request';
import SocketPush from '@/finsight/application/DI/Socket/Client';
import BaseFinsightRepository from '@/finsight/infrastructure/repository/BaseFinsightRepository';
import { IDataChart } from '@/finsight/domain/vo/Bond/IDataChart';

@Dependencies(FinsightRpcDispatcher, SocketPush)
class BondsRepository extends BaseFinsightRepository {
  rpcinvestorPendingStatusCountChangeSubscription: Subscription;

  excelExportUrlSubscription: Subscription;

  getExcelExportUrlBondAnalyticsContactsSubscription: Subscription;

  constructor(protected rpc: typeof FinsightRpcDispatcher, protected socketPush: typeof SocketPush) {
    super(rpc);
    this.rpcinvestorPendingStatusCountChangeSubscription = null;
    this.excelExportUrlSubscription = null;
    this.getExcelExportUrlBondAnalyticsContactsSubscription = null;
    this.getExcelExportUrlBondAnalyticsContacts = this.getExcelExportUrlBondAnalyticsContacts.bind(this);
    this.getExcelExportUrlTraceAnalyticsContacts = this.getExcelExportUrlTraceAnalyticsContacts.bind(this);
  }

  getBwicList = async (payload) => this.abortableCall(
    new Request('finsight_web.market.bond.bwic.get_bwics', payload),
  );

  getInventoryList = async (payload) => this.abortableCall(
    new Request('finsight_web.market.bond.inventory.get_inventories', payload),
  );

  getCondorBwicList = async (payload) => this.abortableCall(
      new Request('finsight_web.bond.bwic.get_bwics', payload),
  );

  getCondorInventoryList = async (payload) => this.abortableCall(
      new Request('finsight_web.bond.inventory.get_inventories', payload),
  );

  getAssetClassList = async () => {
    let response = await this.rpc.call<RpcSuccess>(new Request('finsight_web.market.bond.asset_class.get_asset_classes', {}));
    return response.getResult().payload;
  };

  getTraceAssetClassList = async () => {
    let response = await this.rpc.call<RpcSuccess>(new Request('finsight_web.market.bond.asset_class.get_trace_asset_classes', {}));
    return response.getResult().payload;
  };

  /**
   * @param {string} payload - bwic id
   */
  deleteBwic = async (payload) => {
    let response = await this.rpc.call<RpcSuccess>(new Request('finsight_web.bond.bwic.delete', payload));
    return response.getResult().payload;
  };

  /**
   * @param {string} payload - inventory id
   */
  deleteInventory = async (payload) => {
    let response = await this.rpc.call<RpcSuccess>(new Request('finsight_web.bond.inventory.delete', payload));
    return response.getResult().payload;
  };

  /**
   * @param {String} assetClassId
   * @param {Object} filtersPayload
   * @param {String} search
   * @param {String} url
   */
  getExcelExportUrl = async (assetClassId, filtersPayload, search, url) => {
    let exportId = uuid();

    let req = new Request('finsight_web.market.bond.generate_bond_document_export',
      {
        filter: {
          ...filtersPayload,
          includedAssetClassId: assetClassId,
        },
        exportId,
        search,
        url,
      });

    const socketSubscribeReq = new Request('finsight_web.socket.notifications', {
      entityId: exportId,
    });

    await this.rpc.call(req);
    this.excelExportUrlSubscription = await this.socketPush.subscribe(socketSubscribeReq);

    return new Promise((resolve, reject) => {
      this.excelExportUrlSubscription.on('finsight_web.export_generation',
        ({ params: { payload: { status, data: { url: exportUrl, error } } } }) => {
          this.excelExportUrlSubscription.cancel();
          if (status === 'SUCCESS') {
            resolve(exportUrl);
          } else {
            reject(new RpcError({
              id: req.id,
              error,
            }));
          }
        },
      );
    });
  };

  getInvestorAccessPendingStatusCount = async () => {
    let req = new Request('finsight_web.bond.investor.access.get_investors_access_pending_status_count', {});
    let response = await this.rpc.call<RpcSuccess>(req);
    return response.getResult().payload;
  };

  subscribeToInvestorAccessPendingStatusCountChange = async (callback = () => {}) => {
    this.rpcinvestorPendingStatusCountChangeSubscription = await this.socketPush.subscribe(
      new Request('finsight_web.socket.notifications', {
        entityId: '3c72d6f2-c201-4838-b116-1b2db73973ef',
      }),
    );
    this.rpcinvestorPendingStatusCountChangeSubscription.on(
      'finsight_web.bond.investor_access.investor_access_pending_count_change',
      callback,
    );
  };

  unsubscribeFromInvestorAccessPendingStatusCountChange() {
    this.rpcinvestorPendingStatusCountChangeSubscription?.cancel();
  }

  async getBondsFilter(filter) {
    const payload = { filter };
    let req = new Request('finsight_web.market.bond.filter.get_boundary_filter', payload);
    let response = await this.rpc.call<RpcSuccess>(req);
    return response.getResult().payload;
  }

  async getBondsIssuersFilter(filter, search) {
    const payload = { filter, search };
    let req = new Request('finsight_web.market.bond.filter.get_issuers', payload);
    let response = await this.rpc.call<RpcSuccess>(req);
    return response.getResult().payload;
  }

  async getBondsFilterByHash(hash) {
    const payload = { hash };
    let req = new Request('finsight_web.bond.filter.get_by_hash', payload);
    let response = await this.rpc.call<RpcSuccess>(req);
    return response.getResult().payload;
  }

  getBondInvestorAccessRequestList = async (payload = {}) => {
    let req = new Request('finsight_web.bond.investor.access.get_bond_investor_access_aggregate', payload);
    let response = await this.rpc.call<RpcSuccess>(req);
    return response.getResult().payload;
  };

  getBondInvestorWhitelist = async (payload = {}) => {
    let req = new Request('finsight_web.bond.investor.whitelist.get_bond_investor_whitelist_aggregate', payload);
    let response = await this.rpc.call<RpcSuccess>(req);
    return response.getResult().payload;
  };

  updateInvestorAccessRequest = async (payload = {}) => {
    let req = new Request('finsight_web.bond.investor.access.save_investor_access', payload);
    let response = await this.rpc.call<RpcSuccess>(req);
    return response.getResult().payload;
  };

  addEmailsAndDomainsToWhitelist = async (payload = {}) => {
    let req = new Request('finsight_web.bond.investor.whitelist.save_bond_investor_whitelist', payload);
    let response = await this.rpc.call<RpcSuccess>(req);
    return response.getResult().payload;
  };

  deleteWhitelistItem = async (payload = {}) => {
    let req = new Request('finsight_web.bond.investor.whitelist.delete_bond_investor_whitelist_item', payload);
    let response = await this.rpc.call<RpcSuccess>(req);
    return response.getResult().payload;
  };

  getBondAnalyticsContactsList = async (payload) => this.abortableCall(
      new Request('finsight_web.bond.investor.analytics.get_bi_investor_login_stats', payload),
  );

  getTraceAnalyticsContactsList = async (payload) => this.abortableCall(
      new Request('finsight_web.bond.investor.analytics.get_trace_investor_login_stats', payload),
  );

  /**
   * @return {Promise}
   */
  async getExcelExportUrlBondAnalyticsContacts() {
    let exportId = uuid();

    let req = new Request(
      'finsight_web.bond.investor.analytics.get_bi_investor_login_stats_export',
      { exportId },
    );

    this.getExcelExportUrlBondAnalyticsContactsSubscription = await this.socketPush.subscribe(
      new Request('finsight_web.socket.notifications', {
        entityId: exportId,
      }),
    );
    await this.rpc.call(req);
    return new Promise((resolve, reject) => {
      this.getExcelExportUrlBondAnalyticsContactsSubscription.on('finsight_web.export_generation',
        ({ params: { payload: { status, data: { url: exportUrl, error } } } }) => {
          this.getExcelExportUrlBondAnalyticsContactsSubscription.cancel();
          if (status === 'SUCCESS') {
            resolve(exportUrl);
          } else {
            reject(new RpcError({
              id: req.id,
              error,
            }));
          }
        },
      );
    });
  }

  /**
   * @return {Promise}
   */
  async getExcelExportUrlTraceAnalyticsContacts() {
    let exportId = uuid();

    let req = new Request(
        'finsight_web.bond.investor.analytics.get_trace_investor_login_stats_export',
        { exportId },
    );

    this.getExcelExportUrlBondAnalyticsContactsSubscription = await this.socketPush.subscribe(
        new Request('finsight_web.socket.notifications', {
          entityId: exportId,
        }),
    );
    await this.rpc.call(req);
    return new Promise((resolve, reject) => {
      this.getExcelExportUrlBondAnalyticsContactsSubscription.on('finsight_web.export_generation',
          ({ params: { payload: { status, data: { url: exportUrl, error } } } }) => {
            this.getExcelExportUrlBondAnalyticsContactsSubscription.cancel();
            if (status === 'SUCCESS') {
              resolve(exportUrl);
            } else {
              reject(new RpcError({
                id: req.id,
                error,
              }));
            }
          },
      );
    });
  }

  /**
   * @param {Object} payload
   */
  getBondsTile = async (payload) => {
    let response = await this.rpc.call<RpcSuccess>(new Request('finsight_web.market.bond.get_bond_tile_data', payload));
    return response.getResult().payload;
  };

  getBwicSummaryList = async (payload) => {
    let response = await this.rpc.call<RpcSuccess>(new Request('finsight_web.market.bond.bwic.get_dealer_summary', payload));
    return response.getResult().payload;
  };

  getInventorySummaryList = async (payload) => {
    let response = await this.rpc.call<RpcSuccess>(new Request('finsight_web.market.bond.inventory.get_dealer_summary', payload));
    return response.getResult().payload;
  };

  getBwicDealerChartData = async (payload): Promise<IDataChart[]> => this.abortableCall(
    new Request('finsight_web.market.bond.bwic.get_bwics_chart_by_dealer', payload),
  );

  getBwicSectorChartData = async (payload): Promise<IDataChart[]> => this.abortableCall(
    new Request('finsight_web.market.bond.bwic.get_bwics_chart_by_sector', payload),
  );

  getInventoryDealerChartData = async (payload): Promise<IDataChart[]> => this.abortableCall(
    new Request('finsight_web.market.bond.inventory.get_inventories_chart_by_dealer', payload),
  );

  getInventorySectorChartData = async (payload): Promise<IDataChart[]> => this.abortableCall(
    new Request('finsight_web.market.bond.inventory.get_inventories_chart_by_sector', payload),
  );
}

export default BondsRepository;
