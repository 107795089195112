import { toSlug } from '@/Framework/url/helpers/toSlug';

/**
 * @param {Object} response
 * @returns {Array}
 */
export const prepareSubscriptionsData = (response) => Object.values(response).map((groupItem) => ({
  ...groupItem,
  subgroups: Object.values(groupItem.subgroups).map((subGroup) => ({
    ...subGroup,
    fieldName: toSlug(subGroup.subgroupName).replace(/-([a-z])/g, (item) => item[1].toUpperCase()),
    subscriptions: Object.values(subGroup.subscriptions),
  })),
}));

/**
 * @param {Object} response
 * @returns {Object}
 */
export const prepareSubscriptionsFormValues = (response) => {
  const initialValuesArray = Object.values(response).reduce((acc, groupItem) => {
    const subGroups = Object.values(groupItem.subgroups).reduce((accSubGroup, subGroup) => {
      const fieldName = toSlug(subGroup.subgroupName).replace(/-([a-z])/g, (item) => item[1].toUpperCase());
      const filters = Object.values(subGroup.subscriptions)
        .filter((subscriptions) => subscriptions.isSubscribed)
        .map((subscriptions) => subscriptions.subscriptionId);
      accSubGroup.push([fieldName, { checked: !!(filters.length), filters }]);
      return accSubGroup;
    }, []);
    acc.push(...subGroups);
    return acc;
  }, []);

  const mapFromValues = new Map(initialValuesArray);

  return {
    ...Object.fromEntries(mapFromValues),
    // initial value for unsubscribeFromAll tile
    unsubscribeFromAll: false,
  };
};

/**
 * @param {Object} formData
 * @returns {Array}
 */
export const preparePayload = (formData) => {
  if (formData.unsubscribeFromAll) {
    return [];
  }
  return Object.values(formData).reduce(
    (acc, item) => {
      if (item.filters) {
        acc = [...acc, ...item.filters];
      }
      return acc;
    },
    [],
  );
};
