import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getIndustryList } from '@/dealroadshow/application/actions/industry/data/actions';
import * as industrySelectors from '@/dealroadshow/application/actions/industry/selectors';
import Subindustry from './Subindustry';

function mapStateToProps(state) {
  return {
    collection: industrySelectors.getCollection(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onDidMount: getIndustryList,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Subindustry);
