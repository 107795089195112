import { v4 as uuid } from 'uuid';
import * as actionTypes from './actionTypes';
import * as uploadTypes from '../../../uploadTypes';
import { TUploadPreviewFormValue } from '../interfaces';

export interface IReducerState {
  initialFile: TUploadPreviewFormValue,
  url: string,
  process: {
    progress: number,
    status: string,
    uuid: string,
  },
  fileName: string,
  uploadFile: File,
  callback: any,
  name: string,
  uploaded: boolean,
  canSave: boolean,
}

export const initialState: IReducerState = {
  initialFile: null,
  url: '',
  process: {
    progress: 0,
    status: '',
    uuid: '',
  },
  fileName: null,
  uploadFile: null,
  callback: null,
  name: '',
  uploaded: false,
  canSave: true,
};

export default (state: IReducerState = initialState, action): IReducerState => {
  switch (action.type) {
    case actionTypes.UPLOAD_IMAGE_ADD:
      return {
        initialFile: null,
        url: '',
        process: {
          progress: 0,
          status: uploadTypes.UPLOAD_START,
          uuid: action.payload.uuid,
        },
        fileName: action.payload.uploadFile.name,
        uploadFile: action.payload.uploadFile,
        name: action.payload.uploadFile.name,
        callback: action.payload.callback,
        uploaded: false,
        canSave: false,
      };

    case actionTypes.UPLOAD_IMAGE_ERROR:
      if (state.process.uuid === action.payload.uuid) {
        return {
          ...state,
          process: {
            ...state.process,
            status: uploadTypes.UPLOAD_ERROR,
          },
          uploaded: false,
          canSave: false,
        };
      }
      return state;

    case actionTypes.UPLOAD_IMAGE_CHUNK_SUCCESS:
      if (state.process.uuid === action.payload.uuid) {
        return {
          ...state,
          process: {
            ...state.process,
            progress: parseInt(action.payload.progress),
            status: uploadTypes.UPLOAD_CHUNK_SUCCESS,
            uuid: action.payload.uuid,
          },
        };
      }
      return state;
    case actionTypes.UPLOAD_IMAGE_SUCCESS:
      if (state.process.uuid === action.payload.uuid) {
        return {
          ...state,
          process: {
            ...state.process,
            status: uploadTypes.UPLOAD_SUCCESS,
          },
          uploaded: true,
        };
      }
      return state;

    case actionTypes.UPLOAD_IMAGE_PROCESSING:
      if (state.process.uuid === action.payload.uuid &&
        state.process.status !== uploadTypes.UPLOAD_DONE) {
        return {
          ...state,
          process: {
            ...state.process,
            progress: 0,
            status: uploadTypes.UPLOAD_PROCESSING_START,
          },
        };
      }
      return state;

    case actionTypes.UPLOAD_IMAGE_DONE:
      if (state.process.uuid === action.payload.uuid) {
        const url = action.payload.data[0];

        return {
          ...state,
          url,
          fileName: action.payload.fileName,
          process: {
            ...state.process,
            progress: 100,
            status: uploadTypes.UPLOAD_DONE,
          },
          uploaded: true,
          canSave: true,
        };
      }
      return state;

    case actionTypes.UPLOAD_IMAGE_CANCEL_SUCCESS:
      if (state.process.uuid === action.payload.uuid) {
        return initialState;
      }
      return state;

    case actionTypes.UPLOAD_IMAGE_CLEAR:
      return initialState;

    case actionTypes.UPLOAD_IMAGE_INIT:
      return {
        initialFile: action.payload,
        process: {
          progress: 100,
          status: uploadTypes.UPLOAD_DONE,
          uuid: uuid(),
        },
        fileName: null,
        uploadFile: null,
        url: action.payload.url,
        callback: null,
        name: '',
        uploaded: true,
        canSave: true,
      };

    default:
      return state;
  }
};
