const getBondsTileMetadata = (state) => state.finsight.bonds.metadata;

export default {
  getAssetClassCollection: (state) => getBondsTileMetadata(state).assetClassCollection,
  getSearch: (state) => getBondsTileMetadata(state).search,
  isLoginModalOpen: (state) => getBondsTileMetadata(state).isLoginModalOpen,
  isBlurred: (state) => getBondsTileMetadata(state).isBlurred,
  isFetching: (state) => getBondsTileMetadata(state).isFetching,
  isExportFetching: (state) => getBondsTileMetadata(state).isExportFetching,
  getActiveTab: (state) => getBondsTileMetadata(state).activeTab,
  getActiveAssetClassId: (state) => getBondsTileMetadata(state).assetClass?.id,
  getActiveAssetClassAbbreviation: (state) => getBondsTileMetadata(state).assetClass?.abbreviation,
  getActiveAssetClass: (state) => getBondsTileMetadata(state).assetClass,
};
