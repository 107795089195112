import { Dependencies } from 'constitute';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import Request from '@/Framework/api/Rpc/Request';
import FinsightWebRpcDispatcher from '@/finsight/application/DI/Rpc/finsight-web/HttpDispatcher';

@Dependencies(FinsightWebRpcDispatcher)
class RatingAgencyRepository {
  constructor(protected rpc: typeof FinsightWebRpcDispatcher) {}

  /**
   * @param {Object} payload
   */
  getRatingAgencies = async (payload = {}) => {
    const request = new Request('finsight_web.rating_agency.get_rating_agencies', payload);
    const response = await this.rpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };
}

export default RatingAgencyRepository;
