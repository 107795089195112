export const extractDomainsAndEmailsFromString = (inputString: string) => {
  return (
    inputString
      // split by separators
      .split(/[ ,;]+/)
      // remove spaces on start and end
      .map((valueItem) => valueItem.trim())
      // filters spaces
      .filter((valueItem) => !!valueItem)
      // filters duplicates
      .filter((item, pos, array) => array.indexOf(item) === pos)
      .map((emailOrDomain) => {
        const isEmail = emailOrDomain.includes('@');
        const isDomain = !isEmail;

        return {
          emailOrDomain,
          isDomain,
          isEmail,
          domain: isEmail ? emailOrDomain.split('@')[1] : emailOrDomain,
          email: isEmail ? emailOrDomain : null,
          emailUserName: isEmail ? emailOrDomain.split('@')[0] : null,
        };
      })
  );
};
