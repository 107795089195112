import React, { memo } from 'react';
import cn from 'classnames';
import { CONDOR_CREATE_ACCOUNT_TYPE_BASE_URL } from '@/condor/ui/common/constants';
import PageWrapper from '@/condor/ui/common/Layout/PageWrapper';
import TableWrapper from '@/ui/shared/components/Tables/TableWrapper';
import { Spinner, Icon, IconType, dataTableStyles } from '@dealroadshow/uikit';

import AccountTypesTable from './AccountTypesTable';
import AccountTypesTablePaginator from './AccountTypesTable/AccountTypesTablePaginatorContainer';
import columns from './AccountTypesTable/columns';

import btnStyles from '@/ui/shared/components/Button/button.scss';
import cardStyles from '@/ui/shared/styles/card.scss';
import spacesStyles from '@/ui/shared/styles/helpers/spaces.scss';
import pageStyles from '@/ui/shared/styles/page.scss';

interface IProps {
  init: () => void,
  reset: () => void,
}

const AccountTypesList = (props: IProps) => (
  <PageWrapper
    label="Account Types"
    headerComponent={ (
      <div className={ pageStyles.pageHeaderActions }>
        <a
          href={ CONDOR_CREATE_ACCOUNT_TYPE_BASE_URL }
          target="_blank"
          className={ cn(btnStyles.action, btnStyles.btnIcon, btnStyles.btnIconMobile, spacesStyles.mrn) }
        >
          <span className={ spacesStyles.mrm }>Create Account Type</span>
          <Icon type={ IconType.plusBold } />
        </a>
      </div>
    ) }
    onMount={ props.init }
    onUnmount={ props.reset }
  >
    <TableWrapper label="Account Types">
      { /* @ts-ignore */ }
      <AccountTypesTable
        className={ dataTableStyles.isHoverable }
        columns={ columns }
        loadingComponent={ Spinner }
      />
      { /* @ts-ignore */ }
      <AccountTypesTablePaginator className={ cardStyles.cardInner } />
    </TableWrapper>
  </PageWrapper>
);

export default memo(AccountTypesList);
