import { IValidationError } from '@/ui/shared/validators/interfaces/ValidationError';

export const linkedinRegExp = /(linkedin\.com\/)(((\w{2,3})?)|([^/]+\/(([\w|\d-&#?=])+\/?){1,}))$/;

export const validateLinkedinLink = (params: {
  value: string,
  fieldName: string,
}): IValidationError => {
  if (params.value && !linkedinRegExp.test(params.value)) {
    return { [params.fieldName]: 'Invalid LinkedIn URL' };
  }
  return null;
};
