import orderBy from 'lodash/orderBy';
import formatNumber from '@/Framework/dataHelpers/formatters/formatNumber';
import ITranch from '@/finsight/domain/vo/Tranch/ITranch';
import { IRegion } from '@/finsight/domain/vo/Region';
import { IProduct } from '@/finsight/domain/vo/Product';

export const formatDataCell = <T>(cellData: T, instead = '-'): T | string => cellData || instead;

export function formatNumberWithDecimals(number: string | number, decimals: number): string {
  return formatNumber(+number, { decimals });
}

export function getSortedTranches(tranches: ITranch[]) {
  if (!tranches.length) {
    return tranches;
  }

  return orderBy(tranches, (tranche) => tranche.orderingPosition);
}

export function getTrancheFieldWithPercent(field: string | number, decimals: number) : string | number {
  if (/^\d+(\.\d+.+)?$/.test(`${ field }`)) {
    field = (`${ field }`).replace('%', '');
    return `${ formatNumberWithDecimals(field, decimals) }%`;
  }
  return field;
}

interface ISubscribeTexts {
  isSubscribed: boolean,
  productIds: string[],
  regionIds: string[],
  products: IProduct[],
  regions: IRegion[],
}

export const getSubscribeTexts = (
  {
    isSubscribed,
    productIds,
    regionIds,
    products,
    regions,
  }: ISubscribeTexts,
  isSuccessMessage?: boolean,
) : string => {
  if (productIds.length > 1 || regionIds.length > 1 || !products?.length || !regions?.length) {
    return '';
  }

  const region = regions.find((region) => region.id === regionIds[0]);
  const product = products.find((product) => product.id === productIds[0]);
  const regionShortName = region?.shortDisplayName || '';
  const productAbbreviation = product?.abbreviation || '';
  const regionWithPrefixWord = regionShortName === 'US' ? `the ${ regionShortName }` : regionShortName;
  const productAndRegion = `${ regionWithPrefixWord } ${ productAbbreviation }`;

  if (isSuccessMessage) {
    return `You have successfully subscribed to ${ productAndRegion } Weekly Digest.`;
  }

  return isSubscribed
    ? `Subscribed to ${ productAndRegion } Weekly Digest`
    : `Subscribe to ${ productAndRegion } Weekly Digest`;
};
