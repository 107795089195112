import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InvalidatePasswordModal from './InvalidatePasswordModal';
import {
  setUserInvalidatePasswordModalIsVisible,
  invalidateUserPassword,
} from '@/users/application/actions/links/actions';
import { getUser } from '@/users/application/actions/data/selectors';

const mapStateToProps = (state) => (
  {
    isVisible: state.user.links.passwordReset.isInvalidateModalVisible,
    userEmail: getUser(state).email,
  }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    onCloseClicked: () => setUserInvalidatePasswordModalIsVisible({ isVisible: false }),
    invalidatePassword: invalidateUserPassword,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InvalidatePasswordModal);
