import { batch } from 'react-redux';
import actionTypes from './actionTypes';
import { getCondorFinsightSubsribersListByFilter } from '../collection/actions';
import createAsyncRequest from '@/Framework/State/Redux/createAsyncRequest';
import createAction from '@/Framework/State/Redux/createAction';
import { NotificationManager } from '@/ui/shared/components/Notification';

import {
  allSubscriptionsResponseHandler,
  userSubscriptionsResponseHandler,
  getUpdateSuccessText,
} from './helpers';
import SubscriptionsRepository from '@/finsight/infrastructure/repository/SubscriptionsRepository';
import { preparePayload } from '@/finsight/application/actions/subscriptions/helpers';
import { messageCodes } from '@/Framework/Message/messages';
import { getMessage } from '@/Framework/Message/Mapper/getMessage';

/**
 * @param {Object} payload
 */
export const setManageSubscriptionsModalData = (payload) => createAction(
  actionTypes.SET_MANAGE_SUBSCRIPTION_MODAL_DATA,
  payload,
);

/**
 * @param {Boolean} payload
 */
export const setIsSubscribeModalVisible = (payload) => createAction(
  actionTypes.SET_IS_SUBSCRIBE_MODAL_VISIBLE,
  payload,
);

/**
 * @param {Object} payload
 */
export const initSubscriptionsModal = (payload = {}) => (dispatch, getState) => {
  const subscriptionsRepository = getState().container.get(SubscriptionsRepository);

  return dispatch(createAsyncRequest({
    payload,
    method: payload.email
      ? subscriptionsRepository.getUserSubscriptionsByAdmin
      : subscriptionsRepository.getSubscriptionsListByAdmin,
    types: [
      actionTypes.GET_SUBSCRIPTIONS,
      actionTypes.GET_SUBSCRIPTIONS_SUCCESS,
      actionTypes.GET_SUBSCRIPTIONS_ERROR,
    ],
    callbacks: {
      responseHandler: payload.email
        ? userSubscriptionsResponseHandler
        : allSubscriptionsResponseHandler,
    },
  }));
};

/**
 * @param {Object} formData
 */
export const subscribeUsersByAdmin = (formData) => (dispatch, getState) => {
  const subscriptionsRepository = getState().container.get(SubscriptionsRepository);

  return dispatch(createAsyncRequest({
    payload: {
      emails: formData.subscribersListSelect.map((option) => option.value),
      subscriptionIds: preparePayload(formData),
    },
    method: subscriptionsRepository.subscribeMultipleUsersByAdmin,
    types: [
      actionTypes.CONDOR_FINSIGHT_SUBSCRIBE,
      actionTypes.CONDOR_FINSIGHT_SUBSCRIBE_SUCCESS,
      actionTypes.CONDOR_FINSIGHT_SUBSCRIBE_ERROR,
    ],
    callbacks: {
      onSuccess: ({ dispatch }) => {
        batch(() => {
          dispatch(setIsSubscribeModalVisible(false));
          dispatch(getCondorFinsightSubsribersListByFilter());
        });
        NotificationManager.success(getMessage(messageCodes.ADD_SUBSCRIBERS_BY_ADMIN));
      },
    },
  }));
};

/**
 * @param {String} email
 * @param {Object} formData
 */
export const updateUserSubscriptionsByAdmin = (email, formData) => async (dispatch, getState) => {
  const subscriptionsRepository = getState().container.get(SubscriptionsRepository);

  return dispatch(createAsyncRequest({
    types: [
      actionTypes.CONDOR_FINSIGHT_SUBSCRIBE,
      actionTypes.CONDOR_FINSIGHT_SUBSCRIBE_SUCCESS,
      actionTypes.CONDOR_FINSIGHT_SUBSCRIBE_ERROR,
    ],
    method: subscriptionsRepository.updateUserSubscriptionsByAdmin,
    callbacks: {
      onSuccess: ({ dispatch, payload }) => {
        batch(() => {
          dispatch(setManageSubscriptionsModalData(null));
          dispatch(getCondorFinsightSubsribersListByFilter());
        });
        NotificationManager.success(getUpdateSuccessText(payload));
      },
    },
    payload: {
      email,
      subscriptionIds: preparePayload(formData),
    },
  }));
};

export const resetMetadata = () => createAction(actionTypes.RESET_METADATA);
