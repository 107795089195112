import React from 'react';
import { ICellProps } from '@dealroadshow/uikit';
import CellAction from './CellAction';
import CellFileName from './CellFileName';
import { ISsoCertificate } from '@/users/domain/vo/sso/ISsoCertificate';
import { getFormatInTimeZoneDate } from '@/Framework/DateTime/getFormatInTimeZoneDate';
import { DAY_MONTH_YEAR_DATETIME_FORMAT } from '@/Framework/DateTime/dateFormats';

import styles from './styles.scss';

export const cellFileNameCallback = (cellProps: ICellProps<ISsoCertificate>) => <CellFileName { ...cellProps } />;

export const cellUploadDateCallback = (cellProps: ICellProps<ISsoCertificate>) => (
  <div className={ styles.cellUploadDate }>
    { getFormatInTimeZoneDate(cellProps.cellData, 'America/New_York', DAY_MONTH_YEAR_DATETIME_FORMAT) }
  </div>
);

export const cellActionCallback = (cellProps: ICellProps<ISsoCertificate>) => <CellAction { ...cellProps.row } />;
