import React from 'react';
import { getCellDisplayDateFromString } from '@/condor/ui/common/Layout/helpers';
import { LastTableCell } from '@/ui/shared/components/Tables/TableWrapper';
import { ICellProps as IDataTableCellProps, Button, ButtonVariantType } from '@dealroadshow/uikit';
import { IManagePlansSubscriberItem } from '@/condor/domain/vo/FinsightManager/ManagePlans';
import {
  useManageSubscriptionFormContext,
} from '@/condor/application/FinsightManager/ManagePlans/ManageSubscriptionFormContext';

import styles from '../subscribers.scss';

const SubscriberAtCell = (cellProps: IDataTableCellProps<IManagePlansSubscriberItem>) => {
  const { onOpenModal, onSetData } = useManageSubscriptionFormContext();
  const openManageModal = () => {
    onSetData(cellProps.row);
    onOpenModal();
  };

  return (
    <LastTableCell
      className={ styles.wrapper }
      childrenToShowOnHover={ (
        <Button
          onClick={ openManageModal }
          variant={ ButtonVariantType.action }
          dataTest="manageSubscriberButton"
          className={ styles.manageButton }
        >
          Manage
        </Button>
      ) }
    >
      { getCellDisplayDateFromString(cellProps.cellData) }
    </LastTableCell>
  );
};

export default SubscriberAtCell;
