import { connect } from 'react-redux';
import { change } from 'redux-form';
import ProductField from './ProductField';

import selectors from '@/condor/application/actions/finsight/company/deals/collection/selectors';
import { CONDOR_FINSIGHT_COMPANY_DEAL_TABLE_INLINE_EDIT_FORM_NAME } from '@/condor/ui/common/constants';

const mapStateToProps = (state) => ({
  options: selectors.getOptions(state).products,
});

const mapDispatchToProps = {
  clearSectorSubsector: () => change(
    CONDOR_FINSIGHT_COMPANY_DEAL_TABLE_INLINE_EDIT_FORM_NAME,
    'sector',
    null,
  ),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductField);
