import { v4 as uuid } from 'uuid';
import { IDisplayTab } from './interfaces';
import { FormFieldName } from '../../constants';

export const createNewTab = (displayTab?: Partial<IDisplayTab>): IDisplayTab => ({
  id: uuid(),
  name: '',
  display: true,
  regions: [],
  transactionTypes: [],
  whitelist: [],
  email: '',
  ...(displayTab ?? {}),
});

export const getFieldName = (index: number, name: string) => `${ FormFieldName.DISPLAY_TABS }[${ index }].${ name }`;
export const getFieldDataTest = (name: string) => `${ name }Field`;
