import React from 'react';
import { useTranchesContext } from '@/condor/ui/components/Finsight/DealProfile/Tranches/TranchesContext';
import Button, { variantTypes } from '@/ui/shared/components/Button';
import {
  TRANCHES_VISIBLE,
} from '@/condor/ui/components/Finsight/DealProfile/constants';

import styles from './TranchesNavigation.scss';
import alignStyles from '@/ui/shared/styles/helpers/align.scss';
import { Tooltip, Icon, IconType } from '@dealroadshow/uikit';

const TranchesNavigation = () => {
  const { tranchesViewOffset, setTranchesViewOffset, tranchesFieldArrayLength } = useTranchesContext();

  const handleSingleArrowButtonClickForward = () => setTranchesViewOffset(
        tranchesFieldArrayLength - (tranchesViewOffset + TRANCHES_VISIBLE) > TRANCHES_VISIBLE
            ? tranchesViewOffset + TRANCHES_VISIBLE
            : tranchesFieldArrayLength - TRANCHES_VISIBLE,
    );

  const handleSingleArrowButtonClickBack = () => setTranchesViewOffset(
      tranchesViewOffset <= TRANCHES_VISIBLE ? 0 : tranchesViewOffset - TRANCHES_VISIBLE,
  );

  return (
    <div className={ alignStyles.flexSpaceBetween }>
      <Tooltip content="Go to the first">
        <Button
          className={ styles.button }
          variant={ variantTypes.text }
          disabled={ tranchesViewOffset === 0 }
          onClick={ () => setTranchesViewOffset(0) }
          dataTest="condorTranchesNavigationDoubleLeftButton"
        >
          <Icon
            className={ styles.icon }
            type={ IconType.doubleArrowsLeft }
          />
        </Button>
      </Tooltip>
      <div className={ styles.separator } />
      <Button
        className={ styles.button }
        variant={ variantTypes.text }
        disabled={ tranchesViewOffset === 0 }
        onClick={ handleSingleArrowButtonClickBack }
        dataTest="condorTranchesNavigationLeftButton"
      >
        <Icon
          className={ styles.icon }
          type={ IconType.arrowLeft }
        />
      </Button>
      { tranchesViewOffset + 1 } - { tranchesViewOffset + TRANCHES_VISIBLE }
      <span className={ styles.tranchesLength }> { ` / ${ tranchesFieldArrayLength }` } </span>
      <Button
        className={ styles.button }
        variant={ variantTypes.text }
        disabled={ tranchesFieldArrayLength <= TRANCHES_VISIBLE
            || tranchesViewOffset === tranchesFieldArrayLength - TRANCHES_VISIBLE }
        onClick={ handleSingleArrowButtonClickForward }
        dataTest="condorTranchesNavigationRightButton"
      >
        <Icon
          className={ styles.icon }
          type={ IconType.arrowRight }
        />
      </Button>
      <div className={ styles.separator } />
      <Tooltip content="Go to the last">
        <Button
          className={ styles.button }
          variant={ variantTypes.text }
          disabled={ tranchesFieldArrayLength <= TRANCHES_VISIBLE
              || tranchesViewOffset === tranchesFieldArrayLength - TRANCHES_VISIBLE }
          onClick={ () => setTranchesViewOffset(tranchesFieldArrayLength - TRANCHES_VISIBLE) }
          dataTest="condorTranchesNavigationDoubleRightButton"
        >
          <Icon
            className={ styles.icon }
            type={ IconType.doubleArrowsRight }
          />
        </Button>
      </Tooltip>
    </div>
  );
};

export default TranchesNavigation;
