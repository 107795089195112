import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import TranchesHeader from './TranchesHeader';
import { CONDOR_DEAL_PROFILE_FORM_NAME } from '@/condor/ui/common/constants';
import { DEAL_PROFILE_FIELDS } from '../../constants';

const selector = formValueSelector(CONDOR_DEAL_PROFILE_FORM_NAME);

const mapStateToProps = (state) => (
  {
    tranchesLength: selector(state, DEAL_PROFILE_FIELDS.TRANCHES.name)?.length || 0,
  }
);

export default connect(
  mapStateToProps,
)(TranchesHeader);
