import { connect } from 'react-redux';
import IndustryCell from './IndustryCell';
import industrySelectors from '@/condor/application/actions/finsight/industry/collection/selectors';

const mapStateToProps = (state, ownProps) => ({
  industryId: ownProps.row.industry?.id,
  subindustryId: ownProps.row.subindustry?.id,
  industries: industrySelectors.getCollectionData(state),
});

export default connect(
  mapStateToProps,
)(IndustryCell);
