import { connect } from 'react-redux';
import { isSubmitting, isPristine, isInvalid, getFormValues } from 'redux-form';
import { openIssuerDealsPage } from '@/condor/application/actions/finsight/deal/actions';
import DealProfileButtons from './DealProfileButtons';
import selectors from '@/condor/application/actions/finsight/deal/selectors';
import {
  CONDOR_DEAL_PROFILE_FORM_NAME,
} from '@/condor/ui/common/constants';
import { openFilingsPage } from '@/condor/application/actions/finsight/filings/item/actions';

const mapStateToProps = (state) => (
  {
    formValues: getFormValues(CONDOR_DEAL_PROFILE_FORM_NAME)(state),
    currentDealPrimaryIssuerId: selectors.getItemData(state).primaryIssuer?.id,
    initialIssuerId: selectors.getInitialIssuer(state)?.id,
    submitDisabled: (
      isSubmitting(CONDOR_DEAL_PROFILE_FORM_NAME)(state) ||
      isPristine(CONDOR_DEAL_PROFILE_FORM_NAME)(state) ||
      isInvalid(CONDOR_DEAL_PROFILE_FORM_NAME)(state)
    ),
  }
);

const mapDispatchToProps = {
  openIssuerDealsPage,
  openFilingsPage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DealProfileButtons);
