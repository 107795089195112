import { connect } from 'react-redux';
import TimeZoneAbbr from '@/Framework/TimeZone/TimeZoneAbbr';

function mapStateToProps(state) {
  return {
    abbr: state.dealRoadshows.roadshow.analytics.all.timeZone.abbr,
  };
}

export default connect(
  mapStateToProps,
  null,
)(TimeZoneAbbr);
