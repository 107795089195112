import { IValidationError } from './interfaces/ValidationError';
import isValid from 'date-fns/isValid';

interface IParams {
  value: string,
  fieldName: string,
  fieldCaption: string,
}

export const validateDate = (params: IParams): IValidationError => {
  if (params.value && !isValid(new Date(params.value))) {
    return { [params.fieldName]: `${ params.fieldCaption || 'Field' } is invalid` };
  }
  return null;
};
