import { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import {
  createCompanyOptionWithIssuerTicker,
  createCompanyOptionWithIssuerTickerAndAbbreviation,
} from './createCompanyOption';
import utcToZonedDate from '@/Framework/DateTime/utcToZonedDate';
import { getTime } from 'date-fns';
import { getOptionMapper, mapToDefaultOption } from '@/Framework/dataHelpers/selectOptionMappers';
import { DEAL_PROFILE_FIELDS, DEAL_PROFILE_FIELDS_ARRAY, DEAL_PROFILE_TRANCHE_FIELDS_ARRAY } from '../constants';

/**
 * @param {Array} fields
 * @param {Object} values
 */
const getTextFieldsInitialValues = (fields, values = {}) => fields.reduce(
  (result, currentField) => {
    const { isSelect, isDate, isBoolean, isNumber } = currentField;
    return {
      ...result,
      ...(
        !isSelect &&
        !isDate &&
        !isBoolean &&
        !isNumber &&
        (currentField.name !== DEAL_PROFILE_FIELDS.TRANCHE.name) &&
        {
          [currentField.name]: values[currentField.name] ? values[currentField.name] : '',
        }
      ),
    };
  },
  {},
);

/**
 * @param {Array} fields
 * @param {Object} values
 */
const getDateFieldsInitialValues = (fields, values = {}) => fields.reduce(
  (result, currentField) => {
    const getDateValue = () => {
      if (!values[currentField.name]) {
        return null;
      }
      switch (typeof values[currentField.name]) {
        case 'object': // In Deal object date values are objects */
          const { timezone, dateTime } = values[currentField.name];
          return getTime(utcToZonedDate(timezone.timeZone, new Date(dateTime)));
        default: // In Tranche object date values are strings
          return getTime(utcToZonedDate('UTC', new Date(values[currentField.name])));
      }
    };

    return (
      {
        ...result,
        ...(currentField.isDate && { [currentField.name]: getDateValue() }),
      }
    );
  },
  {},
);

/**
 * @param {Array} fields
 * @param {Object} values
 */
const getNumberFieldsInitialValues = (fields, values = {}) => fields.reduce(
  (result, currentField) => (
    {
      ...result,
      ...(
        currentField.isNumber && {
          [currentField.name]: (
            (!!values[currentField.name] || (values[currentField.name] === 0))
              ? values[currentField.name].toString()
              : ''
          ),
        }
      ),
    }
  ),
  {},
);

/**
 * @param {Array} fields
 */
const getNewObjectSelectFieldsInitialValues = (fields) => fields.reduce(
  (result, currentSelectField) => (
    {
      ...result,
      ...(currentSelectField.isSelect && { [currentSelectField.name]: null }),
    }
  ),
  {},
);

/**
 * @param {Array} fields
 */
const getNewObjectBooleanFieldsInitialValues = (fields) => fields.reduce(
  (result, currentField) => (
    {
      ...result,
      ...(currentField.isBoolean && { [currentField.name]: false }),
    }
  ),
  {},
);

/**
 * @param {Array} ratingAgencies
 * @return {Object}
 */
const getNewTrancheInitialValues = (ratingAgencies) => (
  {
    ...getTextFieldsInitialValues(DEAL_PROFILE_TRANCHE_FIELDS_ARRAY),
    ...getNewObjectBooleanFieldsInitialValues(DEAL_PROFILE_TRANCHE_FIELDS_ARRAY),
    ...getNumberFieldsInitialValues(DEAL_PROFILE_TRANCHE_FIELDS_ARRAY),
    ...getDateFieldsInitialValues(DEAL_PROFILE_TRANCHE_FIELDS_ARRAY),
    ...getNewObjectSelectFieldsInitialValues(DEAL_PROFILE_TRANCHE_FIELDS_ARRAY),
    [DEAL_PROFILE_FIELDS.TRANCHE.RATINGS.name]: ratingAgencies.reduce(
      (result, agency) => ({ ...result, [agency.abbreviation]: null }), {},
    ),
  }
);

const mapToAbbreviationOption = getOptionMapper({ pathToLabel: 'abbreviation' });

/**
 * @param {Object} benchmark
 * @return {Object}
 */
const mapBenchmarkToAbbreviationOption = (benchmark) => (
  {
    value: benchmark.id,
    label: benchmark.abbreviation,
    disabled: !benchmark.trusted,
  }
);

/**
 * @param {Object} deal
 * @return {Object}
 */
const getDealDetailsInitialSelectValues = (deal) => (
  {
    [DEAL_PROFILE_FIELDS.PARENT.name]: mapToDefaultOption(deal.parent),
    [DEAL_PROFILE_FIELDS.PRODUCT.name]: mapToAbbreviationOption(deal.product),
    [DEAL_PROFILE_FIELDS.PRIMARY_REGION.name]: deal.country ? mapToAbbreviationOption(deal.country.region) : null,
    [DEAL_PROFILE_FIELDS.COUNTRY.name]: !deal.country ? null : {
      ...mapToDefaultOption(deal.country),
      region: mapToAbbreviationOption(deal.country.region),
    },
    [DEAL_PROFILE_FIELDS.ABS_SECTOR_SUBSECTOR.name]: !(deal.sector && deal.subsector) ? null : {
      value: deal.subsector.id,
      sectorId: deal.sector.id,
      label: `${ deal.sector.name } - ${ deal.subsector.name }`,
    },
  }
);

/**
 * @param {Object} initialIssuer
 * @param {Object} initialDeal
 * @param {Array} ratingAgencies
 * @param {Boolean} isSecFiling
 * @return {Object}
 */
const getNewDealInitialValues = ({ initialIssuer, initialDeal, ratingAgencies, isSecFiling }) => {
  /**
   * @param {Array} fields
   */
  const getNewDealMultiSelectValuesInitialValues = (fields) => fields.reduce(
    (result, currentMultiSelectField) => (
      {
        ...result,
        ...(currentMultiSelectField.isMultiSelect && { [currentMultiSelectField.name]: [] }),
      }
    ),
    {},
  );

  return {
    ...getTextFieldsInitialValues(DEAL_PROFILE_FIELDS_ARRAY),
    ...getNewObjectBooleanFieldsInitialValues(DEAL_PROFILE_FIELDS_ARRAY),
    ...getNumberFieldsInitialValues(DEAL_PROFILE_FIELDS_ARRAY),
    ...getDateFieldsInitialValues(DEAL_PROFILE_FIELDS_ARRAY),
    ...getNewObjectSelectFieldsInitialValues(DEAL_PROFILE_FIELDS_ARRAY),
    ...getNewDealMultiSelectValuesInitialValues(DEAL_PROFILE_FIELDS_ARRAY),
    ...(!isEmpty(initialDeal) && getDealDetailsInitialSelectValues(initialDeal)),
    [DEAL_PROFILE_FIELDS.TRANCHES.name]: isSecFiling ? [] : [getNewTrancheInitialValues(ratingAgencies)],
    [DEAL_PROFILE_FIELDS.PRIMARY_ISSUER.name]: !isEmpty(initialIssuer)
      ? createCompanyOptionWithIssuerTicker(initialIssuer)
      : null,
  };
};

/**
 * @param {Object} item
 * @param {Array} ratingAgencies
 * @param {Object} options
 * @return {Object}
 */
const getExistingDealInitialValues = ({ item, ratingAgencies, options }) => (
  {
    ...item,
    // DEAL DETAILS SELECT VALUES
    ...getDealDetailsInitialSelectValues(item),
    [DEAL_PROFILE_FIELDS.PRIMARY_ISSUER.name]: createCompanyOptionWithIssuerTicker(item.primaryIssuer),
    // TEXT VALUES AND DEAL ID
    ...getTextFieldsInitialValues(DEAL_PROFILE_FIELDS_ARRAY, item),
    [DEAL_PROFILE_FIELDS.DEAL_ID.name]: item.id,
    // NUMBER INPUT VALUES
    ...getNumberFieldsInitialValues(DEAL_PROFILE_FIELDS_ARRAY, item),
    // DATEPICKER VALUES
    ...getDateFieldsInitialValues(DEAL_PROFILE_FIELDS_ARRAY, item),
    // CHECKBOX VALUES OF TINYINT TYPE
    [DEAL_PROFILE_FIELDS.RESET.name]: !!item.reset,
    [DEAL_PROFILE_FIELDS.REFINANCING.name]: !!item.refinancing,
    // MULTI SELECT VALUES
    [DEAL_PROFILE_FIELDS.ADDITIONAL_ISSUERS.name]: (
      item.additionalIssuers?.map(createCompanyOptionWithIssuerTickerAndAbbreviation) || []
    ),
    [DEAL_PROFILE_FIELDS.ADDITIONAL_REGIONS.name]: item.regions
      ? item.regions
        .filter((region) => region.id !== item.country.region.id)
        .map(mapToAbbreviationOption)
      : [],
    [DEAL_PROFILE_FIELDS.STRUCTURING_LEADS.name]: (
      item.structuringLeads?.map(createCompanyOptionWithIssuerTickerAndAbbreviation) || []
    ),
    [DEAL_PROFILE_FIELDS.JOINT_LEADS.name]: (
      item.jointLeads?.map(createCompanyOptionWithIssuerTickerAndAbbreviation) || []
    ),
    // TRANCHE
    [DEAL_PROFILE_FIELDS.TRANCHES.name]: item.tranches?.map(
      (tranche) => (
        {
          ...tranche,
          [DEAL_PROFILE_FIELDS.TRANCHE.IS_EXCLUDED.name]: false,
          // TEXT VALUES AND ORDERING POSITION
          ...getTextFieldsInitialValues(DEAL_PROFILE_TRANCHE_FIELDS_ARRAY, tranche),
          [DEAL_PROFILE_FIELDS.TRANCHE.ORDERING_POSITION.name]: (
            tranche[DEAL_PROFILE_FIELDS.TRANCHE.ORDERING_POSITION.name]
          ),
          // NUMBER INPUT VALUES
          ...getNumberFieldsInitialValues(DEAL_PROFILE_TRANCHE_FIELDS_ARRAY, tranche),
          [DEAL_PROFILE_FIELDS.TRANCHE.SIZE.name]: tranche.size?.size.toString() || '',
          // DATEPICKER VALUES
          ...getDateFieldsInitialValues(DEAL_PROFILE_TRANCHE_FIELDS_ARRAY, tranche),
          // CODES VALUES
          [DEAL_PROFILE_FIELDS.TRANCHE.CUSIPS.name]: tranche.cusips?.join(',') || '',
          [DEAL_PROFILE_FIELDS.TRANCHE.CUSIPS_REGS.name]: tranche.cusipsRegs?.join(',') || '',
          [DEAL_PROFILE_FIELDS.TRANCHE.ISINS.name]: tranche.isins?.join(',') || '',
          // SELECT VALUES
          [DEAL_PROFILE_FIELDS.TRANCHE.CURRENCY.name]: (
            options.currencies.find((cur) => tranche.currencyId === cur.value)
          ),
          [DEAL_PROFILE_FIELDS.TRANCHE.RANKING.name]: (
            tranche.ranking ? mapToDefaultOption(tranche.ranking) : null
          ),
          [DEAL_PROFILE_FIELDS.TRANCHE.COUPON_TYPE.name]: (
            tranche.couponType ? mapToDefaultOption(tranche.couponType) : null
          ),
          [DEAL_PROFILE_FIELDS.TRANCHE.REGISTRATION.name]: (
            tranche.registration ? mapToDefaultOption(tranche.registration) : null
          ),
          [DEAL_PROFILE_FIELDS.TRANCHE.BENCHMARK.name]: (
            tranche.benchmark ? mapBenchmarkToAbbreviationOption(tranche.benchmark) : null
          ),
          // RATINGS VALUES
          [DEAL_PROFILE_FIELDS.TRANCHE.RATINGS.name]: ratingAgencies.reduce(
            (resultRatings, currentAgency) => (
              {
                ...resultRatings,
                [currentAgency.abbreviation]: tranche.ratings[currentAgency.id]
                  ? mapToAbbreviationOption(tranche.ratings[currentAgency.id][0])
                  : null,
              }
            ),
            {},
          ),
        }
      ),
    ) || [],
  }
);

/**
 * @param {Object} item
 * @param {Array} ratingAgencies
 * @param {Boolean} isInitialized
 * @param {Boolean} isCreateNewDeal
 * @param {Object} options
 * @param {Object} initialIssuer
 * @param {Object} initialDeal
 * @param {Boolean} isSecFiling
 * @return {Object}
 */
const useDealInitialValues = (
  {
    item,
    ratingAgencies,
    isInitialized,
    isCreateNewDeal,
    options,
    initialIssuer,
    initialDeal,
    isSecFiling,
  },
) => {
  const [initialValues, setInitialValues] = useState({});

  useEffect(
    () => {
      if (isInitialized) {
        setInitialValues(
          isCreateNewDeal
            ? getNewDealInitialValues({ initialIssuer, initialDeal, ratingAgencies, isSecFiling })
            : getExistingDealInitialValues({ item, ratingAgencies, options }),
        );
      }
    },
    [isInitialized, initialIssuer],
  );

  return initialValues;
};

export default useDealInitialValues;
export { getNewTrancheInitialValues };
