import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { batch } from 'react-redux';
import { push, replace } from '@dealroadshow/connected-next-router';
import createAction from '@/Framework/State/Redux/createAction';
import isEmptyString from '@/Framework/dataHelpers/string/isEmptyString';
import { addQueryStringParam, removeQueryStringParam } from '@/Framework/url/helpers/queryString';
import BondsRepository from '@/finsight/infrastructure/repository/BondsRepository';
import { filtersUpdate } from '@/ui/shared/modules/Filters/actions';
import { mergeCheckedIdsToParent } from '@/ui/shared/modules/Filters/helpers';
import * as actionTypes from './actionTypes';
import isServer from '@/Framework/Router/Next/isServer';
import Logger from '@/Framework/browser/log/Logger';
import {
  prepareIncludedCurrentFaces,
  isCurrentFacesFromPresets,
  findIncludedCurrentFaceInPresets,
} from '@/finsight/application/actions/bonds/filters/helpers';
import { BONDS_STORE_NAME } from '@/finsight/application/actions/bonds/constants';
import metadataSelectors from '../metadata/selectors';
import bondFiltersSelectors from './selectors';
import filtersSelectors from '@/ui/shared/modules/Filters/selectors';
import { getFormatUrl } from '@/finsight/ui/components/bonds/helpers';
import { getAdditionalParams } from '@/finsight/application/helpers';

/**
 * Prepare everything for bondscreener list and filters
 * @return {Function}
 */
export const applyFilter = () => (dispatch, getState) => {
  const { router, ...state } = getState();
  let initialFilters = bondFiltersSelectors.getInitialFilters(state);
  const filters = filtersSelectors.getFilters(state, BONDS_STORE_NAME);
  const newFiltersPayload = dispatch(getFiltersPayload());

  const isInitialFilters = isEqual(initialFilters, filters);
  const appliedFilters = isInitialFilters ? {} : filters;
  dispatch(payloadChange(newFiltersPayload));
  dispatch(appliedFiltersChange(appliedFilters));

  if (isInitialFilters) {
    const newSearch = removeQueryStringParam(router.location, ['dateFrom', 'dateTo', 'hash']);
    dispatch(push(`${ router.location.pathname }?${ newSearch }`));

    return;
  }

  const additionalParams = getAdditionalParams(newFiltersPayload);

  if (Object.keys(additionalParams).length > 0) {
    const newSearch = addQueryStringParam(router.location, additionalParams);

    dispatch(replace(`${ router.location.pathname }?${ newSearch }`));
  }
};

/**
 * @return {Function}
 */
export const fetchFilter = () => async (dispatch, getState) => {
  const { router, ...state } = getState();
  let initialFilters = bondFiltersSelectors.getInitialFilters(state);
  const filters = filtersSelectors.getFilters(state, BONDS_STORE_NAME);
  const newFiltersPayload = dispatch(getFiltersPayload());

  let response = await dispatch(getBondsFilter(newFiltersPayload));
  const bondsFilter = response.filter || {};
  const isBondsFilter = !!Object.keys(bondsFilter).length;
  const hash = response.hash || '';
  const isInitialFilters = isEqual(initialFilters, filters);
  const isHash = isEmptyString(hash);

  if (isBondsFilter) {
    const { dateFrom, dateTo } = bondsFilter;
    dispatch(setMinMaxDatePeriod(dateFrom, dateTo));
  }
  if (!isHash && !isInitialFilters) {
    const additionalParams = getAdditionalParams(newFiltersPayload);
    const newSearch = addQueryStringParam(router.location, { hash, ...additionalParams });

    dispatch(push(`${ router.location.pathname }?${ newSearch }`));
  }
};

/**
 * @param {Object} filtersPayload
 * @return {Function}
 */
export const getBondsFilter = (filtersPayload) => async (dispatch, getState) => {
  let assetClassId = metadataSelectors.getActiveAssetClassId(getState());
  dispatch(createAction(actionTypes.GET_BONDS_FILTER));
  try {
    const filter = {
      ...filtersPayload,
      includedAssetClassId: assetClassId,
    };
    const bondsRepository = getState().container.get(BondsRepository);
    const response = await bondsRepository.getBondsFilter(filter);

    dispatch(createAction(actionTypes.GET_BONDS_FILTER_SUCCESS, response));
    return response;
  } catch (err) {
    dispatch(createAction(actionTypes.GET_BONDS_FILTER_ERROR));
    return undefined;
  }
};

/**
 * @param {String} hash
 */
export const getBondsFilterByHash = (hash) => (dispatch, getState) => {
  const bondsRepository = getState().container.get(BondsRepository);
  return bondsRepository.getBondsFilterByHash(hash);
};

/**
 * @param {Object} payload
 * @return {Object}
 */
export const payloadChange = (payload) => createAction(actionTypes.BONDS_FILTER_PAYLOAD, payload);

/**
 * @param {Object} payload
 * @return {Object}
 */
export const initialFiltersChange = (payload) => createAction(actionTypes.BONDS_INITIAL_FILTERS, payload);

/**
 * @param {Object} payload
 * @return {Object}
 */
export const appliedFiltersChange = (payload) => createAction(actionTypes.BONDS_FILTER_APPLY, payload);

/**
 * @return {Object}
 */
export const getInitialState = () => ({
  dateFrom: null,
  dateTo: null,
  includedStatus: 'all',
  includedSubsectorIds: [],
  includedIssuerIds: [],
  includedDealerIds: [],
  includedBenchmarkGroupIds: [],
  includedSpeedTypeIds: [],
  includedRatingGroupIds: [],
  includedRatingAgencyIds: [],
  currentFaceRange: {
    range: {
      min: null,
      max: null,
    },
    minValue: null,
    maxValue: null,
  },
  currentFaceRangeIds: [],
  walRange: {
    range: {
      min: null,
      max: null,
    },
    minValue: null,
    maxValue: null,
  },
  spreadRange: {
    range: {
      min: null,
      max: null,
    },
    minValue: null,
    maxValue: null,
  },
  speedRange: {
    range: {
      min: null,
      max: null,
    },
    minValue: null,
    maxValue: null,
  },
  excludedSectorIds: [],
  includedDealIds: [],
  includedIndustryIds: [],
  includedParentIds: [],
  includedSubindustryIds: [],
});

/**
 * @param {Object} filters
 * @return {Function}
 */
export const getFiltersPayload = (filters = {}) => (dispatch, getState) => {
  const isFilters = !!Object.keys(filters).length;
  if (!isFilters) {
    filters = filtersSelectors.getFilters(getState(), BONDS_STORE_NAME);
  }

  const bondsFilter = bondFiltersSelectors.getItemsFilter(getState());
  const isBondsFilter = !!Object.keys(bondsFilter).length;
  let newFilters = cloneDeep(filters);

  const filtersRanges = {
    currentFaceRange: newFilters.currentFaceRange,
    walRange: newFilters.walRange,
    spreadRange: newFilters.spreadRange,
    speedRange: newFilters.speedRange,
  };

  // If range filter value is equal to initial state,
  // we set it to 'null'
  if (isBondsFilter) {
    const bondsFilterRanges = mapRangesForFilters(bondsFilter);
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (let key in filtersRanges) {
      if (isEqual(filtersRanges[key].range.min, bondsFilterRanges[key].range.min)) {
        newFilters[key].range.min = null;
      }
      if (isEqual(filtersRanges[key].range.max, bondsFilterRanges[key].range.max)) {
        newFilters[key].range.max = null;
      }
    }
  }

  let rangesPayload = mapRangesForPayload(newFilters);
  // Cleanup
  Object.keys(filtersRanges).forEach((key) => delete newFilters[key]);

  const checkboxesState = getState().checkboxes;
  const sectorIds = mergeCheckedIdsToParent(
    checkboxesState,
    newFilters,
    'sectorList',
    'includedSubsectorIds',
  );

  return {
    ...newFilters,
    includedSectorIds: sectorIds.parentIds,
    includedSubsectorIds: sectorIds.childrenIds,
    ...rangesPayload,
  };
};

/**
 * @param {Object} bondsFilter
 * @return {Object} mappedRangesForFilters
 */
export const mapRangesForFilters = (bondsFilter) => ({
  currentFaceRange: {
    range: bondsFilter.currentFaceRange.range,
    minValue: bondsFilter.currentFaceRange.range.min,
    maxValue: bondsFilter.currentFaceRange.range.max,
  },
  walRange: {
    range: bondsFilter.walRange.range,
    minValue: bondsFilter.walRange.range.min,
    maxValue: bondsFilter.walRange.range.max,
  },
  spreadRange: {
    range: bondsFilter.spreadRange.range,
    minValue: bondsFilter.spreadRange.range.min,
    maxValue: bondsFilter.spreadRange.range.max,
  },
  speedRange: {
    range: bondsFilter.speedRange.range,
    minValue: bondsFilter.speedRange.range.min,
    maxValue: bondsFilter.speedRange.range.max,
  },
});

/**
 * @param {Object} filters
 * @return {Object} mappedRangesForPayload
 */
export const mapRangesForPayload = (filters) => ({
  includedCurrentFaces: prepareIncludedCurrentFaces(filters),
  includedWalFrom: filters.walRange.range.min,
  includedWalTo: filters.walRange.range.max,
  includedSpreadFrom: filters.spreadRange.range.min,
  includedSpreadTo: filters.spreadRange.range.max,
  includedSpeedFrom: filters.speedRange.range.min,
  includedSpeedTo: filters.speedRange.range.max,
});

/**
 * @param {Object} payload
 * @param {Object} bondsFilter
 * @return {Object} filtersWithRangesPayload
 */
export const mapRangesPayloadToFilters = (payload, bondsFilter = {}) => {
  const rangesForFilters = mapRangesForFilters(bondsFilter);
  return {
    currentFaceRange: {
      range: {
        min: (!isCurrentFacesFromPresets(payload.includedCurrentFaces) &&
              payload.includedCurrentFaces[0].includedCurrentFaceFrom) || rangesForFilters.currentFaceRange.range.min,
        max: (!isCurrentFacesFromPresets(payload.includedCurrentFaces) &&
              payload.includedCurrentFaces[0].includedCurrentFaceTo) || rangesForFilters.currentFaceRange.range.max,
      },
      minValue: rangesForFilters.currentFaceRange.range.min,
      maxValue: rangesForFilters.currentFaceRange.range.max,
    },
    walRange: {
      range: {
        min: payload.includedWalFrom || rangesForFilters.walRange.range.min,
        max: payload.includedWalTo || rangesForFilters.walRange.range.max,
      },
      minValue: rangesForFilters.walRange.range.min,
      maxValue: rangesForFilters.walRange.range.max,
    },
    spreadRange: {
      range: {
        min: payload.includedSpreadFrom || rangesForFilters.spreadRange.range.min,
        max: payload.includedSpreadTo || rangesForFilters.spreadRange.range.max,
      },
      minValue: rangesForFilters.spreadRange.range.min,
      maxValue: rangesForFilters.spreadRange.range.max,
    },
    speedRange: {
      range: {
        min: payload.includedSpeedFrom || rangesForFilters.speedRange.range.min,
        max: payload.includedSpeedTo || rangesForFilters.speedRange.range.max,
      },
      minValue: rangesForFilters.speedRange.range.min,
      maxValue: rangesForFilters.speedRange.range.max,
    },
  };
};

/**
 * @param {Object} payload
 * @param {Object} bondsFilter
 * @return {Object} filtersWithHashPayload
 */
export const mapHashPayloadToFilters = (payload, bondsFilter = {}) => {
  const isBondsFilter = !!Object.keys(bondsFilter).length;
  let filters = { ...payload };
  let includedSubsectorIds = [...filters.includedSubsectorIds];
  let currentFaceRangeIds = [];

  if (isBondsFilter) {
    if (filters.includedSectorIds && filters.includedSectorIds.length) {
      filters.includedSectorIds.forEach((sectorId) => {
        let { subsectorList } = bondsFilter.sectorList.find((sector) => sector.sectorId === sectorId);
        subsectorList.forEach((subSector) => {
          includedSubsectorIds.push(subSector.subsectorId);
        });
      });
    }

    if (filters.includedCurrentFaces?.length && isCurrentFacesFromPresets(filters.includedCurrentFaces)) {
      filters.includedCurrentFaces.forEach((includedCurrentFace) => {
        const includedCurrentFaceId = findIncludedCurrentFaceInPresets(includedCurrentFace).currentFaceRangeId;
        currentFaceRangeIds.push(includedCurrentFaceId);
      });
    }
  }

  const filtersToCleanupKeys = [
    'includedAssetClassId',
    'includedSectorIds',
    'includedCurrentFaces',
    'includedWalFrom',
    'includedWalTo',
    'includedSpreadFrom',
    'includedSpreadTo',
    'includedSpeedFrom',
    'includedSpeedTo',
  ];
  // Cleanup
  filtersToCleanupKeys.forEach((key) => delete filters[key]);

  return {
    ...filters,
    includedSubsectorIds,
    currentFaceRangeIds,
    ...mapRangesPayloadToFilters(payload, bondsFilter),
  };
};

/**
 * @param {Number} dateFrom
 * @param {Number} dateTo
 * @return {Function}
 */
export const setMinMaxDatePeriod = (dateFrom, dateTo) => (dispatch) => {
  batch(() => {
    dispatch(createAction(actionTypes.BONDS_FILTER_MIN_DATE_FROM, dateFrom));
    dispatch(createAction(actionTypes.BONDS_FILTER_MAX_DATE_TO, dateTo));
  });
};

/**
 * @param {Object} filtersPayload
 * @param {Object} filtersState
 * @param {Boolean} isHashPayload
 * @return {Function}
 */
export const initFilters = (
  filtersPayload,
  filtersState = getInitialState(),
  isHashPayload = false,
) => async (dispatch) => {
  try {
    let response = await dispatch(getBondsFilter(filtersPayload));
    const bondsFilter = response.filter;

    filtersState = Object.assign(filtersState, mapRangesForFilters(bondsFilter));

    const initialFilters = Object.assign(getInitialState(), mapRangesForFilters(bondsFilter));
    dispatch(initialFiltersChange(initialFilters));

    if (isHashPayload) {
      const filters = mapHashPayloadToFilters(filtersPayload, bondsFilter);

      batch(() => {
        dispatch(filtersUpdate(BONDS_STORE_NAME, filters));
        dispatch(appliedFiltersChange(filters));
      });
    } else {
      batch(() => {
        dispatch(filtersUpdate(BONDS_STORE_NAME, filtersState));
        dispatch(appliedFiltersChange(filtersState));
      });
    }

    dispatch(setMinMaxDatePeriod(bondsFilter.dateFrom, bondsFilter.dateTo));
  } catch (err) {
    Logger.warn(err);
  }
};

/**
 * @return {Function}
 */
export const resetFilters = () => async (dispatch, getState) => {
  const { location } = getState().router;
  const initialState = getInitialState();
  // Pass filters through all components
  let newFiltersPayload = dispatch(getFiltersPayload(getInitialState()));

  dispatch(payloadChange(newFiltersPayload));
  await dispatch(initFilters(newFiltersPayload, initialState));

  const newSearch = removeQueryStringParam(location, ['dateFrom', 'dateTo', 'hash']);
  const pathname = isServer() ? location.pathname : window.location.pathname;
  dispatch(push(getFormatUrl(`${ pathname }?${ newSearch }`)));
};

/**
 * @return {Function}
 */
export const resetBondsFilter = () => createAction(actionTypes.RESET_BONDS_FILTER);
