import { connect } from 'react-redux';
import {
  changeDeleteBondModalVisible,
  setSelectedBondIdAndName,
} from '@/condor/application/actions/finsight/import/bond/list/actions';
import InventoryRow from './InventoryRow';

const mapDispatchToProps = {
  changeDeleteBondModalVisible,
  setSelectedBondIdAndName,
};

export default connect(
  null,
  mapDispatchToProps,
)(InventoryRow);
