import { connect, batch } from 'react-redux';
import validationSelectors from '@/condor/application/actions/finsight/import/bond/validation/selectors';
import { getCellValueByType } from './cellCallbacks';
import ValidationTable, { columns } from '@/condor/ui/components/Finsight/Import/modules/Validation/ValidationTable';
import {
  reimportFile,
  changeCompanyCreateModalVisibleState,
  changeCompanySelectModalVisibleState,
  changeActiveValidateCell,
  initCompanyCreateModalCompanyData,
  changeCompanySelectModalCollection,
  onSelectCompany,
} from '@/condor/application/actions/finsight/import/bond/validation/actions';
import { reset as resetAll } from '@/condor/application/actions/finsight/import/bond/upload/actions';
import { getOptionMapper } from '@/Framework/dataHelpers/selectOptionMappers';
import * as correctionTypes from '@/condor/ui/components/Finsight/Import/modules/Validation/correctionTypes';

const createOption = getOptionMapper({
  labelRenderer: (item) => `${ item.name } ${ item.issuerTicker ? `[${ item.issuerTicker }]` : '' }`,
});
const createOptionForLead = getOptionMapper({
  labelRenderer: (item) => `${ item.name } ${ item.abbreviation
    ? `(${ item.abbreviation })`
    : '' } ${ item.issuerTicker
    ? `[${ item.issuerTicker }]`
    : '' }`,
});

const mapStateToProps = (state) => {
  const body = validationSelectors.getTableBody(state);
  const activeValidateCell = validationSelectors.getActiveValidateCell(state);
  const header = validationSelectors.getTableHeader(state);
  const isLeadSelect = activeValidateCell && activeValidateCell.correctionType === correctionTypes.companyLead;
  return {
    columns: columns(
      header,
      body,
      validationSelectors.getTableBodyExistsColumn(state),
      getCellValueByType,
    ),
    rows: Object.keys(body).map((rowIndex) => ({ rowIndex })),
    canReimportFile: validationSelectors.isCanReimportFile(state) && validationSelectors.isUiCorrectionOnly(state),
    companySelectModalVisible: validationSelectors.isCompanySelectModalVisible(state),
    companyCreateModalVisible: validationSelectors.isCompanyCreateModalVisible(state),
    companySelectModalOptions: validationSelectors.getCompanySelectModalCollection(state).map(
      isLeadSelect ? createOptionForLead : createOption,
    ),
    companyCreateModalItem: validationSelectors.getCompanyCreateModalCompany(state),
    activeCellCorrectionType: activeValidateCell ? activeValidateCell.correctionType : null,
    activeColumnName: activeValidateCell
      ? header[activeValidateCell.position.columnIndex].name
      : null,
    activeCellValue: activeValidateCell
      ? `${ activeValidateCell.value } ${
        activeValidateCell?.data?.issuerTicker
          ? `[${ activeValidateCell.data.issuerTicker }]`
          : ''
      }`
      : null,
  };
};

const mapDispatchToProps = (dispatch) => (
  {
    onSelect: (company) => dispatch(onSelectCompany(company)),
    onCreateModalOpen: () => dispatch(changeCompanyCreateModalVisibleState(true)),
    onCreateModalClose: () => dispatch(changeCompanyCreateModalVisibleState(false)),
    onSelectModalClose: () => batch(() => {
      dispatch(changeCompanySelectModalVisibleState(false));
      dispatch(changeActiveValidateCell(null));
      dispatch(initCompanyCreateModalCompanyData({}));
      dispatch(changeCompanySelectModalCollection([]));
    }),
    reimportFile: () => dispatch(reimportFile()),
    resetAll: () => dispatch(resetAll()),
  }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ValidationTable);
