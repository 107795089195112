import { connect } from 'react-redux';
import { withRouter } from 'next/router';
import {
  resetDealsImportCollection,
  getDealsImportByFilter,
  sort,
  paginate,
} from '@/condor/application/actions/finsight/import/deal/auditTrail/actions';

import AuditTrail from './AuditTrail';
import selectors from '@/condor/application/actions/finsight/import/deal/auditTrail/selectors';

const mapStateToProps = (state) => ({
  isFetching: selectors.isFetching(state),
  data: selectors.getCollectionData(state),
  sortBy: selectors.getSortBy(state),
  sortOrder: selectors.getSortOrder(state),
});

const mapDispatchToProps = (dispatch) => ({
  init: () => dispatch(getDealsImportByFilter()),
  reset: () => dispatch(resetDealsImportCollection()),
  onSortChange: (sortBy, sortOrder) => {
    dispatch(sort({ sortBy, sortOrder }));
    dispatch(paginate(1));
    dispatch(getDealsImportByFilter());
  },
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuditTrail));
