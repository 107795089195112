export const getDealRoadshows = (state) => state.dealRoadshows;

export const getRoadshow = (state) => getDealRoadshows(state).roadshow;

export const getDealSettings = (state) => getRoadshow(state).dealSettings;

export const getRoadShowMapping = (state) => getRoadshow(state).roadshowMapping;

export const getDealSlides = (state) => getRoadshow(state).dealSlides;

export const isDealSettingsRefreshModalVisible = (state) => getDealSettings(state).isRefreshModalVisible;

export const isRoadshowMappingRefreshModalVisible = (state) => getRoadShowMapping(state).isRefreshModalVisible;

export const isDealSlidesRefreshModalVisible = (state) => getDealSlides(state).needToRefreshModal.isRefreshModalVisible;
