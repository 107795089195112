import { connect } from 'react-redux';
import CondorAsyncSelect from '@/ui/shared/components/Form/Select/legacy/CondorAsyncSelect/CondorAsyncSelect';
import { fetchExistingDealsOptions } from '@/condor/application/actions/finsight/filings/item/actions';
import selectors from '@/condor/application/actions/finsight/filings/item/selectors';

const mapStateToProps = (state) => (
  {
    options: selectors.getExistingDealsOptions(state),
  }
);

const mapDispatchToProps = {
  fetchOptions: fetchExistingDealsOptions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CondorAsyncSelect);
