import React, { memo, useEffect, useCallback } from 'react';
import cn from 'classnames';

import SubscribersList from './SubscribersList';
import AddSubscribersModal from './AddSubscribersModal';

import PageWrapper from '@/condor/ui/common/Layout/PageWrapper';
import { Icon, IconType } from '@dealroadshow/uikit';
import Button, { variantTypes } from '@/ui/shared/components/Button';

import spacesStyles from '@/ui/shared/styles/helpers/spaces.scss';
import btnStyles from '@/ui/shared/components/Button/button.scss';
import styles from './subscribers.scss';

interface IProps {
  getSubscribersList: () => void,
  setIsSubscribeModalVisible: (visible: boolean) => void,
  reset: () => void,
  isSubscribeModalVisible: boolean,
}

const Subscribers = ({
  reset,
  getSubscribersList,
  setIsSubscribeModalVisible,
  isSubscribeModalVisible,
}: IProps) => {
  useEffect(() => {
    getSubscribersList();
    return reset;
  }, []);

  const openSubscribeModal = useCallback(() => {
    setIsSubscribeModalVisible(true);
  }, []);
  return (
    <PageWrapper
      label="Subscribers"
      className={ styles.subscribers }
      headerComponent={ (
        <>
          <Button
            variant={ variantTypes.action }
            onClick={ openSubscribeModal }
            className={ cn(
              styles.addSubscribersBtn,
              btnStyles.action,
              btnStyles.btnIcon,
              spacesStyles.plxl,
              spacesStyles.prxl,
            ) }
            dataTest="subscribersOpenModalButton"
          >
            <span>Add subscribers</span>
            <Icon
              type={ IconType.plusBold }
              className={ spacesStyles.mll }
            />
          </Button>
        </>
      ) }
    >
      <SubscribersList />
      { isSubscribeModalVisible && (
        <AddSubscribersModal isVisible={ isSubscribeModalVisible } />
      ) }
    </PageWrapper>
  );
};

export default memo(Subscribers);
