import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  setDealFilesTableFilterSearch,
  setDealFilesTableFilterPagination,
  setDealFilesTableFilterPerPage,
  setDealFilesTableFilterRadio,
  setDealFilesTableSort,
  getDealFiles,
  downloadFile,
  resetDealFiles,
} from './actions';
import DealFiles from './DealFiles';

function mapStateToProps(state) {
  return {
    tableFilter: state.dealRoadshows.roadshow.dealFiles.tableFilter,
    dealFiles: state.dealRoadshows.roadshow.dealFiles.all.list,
    totalCount: state.dealRoadshows.roadshow.dealFiles.all.totalCount,
    isFetching: state.dealRoadshows.roadshow.dealFiles.all.isFetching,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({
      setDealFilesTableFilterSearch,
      setDealFilesTableFilterPagination,
      setDealFilesTableFilterPerPage,
      setDealFilesTableFilterRadio,
      setDealFilesTableSort,
      getDealFiles,
      downloadFile,
      resetDealFiles,
    }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DealFiles);
