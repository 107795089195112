import debounce from 'lodash/debounce';
import { connect, batch } from 'react-redux';
import {
  getApplicationBlacklistByFilter,
  paginate,
  setApplicationBlacklistSearchQuery,
} from '@/condor/application/actions/blacklists/application/actions';
import selectors from '@/condor/application/actions/blacklists/application/selectors';
import SearchInput from '@/ui/shared/components/Form/Input/SearchInput/SearchInput';

const mapStateToProps = (state) => (
  {
    value: selectors.getSearchQuery(state),
  }
);

const mapDispatchToProps = (dispatch) => {
  const getListDebounced = debounce(() => dispatch(getApplicationBlacklistByFilter()), 500);

  return {
    onChange: (e) => {
      batch(() => {
        dispatch(setApplicationBlacklistSearchQuery(e.target.value));
        dispatch(paginate(1));
      });
      getListDebounced();
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchInput);
