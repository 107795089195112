import { History } from '@/Framework/DI/Providers/History';
import createAsyncRequest from '@/Framework/State/Redux/createAsyncRequest';
import createAction from '@/Framework/State/Redux/createAction';

import { getItemActions } from '@/Framework/State/Redux/itemFactory';
import FilingRepository from '@/finsight/infrastructure/repository/FilingRepository';
import HeaderRepository from '@/finsight/infrastructure/repository/HeaderRepository';

import Filing from '@/finsight/domain/Filing';
import actionTypes from './actionTypes';
import DealRepository from '@/finsight/infrastructure/repository/DealRepository';
import {
  getCompanyFieldOptionMapperByFieldName,
  prepareGetDealByIdPayload,
} from '@/condor/application/actions/finsight/deal/helpers';
import { getFilingsUrl } from '@/condor/ui/components/Finsight/Filings/helpers';
import selectors from '@/condor/application/actions/finsight/filings/item/selectors';

export const {
  reset: resetCondorFilingItem,
  getItem: getCondorFilingItem,
} = getItemActions(
  actionTypes,
  {
    get: {
      method: (payload, dispatch, getState) => {
        const filingRepository = getState().container.get(FilingRepository);
        return filingRepository.getFilingById(payload);
      },
    },
  },
);

/**
 * @param {String} dealId
 */
export const mapFilingToDeal = (dealId) => (dispatch, getState) => {
  const filingId = selectors.getFilingId(getState());
  const filingType = Filing.getFilingType(selectors.getFilingType(getState()));

  const filingRepository = getState().container.get(FilingRepository);

  dispatch(createAsyncRequest(
    {
      payload: {
        filingId,
        dealId,
      },
      method: filingRepository.mapFilingToDeal,
      types: [
        actionTypes.MAP_FILING_TO_DEAL,
        actionTypes.MAP_FILING_TO_DEAL_SUCCESS,
        actionTypes.MAP_FILING_TO_DEAL_ERROR,
      ],
      callbacks: {
        onSuccess: ({ dispatch }) => dispatch(openFilingsPage(filingType)),
      },
    },
  ));
};

export const unmapFilingFromDeal = () => (dispatch, getState) => {
  const filingId = selectors.getFilingId(getState());
  const filingType = Filing.getFilingType(selectors.getFilingType(getState()));

  const filingRepository = getState().container.get(FilingRepository);

  dispatch(createAsyncRequest(
    {
      payload: {
        filingId,
        dealId: null,
      },
      method: filingRepository.mapFilingToDeal,
      types: [
        actionTypes.UNMAP_FILING_FROM_DEAL,
        actionTypes.UNMAP_FILING_FROM_DEAL_SUCCESS,
        actionTypes.UNMAP_FILING_FROM_DEAL_ERROR,
      ],
      callbacks: {
        onSuccess: ({ dispatch }) => dispatch(openFilingsPage(filingType)),
      },
    },
  ));
};

export const resetExistingDealForm = () => createAction(actionTypes.RESET_CONDOR_EXISTING_DEAL_FORM);

/**
 * @param {Object} payload
 */
export const getExistingDeal = (payload) => (dispatch, getState) => {
  const dealRepository = getState().container.get(DealRepository);

  return dispatch(createAsyncRequest(
    {
      payload,
      method: (payload) => dealRepository.getDealsForCondorAdmin(prepareGetDealByIdPayload(payload)),
      types: [
        actionTypes.GET_CONDOR_EXISTING_DEAL,
        actionTypes.GET_CONDOR_EXISTING_DEAL_SUCCESS,
        actionTypes.GET_CONDOR_EXISTING_DEAL_ERROR,
      ],
      callbacks: {
        responseHandler: (response) => response.collection[0],
      },
    },
  ));
};

/**
 * @param {String} payload
 * @param {Function} callback
 */
export const fetchExistingDealsOptions = (payload, callback) => (dispatch, getState) => {
  const headerRepository = getState().container.get(HeaderRepository);

  return dispatch(createAsyncRequest(
    {
      payload,
      method: headerRepository.searchDeals,
      types: [
        actionTypes.GET_CONDOR_EXISTING_DEAL_OPTIONS,
        actionTypes.GET_CONDOR_EXISTING_DEAL_OPTIONS_SUCCESS,
        actionTypes.GET_CONDOR_EXISTING_DEAL_OPTIONS_ERROR,
      ],
      callbacks: {
        onSuccess: ({ response }) => {
          if (callback) {
            callback(response.collection.map(getCompanyFieldOptionMapperByFieldName('existingDealsOptions')));
          }
        },
      },
    },
  ));
};

/**
 * @param {String} filingType
 */
export const openFilingsPage = (filingType) => (dispatch, getState) => {
  const history = getState().container.get(History);

  history.push(getFilingsUrl(filingType));
};
