import { connect } from 'react-redux';
import IndustrySubindustryField from './IndustrySubindustryField';
import selectors from '@/condor/application/actions/finsight/industry/collection/selectors';

const mapStateToProps = (state) => (
  {
    industries: selectors.getCollectionData(state),
  }
);

export default connect(
  mapStateToProps,
)(IndustrySubindustryField);
