import React from 'react';
import { Field } from 'redux-form';
import cn from 'classnames';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { TRANCHES_VISIBLE } from '@/condor/ui/components/Finsight/DealProfile/constants';

import withTrancheCell from '../../helpers/withTrancheCell';
import { useTranchesContext } from '../TranchesContext';
import { DEAL_PROFILE_FIELDS, TRANCHE_GROUP_IDS } from '../../constants';

import { Textarea } from '@/ui/shared/components/Form/Input';
import ReduxFormSelect from '@/ui/shared/components/Form/Select/legacy/ReduxFormSelect';
import CondorDatePicker from '@/condor/ui/common/CondorDatePicker';

import DeleteTrancheButton from './DeleteTrancheButton';
import RatingAgenciesFields from './RatingAgenciesFields';
import { CheckboxInput, Input, Icon, IconType } from '@dealroadshow/uikit';

import styles from './tranche.scss';
import dealStyles from '../../dealProfile.scss';
import alignStyles from '@/ui/shared/styles/helpers/align.scss';

const TextInput = withTrancheCell(Input);
const TrancheTextarea = withTrancheCell(Textarea);
const Select = withTrancheCell(ReduxFormSelect);
const DatePicker = withTrancheCell(CondorDatePicker);
const Checkbox = withTrancheCell(CheckboxInput);

interface IProps {
  trancheIndex: number,
  id: string,
  field: string,
  options: any,
  isSectorCmbs: boolean,
  isProductAbs: boolean,
  isExcluded: boolean,
}

const Tranche = ({
  trancheIndex,
  id,
  field,
  options,
  isSectorCmbs,
  isProductAbs,
  isExcluded,
 }: IProps) => {
  const {
    isStickyHeaderVisible,
    tranchesViewOffset,
    collapsedGroupIds,
  } = useTranchesContext();
  const {
    active,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = active && {
    transform: CSS.Translate.toString(
      isDragging && transform
        ? {
            ...transform,
            y: 0, // set to 0 to compensate for negative margin in styles
          }
        : transform,
    ),
    transition,
  };

  const separatorStyle = trancheIndex < 4 ? styles.cellSeparator : styles.cellSeparatorLast;

  return (
    <div
      ref={ setNodeRef }
      style={ style }
      className={ cn(styles.trancheContainer, {
        [styles.isHoverable]: !isDragging,
        [styles.hoveredTranche]: isExcluded,
        [styles.isDragging]: isDragging,
        [styles.isVisible]: tranchesViewOffset <= trancheIndex && trancheIndex < tranchesViewOffset + TRANCHES_VISIBLE,
      }) }
    >
      <div className={ cn(styles.trancheHeader, { [styles.withStickyHeader]: isStickyHeaderVisible }) }>
        <span
          className={ styles.trancheDragIcon }
          { ...attributes }
          { ...listeners }
          data-test={ `trancheDragIcon${ trancheIndex }` }
        >
          <Icon type={ IconType.draggable } />
        </span>
        <div className={ styles.trancheLabel }>{ `Tranche ${ trancheIndex + 1 }` }</div>
        <div className={ styles.deleteTrancheBtnContainer }>
          <DeleteTrancheButton
            index={ trancheIndex }
            isExcluded={ isExcluded }
          />
        </div>
      </div>
      <div className={ styles.trancheBody }>
        <div
          className={ cn(styles.trancheCellGroup, {
            [styles.trancheCellGroupCollapsed]: collapsedGroupIds.includes(TRANCHE_GROUP_IDS.CLASS),
          }) }
        >
          <Field
            name={ `${ field }.${ DEAL_PROFILE_FIELDS.TRANCHE.CLASS.name }` }
            component={ TextInput }
            disabled={ isExcluded }
            size="small"
            dataTest={ `tranche${ trancheIndex }Class` }
          />
          <Field
            name={ `${ field }.${ DEAL_PROFILE_FIELDS.TRANCHE.CURRENCY.name }` }
            options={ options.currencies }
            placeholder="Select"
            component={ Select }
            isSlim
            className={ dealStyles.requiredField }
            disabled={ isExcluded }
            dataTest={ `tranche${ trancheIndex }Currency` }
          />
          <Field
            name={ `${ field }.${ DEAL_PROFILE_FIELDS.TRANCHE.SIZE.name }` }
            component={ TextInput }
            className={ dealStyles.requiredField }
            disabled={ isExcluded }
            size="small"
            dataTest={ `tranche${ trancheIndex }Size` }
          />
          { isProductAbs && (
            <>
              <Field
                name={ `${ field }.${ DEAL_PROFILE_FIELDS.TRANCHE.WAL_YRS.name }` }
                component={ TextInput }
                disabled={ isExcluded }
                size="small"
                dataTest={ `tranche${ trancheIndex }WalYrs` }
              />
              <Field
                name={ `${ field }.${ DEAL_PROFILE_FIELDS.TRANCHE.WAL_EXT.name }` }
                component={ TextInput }
                disabled={ isExcluded }
                size="small"
                dataTest={ `tranche${ trancheIndex }WalExt` }
              />
            </>
          ) }
          <Field
            name={ `${ field }.${ DEAL_PROFILE_FIELDS.TRANCHE.RANKING.name }` }
            options={ options.rankings }
            component={ Select }
            isSlim
            disabled={ isExcluded }
            dataTest={ `tranche${ trancheIndex }Ranking` }
          />
          <Field
            name={ `${ field }.${ DEAL_PROFILE_FIELDS.TRANCHE.TENOR_YRS.name }` }
            component={ TextInput }
            disabled={ isExcluded }
            size="small"
            dataTest={ `tranche${ trancheIndex }TenorYrs` }
          />
          <div className={ separatorStyle } />
        </div>
        <div
          className={ cn(styles.trancheCellGroup, {
            [styles.trancheCellGroupCollapsed]: collapsedGroupIds.includes(TRANCHE_GROUP_IDS.RATING_AGENCIES),
          }) }
        >
          <RatingAgenciesFields
            field={ field }
            isExcluded={ isExcluded }
            trancheIndex={ trancheIndex }
          />
          <div className={ separatorStyle } />
        </div>
        <div
          className={ cn(styles.trancheCellGroup, {
            [styles.trancheCellGroupCollapsed]: collapsedGroupIds.includes(TRANCHE_GROUP_IDS.CREDIT_ENHANCMENT),
          }) }
        >
          { isProductAbs && (
            <>
              <Field
                name={ `${ field }.${ DEAL_PROFILE_FIELDS.TRANCHE.CREDIT_ENHANCEMENT.name }` }
                component={ TextInput }
                disabled={ isExcluded }
                size="small"
                dataTest={ `tranche${ trancheIndex }CreditEnhancement` }
              />
              <Field
                name={ `${ field }.${ DEAL_PROFILE_FIELDS.TRANCHE.LOAN_TO_VALUE.name }` }
                component={ TextInput }
                disabled={ isExcluded }
                size="small"
                dataTest={ `tranche${ trancheIndex }LoanToValue` }
              />
            </>
          ) }
          <Field
            name={ `${ field }.${ DEAL_PROFILE_FIELDS.TRANCHE.EXP_MATURITY_DATE.name }` }
            component={ DatePicker }
            disabled={ isExcluded }
            dataTest={ `tranche${ trancheIndex }ExpMaturityDate` }
          />
          <Field
            name={ `${ field }.${ DEAL_PROFILE_FIELDS.TRANCHE.MATURITY_DATE.name }` }
            component={ DatePicker }
            disabled={ isExcluded }
            dataTest={ `tranche${ trancheIndex }MaturityDate` }
          />
          <Field
            name={ `${ field }.${ DEAL_PROFILE_FIELDS.TRANCHE.FIRST_PAY_DATE.name }` }
            component={ DatePicker }
            disabled={ isExcluded }
            dataTest={ `tranche${ trancheIndex }FirstPayDate` }
          />
          <Field
            name={ `${ field }.${ DEAL_PROFILE_FIELDS.TRANCHE.REDEMPTION.name }` }
            component={ DatePicker }
            disabled={ isExcluded }
            dataTest={ `tranche${ trancheIndex }Redemption` }
          />
          <Field
            name={ `${ field }.${ DEAL_PROFILE_FIELDS.TRANCHE.COUPON_TYPE.name }` }
            options={ options.couponTypes }
            component={ Select }
            isSlim
            disabled={ isExcluded }
            dataTest={ `tranche${ trancheIndex }CouponType` }
          />
          <Field
            name={ `${ field }.${ DEAL_PROFILE_FIELDS.TRANCHE.BENCHMARK.name }` }
            options={ options.benchmarks }
            component={ Select }
            isSlim
            disabled={ isExcluded }
            dataTest={ `tranche${ trancheIndex }Benchmark` }
          />
          <Field
            name={ `${ field }.${ DEAL_PROFILE_FIELDS.TRANCHE.GUIDANCE_IPT.name }` }
            component={ TextInput }
            disabled={ isExcluded }
            size="small"
            dataTest={ `tranche${ trancheIndex }GuidanceIpt` }
          />
          <Field
            name={ `${ field }.${ DEAL_PROFILE_FIELDS.TRANCHE.GUIDANCE.name }` }
            component={ TextInput }
            disabled={ isExcluded }
            size="small"
            dataTest={ `tranche${ trancheIndex }Guidance` }
          />
          <Field
            name={ `${ field }.${ DEAL_PROFILE_FIELDS.TRANCHE.SPREAD.name }` }
            component={ TextInput }
            disabled={ isExcluded }
            size="small"
            dataTest={ `tranche${ trancheIndex }Spread` }
          />
          <Field
            name={ `${ field }.${ DEAL_PROFILE_FIELDS.TRANCHE.COUPON.name }` }
            component={ TextInput }
            disabled={ isExcluded }
            size="small"
            dataTest={ `tranche${ trancheIndex }Coupon` }
          />
          <Field
            name={ `${ field }.${ DEAL_PROFILE_FIELDS.TRANCHE.YIELD.name }` }
            component={ TextInput }
            disabled={ isExcluded }
            size="small"
            dataTest={ `tranche${ trancheIndex }Yield` }
          />
          <Field
            name={ `${ field }.${ DEAL_PROFILE_FIELDS.TRANCHE.ISSUE_PRICE.name }` }
            component={ TextInput }
            disabled={ isExcluded }
            size="small"
            dataTest={ `tranche${ trancheIndex }IssuePrice` }
          />
          <Field
            name={ `${ field }.${ DEAL_PROFILE_FIELDS.TRANCHE.NEW_ISSUE_CONCESSION.name }` }
            component={ TextInput }
            disabled={ isExcluded }
            size="small"
            dataTest={ `tranche${ trancheIndex }NewIssueConcession` }
          />
          <Field
            name={ `${ field }.${ DEAL_PROFILE_FIELDS.TRANCHE.BOOK_SIZE.name }` }
            component={ TextInput }
            disabled={ isExcluded }
            size="small"
            dataTest={ `tranche${ trancheIndex }BookSize` }
          />
          <div className={ separatorStyle } />
        </div>
        <div
          className={ cn(styles.trancheCellGroup, {
            [styles.trancheCellGroupCollapsed]: collapsedGroupIds.includes(TRANCHE_GROUP_IDS.CUSIP),
          }) }
        >
          <Field
            name={ `${ field }.${ DEAL_PROFILE_FIELDS.TRANCHE.CUSIPS.name }` }
            component={ TextInput }
            disabled={ isExcluded }
            size="small"
            dataTest={ `tranche${ trancheIndex }Cusips` }
          />
          <Field
            name={ `${ field }.${ DEAL_PROFILE_FIELDS.TRANCHE.CUSIPS_REGS.name }` }
            component={ TextInput }
            disabled={ isExcluded }
            size="small"
            dataTest={ `tranche${ trancheIndex }CusipsRegs` }
          />
          <Field
            name={ `${ field }.${ DEAL_PROFILE_FIELDS.TRANCHE.ISINS.name }` }
            component={ TextInput }
            disabled={ isExcluded }
            size="small"
            dataTest={ `tranche${ trancheIndex }Isins` }
          />
          <Field
            name={ `${ field }.${ DEAL_PROFILE_FIELDS.TRANCHE.REGISTRATION.name }` }
            options={ options.registrations }
            component={ Select }
            isSlim
            disabled={ isExcluded }
            dataTest={ `tranche${ trancheIndex }Registration` }
          />
          { isProductAbs && (
            <Field
              name={ `${ field }.${ DEAL_PROFILE_FIELDS.TRANCHE.ERISA_ELIGABLE.name }` }
              dataTest={ `${ field }${ DEAL_PROFILE_FIELDS.TRANCHE.ERISA_ELIGABLE.name }` }
              type="checkbox"
              component={ Checkbox }
              cellClassName={ alignStyles.alignCenter }
              disabled={ isExcluded }
            />
          ) }
          <Field
            name={ `${ field }.${ DEAL_PROFILE_FIELDS.TRANCHE.RETAINED.name }` }
            dataTest={ `${ field }${ DEAL_PROFILE_FIELDS.TRANCHE.RETAINED.name }` }
            type="checkbox"
            component={ Checkbox }
            cellClassName={ alignStyles.alignCenter }
            disabled={ isExcluded }
          />
          { isSectorCmbs && (
            <Field
              name={ `${ field }.${ DEAL_PROFILE_FIELDS.TRANCHE.INTEREST_ONLY.name }` }
              dataTest={ `${ field }${ DEAL_PROFILE_FIELDS.TRANCHE.INTEREST_ONLY.name }` }
              type="checkbox"
              component={ Checkbox }
              cellClassName={ alignStyles.alignCenter }
              disabled={ isExcluded }
            />
          ) }
          <Field
            name={ `${ field }.${ DEAL_PROFILE_FIELDS.TRANCHE.UPSIZED.name }` }
            dataTest={ `${ field }${ DEAL_PROFILE_FIELDS.TRANCHE.UPSIZED.name }` }
            type="checkbox"
            component={ Checkbox }
            cellClassName={ alignStyles.alignCenter }
            disabled={ isExcluded }
          />
          <Field
            name={ `${ field }.${ DEAL_PROFILE_FIELDS.TRANCHE.RE_OPEN.name }` }
            dataTest={ `${ field }${ DEAL_PROFILE_FIELDS.TRANCHE.RE_OPEN.name }` }
            type="checkbox"
            component={ Checkbox }
            cellClassName={ alignStyles.alignCenter }
            disabled={ isExcluded }
          />
          <div className={ separatorStyle } />
        </div>
        <div
          className={ cn(styles.trancheCellGroup, {
            [styles.trancheCellGroupCollapsed]: collapsedGroupIds.includes(TRANCHE_GROUP_IDS.COMMENTS),
          }) }
        >
          <Field
            name={ `${ field }.${ DEAL_PROFILE_FIELDS.TRANCHE.COMMENTS.name }` }
            component={ TrancheTextarea }
            disabled={ isExcluded }
            size="small"
            textareaClassName={ styles.commentsCell }
            dataTest={ `tranche${ trancheIndex }Comments` }
          />
        </div>
      </div>
    </div>
  );
};

export default Tranche;
