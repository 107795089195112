import { createContext, useContext, ReactNode } from 'react';
import { useProfileForm, IUseProfileFormProps } from './useProfileForm';

type ProfileFormContextType = ReturnType<typeof useProfileForm>;

export const ProfileFormContext = createContext<ProfileFormContextType>(null);

export const useProfileFormContext = () => {
  const context = useContext(ProfileFormContext);

  if (!context) {
    throw new Error('useProfileFormContext must be used within the ProfileFormContext');
  }

  return context;
};

interface IProps extends IUseProfileFormProps {
  children: ReactNode,
}

const ProfileFormContextProvider = ({ children, ...props }: IProps) => (
  <ProfileFormContext.Provider value={ useProfileForm(props) }>{ children }</ProfileFormContext.Provider>
);

export default ProfileFormContextProvider;
