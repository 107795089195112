import { injectable, inject } from 'inversify';
import { withCache } from '@/Framework/withCache';
import Request from '@/Framework/api/Rpc/Request';
import RpcDispatcher from '@/openMarket/application/DI/Rpc/HttpDispatcher';
import { ITransactionType } from '@/openMarket/domain/vo/ITransactionType';

@injectable()
class TransactionTypeRepository {
  constructor(@inject(RpcDispatcher) private readonly rpc: typeof RpcDispatcher) {}

  @withCache
  async getTransactionTypesList(): Promise<ITransactionType[]> {
    const request = new Request('open_market.market.transaction_types.list', {
      tenant: 'dealroadshow',
    });
    const response = await this.rpc.call(request);

    return response.getResult().payload;
  }
}

export default TransactionTypeRepository;
