import { Dependencies } from 'constitute';
import Request from '@/Framework/api/Rpc/Request';
import RpcDispatcher from '@/dmPortal/application/DI/Rpc/HttpDispatcher';
import FileDownloadLinkGenerator from '@/dmPortal/application/DI/FileDownloadLinkGenerator/FileDownloadLinkGenerator';

@Dependencies(RpcDispatcher, FileDownloadLinkGenerator)
class AuditTrailRepository {
  constructor(private rpc: typeof RpcDispatcher, private fileDownloadLinkGenerator: typeof FileDownloadLinkGenerator) {}

  /**
   * @param {Object} data
   * @return {Promise.<*>}
   */
  list = async (data: any = {}) => {
    const REQUEST_METHOD = data.isAdmin
      ? 'admin.roadshow.audit_trail.get_audit_trail_log'
      : 'roadshow.audit_trail.get_deal_manager_audit_trail_log';
    const request = new Request(REQUEST_METHOD, data);
    const response = await this.rpc.request(request);

    return response.getResult().payload;
  };

  /**
   * @return {Promise}
   * @param {String} roadshowId
   * @param {Boolean} isAdmin
   */
  getExportToExcelLink = async (roadshowId, isAdmin = false) => {
    const entityId = this.fileDownloadLinkGenerator.generateUniqueId();

    const REQUEST_METHOD = isAdmin
      ? 'admin.roadshow.audit_trail.generate_report'
      : 'roadshow.audit_trail.generate_report';

    const request = new Request(REQUEST_METHOD, { entityId, roadshowId });

    return this.fileDownloadLinkGenerator.getFileLink(request, entityId);
  };

  /**
   * @param {String} id
   * @param {String} accessType
   * @return {Promise.<*>}
   */
  setAccessType = async (id, accessType) => {
    const request = new Request('admin.roadshow.audit_trail.set_action_access_type', { accessType, id });
    const response = await this.rpc.request(request);

    return response.getResult().payload;
  };
}

export default AuditTrailRepository;
