import React, { useCallback, useState, useMemo } from 'react';
import { SelectComponentsConfig } from 'react-select';
import debounce from 'lodash/debounce';
import noop from 'lodash/noop';
import ReduxFormSelect from '@/ui/shared/components/Form/Select/legacy/ReduxFormSelect';

interface IProps {
  options?: any[],
  filteredOptions?: any[],
  components?: SelectComponentsConfig<any, boolean, any>,
  fetchOptions: Function,
  debounceWait?: number,
  input: { [key: string]: any },
  onBlur?: () => void,
  dataTest?: string,
}

/**
 This Component is a local alternative to shared AsyncSelect (ugly and complicated one).
 @deprecated This component is DEPRECATED. Use ui/shared/components/Form/Fields/Select
 */
const CondorAsyncSelect = (
  {
    options = [],
    filteredOptions = [],
    fetchOptions,
    debounceWait = 500,
    onBlur = noop,
    input,
    components = {},
    dataTest = 'condorAsyncSelectReduxFormSelect',
    ...otherProps
  }: IProps,
) => {
  const [isFilteringOptions, setIsFilteringOptions] = useState(false);
  const [inputValue, setInputValue] = useState(null);

  const inputWithOnBlur = useMemo(
    () => (
      {
        ...input,
        onBlur: () => {
          input.onBlur();
          onBlur();
        },
      }
    ),
    [input, onBlur],
  );

  const handleChangeInput = async (value) => {
    const inputValue = value.replace(/\W/g, '');
    setInputValue(inputValue);
    return inputValue;
  };

  const loadOptions = useCallback(debounce(
    async (query, callback) => {
      if (query && query.length) {
        setIsFilteringOptions(true);
        await fetchOptions(query, callback);
        setIsFilteringOptions(false);
      }
    },
    debounceWait,
  ),
  [fetchOptions, inputValue]);

  return (
    <ReduxFormSelect
      { ...otherProps }
      isAsync
      input={ inputWithOnBlur }
      components={ {
        DropdownIndicator: () => null,
        ...components,
      } }
      noOptionsMessage={ () => 'Type to search...' }
      options={ filteredOptions.length ? filteredOptions : options }
      isLoading={ isFilteringOptions }
      onInputChange={ handleChangeInput }
      // @ts-ignore
      loadOptions={ loadOptions }
      dataTest={ dataTest }
    />
  );
};

export default CondorAsyncSelect;
