import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleAccountsTableVerifiedFilter } from '@/condor/application/actions/accounts/filters/actions';
import CheckboxFilters from './CheckboxFilters';
import selectors from '@/condor/application/actions/accounts/filters/selectors';

const mapStateToProps = (state) => (
  {
    filters: selectors.getFilters(state),
  }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    setIsVerified: () => toggleAccountsTableVerifiedFilter('isVerified'),
    setIsUnverified: () => toggleAccountsTableVerifiedFilter('isUnverified'),
    setIsDeleted: () => toggleAccountsTableVerifiedFilter('isDeleted'),
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CheckboxFilters);
