import { Method } from 'constitute';
import Container from '@/Framework/DI/Container';
// eslint-disable-next-line import/no-extraneous-dependencies
import { FinsightSocketProcessingAdapter } from '@dealroadshow/file-uploader';
import SocketPush from '@/finsight/application/DI/Socket/Client';

export default new Method((container) => {
  const socketPush = container.get(SocketPush);
  return new FinsightSocketProcessingAdapter(socketPush);
}, [Container]);
