import React from 'react';
import { isDate, isValid } from 'date-fns';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import * as dateFnsDateFormats from '@/Framework/DateTime/dateFormats';
import { Tooltip } from '@dealroadshow/uikit';
import { getCellDisplayDate } from './helpers';

interface IProps {
  value: Date,
  timeZone: string,
  withoutTime?: boolean,
}

const LastLoginAt = ({ value, timeZone, withoutTime }: IProps) => {
  if (!isDate(value) || !isValid(value)) {
    return null;
  }

  const defaultDateFormat = dateFnsDateFormats.MONTH_DAY_YEAR_FORMAT;
  const fullDateFormat = dateFnsDateFormats.MONTH_DAY_YEAR_DATE_FULLTIME_FORMAT;

  const defaultDate = getCellDisplayDate(value, timeZone, withoutTime);
  const format = withoutTime ? defaultDateFormat : fullDateFormat;

  const fullDate = formatInTimeZone(value, timeZone, format);

  return (
    <Tooltip content={ fullDate }>
      { defaultDate }
    </Tooltip>
  );
};

export default LastLoginAt;
