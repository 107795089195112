import { getErrorMessage, getMessage } from '@/Framework/Message/Mapper/getMessage';
import RoadshowRepository from '@/dealroadshow/infrastructure/repository/RoadshowRepository';
import { messageCodes } from '@/Framework/Message/messages';
import { NotificationManager } from '@/ui/shared/components/Notification';
import * as actionTypes from './actionTypes';

/**
 * @param {String} search
 * @return {Object}
 */
export function search(search = '') {
  return {
    type: actionTypes.LOGIN_LIMITS_SEARCH_CHANGE,
    payload: {
      search,
    },
  };
}

/**
 * @param {Number} page
 * @return {Object}
 */
export function paginate(page) {
  return {
    type: actionTypes.LOGIN_LIMITS_PAGINATION_CHANGE,
    payload: {
      page,
    },
  };
}

/**
 * @param {Number} perPage
 * @return {Object}
 */
export function itemsPerPage(perPage) {
  return {
    type: actionTypes.LOGIN_LIMITS_ITEMS_PER_PAGE_CHANGE,
    payload: {
      perPage,
    },
  };
}

/**
 * @param {String} sortBy
 * @param {String} sortOrder
 * @return {Object}
 */
export function sort(sortBy, sortOrder) {
  return {
    type: actionTypes.LOGIN_LIMITS_SORT_CHANGE,
    payload: {
      sortBy,
      sortOrder,
    },
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function requestLoginLimitsSuccess(payload) {
  return {
    type: actionTypes.REQUEST_LOGIN_LIMITS_SUCCESS,
    payload,
  };
}

/**
 * @param {Number} payload
 * @return {Object}
 */
function requestLoginLimitsTotalCountSuccess(payload) {
  return {
    type: actionTypes.REQUEST_LOGIN_LIMITS_TOTAL_COUNT,
    payload,
  };
}

/**
 * @param {Object} errorResponse
 * @return {Object}
 */
function requestLoginLimitsError(errorResponse) {
  NotificationManager.error(getErrorMessage(errorResponse));
  return {
    type: actionTypes.REQUEST_LOGIN_LIMITS_ERROR,
    error: errorResponse,
  };
}

/**
 * @param {{
 *  roadshowId: String,
 *  sortBy: String,
 *  sortOrder: String,
 *  search: String,
 *  page: Number,
 *  perPage: Number
 * }} paramsToRequest
 */
export function getLoginLimits(paramsToRequest) {
  return async (dispatch, getState) => {
    const roadshowRepository = getState().container.get(RoadshowRepository);
    dispatch({ type: actionTypes.REQUEST_LOGIN_LIMITS });
    try {
      let roadshows = await roadshowRepository.getAdminLoginLimits(paramsToRequest);
      dispatch(requestLoginLimitsSuccess(roadshows.data));
      dispatch(requestLoginLimitsTotalCountSuccess(roadshows.totalCount));
    } catch (err) {
      dispatch(requestLoginLimitsError(err));
    }
  };
}

/**
 * @param {{
 *  roadshowId: String,
 *  userId: int,
 *  type: String,
 *  value: boolean
 * }} toggleParams
 * @param {Object} filtersPayload
 * @return {function(*)}
 */
export function resetAdminLoginLimits(toggleParams, filtersPayload) {
  return async (dispatch, getState) => {
    const roadshowRepository = getState().container.get(RoadshowRepository);
    dispatch({ type: actionTypes.REQUEST_LOGIN_LIMITS });
    try {
      await roadshowRepository.resetAdminLoginLimits(toggleParams);

      NotificationManager.success(getMessage(messageCodes.CONDOR_SAVED));
      dispatch(getLoginLimits(filtersPayload));
    } catch (err) {
      dispatch(requestLoginLimitsError(err));
    }
  };
}

/**
 * @param {{
 *  roadshowId: String,
 *  userId: int,
 *  type: String,
 *  value: boolean
 * }} toggleParams
 * @param {Object} filtersPayload
 * @return {function(*)}
 */
export function resetAdminInvestorLoginsCountByEntryCode(toggleParams, filtersPayload) {
  return async (dispatch, getState) => {
    const roadshowRepository = getState().container.get(RoadshowRepository);
    dispatch({ type: actionTypes.REQUEST_LOGIN_LIMITS });
    try {
      await roadshowRepository.resetAdminInvestorLoginsCountByEntryCode(toggleParams);

      NotificationManager.success(getMessage(messageCodes.CONDOR_SAVED));
      dispatch(getLoginLimits(filtersPayload));
    } catch (err) {
      dispatch(requestLoginLimitsError(err));
    }
  };
}

export function resetLoginLimits() {
  return async (dispatch) => {
    dispatch({ type: actionTypes.LOGIN_LIMITS_RESET });
    dispatch({ type: actionTypes.LOGIN_LIMITS_RESET });
  };
}
