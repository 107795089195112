import get from 'lodash/get';

import { getCollectionSelectors } from '@/Framework/State/Redux/collectionFactory';
import actionTypes from './actionTypes';

export default {
  ...getCollectionSelectors('condor.finsight.filings.collection', actionTypes),
  getSelectedFiling: (state) => get(state, 'condor.finsight.filings.collection.selectedFiling'),
  getIsModalFetching: (state) => get(state, 'condor.finsight.filings.collection.isModalFetching'),
  getIsStatusUpdated: (state) => get(state, 'condor.finsight.filings.collection.isStatusUpdated'),
  getDealMapping: (state) => get(state, 'condor.finsight.filings.collection.dealMapping'),
  getIsModalVisible: (state) => get(state, 'condor.finsight.filings.collection.isModalVisible'),
  getIsSendEmailModalVisible: (state) => get(state, 'condor.finsight.filings.collection.isSendEmailModalVisible'),
  getIsResendEmailModalVisible: (state) => get(state, 'condor.finsight.filings.collection.isResendEmailModalVisible'),
  getIsDisableEmailModalVisible: (state) => get(state, 'condor.finsight.filings.collection.isDisableEmailModalVisible'),
};
