import { connect } from 'react-redux';
import validationSelectors from '@/condor/application/actions/finsight/import/bond/validation/selectors';
import uploadSelectors from '@/condor/application/actions/finsight/import/bond/upload/selectors';
import BondUpload from './BondUpload';
import downloadFile from '@/Framework/api/downloadFile';
import files from '@/finsight/infrastructure/finsightWebUrl';
import { reset as resetAll } from '@/condor/application/actions/finsight/import/bond/upload/actions';

const mapStateToProps = (state) => (
  {
    validationTableVisible: validationSelectors.isValidationTableVisible(state),
    companySelectModalVisible: validationSelectors.isCompanySelectModalVisible(state),
    companyCreateModalVisible: validationSelectors.isCompanyCreateModalVisible(state),
    showAreYouSureModalIfNeeded: !!uploadSelectors.getUploadFileProcessId(state),
  }
);
const mapDispatchToProps = {
  onTemplateDownload: () => () => downloadFile(files.getBondImportTemplateUrl()),
  resetAll,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BondUpload);
