import React from 'react';
import cn from 'classnames';
import { DateTimeCell } from './CellItems/DateTimeCell';
import { ExpandCollapseCell } from './CellItems/ExpandCollapseCell';
import { ExpandedCell } from './CellItems/ExpandedCell';
import { commonCellCallback } from '@/condor/ui/common/Layout/helpers';

import alignStyles from '@/ui/shared/styles/helpers/align.scss';
import styles from './traceTable.scss';

const SimpleCellWithMobileCol = (cellProps) => (
  <div>
    <span className={ styles.mobileColName }>{ cellProps.cellTitle }</span>
    <div>{ commonCellCallback(cellProps) }</div>
  </div>
);

const columns = (archived: boolean) => [
  {
    name: 'issuerName',
    title: 'Issuer',
    sortable: true,
    className: cn(alignStyles.alignLeft, styles.cellItem, styles.issuerName),
    cellCallback: (cellProps) => <ExpandedCell { ... cellProps } />,
  },
  {
    name: 'seriesName',
    title: 'Series',
    width: 130,
    sortable: true,
    className: cn(alignStyles.alignRight, styles.cellItem),
    cellCallback: SimpleCellWithMobileCol,
  },
  {
    name: 'trancheName',
    title: 'Tranche',
    width: 115,
    sortable: true,
    className: cn(alignStyles.alignRight, styles.cellItem),
    cellCallback: SimpleCellWithMobileCol,
  },
  {
    name: 'cusip',
    title: 'CUSIP',
    width: 116,
    sortable: true,
    className: cn(alignStyles.alignRight, styles.cellItem),
    cellCallback: SimpleCellWithMobileCol,
  },
  {
    name: 'tradeStatus',
    title: 'Status',
    width: 100,
    sortable: true,
    className: cn(alignStyles.alignLeft, styles.cellItem),
    cellCallback: SimpleCellWithMobileCol,
  },
  {
    name: 'tradeDate',
    title: 'Trade date & time',
    width: 197,
    sortable: true,
    className: cn(alignStyles.alignRight, styles.dateTimeCell),
    cellCallback: (cellProps) => <DateTimeCell { ...cellProps } archived={ archived } />,
  },
  {
    name: 'expand',
    width: 56,
    className: cn(styles.cellItem, styles.expandCell),
    cellCallback: (cellProps) => <ExpandCollapseCell { ... cellProps } />,
  },
];

export default columns;
