import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { CONDOR_DRS_ROADSHOW_URL, CONDOR_DRS_ROADSHOWS_URL } from '@/condor/ui/common/constants';
import pageStyles from '@/ui/shared/styles/page.scss';
import { Tooltip, Spinner, Modal } from '@dealroadshow/uikit';
import Button, { variantTypes } from '@/ui/shared/components/Button';

interface IProps {
  children: React.ReactNode,
  checkTabState: (roadshowId: string, nextTabLink: string, prevTabLink?: string) => void,
  removeBreadcrumb: (value: string) => void,
  setBreadcrumb: (value: any) => void,
  match: { [key: string]: any },
  tabs: Array<{
    name: string,
    link: string,
    disabled?: boolean,
    disabledTooltip?: string,
  }>,
  isFetching: boolean,
  setSettingsNeedToRefreshModalVisible: (value: boolean) => void,
  setMappingNeedToRefreshModalVisible: (value: boolean) => void,
  setDealSlidesNeedToRefreshModalVisible: (value: boolean) => void,
  push: (url: string) => void,
  isRoadshowSettingsNeedToRefresh: boolean,
  isRoadshowMappingNeedToRefresh: boolean,
  isRoadshowSlidesNeedToRefresh: boolean,
}

class Roadshow extends Component<IProps> {
  constructor(props) {
    super(props);
    this.handlerOnClick = this.handlerOnClick.bind(this);
    this.getPreviousTabLink = this.getPreviousTabLink.bind(this);
    this.getTabLink = this.getTabLink.bind(this);
    this.handleModalRefresh = this.handleModalRefresh.bind(this);
  }

  // TODO: finalize redirect
  componentDidMount() {
    if (typeof this.props.match.params.roadshowId === 'undefined') {
      this.props.push(CONDOR_DRS_ROADSHOWS_URL);
    }
    let tabLink;
    if (this.getPath(this.props).length < 5) {
      tabLink = this.props.tabs[0].link;
      this.props.push(`${ CONDOR_DRS_ROADSHOW_URL }/${ this.props.match.params.roadshowId }/${ tabLink }`);
    } else {
      tabLink = this.getTabLink(this.props);
    }
    this.props.setBreadcrumb({
      name: 'Roadshows',
      url: CONDOR_DRS_ROADSHOWS_URL,
    });

    this.props.checkTabState(this.props.match.params.roadshowId, tabLink);
  }

  componentDidUpdate(prevProps) {
    let nextTabLink = this.getTabLink(this.props);
    let prevTabLink = this.getPreviousTabLink(prevProps);
    if (nextTabLink !== prevTabLink) {
      if (this.getPath(this.props).length === 5 && this.getPath(prevProps).length === 5) {
        this.props.checkTabState(
          this.props.match.params.roadshowId,
          nextTabLink,
          prevTabLink,
        );
      }
    }
  }

  componentWillUnmount() {
    let tabLink = this.getTabLink(this.props);
    this.props.removeBreadcrumb(`${ CONDOR_DRS_ROADSHOW_URL }/${ this.props.match.params.roadshowId }/${ tabLink }`);
    this.props.removeBreadcrumb(CONDOR_DRS_ROADSHOWS_URL);
  }

  getPreviousTabLink(prevProps) {
    return this.getTabLink(prevProps);
  }

  getTabLink(props) {
    return props.location.pathname.substr(props.location.pathname.lastIndexOf('/') + 1);
  }

  getPath(props) {
    return props.location.pathname.split('/');
  }

  handlerOnClick(event, route) {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    this.props.push(`${ CONDOR_DRS_ROADSHOW_URL }/${ this.props.match.params.roadshowId }/${ route }`);
  }

  handleModalRefresh() {
    const {
      isRoadshowSettingsNeedToRefresh, isRoadshowMappingNeedToRefresh, isRoadshowSlidesNeedToRefresh,
      setDealSlidesNeedToRefreshModalVisible, setSettingsNeedToRefreshModalVisible, setMappingNeedToRefreshModalVisible,
    } = this.props;
    isRoadshowSettingsNeedToRefresh && setSettingsNeedToRefreshModalVisible(false);
    isRoadshowMappingNeedToRefresh && setMappingNeedToRefreshModalVisible(false);
    isRoadshowSlidesNeedToRefresh && setDealSlidesNeedToRefreshModalVisible(false);
    window.location.reload();
  }

  /**
   * @return {ReactElement}
   */
  render() {
    const {
      isRoadshowSettingsNeedToRefresh,
      isRoadshowMappingNeedToRefresh,
      isRoadshowSlidesNeedToRefresh,
    } = this.props;
    return (
      <>
        <Spinner isVisible={ this.props.isFetching } overlay />
        <div className={ pageStyles.pageNavigation }>
          <div className={ pageStyles.pageNavigationLinks }>
            { this.props.tabs.map((tab) => {
              if (!tab.disabled) {
                return (
                  <NavLink
                    key={ tab.link }
                    activeClassName={ pageStyles.isActiveNavLink }
                    to={ `${ CONDOR_DRS_ROADSHOW_URL }/${ this.props.match.params.roadshowId }/${ tab.link }` }
                  >
                    { tab.name }
                  </NavLink>
                );
              }
              return (
                <a key={ tab.link } className={ pageStyles.isDisabledNavLink }>
                  <Tooltip
                    disabled={ tab.disabled && isEmpty(tab.disabledTooltip) }
                    content={ tab.disabledTooltip }
                  >
                    { tab.name }
                  </Tooltip>
                </a>
              );
            }) }
          </div>
        </div>
        { this.props.children }
        <Modal
          isVisible={
            isRoadshowSettingsNeedToRefresh ||
            isRoadshowMappingNeedToRefresh ||
            isRoadshowSlidesNeedToRefresh
          }
          title="Roadshow Settings Have Been updated"
          showCloseButton={ false }
          dataTest="roadshowSettingsInfoModal"
          footer={ (
            <>
              <Button
                onClick={ this.handleModalRefresh }
                variant={ variantTypes.action }
                title="Continue"
                dataTest="roadshowModalRefreshButton"
              />
            </>
          ) }
        >
          The roadshow settings have been updated since accessing this page.
          The page will immediately refresh with these updated settings after you select the button below.
        </Modal>
      </>
    );
  }
}

export default Roadshow;
