import React from 'react';
import { Checkbox, ICellProps as IDataTableCellProps } from '@dealroadshow/uikit';
import { IAccountProfileItem } from '@/condor/domain/vo/Accounts/AccountProfileItem';

interface IProps {
  toggleBulkDelete: (item: IAccountProfileItem) => void,
  bulkDeleteItems: IAccountProfileItem[],
  cellProps: IDataTableCellProps<IAccountProfileItem>,
}

const BulkDeleteCell = ({ cellProps: { row }, bulkDeleteItems = [], toggleBulkDelete }: IProps) => {
  const isChecked = Boolean(bulkDeleteItems.find((item) => item.id === row.id));

  const onChange = () => toggleBulkDelete(row);

  return (
    <Checkbox
      dataTest="bulkDeleteCell"
      onChange={ onChange }
      checked={ isChecked }
    />
  );
};

export default BulkDeleteCell;
