import { commonCellCallback } from '@/condor/ui/common/Layout/helpers';
import {
  cellStatusCallback,
  lastTableCell,
  oneOffsCellCallback,
  realTimeCellCallback,
  dailyCellCallback,
  weeklyCellCallback,
} from './cellCallbacks';
import alignStyles from '@/ui/shared/styles/helpers/align.scss';

const columns = [
  {
    name: 'status',
    title: 'Status',
    sortable: true,
    cellCallback: cellStatusCallback,
    width: 80,
  },
  {
    name: 'contactId',
    title: 'ID',
    sortable: true,
    cellCallback: commonCellCallback,
    width: 96,
  },
  {
    name: 'email',
    title: 'Email',
    sortable: true,
    cellCallback: commonCellCallback,
    minWidth: 250,
  },
  {
    name: 'weekly',
    title: 'Weekly',
    sortable: false,
    cellCallback: weeklyCellCallback,
  },
  {
    name: 'daily',
    title: 'Daily',
    sortable: false,
    cellCallback: dailyCellCallback,
    width: 95,
  },
  {
    name: 'real-time',
    title: 'Real-time',
    sortable: false,
    cellCallback: realTimeCellCallback,
    width: 146,
    className: alignStyles.alignRight,
  },
  {
    name: 'one-offs',
    title: 'One-offs',
    sortable: false,
    cellCallback: oneOffsCellCallback,
    width: 148,
    className: alignStyles.alignRight,
  },
  {
    name: 'createdAt',
    title: 'Date',
    sortable: true,
    cellCallback: lastTableCell,
    width: 170,
    className: alignStyles.alignRight,
  },
];

export default columns;
