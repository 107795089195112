import MappingsRepository from '@/users/infrastructure/repository/mappingsRepository';
import { getCollectionActions } from '@/Framework/State/Redux/collectionFactory';

import actionTypes from './actionTypes';
import selectors from './selectors';
import { getAccountId } from '../helpers';

export const {
  getCollection: getMappingsWebDomainsCollection,
  sort,
  itemsPerPage,
  paginate,
  reset: resetMappingsWebDomainsCollection,
} = getCollectionActions(
  actionTypes,
  (payload, dispatch, getState) => {
    const mappingsRepository: MappingsRepository = getState().container.get(MappingsRepository);
  return mappingsRepository.getWebDomainsMappings(payload);
},
);

export const getMappingsWebDomainsByFilter = () => (dispatch, getState) => {
  const state = getState();

  const id = getAccountId(state);

  dispatch(getMappingsWebDomainsCollection(
    {
      accountId: id,
      limit: selectors.getPerPage(state),
      offset: (selectors.getPage(state) - 1) * selectors.getPerPage(state),
      orderBy: selectors.getSortBy(state),
      orderAsc: selectors.getSortOrder(state) === 'asc',
    },
  ));
};
