import { Dependencies } from 'constitute';
import { v4 as uuid } from 'uuid';
import Request from '@/Framework/api/Rpc/Request';
import { Subscription } from '@dealroadshow/socket-frontend-sdk';
import SocketPush from '@/finsight/application/DI/Socket/Client';
import { RpcError, RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import FinsightRpcDispatcher from '@/finsight/application/DI/Rpc/finsight-web/HttpDispatcher';
import { ITopCompanyServerData, ITopParentsPayload } from '@/finsight/application/TopCompanies/interfaces';

@Dependencies(FinsightRpcDispatcher, SocketPush)
class CompanyRepository {
  excelExportUrlSubscription: Subscription;

  constructor(protected rpc: typeof FinsightRpcDispatcher, protected socketPush: typeof SocketPush) {
    this.excelExportUrlSubscription = null;
  }

  getCompanyByViewId = async (payload: {
    viewId: number | string,
  }): Promise<{
    viewId: number,
    id: string,
    name: string,
    industryId: string,
    subindustryId: string,
    publicExchange: string,
    publicTicker?: string | null,
    abbreviation: string,
    issuerTicker: string,
  } | null> => {
    const request = new Request('finsight_web.company.get_company_by_view_id', payload);
    const response = await this.rpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  /**
   * Gets top companies ordered by group
   * @param {Object} payload
   * @return {Promise}
   */
  getTopCompanies = async (payload: ITopParentsPayload): Promise<ITopCompanyServerData> => {
    let response = await this.rpc.request(new Request(
      'finsight_web.market.company.get_top_companies',
      payload,
    ));
    return response.getResult().payload;
  };

  /**
   * Gets top companies periods for periods dropdown
   * @param {Object} payload
   * @return {Promise}
   */
  getTopCompaniesPeriods = async (payload) => {
    let response = await this.rpc.request(new Request(
      'finsight_web.market.company.get_top_companies_ttm_filter',
      payload,
    ));
    return response.getResult().payload;
  };

  /**
   * @param {Object} payload
   * @return {Promise}
   */
  getExcelExportUrl = async (payload = {}) => {
    let exportId = uuid();

    let req = new Request(
      'finsight_web.market.company.generate_top_parents_document_export',
      { ...payload, exportId },
    );
    const socketSubscribeReq = new Request('finsight_web.socket.notifications', {
      entityId: exportId,
    });
    this.excelExportUrlSubscription = await this.socketPush.subscribe(socketSubscribeReq);
    await this.rpc.request(req);
    return new Promise((resolve, reject) => {
      this.excelExportUrlSubscription.on('finsight_web.export_generation',
        ({ params: { payload: { status, data: { url: exportUrl, error } } } }) => {
          this.excelExportUrlSubscription.cancel();
          if (status === 'SUCCESS') {
            resolve(exportUrl);
          } else {
            reject(new RpcError({
              id: req.id,
              error,
            }));
          }
        },
      );
    });
  };

  /**
   * @param {Object} payload
   * @returns {Promise}
   */
  getCompanyById = async (payload = {}) => {
    const response = await this.rpc.request(new Request('finsight_web.company.get_company_by_id', payload));

    return response.getResult().payload;
  };

  /**
   * @param {Object} payload
   * @param {Object} payload.filter
   * @param {String} payload.filter.includedId - require
   * or
   * @param {String} payload.filter.includedViewId - require
   * @param {Object} payload.filter.includedRole - optional
   * @return {Promise}
   */
  getCompaniesListAggregate = async (payload = {}) => {
    const response = await this.rpc.request(new Request('finsight_web.company.get_companies_aggregate', payload));

    return response.getResult().payload;
  };

  /**
   * @param {Object} payload
   * @return {Promise}
   */
  getCompanyInformationForMigration = async (payload) => {
    const response = await this.rpc.request(
      new Request('finsight_web.company.get_companies_information_for_migration', payload),
    );

    return response.getResult().payload;
  };

  /**
   * @param {Object} payload
   * @return {Promise}
   */
  migrateCompanyDeals = async (payload) => {
    const response = await this.rpc.request(
      new Request('finsight_web.company.move_information_between_company', payload),
    );

    return response.getResult().payload;
  };

  /**
   * @param {Object} payload.filter
   * @param {String} payload.filter.includedId - required
   * or
   * @param {String} payload.filter.includedViewId - required
   * @param {Object} payload.filter.includedRole - optional
   * @return {Promise}
   */
  createCompany = async (payload = {}) => {
    const response = await this.rpc.request(new Request('finsight_web.company.create', payload));

    return response.getResult().payload;
  };

  /**
   * @param {Object} payload
   */
  updateCompany = async (payload = {}) => {
    const response = await this.rpc.request(new Request('finsight_web.company.update', payload));

    return response.getResult().payload;
  };

  /**
   * @param {Object} payload
   */
  removeCompany = async (payload) => {
    const response = await this.rpc.request(new Request('finsight_web.company.delete', payload));

    return response.getResult().payload;
  };
}

export default CompanyRepository;
