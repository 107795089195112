const getSubscriptionsMetadata = (state) => state.condor.finsight.subscriptions.metadata;

export default {
  getManageSubscriptionsModalItem: (state) => getSubscriptionsMetadata(state).manageSubscriptionsModalData?.item,
  isManageSubscriptionsModalVisible:
    (state) => getSubscriptionsMetadata(state).manageSubscriptionsModalData?.isModalOpen,
  isSubscribeModalVisible: (state) => getSubscriptionsMetadata(state).isSubscribeModalVisible,
  isSubmitting: (state) => getSubscriptionsMetadata(state).isSubmitting,
  isFetching: (state) => getSubscriptionsMetadata(state).isFetching,
  getSubscriptionsData: (state) => getSubscriptionsMetadata(state).subscriptionsData,
  getSubscriptionsFormValues: (state) => getSubscriptionsMetadata(state).subscriptionsFormValues,
};
