import { batch, connect } from 'react-redux';
import {
  getContactsByFilter,
  resetContacts,
} from '@/condor/application/actions/contacts/collection/actions';
import {
  resetContactItem,
} from '@/condor/application/actions/contacts/item/actions';
import { resetContactsFilters } from '@/condor/application/actions/contacts/filters/actions';
import * as companyActions from '@/users/application/actions/companies/data/actions';
import * as userTitleActions from '@/users/application/actions/title/actions';
import ContactsList from './ContactsList';

const mapDispatchToProps = (dispatch) => (
  {
    init: () => {
      dispatch(getContactsByFilter());
      dispatch(companyActions.getCompanies({ limit: 50 }));
      dispatch(userTitleActions.getTitles());
    },
    reset: () => batch(() => {
      dispatch(resetContactItem());
      dispatch(resetContacts());
      dispatch(resetContactsFilters());
      dispatch(companyActions.resetCompaniesData());
    }),
  }
);

export default connect(
  null,
  mapDispatchToProps,
)(ContactsList);
