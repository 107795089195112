import map from 'lodash/map';
import { getCollectionActions } from '@/Framework/State/Redux/collectionFactory';
import actionTypes from './actionTypes';
import selectors from './selectors';
import companySelectors from '../../item/selectors';
import createAsyncRequest from '@/Framework/State/Redux/createAsyncRequest';
import { prepareGetDealsPayload } from './helpers';

import DealRepository from '@/finsight/infrastructure/repository/DealRepository';
import RegionRepository from '@/finsight/infrastructure/repository/RegionRepository';
import ProductRepository from '@/finsight/infrastructure/repository/ProductRepository';
import SectorRepository from '@/finsight/infrastructure/repository/SectorRepository';
import createAction from '@/Framework/State/Redux/createAction';

export const initDealsList = () => (dispatch, getState) => {
  const productRepository = getState().container.get(ProductRepository);
  const regionRepository = getState().container.get(RegionRepository);
  const sectorRepository = getState().container.get(SectorRepository);

  return dispatch(createAsyncRequest(
    {
      types: [
        actionTypes.GET_CONDOR_DEALS_LIST_OPTIONS,
        actionTypes.GET_CONDOR_DEALS_LIST_OPTIONS_SUCCESS,
        actionTypes.GET_CONDOR_DEALS_LIST_OPTIONS_ERROR,
      ],
      method: () => Promise.all(
        [
          productRepository.getList(),
          regionRepository.getList(),
          sectorRepository.getList(),
        ],
      ),
    },
  ));
};

export const {
  getCollection: getCondorFinsightCompanyDeals,
  sort,
  paginate,
  itemsPerPage,
  setSearchQuery: setCondorFinsightSearch,
  reset: resetCondorFinsightCompanyDeals,
} = getCollectionActions(
  actionTypes,
  (payload, dispatch, getState) => {
    const finsightDealRepository = getState().container.get(DealRepository);
    return finsightDealRepository.getDealsForCondorAdmin(prepareGetDealsPayload(payload));
  },
);

export const getCondorFinsightCompanyDealsByFilter = (companyId) => (dispatch, getState) => {
  const state = getState();
  let currentCompanyId = companyId;
  if (!currentCompanyId) {
    currentCompanyId = companySelectors.getItemData(state).id;
  }

  const payload = {
    filters: {
      includedCompanyId: currentCompanyId,
      includedProductIds: map(selectors.getFilters(state).products, 'value'),
    },
    paginationAndSearch: {
      page: selectors.getPage(state),
      perPage: selectors.getPerPage(state),
      query: selectors.getSearchQuery(state),
      sortBy: selectors.getSortBy(state),
      sortOrder: selectors.getSortOrder(state),
    },
  };

  dispatch(getCondorFinsightCompanyDeals(payload));
};

export const setCondorDealsListFiltersProducts = (payload) => createAction(
  actionTypes.SET_CONDOR_DEALS_LIST_FILTERS_PRODUCTS,
  payload,
);
