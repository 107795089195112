import { set, getYear, getMonth, getHours, getDate, getMinutes, getSeconds } from 'date-fns';
import isObject from 'lodash/isObject';

import pad from '@/Framework/dataHelpers/number/pad';
import isEmptyString from '@/Framework/dataHelpers/string/isEmptyString';
import { addHttpToUrl } from '@/Framework/url/helpers/transformUrl';
import utcToZonedDate from '@/Framework/DateTime/utcToZonedDate';
import { getCompanyIdByName } from '@/users/domain/company/Company';
import { ROTATING_ENTRY_CODE_TYPE, SINGLE_ENTRY_CODE_TYPE } from '@/dealroadshow/domain/EntryCode';
import { SIDEBAR_WIDTHS } from '@/dealroadshow/domain/roadshowForm/constants';
import { VerificationState } from '@/dealroadshow/domain/roadshowForm/defaultRoadshowFormValues';
import getUnixTimestamp from '@/Framework/DateTime/getUnixTimestamp';
import getDateFromUnixTime from '@/Framework/DateTime/getDateFromUnixTime';
import getZonedUTCDate from '@/Framework/DateTime/getZonedUTCDate';

/**
 * @param {Array} whitelist
 * @return {Array}
 */
function filterWhitelist(whitelist) {
  return whitelist.map((whitelist) => {
    if (whitelist.email) {
      let user = whitelist.email.split('@');
      return {
        emailUserName: user[0],
        domain: user[1],
        disclaimer: whitelist.disclaimer,
        serialNumber: whitelist.serialNumber,
      };
    }
    return {
      domain: whitelist.domain,
      disclaimer: whitelist.disclaimer,
    };
  });
}

/**
 * Generate random 4 digit serial number
 * @param {Array} whitelist
 * @return {String}
 */
function generateRandomSerialNumber(whitelist) {
  let serialNumber = (1000 + Math.random() * 9000).toFixed();
  let isExist = whitelist.filter((user) => user.serialNumber === serialNumber);
  if (!isExist.length) {
    return serialNumber;
  }
  return generateRandomSerialNumber(whitelist);
}

/**
 * Generate serial number
 * @param {Number} counter
 * @param {Array} whitelist
 * @return {String}
 */
export function generateSerialNumber(counter, whitelist) {
  let serialNumber = pad(counter, 4);
  let isExist = whitelist.filter((user) => user.serialNumber === serialNumber);
  if (!isExist.length && serialNumber.toString().length < 5) {
    return serialNumber;
  }
  return generateRandomSerialNumber(whitelist);
}

/**
 * @param {Array} blacklist
 * @return {Array}
 */
function filterBlacklist(blacklist) {
  return blacklist.map((blacklist) => {
    if (blacklist.email) {
      let user = blacklist.email.split('@');
      return {
        emailUserName: user[0],
        domain: user[1],
      };
    }
    return {
      domain: blacklist.domain,
    };
  });
}

/**
 * @param {Array} entryCodeRotatingCollection
 * @param {Object} entryCodeRotatingMap
 * @param {Object} timeZoneDisplay
 * @return {Array}
 */
function formatRotatingEntryCodes(entryCodeRotatingCollection, entryCodeRotatingMap, timeZoneDisplay) {
  return entryCodeRotatingCollection.map(({ id = null, uuid }) => {
    const entryCode = entryCodeRotatingMap[uuid];

    return {
      id,
      code: entryCode.code,
      startedAt: convertTimeZone(
        getDateFromUnixTime(entryCode.startedAt),
        timeZoneDisplay,
      ),
      expiredAt: convertTimeZone(
        getDateFromUnixTime(entryCode.expiredAt),
        timeZoneDisplay,
      ),
      loginsMaxNumber: entryCode.loginsMaxNumber,
      loginsTimeLimit: entryCode.loginsTimeLimit,
      notes: entryCode.notes || '',
      dialInCode: entryCode.dialInCode,
    };
  });
}

/**
 * @param {Array} underwriters
 * @return {Array}
 */
function filterUnderwriters(underwriters) {
  return underwriters
    .filter((underwriter) => {
      if (!Object.keys(underwriter).length) {
        return false;
      }
      return !!(underwriter.name || underwriter.phone || underwriter.url || underwriter.lead);
    })
    .map((underwriter) => ({
      id: underwriter.id || null,
      name: underwriter.name,
      phone: underwriter.phone || '',
      url: addHttpToUrl(underwriter.url) || '',
      logoUrl: underwriter.logoUrl?.length ? underwriter.logoUrl : null,
      logoOriginalName: underwriter.logoOriginalName || '',
      lead: underwriter.lead || false,
    }));
}

/**
 * @param {Array} underwriters
 * @param {Array} companies
 * @return {Array}
 */
function filterEnhancedTaggingUnderwriters(underwriters, companies) {
  return underwriters
    .filter((underwriter) => {
      if (!Object.keys(underwriter).length) {
        return false;
      }
      return !!(underwriter.name || underwriter.lead);
    })
    .map((underwriter) => ({
      companyId: getCompanyIdByName(companies, underwriter.name) || null,
      name: underwriter.name,
      lead: underwriter.lead || false,
    }));
}

/**
 * @param {Array} managers
 * @return {Array}
 */
function filterManagers(managers) {
  const filterDealDigest = (digests) => {
    let dealDigest = [];
    // eslint-disable-next-line no-restricted-syntax
    for (let hour in digests) {
      // eslint-disable-next-line no-prototype-builtins
      if (digests.hasOwnProperty(hour)) {
        if (digests[hour] && hour !== '') {
          dealDigest.push(hour);
        }
      }
    }
    return dealDigest.sort((a, b) => a - b);
  };

  const secondsInHour = 60 * 60;
  const dealDigestToHourInTZ = (digests) => digests.map((time) => (time / secondsInHour).toString().padStart(2, '0'));
  const dealDigestToTimestampInTZ = (digests) => digests
    .map((hour) => Number(hour) * secondsInHour)
    .sort((a, b) => a - b);

  return managers
    .filter((manager) => {
      if (!Object.keys(manager).length) {
        return false;
      }
      return !!manager.corporateEmail;
    })
    .map((manager) => {
      let dealDigestInTZ = dealDigestToTimestampInTZ(filterDealDigest(manager.dealDigestInTZ));
      return {
        corporateEmail: manager.corporateEmail,
        dealDigest: dealDigestToHourInTZ(dealDigestInTZ),
        dealDigestTimeInTZ: dealDigestInTZ,
        loginAlerts: manager.loginAlerts || false,
        sendDigestOnWeekends: manager.sendDigestOnWeekends || false,
        analyticsOnly: manager.analyticsOnly || false,
        inviteByEmail: manager.inviteByEmail || false,
        investorQuestionsAlertsEnabled: manager.investorQuestionsAlertsEnabled || false,
        requestMeetingAlertsEnabled: manager.requestMeetingAlertsEnabled || false,
      };
    });
}

/**
 * @param {Object} files
 * @param {Object} formData
 * @return {Array}
 */
export function filterFiles(files, formData) {
  let filesSave = [];
  files.sort.forEach((file, index) => {
    if (files.files[file.uuid].canSave) {
      filesSave.push({
        id: files.files[file.uuid].initialFile ? files.files[file.uuid].initialFile.id : null,
        url: files.files[file.uuid].url,
        name: formData[index].name || '',
        originalName: files.files[file.uuid].originalName || null,
        customDisclaimer: !isEmptyString(formData[index].disclaimer) ? formData[index].customDisclaimer : false,
        disclaimer: formData[index].disclaimer || '',
        viewOnly: formData[index].viewOnly || false,
      });
    }
  });
  return filesSave;
}

/**
 * @param {Object} commercialVideos
 * @param {Object} fromData
 * @return {Array}
 */
function filterCommercialVideos(commercialVideos, fromData) {
  let filesSave = [];
  commercialVideos.sort.forEach((video, index) => {
    if (commercialVideos.files[video.uuid].canSave) {
      filesSave.push({
        id: video.uuid,
        url: commercialVideos.files[video.uuid].url,
        originalName: commercialVideos.files[video.uuid].originalName || null,
        name: fromData[index].name || '',
        status: commercialVideos.files[video.uuid].processed,
      });
    }
  });
  return filesSave;
}

/**
 * @param {Array} links
 * @return {Array}
 */
function filterLinks(links) {
  return links
    .filter((link) => {
      if (!Object.keys(link).length) {
        return false;
      }
      return !!(link.url || link.name);
    })
    .map((link) => ({
      id: link.id || null,
      url: addHttpToUrl(link.url),
      name: link.name || '',
    }));
}

/**
 * @param {Array} slides
 * @return {Array}
 */
function filterSlideShow(slides) {
  return slides.length ? slides : [];
}

/**
 * @param {Date | Number } date
 * @param {Object} timeZoneDisplay
 * @return {Number}
 */
export function convertTimeZone(date, timeZoneDisplay) {
  return getUnixTimestamp(set(utcToZonedDate(timeZoneDisplay.timeZone), {
    year: getYear(date),
    month: getMonth(date),
    date: getDate(date),
    hours: getHours(date),
    minutes: getMinutes(date),
    seconds: getSeconds(date),
    milliseconds: 0,
  }));
}

/**
 * @param {Number} startedAt
 * @param {Object} timeZoneApplied
 * @return {Number}
 */
const filterStartedAt = (startedAt, timeZoneApplied) => {
  if (startedAt) {
    return convertTimeZone(
      getDateFromUnixTime(startedAt),
      timeZoneApplied,
    );
  }

  return getUnixTimestamp();
};

/**
 * @param {Number} dateAt
 * @param {Object} timeZoneDisplay
 * @return {Number}
 */
function filterExpireAt(dateAt, timeZoneDisplay) {
  const date = getDateFromUnixTime(dateAt);

  return getUnixTimestamp(getZonedUTCDate(timeZoneDisplay?.timeZone, date));
}

/**
 * Quick setup
 * @param {Object} formData
 * @param {Object} timeZoneApplied
 * @return {Object}
 */
function filterQuickSetup(formData, timeZoneApplied) {
  let saveData = {
    singleEntryCode: null,
    appliedEntryCodeType: SINGLE_ENTRY_CODE_TYPE,
    rotatingEntryCodes: [],
    name: formData.name,
    transactionTypeId: formData.transactionTypeId,
    startedAt: filterStartedAt(formData.startedAt, timeZoneApplied),
    finishedAt: filterExpireAt(formData.finishedAt, timeZoneApplied),
    timeZone: timeZoneApplied,
    managers: filterManagers(formData.dealManagers),
    dialInEnabled: formData.dialInEnabled,
    dialInPhone: formData.dialInEnabled ? formData.dialInPhone : null,
    autoActivationMultipleEntryCodes: formData.autoActivationMultipleEntryCodes,
    autoActivationMultipleEntryCodesStartTimeShift: !formData.autoActivationMultipleEntryCodes
      ? 10 : formData.autoActivationMultipleEntryCodesStartTimeShift,
    autoActivationMultipleEntryCodesFinishTimeShift: !formData.autoActivationMultipleEntryCodes
      ? 0 : formData.autoActivationMultipleEntryCodesFinishTimeShift,
    evercallConferenceId: formData.evercallConferenceId,
    internalNotes: formData.internalNotes,
    reviewEntryCodeEnabled: formData.reviewEntryCodeEnabled,
    reviewEntryCode: null,
  };

  if (formData.upload.presentation.canSave) {
    saveData.presentation = {
      origin: formData.upload.presentation.presentationOrigin,
    };

    saveData.presentation.slideShow = filterSlideShow(formData.upload.presentation.slideShow);
  }

  if (formData.appliedEntryCodeType === SINGLE_ENTRY_CODE_TYPE) {
    saveData.singleEntryCode = {
      id: formData.singleEntryCodeId,
      code: formData.entryCode,
      startedAt: filterStartedAt(formData.startedAt, timeZoneApplied),
      expiredAt: filterExpireAt(formData.finishedAt, timeZoneApplied),
      loginsMaxNumber: formData.loginsMaxNumber,
      loginsTimeLimit: formData.loginsTimeLimit,
      dialInCode: formData.dialInCode,
    };
  }

  if (formData.appliedEntryCodeType === ROTATING_ENTRY_CODE_TYPE && formData.entryCodeRotatingCollection) {
    saveData.appliedEntryCodeType = ROTATING_ENTRY_CODE_TYPE;
    saveData.rotatingEntryCodes = formatRotatingEntryCodes(
      formData.entryCodeRotatingCollection,
      formData.entryCodeRotatingMap,
      timeZoneApplied,
    );
  }

  if (formData.reviewEntryCodeEnabled) {
    saveData.reviewEntryCode = {
      code: formData.reviewEntryCode,
      startedAt: filterStartedAt(formData.startedAt, timeZoneApplied),
      expiredAt: null,
      loginsMaxNumber: null,
      loginsTimeLimit: null,
      dialInCode: null,
      id: formData.reviewEntryCodeId,
    };
  }
  return saveData;
}

/**
 * Get initial state radio-button investorEmailVerification form value
 * @param {Object} roadshow
 * @returns {string}
 */
export const getInvestorEmailVerificationFormValue = ({ bypassActivated, promptVerificationActivated }) => {
  if (bypassActivated && !promptVerificationActivated) {
    return VerificationState.BYPASS_ACTIVATED;
  }

  if (!bypassActivated && promptVerificationActivated) {
    return VerificationState.PROMPT_VERIFICATION_ACTIVATED;
  }

  return VerificationState.BYPASS_DEACTIVATED;
};

/**
 * Additional Security & Disclaimer
 * Investor Email Verification RadoGroup mapping Values to submit
 * @param {Object} formData
 * @return {Object}
 */
export const getInvestorEmailVerificationValues = ({ investorEmailVerification }) => {
  return {
    bypassActivated: investorEmailVerification === VerificationState.BYPASS_ACTIVATED,
    promptVerificationActivated: investorEmailVerification === VerificationState.PROMPT_VERIFICATION_ACTIVATED,
  };
};

/**
 * Additional Security & Disclaimer
 * @param {Object} formData
 * @return {Object}
 */
function filterSecurityAndDisclaimer(formData) {
  return {
    watermarkEnabled: formData.watermarkEnabled || false,
    watermarkOpacity: parseInt(formData.watermarkOpacity),
    pdfWatermarkEnabled: formData.pdfWatermarkEnabled || false,
    ...getInvestorEmailVerificationValues(formData),
    caseSensitiveEntryCodes: formData.caseSensitiveEntryCodes || false,
    mobileDisabled: formData.mobileDisabled || false,
    corporateEmailChecked: formData.corporateEmailChecked || false,
    sidebarWidth: formData.sidebarWidth || SIDEBAR_WIDTHS.STANDARD,
    disclaimer: formData.disclaimer,
    investorQuestionsEnabled: formData.investorQuestionsEnabled || false,
    requestMeetingEnabled: formData.requestMeetingEnabled || false,
  };
}

/**
 * Restrict Access
 * @param {Object} formData
 * @return {Object}
 */
function filterRestrictAccess(formData) {
  let saveData = {
    whitelistEnabled: false,
    whitelist: filterWhitelist(formData.whitelistUsersAndDomains),
    whitelistWithSerialNumberEnabled: false,
    blacklistEnabled: false,
    blacklist: filterBlacklist(formData.blacklist),
  };

  saveData.whitelistEnabled = formData.restrictWhiteOrBlacklistSwitcher === 'whitelist' || formData.restrictWhiteOrBlacklistSwitcher === 'whitelistWithSerialNumber';
  saveData.blacklistEnabled = formData.restrictWhiteOrBlacklistSwitcher === 'blacklist';
  saveData.whitelistWithSerialNumberEnabled = formData.restrictWhiteOrBlacklistSwitcher === 'whitelistWithSerialNumber';
  return saveData;
}

/**
 * Downloadable Deal Files and Media
 * @param {Object} formData
 * @return {Object}
 */
function filterDealFilesAndCommercialVideo(formData) {
  return {
    files: filterFiles(formData.upload.documents, formData.uploadDocumentsList),
    commercialVideos: filterCommercialVideos(formData.upload.commercialVideo, formData.uploadCommercialVideoList),
    links: filterLinks(formData.externalLinks),
  };
}

/**
 * Other (Underwriters & Logo)
 * @param {Object} formData
 * @return {Object}
 */
function filterUnderwritersAndLogo(formData) {
  let logo = null;
  if (formData.upload.logo.uploaded && formData.upload.logo.canSave) {
    logo = {
      name: formData.upload.logo.name || null,
      url: formData.upload.logo.url,
    };
    if (formData.upload.logo.initialFile) {
      logo.id = formData.upload.logo.initialFile.id;
      logo.name = logo.name || formData.upload.logo.initialFile.name;
    }
  }
  return {
    underwriters: filterUnderwriters(formData.underwriters),
    logo,
  };
}

/**
 * Enhanced Tagging for Analytics
 * @param {Object} formData
 * @param {Array} companies
 * @return {Object}
 */
function filterEnhancedTagging(formData, companies) {
  let sponsor = null;
  if (formData.enhancedTagging.sponsorName) {
    let sponsorId = getCompanyIdByName(companies, formData.enhancedTagging.sponsorName);
    sponsor = sponsorId ? {
      id: sponsorId,
      name: formData.enhancedTagging.sponsorName,
    } : {
      id: null,
      name: formData.enhancedTagging.sponsorName,
    };
  }

  return {
    sponsor,
    subindustryId: formData.enhancedTagging.subindustryId,
    subsectorId: formData.enhancedTagging.subsectorId,
    countryId: formData.enhancedTagging.countryId,
    currencyId: formData.enhancedTagging.currencyId,
    enhancedTaggingUnderwriters: filterEnhancedTaggingUnderwriters(formData.enhancedTagging.underwriters, companies),
    dealSize: formData.enhancedTagging.dealSize
      ? Number(formData.enhancedTagging.dealSize.toString().replace(/[^\d]/g, ''))
      : null,
  };
}

/**
 * @param {Object} formData
 * @param {Object} timeZoneApplied
 * @param {Array} companies
 * @return {Object}
 */
function filterRoadshowFormData(formData, timeZoneApplied, companies) {
  let saveData = {
    type: formData.type || 'Slides',
    published: formData.publish,
    variation: formData.variation,
  };

  if (formData.id) {
    saveData.roadshowId = formData.id;
    saveData.id = formData.id;
  }

  if (formData.originalVersion) {
    saveData.originalVersion = formData.originalVersion;
  }

  return {
    ...saveData,
    ...filterQuickSetup(formData, timeZoneApplied),
    ...filterSecurityAndDisclaimer(formData),
    ...filterRestrictAccess(formData),
    ...filterDealFilesAndCommercialVideo(formData),
    ...filterUnderwritersAndLogo(formData),
    ...filterEnhancedTagging(formData, companies),
  };
}

export default filterRoadshowFormData;

/**
 * @param {String} entryCodeId
 * @param {String} code
 * @param {{
 *   id: String,
 *   uuid: String
 * }[]} initialCollection
 * @param {Object} initialMap
 * @return {String|Null}
 */
function getEntryCodeId(entryCodeId, code, initialCollection, initialMap) {
  if (!entryCodeId) {
    return null;
  }

  // Operate over collection, because it's our source of truth, not map.
  // Use for loop instead of foreach to have ability break the loop.
  for (let i = 0; i < initialCollection.length; i++) {
    const { id, uuid } = initialCollection[i];

    if (id === entryCodeId) {
      return initialMap[uuid].code === code ? id : null;
    }
  }

  return null;
}

/**
 * @param {Object} formData
 * @return {Array}
 */
export function filterOverwriteEntryCodes(formData) {
  return formData.entryCodeRotatingCollection.map(({ uuid }) => {
    const entryCode = formData.entryCodeRotatingMap[uuid];
    const id = getEntryCodeId(
      entryCode.id,
      entryCode.code,
      formData.entryCodeRotatingInitialCollection,
      formData.entryCodeRotatingInitialMap,
    );

    return { uuid, id };
  });
}

/**
 * @param {Object[]} entryCodeRotatingCollection
 * @param {Object[]} entryCodesToSort
 * @return {Object}
 */
export function rearrangeDuplicatedEntryCodes(entryCodeRotatingCollection, entryCodesToSort) {
  return entryCodeRotatingCollection.reduce((acc, entryCode) => (
    entryCodesToSort.indexOf(entryCode.uuid) !== -1
      ? [entryCode, ...acc]
      : [...acc, entryCode]
  ), []);
}

/**
 * @param {Array} managers
 * @return {Array}
 */
function mapManagers(managers) {
  const secondsInHour = 60 * 60;
  return managers.map((manager) => {
    let dealDigestInTZ = {};
    manager.dealDigestTimeInTZ.forEach((time) => {
      let timeInTZ = (time / secondsInHour).toString().padStart(2, '0');

      dealDigestInTZ[timeInTZ] = true;
    });
    delete manager.dealDigestTimeInTZ;
    delete manager.dealDigest;
    return {
      ...manager,
      dealDigestInTZ,
    };
  });
}

/**
 * @param {Array} managers
 * @param {Object} currentUser
 * @param {Object} currentTimeZone
 * @return {Array}
 */
export function sortManagers(managers, currentUser, currentTimeZone) {
  if (currentUser) {
    let currentManager = managers.filter((manager) => manager.corporateEmail === currentUser.email);

    let otherManager = managers.filter((manager) => manager.corporateEmail !== currentUser.email)
      .map((manager) => ({
        ...manager,
        disableInviteByEmail: true,
      }));

    return mapManagers([].concat(currentManager, otherManager), currentTimeZone);
  }
  return mapManagers(managers, currentTimeZone);
}

/**
 * @param {Object} entryCodeRotatingMap
 * @param {String} prevTimeZone
 * @param {String} newTimeZone
 * @return {Object}
 */
export function updateEntryCodeMapWithNewTimeZone(entryCodeRotatingMap, prevTimeZone, newTimeZone) {
  return Object.values(entryCodeRotatingMap).reduce((acc, entryCode) => {
    const { startedAt, expiredAt } = entryCodeRotatingMap[entryCode.uuid];

    const startedAtInNewTimezone = convertTimeZone(
      getDateFromUnixTime(startedAt),
      newTimeZone,
    );
    const expiredAtInNewTimezone = convertTimeZone(
      getDateFromUnixTime(expiredAt),
      newTimeZone,
    );

    acc[entryCode.uuid] = {
      ...entryCode,
      startedAt: startedAtInNewTimezone,
      expiredAt: expiredAtInNewTimezone,
    };

    return acc;
  }, {});
}

/**
 * @param {Object} data
 * @param {Object} roadshowForm
 * @param {Object} roadshow
 * @param {Boolean} upload
 * @return {Object}
 */
export function prepareRoadshowDataForUpdate(data, roadshowForm, roadshow, upload) {
  const formData = {
    ...data,
    upload,
    blacklist: roadshowForm.blacklist.data,
    publish: roadshowForm.roadshow.isTryToPublish,
  };

  if (
    formData.appliedEntryCodeType === SINGLE_ENTRY_CODE_TYPE
    && roadshow.data.item.singleEntryCode
    && formData.entryCode !== roadshow.data.item.singleEntryCode.code
  ) {
    formData.singleEntryCodeId = null;
  }

  if (
    roadshow.data.item.reviewEntryCode
    && formData.reviewEntryCode !== roadshow.data.item.reviewEntryCode.code
  ) {
    formData.reviewEntryCodeId = null;
  }

  if (
    formData.appliedEntryCodeType === ROTATING_ENTRY_CODE_TYPE
    && roadshow.data.item.appliedEntryCodeType === ROTATING_ENTRY_CODE_TYPE
  ) {
    formData.entryCodeRotatingCollection = filterOverwriteEntryCodes(formData);
  }

  return formData;
}

/**
 * @param {Object} data
 * @return {Object}
 */
export function normalizeRoadshowFormData(data) {
  return {

    ...data,
    ...data.name && { name: data.name.trim().replace(/\s\s+/g, ' ') },
    ...data.entryCode && { entryCode: data.entryCode.trim() },

    ...data.entryCodeRotatingCollection && {
      entryCodeRotatingCollection: data.entryCodeRotatingCollection.map((entryCode) => ({

        ...entryCode,
        ...entryCode.notes && { notes: entryCode.notes.trim() },
      })),
    },

    ...data.underwriters && {
      underwriters: data.underwriters.map((underwriter) => {
        const isPhoneEntered = isObject(underwriter.phone) &&
                               underwriter.phone.value.length > underwriter.phone.code.length;

        return {

          ...underwriter,
          ...underwriter.name && { name: underwriter.name.trim() },
          phone: isPhoneEntered ? underwriter.phone.value.trim() : '',
        };
      }),
    },

    ...data.externalLinks && {
      externalLinks: data.externalLinks.map((externalLink) => ({

        ...externalLink,
        ...externalLink.name && { name: externalLink.name.trim() },
      })),
    },

    ...data.uploadCommercialVideoList && {
      uploadCommercialVideoList: data.uploadCommercialVideoList.map((video) => ({

        ...video,
        ...video.name && { name: video.name.trim() },
      })),
    },

    ...data.uploadDocumentsList && {
      uploadDocumentsList: data.uploadDocumentsList.map((document) => ({

        ...document,
        ...document.name && { name: document.name.trim() },
      })),
    },
  };
}
