import { connect } from 'react-redux';
import { resetManagerAnalyticsContacts } from '@/condor/application/actions/finsightManager/analytics/contactsCollection/actions';
import Analytics from './Analytics';

const mapDispatchToProps = {
  onUnmount: resetManagerAnalyticsContacts,
};

export default connect(
  null,
  mapDispatchToProps,
)(Analytics);
