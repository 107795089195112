import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getApiAccessKey, setApiAccessKey } from '@/users/application/actions/advanced/actions';
import Advanced from './Advanced';

const mapStateToProps = (state) => ({
  apiAccessKey: state.user.advanced.apiAccessKey,
  isLoading: state.user.advanced.isLoading,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getApiAccessKey,
  setApiAccessKey,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Advanced);
