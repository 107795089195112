import React, { useCallback } from 'react';
import { Field } from 'redux-form';
import ReduxFormSelect from '@/ui/shared/components/Form/Select/legacy/ReduxFormSelect';
import { APPLICATION_WRP_ID } from '@/ui/shared/components/Layout/ApplicationWrp/constants';
import { CONTENT_WRP_ID } from '@/ui/shared/components/Layout/ContentWrp/constants';
import Product from '@/finsight/domain/Product';

interface IProps {
  options: any[],
  tableRef: any,
  clearSectorSubsector: () => void,
}

const ProductField = (props: IProps) => {
  const onProductChange = useCallback(
    (product) => {
      if (!product) {
        return;
      }
      if (!Product.isAbsProductId(product.value)) {
        props.clearSectorSubsector();
      }
    },
    [],
  );
  return (
    <Field
      isNarrow
      name="product"
      usePortal
      component={ ReduxFormSelect }
      options={ props.options }
      onChange={ onProductChange }
      bodyElement={ document.getElementById(APPLICATION_WRP_ID) }
      scrollableParentElements={ [props.tableRef.current, document.getElementById(CONTENT_WRP_ID)] }
      dataTest="condorProductFieldReduxFormSelect"
    />
  );
};

export default ProductField;
