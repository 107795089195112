import { useState } from 'react';
import cn from 'classnames';
import {
  Icon,
  IconType,
  Tooltip,
  Input,
  Select,
  ISelectOption,
  Button,
  ButtonVariantType,
  CheckboxInput,
  ICellProps,
} from '@dealroadshow/uikit';
import EmailInput from '@/ui/shared/components/Form/Fields/EmailInput';
import { ProfileAccessType, ProfileAccessTypes } from '@/openMarket/domain/Condor/ProfileAccessType';
import { CONTENT_WRP_ID } from '@/ui/shared/components/Layout/ContentWrp/constants';
import { Field, useField, useFieldArray } from '@/ui/shared/components/Form/FinalForm';
import { useDisplayTabsContext } from './DisplayTabsContext';
import { WhitelistModal } from '../Whitelist';
import { getFieldDataTest, getFieldName } from './helpers';
import { IDisplayTab } from './interfaces';
import { DisplayTabFormFieldName } from './constants';
import { FormFieldName } from '../../constants';

import styles from './displayTabs.scss';

export const DisplayCell = ({ rowIndex, cellName }: ICellProps) => {
  const name = getFieldName(rowIndex, cellName);
  const dataTest = getFieldDataTest(cellName);

  return <Field name={ name } dataTest={ dataTest } component={ CheckboxInput } type="checkbox" isNarrow />;
};

export const TabNameCell = ({ rowIndex, cellName }: ICellProps) => {
  const name = getFieldName(rowIndex, cellName);
  const dataTest = getFieldDataTest(cellName);

  return <Field name={ name } dataTest={ dataTest } component={ Input } placeholder="Tab Name" isNarrow />;
};

export const TransactionTypeCell = ({ rowIndex, cellName }: ICellProps) => {
  const name = getFieldName(rowIndex, cellName);
  const dataTest = getFieldDataTest(cellName);

  const { transactionTypesOptions, tableRef } = useDisplayTabsContext();

  return (
    <Field
      name={ name }
      render={ ({ input, meta }) => {
        const value = (input.value as string[])
          .map((id) => transactionTypesOptions.find((option) => option.value === id))
          .filter(Boolean);

        const onChange = (value: ISelectOption[]) => {
          input.onChange(value.map((option) => option.value));
        };

        return (
          <Select
            isUsePortal
            scrollableParentElements={ [document.getElementById(CONTENT_WRP_ID), tableRef.current] }
            dataTest={ dataTest }
            name={ input.name }
            value={ value }
            meta={ meta }
            onBlur={ input.onBlur }
            onChange={ onChange }
            options={ transactionTypesOptions }
            placeholder="Select Transaction Type(s)"
            isMulti
            isNarrow
          />
        );
      } }
    />
  );
};

export const RegionsCell = ({ rowIndex, cellName }: ICellProps) => {
  const name = getFieldName(rowIndex, cellName);
  const dataTest = getFieldDataTest(cellName);

  const { regionsOptions, tableRef } = useDisplayTabsContext();

  return (
    <Field
      name={ name }
      render={ ({ input, meta }) => {
        const value = (input.value as string[])
          .map((id) => regionsOptions.find((option) => option.value === id))
          .filter(Boolean);

        const onChange = (value: ISelectOption[]) => {
          input.onChange(value.map((option) => option.value));
        };

        return (
          <Select
            isUsePortal
            scrollableParentElements={ [document.getElementById(CONTENT_WRP_ID), tableRef.current] }
            dataTest={ dataTest }
            name={ input.name }
            value={ value }
            meta={ meta }
            onBlur={ input.onBlur }
            onChange={ onChange }
            options={ regionsOptions }
            placeholder="Select Region(s)"
            isMulti
            isNarrow
          />
        );
      } }
    />
  );
};

export const EmailCell = ({ rowIndex, cellName }: ICellProps) => {
  const name = getFieldName(rowIndex, cellName);
  const dataTest = getFieldDataTest(cellName);

  const {
    input: { value: restrictAccess },
  } = useField<ProfileAccessTypes>(FormFieldName.RESTRICT_ACCESS);

  const isInputDisabled = ProfileAccessType.isLogin(restrictAccess);

  return (
    <Field
      name={ name }
      dataTest={ dataTest }
      component={ EmailInput }
      disabled={ isInputDisabled }
      placeholder="Set email"
      isNarrow
    />
  );
};

const getCountValue = (count: number, label: string) => {
  if (count === 0) {
    return '';
  }

  const pluralLabel = count > 1 ? `${ label }s` : label;

  return `${ count } ${ pluralLabel }`;
};

export const WhitelistCell = ({ row, rowIndex }: ICellProps<IDisplayTab>) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const {
    input: { value: restrictAccess },
  } = useField<ProfileAccessTypes>(FormFieldName.RESTRICT_ACCESS);

  const isButtonDisabled = ProfileAccessType.isLogin(restrictAccess);

  const {
    input: { value: whitelist, onChange: onWhitelistChange },
  } = useField<IDisplayTab['whitelist']>(getFieldName(rowIndex, DisplayTabFormFieldName.WHITELIST));

  const openModal = () => setIsModalVisible(true);
  const closeModal = () => setIsModalVisible(false);

  const getButtonTitle = () => {
    if (whitelist.length === 0) {
      return 'Add Domains / Emails';
    }

    const emailCounts = whitelist.filter(({ emailUserName }) => Boolean(emailUserName)).length;
    const domainCounts = whitelist.length - emailCounts;

    return [getCountValue(domainCounts, 'Domain'), getCountValue(emailCounts, 'Email')].filter(Boolean).join(' / ');
  };

  const setWhitelist = (whitelist: IDisplayTab['whitelist']) => {
    onWhitelistChange({
      target: {
        value: whitelist,
      },
    });
  };

  return (
    <>
      <Tooltip
        disabled={ !isButtonDisabled }
        content='Whitelists are active and can be edited only if Restrict Access is set to "Restricted with FINSIGHT Login and Whitelist".'
      >
        <Button
          variant={ ButtonVariantType.link }
          disabled={ isButtonDisabled }
          onClick={ openModal }
          dataTest="changeWhitelist"
          className={ cn(styles.whitelistButton, {
            [styles.isDisabled]: isButtonDisabled,
          }) }
        >
          { getButtonTitle() }
        </Button>
      </Tooltip>
      { isModalVisible && (
        <WhitelistModal
          close={ closeModal }
          tabName={ row.name }
          whitelist={ whitelist }
          setWhitelist={ setWhitelist }
        />
      ) }
    </>
  );
};

export const ActionCell = ({ rowIndex }: ICellProps) => {
  const { fields } = useFieldArray<IDisplayTab>(FormFieldName.DISPLAY_TABS);

  return (
    <div className={ styles.actions }>
      <Tooltip content="Delete tab">
        <Button
          dataTest="removeButton"
          variant={ ButtonVariantType.link }
          className={ styles.removeButton }
          onClick={ () => fields.remove(rowIndex) }
        >
          <Icon type={ IconType.trash } />
        </Button>
      </Tooltip>
    </div>
  );
};
