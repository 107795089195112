import React from 'react';
import { parse } from 'date-fns';
import { format } from 'date-fns-tz';
import utcToZonedDate from '@/Framework/DateTime/utcToZonedDate';
import { CONDOR_TIME_FORMAT, MONTH_DAY_YEAR_FORMAT } from '@/Framework/DateTime/dateFormats';
import { IDatetime } from '@/Framework/DateTime/Datetime';
import styles from '@/condor/ui/common/EnhancedTagging/EditableTable/editableTable.scss';

interface IProps {
  date: IDatetime,
  actions: React.ReactNode,
}

const DateCallback = (props: IProps) => {
  const { date, actions } = props;

  const dateUtc = parse(date.dateTime, CONDOR_TIME_FORMAT, new Date());
  const dateWithTimezone = utcToZonedDate(date.timezone.timeZone, dateUtc);
  const formattedDate = format(dateWithTimezone, MONTH_DAY_YEAR_FORMAT, { timeZone: date.timezone.timeZone });

  return (
    <>
      <div className={ styles.contentInActionsOnHoverCell }>{ formattedDate }</div>
      <div className={ styles.actionsInActionsOnHoverCell }>{ actions }</div>
    </>
  );
};

export default DateCallback;
