import { connect } from 'react-redux';

import selectors from '@/condor/application/actions/accounts/mappings/deals/selectors';
import {
  itemsPerPage,
  getMappingsDealsByFilter,
  paginate,
  sort,
  resetMappingsDealsCollection,
} from '@/condor/application/actions/accounts/mappings/deals/actions';

import BaseMappingsPanel from '../BaseMappingsPanel';
import alignStyles from '@/ui/shared/styles/helpers/align.scss';
import { dealNameCellCallback } from '../cellCallbacks/dealNameCellCallback';
import { platformCellCallback } from '../cellCallbacks/platformCellCallback';

const columns = [
  {
    name: 'name',
    title: 'Name',
    cellCallback: dealNameCellCallback,
    className: alignStyles.alignLeft,
    width: 320,
    sortable: true,
  },
  {
    name: 'platform',
    title: 'Platform',
    cellCallback: platformCellCallback,
    className: alignStyles.alignLeft,
    sortable: true,
  },
];

const mapStateToProps = (state) => (
  {
    page: selectors.getPage(state),
    perPage: selectors.getPerPage(state),
    sortBy: selectors.getSortBy(state),
    sortOrder: selectors.getSortOrder(state),
    isFetching: selectors.isFetching(state),
    totalCount: selectors.getTotalCount(state),
    columns,
    dataTableDataTest: 'dealsMappingsTable',
    paginatorDataTest: 'dealsMappingsTablePaginator',
  }
);

const mapDispatchToProps = (dispatch) => (
  {
    getCollection: () => {
      dispatch(getMappingsDealsByFilter());
    },
    onItemsPerPageChange: (perPage) => {
      dispatch(itemsPerPage(perPage.value));
      dispatch(getMappingsDealsByFilter());
    },
    onPageChange: (page) => {
      dispatch(paginate(page.selected));
      dispatch(getMappingsDealsByFilter());
    },
    reset: () => {
      dispatch(resetMappingsDealsCollection());
    },
    onSortChange: (sortBy, sortOrder) => {
      dispatch(sort({ sortBy, sortOrder }));
      dispatch(getMappingsDealsByFilter());
    },
  }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BaseMappingsPanel);
