import React from 'react';
import styles from './colorPicker.scss';

interface IProps {
  color: string,
}

/**
 * @deprecated This component has been chosen for migration to UIKit, meaning any further changes or updates
 * must be completed within the migration process.
 * @see https://www.notion.so/finsight-group/Component-Migration-Process-f4475950481d429ba0dc450d0bb0cb8b
 */
const SelectedColor = ({ color }: IProps) => (
  <div
    className={ styles.colorComponent }
    style={ { backgroundColor: `${ color }` } }
  />
);

export default SelectedColor;
