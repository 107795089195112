import { connect } from 'react-redux';
import selectors from '@/condor/application/actions/finsight/import/company/upload/selectors';
import {
  upload,
} from '@/condor/application/actions/finsight/import/company/upload/actions';
import UploadDropzoneOneFile from '@/condor/ui/components/Finsight/Import/modules/Upload/UploadDropzoneOneFile';

const mapStateToProps = (state) => (
  {
    uploadFileProcess: selectors.getUploadProcess(state),
    allowedFileTypes: '.csv, .xls, .xlsx',
    allowedFileText: 'Drag csv or xls file here or press button below.',
    downloadTemplateText: 'Download Company Bulk Upload Template',
    formFieldName: 'companyImport',
    uploadName: 'Company Data',
    maxSize: Infinity,
  }
);

const mapDispatchToProps = {
  onUpload: upload,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UploadDropzoneOneFile);
