import { SubProductType } from '@/condor/domain/vo/types';

export const DEFAULT_TRACE_PARAMS = {
  sortBy: 'tradeDate',
  sortOrder: 'desc',
  page: 1,
  perPage: 25,
  query: '',
  filters: {
    includedMapped: true,
    includedUnmapped: true,
    includedArchived: false,
    includedNotArchived: true,
    spdType: SubProductType.ABS,
  },
};

export const DEALS_REQUEST_DEFAULT_PARAMS = {
  filterType: 'searchDeals',
  perPage: 25,
  page: 1,
  sortBy: 'issuerTicker',
  sortOrder: 'desc',
};

export const DEFAULT_TRACE_DATA = {
  lastImportAt: null,
  trades: {
    collection: [],
    totalCount: 0,
  },
};
