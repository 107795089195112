import React from 'react';
import { useField, Field } from '@/ui/shared/components/Form/FinalForm';
import { Tooltip } from '@dealroadshow/uikit';

interface IProps extends Record<string, any> {
  name: string,
  children: (props: any) => React.ReactNode,
}

const FieldWithErrorTooltip: typeof Field = ({ children, name, ...props }: IProps) => {
  const fieldProps = useField(name);
  const { meta } = fieldProps;

  const showError = meta.error && meta.touched;

  return (
    <Tooltip content={ meta.error } disabled={ !showError } type={ Tooltip.CONSTANTS.ERROR } usePortal={ false }>
      { children({ ...fieldProps, ...props }) }
    </Tooltip>
  );
};

export default FieldWithErrorTooltip;
