import React from 'react';
import cn from 'classnames';
import { Field } from 'redux-form';
import { CONDOR_CONTACT_PROFILE_BASE_URL } from '@/condor/ui/common/constants';
import CompanyFieldContainer from '@/condor/ui/components/AccountsAndContacts/Contacts/CompanyFieldContainer';
import TitleField from '@/condor/ui/components/AccountsAndContacts/Contacts/TitleFieldContainer';
import PhoneInput from '@/ui/shared/components/Form/Input/Phone';
import { getStatus } from '@/condor/ui/components/AccountsAndContacts/Contacts/helpers';
import {
  getCellDisplayDateFromString,
  openProfileCellWrapper,
  statusCellWrapper,
} from '@/condor/ui/common/Layout/helpers';
import { APPLICATION_WRP_ID } from '@/ui/shared/components/Layout/ApplicationWrp/constants';
import { CONTENT_WRP_ID } from '@/ui/shared/components/Layout/ContentWrp/constants';
import linkStyles from '@/ui/shared/styles/links.scss';
import LastLoginAtCell from './LastLoginAtCell';
import styles from './contactsTable.scss';

/**
 * @param {Object} cellProps
 */
export const cellStatusCallback = (cellProps) => statusCellWrapper(
  getStatus({
    activated: cellProps.row.activated,
    registered: cellProps.row.registered,
    banned: cellProps.row.banned,
    emailVerified: cellProps.row.emailVerified,
  }),
);

/**
 * @param {Object} cellProps
 */
export const cellEmailCallback = (cellProps) => openProfileCellWrapper(
  {
    href: `${ CONDOR_CONTACT_PROFILE_BASE_URL }/${ cellProps.row.id }`,
    className: linkStyles.link,
    children: cellProps.row.email,
  },
);

/**
 * @param {Object} cellProps
 */
export const cellTitleCallback = (cellProps) => {
  if (!cellProps.row.title) {
    return '-';
  }

  return cellProps.row.title.name;
};

/**
 * @param {Object} cellProps
 */
export const cellAccountCallback = (cellProps) => {
  if (!cellProps.row.company) {
    return '-';
  }

  return (
    <span className={ cn({ [styles.verifiedCompany]: cellProps.row.company.verified }) }>
      { cellProps.row.company.name }
    </span>
  );
};

/**
 * @param {Object} cellProps
 */
export const cellCreatedAtCallback = (cellProps) => getCellDisplayDateFromString(cellProps.row.createdAt);

/**
 * @param {Object} cellProps
 */
export const cellLastLoginAtCallback = (cellProps) => (
  <LastLoginAtCell cellProps={ cellProps } />
);

/**
 * @param {Object} cellProps
 */
export const accountFieldRenderer = (cellProps) => (
  <CompanyFieldContainer
    isNarrow
    usePortal
    bodyElement={ document.getElementById(APPLICATION_WRP_ID) }
    scrollableParentElements={ [cellProps.tableRef.current, document.getElementById(CONTENT_WRP_ID)] }
    dataTest="company"
  />
);

export const phoneFieldRenderer = () => (
  <Field
    name="officePhone"
    placeholder=""
    isNarrow
    component={ PhoneInput }
  />
);

/**
 * @param {Object} cellProps
 */
export const titleFieldRenderer = (cellProps) => (
  <TitleField
    isNarrow
    usePortal
    bodyElement={ document.getElementById(APPLICATION_WRP_ID) }
    scrollableParentElements={ [cellProps.tableRef.current, document.getElementById(CONTENT_WRP_ID)] }
  />
);
