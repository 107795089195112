import MappingsRepository from '@/condor/infrastructure/repository/mappings/mappingsRepository';
import { getCollectionActions } from '@/Framework/State/Redux/collectionFactory';

import actionTypes from './actionTypes';
import selectors from './selectors';
import { getAccountId } from '../helpers';

export const {
  getCollection: getMappingsDealsCollection,
  itemsPerPage,
  paginate,
  sort,
  reset: resetMappingsDealsCollection,
} = getCollectionActions(
  actionTypes,
  (payload, dispatch, getState) => {
    const mappingsRepository: MappingsRepository = getState().container.get(MappingsRepository);
    return mappingsRepository.getDealsMappings(payload);
  },
);

export const getMappingsDealsByFilter = () => (dispatch, getState) => {
  const state = getState();

  const id = getAccountId(state);

  dispatch(getMappingsDealsCollection(
    {
      id,
      sortBy: selectors.getSortBy(state),
      sortOrder: selectors.getSortOrder(state),
      perPage: selectors.getPerPage(state),
      page: selectors.getPage(state),
    },
  ));
};
