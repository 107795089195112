import { useCallback, useEffect, useState } from 'react';
import { useEnhancedTaggingFiltersData } from './useEnhancedTaggingFiltersData';
import { useEnhancedTaggingFiltersStorage } from './useEnhancedTaggingFiltersStorage';
import { FiltersType } from './types';

const defaultFilters: FiltersType = {
  statuses: [],
  untaggedBy: [],
  taggedBy: [],
  transactionTypeIds: {},
  dateFrom: null,
  dateTo: null,
};

export const useEnhancedTaggingFilters = () => {
  const { fetchFilters, filtersData, isFiltersLoaded, isFiltersLoading } = useEnhancedTaggingFiltersData();
  const { getFiltersFromStorage, saveFiltersToStorage } = useEnhancedTaggingFiltersStorage();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [filters, setFilters] = useState(getFiltersFromStorage() ?? defaultFilters);

  useEffect(() => {
    saveFiltersToStorage(filters);
  }, [filters]);

  const openSidebar = () => {
    setIsSidebarOpen(true);

    if (!isFiltersLoaded && !isFiltersLoading) {
      fetchFilters();
    }
  };
  const closeSidebar = () => setIsSidebarOpen(false);

  const resetFilters = () => setFilters(defaultFilters);

  const getFiltersForTenant = useCallback(
    (tenant: string) => ({
      ...filters,
      transactionTypeIds: filters.transactionTypeIds[tenant] ?? [],
    }),
    [filters],
  );

  return {
    isSidebarOpen,
    openSidebar,
    closeSidebar,
    applyFilters: setFilters,
    resetFilters,
    defaultFilters,
    filters,
    getFiltersForTenant,
    filtersData,
    isFiltersLoaded,
    isFiltersLoading,
  };
};
