import React from 'react';
import TableWrapper from '@/ui/shared/components/Tables/TableWrapper';
import { Spinner, DataTable, dataTableStyles, Paginator } from '@dealroadshow/uikit';
import Filters from './Filters';
import columns from './columns';
import RequestsContextProvider, { useRequestsContext } from '@/condor/application/FinsightManager/Requests';
import {
  ApproveDenyFormContextProvider,
  useApproveDenyFormContext,
} from '@/condor/application/FinsightManager/ManagePlans/ApproveDenyFormContext';
import ApproveDenyModal from './ApproveDenyModal';

import commonStyles from '../common/common.scss';
import cardStyles from '@/ui/shared/styles/card.scss';

const Requests = () => {
  const {
    collection,
    paginate,
    setItemsPerPage,
    page,
    perPage,
    totalCount,
    sortOrder,
    sortBy,
    sort,
    isFetching,
  } = useRequestsContext();
  const {
    selectedSubmitData: { typeSubmit },
  } = useApproveDenyFormContext();
  return (
    <>
      <TableWrapper
        label="Subscription Requests"
        filterComponent={ <Filters /> }
        contentClassName={ commonStyles.tableWrapper }
      >
        <DataTable
          isFetching={ isFetching }
          loadingComponent={ Spinner }
          className={ dataTableStyles.isHoverable }
          columns={ columns }
          data={ collection }
          dataTest="finsightManagePlansRequestsTable"
          sortBy={ sortBy }
          sortOrder={ sortOrder }
          onSortChange={ sort }
        />
        <Paginator
          page={ page }
          onPageChange={ (page) => paginate(page.selected) }
          perPage={ perPage }
          onItemsPerPageChange={ (perPage) => setItemsPerPage(perPage.value) }
          totalCount={ totalCount }
          className={ cardStyles.cardInner }
          isItemsPerPageVisible
          dataTest="finsightManagePlansRequestsPaginator"
        />
      </TableWrapper>
      { typeSubmit && (
        <ApproveDenyModal />
      ) }
    </>
  );
};

export default () => (
  <RequestsContextProvider>
    <ApproveDenyFormContextProvider>
      <Requests />
    </ApproveDenyFormContextProvider>
  </RequestsContextProvider>
);
