import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import Properties from './Properties';
import selectors from '@/condor/application/actions/finsight/deal/selectors';
import Product from '@/finsight/domain/Product';
import Sector from '@/finsight/domain/Sector';
import { DEAL_PROFILE_FIELDS } from '../../constants';
import { CONDOR_DEAL_PROFILE_FORM_NAME } from '@/condor/ui/common/constants';
import { mapOptionToValue } from '@/Framework/dataHelpers/selectOptionMappers';

const formSelector = formValueSelector(CONDOR_DEAL_PROFILE_FORM_NAME);

const mapStateToProps = (state) => {
  const product = formSelector(state, DEAL_PROFILE_FIELDS.PRODUCT.name);
  const sectorSubsector = formSelector(state, DEAL_PROFILE_FIELDS.ABS_SECTOR_SUBSECTOR.name);

  return {
    isSectorCmbs: sectorSubsector ? Sector.isCmbsSectorId(sectorSubsector.sectorId) : false,
    isProductAbs: Product.isAbsProductId(mapOptionToValue(product)),
    ratingAgencies: selectors.getRatingAgencies(state),
  };
};

export default connect(
  mapStateToProps,
)(Properties);
