export const PROFILE_FORM = 'profileForm';

export enum PanelName {
  details = 'details',
  customization = 'customization',
  loginAndDisclaimer = 'loginAndDisclaimer',
}

export enum FormFieldName {
  // details panel
  NAME = 'name',
  RESTRICT_ACCESS = 'restrictAccess',
  URL = 'url',
  // customization panel
  HEADER_LOGO = 'headerLogo',
  HEADER_DESCRIPTION = 'headerDescription',
  DISPLAY_TABS = 'displayTabs',
  // login and disclaimer panel
  DISCLAIMER = 'disclaimer',
  DISCLAIMER_LOGO = 'disclaimerLogo',
  // disclaimer customization
  IS_CUSTOMIZED = 'isCustomized',
  BACKGROUND_IMAGE = 'backgroundImage',
  BACKGROUND_COLOR = 'backgroundColor',
  TILE_COLOR = 'tileColor',
  PRIMARY_TEXT_COLOR = 'primaryTextColor',
  SECONDARY_TEXT_COLOR = 'secondaryTextColor',
  LINK_COLOR = 'linkColor',
  INPUT_LABEL_COLOR = 'inputLabelColor',
  BUTTON_TEXT_COLOR = 'buttonTextColor',
  BUTTON_COLOR = 'buttonColor',
}

export const mapFieldToGroup: { [key in FormFieldName]: { group: PanelName } } = {
  // details panel
  [FormFieldName.NAME]: {
    group: PanelName.details,
  },
  [FormFieldName.RESTRICT_ACCESS]: {
    group: PanelName.details,
  },
  [FormFieldName.URL]: {
    group: PanelName.details,
  },
  // customization panel
  [FormFieldName.HEADER_LOGO]: {
    group: PanelName.customization,
  },
  [FormFieldName.HEADER_DESCRIPTION]: {
    group: PanelName.customization,
  },
  [FormFieldName.DISPLAY_TABS]: {
    group: PanelName.customization,
  },
  // login and disclaimer panel
  [FormFieldName.DISCLAIMER]: {
    group: PanelName.loginAndDisclaimer,
  },
  [FormFieldName.DISCLAIMER_LOGO]: {
    group: PanelName.loginAndDisclaimer,
  },
  // disclaimer customization
  [FormFieldName.IS_CUSTOMIZED]: {
    group: PanelName.loginAndDisclaimer,
  },
  [FormFieldName.BACKGROUND_IMAGE]: {
    group: PanelName.loginAndDisclaimer,
  },
  [FormFieldName.BACKGROUND_COLOR]: {
    group: PanelName.loginAndDisclaimer,
  },
  [FormFieldName.TILE_COLOR]: {
    group: PanelName.loginAndDisclaimer,
  },
  [FormFieldName.PRIMARY_TEXT_COLOR]: {
    group: PanelName.loginAndDisclaimer,
  },
  [FormFieldName.SECONDARY_TEXT_COLOR]: {
    group: PanelName.loginAndDisclaimer,
  },
  [FormFieldName.LINK_COLOR]: {
    group: PanelName.loginAndDisclaimer,
  },
  [FormFieldName.INPUT_LABEL_COLOR]: {
    group: PanelName.loginAndDisclaimer,
  },
  [FormFieldName.BUTTON_TEXT_COLOR]: {
    group: PanelName.loginAndDisclaimer,
  },
  [FormFieldName.BUTTON_COLOR]: {
    group: PanelName.loginAndDisclaimer,
  },
};
