import React from 'react';
import { Icon, IconType, ICellProps } from '@dealroadshow/uikit';
import { ISsoCertificate } from '@/users/domain/vo/sso/ISsoCertificate';
import { useUploadSsoCertificateContext } from '@/condor/application/Accounts/AccountProfile/AccountSSO/UploadSsoSertificate';

import styles from './styles.scss';

const CellFileName = ({ row, rowIndex, cellData }: ICellProps<ISsoCertificate>) => {
  const { setCertificateForView } = useUploadSsoCertificateContext();

  const onClick = () => {
    setCertificateForView({ number: rowIndex + 1, content: row.content });
  };

  return (
    <div className={ styles.cellFileName } onClick={ onClick }>
      <Icon className={ styles.cellFileNameIcon } type={ IconType.document } />
      <span className={ styles.cellFileNameContent }>{ cellData }</span>
    </div>
  );
};

export default CellFileName;
