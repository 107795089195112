export const APPROVED = 'approved';
export const PENDING = 'pending';
export const DENIED = 'denied';

class BondAccessType {
  /**
   * @return {Boolean}
   */
  static isApproved(data) {
    return APPROVED === data;
  }

  /**
   * @return {String}
   */
  static getApproved() {
    return APPROVED;
  }

  /**
   * @return {Boolean}
   */
  static isPending(data) {
    return PENDING === data;
  }

  /**
   * @return {String}
   */
  static getPending() {
    return PENDING;
  }

  /**
   * @return {Boolean}
   */
  static isDenied(data) {
    return DENIED === data;
  }

  /**
   * @return {String}
   */
  static getDenied() {
    return DENIED;
  }

  /**
   * @param {String} bondType
   * @return {Boolean}
   */
  static isValid(bondType) {
    return [
      APPROVED,
      PENDING,
      DENIED,
    ].includes(bondType);
  }
}

export default BondAccessType;
