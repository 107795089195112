export const debug = 'debug';
export const duplicate = 'duplicate';
export const error = 'error';
export const invalid = 'invalid';
export const deprecated = 'deprecated';
export const notTrusted = 'notTrusted';
export const notFound = 'notFound';
export const notFoundInParent = 'notFoundInParent';
export const mustLengthFail = 'mustLengthFail';
export const maxLengthFail = 'maxLengthFail';
export const rangeFail = 'rangeFail';
export const requiredColumn = 'requiredColumn';
export const dependentColumn = 'dependentColumn';
export const dateFail = 'dateFail';
export const numberFail = 'numberFail';
export const numberOrFloatFail = 'numberOrFloatFail';
export const floatFail = 'floatFail';
export const companyLead = 'companyLead';
export const companyParent = 'companyParent';
export const companySelect = 'companySelect';

export const isCompanyParent = (correctionType) => !!correctionType && correctionType === companyParent;
export const isCompanyLead = (correctionType) => !!correctionType && correctionType === companyLead;
