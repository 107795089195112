import CdnRepository from '@/condor/infrastructure/repository/admin/CdnRepository';
import * as actionTypes from './actionTypes';

/**
 * @param {Object} payload
 * @return {Object}
 */
function requestCdnListSuccess(payload) {
  return {
    type: actionTypes.REQUEST_CDN_LIST_SUCCESS,
    payload,
  };
}

/**
 * @param {String} error
 * @return {Object}
 */
function requestCdnListError(error) {
  return {
    type: actionTypes.REQUEST_CDN_LIST_ERROR,
    error,
  };
}

export function getCdnList() {
  return async (dispatch, getState) => {
    dispatch({ type: actionTypes.REQUEST_CDN_LIST });

    try {
      const cdnRepository = getState().container.get(CdnRepository);
      const response = await cdnRepository.list();
      const payload = response.map(
        (cdn) => ({
          value: cdn.key,
          label: cdn.value,
        }),
      );
      dispatch(requestCdnListSuccess(payload));
    } catch (err) {
      dispatch(requestCdnListError(err));
    }
  };
}
