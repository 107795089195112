import isEmpty from 'lodash/isEmpty';

import { fieldsData } from '@/condor/ui/components/Finsight/Companies/MigrateModal/validate';

/**
 * @param {Object} informationForMigration
 * @return {Object}
 */

const getFieldsConfig = (informationForMigration) => {
  if (isEmpty(informationForMigration)) {
    return {};
  }
  return {
    parentConfig: informationForMigration.deal.parent ? fieldsData.PARENT.active : fieldsData.PARENT.disabled,
    issuerConfig: informationForMigration.deal.issuer ? fieldsData.ISSUER.active : fieldsData.ISSUER.disabled,
    underwriterConfig: informationForMigration.deal.underwriter
      ? fieldsData.UNDERWRITER.active
      : fieldsData.UNDERWRITER.disabled,
    dealerConfig: informationForMigration.deal.dealer ? fieldsData.DEALER.active : fieldsData.DEALER.disabled,
    researchIssuerConfig: informationForMigration.research.issuer
      ? fieldsData.RESEARCH_ISSUER.active
      : fieldsData.RESEARCH_ISSUER.disabled,
    researchParentConfig: informationForMigration.research.parent
      ? fieldsData.RESEARCH_PARENT.active
      : fieldsData.RESEARCH_PARENT.disabled,
  };
};

export default getFieldsConfig;
