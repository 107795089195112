import { connect } from 'react-redux';
import {
  getCondorFinsightCompanyDealsByFilter,
  paginate,
  itemsPerPage,
} from '@/condor/application/actions/finsight/company/deals/collection/actions';
import { Paginator } from '@dealroadshow/uikit';
import selectors from '@/condor/application/actions/finsight/company/deals/collection/selectors';

const mapStateToProps = (state) => (
  {
    totalCount: selectors.getTotalCount(state),
    page: selectors.getPage(state),
    perPage: selectors.getPerPage(state),
  }
);

const mapDispatchToProps = (dispatch) => (
  {
    onItemsPerPageChange: (perPage) => {
      dispatch(itemsPerPage(perPage.value));
      dispatch(getCondorFinsightCompanyDealsByFilter());
    },
    onPageChange: (page) => {
      dispatch(paginate(page.selected));
      dispatch(getCondorFinsightCompanyDealsByFilter());
    },
  }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Paginator);
