import { useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import CondorRepository from '@/dataroom/infrastructure/repository/CondorRepository';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import NotificationManager from '@/ui/shared/components/Notification/NotificationManager';
import getMessage from '@/Framework/Message/getMessage';
import { messageCodes } from '@/Framework/Message/messages';

export default function useEditDataroom() {
  const { container } = useDIContext();
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const editDataroom = async (dataroomId: number, formData: { displayName: string }) => {
    setIsFetching(true);
    try {
      const payload = {
        dataroomId,
        displayName: formData.displayName,
      };

      const condorRepository = container.get<CondorRepository>(CondorRepository);
      await condorRepository.editDataroomName(payload);

      NotificationManager.success(
        getMessage(messageCodes.DATAROOM_CONDOR_EDIT_SUCCESS, { name: formData.displayName }),
      );
    } catch (error) {
      container.get(DataroomErrorHandler).handleError(error);
    } finally {
      setIsFetching(false);
    }
  };

  return {
    isFetching,
    editDataroom,
  };
}
