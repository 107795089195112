import { connect } from 'react-redux';
import {
  getContactsByFilter,
  itemsPerPage,
  paginate,
} from '@/condor/application/actions/contacts/collection/actions';
import {
  resetContactItem,
} from '@/condor/application/actions/contacts/item/actions';
import selectors from '@/condor/application/actions/contacts/collection/selectors';
import { Paginator } from '@dealroadshow/uikit';

const mapStateToProps = (state) => (
  {
    totalCount: selectors.getTotalCount(state),
    page: selectors.getPage(state),
    perPage: selectors.getPerPage(state),
  }
);

const mapDispatchToProps = (dispatch) => (
  {
    onItemsPerPageChange: (perPage) => {
      dispatch(itemsPerPage(perPage.value));
      dispatch(getContactsByFilter());
      dispatch(resetContactItem());
    },
    onPageChange: (page) => {
      dispatch(paginate(page.selected));
      dispatch(getContactsByFilter());
      dispatch(resetContactItem());
    },
  }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Paginator);
