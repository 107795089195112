import cn from 'classnames';
import { IColumn } from '@dealroadshow/uikit';
import {
  NameCell,
  StatusCell,
  ProfilesCell,
  RoadshowCell,
  CountryCell,
  RegionCell,
  ExpirationDate,
  ActionsCell,
} from './cellCallbacks';

import alignStyles from '@/ui/shared/styles/helpers/align.scss';
import styles from './eventsList.scss';

export const columns: IColumn[] = [
  {
    name: 'status',
    title: 'Status',
    className: alignStyles.alignCenter,
    width: 77,
    cellCallback: StatusCell,
  },
  {
    name: 'name',
    title: 'Event name',
    className: styles.name,
    width: 330,
    cellCallback: NameCell,
  },
  {
    name: 'profiles',
    title: 'Profile / Display tab',
    cellCallback: ProfilesCell,
  },
  {
    name: 'roadshow',
    title: 'DRS',
    width: 77,
    className: alignStyles.alignCenter,
    cellCallback: RoadshowCell,
  },
  {
    name: 'country',
    title: 'Country',
    width: 124,
    cellCallback: CountryCell,
  },
  {
    name: 'region',
    title: 'Region',
    width: 70,
    cellCallback: RegionCell,
  },
  {
    name: 'expirationDate',
    title: 'Exp date',
    cellCallback: ExpirationDate,
    className: cn(alignStyles.alignRight, styles.hideOnHover),
    width: 145,
  },
  {
    name: 'actionButtons',
    className: styles.actionButtonsCell,
    cellCallback: ActionsCell,
  },
];
