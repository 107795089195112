const inventorySelector = (state) => state.condor.finsight.import.bond.inventory;

export default {
  getSortBy: (state) => inventorySelector(state).sortBy,
  getSortOrder: (state) => inventorySelector(state).sortOrder,
  getCollectionData: (state) => inventorySelector(state).data,
  isFetching: (state) => inventorySelector(state).isFetching,
  getTotalCount: (state) => inventorySelector(state).totalCount,
  getPage: (state) => inventorySelector(state).page,
  getPerPage: (state) => inventorySelector(state).perPage,
  getInventoryLastImportDate: (state) => inventorySelector(state).lastImportAt,
  getSubscriptionId: (state) => inventorySelector(state).subscriptions.subscriptionId,
  getIsSubscribed: (state) => inventorySelector(state).subscriptions.isSubscribed,
  getIsSubscriptionsFetching: (state) => inventorySelector(state).subscriptions.isFetching,
};
