import React from 'react';
import { Icon, IconType, Tooltip } from '@dealroadshow/uikit';
import Country from '@/dealroadshow/domain/vo/Country';
import { IUserProfileFormData } from '@/users/domain/vo/IUserProfileFormData';

import styles from './common.scss';

export const getJurisdictionLabel = () => (
  <span>Jurisdiction
    <span className={ styles.explanationIconWrapper }>
      <Tooltip
        content="Please identify your country of residencefor data protection regulations."
        className={ styles.tooltip }
        usePortal={ false }
      >
        <Icon type={ IconType.question } className={ styles.explanationIcon } />
      </Tooltip>
    </span>
  </span>
);

export const getLabelWithOptionalMark = (text: string) => (
  <span>{ text }<span className={ styles.optionalLabel }> (optional)</span></span>
);

export const mapCountriesToOptions = (countriesList: [Country?]) => (countriesList.map((country) => ({
      label: country.name,
      value: country.id,
      countryCode: country.code,
    }),
  )
);

export const prepareFormData = ({
  jurisdiction,
  officePhone,
  personalPhone,
  bloombergId,
  ...formData
}: IUserProfileFormData) => {
  const jurisdictionCountryId = typeof jurisdiction === 'string'
    ? jurisdiction
    : jurisdiction.value;
  const workPhone = officePhone?.value || '';
  const mobilePhone = personalPhone?.value || '';

  return {
    ...formData,
    bloombergEmail: bloombergId || null,
    titleId: formData.titleId.value,
    workPhone: workPhone.length > 6 ? workPhone : '',
    personalPhone: mobilePhone.length > 6 ? mobilePhone : '',
    jurisdictionCountryId,
  };
};
