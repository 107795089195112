import * as actionTypes from './actionTypes';
import SectorRepository from '@/dealroadshow/infrastructure/repository/sector/sectorRepository';

export function getAbsSectorList() {
  return async (dispatch, getState) => {
    dispatch(requestAbsSectorList());
    try {
      const sectorRepository = getState().container.get(SectorRepository);
      const payload = await sectorRepository.list();
      dispatch(requestAbsSectorListSuccess(payload));
    } catch (error) {
      dispatch(requestAbsSectorListError(error));
      throw error;
    }
  };
}

/**
 * @return {Object}
 */
function requestAbsSectorList() {
  return {
    type: actionTypes.GET_ABS_SECTOR_HIERARCHY_LIST,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function requestAbsSectorListSuccess(payload) {
  return {
    type: actionTypes.GET_ABS_SECTOR_HIERARCHY_LIST_SUCCESS,
    payload,
  };
}

/**
 * @param {String} error
 * @return {Object}
 */
function requestAbsSectorListError(error) {
  return {
    type: actionTypes.GET_ABS_SECTOR_HIERARCHY_LIST_ERROR,
    error,
  };
}
