import { useState, useCallback } from 'react';

const useDealModalsStates = () => {
  const [isSubmitModalVisible, setIsSubmitModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const openSubmitModal = useCallback(() => setIsSubmitModalVisible(true), []);
  const closeSubmitModal = useCallback(() => setIsSubmitModalVisible(false), []);

  const openDeleteModal = useCallback(() => setIsDeleteModalVisible(true), []);
  const closeDeleteModal = useCallback(() => setIsDeleteModalVisible(false), []);

  return {
    isSubmitModalVisible,
    openSubmitModal,
    closeSubmitModal,

    isDeleteModalVisible,
    openDeleteModal,
    closeDeleteModal,
  };
};

export default useDealModalsStates;
