import FilingRepository from '@/finsight/infrastructure/repository/FilingRepository';
import { getCollectionActions } from '@/Framework/State/Redux/collectionFactory';
import createAction from '@/Framework/State/Redux/createAction';

import actionTypes from './actionTypes';
import selectors from './selectors';
import { createAsyncRequest } from '@/Framework/State/Redux/createAsyncRequest';

export const {
  getCollection: getFilingsCollection,
  sort,
  itemsPerPage,
  paginate,
  setSearchQuery,
  reset: resetFilingsCollection,
} = getCollectionActions(
  actionTypes,
  (payload, dispatch, getState) => {
    const filingRepository = getState().container.get(FilingRepository);
    return filingRepository.getAllFilings(payload);
  },
);

/**
 * @param {boolean} payload
 */
export const setDealMapping = (payload) => createAction(
  actionTypes.SET_DEAL_MAPPING,
  payload,
);

/**
 * @param {object} payload
 */
export const setIsDisableModalVisible = (payload) => createAction(
  actionTypes.SET_IS_DISABLE_MODAL_VISIBLE,
  payload,
);

/**
 * @param {object} payload
 */
export const setIsSendEmailModalVisible = (payload) => createAction(
  actionTypes.SET_IS_SEND_EMAIL_MODAL_VISIBLE,
  payload,
);

/**
 * @param {object} payload
 */
export const setIsResendEmailModalVisible = (payload) => createAction(
  actionTypes.SET_IS_RESEND_EMAIL_MODAL_VISIBLE,
  payload,
);

export const hideModal = () => createAction(actionTypes.HIDE_MODAL);

export const getFilingsByFilter = (types) => (dispatch, getState) => {
  const state = getState();
  const payload = {
    filters: {
      search: selectors.getSearchQuery(state) || null,
      types,
      dealMapping: selectors.getDealMapping(state),
    },
    perPage: selectors.getPerPage(state),
    page: selectors.getPage(state),
    sortBy: selectors.getSortBy(state),
    sortOrder: selectors.getSortOrder(state),
  };

  dispatch(getFilingsCollection(payload));
};

/**
 * @param {string} status
 */
export const updateStatus = (status) => async (dispatch, getState) => {
  const state = getState();
  const selectedFilingId = selectors.getSelectedFiling(state).id;

  const filingRepository = getState().container.get(FilingRepository);

  dispatch(createAsyncRequest(
    {
      payload: {
        filingId: selectedFilingId,
        status,
      },
      method: filingRepository.updateStatus,
      types: [
        actionTypes.UPDATE_STATUS,
        actionTypes.UPDATE_STATUS_SUCCESS,
        actionTypes.UPDATE_STATUS_ERROR,
      ],
      callbacks: {
        responseHandler: (response) => selectors.getCollectionData(state).map((filing) => {
          if (filing.id === response.id) {
            return response;
          }
          return filing;
        }),
        onSuccess: ({ dispatch }) => {
          dispatch(hideModal());
        },
      },
    },
  ));
};
