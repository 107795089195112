import { connect } from 'react-redux';

import Abs15GTable from './Abs15GTable';

import {
  getFilingsByFilter,
  sort,
  paginate,
  resetFilingsCollection,
} from '@/condor/application/actions/finsight/filings/collection/actions';
import { FILING_TYPES_ABS_15G } from '@/finsight/domain/Filing';
import selectors from '@/condor/application/actions/finsight/filings/collection/selectors';

const mapStateToProps = (state) => (
  {
    data: selectors.getCollectionData(state),
    isFetching: selectors.isFetching(state),
    sortBy: selectors.getSortBy(state),
    sortOrder: selectors.getSortOrder(state),
  }
);

const mapDispatchToProps = (dispatch) => ({
  init: () => dispatch(getFilingsByFilter(FILING_TYPES_ABS_15G)),
  reset: () => dispatch(resetFilingsCollection()),
  onSortChange: (sortBy, sortOrder) => {
    dispatch(sort({ sortBy, sortOrder }));
    dispatch(paginate(1));
    dispatch(getFilingsByFilter(FILING_TYPES_ABS_15G));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Abs15GTable);
