import { useCallback } from 'react';
import { useParams } from 'react-router';

import { useDIContext } from '@/Framework/DI/DIContext';
import EvercallAdminRepository from '@/evercall/infrastructure/repository/EvercallAdminRepository';
import { IAuditTrailItem, IAuditTrailPayload } from '@/evercall/domain/vo/AuditTrail';
import useFetchCollection, { IFetchCallback } from '@/Framework/State/useFetchCollection';

export default () => {
  const { callId } = useParams();
  const { container } = useDIContext();
  const evercallAdminRepository = container.get<EvercallAdminRepository>(EvercallAdminRepository);

  const fetcher: IFetchCallback<IAuditTrailItem, IAuditTrailPayload> = useCallback(
    evercallAdminRepository.getAuditTrail,
    [],
  );

  const activity = useFetchCollection(fetcher, { callId }, { sortBy: 'occurredAt', sortOrder: 'desc' });

  return {
    activity,
  };
};
