import { Dependencies } from 'constitute';

import BaseFinsightRepository from '@/finsight/infrastructure/repository/BaseFinsightRepository';
import FinsightRpcDispatcher from '@/finsight/application/DI/Rpc/finsight-web/HttpDispatcher';
import Request from '@/Framework/api/Rpc/Request';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import {
  ArchiveByOptions,
  ITraceFilterByHashResponse,
  ITraceFilterResponse,
  ITraceIssuersResponse,
  ITracePayload,
  ITraceResponse,
} from '@/condor/domain/vo/types';
import { IMarketTradesPayload } from '@/finsight/domain/vo/Trace/IMarketTradesPayload';
import { ITraceFilters } from '@/finsight/domain/vo/TraceFilters';
import { ITraceChartItem, ITraceChartPayload } from '@/finsight/domain/vo/Trace/ITraceChartData';

@Dependencies(FinsightRpcDispatcher)
class TraceRepository extends BaseFinsightRepository {
  constructor(protected rpc: typeof FinsightRpcDispatcher) {
    super(rpc);
  }

  getTradesRequest = async (payload: ITracePayload): Promise<ITraceResponse> => this.abortableCall(new Request('finsight_web.bond.trace.get_trades', payload));

  getDealsRequest = async (payload) => this.abortableCall(new Request('finsight_web.deal_transaction.deal.get_deals', payload));

  mapTradeRequest = async (payload) => this.abortableCall(new Request('finsight_web.bond.trace.map_trade', payload));

  archiveTrade = async (payload: { cusip: string | null, archiveBy: ArchiveByOptions, issuerName: string | null }) => {
    let response = await this.rpc.call<RpcSuccess>(new Request('finsight_web.bond.trace.archive_trace', payload));
    return response.getResult().payload;
  };

  restoreTrade = async (payload: { cusip: string }) => this.abortableCall(new Request('finsight_web.bond.trace.restore_trace', payload));

  getMarketTrades = async (payload: IMarketTradesPayload) => this.abortableCall(
      new Request('finsight_web.market.bond.trace.get_trades_tile', payload),
  );

  getTraceIssuersFilter = async (filter: ITraceFilters, search: string): Promise<ITraceIssuersResponse> => {
    const payload = { filter, search };
    return this.abortableCall(
        new Request('finsight_web.market.bond.trace.filter.get_issuers', payload),
    );
  };

  getTraceFilter = async (filter: ITraceFilters): Promise<ITraceFilterResponse> => {
    const payload = { filter };
    return this.abortableCall(
        new Request('finsight_web.market.bond.trace.filter.get_boundary_filter', payload),
    );
  };

  getTraceFilterByHash = async (hash: string): Promise<ITraceFilterByHashResponse> => {
    const payload = { hash };
    return this.abortableCall(
        new Request('finsight_web.bond.trace.filter.get_by_hash', payload),
    );
  };

  getTraceChartData = async (payload: ITraceChartPayload): Promise<ITraceChartItem[]> => {
    return this.abortableCall(
        new Request('finsight_web.market.bond.trace.get_chart_data', payload),
    );
  };
}

export default TraceRepository;
