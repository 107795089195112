import React, { createContext, useContext } from 'react';
import { useTraceData } from './useTraceData';

type TraceContextType = ReturnType<typeof useTraceData>;

export const TraceContext = createContext<TraceContextType>(null);

export const useTraceContext = () => {
  const context = useContext(TraceContext);
  if (!context) {
    throw new Error('useTraceContext must be used within a TraceContextProvider');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

const TraceContextProvider = ({ children }: IProps) => (
  <TraceContext.Provider value={ useTraceData() }>
    { children }
  </TraceContext.Provider>
);

export default TraceContextProvider;
