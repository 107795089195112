export const cellTimeCheck = (fields, rowIndex): [number, string] => {
  let time = parseInt(fields.get(rowIndex).time);
  let format;
  if (time > 60 * 60 * 24) {
    format = 'dd [day] hh:mm:ss';
  } else if (time > 60 * 60) {
    format = 'hh:mm:ss';
  } else {
    format = 'mm:ss';
  }
  return [time, format];
};
