import { APPROVED, DENIED } from '@/finsight/domain/BondAccessType';

export const CONTACT_REQUEST_SUBMIT_TYPE = {
  GRANT: 'Grant',
  DENY: 'Deny',
};

export enum RequestSubmitType {
  APPROVE = 'Approve',
  DENY = 'Deny',
  UPGRADE = 'Upgrade',
}

export const CONTACT_REQUEST_DEFAULT_SUBJECT = {
  [CONTACT_REQUEST_SUBMIT_TYPE.GRANT]: 'Access Granted',
  [CONTACT_REQUEST_SUBMIT_TYPE.DENY]: 'Access Denied',
};

export const REQUEST_DEFAULT_SUBJECT = {
  [RequestSubmitType.APPROVE]: 'Your Finsight Market Data subscription has been approved',
  [RequestSubmitType.DENY]: 'Your Finsight Market Data subscription has been denied',
  [RequestSubmitType.UPGRADE]: 'Your Finsight Market Data subscription has been upgraded',
};

export const CONTACT_REQUEST_ACCESS_TYPE = {
  [CONTACT_REQUEST_SUBMIT_TYPE.GRANT]: APPROVED,
  [CONTACT_REQUEST_SUBMIT_TYPE.DENY]: DENIED,
};

export const REQUEST_ACCESS_TYPE = {
  [RequestSubmitType.APPROVE]: APPROVED,
  [RequestSubmitType.DENY]: DENIED,
};
