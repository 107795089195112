import { validateRequiredField } from '@/ui/shared/validators/validateRequiredField';
import { validateMinLength } from '@/ui/shared/validators/validateMinLength';
import { validateMaxLength } from '@/ui/shared/validators/validateMaxLength';
import { validateAlphanumeric } from '@/ui/shared/validators/validateAlphanumeric';
import { groupValidators } from '@/ui/shared/validators/helpers';
import { IFormValues } from '../../interfaces';
import { FormFieldName } from '../../constants';

export default (values: IFormValues) => {
  return groupValidators(
    validateRequiredField({
      fieldName: FormFieldName.NAME,
      fieldCaption: 'Profile name',
      value: values.name,
    }),
    validateMinLength({
      fieldName: FormFieldName.NAME,
      fieldCaption: 'Profile name',
      value: values.name,
      length: 3,
    }),
    validateMaxLength({
      fieldName: FormFieldName.NAME,
      fieldCaption: 'Profile name',
      value: values.name,
      length: 128,
    }),
    validateRequiredField({
      fieldName: FormFieldName.URL,
      fieldCaption: 'Profile URL',
      value: values.url,
    }),
    validateMinLength({
      fieldName: FormFieldName.URL,
      fieldCaption: 'Profile URL',
      value: values.url,
      length: 3,
    }),
    validateMaxLength({
      fieldName: FormFieldName.URL,
      fieldCaption: 'Profile URL',
      value: values.url,
      length: 128,
    }),
    validateAlphanumeric({
      fieldName: FormFieldName.URL,
      fieldCaption: 'Profile URL',
      value: values.url,
    }),
    validateRequiredField({
      fieldName: FormFieldName.RESTRICT_ACCESS,
      fieldCaption: 'Restrict Access',
      value: values.restrictAccess,
    }),
  );
};
