import cn from 'classnames';
import { cellStartedAtCallback, teamCellCallback } from './cellCallbacks';
import {
  contactColumn,
  accountColumn,
  titleColumn,
  phoneColumn,
  planColumn,
} from '../common/columns';

import styles from '../common/common.scss';
import alignStyles from '@/ui/shared/styles/helpers/align.scss';

const columns = [
  {
    ...contactColumn,
  },
  {
    ...accountColumn,
  },
  {
    name: 'team',
    title: 'Team',
    minWidth: 130,
    cellCallback: teamCellCallback,
    sortable: true,
  },
  {
    ...titleColumn,
  },
  {
    ...phoneColumn,
  },
  {
    name: 'plan',
    title: 'Plan',
    ...planColumn,
  },
  {
    name: 'startedAt',
    title: 'Started At',
    className: cn(styles.dateCol, alignStyles.alignRight),
    cellCallback: cellStartedAtCallback,
    sortable: true,
  },
];

export default columns;
