import { toSlug } from '@/Framework/url/helpers/toSlug';
import {
  prepareSubscriptionsData,
  prepareSubscriptionsFormValues,
} from '@/finsight/application/actions/subscriptions/helpers';

/**
 * @param {Object} payload
 * @return {String}
 */
export const getUpdateSuccessText = (payload) => `Changes saved successfully for ${ payload.email }`;

/**
 * @param {Array} emails
 * @return {string}
 */
export const getErrorText = (emails) => `Unprocessed emails: ${ emails.join(', ') }`;

/**
 * @param {Array} response
 * @returns {Array}
 */
export const prepareAllSubscriptionsData = (response) => response.map((groupItem) => ({
  ...groupItem,
  subgroups: groupItem.subgroups.map((subGroup) => ({
    ...subGroup,
    fieldName: toSlug(subGroup.name).replace(/-([a-z])/g, (item) => item[1].toUpperCase()),
    hasAccess: true,
    subscriptions: subGroup.subscriptions,
  })),
}));

/**
 * @param {Array} response
 * @return {Object}
 */
export const allSubscriptionsResponseHandler = (response) => {
  const subscriptionsData = prepareAllSubscriptionsData(response);
  return { subscriptionsData, subscriptionsFormValues: {} };
};

/**
 * @param {Object} response
 * @return {Object}
 */
export const userSubscriptionsResponseHandler = (response) => {
  const subscriptionsData = prepareSubscriptionsData(response);
  const subscriptionsFormValues = prepareSubscriptionsFormValues(response);
  return { subscriptionsData, subscriptionsFormValues };
};
