import { connect } from 'react-redux';
import {
  getCondorFinsightCompany,
  submitCondorFinsightCompanyForm,
  resetCondorFinsightCompanyItem,
  getCompanyInformationForMigration,
} from '@/condor/application/actions/finsight/company/item/actions';
import {
  getCondorFinsightCompanyDealsByFilter,
  resetCondorFinsightCompanyDeals,
} from '@/condor/application/actions/finsight/company/deals/collection/actions';
import {
  getCondorFinsightIndustries,
  resetCondorFinsightIndustries,
} from '@/condor/application/actions/finsight/industry/collection/actions';
import { resetFilteredCompaniesOptions } from '@/condor/application/actions/finsight/company/filteredCompaniesOptionsByFields/actions';
import CompanyProfile from './CompanyProfile';
import selectors from '@/condor/application/actions/finsight/company/item/selectors';
import industryCollectionSelectors from '@/condor/application/actions/finsight/industry/collection/selectors';

const mapStateToProps = (state) => ({
  isSpinnerVisible: (
    selectors.isUpdating(state)
    || selectors.isFetching(state)
    || selectors.isInformationForMigrationFetching(state)
    || industryCollectionSelectors.isFetching(state)
  ),
  item: selectors.getItemData(state),
  industries: industryCollectionSelectors.getCollectionData(state),
  isMigrateModalVisible: selectors.isMigrateModalVisible(state),
});

const mapDispatchToProps = (dispatch, ownProps) => (
  {
    init: () => {
      if (!ownProps.location.pathname.includes('create')) {
        dispatch(getCondorFinsightCompany({ id: ownProps.match.params.id }));
        dispatch(getCompanyInformationForMigration({ id: ownProps.match.params.id }));
        dispatch(getCondorFinsightCompanyDealsByFilter(ownProps.match.params.id));
      }
      dispatch(getCondorFinsightIndustries());
    },
    reset: () => {
      dispatch(resetCondorFinsightCompanyItem());
      dispatch(resetCondorFinsightIndustries());
      dispatch(resetCondorFinsightCompanyDeals());
      dispatch(resetFilteredCompaniesOptions());
    },
    onSubmit: (values) => dispatch(submitCondorFinsightCompanyForm(values)),
  }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompanyProfile);
