import React, { useMemo } from 'react';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import FinalForm, { Field } from '@/ui/shared/components/Form/FinalForm';
import UserPanel from './UserPanel';
import PlansPanel from '../../common/PlansPanel';
import TeamPanel from '../../common/TeamPanel';
import Footer from './Footer';
import {
  useManageSubscriptionFormContext,
} from '@/condor/application/FinsightManager/ManagePlans/ManageSubscriptionFormContext';
import { UserPlanType } from '@/users/domain/UserPlan';
import InputTags from '@/ui/shared/components/Form/InputTags';
import Input from '@/ui/shared/components/Form/Input/FinalFormInput';
import RichTextInput from '@/ui/shared/components/Form/RichTextInput';
import { PreviewText } from '@/condor/ui/components/FinsightManager/ManagePlans/common/PreviewText';
import { RequestSubmitType } from '@/condor/application/FinsightManager/ManagePlans/constants';
import NoNotificationOverlay from '@/condor/ui/components/FinsightManager/ManagePlans/common/NoNotificationOverlay';
import { Modal, Spinner, Tooltip, Checkbox } from '@dealroadshow/uikit';
import { TFinalFormRenderProp } from '@/ui/shared/components/Form/FinalForm/interfaces';

import styles from './styles.scss';
import commonStyles from '../../common/common.scss';

const ManageSubscriptionModal = () => {
  const {
    isOpen,
    isSubmitting,
    onSubmit,
    isEmailFormVisible,
    data: { teams, plan, email, firstName, features },
    isNotificationEnabled,
    reset,
  } = useManageSubscriptionFormContext();

  const initialValues = useMemo(() => {
    const initialData = {
      teamName: [],
      userPlan: plan,
      isExportEnabled: features?.length && features?.indexOf('xlsExport') >= 0,
    };

    if (!isEmpty(teams)) {
      initialData.teamName = teams.map((team) => ({
        label: team.name,
        value: team.id,
      }));
    }

    return initialData;
  }, [teams, plan, features]);

  const renderForm: TFinalFormRenderProp = ({ values, form }) => {
    const onClose = () => {
      form.reset();
      reset();
    };

    const onChange = (e) => {
      form.change('isExportEnabled', e.target.checked);
    };

    return (
      <Modal
        title="Manage Subscription"
        isVisible={ isOpen }
        dataTest="manageSubscriptionModal"
        className={ cn(styles.modalContainer, {
          [styles.modalContainerWide]: isEmailFormVisible,
        }) }
        handleCloseClick={ onClose }
        footer={ <Footer onClose={ onClose } /> }
      >
        { !isEmailFormVisible && (
          <>
            <UserPanel />
            <PlansPanel
              plan={ plan }
              name="userPlan"
              dataTest="manageSubscriptionFormUserPlan"
              label="plans"
            />
            <div className={ styles.featuresTitle }>Features</div>
            <Tooltip
              disabled={ values.userPlan === UserPlanType.PRO }
              content="Contacts must be subscribed to the Pro plan to activate Export to XLS."
              maxWidth={ 248 }
            >
              <Checkbox
                dataTest="manageSubscriptionFormExportToXLS"
                label="Export to XLS"
                disabled={ values.userPlan !== UserPlanType.PRO }
                checked={ values.userPlan !== UserPlanType.PRO ? false : values.isExportEnabled }
                onChange={ onChange }
              />
            </Tooltip>
            <TeamPanel
              labelText="assign team"
              name="teamName"
              dataTest="manageSubscriptionTeamName"
              isMulti
            />
          </>
        ) }
        { isEmailFormVisible && (
          <>
            <NoNotificationOverlay isNotificationEnabled={ isNotificationEnabled } />
            <div className={ commonStyles.fieldWrapper }>
              <label className={ commonStyles.fromFieldLabel }>From:</label>
              <div className={ commonStyles.fromField }>
                Finsight Support<span className={ commonStyles.fromFieldEmail }>{ ' <support@finsight.com>' }</span>
              </div>
            </div>
            <div className={ commonStyles.fieldWrapper }>
              <label className={ commonStyles.topFieldLabel }>To:</label>
              <InputTags
                wrapperClassName={ commonStyles.topInput }
                tags={ [{ label: email, value: email, isValid: true }] }
                isDisabled
                dataTest="manageUserPlanRequestFormEmailField"
              />
            </div>
            <div className={ commonStyles.fieldWrapper }>
              <label className={ commonStyles.topFieldLabel }>Subject:</label>
              <Field
                name="subject"
                formFieldClassName={ commonStyles.topInput }
                component={ Input }
                initialValue="Welcome to Finsight.com! Your subscription plan has been updated."
                dataTest="manageUserPlanRequestFormSubjectField"
              />
            </div>
            <Field
              isFocused
              name="emailText"
              component={ RichTextInput }
              containerCls={ commonStyles.editor }
              /* Please do not do this. Instead, use Textarea and apply 'white-space: pre-line' to the div where
               * the submitted text is being inserted via dangerouslySetInnerHTML tag. */
              toolbarComponent={ () => null }
              placeholder="Type text here..."
              label="custom message text:"
              dataTest="manageUserPlanRequestFormMessageField"
            />
            <PreviewText
              name={ firstName }
              type={ RequestSubmitType.UPGRADE }
              emailText={ values.emailText === '<p></p>' ? '' : values.emailText }
              planName={ values.userPlan }
            />
          </>
        ) }
        { isSubmitting && <Spinner isAbsolute overlay /> }
      </Modal>
    );
  };

  return (
    <FinalForm
      name="manageSubscriptionForm"
      dataTest="manageSubscriptionForm"
      render={ renderForm }
      onSubmit={ onSubmit }
      initialValues={ initialValues }
    />
  );
};

export default ManageSubscriptionModal;
