import React, { Component } from 'react';
import { Field } from 'redux-form';
import { Subindustry } from '@/ui/shared/components/Form/Fields/Subindustry';
import { Subsector } from '@/ui/shared/components/Form/Fields/Subsector';

interface IProps {
  industryList: any[],
  absSectorList: any[],
  getIndustryList: () => void,
  getAbsSectorList: () => void,
}

class RoadshowMappingForm extends Component<IProps, any> {
  componentDidMount() {
    this.props.getIndustryList();
    this.props.getAbsSectorList();
  }

  render() {
    return (
      <>
        <Field
          name="subindustryId"
          label="Industry / Subindustry"
          placeholder="Search"
          collection={ this.props.industryList }
          component={ Subindustry }
          simpleValue
          dataTest="subindustryIdReduxFormSelect"
        />
        <Field
          name="subsectorId"
          label="ABS Sector / Subsector"
          placeholder="Search"
          collection={ this.props.absSectorList }
          component={ Subsector }
          simpleValue
          isNarrow
          dataTest="condorSubsectorIdFormSelect"
        />
      </>
    );
  }
}

export default RoadshowMappingForm;
