import { cellContactCallback, cellUserPlanCallback } from './cellCallbacks';
import { commonCellCallback } from '@/condor/ui/common/Layout/helpers';

export const contactColumn = {
  name: 'contact',
  title: 'Contact',
  width: 233,
  minWidth: 200,
  cellCallback: cellContactCallback,
  sortable: true,
};

export const accountColumn = {
  name: 'account',
  title: 'Account',
  width: 233,
  minWidth: 200,
  cellCallback: commonCellCallback,
  sortable: true,
};

export const titleColumn = {
  name: 'title',
  title: 'Title',
  width: 220,
  minWidth: 220,
  cellCallback: commonCellCallback,
  sortable: true,
};

export const phoneColumn = {
  name: 'phone',
  width: 190,
  minWidth: 190,
  title: 'Phone',
  cellCallback: commonCellCallback,
  sortable: true,
};

export const planColumn = {
  minWidth: 84,
  width: 84,
  cellCallback: cellUserPlanCallback,
  sortable: true,
};
