import React from 'react';
import { Icon, IconType } from '@dealroadshow/uikit';

import styles from '../traceTable.scss';

export const ExpandCollapseCell = (cellProps) => {
  const { isExpanded } = cellProps;

  return (
    <Icon
      className={ styles.expandCollapse }
      type={ isExpanded ? IconType.collapse : IconType.expand }
    />
  );
};
