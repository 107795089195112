import { connect } from 'react-redux';
import {
  initDealProfile,
  reset,
  updateDeal,
  createDeal,
  createDealWithMapToFiling,
  updateDealWithMapToFiling,
} from '@/condor/application/actions/finsight/deal/actions';
import { unmapFilingFromDeal } from '@/condor/application/actions/finsight/filings/item/actions';
import DealProfile from './DealProfile';
import selectors from '@/condor/application/actions/finsight/deal/selectors';

const mapStateToProps = (state, ownProps) => (
  {
    // dealId is passed directly on 15G or EE filing
    // dealId is got from router match params by default on DealProfile page
    dealId: ownProps.dealId || ownProps.match.params.dealId,
    filingType: ownProps.match.params.filingType,
    isInitialized: selectors.isInitialized(state),
    isCreateNewDeal: ownProps.location.pathname.includes('create'),
    item: selectors.getItemData(state),
    ratingAgencies: selectors.getRatingAgencies(state),
    options: selectors.getOptions(state),
    initialIssuer: selectors.getInitialIssuer(state),
    initialDeal: selectors.getInitialDeal(state),
  }
);

const mapDispatchToProps = {
  init: initDealProfile,
  reset,
  updateDeal,
  createDeal,
  createDealWithMapToFiling,
  updateDealWithMapToFiling,
  unmap: unmapFilingFromDeal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DealProfile);
