import { IValidationError } from './interfaces/ValidationError';

interface IParams {
  value: {
    checked: boolean,
    filters: [],
  },
  fieldName: string,
  fieldCaption: string,
}

export const validateCheckboxWithFilters = (params: IParams): IValidationError => {
  if (params.value?.checked && !params.value?.filters?.length) {
    return { [params.fieldName]: 'Select at least one item' };
  }
  return null;
};
