import React from 'react';
import { getModalContent } from '../helpers';
import { Modal } from '@dealroadshow/uikit';
import styles from './FilingModal.scss';

interface IProps {
  hideModal: () => void,
  isModalVisible: boolean,
  isDisableEmailModalVisible: boolean,
  isSendEmailModalVisible: boolean,
  isResendEmailModalVisible: boolean,
  selectedFiling: any,
}

const FilingModal = (
  {
    hideModal,
    isModalVisible,
    isDisableEmailModalVisible,
    isSendEmailModalVisible,
    isResendEmailModalVisible,
    selectedFiling,
  }: IProps,
) => {
  if (isModalVisible) {
    const { footer, content } = getModalContent(
      isDisableEmailModalVisible,
      isSendEmailModalVisible,
      isResendEmailModalVisible,
      selectedFiling,
    );

    return (
      <Modal
        title="Are you sure?"
        isVisible
        afterClose={ hideModal }
        footer={ footer }
        className={ styles.filingModal }
        dataTest="areYouSureFilingModal"
      >
        { content }
      </Modal>
    );
  }

  return null;
};

export default FilingModal;
