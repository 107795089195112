import { connect, batch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { submit, isPristine, isInvalid, isSubmitting } from 'redux-form';
import { CONDOR_CONTACT_TABLE_INLINE_EDIT_FORM_NAME } from '@/condor/ui/common/constants';
import {
  setCurrentContactItem,
  resetContactItem,
  setCondorContactsIsInlineEditing,
} from '@/condor/application/actions/contacts/item/actions';
import itemSelectors from '@/condor/application/actions/contacts/item/selectors';
import LastLoginAtCell from './LastLoginAtCell';
import { isUserUpdating } from '@/users/application/actions/data/selectors';

const mapStateToProps = (state, ownProps) => ({
  isSelectedForEditing: itemSelectors.getItemData(state)?.id === ownProps.cellProps?.row?.id,
  isUserUpdating: isUserUpdating(state),
  inlineEditFormState: {
    pristine: isPristine(CONDOR_CONTACT_TABLE_INLINE_EDIT_FORM_NAME)(state),
    invalid: isInvalid(CONDOR_CONTACT_TABLE_INLINE_EDIT_FORM_NAME)(state),
    submitting: isSubmitting(CONDOR_CONTACT_TABLE_INLINE_EDIT_FORM_NAME)(state),
  },
});

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators(
  {
    onEditClick: () => () => batch(() => {
      dispatch(setCurrentContactItem(ownProps.cellProps.row));
      dispatch(setCondorContactsIsInlineEditing(true));
    }),
    onSubmit: () => submit(CONDOR_CONTACT_TABLE_INLINE_EDIT_FORM_NAME),
    onReset: resetContactItem,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LastLoginAtCell);
