import React from 'react';
import cn from 'classnames';
import { parse, isAfter } from 'date-fns';

import getZonedUTCDate from '@/Framework/DateTime/getZonedUTCDate';
import { Tooltip, ICellProps } from '@dealroadshow/uikit';

import { CONDOR_TIME_FORMAT } from '@/Framework/DateTime/dateFormats';
import styles from '../roadshows.scss';

const StatusCallback = ({ row: { finishedAt }, cellData: isPublish }: ICellProps) => {
  const isExpire = () => {
    const parsedDateUtc = parse(finishedAt.dateTime, CONDOR_TIME_FORMAT, new Date());
    const zonedDate = getZonedUTCDate('UTC', parsedDateUtc);
    return isAfter(new Date(), zonedDate);
  };

  const getStatusColor = () => {
    if (isExpire()) {
      return styles.statusCallbackGray;
    }
    if (isPublish) {
      return styles.statusCallbackGreen;
    }
    return styles.statusCallbackOrange;
  };

  const getStatusTooltip = () => {
    if (isExpire()) {
      return 'Expired';
    }
    if (isPublish) {
      return 'Published';
    }
    return 'Unpublished';
  };

  return (
    <div className={ styles.statusCallbackWrapper }>
      <Tooltip content={ getStatusTooltip() }>
        <div className={ cn(styles.statusCallback, getStatusColor()) } />
      </Tooltip>
    </div>
  );
};

export default StatusCallback;
