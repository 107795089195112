import MappingsRepository from '@/condor/infrastructure/repository/mappings/mappingsRepository';
import { getCollectionActions } from '@/Framework/State/Redux/collectionFactory';

import actionTypes from './actionTypes';
import selectors from './selectors';
import { getAccountId } from '../helpers';

export const {
  getCollection: getMappingsEvercallsCollection,
  sort,
  itemsPerPage,
  paginate,
  reset: resetMappingsEvercallsCollection,
} = getCollectionActions(
  actionTypes,
  (payload, dispatch, getState) => {
    const mappingsRepository: MappingsRepository = getState().container.get(MappingsRepository);
    return mappingsRepository.getEvercallsMappings(payload);
  },
);

export const getMappingsEvercallsByFilter = () => (dispatch, getState) => {
  const state = getState();
  const id = getAccountId(state);
  dispatch(getMappingsEvercallsCollection(
    {
      evercallType: 'evercall',
      accountId: id,
      perPage: selectors.getPerPage(state),
      page: selectors.getPage(state),
      sortBy: selectors.getSortBy(state),
      sortOrder: selectors.getSortOrder(state),
    },
  ));
};
