import { IAdminCall } from '@/evercall/domain/vo/call/admin/AdminCall';

export const INITIAL_CALL_ITEM_STATE: IAdminCall = {
  id: '',
  name: '',
  groupName: '',
  groupPath: '',
  isFooterHidden: false,
  participantConferenceId: '',
  roadshowId: '',
  roadshowEntryCode: '',
};
