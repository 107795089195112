import { connect } from 'react-redux';
import DealProfileHeaderLinks from './DealProfileHeaderLinks';
import selectors from '@/condor/application/actions/finsight/deal/selectors';

const mapStateToProps = (state) => (
  {
    item: selectors.getItemData(state),
    initialIssuer: selectors.getInitialIssuer(state),
  }
);

export default connect(
  mapStateToProps,
)(DealProfileHeaderLinks);
