import { connect } from 'react-redux';
import { isSubmitting, isPristine, isInvalid, submit } from 'redux-form';
import {
  submitCondorFinsightCompanyForm,
  setCondorFinsightCompanyIsMigrateModalVisible,
} from '@/condor/application/actions/finsight/company/item/actions';
import {
  CONDOR_FINSIGHT_COMPANY_PROFILE_FORM_NAME,
} from '@/condor/ui/common/constants';
import CompanyProfile from './CompaniesProfileForm';

import selectors from '@/condor/application/actions/finsight/company/item/selectors';
import industryCollectionSelectors from '@/condor/application/actions/finsight/industry/collection/selectors';

const mapStateToProps = (state) => (
  {
    item: selectors.getItemData(state),
    industries: industryCollectionSelectors.getCollectionData(state),
    dealsInformation: selectors.getInformationForMigration(state),
    isSubmitDisabled: (
      isSubmitting(CONDOR_FINSIGHT_COMPANY_PROFILE_FORM_NAME)(state)
      || isInvalid(CONDOR_FINSIGHT_COMPANY_PROFILE_FORM_NAME)(state)
      || isPristine(CONDOR_FINSIGHT_COMPANY_PROFILE_FORM_NAME)(state)
    ),
  }
);

const mapDispatchToProps = (dispatch) => ({
  reduxFormSubmit: () => dispatch(submit(CONDOR_FINSIGHT_COMPANY_PROFILE_FORM_NAME)),
  onSubmit: (formData, withCreateDeal) => dispatch(submitCondorFinsightCompanyForm(formData, withCreateDeal)),
  onMigrateClick: () => dispatch(setCondorFinsightCompanyIsMigrateModalVisible(true)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompanyProfile);
